import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild, ChangeDetectorRef, OnChanges, SimpleChanges  } from '@angular/core';
import { Router } from '@angular/router';

import {environment} from '../../../environments/environment';

import {ProveedorService} from '../proveedor.service';
import {DistritoService} from '../../module-distrito/distrito.service';
import { MessageService } from '../../services/message.service';
import {AfipService} from '../../services/afip.service';

import {Proveedor} from '../models';
import {Distrito} from '../../module-distrito/models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';

@Component({
  selector: 'proveedores_form',
  templateUrl: 'proveedores_form.html'
})
export class ProveedoresFormComponent extends GenericFormComponent implements OnInit, AfterViewInit, OnChanges{
    @ViewChild('firstElementProveedor') firstElement: ElementRef;
    
    @Input() proveedor: Proveedor = new Proveedor();
    public distritos: Distrito[] = [];
    public condiciones: string[] = environment.condicionesIva;
    
    @Output() actualize: EventEmitter<Proveedor> = new EventEmitter<Proveedor>();
    @Output() cancel: EventEmitter<string> = new EventEmitter<string>();

    public INIT_BREADCRUM_ITEMS = [ {title: 'Home', ref: '#' }, {title: 'Proveedores', ref: '#/proveedores' }];
    public breadcrumbItems: BreadcrumbItems[] = Object.assign([], this.INIT_BREADCRUM_ITEMS);
    public editable: boolean = true;

    constructor(protected messageService: MessageService, private proveedorService: ProveedorService, private router: Router,  private distritoService: DistritoService,
        private afipService: AfipService, private cdRef:ChangeDetectorRef) {
        super(messageService);
    }
    ngOnChanges(changes: SimpleChanges): void {
        if(changes.proveedor.previousValue?.id != changes.proveedor.currentValue?.id){
            this.breadcrumbItems = Object.assign([], this.INIT_BREADCRUM_ITEMS);
            this.breadcrumbItems.push({ title: `${this.proveedor.cuit} - ${this.proveedor.nombre}`, active: true });
        }
    }

    ngOnInit(): void {
        if(this.proveedor?.id) {
            this.breadcrumbItems = Object.assign([], this.INIT_BREADCRUM_ITEMS);
            this.breadcrumbItems.push({ title: `${this.proveedor.cuit} - ${this.proveedor.nombre}`, active: true });
            this.editable = this.proveedor?.total_facturas == 0 && this.proveedor?.total_ordenes == 0;
        }
        this.distritoService.getDistritos({per_page: 1000}, {sort: 'nombre'}).subscribe(
            (rta: any) => {
                this.distritos = rta.data;
            }
        );  
    }
    ngAfterViewInit(): void {
        this.firstElement?.nativeElement?.focus();
        this.cdRef.detectChanges();
    }
    
    public save(event){
        this.beforeSubmit(event);
        if (!this.proveedor.id){
            this.proveedorService.createProveedor(this.proveedor).subscribe(
                (proveedor: Proveedor) => {
                    this.showSuccess(true, 'Proveedor creado correctamente');
                    this.actualize.emit(proveedor);
                    this.router.navigate(['/proveedores', proveedor.id]); 
                },err => {
                    this.showError(err, true);
                });
        } else {
            this.proveedorService.updateProveedor(this.proveedor).subscribe(
                (proveedor: Proveedor) => {
                    this.showSuccess(true, 'Proveedor modificado correctamente');
                },err => {
                    this.showError(err, true);
                });
        }
    }
    
    public cancelForm($event?){
        this.cancel.emit("Cancelacion");
    }
    
    public searchCuit() {
        this.cursorWait();
        this.afipService.infoCuit(this.proveedor.cuit).subscribe(
            (info: any) => {
                this.cursorDefault();
                this.proveedor.infoFromAfip(info);
            }, (error) => {
                this.showError({}, true, 'No se encontro informacion de ese cuit');
            }
        );
    }
    
    //
    //UTILS
    public customCompareDistrito(d1: Distrito, d2: Distrito) {
        if(d1 && d2){
            return d1.id == d2.id;
        }else{
            return d1 == d2;
        }
    }
    
    public changeCondicionIva() {
        this.proveedor.consumidor_final = this.proveedor.condicion_iva == 'CONFI';
    }
    
    public enabledSubmit() {
        this.submitted = false;
    }
    
    public disabledSubmit() {
        this.submitted = true;
    }
}