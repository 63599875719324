import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'filters-header-section',
  templateUrl: './filters-header-section.component.html',
  styleUrls: ['./filters-header-section.component.scss']
})
export class FiltersHeaderSectionComponent implements OnInit {
  @Input() expanded?: boolean = true;
  @Input() loading: boolean = false;
  @Input() title?: string;
  @Input() showFilterButton: boolean = true;
  @Input() showHeaderSection: boolean = true;
  @Input() advancedFilters: boolean = false;
  @Input() someFilterApllied: boolean = false;
  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();

  public isSomeFilterActive: boolean = false;
  public showFilterSection: boolean = false;
  public filtersApplyCounter: number = 0;
  public showMoreFilters: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.showFilterSection = this.expanded as boolean;
  }

  public toggleFilterSection(): void {
    this.showFilterSection = !this.showFilterSection;
  }

  public onFilterLocal(): void {
    this.onFilter.emit();
  }

  public onClearLocal(): void {
    this.onClear.emit();
  }

}
