<div class="container-fluid">
    <div *ngIf="periodo" class="row">
        <periodos_form [periodo]="periodo" (actualize)="actualizeForm($event)" (cancel)="cancelForm($event)"></periodos_form>
    </div>
    
    <div class="row">
        <div class="card">
            <div class="card-content">
                <breadcrumb [items]="breadcrumbItems"></breadcrumb> 

                <filters-header-section (onFilter)="filter(1)" [advancedFilters]="true">
                    <div class="col-sm-12">
                        <div class="col-sm-12 col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4">
                            <label for="search">Período:</label>
                            <div class="input-group">
                                <input class="form-control" type="text" placeholder="Período" name="search" [(ngModel)]="filtro.nombre_matches">
                                <span class="input-group-addon" (click)="filter(1)"><i class="cmp-i busqueda i-sm"></i></span>
                            </div>
                        </div>
                    </div>
                
                    <div moreFilters>
                        <div class="col-md-12">
                                <generic_period (outDate)="changeFilterDate($event)"></generic_period>
                        </div>       
                    </div>
                </filters-header-section>
 

                <div class="row mt-2">
                    <div class="col-sm-12">
                        <button class="btn btn-simple btn-icon btn-add" *ngIf="!periodo" (click)="newPeriodo()" title="Agregar"><i class="cmp-i agregar"></i> Agregar</button>
                    </div>  
                </div>
            
                <div class="row">
                    <div class="col-md-12 table-responsive">
                        <table class="table table-hover">
                            <thead class="text-primary">
                                <tr>
                                    <th><b>Nombre</b></th>
                                    <th><b>Fecha</b></th>
                                    <th><b>Acciones</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let periodo of periodos'>
                                    <td><a [routerLink]="['/periodos', periodo.id]" rel="tooltip" title="Editar Periodo">{{periodo.nombre}}</a></td>
                                    <td>{{periodo.fecha  | date: 'dd/MM/yyyy'}}</td>
                                    <td class="td-actions text-right">
                                        <a [routerLink]="['/periodos', periodo.id]" rel="tooltip" title="Editar Periodo" class="btn btn-primary btn-simple btn-xs btn-cmp-i">
                                            <i class="cmp-i editar i-sm"></i>
                                        </a>
                                        <button type="button" rel="tooltip" title="Eliminar Periodo" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="periodo" confirm-message="¿Está seguro que desea eliminar el Periodo?">
                                            <i class="cmp-i eliminar-borrar i-sm"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="periodos != null && periodos.length == 0" class="text-center">
                                    <td colspan="8">No se encontraron periodos</td>
                                </tr>
                            </tbody>
                        </table>                        
                        <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)" *ngIf="periodos != null && periodos.length > 0"></pager>
                    </div>
                </div>
            </div>
        </div>          
    </div>
</div>