import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import {ProveedorService} from '../proveedor.service';
import {GlobalVars} from '../../services/globalVars';
import {DistritoService} from '../../module-distrito/distrito.service';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Proveedor} from '../models';
import {User} from '../../models/user';
import {Distrito} from '../../module-distrito/models';
import Sort from 'app/models/Sort';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
 
@Component({
  selector: 'proveedores',
  templateUrl: './proveedores.component.html'
})
export class ProveedoresComponent extends GenericFormComponent implements OnInit {
  public actualUser: User;
    
  public proveedores: Proveedor[];
  public params = {page: 1, per_page: 10};

  public sortOptions: Sort[] = [
    { key: 'nombre', order: 'asc', description: 'Nombre' },
    { key: 'cuit', order: 'asc', description: 'Cuit' }
  ]

  public filtro = { search_elastic: null, sort: this.sortOptions[0] };
  public distritos: Distrito[] = [];
  //FORM
  public proveedor: Proveedor;

  public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Proveedores', active: true }];

  @ViewChild('search_elastic', { static: true }) searchElastic: ElementRef;

    
  constructor(protected messageService: MessageService, private proveedorService: ProveedorService, private globalVars: GlobalVars, private distritoService: DistritoService) {
    super(messageService);
  }

  ngOnInit() {
    this.actualUser = this.globalVars.actualUser;
    this.filter();
    this.distritoService.getDistritos({per_page: 1000}, {sort: 'nombre'}).subscribe(
        (rta: any) => {
            this.distritos = rta.data;
        }
    );
    setTimeout(() => {this.searchElastic.nativeElement.focus()}, 100);
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.cursorWait();
    this.proveedorService.getProveedores(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.proveedores = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
        },
        error => {
            this.cursorDefault();
        }
    );
  }
  
  //ACCIONES    
  onNotifyDelete(proveedor: Proveedor):void {
    this.deleteProveedor(proveedor);
  }
    
  public deleteProveedor(proveedor: Proveedor) {
    this.beforeSubmitList();
    this.proveedorService.deleteProveedor(proveedor.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Proveedor eliminado correctamente');
            //ELIMINO DE LA LISTA      
            this.proveedores.forEach(function(proveedorFor, index, object) {
                if(proveedorFor.id == proveedor.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }
  
    
  //FORMULARIO ALTA/EDICION
  //Para el Form
  public newProveedor(){
    this.proveedor = new Proveedor();
  }
  
  //Desde el formulario
  public actualizeForm(proveedor): void {
    this.proveedores.splice(0, 0, proveedor);
    this.cancelForm();
  }    
  public cancelForm(event?): void{
    this.proveedor = null;
  }
  
}