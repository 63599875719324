import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import {ProveedorService} from '../proveedor.service';
import {ProveedorCommunicate} from '../proveedor.communicate';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Proveedor} from '../models';
import Sort from 'app/models/Sort';

declare var $: any;

@Component({
  selector: 'proveedores_modal',
  templateUrl: './proveedores_modal.component.html'
})
export class ProveedoresModalComponent extends GenericFormComponent implements OnInit, OnDestroy {
  @ViewChild('proveedoresModal', { static: true }) el:ElementRef;
    
  public show: boolean= false;
  public proveedores: Proveedor[];
  public params = {page: 1, per_page: 10};

  public sortOptions: Sort[] = [
    { key: 'nombre', order: 'asc', description: 'Nombre' },
    { key: 'cuit', order: 'asc', description: 'Cuit' }
  ]

  public filtro = { search_elastic: null, sort: this.sortOptions[0] };
  
  public lastSearch: number = 0;

  public selectAll: boolean = false;
  
  //Observables
  private proveedoresModal: any;
    
  constructor(protected messageService: MessageService, private proveedorService: ProveedorService, private proveedorCommunicate: ProveedorCommunicate) {
      super(messageService);
  }

  ngOnInit() {
    this.proveedoresModal = this.proveedorCommunicate.proveedoresModalPop$.subscribe(
        (info: any) => {
            this.load();
            this.filter();
        });
        
    $(this.el.nativeElement).on('hidden.bs.modal', (e) => {
        this.show= false;
    });
  }
  
  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.cursorWait();
    this.lastSearch = new Date().getTime() / 1000;
    this.proveedorService.getProveedores(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.proveedores = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
            this.lastSearch = new Date().getTime() / 1000;
        },
        error => {
            this.cursorDefault();
        }
    );
  }

  onChangeSearch(event) {
    if (new Date().getTime() / 1000 > this.lastSearch + 1) {
      this.filter(1);
    }
  }
     
  public seleccionar(){
    let proveedores: Proveedor[] = this.proveedores.filter(pro => pro.selectedInTable);
    if (proveedores.length > 0) { 
        this.proveedorCommunicate.proveedoresSelecModalPopUp(proveedores);
        this.hide();
    }
  } 
  
  //
  //UTILS
  public changeSelected(proveedor: Proveedor) {
    this.proveedores.forEach(pro => pro.selectedInTable = false);  
    proveedor.selectedInTable= !proveedor.selectedInTable
    this.seleccionar();
  }
   
  //MODAL
  public load(){
    this.filtro.search_elastic = '';
    this.show= true;
    $(this.el.nativeElement).modal();
  }
  public hide(){
    $(this.el.nativeElement).modal("hide");
    this.show= false;
  }
  
  //OnDestroy
  ngOnDestroy() {
    this.proveedoresModal.unsubscribe();
  }
}