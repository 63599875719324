<div class="card">
    <div class="card-content">
        <h4 class="card-title">Alta/Edición de Producto</h4>
        <hr>

        <form class="form-horizontal" (submit)="save($event)" #datosProductoForm="ngForm">
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Nombre</label>
                        <div class="col-md-9">                                  
                            <input #firstElementProducto type="text" name="nombre" [(ngModel)]="producto.nombre" class="form-control" required>
                            <small [hidden]="!inputError.nombre" [innerHtml]="inputError.nombre" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>
            
            <div class="form-group">
                <div class="col-md-3">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Cantidad</label>
                        <div class="col-md-9">                                  
                            <input type="number" name="cantidad" [(ngModel)]="producto.cantidad" class="form-control" required (blur)="producto.calcularTotal()">
                            <small [hidden]="!inputError.cantidad" [innerHtml]="inputError.cantidad" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Importe</label>
                        <div class="col-md-9">                                  
                            <input type="number" name="importe" [(ngModel)]="producto.importe" class="form-control" required (blur)="producto.calcularTotal()">
                            <small [hidden]="!inputError.importe" [innerHtml]="inputError.importe" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Total</label>
                        <div class="col-md-9">                                  
                            <input type="number" name="total" [(ngModel)]="producto.total" class="form-control" disabled="disabled">
                            <small [hidden]="!inputError.total" [innerHtml]="inputError.total" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>

            <div class="row text-center">
                <button class="btn btn-default btn-cancel" type="button" (confirm-click)="cancelForm($event)" confirm-message="¿Está seguro que desea cancelar este formulario?">Cancelar</button>
                <button class="btn btn-primary ml-2" type="submit" [disabled]="submitted">Guardar</button>
            </div>
            <div class="clearfix"></div>
        </form>
    </div>
</div>