import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import {OrdenCommunicate} from '../orden.communicate';
import {GlobalVars} from '../../services/globalVars';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

declare var $: any;

@Component({
  selector: 'orden_venta_modal',
  templateUrl: './orden_modal.component.html'
})
export class OrdenVentaModalComponent extends GenericFormComponent implements OnInit, OnDestroy {
  @ViewChild('ordenModal', { static: true }) el:ElementRef;

  public show: boolean = false;
  public id: number = null;
  
  //Observables
  private ordenModal: any;
    
  constructor(protected messageService: MessageService, private globalVars: GlobalVars, private ordenCommunicate: OrdenCommunicate) {
      super(messageService);
  }

  ngOnInit() {
    this.ordenModal = this.ordenCommunicate.ordenModalPop$.subscribe(
        (id) => {
          this.id = id;
          this.load();
        });
        
    $(this.el.nativeElement).on('hidden.bs.modal', (e) => {
        this.show= false;
    });
  }
   
  //MODAL
  public load(){
    this.show= true;
    $(this.el.nativeElement).modal();
  }
  public hide(){
    $(this.el.nativeElement).modal("hide");
    this.show= false;
  }
  
  //OnDestroy
  ngOnDestroy() {
    this.ordenModal.unsubscribe();
  }
}