<div class="card">
    <div class="card-content">
        <h4 class="card-title">Crear Recibo</h4>
        <hr>
        <form class="form-horizontal" (submit)="save($event)" #datosMovForm="ngForm">
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Método de Pago</label>
                        <div class="col-md-9">
                            <select class="form-control" data-style="btn btn-wd btn-block" name="tipo" [(ngModel)]="recibo.tipo" (change)="onChangeTipo()" #firstElementRecibo>
                                <option [ngValue]="null">Seleccione un Método de Pago</option>
                                <option *ngFor="let tipo of tipos" [ngValue]="tipo">{{tipo}}</option>
                            </select>
                            <small [hidden]="!inputError.tipo" [innerHtml]="inputError.tipo" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Monto</label>
                        <div class="col-md-9">                                  
                            <input type="number" name="monto" [(ngModel)]="recibo.monto" class="form-control" (change)="validateField('monto')" required>
                            <small [hidden]="!inputError.monto" [innerHtml]="inputError.monto" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>
            
            <div class="form-group">
                <div class="col-md-6" *ngIf="recibo.tipo == 'CHEQUE'">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Cheque</label>
                        <div class="col-md-9">                                  
                            <input type="number" name="nro_cheque" [(ngModel)]="recibo.nro_cheque" class="form-control" (change)="validateField('nro_cheque')" required>
                            <small [hidden]="!inputError.nro_cheque" [innerHtml]="inputError.nro_cheque" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6" *ngIf="recibo.tipo == 'TRANSFERENCIA'">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Cuenta</label>
                        <div class="col-md-9">                                  
                            <input type="number" name="nro_cuenta" [(ngModel)]="recibo.nro_cuenta" class="form-control" (change)="validateField('nro_cuenta')" required>
                            <small [hidden]="!inputError.nro_cuenta" [innerHtml]="inputError.nro_cuenta" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Fecha Recibo</label>
                        <div class="col-md-9"> 
                            <input-datepicker [(dateModel)]="recibo.fecha" [isStringFormat]="true" [required]="true" (actualize)="validateField('fecha')"></input-datepicker> 
                            <small [hidden]="!inputError.fecha" [innerHtml]="inputError.fecha" class="text-danger"></small>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Fecha Vencimiento Pago</label>
                        <div class="col-md-9"> 
                            <input-datepicker [(dateModel)]="recibo.fecha_vto_pago" [isStringFormat]="true" [required]="true" (actualize)="validateField('fecha_vto_pago')"></input-datepicker> 
                            <small [hidden]="!inputError.fecha_vto_pago" [innerHtml]="inputError.fecha_vto_pago" class="text-danger"></small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Fecha Servicio Hasta</label>
                        <div class="col-md-9"> 
                            <input-datepicker [(dateModel)]="recibo.fecha_serv_hasta" [isStringFormat]="true" [required]="true" (actualize)="validateField('fecha_serv_hasta')"></input-datepicker> 
                            <small [hidden]="!inputError.fecha_serv_hasta" [innerHtml]="inputError.fecha_serv_hasta" class="text-danger"></small>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Fecha Servicio Desde</label>
                        <div class="col-md-9"> 
                            <input-datepicker [(dateModel)]="recibo.fecha_serv_desde" [isStringFormat]="true" [required]="true" (actualize)="validateField('fecha_serv_desde')" ></input-datepicker> 
                            <small [hidden]="!inputError.fecha_serv_desde" [innerHtml]="inputError.fecha_serv_desde" class="text-danger"></small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-12">
                    <label>Descripción</label>
                    <div class="row">
                        <div class="col-md-12">                                  
                            <textarea type="text" name="descripcion" [(ngModel)]="recibo.descripcion" class="form-control" rows="4" placeholder="Descripción del recibo" style="height: 80px !important;" (change)="validateField('descripcion')" required></textarea>
                            <small [hidden]="!inputError.descripcion" [innerHtml]="inputError.descripcion" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>
            
            <div class="row text-center">
                <button class="btn btn-default btn-cancel" type="button" (confirm-click)="cancelForm($event)" confirm-message="¿Está seguro que desea cancelar este formulario?">Cancelar</button>
                <custom-btn-loader
                    text="Guardar"
                    [loading]="submitted"
                    [disabled]="submitted"
                    (action)="save($event)"
                    btnClass="btn btn-primary ml-1"
                ></custom-btn-loader> 
            </div>
            <div class="clearfix"></div>
        </form>
    </div>
</div>