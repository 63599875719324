<div *ngIf="movimiento" class="row">
    <movimientos_orden_form [movimiento]="movimiento" [proveedor]="proveedor" (actualize)="actualizeForm($event)" (cancel)="cancelForm($event)"></movimientos_orden_form>
</div>

<div class="row">
    <div class="card">
        <div class="card-content">
            <h4 class="card-title">Pagos / Movimientos</h4>
            <hr>

            <div class="row mt-2">
                <div class="col-sm-12">
                    <button class="btn btn-simple btn-icon btn-add" *ngIf="! movimiento && orden.tieneDocumentos() && !orden.estaPagado(redondeo) && !orden.verificada" (click)="newMovimiento()" title="Agregar"><i class="cmp-i agregar"></i> Agregar</button>
                </div>  
            </div>
            
            <div class="row">
                <div class="col-md-12 table-responsive">
                    <table class="table table-hover">
                        <thead class="text-primary">
                            <tr>
                                <th rowspan="2"><b>Descripción</b></th>
                                <th rowspan="2"><b>Monto</b></th>
                                <th rowspan="2"><b>Moneda</b></th>
                                <th class="text-center" colspan="2"><b>Origen</b></th>
                                <th class="text-center" colspan="2"><b>Destino</b></th>
                                <th rowspan="2"><b>Fecha</b></th>
                                <th rowspan="2"><b>Método de Pago</b></th>
                                <th rowspan="2"><b>Cheque</b></th>
                                <th rowspan="2"><b>Acciones</b></th>
                            </tr>
                            <tr>
                                <th><b>Banco</b></th>
                                <th><b>Cuenta</b></th>
                                <th><b>Banco</b></th>
                                <th><b>Cuenta</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor='let movimiento of movimientos'>
                                <td>
                                    <span *ngIf="!movimiento.descripcion">-</span>
                                    <span *ngIf="movimiento.descripcion">{{movimiento.descripcion}}</span>
                                    <span *ngIf="movimiento.anulado"> (ANULADO)</span>                           
                                </td>
                                <td>$ {{movimiento.monto | number: '1.2-2'}}</td>
                                <td>{{movimiento.moneda | translate: 'MONEDA'}}</td>
                                <td>
                                    <div *ngIf="movimiento.cuenta.nombre">
                                        <span *ngIf="movimiento.cuenta.tipo == 'BANCO' && movimiento.cuenta.banco_id">
                                            <span [class]="'bank-logo bank-logo-' + movimiento.cuenta.banco_id" *ngIf="BANK_LOGO_IDS.includes(movimiento.cuenta.banco_id)"></span>
                                        </span>
                                        <span *ngIf="movimiento.cuenta.tipo != 'BANCO' || !movimiento.cuenta.banco_id" class="text-center">-</span>
                                    </div>
                                    <span *ngIf="!movimiento.cuenta.nombre" class="text-center">-</span>
                                </td>
                                <td>
                                    <div *ngIf="movimiento.cuenta.nombre">
                                        <a [routerLink]="['/cuentas', movimiento.cuenta.id]" rel="tooltip" title="Detalle Cuenta">{{movimiento.cuenta.nombre}}</a>
                                    </div>
                                    <span *ngIf="!movimiento.cuenta.nombre" class="text-center">-</span>
                                </td>

                                
                                <td>
                                    <div *ngIf="movimiento.cuentadestino">
                                        <span *ngIf="movimiento.cuentadestino.tipo == 'BANCO' && movimiento.cuentadestino.banco_id">
                                            <span [class]="'bank-logo bank-logo-' + movimiento.cuentadestino.banco_id" *ngIf="BANK_LOGO_IDS.includes(movimiento.cuentadestino.banco_id)"></span>
                                        </span>
                                        <span *ngIf="movimiento.cuentadestino.tipo != 'BANCO' || !movimiento.cuentadestino.banco_id" class="text-center">-</span>
                                    </div>
                                    <span *ngIf="!movimiento.cuentadestino" class="text-center">-</span>
                                </td>
                                <td>
                                    <div *ngIf="movimiento.cuentadestino">
                                        <span> {{movimiento.cuentadestino.nombre}}</span>
                                    </div>
                                    <span *ngIf="!movimiento.cuentadestino"  class="text-center">-</span>
                                </td>
                                <td>
                                	<span *ngIf="!movimiento.cheque">{{movimiento.fecha_contable | date: 'dd/MM/yyyy'}}</span>
                                    <span *ngIf="movimiento.cheque">{{movimiento.cheque.emision | date: 'dd/MM/yyyy'}}</span></td>
                                <td>{{movimiento.metodopago.nombre}}</td>
                                <td>
                                    <span *ngIf="!movimiento.cheque">-</span>
                                    <span *ngIf="movimiento.cheque"><a (click)="detalleCheque(movimiento.cheque)" rel="tooltip" title="Detalle">{{movimiento.cheque.numero}}</a></span>
                                </td>                                
                                <td class="td-actions text-right">
                                    <button *ngIf="! movimiento.anulado && movimiento.cheque && ! orden.verificada" type="button" rel="tooltip" title="Eliminar Movimiento" class="btn btn-danger btn-simple btn-xs" (confirm-click)="onNotifyAnular($event)" [confirm-params]="movimiento" confirm-message="¿Está seguro que desea anular el Cheque?">
                                        Anular
                                    </button>
                                    <button
                                        [disabled]="orden.verificada"
                                        [title]="orden.verificada ? 'No es posible eliminar el movimiento ya que la orden esta verificada.' : 'Eliminar Movimiento'" 
                                        type="button" rel="tooltip" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="movimiento" confirm-message="¿Está seguro que desea eliminar el Movimiento? Si tiene un cheque asociado quedará liberado para ser utilizado luego.">
                                        <i class="cmp-i eliminar-borrar i-sm"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr *ngIf="movimientos != null && movimientos.length == 0" class="text-center">
                                <td colspan="11">No hay pagos / movimientos</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>