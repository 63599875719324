<div class="card">
    <div class="card-content">
        <h4 class="card-title">{{!tipoViatico.id ? 'Nuevo Tipo de Viático' : 'Modificación de Tipo de Viático'}}</h4>
        <hr>
        <form class="form-horizontal" (submit)="save($event)" #datosMovForm="ngForm">
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Tipo</label>
                        <div class="col-md-9">                                  
                            <select #firstElement class="form-control" data-style="btn btn-wd btn-block" name="tipo"  [(ngModel)]="tipoViatico.tipo" required="">
                                <option [ngValue]="null">Seleccione un Tipo</option>
                                <option *ngFor="let tipo of tipos" [ngValue]="tipo">{{tipo | translate: "TIPO_VIATICO"}}</option>
                            </select>
                            <small [hidden]="!inputError.tipo" [innerHtml]="inputError.tipo" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>
            
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Monto</label>
                        <div class="col-md-9">                                  
                            <input type="number" name="monto" [(ngModel)]="tipoViatico.monto" class="form-control" placeholder="1000" required>
                            <small [hidden]="!inputError.monto" [innerHtml]="inputError.monto" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6" *ngIf="tipoViatico.tipo == 'K'">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Kilometros</label>
                        <div class="col-md-9" >
                            <input type="number" name="kilometros" [(ngModel)]="tipoViatico.kilometros" class="form-control" placeholder="10" required>
                            <small [hidden]="!inputError.kilometros" [innerHtml]="inputError.kilometros" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>

            <div class="row text-center">

                <button class="btn btn-default btn-cancel" type="button" (confirm-click)="cancelForm($event)" confirm-message="¿Está seguro que desea cancelar este formulario?">Cancelar</button>
                <button class="btn btn-primary ml-2" type="submit" [disabled]="submitted">Guardar</button>
            </div>
            <div class="clearfix"></div>
        </form>
    </div>
</div>