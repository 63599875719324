import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AfipConfigComponent } from './afip-config/afip-config.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { GenericModule } from 'app/module-generic/generic.module';
import { TranslateComponentModule } from 'app/translate-module/translate-component.module';



@NgModule({
  declarations: [
    AfipConfigComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    GenericModule,
    TranslateComponentModule
  ]
})
export class ConfigModule { }
