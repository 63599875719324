<div class="container-fluid">
    <div class="row" class="login-box-container">
        <div class="col-sm-10 col-sm-offset-1 col-lg-6 col-lg-offset-3">
            <div class="card card-login-custom">
                <div class="card-header text-center card-header-login-custom">
                    <div class="row">
                        <div class="col-sm-12 col-md-6 login-logo-container">
                            <img src="assets/img/CompassLogo.png" style="width: 200px; height: auto"/>
                        </div>
                        <div class="col-sm-12 col-md-6 login-logo-container">
                            <img src="assets/img/ColegioTecnicos.png" style="width: 180px; height: auto"/>
                        </div>
                    </div>
                    <hr>

                </div>
                <div class="card-content mt-2" class="card-content-login-custom">
                    <form class="form-horizontal" (submit)="login($event)" #datosForm="ngForm" *ngIf="!access">
                        <div class="form-group">
                            <div class="col-sm-12 text-center">
                                <label><b>Email / Usuario:</b></label>
                            </div>
                            <div class="col-sm-12 col-md-offset-3 col-md-6">
                                <input type="email" class="form-control" name="email" [(ngModel)]="loginForm.email" placeholder="Email o usuario">
                                <small [hidden]="!inputError.email" [innerHtml]="inputError.email" class="text-danger"></small>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-sm-12 text-center">
                                <label><b>Contraseña:</b></label>
                            </div>
                            <div class="col-sm-12 col-md-offset-3 col-md-6">
                                <input type="password" class="form-control" name="password" [(ngModel)]="loginForm.password" placeholder="Contraseña">
                                <small [hidden]="!inputError.password" [innerHtml]="inputError.password" class="text-danger"></small>
                            </div>
                        </div>

                        <div class="col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4 mt-2">
                            <button type="submit" class="btn btn-info btn-block pull-right" [disabled]="submitted">Iniciar Sesión</button>
                        </div>
                        <div class="clearfix"></div>
                    </form>

                    <div class="msg-content" *ngIf="access">
                        <br>
                        <div class="row mt-2">
                            <h3 class="col-sm-12 text-center"><b>Le damos la bienvenida!</b></h3>
                            <p class="col-sm-12 text-center mt-2">Accediendo al sistema...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </div>
</div>
