import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule }   from '@angular/forms';

import {GenericModule} from '../module-generic/generic.module';
import {TranslateComponentModule} from '../translate-module/translate-component.module';

import {UserService} from './user.service';

import { LoginComponent } from './login/login.component';

import {UsersComponent} from './users/users.component';
import {UsersFormComponent} from './users_form/users_form.component';
import {RolesComponent} from './roles/roles.component';
import {PermisosComponent} from './permisos/permisos.component';
import {PermisosFormComponent} from './permisos_form/permisos_form.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    
    GenericModule,
    TranslateComponentModule,
  ],
  declarations: [
    LoginComponent,
    UsersComponent,
    UsersFormComponent,
    RolesComponent,
    PermisosComponent,
    PermisosFormComponent,
  ],
  providers: [
    UserService
  ],
  exports: [    
    LoginComponent,
    UsersComponent,
    RolesComponent,
  ]
})
export class UserModule { }
