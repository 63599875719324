import { Component, OnInit, Input } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {CuentaService} from '../cuenta.service';
import {CuentaCommunicate} from '../cuenta.communicate';
import { MessageService } from '../../services/message.service';
import {PdfService} from '../../services/pdf.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Cuenta, Movimiento, MetodoDePago, Cheque} from '../models';
import Sort from 'app/models/Sort';
import { environment } from 'environments/environment';
 
@Component({
  selector: 'movimientos',
  templateUrl: './movimientos.component.html'
})
export class MovimientosComponent extends GenericFormComponent implements OnInit {
  @Input() cuenta: Cuenta;
  public movimientos: Movimiento[];
  public params = {page: 1, per_page: 10, excel: 0, tipo: null, reposicion: null};
  public sortOptions: Sort[] = [
    { key: 'fecha_contable', order: 'desc', description: 'Fecha creación (Descendente)' },
    { key: 'fecha_contable', order: 'asc', description: 'Fecha creación (Ascendente)' },
  ]
  public filtro = {metodo_pago_id: null, fecha_contable_gteq: null, fecha_contable_lteq: null, sort: this.sortOptions[0]};
  
  public metodos: MetodoDePago[] = [];
  //FORM
  public movimiento: Movimiento;

  public fechas = null;

  public BANK_LOGO_IDS = environment.bank_logo_ids;


  constructor(protected messageService: MessageService, private cuentaService: CuentaService, private pdfService: PdfService,
    private cuentaCommunicate: CuentaCommunicate, private route: ActivatedRoute, private router: Router) {
    super(messageService);
  }

  ngOnInit() {
    this.filter();
    this.cuentaService.getMetodosDePago({per_page: 1000}, {sort: 'nombre'}).subscribe(
        (rta: any) => {
            this.metodos = rta.data;
        }
    );
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    this.route.queryParams.subscribe(params => {
        if(params['estado'] == 'reposiciones'){
          this.params.tipo = 'ingreso';
          this.router.navigate([], { 
            queryParams: {
              estado: null
            }
          });
        }

        if(this.fechas != 'fechacambiado'){

          if((params['fecha_gteq'] !== undefined) &&
            (params['fecha_gteq'] != null) && (params['fecha_gteq'] != "undefined")){
              this.filtro.fecha_contable_gteq = params['fecha_gteq'];
          }

          if((params['fecha_lteq'] !== undefined) &&
            (params['fecha_lteq'] != null) && (params['fecha_lteq'] != "undefined")){
              this.filtro.fecha_contable_gteq = params['fecha_lteq'];
          }
        } else {
          this.fechas = null;
        }

    });
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.cursorWait();
    this.cuentaService.getMovimientosCuenta(this.cuenta.id, this.params, this.filtro).subscribe(
        (rta: any) => {
            this.cursorDefault();
            this.movimientos = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
        },
        error => {
            this.cursorDefault();
        }
    );
  }
        
  public changeFilterDate(value){
    this.filtro.fecha_contable_gteq = value.from;
    this.filtro.fecha_contable_lteq = value.to;
    this.fechas = 'fechacambiado';
    this.filter(1);
  }
  
  public pdfMovimientos(excel: number) {
    this.cursorWait();
    this.params.excel = excel;
    this.cuentaService.pdfCajaChica(this.cuenta.id, this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            if (excel) {
                this.pdfService.loadExcel("caja_chica", rta);                
            } else {
                this.pdfService.loadPdf("caja_chica", rta);
            }
        },
        error => {
            this.cursorDefault();
            alert('Ups! No pudimos imprimir el pdf');
        }
    );
  }
  
  //ACCIONES    
  onNotifyDelete(movimiento: Movimiento):void {
    this.deleteMovimiento(movimiento);
  }
    
  public deleteMovimiento(movimiento: Movimiento) {
    this.beforeSubmitList();
    this.cuentaService.deleteMovimiento(movimiento.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Movimiento eliminado correctamente');
            //ELIMINO DE LA LISTA      
            this.movimientos.forEach(function(movimientoFor, index, object) {
                if(movimientoFor.id == movimiento.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }
  
    
  //FORMULARIO ALTA/EDICION
  //Para el Form
  public newMovimiento(){
    this.movimiento = new Movimiento();
    this.movimiento.cuenta = this.cuenta;
  }
  public updateMovimiento(movimiento: Movimiento) {
    this.movimiento = new Movimiento(movimiento);
  }
  
  //Desde el formulario
  public actualizeForm(movimiento: Movimiento): void {
    let exists= false;
    this.movimientos.forEach(function(movimientoFor, index, object) {
        if(movimientoFor.id == movimiento.id) {
            object[index]= movimiento;
            exists= true;
        }
    });
    if(!exists){
        this.movimientos.splice(0, 0, movimiento);
    }
    this.cancelForm();
  }    
  public cancelForm(event?): void{
    this.movimiento = null;
  }
  
  public detalleCheque(cheque: Cheque) {
    this.cuentaCommunicate.chequeModalPopUp(cheque);
  }
}