<div class="card">
    <div class="card-content">
        <h4 class="card-title">Nueva Imputación</h4>
        <hr>
        <form class="form-horizontal" (submit)="save($event)" #datosFormImpCon="ngForm">
            <div class="form-group">
                <div class="col-md-12">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Imputación</label>
                        <div class="col-md-9">
                            <select #firstElementImputacionContable class="form-control filter-form-control selectpicker custom-live-search" data-live-search="true" data-style="btn btn-wd btn-block" name="imputacion_contable" [(ngModel)]="imputacion" [compareWith]='customCompareImputacion'>
                                <option [ngValue]="null">Seleccione una Imputación</option>
                                <option *ngFor="let imputacion of imputaciones" [ngValue]="imputacion">{{imputacion.nombre}}</option>
                            </select>
                            <small [hidden]="!inputError.imputacion_id" [innerHtml]="inputError.imputacion_id" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>

            <div class="row text-center">
                <button class="btn btn-default btn-cancel" type="button" (confirm-click)="cancelForm($event)" confirm-message="¿Está seguro que desea cancelar este formulario?">Cancelar</button>
                <button class="btn btn-primary ml-2" type="submit" [disabled]="submitted">Guardar</button>
            </div>
            <div class="clearfix"></div>
        </form>
    </div>
</div>