import { Injectable } from '@angular/core';

export interface SelectPeriod{
    period: string,
    fromDate: any,
    toDate: any,
}

@Injectable()
export class ProjectUtils {
    constructor() { 
    }

    //
    //DATES


    //Formatea a unix format
    public parseDateTimeToUnixFormat(date: Date){
        return Math.round(date.getTime()/1000);
    }


    //Formatea a 'yyyy-MM-dd' 
    // Si mes/dias es menor a 10 le agrega el 0 adelante
    public parseDateTimeToFormat(date: Date): string{
        let month= date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        let day= date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        return `${date.getFullYear()}-${month}-${day}`;
    }


    //Formatea a 'yyyyMMdd' 
    // Si mes/dias es menor a 10 le agrega el 0 adelante
    public parseDateTimeToFormatYyyyMmDd(date: Date): string{
        let month= date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        let day= date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        return `${date.getFullYear()}${month}${day}`;
    }


    //Formatea a 'yyyy-MM-dd hh:mm:ss'
    public parseDateTimeToFullFormat(date: Date): string{
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();

        return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    }
    

    //Formatea a 'yyyy-MM-dd[hh.mm]'
    public parseDateTimeToFullName(date: Date): string{
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hours = date.getHours();
        let minutes = date.getMinutes();

        return year + "-" + this.parseTime2digits(month) + "-" + this.parseTime2digits(day) + "[" + this.parseTime2digits(hours)  + "." + this.parseTime2digits(minutes)  + "]";
    }
    
    //Formatea numero a 2 digitos agregando cero adelante si es entre 0 y 9
    private parseTime2digits(number: number) {
        return (number.toString().length > 1 ? number.toString() : "0" + number.toString())
    }


    //Formatea string (con formato de fecha) a 'yyyy-MM-dd'
    //En caso de no tener fecha y hora le agrega las 00
    public parseDateToServiceFormat(dateString: string): string{
        if(dateString){
            if(dateString.length < 12){
                dateString+= " 00:00:00.000000";
            }
            let date= new Date(dateString);
            let month= date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
            let day= date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            return `${date.getFullYear()}-${month}-${day}`;
        }else{
            return '';
        }
    }

    //Formatea string (con formato de fecha) a unix format
    //En caso de no tener fecha y hora le agrega las 00
    public parseDateTimeToServiceFormat(dateString: string): number{
        if(dateString){
            if(dateString.length < 12){
                dateString+= " 00:00:00.000000";
            }
            let date= new Date(dateString);
            return Math.round(date.getTime()/1000);
        }else{
            return null;
        }
    }
    
    //Formatea a 'yyyy-MM-dd'
    public parseDateToInput(dateString): string{
        if(dateString){
            let date= new Date(dateString);
            let month= date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
            let day= date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            return `${date.getFullYear()}-${month}-${day}`;
        }else{
            return '';
        }
    }
    
    //Formatea a 'dd/MM/yyyy'
    public parseDateToLabel(dateString): string{
        if(dateString){
            let date= new Date(dateString);
            let month= date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
            let day= date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            return `${day}/${month}/${date.getFullYear()}`;
        }else{
            return '';
        }
    }

    //Formatea a 'dd/MM/yyyy hh:mm'
    public parseDateTimeToLabel(dateString): string{
        if(dateString){
            let date= new Date(dateString);
            let month= date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
            let day= date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            let hour= date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
            return `${day}/${month}/${date.getFullYear()} ${hour}:${minutes}`;
        }else{
            return '';
        }
    }
    
    //
    //MANEJO DE PERIODOS
    public lastMonths(cant: number) {
        // Tener en cuenta que los meses aca van de 0 a 11
        let meses = [];
        let x = new Date();
        x.setDate(1); // Primer dia del mes
        for (let i = 0; i < cant; i++) {
          meses.push(x.getMonth());
          x.setMonth(x.getMonth()-1);
        }
        return meses;
    }

    //Devuelve coleccion "cant" de años para atras
    public lastYears(cant: number) {
        // Tener en cuenta que los meses aca van de 0 a 11
        let anios = [];
        let x = new Date();
        x.setDate(1); // Primer dia del mes
        for (let i = 0; i < cant; i++) {
          anios.push(x.getFullYear());
          x.setFullYear(x.getFullYear()-1);
        }
        return anios;
    }

    //Devuelve periodo a partir de un numero de mes
    //Formato 'yyyyMMdd hh:MM:ss'
    public monthToPeriod(mes: number) {
        let i = new Date();
        if (mes > i.getMonth()) {
            i.setFullYear(i.getFullYear() - 1)
        }
        i.setDate(1);
        i.setMonth(mes);
        let f = new Date();
        if (mes > f.getMonth()) {
            f.setFullYear(f.getFullYear() - 1)
        }
        f.setMonth(mes + 1);
        f.setDate(0);
        let period: SelectPeriod = {
          period: null,
          fromDate: this.parseDateTimeToFormat(i),
          toDate: this.parseDateTimeToFormat(f)
        };
        
        return this.updateFullFormatDate(period);
    }

    //Devuelve periodo a partir de un mes y un año
    //Formato 'yyyyMMdd hh:MM:ss'
    public monthAndYearToPeriod(mes: number, anio: number) {
        let i = new Date();
        

        i.setDate(1);
        i.setMonth(mes);
        i.setFullYear(anio);
        
        let f = new Date();
        
        f.setMonth(mes + 1);
        f.setDate(0);
        f.setFullYear(anio);
        let period: SelectPeriod = {
          period: null,
          fromDate: this.parseDateTimeToFormat(i),
          toDate: this.parseDateTimeToFormat(f)
        };
        
        return this.updateFullFormatDate(period);
    }


    //Desde las 00:00:00 de la fecha "from" hasta
    //las 23:59:59 de la fecha "to"
    //Formato "UnixFromDate^UnixToDate" o ">UnixFromDate" o "<UnixToDate"
    //dependiendo que fechas se le indique

    public updateUnixDate(optPeriod: SelectPeriod){
        let dateFrom= null;
        if (optPeriod.fromDate && !(optPeriod.fromDate instanceof Date)){
            dateFrom= new Date(optPeriod.fromDate + " 00:00:00");
        }
        let dateTo= null;
        if (optPeriod.toDate && !(optPeriod.fromDate instanceof Date)){
            dateTo= new Date(optPeriod.toDate + " 23:59:59");
        }
        
        if(dateFrom && dateTo){
            return this.parseDateTimeToUnixFormat(dateFrom) + "^" + this.parseDateTimeToUnixFormat(dateTo);
        }else if(dateFrom){
            return  ">" + this.parseDateTimeToUnixFormat(dateFrom);
        }else if(dateTo){
            return  "<" + this.parseDateTimeToUnixFormat(dateTo);
        }else{
            return null;
        }
    }
    
    //Desde las 00:00:00 de la fecha "from" hasta
    //las 23:59:59 de la fecha "to"
    //Formato 'yyyyMMdd hh:MM:ss'
    public updateFullFormatDate(optPeriod: SelectPeriod){
        let dateFrom= null;
        if (optPeriod.fromDate && !(optPeriod.fromDate instanceof Date)){
            dateFrom= new Date(optPeriod.fromDate + " 00:00:00");
        }
        let dateTo= null;
        if (optPeriod.toDate && !(optPeriod.fromDate instanceof Date)){
            dateTo= new Date(optPeriod.toDate + " 23:59:59");
        }
        
        if(dateFrom && dateTo){
            return {from: optPeriod.fromDate + " 00:00:00", to: optPeriod.toDate + " 23:59:59"};
        }else if(dateFrom){
            return  {from: optPeriod.fromDate + " 00:00:00", to: null};
        }else if(dateTo){
            return  {from: null, to: optPeriod.toDate + " 23:59:59"};
        }else{
            return {from: null, to: null};
        }
    }
    
    //Desde las 00:00:00 de la fecha "from" hasta
    //las 23:59:59 de la fecha "to"
    //Formato 'yyyyMMdd'
    public updateYmdFormat(optPeriod: SelectPeriod){
        let dateFrom= null;
        if (optPeriod.fromDate && !(optPeriod.fromDate instanceof Date)){
            dateFrom= new Date(optPeriod.fromDate + " 00:00:00");
        }
        let dateTo= null;
        if (optPeriod.toDate && !(optPeriod.fromDate instanceof Date)){
            dateTo= new Date(optPeriod.toDate + " 23:59:59");
        }
        
        if(dateFrom && dateTo){
            return  {from: optPeriod.fromDate.replace(/-/g , ""), to: optPeriod.toDate.replace(/-/g , "")};
        }else if(dateFrom){
            dateFrom.setDate(dateFrom.getDate() - 1);
            return  {from: this.parseDateTimeToFormatYyyyMmDd(dateFrom), to: null};
        }else if(dateTo){
            dateTo.setDate(dateTo.getDate() + 1);
            return  {from: null, to: this.parseDateTimeToFormatYyyyMmDd(dateTo)};
        }else{
            return {from: null, to: null};
        }
    }
    
    //Calcula periodo de tiempo segun parametro
    public loadPeriod(optPeriod: SelectPeriod){
        switch (optPeriod.period) {
            case 'today':
                this.today(optPeriod);
                break;
            case 'yesterday':
                this.yesterday(optPeriod);
                break;
            case 'lastWeek':
                this.lastWeek(optPeriod);
                break;
            case 'actualMonth':
                this.actualMonth(optPeriod);
                break;
            case 'actualYear':
                this.actualYear(optPeriod);
                break;
            case 'jan':
            case 'feb':
            case 'mar':
            case 'apr':
            case 'may':
            case 'jun':
            case 'jul':
            case 'aug':
            case 'sep':
            case 'oct':
            case 'nov':
            case 'dec':
                this.month(optPeriod);
                break;

            default:
                return this.updateUnixDate(optPeriod);
          }
    }

    //Desde y hasta hoy
    public today(optPeriod: SelectPeriod){
        let date1: Date= new Date();
        let date2: Date= new Date();
        optPeriod.fromDate = this.parseDateTimeToFormat(date1);
        optPeriod.toDate= this.parseDateTimeToFormat(date2);
        
    }

    //Desde y hasta 1 dias atras 
    public yesterday(optPeriod: SelectPeriod){
        let date1: Date= new Date();
        let date2: Date= new Date();
        date1.setDate(date1.getDate() -1);
        date2.setDate(date2.getDate() -1);
        optPeriod.fromDate = this.parseDateTimeToFormat(date1);
        optPeriod.toDate= this.parseDateTimeToFormat(date2);
    }

    //Desde 7 dias atras hasta el dia de hoy
    public lastWeek(optPeriod: SelectPeriod){
        let date1: Date= new Date();
        let date2: Date= new Date();
        date1.setDate(date1.getDate() -7);
        optPeriod.fromDate = this.parseDateTimeToFormat(date1);
        optPeriod.toDate= this.parseDateTimeToFormat(date2);
    }

    //Desde el dia 1 del mes hasta hoy
    public actualMonth(optPeriod: SelectPeriod){
        let date1: Date= new Date();
        let date2: Date= new Date();
        date1.setDate(1);
        optPeriod.fromDate = this.parseDateTimeToFormat(date1);
        optPeriod.toDate= this.parseDateTimeToFormat(date2);
    }


    //Desde el dia 1 del mes 1 hasta hoy
    public actualYear(optPeriod: SelectPeriod){
        let date1: Date= new Date();
        let date2: Date= new Date();
        date1.setMonth(0, 1);
        optPeriod.fromDate = this.parseDateTimeToFormat(date1);
        optPeriod.toDate= this.parseDateTimeToFormat(date2);
    }

    //Segun el mes que se le pase por parametro calcula el periodo
    //desde el dia 1 del mes elegido hasta el dia "cero" del mes siguiente
    //Dia "cero" toma la 23:59:59 evitando tener que calcular si es
    //mes de 28/29/30/31 dias
    public month(optPeriod: SelectPeriod) {
        let months = [];
        months["jan"] = 0;
        months["feb"] = 1;
        months["mar"] = 2;
        months["apr"] = 3;
        months["may"] = 4;
        months["jun"] = 5;
        months["jul"] = 6;
        months["aug"] = 7;
        months["sep"] = 8;
        months["oct"] = 9;
        months["nov"] = 10;
        months["dec"] = 11;

        let fromDate: Date = new Date();
        fromDate.setDate(1);
        fromDate.setMonth(months[optPeriod.period]);

        let toDate = new Date(fromDate.getFullYear(), fromDate.getMonth()+1, 0);

        optPeriod.fromDate = this.parseDateTimeToFormat(fromDate);
        optPeriod.toDate= this.parseDateTimeToFormat(toDate);
    }


    ///Manejo de config de balance en local storage
    public getShowBalanceConfig() : boolean {
        let item = localStorage.getItem("balance_config");
        let showBalance: boolean = JSON.parse(item);
        return showBalance;
    }
    public setShowBalanceConfig(showBalance: boolean) : void {
        localStorage.setItem("balance_config", JSON.stringify(showBalance));
    }
}