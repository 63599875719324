import { Component, OnInit, Input } from '@angular/core';

import {OrdenService} from '../orden.service';
import {GlobalVars} from '../../services/globalVars';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {OrdenDePagoGeneral} from '../models';
import {Proveedor} from '../../module-proveedor/models';
import {Factura, NotaDeCredito} from '../../module-factura/models';
 
@Component({
  selector: 'notas',
  templateUrl: './notas.component.html'
})
export class NotasComponent extends GenericFormComponent implements OnInit {
  @Input() orden: OrdenDePagoGeneral;
  @Input() proveedor: Proveedor;
  public notas: NotaDeCredito[];

  public redondeo: number = 0;

  //FORM
  public notasLibres: NotaDeCredito[] = [];
  public nota: NotaDeCredito = null;
    
  constructor(protected messageService: MessageService, private ordenService: OrdenService, private globalVars: GlobalVars) {
    super(messageService);
  }

  ngOnInit() {
    this.redondeo = this.globalVars.config.redondeo;
    this.filter();
    this.cargarNotasLibres();    
  }

  public cargarNotasLibres() {
    this.nota = null;
    this.ordenService.getNotas({page: 1, per_page: 1000, distrito: this.globalVars.getActualDistrito()}, {moneda_eq: this.orden.moneda, proveedor_id_eq: this.proveedor.id, orden_pago_id_eq: 'null', sort: 'fecha,desc'}).subscribe(
      (rta: any) => {
        this.notasLibres = rta.data.filter(n => n.ordenpago == null);
      },
    );
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    this.cursorWait();
    this.ordenService.getNotasOrden(this.orden.id).subscribe(
        (notas: NotaDeCredito[]) => {
            this.cursorDefault();
            this.notas = notas;
        },
        error => {
            this.cursorDefault();
        }
    );
  }
  
  //ACCIONES
  public agregarNota(event?){
    let nota = this.nota;
    if (this.nota) {
        this.beforeSubmit(event);
        this.ordenService.agregarNota(this.orden.id, nota).subscribe(
            (nota: NotaDeCredito) => {
                this.showSuccess(true, 'Nota de credito agregada correctamente');
                this.orden.actualizar(nota.ordenpago);
                this.cargarNotasLibres();
                this.notas.splice(0, 0, nota);
            },err => {
                this.showError(err, true);
            });
    }
  }

  onNotifyDelete(nota: NotaDeCredito):void {
    this.deleteNota(nota);
  }
    
  public deleteNota(nota: NotaDeCredito) {
    this.beforeSubmitList();
    this.ordenService.liberarNota(this.orden.id, nota).subscribe(
        (orden: OrdenDePagoGeneral) => {
            this.showSuccessList(true, 'Nota de credito liberada correctamente');
            //ELIMINO DE LA LISTA      
            this.notas.forEach(function(notaFor, index, object) {
                if(notaFor.id == nota.id) {
                    object.splice(index, 1);
                }
            });
            //this.orden.pagado = Number((this.orden.pagado - nota.monto).toFixed(2));
            this.orden.actualizar(orden);
            this.cargarNotasLibres();
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }
  
}