<div class="wrapper">
    <div id="top" *ngIf="user"></div>

    <div class="sidebar" data-background-color="olive" data-active-color="primary" *ngIf="user">
        <sidebar-cmp></sidebar-cmp>
    </div>
    <div [ngClass]="user == null ? 'main-panel-logout' : 'main-panel'" id='main-panel-home'>
        <navbar-cmp [hidden]="!user"></navbar-cmp>
        
        <div class="main-content" id='main-content'>
            <router-outlet ></router-outlet>        
        </div>

        <footer-cmp *ngIf="user"></footer-cmp>
    </div>
</div>    

<facturas_modal></facturas_modal>
<factura_modal></factura_modal>

<viaticos_modal></viaticos_modal>
<viatico_modal></viatico_modal>

<orden_modal></orden_modal>
<orden_venta_modal></orden_venta_modal>

<proveedores_modal></proveedores_modal>
<proveedor_modal></proveedor_modal>

<cheque_modal></cheque_modal>

<facturas_ventas_modal></facturas_ventas_modal>
<factura_venta_modal></factura_venta_modal>