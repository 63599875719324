import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';

//import 'rxjs/add/operator/toPromise';
import { Observable ,  Subject } from 'rxjs';
// Import RxJs required methods
import { map, catchError } from 'rxjs/operators';

import { GlobalVars } from '../services/globalVars';
import { Cache } from '../services/cache';

import {Recibo} from '../module-orden-venta/models';

@Injectable()
export class ReciboService{
    private reciboUrl = 'facturacion/recibos';

    constructor(private http: HttpClient, private globalVars: GlobalVars, private cache: Cache) {        
    }
        
    getRecibos(params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.reciboUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new Recibo(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    getRecibo(id): Observable<Recibo> {
        const url= `${this.globalVars.apiHost}${this.reciboUrl}/${id}`;
        return this.http.get<Recibo>(url, this.globalVars.getOptionsRequest()).pipe(
            map((recibo: Recibo) => {
                return new Recibo(recibo);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createRecibo(recibo: Recibo): Observable<Recibo> {
        const url = `${this.globalVars.apiHost}${this.reciboUrl}`;
        let body= JSON.parse(JSON.stringify(recibo));
        return this.http.post<Recibo>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((recibo: Recibo) => {
                return new Recibo(recibo);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
     
    updateRecibo(recibo: Recibo): Observable<Recibo> {
        const url = `${this.globalVars.apiHost}${this.reciboUrl}/${recibo.id}`;
        let body= JSON.parse(JSON.stringify(recibo));        
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((recibo: Recibo) => {
                return new Recibo(recibo);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    deleteRecibo(id: number): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.reciboUrl}/${id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }
}