import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild } from '@angular/core';

import {ViaticoService} from '../viatico.service';
import { MessageService } from '../../services/message.service';

import {Periodo} from '../models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';

@Component({
  selector: 'periodos_form',
  templateUrl: 'periodos_form.html'
})
export class PeriodosFormComponent extends GenericFormComponent implements OnInit, AfterViewInit{
    @ViewChild('firstElementPeriodo', { static: true }) firstElement: ElementRef;
    
    @Input() periodo: Periodo= new Periodo();
    
    @Output() actualize: EventEmitter<Periodo> = new EventEmitter<Periodo>();
    @Output() cancel: EventEmitter<string> = new EventEmitter<string>();

    public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Períodos', ref: '#/periodos' }];
    
    constructor(protected messageService: MessageService, private viaticoService: ViaticoService) {
        super(messageService);
    }

    ngOnInit(): void {
        if(this.periodo?.id) {
            this.breadcrumbItems.push({ title: `${this.periodo.nombre}`, active: true });
        }
    }
    ngAfterViewInit(): void {
        this.firstElement.nativeElement.focus();
    }
    
    public save(event){
        this.beforeSubmit(event);
        if(!this.periodo.id){
            this.viaticoService.createPeriodo(this.periodo).subscribe(
                (periodo: Periodo) => {
                    this.showSuccess(true, 'Periodo creado correctamente');
                    this.periodo = periodo;
                    this.actualize.emit(this.periodo);             
                },err => {
                    this.showError(err, true);
                });
        }else{
            this.viaticoService.updatePeriodo(this.periodo).subscribe(
                (periodo: Periodo) => {
                    this.showSuccess(true, 'Periodo modificado correctamente');  
                    this.periodo = periodo;
                    this.actualize.emit(this.periodo);  
                },err => {
                    this.showError(err, true);
                });
        }
    }
    
    public cancelForm($event?){
        this.cancel.emit("Cancelacion");
    }
}