<div class="row">
    <div class="card">
        <div class="card-content">
            <h4 class="card-title">Facturas / Documentos</h4>
            <hr>

            <div class="row">
                <div class="row mt-1">
                    <div class="col-sm-2">
                        <button class="btn btn-simple btn-icon btn-add" *ngIf="!orden.tienePagos() && !orden.anulada" (click)="addDocumento('F')" title="Agregar"><i class="cmp-i agregar"></i> Agregar Factura</button>
                    </div>  
                    <div class="col-sm-2">
                        <button class="btn btn-simple btn-icon btn-add" *ngIf="!orden.tienePagos() && !orden.anulada" (click)="addDocumento('V')" title="Agregar"><i class="cmp-i agregar"></i> Agregar Viático</button>
                    </div>  
                </div>

                <div class="col-md-12 table-responsive mt-1">
                    <table class="table table-hover">
                        <thead class="text-primary">
                            <tr>
                                <th><b>Num. Documento</b></th>
                                <th><b>Total (Monto original)</b></th>
                                <th><b>Imputado</b></th>
                                <th><b>Retención Ganancias</b></th>
                                <th><b>Retención Caja</b></th>
                                <th><b>Acciones</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor='let ordenFactura of ordenes'>
                                <tr>
                                    <td><a (click)="detalleDocumento(ordenFactura)" rel="tooltip" title="Detalle">{{ordenFactura.numero()}}</a></td>
                                    <td>$ {{ordenFactura.montoOriginal() | number: '1.2-2'}}</td>
                                    <td>$ {{ordenFactura.monto | number: '1.2-2'}}</td>
                                    <td>$ {{ordenFactura.retencion | number: '1.2-2'}}</td>
                                    <td>{{ordenFactura.porcentaje_descuentos | number: '1.2-2'}}% - $ {{ordenFactura.descuentos | number: '1.2-2'}}</td>                            
                                    <td class="td-actions text-right">
                                        <button 
                                            [disabled]="orden.verificada"
                                            [title]="orden.verificada ? 'No es posible editar ya que la orden esta verificada.' : 'Editar'" 
                                            type="button" rel="tooltip" title="Editar" class="btn btn-primary btn-simple btn-xs btn-cmp-i" (click)="ordenFactura.editando = true;">
                                            <i class="cmp-i editar i-sm"></i>
                                        </button>
                                        <button *ngIf="! orden.verificada" type="button" rel="tooltip" title="Eliminar" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="ordenFactura" confirm-message="¿Está seguro que desea quitar la factura?">
                                            <i class="cmp-i eliminar-borrar i-sm"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="ordenFactura.editando" style="background-color: #f8dada;">
                                    <td colspan="6">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Monto</label>
                                                    <input type="number" name="monto" [(ngModel)]="ordenFactura.monto" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-md-4" *ngIf="! ordenFactura.viatico">
                                                <div class="form-group">
                                                    <label>Descuento Caja (%)</label>
                                                    <select name="porcentaje_descuentos" [(ngModel)]="ordenFactura.porcentaje_descuentos" class="form-control" required>
                                                        <option *ngFor="let descuento of descuentos" [value]="descuento">{{descuento}}%</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="ordenFactura.id">
                                            <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label class="checkbox-inline"><input type="checkbox" name="retencion_manual" [(ngModel)]="ordenFactura.retencion_manual"> Retención Manual</label>
                                                    </div>
                                                </div>
                                            <div class="col-md-4" *ngIf="ordenFactura.retencion_manual">
                                                <div class="form-group">
                                                    <label>Retención</label>
                                                    <input type="number" name="retencion" [(ngModel)]="ordenFactura.retencion" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row text-center">
                                            <button type="submit" title="Cancelar" class="btn btn-primary btn-cancel" (click)="cancel(ordenFactura)">Cancelar</button>
                                            <button type="submit" title="Guardar" class="btn btn-primary ml-2" [disabled]="submitted" (click)="save(ordenFactura)">Guardar</button>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                            <tr *ngIf="ordenes != null && ordenes.length == 0" class="text-center">
                                <td colspan="6">No hay facturas</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>