import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {ViaticoService} from '../viatico.service';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Periodo} from '../models';
 
@Component({
  selector: 'periodos_detalle',
  templateUrl: './periodos_detalle.component.html'
})
export class PeriodosDetalleComponent extends GenericFormComponent implements OnInit {
  public periodo: Periodo;
  
  constructor(protected messageService: MessageService, private route:ActivatedRoute, private router: Router, private viaticoService: ViaticoService) {
    super(messageService);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
        if(params['id']){
            this.cursorWait();
            this.viaticoService.getPeriodo(params['id']).subscribe(
                (periodo: Periodo) => {
                    this.cursorDefault();
                    this.periodo = periodo;
                }, error => {
                    this.cursorDefault();
                    this.messageService.showNotification('top', 'center', 'danger', 'No se encontro el periodo');
                    this.router.navigate(['periodos']);
                }
            );
        }
    });
  }

}