<div class="card">
    <div class="card-content">
        <h4 class="card-title" *ngIf="!periodo.id">Nuevo Período</h4>
        <hr *ngIf="!periodo.id">

        <breadcrumb [items]="breadcrumbItems" *ngIf="periodo.id"></breadcrumb>

        <form class="form-horizontal" (submit)="save($event)" #datosForm="ngForm">
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Nombre</label>
                        <div class="col-md-9">                                  
                            <input #firstElementPeriodo type="text" name="nombre" [(ngModel)]="periodo.nombre" class="form-control" placeholder="Periodo 11/19" required>
                            <small [hidden]="!inputError.nombre" [innerHtml]="inputError.nombre" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Fecha</label>
                        <div class="col-md-9">      
                            <input-datepicker [(dateModel)]="periodo.fecha" [isStringFormat]="true" [required]="true" ></input-datepicker>
                            <small [hidden]="!inputError.fecha" [innerHtml]="inputError.fecha" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>                    
            </div>

            <div class="row text-center">
                <button class="btn btn-default btn-cancel" type="button" *ngIf="! periodo.id" (confirm-click)="cancelForm($event)" confirm-message="¿Está seguro que desea cancelar este formulario?">Cancelar</button>
                <button class="btn btn-primary ml-2" type="submit" [disabled]="submitted">Guardar</button>
            </div>
            
            <div class="clearfix"></div>
        </form>
    </div>
</div>