<div class="container-fluid">
    <div class="row">
        <div class="card">
            <div class="card-content">
                <breadcrumb [items]="breadcrumbItems"></breadcrumb>
                 
                <form class="form-horizontal" (submit)="save($event)" #datosConfigForm="ngForm" *ngIf="config">
                    <div class="form-group">
                        <div class="col-sm-12">
                            <div class="col-sm-12 col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4">
                                <label for="modulo">Valor del Modulo:</label>                             
                                <input #firstElementConfig type="text" name="modulo" [(ngModel)]="config.modulo" class="form-control" required>
                                <small [hidden]="!inputError.modulo" [innerHtml]="inputError.modulo" class="text-danger"></small>
                            </div>
                        </div>
                        <div class="col-sm-12 mt-2">
                            <div class="col-sm-12 col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4">
                                <label for="redondeo">Redondeo:</label>                              
                                <input #firstElementConfig type="text" name="redondeo" [(ngModel)]="config.redondeo" class="form-control" required>
                                <small [hidden]="!inputError.redondeo" [innerHtml]="inputError.redondeo" class="text-danger"></small> 
                            </div>               
                        </div>
                    </div>

                    <div class="text-center mt-2">
                        <button class="btn btn-primary" type="submit" [disabled]="submitted">Guardar</button>
                    </div>
                    <div class="clearfix"></div>
                </form>
            </div>
        </div>          
    </div>
</div>