import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule }   from '@angular/forms';

import {GenericModule} from '../module-generic/generic.module';
import {TranslateComponentModule} from '../translate-module/translate-component.module';

import {PuntoDeVentaService} from './punto-de-venta.service';

import {PuntoDeVentaComponent} from './punto-de-venta/punto-de-venta.component';
import {PuntoDeVentaFormComponent} from './punto-de-venta-form/punto-de-venta-form.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    
    GenericModule,
    TranslateComponentModule,
  ],
  declarations: [
    PuntoDeVentaComponent,
    PuntoDeVentaFormComponent
  ],
  providers: [
    PuntoDeVentaService
  ],
  exports: [
    PuntoDeVentaComponent,
  ]
})
export class PuntoDeVentaModule { }
