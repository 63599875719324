<div *ngIf="provImpu" class="row">
    <imputaciones_proveedor_form [proveedor]="proveedor" [provImpu]="provImpu" (actualize)="actualizeForm($event)" (cancel)="cancelForm($event)"></imputaciones_proveedor_form>
</div>

<div class="row">
    <div class="card">
        <div class="card-content">
            <h4 class="card-title">Categorías de retención</h4>
            <hr>

            <div class="row mt-2">
                <div class="col-sm-10">
                    <button class="btn btn-simple btn-icon btn-add" *ngIf="! provImpu" (click)="newImputacion()" title="Agregar"><i class="cmp-i agregar"></i> Agregar</button>
                </div>  
            </div>

            <div class="row">
                <div class="col-md-12 table-responsive">
                    <table class="table table-hover">
                        <thead class="text-primary">
                            <tr>                                
                                <th><b>Imputación</b></th>
                                <th><b>Retención</b></th>
                                <th><b>Acciones</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor='let provImpu of imputacionesProv'>
                                <td><a (click)="updateImputacion(provImpu)" rel="tooltip" title="Editar Imputacion">{{provImpu.imputacion.nombre}}</a></td>
                                <td>
                                    <span *ngIf="provImpu.retencion">Sí</span>
                                    <span *ngIf="! provImpu.retencion">No</span>
                                </td>
                                <td class="td-actions text-right">
                                    <button type="button" rel="tooltip" title="Editar Imputación" class="btn btn-primary btn-simple btn-xs btn-cmp-i" (click)="updateImputacion(provImpu)">
                                        <i class="cmp-i editar i-sm"></i>
                                    </button>
                                    <button type="button" rel="tooltip" title="Eliminar Imputación" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="provImpu" confirm-message="¿Está seguro que desea eliminar la Imputación?">
                                        <i class="cmp-i eliminar-borrar i-sm"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr *ngIf="imputacionesProv != null && imputacionesProv.length == 0" class="text-center">
                                <td colspan="3">No se encontraron imputaciones</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>    
</div>