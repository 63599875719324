
import {first} from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef, NgZone, AfterViewChecked, AfterViewInit, AfterContentInit, AfterContentChecked } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {environment} from '../../../environments/environment';

import {FacturaService} from '../factura.service';
import {DistritoService} from '../../module-distrito/distrito.service';
import {ProveedorService} from '../../module-proveedor/proveedor.service';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Factura, ImputacionContable} from '../models';
import {User} from '../../models/user';
import {Distrito} from '../../module-distrito/models';
import {Proveedor} from '../../module-proveedor/models';
import Sort from 'app/models/Sort';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
import { FilterCacheUtilsService } from 'app/services/filter-cache-utils.service';
import { FilterCache } from 'app/models/filter-cache';
 
declare var $: any;
const VIEW_ID = "facturas";

@Component({
  selector: 'facturas',
  templateUrl: './facturas.component.html'
})
export class FacturasComponent extends GenericFormComponent implements OnInit {
  public actualUser: User;
    
  public facturas: Factura[];

  public sortOptions: Sort[] = [
    { key: 'fecha', order: 'desc', description: 'Fecha' },
    { key: 'monto', order: 'desc', description: 'Monto' }
  ]

  public params = {page: 1, per_page: 10};

  private filtroDefault = {
      search_elastic: null, 
      tipo: null, 
      estado: null, 
      fecha_gteq: null, 
      fecha_lteq: null, 
      imputacion_contable_id: null, 
      distrito_id: null, 
      punto_venta: null, 
      numero: null, 
      monto_gteq: null, 
      monto_lteq: null, 
      sort: this.sortOptions[0]
    };
  public filtro = Object.assign({},this.filtroDefault);
  public someFiltersApplied: boolean = false;
  
  public tipos: string[] = environment.tiposFactura;
  public estados: string[] = environment.estadosFactura;
  public cant_listado: number[] = environment.cant_listado;
  public imputacionesContables: ImputacionContable[] = [];
  public distritos: Distrito[] = [];
  public proveedores: Proveedor[] = [];

  public fechas = null;

  public STATUS_STYLE_CLASS = { A: 'status-sucess', P: 'status-warning', R: 'status-danger' };
  public STATUS_ICON = { A: 'ti-check', P: 'ti-time', R: 'ti-na' };

  public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Facturas', active: true }];

  @ViewChild('fac_select_imputaciones', { static: true }) selectImputaciones: ElementRef;
  @ViewChild('search_elastic', { static: true }) searchElastic: ElementRef;
  
  // @ViewChild('fac_select_proveedores') selectProveedores: ElementRef;
    
  constructor(protected messageService: MessageService, private globalVars: GlobalVars, private facturaService: FacturaService, private distritoService: DistritoService,
      private ngZone: NgZone, private proveedorService: ProveedorService, private route: ActivatedRoute, private router: Router, private filterCacheUtilsService: FilterCacheUtilsService) {
    super(messageService);
  }

  ngOnInit() {
    this.actualUser = this.globalVars.actualUser;
    this.filtro.distrito_id = this.globalVars.getActualDistrito();
    this.loadCacheFiltersParams();
    this.filter();
    this.facturaService.getImputacionesContables({page: 1, per_page: 1000}, {sort: 'nombre'}).subscribe(
        rta => {
            this.imputacionesContables = rta.data;
            this.ngZone.onStable.pipe(first()).subscribe(() => {
                $(this.selectImputaciones.nativeElement).selectpicker(); 
            });
        }
    );
    this.distritoService.getDistritos({per_page: 1000}, {sort: 'nombre'}).subscribe(
        (rta: any) => {
            this.distritos = rta.data;
        }
    );
    // this.proveedorService.getProveedores({page: 1}, {sort: 'nombre'}).subscribe(
    //     rta => {
    //         this.proveedores = rta.data;
    //         this.ngZone.onStable.first().subscribe(() => {
    //             $(this.selectProveedores.nativeElement).selectpicker(); 
    //         });
    //     }
    // );
    setTimeout(() => {this.searchElastic.nativeElement.focus()}, 100);
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    this.route.queryParams.subscribe(params => {
        if(this.fechas != 'fechacambiado'){

          if((params['fecha_gteq'] !== undefined) &&
            (params['fecha_gteq'] != null) && (params['fecha_gteq'] != "undefined")){
              this.filtro.fecha_gteq = params['fecha_gteq'];
          }

          if((params['fecha_lteq'] !== undefined) &&
            (params['fecha_lteq'] != null) && (params['fecha_lteq'] != "undefined")){
              this.filtro.fecha_lteq = params['fecha_lteq'];
          }
        } else {
          this.fechas = null;
        }

        if(params['estado'] == 'pagadas'){
          console.log(params['estado']);
          this.filtro.estado = params['estado'];
        }
        if(params['estado'] == 'sinpagos'){
          console.log(params['estado']);
          this.filtro.estado = params['estado'];
        }
        if(params['estado'] == 'pagoparcial'){
          this.filtro.estado = params['estado'];
        }
    });

    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.cursorWait();    
    this.filtro.numero = this.filtro.numero == '' ? null : this.filtro.numero;
    this.filtro.punto_venta = this.filtro.punto_venta == '' ? null : this.filtro.punto_venta;
    this.someFiltersApplied = this.globalVars.isSomeFilterApplied(this.filtro);
    this.facturaService.getFacturas(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.facturas = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
            this.filterCacheUtilsService.setFilterParams(VIEW_ID, this.filtro, this.params);
        },
        error => {
            this.cursorDefault();
        }
    );
  }
  
  public changeFilterDate(value){
    this.filtro.fecha_gteq = value.from;
    this.filtro.fecha_lteq = value.to;
    this.fechas = 'fechacambiado';
    this.filter(1);
  }
  
  //ACCIONES
  changeState(factura: Factura, estado: string, event){
    this.beforeSubmitList(event);
    this.facturaService.cambiarEstadoFactura(factura, estado).subscribe(
        rta => {
            this.showSuccessList(true, 'Factura modificado correctamente');
            factura.estado = estado;
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }

  onNotifyDelete(factura: Factura):void {
    this.deleteFactura(factura);
  }
    
  public deleteFactura(factura: Factura) {
    this.beforeSubmitList();
    this.facturaService.deleteFactura(factura.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Factura eliminada correctamente');
            //ELIMINO DE LA LISTA      
            this.facturas.forEach(function(facturaFor, index, object) {
                if(facturaFor.id == factura.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }

  public generarOrden(factura){
        this.beforeSubmit();
        this.facturaService.createOrdenFactura(factura.id).subscribe(
            (orden) => {
                this.showSuccess(true, 'Orden generada correctamente');;
                this.router.navigate(['/ordenespago', orden.id]);   
            },err => {
                this.showError(err, true);
            });
    }

  private loadCacheFiltersParams() {
    let cacheFilters: FilterCache = this.filterCacheUtilsService.getFilterParams(VIEW_ID);
    if (!cacheFilters) return;

    this.filtro = cacheFilters.filters;
    this.params = cacheFilters.params;
  }

  public resetFilters() {
    this.filtro = Object.assign({},this.filtroDefault);
    this.filtro.distrito_id = this.globalVars.getActualDistrito();
    this.filter();
  }
}