import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';

//import 'rxjs/add/operator/toPromise';
import { Observable ,  Subject } from 'rxjs';
// Import RxJs required methods
import { map, catchError } from 'rxjs/operators';

import { GlobalVars } from '../services/globalVars';
import { Cache } from '../services/cache';

import {Distrito} from './models';
import { Cuenta } from 'app/module-cuenta/models';

@Injectable()
export class DistritoService{
    private distritosUrl = 'distritos';
    
    public distritos: Distrito[] = [];

    constructor(private http: HttpClient, private globalVars: GlobalVars, private cache: Cache) {        
    }
    
    loadDistritos(): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.distritosUrl}?per_page=1000`;        
        return this.http.get<any>(url, this.globalVars.getOptionsRequest()).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new Distrito(item));
                this.distritos = rta.data;
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    getLoadDistrito (id: number) {
        for (let distrito of this.distritos) {
            if (distrito.id == id) {
                return distrito;
            }
        }
        this.globalVars.setActualDistrito(this.distritos[0].id);
        return this.distritos[0];
    }
    
    
    getDistritos(params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.distritosUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new Distrito(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    getDistrito(id): Observable<Distrito> {
        const url= `${this.globalVars.apiHost}${this.distritosUrl}/${id}`;
        return this.http.get<Distrito>(url, this.globalVars.getOptionsRequest()).pipe(
            map((distrito: Distrito) => {
                return new Distrito(distrito);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createDistrito(distrito: Distrito): Observable<Distrito> {
        const url = `${this.globalVars.apiHost}${this.distritosUrl}`;
        let body= JSON.parse(JSON.stringify(distrito));    
        return this.http.post<Distrito>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((distrito: Distrito) => {
                return new Distrito(distrito);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
     
    updateDistrito(distrito: Distrito): Observable<Distrito> {
        const url = `${this.globalVars.apiHost}${this.distritosUrl}/${distrito.id}`;
        let body= JSON.parse(JSON.stringify(distrito));
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((distrito: Distrito) => {
                return new Distrito(distrito);
            }), catchError(this.globalVars.tratarErrores)
        );
    }    
      
    deleteDistrito(id: number): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.distritosUrl}/${id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }

    getCuentaCajaChicaDistrito(id): Observable<Cuenta> {
        const url= `${this.globalVars.apiHost}${this.distritosUrl}/${id}/cajachica`;
        return this.http.get<Cuenta>(url, this.globalVars.getOptionsRequest()).pipe(
            map((cuenta: Cuenta) => {
                return new Cuenta(cuenta);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
}