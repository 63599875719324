import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild } from '@angular/core';

import {UserService} from '../user.service';
import {DistritoService} from '../../module-distrito/distrito.service';
import {GlobalVars} from '../../services/globalVars';
import { MessageService } from '../../services/message.service';

import {User, Rol} from '../../models/user';
import {Distrito} from '../../module-distrito/models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

@Component({
  selector: 'users_form',
  templateUrl: 'users_form.html'
})
export class UsersFormComponent extends GenericFormComponent implements OnInit, AfterViewInit{
    @ViewChild('firstElementUser', { static: true }) firstElement: ElementRef;
    public actualUser: User;
    
    @Input() user: User= new User();
    public distritos: Distrito[] = [];
    public roles: Rol[] = [];
    
    @Output() actualize: EventEmitter<User> = new EventEmitter<User>();
    @Output() cancel: EventEmitter<string> = new EventEmitter<string>();
    
    constructor(protected messageService: MessageService, private userService: UserService, private distritoService: DistritoService, private globalVars: GlobalVars) {
        super(messageService);
    }

    ngOnInit(): void {
        this.actualUser = this.globalVars.actualUser;
        
        this.distritoService.getDistritos({per_page: 1000}, {sort: 'nombre'}).subscribe(
            (rta: any) => {
                this.distritos = rta.data;
            }
        );
        this.userService.getRoles().subscribe(
            (roles: Rol[]) => {
                this.roles = roles;
            }
        );
    }
    ngAfterViewInit(): void {
        this.firstElement.nativeElement.focus();
    }
    
    public save(event){
        this.beforeSubmit(event);
        if(!this.user.id){
            this.userService.createUser(this.user).subscribe(
                (user: User) => {
                    this.showSuccess(true, 'Usuario creado correctamente');
                    this.user = user;
                    this.actualize.emit(this.user);             
                },err => {
                    this.showError(err, true);
                });
        }else{
            this.userService.updateUser(this.user).subscribe(
                (user: User) => {
                    this.showSuccess(true, 'Usuario modificado correctamente');  
                    this.user = user;
                    this.actualize.emit(this.user);  
                },err => {
                    this.showError(err, true);
                });
        }
    }
    
    public cancelForm($event?){
        this.cancel.emit("Cancelacion");
    }
    
    //
    //UTILS
    public customCompareDistrito(d1: Distrito, d2: Distrito) {
        if(d1 && d2){
            return d1.id == d2.id;
        }else{
            return d1 == d2;
        }
    }
    
    public changeRol(rol: Rol){
        if(this.user.hasRol(rol)){
            this.user.removeRol(rol);
        }else{
            this.user.addRol(rol);
        }
    }
}