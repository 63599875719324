<div class="container-fluid">    
    <div class="row">
        <div class="card">
            <div class="card-content">
                <breadcrumb [items]="breadcrumbItems"></breadcrumb>
            
                <filters-header-section (onFilter)="filter(1)" [advancedFilters]="true" [someFilterApllied]="someFiltersApplied" (onClear)="resetFilters()">
                    <div searchElastic>
                        <div class="col-sm-12">
                            <div class="col-sm-12 col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4">
                                <label for="search_elastic">Buscador:</label>
                                <div class="input-group filter-input-group">
                                    <input #search_elastic class="form-control" type="text" placeholder="Punto de Venta / Número / Proveedor" name="search_elastic" [(ngModel)]="filtro.search_elastic" (keydown.enter)="filter(1)" autofocus>
                                    <span class="input-group-addon cursor-pointer" (click)="filter(1)"><i class="cmp-i busqueda i-sm"></i></span>
                                </div>
                            </div> 
                        </div> 
                    </div>
                    <div class="col-sm-12">  
                        <div class="col-sm-12 col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4">
                            <label for="distrito_id">Distrito:</label>
                            <select class="form-control filter-form-control col-md-12" data-style="btn btn-wd btn-block" name="distrito_id"  [(ngModel)]="filtro.distrito_id" (change)="filter(1)" [disabled]="! actualUser.multi_distrito">
                                <option [ngValue]="null">Todos</option>
                                <option *ngFor="let distrito of distritos" [ngValue]="distrito.id">{{distrito.nombre}}</option>
                            </select>
                        </div>         
                    </div>        
                    
                    <div moreFilters>
                        <div class="col-md-12">
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="imputacion_contable_id">Imputaciones:</label>
                                <select #fac_select_imputaciones class="form-control filter-form-control selectpicker custom-live-search" data-live-search="true" data-style="btn btn-wd btn-block" 
                                    name="imputacion_contable_id" [(ngModel)]="filtro.imputacion_contable_id" (change)="filter(1)">
                                    <option [ngValue]="null">Todos</option>
                                    <option *ngFor="let imputacion of imputacionesContables" [ngValue]="imputacion.id">{{imputacion.nombre}}</option>
                                </select>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="tipo">Tipo:</label>
                                <select class="form-control filter-form-control" data-style="btn btn-wd btn-block" name="tipo" [(ngModel)]="filtro.tipo" (change)="filter(1)">
                                    <option [ngValue]="null">Todos</option>
                                    <option *ngFor="let tipo of tipos" [ngValue]="tipo">{{tipo | translate: "TIPO_FACTURA"}}</option>
                                </select>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="estado">Estado:</label>
                                <select class="form-control filter-form-control" data-style="btn btn-wd btn-block" name="estado" [(ngModel)]="filtro.estado" (change)="filter(1)">
                                    <option [ngValue]="null">Todos</option>
                                    <option *ngFor="let estado of estados" [ngValue]="estado">{{estado | translate: "FACTURA_EST"}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-12" style="margin-top: 10px;">
                            <!-- <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <select #fac_select_proveedores class="form-control filter-form-control selectpicker custom-live-search" data-live-search="true" data-style="btn btn-wd btn-block" name="proveedor_id" [(ngModel)]="filtro.proveedor_id_eq" (change)="filter(1)">
                                    <option [ngValue]="null">Todos los Proveedores</option>
                                    <option *ngFor="let proveedor of proveedores" [ngValue]="proveedor.id">{{proveedor.nombre}}</option>
                                </select>
                            </div> -->                                                
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="punto_venta">Punto de Venta:</label>
                                <div class="form-group ordenar filter-form-control">
                                    <input type="text" class="form-control" placeholder="Punto de Venta" name="punto_venta" [(ngModel)]="filtro.punto_venta">
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="numero">Nro Comprobante:</label>
                                <div class="form-group ordenar filter-form-control">
                                    <input type="text" class="form-control" placeholder="Nro Comprobante" name="numero" [(ngModel)]="filtro.numero">
                                </div>
                            </div>
 
                        </div>
                        <div class="col-md-12" style="margin-top: 10px;">                                           
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="monto_gteq">Monto Mínimo:</label>
                                <div class="form-group ordenar filter-form-control">
                                    <input type="number" class="form-control" placeholder="Monto Mínimo" name="monto_gteq" [(ngModel)]="filtro.monto_gteq" min="0">
                                    <div class="text-danger" *ngIf="filtro.monto_gteq && filtro.monto_lteq && filtro.monto_gteq >= filtro.monto_lteq">
                                        El monto mínimo tiene que ser menor que el monto máximo
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="monto_lteq">Monto Máximo:</label>
                                <div class="form-group ordenar filter-form-control">
                                    <input type="number" class="form-control" placeholder="Monto Máximo" name="monto_lteq" [(ngModel)]="filtro.monto_lteq" min="0">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <generic_period (outDate)="changeFilterDate($event)" [fromDate]="filtro.fecha_gteq" [toDate]="filtro.fecha_lteq" [enableCleanOption]="true"></generic_period>
                        </div>         
                    </div>
                                    
                                    
                </filters-header-section> 


                <div class="row mt-2">
                    <div class="col-sm-10">
                        <a class="btn btn-simple btn-icon btn-add" [routerLink]="['/facturas/new']" title="Agregar"><i class="cmp-i agregar"></i> Agregar</a>
                    </div>  
                    <div class="col-sm-2">
                        <sort-select-structured [options]="sortOptions" [(sortModel)]="filtro.sort" (onSelect)="filter(1)"></sort-select-structured>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 table-responsive">
                        <table class="table table-hover">
                            <thead class="text-primary">
                                <tr>
                                    <th><b>Número</b></th>
                                    <th><b>Proveedor</b></th>
                                    <th><b>Fecha</b></th>
                                    <th><b>Tipo</b></th>
                                    <th><b>Monto / IVA</b></th>
                                    <th><b>Ordenes de pago por</b></th>
                                    <th><b>Moneda</b></th>
                                    <th><b>Imputación</b></th>
                                    <th><b>Ordenes Pago</b></th>
                                    <!-- <th><b>Estado</b></th> -->
                                    <th><b>Acciones</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let factura of facturas'>
                                    <td><a [routerLink]="['/facturas', factura.id]" rel="tooltip" title="Editar Factura">{{factura.punto_venta}} - {{factura.numero}}</a></td>
                                    <td>
                                        <a *ngIf="factura.proveedor" [routerLink]="['/proveedores', factura.proveedor.id]" rel="tooltip" title="Detalle Proveedor" class="link-no-highlight">{{factura.proveedor.nombre}}</a>
                                        <span *ngIf="! factura.proveedor">-</span>
                                    </td>
                                    <td>{{factura.fecha != '0000-00-00 00:00:00' ? (factura.fecha | date: 'dd/MM/yyyy') : '-'}}</td>
                                    <td>{{factura.tipo | translate: 'TIPO_FACTURA'}}</td>
                                    <td>$ {{factura.monto | number: '1.2-2'}} / {{factura.iva | number: '1.2-2'}}</td>
                                    <td>$ {{factura.pagado | number: '1.2-2'}}</td>
                                    <td>{{factura.moneda | translate: 'MONEDA'}}</td>
                                    <td>
                                        <span *ngIf="factura.imputacion_contable">{{factura.imputacion_contable.nombre}}</span>
                                        <span *ngIf="!factura.imputacion_contable">-</span>
                                    </td>
                                    <td>
                                        <div class="dropup">
                                            <span class="badge badge-custom-danger" *ngIf="factura.ordenes.length == 0">0</span>
                                            <a class="dropdown-toggle" *ngIf="factura.ordenes.length > 0 && factura.restaPagar() == 0" id="dropdownOrdenes" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span class="badge badge-custom-success" >{{factura.ordenes.length}}</span>
                                            </a>
                                            <a class="dropdown-toggle" *ngIf="factura.ordenes.length > 0 && factura.restaPagar() > 0" id="dropdownOrdenes" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span class="badge badge-custom-warning" >{{factura.ordenes.length}}</span>
                                            </a>
                                            <ul class="dropdown-menu" aria-labelledby="dropdownOrdenes">
                                                <li *ngFor="let orden of factura.ordenes">
                                                    <a [routerLink]="['/ordenespago', orden.ordenpagogeneral.id]" rel="tooltip" 
                                                        title="Detalle Orden Pago">{{orden.ordenpagogeneral.punto_venta}} - {{orden.ordenpagogeneral.numero}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                   <!--  <td [ngClass]="STATUS_STYLE_CLASS[factura.estado]">
                                        <b>{{factura.estado | translate: "FACTURA_EST"}}</b>
                                        <i class="ml-1" [ngClass]="STATUS_ICON[factura.estado]"></i>
                                    </td> -->
                                    <td class="td-actions text-right">
                                        <!-- <a *ngIf="factura.estado == 'A' && factura.restaPagar() && actualUser.hasAtLeastOneRolByName(['tesorero', 'administrativo'])" [routerLink]="['/ordenespago/new']" [queryParams]="{proveedor: factura.proveedor.id}" rel="tooltip" title="Generar O.P." class="btn btn-primary btn-simple btn-xs">
                                            <i class="ti-panel"></i>
                                        </a> -->
                                        <button *ngIf="factura.estado == 'A' && factura.restaPagar() && actualUser.hasAtLeastOneRolByName(['tesorero', 'administrativo'])" type="button" rel="tooltip" title="Generar Factura" class="btn btn-danger btn-simple btn-xs" (click)="generarOrden(factura)">
                                            <i class="ti-panel"></i>
                                        </button>

                                        <a [routerLink]="['/facturas', factura.id]" rel="tooltip" title="Editar Factura" class="btn btn-primary btn-simple btn-xs btn-cmp-i">
                                            <i class="cmp-i editar i-sm"></i>
                                        </a>
                                        <button *ngIf="factura.estado != 'R' && actualUser.esTesorero()" type="button" rel="tooltip" title="Rechazar Factura" class="btn btn-danger btn-simple btn-xs" (click)="changeState(factura, 'R', $event)">
                                            <i class="ti-close"></i>
                                        </button>
                                        <button *ngIf="factura.estado != 'A' && actualUser.esTesorero()" type="button" rel="tooltip" title="Aprobar Factura" class="btn btn-danger btn-simple btn-xs" (click)="changeState(factura, 'A', $event)">
                                            <i class="ti-check"></i>
                                        </button>
                                        <button type="button" rel="tooltip" title="Eliminar Factura" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="factura" confirm-message="¿Está seguro que desea eliminar la Factura?">
                                            <i class="cmp-i eliminar-borrar i-sm"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="facturas != null && facturas.length == 0" class="text-center">
                                    <td colspan="10">No se encontraron facturas</td>
                                </tr>
                            </tbody>
                        </table>                 
                        <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)" *ngIf="facturas != null && facturas.length > 0"></pager>
                    </div>
                </div>
            </div>
        </div>          
    </div>
</div>