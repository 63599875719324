
<div class="container-fluid">    
    
    <div class="row">
        <div class="card">
            <div class="card-content">
                <breadcrumb [items]="breadcrumbItems"></breadcrumb>

                <form (submit)="save($event)" #datosForm="ngForm">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="col-sm-12 col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4 text-center" [hidden]="factura.id || factura.proveedor">
                                <provider-select [(providerModel)]="factura.proveedor" (onModelChange)="onSelectProvider()"></provider-select>
                            </div>
                            <ng-container *ngIf="factura.proveedor">
                                <h5><strong>Proveedor</strong></h5>
                                <hr>
                                <div class="row mt-1">
                                    <div class="col-md-6 col-lg-3">
                                        <p><strong class="mr-1">Nombre: </strong><a (click)="detalleProveedor(factura.proveedor)" rel="tooltip" title="Detalle">{{factura.proveedor.nombre}}</a></p>
                                    </div>
                                    <div class="col-md-6 col-lg-3">
                                        <p><strong class="mr-1">CUIT: </strong> {{factura.proveedor.cuit | cuitformat}}</p>
                                    </div>
                                    <div class="col-md-6 col-lg-3">
                                        <p><strong class="mr-1">Email: </strong> {{factura.proveedor.email}}</p>
                                    </div>

                                    <div class="col-md-6 col-lg-3" *ngIf="!globalVars.actualUser.multi_distrito">
                                        <p><strong>Distrito:</strong> {{factura.distrito.nombre}}</p>
                                    </div>
    
                                    <div class="col-md-6 col-lg-3" *ngIf="globalVars.actualUser.multi_distrito">
                                        <div class="row">
                                            <p class="col-xs-1 col-md-2"><strong>Distrito:</strong></p>
                                            <div class="col-xs-4 col-md-7 ml-2">
                                                <select class="form-control" data-style="btn btn-wd btn-block" name="distrito" [(ngModel)]="factura.distrito_id" >
                                                    <option *ngFor="let distrito of distritos" [ngValue]="distrito.id">{{distrito.nombre}}</option>
                                                </select>
                                                <small [hidden]="!inputError.distrito_id" [innerHtml]="inputError.distrito_id" class="text-danger"></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-md-12 text-center" *ngIf="!factura.id">
                                        <button type="button" class="btn btn-primary" (click)="factura.proveedor = null">Cambiar Proveedor</button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        
                        <div class="col-md-12 mt-2" [hidden]="!factura.proveedor">
                            <h5><strong>Datos</strong></h5>
                            <hr>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Punto de Venta - Número</label>
                                        <div class="row">
                                            <div class="col-xs-6">
                                                <input type="number" name="punto_venta" [(ngModel)]="factura.punto_venta" class="form-control" placeholder="0001" required="" (blur)="checkNumber('punto_venta', factura.punto_venta)">
                                            </div>
                                            <div class="col-xs-6">
                                                <input type="number" name="numero" [(ngModel)]="factura.numero" class="form-control" placeholder="0000001" required="" (blur)="checkNumber('numero', factura.numero)">
                                            </div>
                                            <div class="col-xs-12">
                                                <small [hidden]="!inputError.punto_venta" [innerHtml]="inputError.punto_venta" class="text-danger"></small>
                                            </div>
                                            <div class="col-xs-12">
                                                <small [hidden]="!inputError.numero" [innerHtml]="inputError.numero" class="text-danger"></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Fecha</label>
                                        <input-datepicker [(dateModel)]="factura.fecha" [isStringFormat]="true" [required]="true"></input-datepicker>
                                        <small [hidden]="!inputError.fecha" [innerHtml]="inputError.fecha" class="text-danger"></small>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label>Tipo de Factura</label>
                                        <select name="tipo" class="form-control" data-style="btn btn-wd btn-block" title="Tipo de Factura" data-size="7" [(ngModel)]="factura.tipo" required="">
                                            <option [ngValue]="null">Seleccione un tipo</option>
                                            <option *ngFor="let tipo of tipos" [ngValue]="tipo">{{tipo | translate: "TIPO_FACTURA"}}</option>
                                        </select>
                                        <small [hidden]="!inputError.tipo" [innerHtml]="inputError.tipo" class="text-danger"></small>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label>Moneda</label>
                                        <select class="form-control" data-style="btn btn-wd btn-block" name="moneda" [(ngModel)]="factura.moneda" [disabled]="factura.tienePagos()">
                                            <option [ngValue]="null">Seleccione una Moneda</option>
                                            <option *ngFor="let moneda of monedas" [ngValue]="moneda">{{moneda | translate: 'MONEDA'}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label style="display: block;">Imputación Contable</label>
                                        <select #select_inputacion_contable class="form-control filter-form-control selectpicker custom-live-search" data-live-search="true" 
                                            data-live-search-placeholder="Buscar Imputacion Contable" name="imputacion_contable" data-style="btn btn-wd btn-block" title="Imputación Contable" 
                                            [(ngModel)]="factura.imputacion_contable" [compareWith]='customCompareImputacionContable'>
                                            <option [ngValue]="null">Ninguna</option>
                                            <option *ngFor="let imputacion of imputacionesContables" [ngValue]="imputacion">{{imputacion.nombre}}</option>
                                        </select>
                                        <small [hidden]="!inputError.imputacion_contable_id" [innerHtml]="inputError.imputacion_contable_id" class="text-danger"></small>
                                    </div>
                                </div>
    
    
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Monto</label>                      
                                        <input type="number" name="monto" [(ngModel)]="factura.monto" class="form-control" placeholder="1000" [disabled]="factura.tienePagos()" required (blur)="checkNumber('monto', factura.monto)">
                                        <small [hidden]="!inputError.monto" [innerHtml]="inputError.monto" class="text-danger"></small>                          
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Fecha Vencimiento</label>
                                        <input-datepicker [(dateModel)]="factura.fecha_vencimiento" [isStringFormat]="true" [required]="true"></input-datepicker>
                                        <small [hidden]="!inputError.fecha_vencimiento" [innerHtml]="inputError.fecha_vencimiento" class="text-danger"></small>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Fecha Contable</label>
                                        <input-datepicker [(dateModel)]="factura.fecha_contable" [isStringFormat]="true" [required]="true"></input-datepicker>
                                        <small [hidden]="!inputError.fecha_contable" [innerHtml]="inputError.fecha_contable" class="text-danger"></small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>IVA</label>
                                        <select name="iva" [(ngModel)]="factura.iva" class="form-control" [disabled]="factura.tienePagos()" required>
                                            <option *ngFor="let iva of ivas" [value]="iva">{{iva}}%</option>
                                        </select>
                                        <small [hidden]="!inputError.iva" [innerHtml]="inputError.iva" class="text-danger"></small>                       
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label style="display: block;">Categoría de Retención</label>
                                        <select name="proveedor_imputacion" class="form-control" data-style="btn btn-wd btn-block" name="proveedor_imputacion" [(ngModel)]="factura.proveedor_imputacion" [compareWith]='customCompareImputacion' (change)="actualizarRetencion()" [disabled]="factura.tienePagos()">
                                            <option [ngValue]="null">Ninguna</option>
                                            <option *ngFor="let imputacion of imputaciones" [ngValue]="imputacion">{{imputacion.imputacion.nombre}}</option>
                                        </select>
                                        <small [hidden]="!inputError.proveedor_imputacion_id" [innerHtml]="inputError.proveedor_imputacion_id" class="text-danger"></small>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Retención Ganancias (%)</label>
                                        <input type="number" name="retencion" [ngModel]="factura.retencion" class="form-control" placeholder="0" disabled="disabled">
                                        <!--<small class="text-info">Solo aplica cuando se supere el monto mensual indicado por la imputacion.</small> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row mt-2">
                        <div class="row text-center" *ngIf="factura.proveedor">
                            <button class="btn btn-primary" type="submit" *ngIf="!factura.id" 
                                [disabled]="submitted || !factura.punto_venta || !factura.numero || !factura.fecha || !factura.tipo || !factura.moneda || factura.monto <= 0 || factura.iva < 0 || !factura.proveedor || !factura.distrito_id">
                                Crear Factura
                            </button><!-- 6 -->
                            <button class="btn btn-primary" type="submit" *ngIf="factura.id" [disabled]="submitted">Actualizar Factura</button>
                            <button class="btn btn-primary ml-1" type="button" *ngIf="factura.estado == 'A' && factura.restaPagar() != 0" (click)="generarOrden()" [disabled]="submitted">Generar Orden de Pago</button><!-- 7 -->
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <productos *ngIf="factura.id" [factura]="factura"></productos>

    <div class="row" *ngIf="false">
        <div class="card">
            <div class="card-content">
                <h4 class="card-title">Importe / Tasas</h4>    
                <hr>                
                <div class="row">
<!--                    <div class="col-md-4">
                        <form (submit)="saveRetencion($event)" #datosForm2="ngForm" *ngIf="factura.id &&  !factura.tienePagos()">
                            <div class="row">                        
                                <div class="col-md-12">
                                    <div class="row">                          
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Monto Retencion</label>                               
                                                <input type="number" name="monto_retencion" [(ngModel)]="factura.monto_retencion" class="form-control" required >
                                                <small [hidden]="!inputError.monto_retencion" [innerHtml]="inputError.monto_retencion" class="text-danger"></small>                          
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <button class="btn btn-primary pull-right" type="submit" [disabled]="submitted">Modificar</button>
                                </div>
                            </div>
                        </form>
                    </div>-->
                    
                    <div class="col-md-4 col-md-offset-8">
                        <p><strong>Retención Ganancias: </strong> $ {{factura.monto_retencion | number: '1.2-2'}}</p>
                        <p><strong>Monto:</strong> $ {{factura.monto | number: '1.2-2'}}</p>
                    </div>
                </div>
            </div>
        </div> <!-- end card -->
    </div>

    <notas *ngIf="factura.id" [factura]="factura"></notas>

    <ordenes_factura *ngIf="factura.id" [factura]="factura"></ordenes_factura>

</div>


