<div class="card">
    <div class="card-content">
        <h4 class="card-title">{{!user.id ? 'Nuevo Usuario' : 'Modificación de Usuario'}}</h4>
        <hr>
        
        <form class="form-horizontal" (submit)="save($event)" #datosForm="ngForm">
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Nombre de Usuario</label>
                        <div class="col-md-9">                                  
                            <input #firstElementUser type="text" name="username" [(ngModel)]="user.username" class="form-control" placeholder="juan_perez_89" required>
                            <small [hidden]="!inputError.username" [innerHtml]="inputError.username" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Email</label>
                        <div class="col-md-9">                                 
                            <input type="email" name="email" [(ngModel)]="user.email" class="form-control" placeholder="juan@sistema.com" required>
                            <small [hidden]="!inputError.email" [innerHtml]="inputError.email" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>                    
            </div>

            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Nombre</label>
                        <div class="col-md-9">
                            <input type="text" name="nombre" [(ngModel)]="user.nombre" class="form-control" placeholder="Juan Perez" required>
                            <small [hidden]="!inputError.nombre" [innerHtml]="inputError.nombre" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Distrito</label>
                        <div class="col-md-9">
                            <select class="form-control" data-style="btn btn-wd btn-block" name="distrito" [(ngModel)]="user.distrito" [compareWith]='customCompareDistrito' [disabled]="!actualUser.multi_distrito">
                                <option [ngValue]="null">Seleccione un Distrito</option>
                                <option *ngFor="let distrito of distritos" [ngValue]="distrito">{{distrito.nombre}}</option>
                            </select>
                            <small [hidden]="!inputError.distrito_id" [innerHtml]="inputError.distrito_id" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Contraseña</label>
                        <div class="col-md-9">
                            <input type="password" name="password" [(ngModel)]="user.password" class="form-control" placeholder="password" [required]="!user.id">
                            <small [hidden]="!inputError.password" [innerHtml]="inputError.password" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Repita Contraseña</label>
                        <div class="col-md-9">
                            <input type="password" name="password" [(ngModel)]="user.confPassword" class="form-control" placeholder="repita password" [required]="!user.id">
                            <small [hidden]="!inputError.confPassword" [innerHtml]="inputError.confPassword" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Opciones</label>
                        <div class="col-md-9">
                            <div class="checkbox">
                                <input type="checkbox" name="habilitado" value="1" [(ngModel)]="user.habilitado" checked>
                                <label>Habilitado</label>
                            </div>
<!--                            <div class="checkbox">
                                <input type="checkbox" name="cobra_viatico" value="1" [(ngModel)]="user.cobra_viatico" checked>
                                <label>Cobra Viatico</label>
                            </div>-->
                            <div class="checkbox">
                                <input type="checkbox" name="multi_distrito" value="1" [(ngModel)]="user.multi_distrito" checked [disabled]="!actualUser.multi_distrito">
                                <label>Multi Distrito</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Roles</label>
                        <div class="col-md-9">
                            <div class="checkbox" *ngFor="let rol of roles">
                                <input type="checkbox" name="roles" [checked]="user.hasRol(rol)" (change)="changeRol(rol)"> 
                                <label>{{rol.name.replace('_', ' ')}}</label>
                            </div>
                        </div>
                        <small [hidden]="!inputError.roles" [innerHtml]="inputError.roles" class="text-danger"></small>
                    </div>
                </div>
            </div>

            <div class="row text-center">
                <button class="btn btn-default btn-cancel" type="button" (confirm-click)="cancelForm($event)" confirm-message="¿Está seguro que desea cancelar este formulario?">Cancelar</button>
                <button class="btn btn-primary ml-2" type="submit" [disabled]="submitted">Guardar</button>
            </div>
            <div class="clearfix"></div>
        </form>
    </div>
</div>