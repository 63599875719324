import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';

import {UserService} from '../user.service';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {User, PermisoViatico} from '../../models/user';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
 
@Component({
  selector: 'permisos',
  templateUrl: './permisos.component.html'
})
export class PermisosComponent extends GenericFormComponent implements OnInit {
  public user: User = new User();
  public permisos: PermisoViatico[];
  public params = {};
  public filtro = {user_id_eq: null};
  //FORM
  public permiso: PermisoViatico;

  public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }];
    
  constructor(protected messageService: MessageService, private userService: UserService, private route: ActivatedRoute, private router: Router) {
    super(messageService);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
        if(params['id']){
            this.cursorWait();
            this.userService.getUser(params['id']).subscribe(
                (user: User) => {
                    this.cursorDefault();
                    this.user = user;
                    this.filtro.user_id_eq = this.user.id;
                    this.filter();
                    this.breadcrumbItems.push({title: `Permisos usuario: ${this.user.username}`, active: true })
                }, error => {
                    this.cursorDefault();
                    this.messageService.showNotification('top', 'center', 'danger', 'No se encontro el usuario');
                    this.router.navigate(['usuarios']);
                }
            );
        }
    });
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    this.cursorWait();
    this.userService.getPermisosViaticos(this.params, this.filtro).subscribe(
        (permisos: PermisoViatico[]) => {
            this.cursorDefault();
            this.permisos = permisos;
        },
        error => {
            this.cursorDefault();
        }
    );
  }
  
  //ACCIONES  
  onNotifyDelete(permiso: PermisoViatico):void {
    this.deletePermiso(permiso);
  }
    
  public deletePermiso(permiso: PermisoViatico) {
    this.beforeSubmitList();
    this.userService.deletePermisoViatico(permiso.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Permiso eliminado correctamente');
            //ELIMINO DE LA LISTA      
            this.permisos.forEach(function(permisoFor, index, object) {
                if(permisoFor.id == permiso.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }
  
    
  //FORMULARIO ALTA/EDICION
  //Para el Form
  public newPermiso(){
    this.permiso = new PermisoViatico();
    this.permiso.user = this.user;
  }
  
  //Desde el formulario
  public actualizeForm(permiso): void {
    this.permisos.splice(0, 0, permiso);
    this.cancelForm();
  }    
  public cancelForm(event?): void{
    this.permiso = null;
  }
  
}