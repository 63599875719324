import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule }   from '@angular/forms';

import {GenericModule} from '../module-generic/generic.module';
import {TranslateComponentModule} from '../translate-module/translate-component.module';
import {CuentaModule} from '../module-cuenta/cuenta.module';

import {OrdenService} from './orden.service';
import {OrdenCommunicate} from './orden.communicate';

import {OrdenesComponent} from './ordenes/ordenes.component';
import {OrdenesFormComponent} from './ordenes_form/ordenes_form.component';
import {OrdenesFacturasComponent} from './ordenes_facturas/ordenes_facturas.component';
import {MovimientosComponent} from './movimientos/movimientos.component';
import {MovimientosFormComponent} from './movimientos_form/movimientos_form.component';
import {NotasComponent} from './notas/notas.component';
import {DeudasRetencionComponent} from './deudas_retencion/deudas_retencion.component';

import {OrdenModalComponent} from './orden_modal/orden_modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    
    GenericModule,
    TranslateComponentModule,
    CuentaModule,
  ],
  declarations: [
    OrdenesComponent,
    OrdenesFormComponent,
    OrdenesFacturasComponent,
    MovimientosComponent,
    MovimientosFormComponent,
    NotasComponent,
    DeudasRetencionComponent,

    OrdenModalComponent
  ],
  providers: [
    OrdenService,
    OrdenCommunicate
  ],
  exports: [
    OrdenesComponent,
    OrdenesFormComponent,

    OrdenModalComponent
  ]
})
export class OrdenModule { }
