import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule }   from '@angular/forms';

import {GenericModule} from '../module-generic/generic.module';
import {TranslateComponentModule} from '../translate-module/translate-component.module';
import {CuentaModule} from '../module-cuenta/cuenta.module';

import {ProveedorService} from './proveedor.service';
import {ProveedorCommunicate} from './proveedor.communicate';

import {ProveedoresComponent} from './proveedores/proveedores.component';
import {ProveedoresFormComponent} from './proveedores_form/proveedores_form.component';
import {ProveedoresDetalleComponent} from './proveedores_detalle/proveedores_detalle.component';
import {ImputacionesProveedorComponent} from './imputaciones_proveedor/imputaciones_proveedor.component';
import {ImputacionesProveedorFormComponent} from './imputaciones_proveedor_form/imputaciones_proveedor_form.component';
import {ImputacionesComponent} from './imputaciones/imputaciones.component';
import {ImputacionesFormComponent} from './imputaciones_form/imputaciones_form.component';
import {ImputacionesContableProveedorComponent} from './imputaciones_contable_proveedor/imputaciones_contable_proveedor.component';
import {ImputacionesContableProveedorFormComponent} from './imputaciones_contable_proveedor_form/imputaciones_contable_proveedor_form.component';

import {FacturasComponent} from './facturas/facturas.component';
import {ViaticosComponent} from './viaticos/viaticos.component';
import {OrdenesComponent} from './ordenes/ordenes.component';
import {NotasComponent} from './notas/notas.component';

import {ProveedoresModalComponent} from './proveedores_modal/proveedores_modal.component';
import {ProveedorModalComponent} from './proveedor_modal/proveedor_modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    
    GenericModule,
    TranslateComponentModule,
    CuentaModule,
  ],
  declarations: [
    ProveedoresComponent,
    ProveedoresFormComponent,
    ProveedoresDetalleComponent,
    ImputacionesProveedorComponent,
    ImputacionesProveedorFormComponent,
    ImputacionesContableProveedorComponent,
    ImputacionesContableProveedorFormComponent,
    ImputacionesComponent,
    ImputacionesFormComponent,
    
    FacturasComponent,
    ViaticosComponent,
    OrdenesComponent,
    NotasComponent,
    
    ProveedoresModalComponent,
    ProveedorModalComponent
  ],
  providers: [
    ProveedorService,
    ProveedorCommunicate
  ],
  exports: [
    ProveedoresComponent,
    ProveedoresDetalleComponent,
    ImputacionesComponent,
    
    ProveedoresModalComponent,
    ProveedorModalComponent
  ]
})
export class ProveedorModule { }
