import { Component, OnInit, Input } from '@angular/core';

import {OrdenService} from '../../module-orden/orden.service';
import {OrdenCommunicate} from '../../module-orden/orden.communicate';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Proveedor} from '../models';
import {OrdenDePagoGeneral} from '../../module-orden/models';
 
@Component({
  selector: 'ordenes_proveedor',
  templateUrl: './ordenes.component.html'
})
export class OrdenesComponent extends GenericFormComponent implements OnInit {
  @Input() proveedor: Proveedor;
  public ordenes: OrdenDePagoGeneral[];
  public params = {page: 1, per_page: 10};
  public filtro = {distrito_id: null, proveedor_id: null, fecha_gteq: null, fecha_lteq: null, sort: { key: 'fecha', order: 'desc', description: 'Fecha' }};
    
  constructor(protected messageService: MessageService, private ordenService: OrdenService,
    private ordenCommunicate: OrdenCommunicate, private globalVars: GlobalVars) {
    super(messageService);
  }

  ngOnInit() {
    this.filtro.proveedor_id = this.proveedor.id;
    this.filtro.distrito_id = this.globalVars.getActualDistrito();
    this.filter();
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.cursorWait();
    this.ordenService.getOrdenes(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.ordenes = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
        },
        error => {
            this.cursorDefault();
        }
    );
  }
    
  public changeFilterDate(value){
    this.filtro.fecha_gteq = value.from;
    this.filtro.fecha_lteq = value.to;
    this.filter(1);
  }
  
  //ACCIONES    
  onNotifyDelete(orden: OrdenDePagoGeneral):void {
    this.deleteOrden(orden);
  }
    
  public deleteOrden(orden: OrdenDePagoGeneral) {
    this.beforeSubmitList();
    this.ordenService.deleteOrden(orden.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Orden eliminada correctamente');
            //ELIMINO DE LA LISTA      
            this.ordenes.forEach(function(ordenFor, index, object) {
                if(ordenFor.id == orden.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }

  public detalleOrden(orden: OrdenDePagoGeneral) {
    this.ordenCommunicate.ordenModalPopUp(orden.id);
  }
}