<div class="row mt-2">
    <div class="filter-section">            
        <div class="col-sm-12 col-md-offset-2 col-md-8 col-lg-offset-3 col-lg-6">
            <label for="cuenta_sel">Cuenta:</label>
            <ng-select 
                class="custom-ng-select"
                [items]="cuentas"
                [(ngModel)]="params.cuenta" 
                name="cuenta_sel"
                placeholder="Seleccione una cuenta"
                (ngModelChange)="changeCuenta()"
            >
                <ng-template ng-label-tmp let-item="item">
                    <span [class]="'mr-2 bank-logo bank-logo-' + item.banco_id" *ngIf="BANK_LOGO_IDS.includes(item.banco_id)"></span>
                    <b>{{item.nombre}}</b>
                    <i class="ml-1">{{item.descripcion?.length > 0 ? '(' + item.descripcion + ') ' : ''}}</i>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <span [class]="'bank-logo bank-logo-' + item.banco_id" *ngIf="BANK_LOGO_IDS.includes(item.banco_id)"></span>
                    <span style="margin-left: 100px;" *ngIf="!BANK_LOGO_IDS.includes(item.banco_id)"></span>
                    <b class="ml-2 ">{{item.nombre}} </b>
                    <i class="ml-1">{{item.descripcion?.length > 0 ? '(' + item.descripcion + ') ' : ''}}</i>
                </ng-template>
            </ng-select>  
        </div>
        <div class="col-sm-12 col-md-offset-2 col-md-8 col-lg-offset-3 col-lg-6 mt-1">
            <label for="chequera_id_eq">Chequera:</label>
            <select class="form-control" data-style="btn btn-wd btn-block" name="chequera_id_eq" [(ngModel)]="filtro.chequera_id_eq" (change)="changeCuenta()">
                <option [ngValue]="null">Seleccione una Chequera</option>
                <option *ngFor="let chequera of chequeras" [ngValue]="chequera.id">{{chequera.numero}} ({{chequera.numero_desde}} - {{chequera.numero_hasta}})</option>
            </select>
        </div>                           
        <div class="col-sm-12 col-md-offset-2 col-md-8 col-lg-offset-3 col-lg-6 mt-1">
            <label for="orderBy">Ordenar por:</label>
            <select class="form-control" data-style="btn btn-wd btn-block" name="orderBy" [(ngModel)]="filtro.sort">
                <option value="numero,desc">Número descendente</option>
                <option value="numero,asc">Número ascendente</option>
            </select>
        </div>

        <div class="col-md-12 search-filter mt-2">
            <div class="col-sm-12 col-md-offset-2 col-md-4">
                <custom-btn-loader
                    text="Descargar Pdf"
                    icon="cmp-i descargar-pdf"
                    [loading]="loading && params.excel == 0"
                    [disabled]="!params.cuenta || !filtro.chequera_id_eq || loading"
                    (action)="pdfCheques(0)"
                    btnClass="btn btn-info btn-icon btn-block like btn-add"
                ></custom-btn-loader> 
            </div>
            <div class="col-sm-12 col-md-4">
                <custom-btn-loader
                    text="Descargar Excel"
                    icon="cmp-i descargar-excel"
                    [loading]="loading && params.excel == 1"
                    [disabled]="!params.cuenta || !filtro.chequera_id_eq || loading"
                    (action)="pdfCheques(1)"
                    btnClass="btn btn-info btn-icon btn-block like btn-add"
                ></custom-btn-loader> 
            </div>
        </div>
    </div>

    <div class="col-sm-6">

        
    </div>
</div>
