import { Component, OnInit } from '@angular/core';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
import { GenericFormComponent } from 'app/module-generic/components/generic-form.component';
import { AfipService } from 'app/services/afip.service';
import { MessageService } from 'app/services/message.service';
import { CertStates } from './afip.config.model';

@Component({
  selector: 'afip-config',
  templateUrl: './afip-config.component.html',
  styleUrls: ['./afip-config.component.sass']
})

export class AfipConfigComponent extends GenericFormComponent implements OnInit {
  public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Configuración', active: true }, {title: 'Afip', active: true }];
  public certExpirationDate: Date;
  public certStatus: CertStates;
  public states = CertStates;

  
  constructor(protected messageService: MessageService, private afipService: AfipService) {
    super(messageService);
  }

  ngOnInit(): void {
   this.afipService.getCertificateExpirationDate().subscribe(
    expDate => {
      this.certExpirationDate = new Date(expDate);
      this.certStatus = this.checkStatus(this.certExpirationDate);
    },err => {
      this.showErrorList(err.error, true);
    });

  }

  private checkStatus(date: Date): CertStates {
    let today: Date = new Date();
    let nextMonth: Date = new Date(new Date().setMonth(today.getMonth() + 1));

    if(date.getTime() > nextMonth.getTime())
      return CertStates.active
    else if (date.getTime() > today.getTime())
      return CertStates.closeToexpire
    else 
      return CertStates.expired
  }
}
