<div class="logo">
    <a class="simple-text logo-mini">
    <div class="logo-img">
        <img src="assets/img/angular2-logo-white.png"/>
    </div>
    </a>

    <a [routerLink]="['/']" class="simple-text logo-normal">
        Compass ERP
    </a>
</div>

<div class="sidebar-wrapper">

	<!--<div class="user">
        <div class="photo">
            <img src="../assets/img/faces/face-2.jpg" />
        </div>
        <div class="info">
            <a data-toggle="collapse" href="#collapseExample" class="collapsed">
                <span>
                    Chet Faker
                    <b class="caret"></b>
                </span>
            </a>
            <div class="clearfix"></div>

            <div class="collapse" id="collapseExample">
                <ul class="nav">
                    <li>
                        <a href="#profile">
                            <span class="sidebar-mini">Mp</span>
                            <span class="sidebar-normal">My Profile</span>
                        </a>
                    </li>
                    <li>
                        <a href="#edit">
                            <span class="sidebar-mini">Ep</span>
                            <span class="sidebar-normal">Edit Profile</span>
                        </a>
                    </li>
                    <li>
                        <a href="#settings">
                            <span class="sidebar-mini">S</span>
                            <span class="sidebar-normal">Settings</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>-->
    <div *ngIf="isNotMobileMenu() && user">
<!--        <form class="navbar-form navbar-left navbar-search-form" role="search">
            <div class="input-group">
                <span class="input-group-addon"><i class="cmp-i busqueda i-sm"></i></span>
                <input type="text" value="" class="form-control" placeholder="Search...">
            </div>
        </form>-->
        <ul class="nav nav-mobile-menu">
            <li>
                <a [routerLink]="['/perfil']" class="dropdown-toggle btn-magnify" data-toggle="dropdown" title="Perfil">
                  <i class="cmp-i usuario" style="background-color: #FFFFFF;"></i>
                   Mi Perfil
                </a>
            </li>
            <li class="dropdown" *ngIf="distrito">
                <a class="dropdown-toggle btn-magnify" [ngClass]="{'disabled': ! user.multi_distrito}" data-toggle="dropdown">
                    <i class="ti-angle-down"></i>
                    <p>Distrito: {{distrito.nombre}}</p>
                </a>
                <ul class="dropdown-menu">
                    <li *ngFor="let distri of distritos">
                        <a (click)="selectDistrito(distri)">
                            <b *ngIf="distrito.id == distri.id">-> </b>{{distri.nombre}}
                        </a>
                    </li>
                </ul>
            </li>
            
            <li class="dropdown">
                <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                    <i class="ti-angle-down"></i>
                    <p>Panel de {{getActualPanel() | translate: 'PANEL'}}</p>
                </a>
                <ul class="dropdown-menu">
                    <li *ngFor="let panel of paneles">
                        <a (click)="selectPanel(panel)">
                            <b *ngIf="getActualPanel() == panel">-> </b>{{panel | translate: 'PANEL'}}
                        </a>
                    </li>
                </ul>
            </li>
            
            <li>
                <a href="#" class="dropdown-toggle btn-rotate" data-toggle="dropdown" (click)="cleanAllCache()" title="Limpiar Cache">
                   <i class="cmp-i borrar-cache" style="background-color: #FFFFFF;"></i>
                   <p class="hidden-lg hidden-md">Borrar Cache</p>
                </a>
            </li>
            <li>
                <a href="#" class="dropdown-toggle btn-rotate" data-toggle="dropdown" (click)="closeSession()" title="Cerrar Sesion">
                   <i class="cmp-i cerrar-sesion" style="background-color: #FFFFFF;"></i>
                   <p class="hidden-lg hidden-md">Cerrar Sesion</p>
                </a>
            </li>
        </ul>
        <hr>
    </div>
    <ul class="nav">
        <!-- <li class="active" *ngIf="! user">
            <a [routerLink]="['/login']">
                <i class="ti-unlock"></i>
                <p>Login</p>
            </a>
        </li> -->

        <li routerLinkActive="active" *ngFor="let menuitem of menuItems">
            <!--If is a single link-->
            <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'">
                <i class="{{menuitem.icontype}}" style="background-color: #FFFFFF;"></i>
                <p>{{menuitem.title}}</p>
            </a>
            <!--If it have a submenu-->
            <a data-toggle="collapse" href="#{{menuitem.title}}" *ngIf="menuitem.type === 'sub'">
                <i class="{{menuitem.icontype}}"></i>
                <p>{{menuitem.title}}<b class="caret"></b></p>
            </a>

            <!--Display the submenu items-->
            <div id="{{menuitem.title}}" class="collapse" *ngIf="menuitem.type === 'sub'">
                <ul class="nav">
                    <li routerLinkActive="active" *ngFor="let childitem of menuitem.children">
                        <a [routerLink]="[menuitem.path, childitem.path]">
                            <span class="sidebar-mini">{{childitem.ab}}</span>
                            <span class="sidebar-normal">{{childitem.title}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
</div>
