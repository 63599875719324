import {Component, OnInit, AfterViewInit } from '@angular/core';

import {ReportesService} from '../reportes.service';
import {CuentaService} from '../../module-cuenta/cuenta.service';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';
import {PdfService} from '../../services/pdf.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Cuenta, Chequera} from '../../module-cuenta/models';
import { environment } from 'environments/environment';


@Component({
  selector: 'reporte_cheques',
  templateUrl: './reporte-cheques.component.html'
})
export class ReporteChequesComponent extends GenericFormComponent implements OnInit {
  public params = {excel: 0, cuenta: null};
  public filtro = {chequera_id_eq: null, sort: 'numero,asc'};
    
  public cuentas: Cuenta[] = [];
  public chequeras: Chequera[] = [];
  public BANK_LOGO_IDS = environment.bank_logo_ids;
  public loading: boolean = false;

  constructor(protected messageService: MessageService, private globalVars: GlobalVars, private reportesService: ReportesService, 
      private pdfService: PdfService, private cuentaService: CuentaService) {
    super(messageService);
  }

  ngOnInit() {
    this.cuentaService.getCuentas({per_page: 1000}, {tipo_eq: 'BANCO', distrito_id_eq: this.globalVars.getActualDistrito(), sort: 'nombre'}).subscribe(
        rta => {
            this.cuentas = rta.data;
        }
    );
  }

  public changeCuenta() {
    if(this.params.cuenta == null) {
      this.filtro.chequera_id_eq = null;
      this.filtro.sort = 'numero,asc';
      return;
    }
    this.cuentaService.getChequeras({per_page: 1000}, {cuenta_id_eq: this.params.cuenta.id, sort: 'numero, desc'}).subscribe(
        rta => {
            this.chequeras = rta.data;
        }
    );
  }

  public pdfCheques(excel: number) {
    if (this.params.cuenta == null) {
      alert("Seleccione una cuenta");
      return;
    }
    this.cursorWait();
    this.params.excel = excel;
    let endpointParams = {
      excel: excel,
      cuenta: this.params.cuenta?.id
    }
    this.loading = true;
    this.reportesService.pdfCheques(endpointParams, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            if (excel) {
                this.pdfService.loadExcel("caja_chica", rta);                
            } else {
                this.pdfService.loadPdf("caja_chica", rta);
            }
            this.loading = false;
        },
        error => {
            this.cursorDefault();
            this.loading = false;
            alert('Ups! No pudimos imprimir el pdf');
        }
    );
  }
}