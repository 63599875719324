<div class="card">
    <div class="card-content">
        <h4 class="card-title">Alta/Edición de Punto De Venta</h4>
        <hr>
        <form class="form-horizontal" (submit)="save($event)" #datosForm="ngForm">
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Punto De Venta</label>
                        <div class="col-md-9">                                  
                            <input #firstElementPuntoDeVenta type="text" name="punto_venta" [(ngModel)]="puntoDeVenta.punto_venta" class="form-control" required>
                            <small [hidden]="!inputError.punto_venta" [innerHtml]="inputError.punto_venta" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>

            <div class="row text-center">
                <button class="btn btn-default btn-cancel" type="button" (confirm-click)="cancelForm($event)" confirm-message="¿Está seguro que desea cancelar este formulario?">Cancelar</button>
                <button class="btn btn-primary" type="submit" [disabled]="submitted">Guardar</button>
    <!--             <a class="btn btn-info btn-fill btn-wd" [routerLink]="['/usuarios']"  [queryParams]="{puntoDeVenta: puntoDeVenta.id}"  *ngIf="puntoDeVenta.id">Ver Usuarios</a> -->

            </div>


            
            <div class="clearfix"></div>
        </form>
    </div>
</div>