import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, NgZone } from '@angular/core';

import {ViaticoService} from '../viatico.service';
import { MessageService } from '../../services/message.service';

import {Viatico, ItemViatico, Periodo, TipoViatico} from '../models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

declare var $: any;

@Component({
  selector: 'items_viatico_form',
  templateUrl: 'items_viatico_form.html'
})
export class ItemsViaticoFormComponent extends GenericFormComponent implements OnInit {
    @Input() viatico: Viatico = new Viatico();
    @Input() item: ItemViatico = new ItemViatico();
    @Input() periodo: Periodo;
    
    @Input() tipo: string = 'K';
    public tipos: TipoViatico[] = [];
    
    @Output() actualize: EventEmitter<ItemViatico> = new EventEmitter<ItemViatico>();
    //@Output() cancel: EventEmitter<string> = new EventEmitter<string>();
    
    @ViewChild('fac_select_tipos') selectTipos: ElementRef;
    
    constructor(protected messageService: MessageService, private viaticoService: ViaticoService, private ngZone: NgZone) {
        super(messageService);
    }

    ngOnInit(): void {
        this.viaticoService.getTipoViaticos(this.periodo.id, {per_page: 1000}, {tipo_eq: this.tipo}).subscribe(
            (rta: any) => {
                this.tipos = rta.data;
                if (this.item.tipo_viatico == null && this.tipos.length) {
                    this.item.tipo_viatico = this.tipos[0];
                    this.item.monto_diario = this.item.tipo_viatico.monto;
                }
                if (this.tipo == 'K') {
                    // this.ngZone.onStable.first().subscribe(() => {
                    //     $(this.selectTipos.nativeElement).selectpicker(); 
                    // });
                }
            }
        );
    }
        
    public save(event){
        if (this.tipo == 'K') {
            const kil = this.tipos.filter(t => Number(t.kilometros_desde) <= Number(this.item.kilometros) && Number(t.kilometros_hasta) >= Number(this.item.kilometros))
            if (kil.length) {
                this.item.tipo_viatico = kil[0];
            } else {
                this.item.tipo_viatico = this.tipos[0];
            }
        }
        if (this.tipo == 'H') {
            if (this.item.monto_diario > this.item.tipo_viatico.monto) {
                this.showError({error: 'El monto diario indicado es mayor al permitido'}, true);
            }
        }
        this.beforeSubmit(event);
        if (! this.item.id) {
            this.viaticoService.createItemViatico(this.item).subscribe(
                (item: ItemViatico) => {
                    this.noShowSuccess();
                    this.item.monto = item.monto;
                    this.item.id = item.id;
                    this.actualize.emit(item);
                },err => {
                    this.showError(err, true);
                });
        } else {
            this.viaticoService.updateItemViatico(this.viatico.id, this.item).subscribe(
                (item: ItemViatico) => {
                    this.noShowSuccess();
                    this.item.monto = item.monto;
                    this.actualize.emit(item);
                },err => {
                    this.showError(err, true);
                });
        }
    }
    
    public cancelForm($event?){
        //this.cancel.emit("Cancelacion");
    }
    
    //
    //UTILS
    public customCompareTipoViatico(t1: TipoViatico, t2: TipoViatico) {
        if(t1 && t2){
            return t1.id == t2.id;
        }else{
            return t1 == t2;
        }
    }
}