import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild } from '@angular/core';

import {FacturaService} from '../factura.service';
import { MessageService } from '../../services/message.service';

import {ImputacionContable} from '../models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';
import { Title } from '@angular/platform-browser';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'imputaciones_form',
  templateUrl: 'imputaciones_form.component.html'
})
export class ImputacionesFormComponent extends GenericFormComponent implements OnInit, AfterViewInit{
    @Input() id: number = null;

    @ViewChild('firstElementImputacion', { static: true }) firstElement: ElementRef;
    
    public imputacion: ImputacionContable= new ImputacionContable();

    public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Imputaciones', ref: '#/imputaciones' }];
    
    constructor(protected messageService: MessageService, private facturaService: FacturaService, 
        private _titleService: Title, private route: ActivatedRoute, private router: Router) {
        super(messageService);
    }

    ngOnInit(): void {
        if (this.id) {
            this.initiaLoad(this.id);
        } else {
            this.route.params.subscribe(
                params => {
                    if (params.id)
                        this.initiaLoad(params.id);
                    else {
                        this.breadcrumbItems.push({title: `Nueva Imputación` , active: true });
                        this._titleService.setTitle(`Nueva Imputación`)
                    }
                }
            );
        }
    }
    ngAfterViewInit(): void {
        this.firstElement.nativeElement.focus();
    }

    private initiaLoad(id) {
        this.facturaService.getImputacionContable(id).subscribe(
            (imputacion: ImputacionContable) => {
                this.imputacion = imputacion;
                this.breadcrumbItems.push({title: `${this.imputacion.print()}` , active: true });
                this._titleService.setTitle(`${this.imputacion.print()}`)
            }, error => {
                this.messageService.showNotification('top', 'center', 'danger', 'No se encontro la imputación');
                this.router.navigate(['imputaciones']);
            }
        );
    }
    
    public save(event){
        this.beforeSubmit(event);
        if(!this.imputacion.id){
            this.facturaService.createImputacionContable(this.imputacion).subscribe(
                (imputacion: ImputacionContable) => {
                    this.showSuccess(true, 'Imputación creada correctamente');
                    this.imputacion = imputacion; 
                    this.router.navigate(['imputaciones']);           
                },err => {
                    this.showError(err, true);
                });
        }else{
            this.facturaService.updateImputacionContable(this.imputacion).subscribe(
                (imputacion: ImputacionContable) => {
                    this.showSuccess(true, 'Imputación modificada correctamente');  
                    this.imputacion = imputacion;
                    this.router.navigate(['imputaciones']); 
                },err => {
                    this.showError(err, true);
                });
        }
    }
    
    public cancelForm($event?){
        this.router.navigate(['imputaciones']);
    }
}