<div class="card">
    <div class="card-content">
        <h4 class="card-title" *ngIf="!cuenta.id">Nueva Cuenta</h4>
        <hr *ngIf="!cuenta.id">
        
        <breadcrumb [items]="breadcrumbItems" *ngIf="cuenta.id"></breadcrumb>

        <form class="form-horizontal" (submit)="save($event)" #datosForm="ngForm">
            <div class="row">
                <div class="col-md-12">
                    <div class="row text-right mr-2">
                        <div class="row">                                
                            <button class="btn btn-simple btn-info btn-icon edit" (click)="onEditAccount()" 
                                title="Editar cuenta" *ngIf="!edit">
                                <i class="cmp-i editar i-md"></i>
                                <label class="label-on-right">Editar</label>
                            </button>                         
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Nombre de Cuenta</label>
                        <div class="col-md-9" *ngIf="!edit">                                  
                            <p>{{cuenta.nombre}}</p>
                        </div>  
                        <div class="col-md-9" *ngIf="edit">                                  
                            <input #firstElementCuenta type="text" name="nombre" [(ngModel)]="cuenta.nombre" class="form-control" placeholder="Cuenta Banco Galicia" [disabled]="!edit" required>
                            <small [hidden]="!inputError.nombre" [innerHtml]="inputError.nombre" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
<!--                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Numero de Cuenta</label>
                        <div class="col-md-9">                                  
                            <input type="number" name="numero_cuenta" [(ngModel)]="cuenta.numero_cuenta" class="form-control" required>
                            <small [hidden]="!inputError.numero_cuenta" [innerHtml]="inputError.numero_cuenta" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>-->

            </div>            
                
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Tipo</label>
                        <div class="col-md-9" *ngIf="!edit">                                  
                            <p>{{cuenta.tipo | translate: 'TIPO_CUENTA'}}</p>
                        </div>  
                        <div class="col-md-9" *ngIf="edit">
                            <select class="form-control" data-style="btn btn-wd btn-block" name="tipo" [(ngModel)]="cuenta.tipo" [disabled]="cuenta.proveedor || cuenta.id || !edit">
                                <option [ngValue]="null">Seleccione un Tipo</option>
                                <option *ngFor="let tipo of tipos" [ngValue]="tipo">{{tipo | translate: 'TIPO_CUENTA'}}</option>
                            </select>
                            <small [hidden]="!inputError.tipo" [innerHtml]="inputError.tipo" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6" *ngIf="cuenta.tipo == 'BANCO'">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Tipo de Cuenta</label>
                        <div class="col-md-9" *ngIf="!edit">                                  
                            <p>{{cuenta.tipo_cuenta}}</p>
                        </div>  
                        <div class="col-md-9" *ngIf="edit">
                            <select class="form-control" data-style="btn btn-wd btn-block" name="tipo_cuenta" [(ngModel)]="cuenta.tipo_cuenta" [disabled]="!edit">
                                <option [ngValue]="null">Seleccione un Tipo de Cuenta</option>
                                <option *ngFor="let tipo of tiposCuenta" [ngValue]="tipo">{{tipo}}</option>
                            </select>
                            <small [hidden]="!inputError.tipo_cuenta" [innerHtml]="inputError.tipo_cuenta" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>                
            </div>
                            
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Descripción</label>
                        <div class="col-md-9" *ngIf="!edit">                                  
                            <p>{{cuenta.descripcion}}</p>
                        </div>  
                        <div class="col-md-9" *ngIf="edit">                                 
                            <input type="text" name="descripcion" [(ngModel)]="cuenta.descripcion" class="form-control" placeholder="Descripción de la cuenta" [disabled]="!edit" required>
                            <small [hidden]="!inputError.descripcion" [innerHtml]="inputError.descripcion" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6" *ngIf="! cuenta.proveedor">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Distrito</label>
                        <div class="col-md-9" *ngIf="!edit">                                  
                            <p>{{cuenta.distrito.nombre}}</p>
                        </div>  
                        <div class="col-md-9" *ngIf="edit">
                            <select class="form-control" data-style="btn btn-wd btn-block" name="distrito" [(ngModel)]="cuenta.distrito" [compareWith]='customCompareDistrito' [disabled]="!actualUser.multi_distrito || !edit">
                                <option [ngValue]="null">Seleccione un Distrito</option>
                                <option *ngFor="let distrito of distritos" [ngValue]="distrito">{{distrito.nombre}}</option>
                            </select>
                            <small [hidden]="!inputError.distrito_id" [innerHtml]="inputError.distrito_id" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>         
            </div>
            
            <div class="form-group" *ngIf="cuenta.tipo == 'BANCO'">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Banco</label>
                        <div class="col-md-9" *ngIf="!edit">                                  
                            <p>{{cuenta.banco.nombre}}</p>
                        </div>  
                        <div class="col-md-9" *ngIf="edit">
                            <select class="form-control" data-style="btn btn-wd btn-block" name="banco" [(ngModel)]="cuenta.banco" [compareWith]='customCompareBanco' [disabled]="!edit">
                                <option [ngValue]="null">Seleccione un Banco</option>
                                <option *ngFor="let banco of bancos" [ngValue]="banco">{{banco.nombre}}</option>
                            </select>
                            <small [hidden]="!inputError.banco_id" [innerHtml]="inputError.banco_id" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">CBU</label>
                        <div class="col-md-9" *ngIf="!edit">          
                            <p>{{(cuenta.cbu != null && cuenta.cbu != '') ? cuenta.cbu : '-'}}</p>
                        </div>  
                        <div class="col-md-9" *ngIf="edit">                                  
                            <input type="text" name="cbu" [(ngModel)]="cuenta.cbu" class="form-control" placeholder="CBU" [disabled]="!edit" required>
                            <small [hidden]="!inputError.cbu" [innerHtml]="inputError.cbu" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="cuenta.tipo == 'BANCO'">
                <div class="col-md-6">
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Alias</label>
                        <div class="col-md-9" *ngIf="!edit">                                  
                            <p>{{(cuenta.alias != null && cuenta.alias != '') ? cuenta.alias : '-'}}</p>
                        </div>  
                        <div class="col-md-9" *ngIf="edit">                                  
                            <input type="text" name="alias" [(ngModel)]="cuenta.alias" class="form-control" placeholder="Alias" [disabled]="!edit" required>
                            <small [hidden]="!inputError.alias" [innerHtml]="inputError.alias" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>
            
            <div class="form-group" *ngIf="cuenta.tipo == 'BANCO'">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Titular</label>
                        <div class="col-md-9" *ngIf="!edit">                                  
                            <p>{{cuenta.titular}}</p>
                        </div>  
                        <div class="col-md-9" *ngIf="edit">                                  
                            <input type="text" name="titular" [(ngModel)]="cuenta.titular" class="form-control" placeholder="Titular" [disabled]="!edit" required>
                            <small [hidden]="!inputError.titular" [innerHtml]="inputError.titular" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">CUIT</label>
                        <div class="col-md-9" *ngIf="!edit">                                  
                            <p>{{(cuenta.cuit != null && cuenta.cuit != '') ? (cuenta.cuit | cuitformat) : '-'}}</p>
                        </div>  
                        <div class="col-md-9" *ngIf="edit">                                  
                            <input type="number" name="cuit" [(ngModel)]="cuenta.cuit" class="form-control" placeholder="Cuit" [disabled]="!edit" required>
                            <small [hidden]="!inputError.cuit" [innerHtml]="inputError.cuit" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>
            
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Opciones</label>
                        <div class="col-md-9">
                            <div class="checkbox">
                                <input type="checkbox" name="habilitado" value="1" [(ngModel)]="cuenta.habilitado" [disabled]="!edit" checked>
                                <label>Habilitado</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6" *ngIf="cuenta.id && cuenta.tipo == 'CAJA_CHICA'">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Saldo</label>
                        <div class="col-md-9">                                 
                            <input type="text" name="saldo" [(ngModel)]="cuenta.monto" class="form-control" disabled="disabled">
                        </div>                            
                    </div>
                </div>
            </div>

            <div class="row text-center" *ngIf="edit">
                <button class="btn btn-danger btn-cancel" type="button" (confirm-click)="cancelForm($event)" confirm-message="¿Está seguro que desea cancelar este formulario?" *ngIf="!cuenta.id">Cancelar</button>
                <button class="btn btn-danger btn-cancel" type="button" (confirm-click)="onCancelEditAccount($event)" confirm-message="Se perderan los cambios realizados ¿Desea cancelar?" *ngIf="cuenta.id">Cancelar</button>
                <button class="btn btn-primary ml-1" type="submit" [disabled]="submitted">Guardar</button>
            </div>
            <div class="clearfix"></div>
        </form>
    </div>
</div>