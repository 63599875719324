import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import {ViaticoService} from '../viatico.service';
import {ViaticoCommunicate} from '../viatico.communicate';
import {GlobalVars} from '../../services/globalVars';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Viatico} from '../models';

declare var $: any;

@Component({
  selector: 'viaticos_modal',
  templateUrl: './viaticos_modal.component.html'
})
export class ViaticosModalComponent extends GenericFormComponent implements OnInit, OnDestroy {
  @ViewChild('viaticosModal', { static: true }) el:ElementRef;
    
  public show: boolean= false;
  public viaticos: Viatico[];
  public params = {page: 1, per_page: 10, esta_pagado: 1};
  public filtro = {numero_matches: null, estado_eq: 'A', proveedor_id_eq: null, distrito_id_eq: null, sort: 'emision,desc'};
  public lastSearch: number = 0;

  public selectAll: boolean = false;
  
  
  //Observables
  private viaticosModal: any;
    
  constructor(protected messageService: MessageService, private globalVars: GlobalVars, private viaticoCommunicate: ViaticoCommunicate, private viaticoService: ViaticoService) {
      super(messageService);
  }

  ngOnInit() {
    this.viaticosModal = this.viaticoCommunicate.viaticosModalPop$.subscribe(
        (info: any) => {
            this.load();
            if ('proveedor' in info) {
                this.filtro.proveedor_id_eq = info['proveedor'];
            } else {
                this.filtro.proveedor_id_eq = null;
            }
            if(!this.globalVars.actualUser.multi_distrito){
               this.filtro.distrito_id_eq = this.globalVars.getActualDistrito();
            }
            this.filter();
        });
        
    $(this.el.nativeElement).on('hidden.bs.modal', (e) => {
        this.show= false;
    });
  }
  
  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.cursorWait();
    this.lastSearch = new Date().getTime() / 1000;
    this.viaticoService.getViaticos(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.viaticos = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
            this.lastSearch = new Date().getTime() / 1000;
        },
        error => {
            this.cursorDefault();
        }
    );
  }

  onChangeSearch(event) {
    if (new Date().getTime() / 1000 > this.lastSearch + 1) {
      this.filter(1);
    }
  }
     
  public seleccionar(){
    let viaticos: Viatico[] = this.viaticos.filter(vic => vic.selectedInTable);
    if (viaticos.length > 0) {
        this.viaticoCommunicate.viaticosSelecModalPopUp(viaticos);
        this.hide();
    }
  } 
  
  //
  //UTILS
  public changeAllSelects(){
    this.selectAll = !this.selectAll;
    this.viaticos.forEach(vic => vic.selectedInTable = this.selectAll);
  }
   
  //MODAL
  public load(){
    this.filtro.numero_matches = '';
    this.show= true;
    $(this.el.nativeElement).modal();
  }
  public hide(){
    $(this.el.nativeElement).modal("hide");
    this.show= false;
  }
  
  //OnDestroy
  ngOnDestroy() {
    this.viaticosModal.unsubscribe();
  }
}