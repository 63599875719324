//import { OpaqueToken } from '@angular/core';

// import translations
import { LANG_ES_NAME, LANG_ES_TRANS } from './lang-es';

// translation token
export const TRANSLATIONS = 'dictionary-translations'//new OpaqueToken('translations');

// all translations
export const dictionary = {
    [LANG_ES_NAME]: LANG_ES_TRANS
};

// providers
// Creo que con esto logramos transformar el dictionary en un servicio(provider) y por lo tanto que se cargue bien una unica vez.
export const TRANSLATION_PROVIDERS = [
    { provide: TRANSLATIONS, useValue: dictionary },
];