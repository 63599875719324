import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild } from '@angular/core';

import {PuntoDeVentaService} from '../punto-de-venta.service';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';

import {PuntoDeVenta} from '../models';
import {Distrito} from '../../module-distrito/models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

@Component({
  selector: 'punto_de_venta_form',
  templateUrl: 'punto-de-venta-form.html'
})
export class PuntoDeVentaFormComponent extends GenericFormComponent implements OnInit, AfterViewInit{
    @ViewChild('firstElementPuntoDeVenta', { static: true }) firstElement: ElementRef;
    
    @Input() puntoDeVenta: PuntoDeVenta= new PuntoDeVenta();
    
    @Output() actualize: EventEmitter<PuntoDeVenta> = new EventEmitter<PuntoDeVenta>();
    @Output() cancel: EventEmitter<string> = new EventEmitter<string>();
    
    constructor(protected messageService: MessageService, private globalVars: GlobalVars, private puntoDeVentaService: PuntoDeVentaService) {
        super(messageService);
    }

    ngOnInit(): void {
        
    }
    ngAfterViewInit(): void {
        this.firstElement.nativeElement.focus();
    }
    
    public save(event){
        this.beforeSubmit(event);
        let distrito: Distrito = new Distrito();
        distrito.id = this.globalVars.getActualDistrito();
        this.puntoDeVenta.distrito = distrito;
        this.puntoDeVenta.distrito_id = this.globalVars.getActualDistrito();
        if(!this.puntoDeVenta.id){
            this.puntoDeVentaService.createPuntoDeVenta(this.puntoDeVenta).subscribe(
                (puntoDeVenta: PuntoDeVenta) => {
                    this.showSuccess(true, 'Punto De Venta creado correctamente');
                    this.puntoDeVenta = puntoDeVenta;
                    this.actualize.emit(this.puntoDeVenta);             
                },err => {
                    this.showError(err, true);
                });
        }else{
            this.puntoDeVentaService.updatePuntoDeVenta(this.puntoDeVenta).subscribe(
                (puntoDeVenta: PuntoDeVenta) => {
                    this.showSuccess(true, 'Punto De Venta modificado correctamente');  
                    this.puntoDeVenta = puntoDeVenta;
                    this.actualize.emit(this.puntoDeVenta);  
                },err => {
                    this.showError(err, true);
                });
        }
    }
    
    public cancelForm($event?){
        this.cancel.emit("Cancelacion");
    }
}