<div class="container-fluid">
    <div *ngIf="distrito" class="row">
        <distritos_form [distrito]="distrito" (actualize)="actualizeForm($event)" (cancel)="cancelForm($event)"></distritos_form>
    </div>
    
    <div class="row">
        <div class="card">
            <div class="card-content">
                <breadcrumb [items]="breadcrumbItems"></breadcrumb>

                <filters-header-section (onFilter)="filter(1)">
                    <div class="col-sm-12 col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4">
                         <!-- <div class="col-md-4">
                            <div class="input-group">
                                <input class="form-control" type="text" placeholder="Búsqueda de usuarios" name="search" [(ngModel)]="filtro.username_matches">
                                <span class="input-group-addon" (click)="filter(1)"><i class="cmp-i busqueda i-sm"></i></span>
                            </div>
                        </div> -->

                        <label for="search">Distrito:</label>
                        <div class="input-group">
                            <input class="form-control" type="text" placeholder="Distrito" name="search" [(ngModel)]="filtro.nombre_matches">
                            <span class="input-group-addon" (click)="filter(1)"><i class="cmp-i busqueda i-sm"></i></span>
                        </div>

                         <!-- <div class="col-md-12 filter-header">
                                <div class="panel panel-border panel-default">
                                    <a data-toggle="collapse" href="#collapseOnePlain" class="collapsed" aria-expanded="false">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">
                                                Filtros
                                                <i class="ti-angle-down"></i>
                                            </h4>
                                        </div>
                                    </a>
                                    <div id="collapseOnePlain" class="panel-collapse collapse" aria-expanded="false" style="height: 0px;">
                                        <div class="panel-body">
                                            <div class="col-md-12">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label class="checkbox-inline"><input type="checkbox" name="enabled" [(ngModel)]="filtro.habilitado_eq" (change)="filter(1)"> Ver solo usuarios habilitados</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <select class="form-control" data-style="btn btn-wd btn-block" name="orderBy"  [(ngModel)]="filtro.sort" (change)="filter(1)">
                                                        <option value="created_at,desc">Ordenar por fecha de creación</option>
                                                        <option value="username">Ordenar por nombre de usuario</option>
                                                        <option value="distrito_id">Ordenar por distrito</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                    </div>
                                    
                                    
                </filters-header-section> 
            
                <div class="row mt-2">
                    <div class="col-sm-12">
                        <button class="btn btn-simple btn-icon btn-add" *ngIf="! distrito" (click)="newDistrito()" title="Agregar"><i class="cmp-i agregar"></i> Agregar</button>
                    </div>  
                </div>

                <div class="row">
                    <div class="col-md-12 table-responsive">
                        <table class="table table-hover">
                            <thead class="text-primary">
                                <tr>
                                    <th><b>Distrito</b></th>
                                    <th><b>Código</b></th>
                                    <th><b>Dirección</b></th>
                                    <th><b>Teléfonos</b></th>
                                    <th><b>Acciones</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let distrito of distritos'>
                                    <td><a (click)="updateDistrito(distrito)" rel="tooltip" title="Editar Distrito">{{distrito.nombre}}</a></td>
                                    <td>{{distrito.codigo}}</td>
                                    <td>{{distrito.direccion}}</td>
                                    <td>{{distrito.telefonos}}</td>
                                    <td class="td-actions text-right">
                                        <button *ngIf="hasAccess(distrito.id)" type="button" rel="tooltip" title="Usuarios del Distrito" class="btn btn-primary btn-simple btn-xs" [routerLink]="['/usuarios']"  [queryParams]="{distrito: distrito.id}">
                                            <i class="ti-user"></i>
                                        </button>
                                        <button [disabled]="!hasAccess(distrito.id)" type="button" rel="tooltip" title="Editar Distrito" class="btn btn-primary btn-simple btn-xs btn-cmp-i" (click)="updateDistrito(distrito)">
                                            <i class="cmp-i editar i-sm"></i>
                                        </button>
                                        <button *ngIf="hasAccess(distrito.id)" type="button" rel="tooltip" title="Eliminar Distrito" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="distrito" confirm-message="¿Está seguro que desea eliminar el Distrito?">
                                            <i class="cmp-i eliminar-borrar i-sm"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="distritos != null && distritos.length == 0" class="text-center">
                                    <td colspan="4">No se encontraron distritos</td>
                                </tr>
                            </tbody>
                        </table>                        
                        <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)" *ngIf="distritos != null && distritos.length > 0"></pager>
                    </div>
                </div>
            </div>
        </div>          
    </div>
</div>