<div class="generic-period-container mt-2">
    <div class="col-sm-12 col-md-6">
        <label for="selectState">Período de tiempo:</label>
        <ng-select 
            class="custom-ng-select"
            [items]="periods"
            bindLabel="placeholder"
            [(ngModel)]="optPeriod.period" 
            bindValue="period"
            name="period_sel"
            placeholder="Seleccione un período"
            (change)="loadPeriod()"
            [clearable]="enableCleanOption"
            (clear)="setEmptyPeriod()"
            [compareWith]="comparePeriods"
            *ngIf="!enableMonthOptions"
        >
        </ng-select> 

        <ng-select 
            class="custom-ng-select"
            [items]="periods"
            bindLabel="placeholder"
            [(ngModel)]="optPeriod.period" 
            bindValue="period"
            name="period_sel"
            placeholder="Seleccione un período"
            (change)="loadPeriod()"
            [clearable]="enableCleanOption"
            (clear)="setEmptyPeriod()"
            [compareWith]="comparePeriods"
            groupBy="group"
            *ngIf="enableMonthOptions"
        >
        </ng-select> 
    </div>
    <div class="col-sm-12 col-md-6">
        <label for="datepicker">Desde / Hasta:</label>
        <div class="input-group">
            <span class="input-group-addon"></span>
            <input #datepicker type="datetime" class="form-control cursor-pointer" placeholder="Seleccione un período">
            <span class="input-group-addon"><i class="cmp-i calendario"></i></span>
        </div>
    </div>
</div>