<div class="card">
    <div class="card-content">
        <h4 class="card-title">{{!metodo.id ? 'Nuevo Método de Pago' : 'Modificación de Método de Pago'}}</h4>
        <hr>
        <form class="form-horizontal" (submit)="save($event)" #datosForm="ngForm">
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Nombre de Método de Pago</label>
                        <div class="col-md-9">                                  
                            <input #firstElementMetodo type="text" name="nombre" [(ngModel)]="metodo.nombre" class="form-control" placeholder="Debito" required>
                            <small [hidden]="!inputError.nombre" [innerHtml]="inputError.nombre" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Descripción</label>
                        <div class="col-md-9">                                 
                            <input type="text" name="descripcion" [(ngModel)]="metodo.descripcion" class="form-control" placeholder="Descripción método de pago" required>
                            <small [hidden]="!inputError.descripcion" [innerHtml]="inputError.descripcion" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>                    
            </div>
            
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Tipo</label>
                        <div class="col-md-9">
                            <select class="form-control" data-style="btn btn-wd btn-block" name="tipo" [(ngModel)]="metodo.tipo">
                                <option [ngValue]="null">Seleccione un Tipo</option>
                                <option *ngFor="let tipo of tipos" [ngValue]="tipo">{{tipo | translate: 'TIPO_METODO'}}</option>
                            </select>
                            <small [hidden]="!inputError.tipo" [innerHtml]="inputError.tipo" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>

            <div class="row text-center">
                <button class="btn btn-default btn-cancel" type="button" (confirm-click)="cancelForm($event)" confirm-message="¿Está seguro que desea cancelar este formulario?">Cancelar</button>
                <button class="btn btn-primary ml-2" type="submit" [disabled]="submitted">Guardar</button>
            </div>
            <div class="clearfix"></div>
        </form>
    </div>
</div>