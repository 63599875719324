import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';

//import 'rxjs/add/operator/toPromise';
import { Observable ,  Subject } from 'rxjs';
// Import RxJs required methods
import { map, catchError } from 'rxjs/operators';

import { GlobalVars } from '../services/globalVars';
import { Cache } from '../services/cache';

import {PuntoDeVenta} from './models';

@Injectable()
export class PuntoDeVentaService{
    private puntoDeVentaUrl = 'facturacion/punto_de_ventas';
    
    public puntoDeVentas: PuntoDeVenta[] = [];

    constructor(private http: HttpClient, private globalVars: GlobalVars, private cache: Cache) {        
    }
    
    getPuntoDeVentas(params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.puntoDeVentaUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new PuntoDeVenta(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    getPuntoDeVenta(id): Observable<PuntoDeVenta> {
        const url= `${this.globalVars.apiHost}${this.puntoDeVentaUrl}/${id}`;
        return this.http.get<PuntoDeVenta>(url, this.globalVars.getOptionsRequest()).pipe(
            map((puntoDeVenta: PuntoDeVenta) => {
                return new PuntoDeVenta(puntoDeVenta);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createPuntoDeVenta(puntoDeVenta: PuntoDeVenta): Observable<PuntoDeVenta> {
        const url = `${this.globalVars.apiHost}${this.puntoDeVentaUrl}`;
        let body= JSON.parse(JSON.stringify(puntoDeVenta));
        return this.http.post<PuntoDeVenta>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((distrito: PuntoDeVenta) => {
                return new PuntoDeVenta(distrito);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
     
    updatePuntoDeVenta(puntoDeVenta: PuntoDeVenta): Observable<PuntoDeVenta> {
        const url = `${this.globalVars.apiHost}${this.puntoDeVentaUrl}/${puntoDeVenta.id}`;
        let body= JSON.parse(JSON.stringify(puntoDeVenta));
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((puntoDeVenta: PuntoDeVenta) => {
                return new PuntoDeVenta(puntoDeVenta);
            }), catchError(this.globalVars.tratarErrores)
        );
    }    
      
    deletePuntoDeVenta(id: number): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.puntoDeVentaUrl}/${id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }
}