<div class="card">
    <div class="card-content">
        <h4 class="card-title">{{!provImpu.id ? 'Nueva Imputación' : 'Modificación de Imputación'}}</h4>
        <hr>

        <form class="form-horizontal" (submit)="save($event)" #datosForm="ngForm">
            <div class="form-group">
                <div class="col-md-12">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Categoría de Retención</label>
                        <div class="col-md-9">
                            <select #firstElementImputacion class="form-control filter-form-control selectpicker custom-live-search" data-live-search="true" data-style="btn btn-wd btn-block" name="imputacion" [(ngModel)]="provImpu.imputacion" [compareWith]='customCompareImputacion'>
                                <option [ngValue]="null">Seleccione una Retención</option>
                                <option *ngFor="let imputacion of imputaciones" [ngValue]="imputacion">{{imputacion.nombre}}</option>
                            </select>
                            <small [hidden]="!inputError.imputacion_id" [innerHtml]="inputError.imputacion_id" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>

<!--            <div class="form-group">
                <div class="col-md-12">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Opciones</label>
                        <div class="col-md-9">
                            <div class="checkbox">
                                <input type="checkbox" name="retencion" value="1" [(ngModel)]="provImpu.retencion" checked>
                                <label>Retiene</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>-->

            <div class="row text-center">
                <button class="btn btn-default btn-cancel" type="button" (confirm-click)="cancelForm($event)" confirm-message="¿Está seguro que desea cancelar este formulario?">Cancelar</button>
                <button class="btn btn-primary ml-2" type="submit" [disabled]="submitted">Guardar</button>
            </div>
            <div class="clearfix"></div>
        </form>
    </div>
</div>