import {Component, OnInit, AfterViewInit } from '@angular/core';

import {OrdenService} from '../orden.service';
import {DistritoService} from '../../module-distrito/distrito.service';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';
import {PdfService} from '../../services/pdf.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {OrdenDeVentaGeneral} from '../models';
import {User} from '../../models/user';
import {Distrito} from '../../module-distrito/models';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
import { FilterCacheUtilsService } from 'app/services/filter-cache-utils.service';
import { FilterCache } from 'app/models/filter-cache';

const VIEW_ID = "ordenesventas";

@Component({
  selector: 'ordenes',
  templateUrl: './ordenes.component.html'
})
export class OrdenesVentasComponent extends GenericFormComponent implements OnInit {
  public actualUser: User;
    
  public ordenes: OrdenDeVentaGeneral[];
  public estadoOrden: number = null;
  public params = {page: 1, per_page: 10, excel: 0, proveedor_search: null};
  public filtroDefault = {or_numero_matches: null, or_punto_venta_matches: null, punto_venta_eq: null, numero_eq: null,
    fecha_gteq: null, fecha_lteq: null, distrito_id_eq: null, finalizada_eq: null, revision_eq: null, verificada_eq: null, anulada_eq: null, sort: 'fecha,desc|numero,desc'};
  public filtro = Object.assign({}, this.filtroDefault);
  public someFiltersApplied: boolean = false;
  
  public distritos: Distrito[] = [];
  
  public sortOptions: { [id: string] : string } = { 'fecha,desc|numero,desc': 'Fecha y número', 'fecha,desc': 'Fecha' };
  public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Ordenes de venta', active: true }];
  
  constructor(protected messageService: MessageService, private ordenService: OrdenService, private globalVars: GlobalVars, private distritoService: DistritoService, 
      private pdfService: PdfService, private filterCacheUtilsService: FilterCacheUtilsService) {
    super(messageService);
  }

  ngOnInit() {
    this.actualUser = this.globalVars.actualUser;
    this.loadInitFilters();
    this.loadCacheFiltersParams();
    this.filter();
    this.distritoService.getDistritos({per_page: 1000}, {sort: 'nombre'}).subscribe(
        (rta: any) => {
            this.distritos = rta.data;
        }
    );
  }

  public changeEstado() {
    if (this.estadoOrden == 0) {
      this.filtro.finalizada_eq = 0;
      this.filtro.revision_eq = 0;
      this.filtro.verificada_eq = 0;
      this.filtro.anulada_eq = 0;
    } else {
      this.filtro.finalizada_eq = this.estadoOrden == 1 ? 1 : null;
      this.filtro.revision_eq = this.estadoOrden == 2 ? 1 : null;
      this.filtro.verificada_eq = this.estadoOrden == 3 ? 1 : null;
      this.filtro.anulada_eq = this.estadoOrden == 4 ? 1 : null;
    }
    this.filter(1);
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.cursorWait();
    this.filtro.or_punto_venta_matches = this.filtro.or_numero_matches;
    this.params.proveedor_search = this.filtro.or_numero_matches;
    this.filtro.numero_eq = this.filtro.numero_eq == '' ? null : this.filtro.numero_eq;
    this.filtro.punto_venta_eq = this.filtro.punto_venta_eq == '' ? null : this.filtro.punto_venta_eq;
    this.someFiltersApplied = this.globalVars.isSomeFilterApplied(this.filtro);
    this.ordenService.getOrdenes(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.ordenes = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
            this.filterCacheUtilsService.setFilterParams(VIEW_ID, this.filtro, this.params);
        },
        error => {
            this.cursorDefault();
        }
    );
  }
    
  public changeFilterDate(value){
    this.filtro.fecha_gteq = value.from;
    this.filtro.fecha_lteq = value.to;
    this.filter(1);
  }
  
  //ACCIONES    
  onNotifyDelete(orden: OrdenDeVentaGeneral):void {
    this.deleteOrden(orden);
  }
    
  public deleteOrden(orden: OrdenDeVentaGeneral) {
    this.beforeSubmitList();
    this.ordenService.deleteOrden(orden.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Orden eliminada correctamente');
            //ELIMINO DE LA LISTA      
            this.ordenes.forEach(function(ordenFor, index, object) {
                if(ordenFor.id == orden.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }

  private loadInitFilters(): void {
    this.filtro.distrito_id_eq = this.globalVars.getActualDistrito();
  }

  private loadCacheFiltersParams() {
    let cacheFilters: FilterCache = this.filterCacheUtilsService.getFilterParams(VIEW_ID);
    if (!cacheFilters) return;

    this.filtro = cacheFilters.filters;
    this.params = cacheFilters.params;
  }

  public resetFilters() {
    this.filtro = Object.assign({}, this.filtroDefault);
    this.loadInitFilters();
    this.filter();
  }
}