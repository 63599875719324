import { Component, OnInit, Input } from '@angular/core';

import {CuentaService} from '../cuenta.service';
import { MessageService } from '../../services/message.service';
import {PdfService} from '../../services/pdf.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Chequera, Cheque} from '../models';
import { Router } from '@angular/router';
 
@Component({
  selector: 'chequeras_cheques',
  templateUrl: './chequeras_cheques.component.html'
})
export class ChequerasChequesComponent extends GenericFormComponent implements OnInit {
  @Input() chequera: Chequera;
  public cheques: Cheque[];
  public params = {page: 1, per_page: 10, cuenta: null, excel: 0};
  //FORM
  public cheque: Cheque;
    
  constructor(protected messageService: MessageService, private cuentaService: CuentaService, private pdfService: PdfService,
    private router: Router) {
    super(messageService);
  }

  ngOnInit() {
    this.filter();
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.cursorWait();
    this.cuentaService.getChequesChequera(this.chequera.id, this.params).subscribe(
        (cheques: Cheque[]) => {
            this.cursorDefault();
            this.cheques = cheques;
        },
        error => {
            this.cursorDefault();
        }
    );
  }
  
  public pdfCheques(excel: number) {
    this.cursorWait();
    this.params.excel = excel;
    this.params.cuenta = this.chequera.cuenta.id;
    this.cuentaService.pdfCheques(this.params, {chequera_id_eq: this.chequera.id}).subscribe(
        rta => {
            this.cursorDefault();
            if (excel) {
                this.pdfService.loadExcel("cheques", rta);                
            } else {
                this.pdfService.loadPdf("cheques", rta);
            }
        },
        error => {
            this.cursorDefault();
            alert('Ups! No pudimos imprimir el pdf');
        }
    );
  }
  
  //ACCIONES    
  onNotifyDelete(cheque: Cheque):void {
    this.deleteCheque(cheque);
  }
    
  public deleteCheque(cheque: Cheque) {
    this.beforeSubmitList();
    this.cuentaService.deleteCheque(cheque.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Cheque eliminado correctamente');
            //ELIMINO DE LA LISTA      
            this.cheques.forEach(function(chequeFor, index, object) {
                if(chequeFor.id == cheque.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }
  
    
  //FORMULARIO ALTA/EDICION
  //Para el Form
  public newCheque(){
    this.cheque = new Cheque();
    this.cheque.chequera = this.chequera;
  }
  public updateCheque(cheque: Cheque) {
    this.router.navigate(['/cheques', cheque.id]);  
  }
  
  //Desde el formulario
  public actualizeForm(cheque: Cheque): void {
    let exists= false;
    this.cheques.forEach(function(chequeFor, index, object) {
        if(chequeFor.id == cheque.id) {
            object[index]= cheque;
            exists= true;
        }
    });
    if(!exists){
        this.cheques.splice(0, 0, cheque);
    }
    this.cancelForm();
  }    
  public cancelForm(event?): void{
    this.cheque = null;
  }
  
}