
<div class="container-fluid">
    
    <div class="row">
        <div class="card">
            <div class="card-content">
                <breadcrumb [items]="breadcrumbItems"></breadcrumb>

                <form (submit)="save($event)" #datosOrdenForm="ngForm">
                    <div class="row">
                        <div class="col-md-12">

                            <div class="col-sm-12 col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4 text-center" [hidden]="orden.id || orden.proveedor">
                                <provider-select [(providerModel)]="orden.proveedor"></provider-select>
                            </div>
                            <ng-container *ngIf="orden.proveedor">
                                <h5><strong>Proveedor</strong></h5>
                                <hr>
                                <div class="row mt-1">
                                    <div class="col-md-3">
                                        <p><strong class="mr-1">Nombre:</strong><a (click)="detalleProveedor(orden.proveedor)" rel="tooltip" title="Detalle">{{orden.proveedor.nombre}}</a></p>
                                    </div>
                                    <div class="col-md-3">
                                        <p><strong>CUIT:</strong> {{orden.proveedor.cuit | cuitformat}}</p>
                                    </div>
                                    <div class="col-md-3">
                                        <p><strong>Email:</strong> {{orden.proveedor.email}}</p>
                                    </div>
                                    <div class="col-md-3" *ngIf="globalVars.actualUser.multi_distrito">
                                        <label for="distrito">Distrito</label>
                                        <div *ngIf="globalVars.actualUser.multi_distrito">
                                            <select class="form-control" data-style="btn btn-wd btn-block" name="distrito" [(ngModel)]="orden.distrito_id" >
                                                <option *ngFor="let distrito of distritos" [ngValue]="distrito.id">{{distrito.nombre}}</option>
                                            </select>
                                            <small [hidden]="!inputError.distrito_id" [innerHtml]="inputError.distrito_id" class="text-danger"></small>
                                        </div>
                                    </div>
                                </div>


                                <div class="row mt-2">
                                    <div class="col-md-12 text-center" *ngIf="!orden.id">
                                        <button type="button" class="btn btn-primary" (click)="orden.proveedor = null">Cambiar Proveedor</button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <div class="col-md-12" *ngIf="orden.proveedor">
                            <h5><strong>Datos</strong></h5>
                            <div class="row">
                                <div class="col-md-3" style="padding-left: 0px;">
                                    <div class="form-group">
                                        <div class="col-md-12">
                                            <label>Número</label>
                                        </div>
                                        <div class="col-md-4">
                                            <select class="form-control" data-style="btn btn-wd btn-block" name="punto_venta" [(ngModel)]="orden.punto_venta" [disabled]="orden.verificada">
                                                <option [ngValue]="1">1</option>
                                                <option [ngValue]="3">3</option>
                                            </select>
                                        </div>
                                        
                                        <div class="col-md-8">
                                            <input type="number" name="numero" [(ngModel)]="orden.numero" class="form-control" placeholder="0000001" disabled="disabled">
                                        </div>
                                        <small [hidden]="!inputError.punto_venta" [innerHtml]="inputError.punto_venta" class="text-danger"></small>
                                        <small [hidden]="!inputError.numero" [innerHtml]="inputError.numero" class="text-danger"></small>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label>Fecha</label>
                                        <input-datepicker [(dateModel)]="orden.fecha" [isStringFormat]="true" [required]="true" [disabled]="orden.verificada"></input-datepicker>
                                        <small [hidden]="!inputError.fecha" [innerHtml]="inputError.fecha" class="text-danger"></small>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label>Moneda</label>
                                        <select class="form-control" data-style="btn btn-wd btn-block" name="moneda" [(ngModel)]="orden.moneda" [disabled]="orden.monto != 0">
                                            <option [ngValue]="null">Seleccione una Moneda</option>
                                            <option *ngFor="let moneda of monedas" [ngValue]="moneda">{{moneda | translate: 'MONEDA'}}</option>
                                        </select>
                                        <small [hidden]="!inputError.moneda" [innerHtml]="inputError.moneda" class="text-danger"></small>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label> </label>
                                            <div class="checkbox">
                                                <input type="checkbox" name="diferido" value="1" [(ngModel)]="orden.multi_proveedor" checked [disabled]="orden.verificada">
                                                <label>Facturas de Varios Proveedores</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Nota de la orden de pago</label>
                                        <input type="text" name="nota" [(ngModel)]="nota" class="form-control" (change)="notaActualizar($event.target.value, nota)" placeholder="Nota de la orden de pago" required>
                                        <small [hidden]="!inputError.nota" [innerHtml]="inputError.nota" class="text-danger"></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row text-center" *ngIf="orden.proveedor">
                        <button class="btn btn-danger" type="button" (confirm-click)="cancel($event)" confirm-message="Se perderan los cambios no guardados. ¿Desea continuar?" *ngIf="!orden.id" [disabled]="submitted">Cancelar</button>
                        <button class="btn btn-primary ml-2" type="submit" *ngIf="!orden.id" [disabled]="submitted || !orden.proveedor">Iniciar Orden</button>
                        <button class="btn btn-primary ml-2" type="submit" *ngIf="orden.id && !orden.verificada" [disabled]="submitted">Actualizar Orden</button>
                        <button class="btn btn-primary ml-2" type="button" *ngIf="orden.estaPagado(redondeo) && !orden.finalizada" (confirm-click)="finalizar($event)" confirm-message="¿Está seguro que desea finalizar la Orden?" [disabled]="submitted">Finalizar Orden</button>
                        <button class="btn btn-primary ml-2" type="button" *ngIf="orden.finalizada && !orden.verificada && !orden.anulada && actualUser.hasAtLeastOneRolByName(['tesorero', 'auditor'])" (confirm-click)="finalizar($event)" confirm-message="¿Está seguro que desea poner en revisión la Orden?" [disabled]="submitted">A Revisión</button>
                        <button class="btn btn-primary ml-2" type="button" *ngIf="orden.finalizada && !orden.verificada && !orden.anulada && actualUser.hasAtLeastOneRolByName(['tesorero', 'auditor'])" (confirm-click)="verificar($event)" confirm-message="¿Está seguro que desea verificar la Orden?" [disabled]="submitted">Verificar Orden</button>
                        <button class="btn btn-primary ml-2" type="button" *ngIf="orden.verificada && actualUser.hasAtLeastOneRolByName(['auditor'])" (confirm-click)="verificar($event)" confirm-message="¿Está seguro que desea volver a pendiente la Orden?" [disabled]="submitted">Sin Verificar</button>
                        <button class="btn btn-danger ml-2" type="button" *ngIf="!orden.finalizada && orden.pagado == 0 && orden.id" (confirm-click)="onNotifyAnular($event)" [confirm-params]="orden" confirm-message="¿Está seguro que desea eliminar la Orden?"[disabled]="submitted">Anular Orden</button>
                        <button type="button" class="btn btn-default ml-2" *ngIf="orden.id && orden.finalizada" (click)="pdfOrden()">Imprimir</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
    
    <ordenes_facturas *ngIf="orden.id" [orden]="orden"></ordenes_facturas>
        
    <div class="row" *ngIf="orden.id">
        <div class="card">
            <div class="card-content">
                <h4 class="card-title">Importe / Tasas</h4>
                <hr>
                                    
                <div class="row">
                    <div class="col-md-4 col-md-offset-8">                        
                        <p><strong>Retención Ganancias: </strong> $ {{orden.retencion | number: '1.2-2'}}</p>
                        <p><strong>Retención Caja:</strong> $ {{orden.descuentos | number: '1.2-2'}}</p>
                        <hr>
                        <p><strong>TOTAL:</strong> $ {{orden.monto | number: '1.2-2'}}</p>
                        <p><strong>A PAGAR: </strong> $ {{orden.monto_descuentos | number: '1.2-2'}}</p>
                        <p><strong>RESTA PAGAR:</strong> $ {{orden.restaPagar()  | number: '1.2-2'}}</p>
                    </div>
                </div>
            </div>
        </div> <!-- end card -->
    </div>
    
    <movimientos_orden *ngIf="orden.id" [orden]="orden" [proveedor]="orden.proveedor"></movimientos_orden>

    <notas *ngIf="orden.id" [orden]="orden" [proveedor]="orden.proveedor"></notas>

</div>


