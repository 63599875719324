import { Component, OnInit, Input } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {environment} from '../../../environments/environment';

import {OrdenService} from '../orden.service';
import {DistritoService} from '../../module-distrito/distrito.service';
import {ProveedorCommunicate} from '../../module-proveedor/proveedor.communicate';
import {ProveedorService} from '../../module-proveedor/proveedor.service';
import {GlobalVars} from '../../services/globalVars';
import { MessageService } from '../../services/message.service';
import {PdfService} from '../../services/pdf.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {OrdenDePagoGeneral} from '../models';
import {Factura} from '../../module-factura/models';
import {Proveedor, ProveedorImputacion} from '../../module-proveedor/models';
import {User} from '../../models/user';
import {Distrito} from '../../module-distrito/models';
import { Title } from '@angular/platform-browser';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';

@Component({
    selector: 'ordenes_form',
    templateUrl: 'ordenes_form.component.html'
})

export class OrdenesFormComponent extends GenericFormComponent implements OnInit {
    @Input() id: number = null;
    public actualUser: User;

    public orden: OrdenDePagoGeneral = new OrdenDePagoGeneral();
    
    public proveedores: Proveedor[] = [];
    
    public monedas: string[] = environment.monedas;
    public descuentos: number[] = environment.descuentosCaja;
    public imputaciones: ProveedorImputacion[] = [];
    public distritos: Distrito[] = [];
    public redondeo: number = 0;

    public nota;
    public INIT_BREADCRUM_ITEMS = [ {title: 'Home', ref: '#' }, {title: 'Ordenes de pago', ref: '#/ordenespago' }];
    public breadcrumbItems: BreadcrumbItems[] = Object.assign([], this.INIT_BREADCRUM_ITEMS);

    
    constructor(protected messageService: MessageService, private ordenService: OrdenService, private distritoService: DistritoService, private pdfService: PdfService,
        private proveedorCommunicate: ProveedorCommunicate, private route: ActivatedRoute, private router: Router, private globalVars: GlobalVars,
        private proveedorService: ProveedorService, private _titleService: Title) {
        super(messageService);
    }
    
    ngOnInit(){
        this.actualUser = this.globalVars.actualUser;
        this.redondeo = this.globalVars.config.redondeo;
        
        this.distritoService.getDistritos({per_page: 1000}, {sort: 'nombre'}).subscribe(
            (rta: any) => {
                this.distritos = rta.data;
            }
        );
        if (this.id) {
            this.initialLoad(this.id);
        } else {
            this.route.params.subscribe(
                params => {
                    if (params.id) {
                        this.initialLoad(params.id);
                    } else {
                        this.orden.fecha = new Date().toISOString().slice(0,10) + " 00:00:00";
                        this.orden.distrito = this.distritoService.getLoadDistrito(this.globalVars.getActualDistrito());
                        this.orden.distrito_id = this.orden.distrito.id;

                        const proveedorId = this.route.snapshot.queryParams['proveedor'];
                        if (proveedorId) {
                          this.proveedorService.getProveedor(proveedorId).subscribe(
                              (proveedor: Proveedor) => {
                                  this.orden.proveedor = proveedor;
                              }
                          );
                        }
                        this.breadcrumbItems.push({title: 'Agregar' , active: true });
                    }                    
                }
            );
        }
    }
    
    public initialLoad(id: number) {
        this.ordenService.getOrden(id).subscribe(
            (orden: OrdenDePagoGeneral) => {
                this.orden = orden;
                //Orden de Pago # {{ orden.print() }}<span *ngIf="orden.revision">(En Revisión)</span></h4>
                this.breadcrumbItems = Object.assign([], this.INIT_BREADCRUM_ITEMS);
                this.breadcrumbItems.push({title: `Orden de Pago # ${this.orden.print()} ${orden.revision ? '(En Revisión)' : ''}` , active: true });
                this.nota = this.orden.nota;
                if (this.orden.distrito_id != this.globalVars.getActualDistrito() && ! this.globalVars.actualUser.multi_distrito) {
                    this.messageService.showNotification('top', 'center', 'danger', 'Usted no tiene acceso a esta orden de pago');
                    this.router.navigate(['/ordenespago']);
                } else if (this.orden.distrito_id != this.globalVars.getActualDistrito() && this.globalVars.actualUser.multi_distrito) {
                    //fix de multi_distrito. Todos los usuarios multi_distritos puede generar facturas cruzadas
                    //this.globalVars.setActualDistrito(this.orden.distrito_id);
                }
                this._titleService.setTitle(`Orden de pago # ${this.orden.print()} - ${this.orden.proveedor.nombre}`)
            }, error => {
                this.messageService.showNotification('top', 'center', 'danger', 'No se encontro la orden de pago');
                this.router.navigate(['/ordenespago']);
            }
        );
    }

    public save(event){
        this.beforeSubmit(event);
        if(! this.orden.id){
            this.ordenService.createOrden(this.orden).subscribe(
                (orden: OrdenDePagoGeneral) => {
                    this.showSuccess(true, 'Orden creada correctamente');;
                    this.router.navigate(['/ordenespago', orden.id]);   
                },err => {
                    this.showError(err, true);
                });
        } else {
            this.ordenService.updateOrden(this.orden).subscribe(
                (orden: OrdenDePagoGeneral) => {
                    this.orden.numero = orden.numero;
                    this.showSuccess(true, 'Orden modificada correctamente');
                },err => {
                    this.showError(err, true);
                });
        }
    }
    
    onNotifyAnular(orden: OrdenDePagoGeneral):void {
        this.anularOrden(orden);
    }
        
    public anularOrden(orden: OrdenDePagoGeneral) {
        this.beforeSubmitList();
        this.ordenService.anularOrden(orden.id).subscribe(
            rta => {
                this.showSuccessList(true, 'Orden anulada correctamente');
                this.orden.anulada = rta.anulada;
                this.orden.finalizada = rta.finalizada;
            }, error => {
                this.showErrorList(error, true);
            }
        );
    }

    finalizar(event){
        this.beforeSubmitList(event);
        this.ordenService.finalizarOrden(this.orden).subscribe(
            rta => {
                this.showSuccessList(true, 'Orden finalizada correctamente');
                this.orden.finalizada = rta.finalizada;
                this.orden.revision = rta.revision
            }, error => {
                this.showErrorList(error, true);
            }
        );
    }

    notaActualizar(elemento, otro){
        console.log(elemento);
        this.orden.nota = elemento;
    }

    verificar(event){
        this.beforeSubmitList(event);
        this.ordenService.verificarOrden(this.orden).subscribe(
            rta => {
                this.showSuccessList(true, 'Orden verificada correctamente');
                this.orden.verificada = rta.verificada;
            }, error => {
                this.showErrorList(error, true);
            }
        );
    }
    
    public pdfOrden() {
        this.cursorWait();
        this.ordenService.pdfOrden(this.orden.id).subscribe(
            rta => {
                this.cursorDefault();
                this.pdfService.loadPdf("orden_pago", rta); 
            },
            error => {
                this.cursorDefault();
                alert('Ups! No pudimos imprimir el pdf');
            }
        );
    }

    public detalleProveedor(proveedor: Proveedor) {
        this.proveedorCommunicate.proveedorModalPopUp(proveedor.id);
    }

    public cancel(event){
        this.beforeSubmitList(event);
        this.router.navigate(['/ordenespago']);
    }
}
