import {Component, OnInit, AfterViewInit, Input } from '@angular/core';

import {environment} from '../../../environments/environment';

import {ReportesService} from '../reportes.service';
import {CuentaService} from '../../module-cuenta/cuenta.service';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';
import {PdfService} from '../../services/pdf.service';
import {ProjectUtils, SelectPeriod} from '../../services/utils';

import {Cuenta} from '../../module-cuenta/models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';


@Component({
  selector: 'reporte_ordenes',
  templateUrl: './reporte-ordenes.component.html'
})
export class ReporteOrdenesComponent extends GenericFormComponent implements OnInit {
  @Input() type: string;
  public params = {excel: 0, cuenta: null};
  public filtro = {fecha_gteq: null, fecha_lteq: null, distrito_id: null, sort: { key: 'fecha', order: 'desc', description: 'Fecha' }};
    
  public labels: string[] = environment.meses;
  public meses: number[] = [];
  public anios: number[] = [];
  public cuenta: Cuenta = null;
  public cuentas: Cuenta[] = [];
  public BANK_LOGO_IDS = environment.bank_logo_ids;
  public loading: boolean = false;

  constructor(protected messageService: MessageService, private globalVars: GlobalVars, private reportesService: ReportesService, 
      private pdfService: PdfService, private cuentaService: CuentaService, private projectUtils: ProjectUtils) {
    super(messageService);
    this.meses = this.projectUtils.lastMonths(12);
    this.anios = this.projectUtils.lastYears(5);
  }

  ngOnInit() {
    this.filtro.distrito_id = this.globalVars.getActualDistrito();

    this.cuentaService.getCuentas({per_page: 1000}, {distrito_id_eq: this.globalVars.getActualDistrito(), sort: 'nombre'}).subscribe(
        rta => {
            this.cuentas = rta.data;
            let emptyAcc = new Cuenta({ nombre: "Todas", id: null});
            this.cuentas.push(emptyAcc);
            this.params.cuenta = emptyAcc;
        }
    );
  }
    
  public pdfOrdenes(excel: number) {
    this.cursorWait();
    this.params.excel = excel;
    let endpointParams = {
      excel: excel,
      cuenta: this.params.cuenta?.id
    }
    this.loading = true;
    this.reportesService.pdfOrdenes(endpointParams, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            let nombreArchivo = `ordenes_pago_${this.projectUtils.parseDateTimeToFullName(new Date(this.filtro.fecha_gteq))}_${this.projectUtils.parseDateTimeToFullName(new Date(this.filtro.fecha_lteq))}`;
            if(this.params.cuenta?.id != null)
              nombreArchivo = `ordenes_pago_${this.params.cuenta.nombre}_${this.projectUtils.parseDateTimeToFullName(new Date(this.filtro.fecha_gteq))}_${this.projectUtils.parseDateTimeToFullName(new Date(this.filtro.fecha_lteq))}`;
            
            if (excel) {
                this.pdfService.loadExcel(nombreArchivo, rta);                
            } else {
                this.pdfService.loadPdf(nombreArchivo, rta);
            }
            this.loading = false;
        },
        error => {
            this.cursorDefault();
            this.loading = false;
            alert('Ups! No pudimos imprimir el pdf');
        }
    );
  }
  
  public pdfRetenciones(excel: number) {
    this.cursorWait();
    this.params.excel = excel;
    let endpointParams = {
      excel: excel,
      cuenta: this.params.cuenta?.id
    }
    this.loading = true;
    this.reportesService.pdfRetenciones(endpointParams, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            let nombreArchivo = `retenciones_${this.projectUtils.parseDateTimeToFullName(new Date(this.filtro.fecha_gteq))}_${this.projectUtils.parseDateTimeToFullName(new Date(this.filtro.fecha_lteq))}`;
            if (excel) {
                this.pdfService.loadExcel(nombreArchivo, rta);                
            } else {
                this.pdfService.loadPdf(nombreArchivo, rta);
            }
            this.loading = false;
        },
        error => {
            this.cursorDefault();
            this.loading = false;
            alert('Ups! No pudimos imprimir el pdf');
        }
    );
  }

  public changeFilterDate(value){
    this.filtro.fecha_gteq = value.from;
    this.filtro.fecha_lteq = value.to;
  }
}