import { Component, OnInit, Input } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {ProveedorService} from '../proveedor.service';
import {CuentaService} from '../../module-cuenta/cuenta.service';
import { MessageService } from '../../services/message.service';
import { GlobalVars } from '../../services/globalVars';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Proveedor} from '../models';
import {Cuenta} from '../../module-cuenta/models';
import {User} from '../../models/user';
import { Title } from '@angular/platform-browser';
import { environment } from 'environments/environment';
 
@Component({
  selector: 'proveedores_detalle',
  templateUrl: './proveedores_detalle.component.html'
})
export class ProveedoresDetalleComponent extends GenericFormComponent implements OnInit {
  @Input() id: number = null;
  public actualUser: User;
  public proveedor: Proveedor;
  
  public cuentas: Cuenta[];
  public cuenta: Cuenta = null;

  public BANK_LOGO_IDS = environment.bank_logo_ids;
  
  constructor(protected messageService: MessageService, private route:ActivatedRoute, private router: Router, private proveedorService: ProveedorService, 
    private cuentaService: CuentaService, private globalVars: GlobalVars, private _titleService: Title) {
    super(messageService);
  }

  ngOnInit() {
    this.actualUser = this.globalVars.actualUser;
    if (this.id) {
        this.initialLoad(this.id);
    } else {
        this.route.params.subscribe(params => {
          if(params['id']){
              this.initialLoad(params['id']);
          }
      });
    }
  }

  public initialLoad(id: number) {
    this.cursorWait();
    this.proveedor = null;
    this.proveedorService.getProveedor(id).subscribe(
        (proveedor: Proveedor) => {
            this.cursorDefault();
            this.proveedor = proveedor;
            this.proveedorService.getCuentasProveedor(this.proveedor.id).subscribe(
                (cuentas: Cuenta[]) => {
                    this.cuentas = cuentas;
                }
            );
            this._titleService.setTitle(`Proveedor: ${proveedor.nombre}`);
        }, error => {
            this.cursorDefault();
            this.messageService.showNotification('top', 'center', 'danger', 'No se encontro el proveedor');
            this.router.navigate(['proveedores']);
        }
    );
  }
  
  //ACCIONES
  changeState(cuenta: Cuenta, state, event){
    this.beforeSubmitList(event);
    cuenta.habilitado = ! cuenta.habilitado;
    this.cuentaService.updateCuenta(cuenta).subscribe(
        rta => {
            this.showSuccessList(true, 'Cuenta modificada correctamente');
        }, error => {
            cuenta.habilitado = ! cuenta.habilitado;
            this.showErrorList(error, true);
        }
    );
  }
  
  onNotifyDelete(cuenta: Cuenta):void {
    this.deleteCuenta(cuenta);
  }
    
  public deleteCuenta(cuenta: Cuenta) {
    this.beforeSubmitList();
    this.cuentaService.deleteCuenta(cuenta.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Cuenta eliminada correctamente');
            //ELIMINO DE LA LISTA      
            this.cuentas.forEach(function(cuentaFor, index, object) {
                if(cuentaFor.id == cuenta.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }  
  
  //FORMULARIO ALTA/EDICION
  //Para el Form
  public newCuenta(){
    this.cuenta = new Cuenta();
    //this.cuenta.distrito = this.proveedor.distrito;
    this.cuenta.tipo = 'BANCO';
    this.cuenta.titular = this.proveedor.nombre;
    this.cuenta.cuit = this.proveedor.cuit;
  }

  public cancelForm(event?): void{
    this.cuenta = null;
  }
}