import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import {ProveedorCommunicate} from '../proveedor.communicate';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

declare var $: any;

@Component({
  selector: 'proveedor_modal',
  templateUrl: './proveedor_modal.component.html'
})
export class ProveedorModalComponent extends GenericFormComponent implements OnInit, OnDestroy {
  @ViewChild('proveedorModal', { static: true }) el:ElementRef;
    
  public show: boolean= false;
  public id: number = null;
  
  //Observables
  private proveedorModal: any;
    
  constructor(protected messageService: MessageService, private proveedorCommunicate: ProveedorCommunicate) {
      super(messageService);
  }

  ngOnInit() {
    this.proveedorModal = this.proveedorCommunicate.proveedorModalPop$.subscribe(
        (id: number) => {
            this.id = id;
            this.load();
        });
        
    $(this.el.nativeElement).on('hidden.bs.modal', (e) => {
        this.show= false;
    });
  }
   
  //MODAL
  public load(){
    this.show= true;
    $(this.el.nativeElement).modal();
  }
  public hide(){
    $(this.el.nativeElement).modal("hide");
    this.show= false;
  }
  
  //OnDestroy
  ngOnDestroy() {
    this.proveedorModal.unsubscribe();
  }
}