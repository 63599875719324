<div class="card">
    <div class="card-content">
        <h4 class="card-title" *ngIf="!chequera.id">Nueva Chequera</h4>
        <hr *ngIf="!chequera.id">
        
        <breadcrumb [items]="breadcrumbItems" *ngIf="chequera.id"></breadcrumb>

        <form class="form-horizontal" (submit)="save($event)" #datosForm="ngForm">
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Cuenta</label>
                        <div class="col-md-9">
                            <ng-select 
                                    class="custom-ng-select"
                                    [items]="cuentas"
                                    [(ngModel)]="chequera.cuenta" 
                                    name="cuenta-sel"
                                    placeholder="Seleccione una cuenta"
                                    [disabled]="chequera.id">
                                <ng-template ng-label-tmp let-item="item">
                                    <span [class]="'mr-2 bank-logo bank-logo-' + item.banco_id" *ngIf="BANK_LOGO_IDS.includes(item.banco_id)"></span>
                                    <b>{{item.nombre}}</b>
                                    <i class="ml-1">{{item.descripcion?.length > 0 ? '(' + item.descripcion + ') ' : ''}}</i>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                    <span [class]="'bank-logo bank-logo-' + item.banco_id" *ngIf="BANK_LOGO_IDS.includes(item.banco_id)"></span>
                                    <span style="margin-left: 100px;" *ngIf="!BANK_LOGO_IDS.includes(item.banco_id)"></span>
                                    <b class="ml-2 ">{{item.nombre}} </b>
                                    <i class="ml-1">{{item.descripcion?.length > 0 ? '(' + item.descripcion + ') ' : ''}}</i>
                                </ng-template>
                             </ng-select>
                            <small [hidden]="!inputError.cuenta_id" [innerHtml]="inputError.cuenta_id" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Fecha emisión</label>
                        <div class="col-md-9">     
                            <input-datepicker [(dateModel)]="chequera.emision" [isStringFormat]="true" [required]="true" ></input-datepicker>                             
                            <small [hidden]="!inputError.emision" [innerHtml]="inputError.emision" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>
            
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Número cheque desde</label>
                        <div class="col-md-9">                                  
                            <input #firstElementChequera type="number" name="numero_desde" [(ngModel)]="chequera.numero_desde" class="form-control" placeholder="100500" required [disabled]="chequera.id" (blur)="checkNumber('numero_desde', chequera.numero_desde)">
                            <small [hidden]="!inputError.numero_desde" [innerHtml]="inputError.numero_desde" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Número cheque hasta</label>
                        <div class="col-md-9">                                  
                            <input type="number" name="numero_hasta" [(ngModel)]="chequera.numero_hasta" class="form-control" placeholder="200500" required [disabled]="chequera.id" (blur)="checkNumber('numero_hasta', chequera.numero_hasta)">
                            <small [hidden]="!inputError.numero_hasta" [innerHtml]="inputError.numero_hasta" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>
            
            <div class="form-group">                
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Tipo de Libro</label>
                        <div class="col-md-9">
                            <select class="form-control" data-style="btn btn-wd btn-block" name="tipo" [(ngModel)]="chequera.tipo_libro">
                                <option [ngValue]="null">Seleccione un Tipo de Cuenta</option>
                                <option *ngFor="let book_type of book_types" [ngValue]="book_type">{{book_type | translate: 'BOOK_TYPE'}}</option>
                            </select>
                            <small [hidden]="!inputError.tipo_libro" [innerHtml]="inputError.tipo_libro" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6" *ngIf="chequera.id">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Opciones</label>
                        <div class="col-md-9">
                            <div class="checkbox">
                                <input type="checkbox" name="finalizada" value="1" [(ngModel)]="chequera.finalizada" checked>
                                <label>Finalizada</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center">
                <button class="btn btn-default btn-cancel" type="button" (confirm-click)="cancelForm($event)" confirm-message="¿Está seguro que desea cancelar este formulario?" *ngIf="! chequera.id">Cancelar</button>
                <button class="btn btn-primary ml-2" type="submit" [disabled]="submitted">Guardar</button>
            </div>
            <div class="clearfix"></div>
        </form>
    </div>
</div>