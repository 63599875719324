<div class="container-fluid">    
    <div class="row">
        <div class="card">
            <div class="card-content">
                <breadcrumb [items]="breadcrumbItems"></breadcrumb>         

                <filters-header-section (onFilter)="filter(1)" [advancedFilters]="true" [someFilterApllied]="someFiltersApplied" (onClear)="resetFilters()">
                    <div class="col-sm-12">
                        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                            <label for="search">Número / Persona:</label>
                            <div class="input-group">
                                <input class="form-control" type="text" placeholder="Número / Persona" name="search" [(ngModel)]="filtro.or_numero_matches">
                                <span class="input-group-addon" (click)="filter(1)"><i class="cmp-i busqueda i-sm"></i></span>
                            </div>
                        </div>
                                            
                        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                            <label for="distrito_id">Distrito:</label>
                            <select class="form-control" data-style="btn btn-wd btn-block" name="distrito_id"  [(ngModel)]="filtro.distrito_id_eq" (change)="filter(1)" [disabled]="!actualUser.multi_distrito">
                                <option [ngValue]="null">Todos</option>
                                <option *ngFor="let distrito of distritos" [ngValue]="distrito.id">{{distrito.nombre}}</option>
                            </select>
                        </div>
                        
                        <!--<div class="col-sm-12 col-md-6 col-lg-4 col-xl-3" *ngIf="cargadorViaticos">
                            <label for="proveedor_id">Persona:</label>
                            <select class="form-control" data-style="btn btn-wd btn-block" name="proveedor_id"  [(ngModel)]="filtro.proveedor_id_eq" (change)="filter(1)">
                                <option [ngValue]="null">Todas</option>
                                <option *ngFor="let permiso of permisosViaticos" [ngValue]="permiso.proveedor.id">{{permiso.proveedor.nombre}}</option>
                            </select>
                        </div> -->
                    </div>
                
                    <div moreFilters>
                        <div class="col-md-12">
                            <div class="col-md-4">
                                <label for="estado">Estado:</label>
                                <select class="form-control" data-style="btn btn-wd btn-block" name="estado" [(ngModel)]="filtro.estado_eq" (change)="filter(1)">
                                    <option [ngValue]="null">Todos</option>
                                    <option *ngFor="let estado of estados" [ngValue]="estado">{{estado | translate: "VIATICO_EST"}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <generic_period (outDate)="changeFilterDate($event)" [fromDate]="filtro.emision_gteq" [toDate]="filtro.emision_lteq" [enableCleanOption]="true"></generic_period>
                        </div>       
                    </div>
                                    
                                    
                </filters-header-section>
            

                <div class="row mt-2">
                    <div class="col-sm-10">
                        <button class="btn btn-simple btn-icon btn-add" [routerLink]="['/viaticos/new']" *ngIf="!authViaticos" title="Agregar"><i class="cmp-i agregar"></i> Agregar</button>
                    </div>  
                    <div class="col-sm-2">
                        <sort-select [options]="sortOptions" [(sortModel)]="filtro.sort" (onSelect)="filter(1)"></sort-select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 table-responsive">
                        <table class="table table-hover">
                            <thead class="text-primary">
                                <tr>
                                    <th><b>Número</b></th>
                                    <th><b>Emisión</b></th>
                                    <th><b>Persona</b></th>
                                    <th><b>Distrito</b></th>
                                    <th><b>Monto / A Pagar / Facturado</b></th>
                                    <th><b>Ordenes de pago por</b></th>
                                    <th><b>Estado</b></th>
                                    <th><b>Acciones</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let viatico of viaticos'>
                                    <td><a [routerLink]="['/viaticos', viatico.id]" rel="tooltip" title="Editar Viatico">{{viatico.numero}}</a></td>
                                    <td>{{viatico.emision  | date: 'dd/MM/yyyy'}}</td>
                                    <td><a [routerLink]="['/proveedores', viatico.proveedor.id]" rel="tooltip" title="Proveedor">{{viatico.proveedor.nombre}} - {{viatico.proveedor.cuit | cuitformat}}</a></td>
                                    <td>{{viatico.distrito.nombre}}</td>                             
                                    <td>$ {{viatico.monto | number: '1.2-2'}} / $ {{viatico.monto_neto | number: '1.2-2'}} / $ {{viatico.monto_real | number: '1.2-2'}}</td>
                                    <td>$ {{viatico.pagado | number: '1.2-2'}}</td>
                                    <td [ngClass]="viatico.estado === 'R' ? 'status-danger': viatico.estado === 'A' ? 'status-sucess' : 'status-info'">
                                        {{viatico.estado | translate: 'VIATICO_EST'}}
                                    </td>
                                    <td class="td-actions text-right">
                                        <button type="button" rel="tooltip" title="Imprimir Viatico" class="btn btn-info btn-simple btn-xs" (click)="pdfViatico(viatico)">
                                            <i class="ti-printer"></i>
                                        </button>
                                        <a *ngIf="viatico.estado == 'A' && viatico.restaPagar() && actualUser.hasRolByName('tesorero', 'administrativo')" [routerLink]="['/ordenespago/new']" [queryParams]="{proveedor: viatico.proveedor.id}" rel="tooltip" title="Generar O.P." class="btn btn-primary btn-simple btn-xs">
                                            <i class="ti-panel"></i>
                                        </a>
                                        <a [routerLink]="['/viaticos', viatico.id]" rel="tooltip" title="Editar Viatico" class="btn btn-primary btn-simple btn-xs btn-cmp-i">
                                            <i class="cmp-i editar i-sm"></i>
                                        </a>
                                        <button *ngIf="authViaticos && viatico.estado != 'R'" type="button" rel="tooltip" title="Rechazar Viatico" class="btn btn-danger btn-simple btn-xs" (click)="changeState(viatico, 'R', $event)">
                                            <i class="ti-close"></i>
                                        </button>
                                        <button *ngIf="authViaticos && viatico.estado != 'A'" type="button" rel="tooltip" title="Aprobar Viatico" class="btn btn-success btn-simple btn-xs" (click)="changeState(viatico, 'A', $event)">
                                            <i class="ti-check"></i>
                                        </button>
                                        <button *ngIf="!authViaticos && viatico.estado != 'A'" type="button" rel="tooltip" title="Eliminar Viatico" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="viatico" confirm-message="¿Está seguro que desea eliminar el Viatico?">
                                            <i class="cmp-i eliminar-borrar i-sm"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="viaticos != null && viaticos.length == 0" class="text-center">
                                    <td colspan="8">No se encontraron viáticos</td>
                                </tr>
                                <tr *ngIf="viaticos == null && !hasLoadPermissions" class="text-center">
                                    <td colspan="8">No tiene asociada ninguna persona para asignar viaticos</td>
                                </tr>
                            </tbody>
                        </table>                        
                        <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)" *ngIf="viaticos != null && viaticos.length > 0"></pager>
                    </div>
                </div>
            </div>
        </div>          
    </div>
</div>