<div class="row text-right mr-2">
    <b>Ordenar por:</b>
    <a href="#" data-toggle="dropdown">
        <p>{{sortModel.description}} <i class="ti-angle-down"></i></p>
    </a>
    <ul class="dropdown-menu">
        <li *ngFor="let option of options">
            <a (click)="onSelectLocal(option)"><i class="ti-check" *ngIf="sortModel == option"></i>{{option.description}}</a>
        </li>
    </ul>
</div>
