import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {environment} from '../../../environments/environment';

import {CuentaService} from '../cuenta.service';
import {DistritoService} from '../../module-distrito/distrito.service';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';
import {PdfService} from '../../services/pdf.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Cuenta, Cheque} from '../models';
import {User} from '../../models/user';
import {Distrito} from '../../module-distrito/models';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
import { FilterCacheUtilsService } from 'app/services/filter-cache-utils.service';
import { FilterCache } from 'app/models/filter-cache';
 
const VIEW_ID = "cheques";

@Component({
  selector: 'cheques',
  templateUrl: './cheques.component.html'
})
export class ChequesComponent extends GenericFormComponent implements OnInit {
  public actualUser: User;
    
  public cheques: Cheque[];
  public params = {distrito: null, cuenta: null, page: 1, per_page: 10, excel: 0};
  public filtroDefault = {numero_matches: null, estado_eq: null, emision_gteq: null, emision_lteq: null, sort: 'emision,desc'};
  public filtro = Object.assign({},this.filtroDefault);
  public someFiltersApplied: boolean = false;

  public estados: string[] = environment.estados_cheque;
  
  public cuentas: Cuenta[] = [];
  public distritos: Distrito[] = [];

  //FORM
  public cheque: Cheque;

  public sortOptions: { [id: string] : string } = { 'numero,desc': 'Número', 'emision,desc': 'Emisión' };
  public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Cheques', active: true }];
  public BANK_LOGO_IDS = environment.bank_logo_ids;
    
  constructor(protected messageService: MessageService, private globalVars: GlobalVars, private cuentaService: CuentaService, private route: ActivatedRoute
      , private distritoService: DistritoService, private pdfService: PdfService, private filterCacheUtilsService: FilterCacheUtilsService, private router: Router) {
    super(messageService);
  }

  ngOnInit() {
    this.actualUser = this.globalVars.actualUser;
    this.loadInitFilters();
    this.loadCacheFiltersParams();
    this.filter();
    this.distritoService.getDistritos({per_page: 1000}, {sort: 'nombre'}).subscribe(
        (rta: any) => {
            this.distritos = rta.data;
        }
    );
    this.updateCuentas();
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.cursorWait();
    this.someFiltersApplied = this.globalVars.isSomeFilterApplied(this.filtro);
    this.cuentaService.getCheques(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.cheques = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
            this.filterCacheUtilsService.setFilterParams(VIEW_ID, this.filtro, this.params);
        },
        error => {
            this.cursorDefault();
        }
    );
  }
      
  public updateCuentas(resetCuenta = false) {
    if (resetCuenta) {
        this.params.cuenta = null
    }
    this.cuentaService.getCuentas({per_page: 1000}, {tipo_eq: 'BANCO', distrito_id_eq: this.params.distrito, sort: 'nombre'}).subscribe(
        (rta: any) => {
            this.cuentas = rta.data;
        }
    );
  }
  
  public changeFilterDate(value){
    this.filtro.emision_gteq = value.from;
    this.filtro.emision_lteq = value.to;
    this.filter(1);
  }
  
  public pdfCheques(excel: number) {
    this.cursorWait();
    this.params.excel = excel;
    this.cuentaService.pdfCheques(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            if (excel) {
                this.pdfService.loadExcel("cheques", rta);                
            } else {
                this.pdfService.loadPdf("cheques", rta);
            }
        },
        error => {
            this.cursorDefault();
            alert('Ups! No pudimos imprimir el pdf');
        }
    );
  }
  
  //ACCIONES  
  onNotifyDelete(cheque: Cheque):void {
    this.deleteCheque(cheque);
  }
    
  public deleteCheque(cheque: Cheque) {
    this.beforeSubmitList();
    this.cuentaService.deleteCheque(cheque.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Cheque eliminada correctamente');
            //ELIMINO DE LA LISTA      
            this.cheques.forEach(function(chequeFor, index, object) {
                if(chequeFor.id == cheque.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }

  //DESDE EL FORM
  public updateCheque(cheque: Cheque) {
    this.router.navigate(['/cheques', cheque.id]);  
  }
  
  //Desde el formulario
  public actualizeForm(cheque: Cheque): void {
    let exists= false;
    this.cheques.forEach(function(chequeFor, index, object) {
        if(chequeFor.id == cheque.id) {
            cheque.chequera = object[index].chequera;
            object[index] = cheque;            
            exists= true;
        }
    });
    this.cancelForm();
  }    
  public cancelForm(event?): void{
    this.cheque = null;
  }

  private loadInitFilters(): void {
    this.params.distrito = this.globalVars.getActualDistrito();
    this.params.cuenta = this.route.snapshot.queryParams['cuenta'] ? Number(this.route.snapshot.queryParams['cuenta']) : null;

    this.route.params.subscribe(params => {
      if(params['id']){
      this.cursorWait();
        this.cuentaService.getCheque(params['id']).subscribe(
            (cheque: Cheque) => {
                this.cursorDefault();
                this.cheque = cheque;
            }, error => {
                this.cursorDefault();
                this.messageService.showNotification('top', 'center', 'danger', 'No se encontro el cheque');
                setTimeout(() => {
                  this.router.navigate(['cheques']);
                }, 500)
            }
        );
      }
  });
  }

  private loadCacheFiltersParams() {
    let cacheFilters: FilterCache = this.filterCacheUtilsService.getFilterParams(VIEW_ID);
    if (!cacheFilters) return;

    this.filtro = cacheFilters.filters;
    this.params = cacheFilters.params;
  }

  public resetFilters() {
    this.filtro = Object.assign({}, this.filtroDefault);
    this.loadInitFilters();
    this.filter();
  }

}