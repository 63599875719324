import { Component, OnInit } from '@angular/core';

import {ConfigService} from '../../services/config.service';
import { MessageService } from '../../services/message.service';

import {Config} from '../../models/config';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
 
@Component({
  selector: 'configuracion',
  templateUrl: './configuracion.component.html'
})
export class ConfiguracionComponent extends GenericFormComponent implements OnInit {
    
    config: Config;

    public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Configuración', active: true }, {title: 'Valor del modulo', active: true }];
    
    constructor(protected messageService: MessageService, private configService: ConfigService) {
        super(messageService);
    }

    ngOnInit() {
        this.configService.config().subscribe(
            (config: Config) => {
                this.config = config;
            }
        );
    }
    
    public save(event){
        this.beforeSubmit(event);
        this.configService.updateConfig(this.config).subscribe(
            (config: Config) => {
                this.showSuccess(true, 'Configuracion modificada correctamente');       
            },err => {
                this.showError(err.error, true);
            });
  }  
}