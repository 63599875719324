import {Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {OrdenService} from '../orden.service';
import {DistritoService} from '../../module-distrito/distrito.service';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';
import {PdfService} from '../../services/pdf.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {OrdenDePagoGeneral} from '../models';
import {User} from '../../models/user';
import {Distrito} from '../../module-distrito/models';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
import Sort from 'app/models/Sort';
import { FilterCacheUtilsService } from 'app/services/filter-cache-utils.service';
import { FilterCache } from 'app/models/filter-cache';

const VIEW_ID = "ordenespago";

@Component({
  selector: 'ordenes',
  templateUrl: './ordenes.component.html'
})
export class OrdenesComponent extends GenericFormComponent implements OnInit {
  public actualUser: User;
    
  public ordenes: OrdenDePagoGeneral[];
  public params = {page: 1, per_page: 10, excel: 0};

  public sortOptions: Sort[] = [
    { key: 'fecha', order: 'desc', description: 'Fecha' },
    { key: 'numero', order: 'desc', description: 'Número' }
  ]

  public filtroDefault = {
    search_elastic: null, 
    punto_venta: null, 
    numero: null,
    fecha_gteq: null, 
    fecha_lteq: null, 
    distrito_id: null,
    estado: null, 
    monto_gteq: null, 
    monto_lteq: null, 
    pagado_gteq: null, 
    pagado_lteq: null, 
    sort: this.sortOptions[0]
  };
  public filtro = Object.assign({},this.filtroDefault);
  public someFiltersApplied: boolean = false;
  
  public distritos: Distrito[] = [];
  public fechas = null;
  public estadoChange = null;
  
  public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Ordenes de pago', active: true }];

  @ViewChild('search_elastic', { static: true }) searchElastic: ElementRef;
    
    
  constructor(protected messageService: MessageService, private ordenService: OrdenService, private globalVars: GlobalVars, private distritoService: DistritoService, 
      private pdfService: PdfService, private route: ActivatedRoute, private router: Router, private filterCacheUtilsService: FilterCacheUtilsService) {
    super(messageService);
  }

  ngOnInit() {
    this.actualUser = this.globalVars.actualUser;
    this.filtro.distrito_id = this.globalVars.getActualDistrito();
    this.loadCacheFiltersParams();
    this.filter();
    this.distritoService.getDistritos({per_page: 1000}, {sort: 'nombre'}).subscribe(
        (rta: any) => {
            this.distritos = rta.data;
        }
    );
    setTimeout(() => {this.searchElastic.nativeElement.focus()}, 100);
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.cursorWait();
    this.route.queryParams.subscribe(params => {
        if(this.fechas != 'fechacambiado'){

          if((params['fecha_gteq'] !== undefined) &&
            (params['fecha_gteq'] != null) && (params['fecha_gteq'] != "undefined")){
              this.filtro.fecha_gteq = params['fecha_gteq'];
          }

          if((params['fecha_lteq'] !== undefined) &&
            (params['fecha_lteq'] != null) && (params['fecha_lteq'] != "undefined")){
              this.filtro.fecha_lteq = params['fecha_lteq'];
          }
        } else {
          this.fechas = null;
        }

        if(this.estadoChange != 'estadocambiado'){
          this.filtro.estado = params['estado'] ?? null;
        } else {
          this.estadoChange = null;
        }

    });
    this.someFiltersApplied = this.globalVars.isSomeFilterApplied(this.filtro);
    this.ordenService.getOrdenes(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.ordenes = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
            this.filterCacheUtilsService.setFilterParams(VIEW_ID, this.filtro, this.params);
        },
        error => {
            this.cursorDefault();
        }
    );
  }

  public changeEstado() {
    this.estadoChange = 'estadocambiado';
    this.filter(1);
  }
    
    
  public pdfOrdenes(excel: number) {
    this.cursorWait();
    let params = {excel: excel};
    let filtro = {distrito_id: this.globalVars.getActualDistrito(), fecha_gteq: this.filtro.fecha_gteq, fecha_lteq: this.filtro.fecha_lteq, sort: 'fecha,desc|numero,desc'}
    this.ordenService.pdfOrdenes(params, filtro).subscribe(
        rta => {
            this.cursorDefault();
            if (excel) {
                this.pdfService.loadExcel("ordenes_pago", rta);                
            } else {
                this.pdfService.loadPdf("ordenes_pago", rta);
            }
        },
        error => {
            this.cursorDefault();
            alert('Ups! No pudimos imprimir el pdf');
        }
    );
  }
  
  public pdfRetenciones(excel: number) {
    this.cursorWait();
    this.params.excel = excel;
    this.ordenService.pdfRetenciones(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            if (excel) {
                this.pdfService.loadExcel("retenciones", rta);                
            } else {
                this.pdfService.loadPdf("retenciones", rta);
            }
        },
        error => {
            this.cursorDefault();
            alert('Ups! No pudimos imprimir el pdf');
        }
    );
  }

  public pdfOrden(orden) {
      this.cursorWait();
      this.ordenService.pdfOrden(orden.id).subscribe(
          rta => {
              this.cursorDefault();
              this.pdfService.loadPdf("orden_pago", rta); 
          },
          error => {
              this.cursorDefault();
              alert('Ups! No pudimos imprimir el pdf');
          }
      );
  }
  
  public changeFilterDate(value){
    this.filtro.fecha_gteq = value.from;
    this.filtro.fecha_lteq = value.to;
    this.fechas = 'fechacambiado';
    this.filter(1);
  }
  
  //ACCIONES    
  onNotifyDelete(orden: OrdenDePagoGeneral):void {
    this.deleteOrden(orden);
  }
    
  public deleteOrden(orden: OrdenDePagoGeneral) {
    this.beforeSubmitList();
    this.ordenService.deleteOrden(orden.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Orden eliminada correctamente');
            //ELIMINO DE LA LISTA      
            this.ordenes.forEach(function(ordenFor, index, object) {
                if(ordenFor.id == orden.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }

  private loadCacheFiltersParams() {
    let cacheFilters: FilterCache = this.filterCacheUtilsService.getFilterParams(VIEW_ID);
    if (!cacheFilters) return;

    this.filtro = cacheFilters.filters;
    this.params = cacheFilters.params;
  }

  public resetFilters() {
    this.filtro = Object.assign({},this.filtroDefault);
    this.filtro.distrito_id = this.globalVars.getActualDistrito();
    this.filter();
  }
}