<div class="row">
    <div class="card">
        <div class="card-content">
            <h4 class="card-title">Reponer</h4>
            <hr>

            <div class="row" *ngIf="(allowsReplacement || allowsBeforeReplacement) && cuenta.habilitado && movRestantes && movRestantes.movimientos > 0">
                <div class="col-sm-12" >
                    <div class="row text-center">
                        <p>Para la fecha contable <b>{{ movimiento.fecha_contable | date: 'dd/MM/yyyy'}}</b> se admite {{movRestantes.movimientos}} {{movRestantes.movimientos > 1 ? 'reposiciones' : 'reposición'}} por una suma total de $ 
                            <b class="cursor-pointer" (mouseenter)="showBalance = true" (mouseleave)="hideRepoBalance()">{{showBalance ? (movRestantes.saldo | number: '1.2-2') : '****  '}}</b>
                            <i *ngIf="!showBalance" class="cmp-i ocultar i-md cursor-pointer" (mouseenter)="showBalance = true" (mouseleave)="hideRepoBalance()"></i>
                        </p>                           
                    </div>
                </div>
            </div>

            <div class="row text-center" *ngIf="!allowsReplacement && cuenta.habilitado">
                <h4>La caja chica no permite mas reposiciones {{!allowsBeforeReplacement ? 'por este mes.' : 'para el mes seleccionado.'}}</h4>
            </div>

            <br>
            <form class="form-horizontal mt-2" (submit)="save($event)" #datosMovForm="ngForm"  *ngIf="(allowsReplacement || allowsBeforeReplacement) && cuenta.habilitado"> 
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Método de Pago</label>
                            <div class="col-md-9">
                                <ng-select 
                                        class="custom-ng-select"
                                        bindLabel="nombre"
                                        [items]="metodos"
                                        [(ngModel)]="movimiento.metodopago" 
                                        name="metodoPago"
                                        placeholder="Seleccione un Método de Pago"
                                        (change)="changeMetodo()">
                                 </ng-select>
                                
                                <small [hidden]="!inputError.metodo_pago_id" [innerHtml]="inputError.metodo_pago_id" class="text-danger"></small>
                            </div>                            
                        </div>
                    </div>
    
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Monto</label>
                            <div class="col-md-9">                                  
                                <input type="number" name="monto" [(ngModel)]="movimiento.monto" class="form-control" placeholder="1000" required>
                                <small [hidden]="!inputError.monto" [innerHtml]="inputError.monto" class="text-danger"></small>
                            </div>                            
                        </div>
                    </div>
                </div>
                <div class="form-group">                
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Cuenta Origen</label>
                            <div class="col-md-9">
                                <ng-select 
                                        class="custom-ng-select"
                                        [items]="cuentasFiltro"
                                        [(ngModel)]="movimiento.cuenta" 
                                        name="cuenta-sel"
                                        placeholder="Seleccione una cuenta origen"
                                        [disabled]="!movimiento.metodopago" (change)="onChangeAccount()">
                                    <ng-template ng-label-tmp let-item="item">
                                        <span [class]="'mr-2 bank-logo bank-logo-' + item.banco_id" *ngIf="BANK_LOGO_IDS.includes(item.banco_id)"></span>
                                        <b>{{item.nombre}}</b>
                                        <i class="ml-1">{{item.descripcion?.length > 0 ? '(' + item.descripcion + ') ' : ''}}</i>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-index="index">
                                        <span [class]="'bank-logo bank-logo-' + item.banco_id" *ngIf="BANK_LOGO_IDS.includes(item.banco_id)"></span>
                                        <span style="margin-left: 100px;" *ngIf="!BANK_LOGO_IDS.includes(item.banco_id)"></span>
                                        <b class="ml-2 ">{{item.nombre}} </b>
                                        <i class="ml-1">{{item.descripcion?.length > 0 ? '(' + item.descripcion + ') ' : ''}}</i>
                                    </ng-template>
                                 </ng-select>
                            </div>                            
                        </div>
                    </div>
    
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Moneda</label>
                            <div class="col-md-9">
                                <ng-select 
                                        class="custom-ng-select"
                                        [items]="monedas"
                                        [(ngModel)]="movimiento.moneda" 
                                        name="moneda"
                                        placeholder="Seleccione una Moneda">
                                    <ng-template ng-label-tmp let-item="item">
                                        <b>{{item | translate: 'MONEDA'}}</b>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-index="index">
                                        <b>{{item | translate: 'MONEDA'}}</b>
                                    </ng-template>
                                 </ng-select>
                                <!-- <small [hidden]="!inputError.moneda" [innerHtml]="inputError.moneda" class="text-danger"></small> -->
                            </div>                            
                        </div>
                    </div>
                </div>
    
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Observaciones</label>
                            <div class="col-md-9">                                  
                                <input #firstElementMovimiento type="text" name="descripcion" [(ngModel)]="movimiento.descripcion" class="form-control" placeholder="Obervaciones del movimiento" required>
                            </div>                            
                        </div>
                    </div>
    
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Fecha</label>
                            <div class="col-md-9">
                                <input-datepicker [(dateModel)]="movimiento.fecha_contable" [isStringFormat]="true" [required]="true" [minDate]="validDates.min" [maxDate]="validDates.max" [defaultValue]="defaultDate" (actualize)="validarDestino()" #datepicker></input-datepicker>
                            </div>                            
                        </div>
                    </div>
                </div>

                <div class="text-right mt-2" *ngIf="!allowsBeforeReplacement && cuenta.habilitado">
                    <a class="dropdown-toggle btn-magnify" (click)="enableBeforeReplacement()">
                        <p>Reponer mes anterior</p>
                    </a>
                </div>
                
                <ng-container *ngIf="movimiento.metodopago && movimiento.metodopago.esCheque() && movimiento.cuenta.tipo == 'BANCO'">
                    <h4>Cheque</h4>
    
                    <div class="form-group">
                        <div class="col-md-6">
                            <div class="input-group">
                                <input class="form-control" type="text" placeholder="Búsqueda de cheque" name="search" [(ngModel)]="numeroCheque" (keyup.enter)="buscarCheque()" (blur)="enabledSubmit()" (focus)="disabledSubmit()">
                                <span class="input-group-addon" (click)="buscarCheque()"><i class="cmp-i busqueda i-sm"></i></span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <button class="btn btn-primary" type="button" (click)="newCheque()">Crear Cheque</button>
                        </div>
                    </div>
                    
                    <div *ngIf="cheque" class="row">
                        <cheques_form [cheque]="cheque" [cuenta]="movimiento.cuenta" [withBackground]="false" (actualize)="actualizeFormCheque($event)" (cancel)="cancelFormCheque($event)"></cheques_form>
                    </div>
    
                    <div class="form-group" *ngIf="movimiento.cheque">
                        <div class="col-md-6">
                            <div class="row">
                                <label class="col-md-3 label-on-left">Número de Cheque</label>
                                <div class="col-md-9">                                  
                                    <input type="number" name="numero_cheque" [(ngModel)]="movimiento.cheque.numero" class="form-control" placeholder="Número de cheque">
                                    <small [hidden]="!inputError.numero" [innerHtml]="inputError.numero" class="text-danger"></small>
                                </div>                            
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <label class="col-md-3 label-on-left">Monto</label>
                                <div class="col-md-9">                                  
                                    <input type="number" name="monto_cheque" [(ngModel)]="movimiento.cheque.monto" class="form-control" placeholder="1000">
                                    <small [hidden]="!inputError.monto" [innerHtml]="inputError.monto" class="text-danger"></small>
                                </div>                            
                            </div>
                        </div>
                    </div>
                </ng-container>
    
                <div class="row text-center">
                    <button class="btn btn-primary ml-1" type="submit" [disabled]="submitted || (movimiento.metodopago && movimiento.metodopago.esCheque() && !movimiento.cheque) || !movimiento.cuenta || !movimiento.monto || !movimiento.moneda" *ngIf="allowsReplacement">Reponer</button>
                </div>
                <div class="clearfix"></div>
            </form>

            <div class="text-center" *ngIf="!cuenta.habilitado">
                <h4>La cuenta se encuentra inhabilitada.</h4>
            </div>
        </div>
    </div>


</div>