import { Component, OnInit } from '@angular/core';

import {CuentaService} from '../cuenta.service';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {MetodoDePago} from '../models';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
 
@Component({
  selector: 'metodos_pago',
  templateUrl: './metodos_pago.component.html'
})
export class MetodosPagoComponent extends GenericFormComponent implements OnInit {
  public metodos: MetodoDePago[];
  public params = {page: 1, per_page: 10};
  public filtro = {nombre_matches: null, sort: 'nombre'};
  //FORM
  public metodo: MetodoDePago;

  public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Métodos de Pago', active: true }];
    
  constructor(protected messageService: MessageService, private cuentaService: CuentaService) {
    super(messageService);
  }

  ngOnInit() {
    this.filter();
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.cursorWait();
    this.cuentaService.getMetodosDePago(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.metodos = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
        },
        error => {
            this.cursorDefault();
        }
    );
  }
  
  //ACCIONES    
  onNotifyDelete(metodo: MetodoDePago):void {
    this.deleteMetodo(metodo);
  }
    
  public deleteMetodo(metodo: MetodoDePago) {
    this.beforeSubmitList();
    this.cuentaService.deleteMetodoDePago(metodo.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Método de pago eliminado correctamente');
            //ELIMINO DE LA LISTA      
            this.metodos.forEach(function(metodoFor, index, object) {
                if(metodoFor.id == metodo.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }
  
    
  //FORMULARIO ALTA/EDICION
  //Para el Form
  public newMetodo(){
    this.metodo = new MetodoDePago();
  }
  public updateMetodo(metodo: MetodoDePago){
    this.metodo = new MetodoDePago(metodo);
  }
  
  //Desde el formulario
  public actualizeForm(metodo): void {
    let exists= false;
    this.metodos.forEach(function(metodoFor, index, object) {
        if(metodoFor.id == metodo.id) {
            object[index]= metodo;
            exists= true;
        }
    });
    if(!exists){
        this.metodos.splice(0, 0, metodo);
    }
    this.cancelForm();
  }    
  public cancelForm(event?): void{
    this.metodo = null;
  }
  
}