import { Component, OnInit } from '@angular/core';

import {environment} from '../../../environments/environment';

import {CuentaService} from '../cuenta.service';
import {DistritoService} from '../../module-distrito/distrito.service';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Cuenta} from '../models';
import {User} from '../../models/user';
import {Distrito} from '../../module-distrito/models';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
 
@Component({
  selector: 'cuentas',
  templateUrl: './cuentas.component.html'
})
export class CuentasComponent extends GenericFormComponent implements OnInit {
  public actualUser: User;
    
  public cuentas: Cuenta[];
  public params = {page: 1, per_page: 10};
  public filtro = {nombre_matches: null, tipo_eq: null, distrito_id_eq: null, sort: 'nombre'};
  public sortOptions: { [id: string] : string } = { numero_cuenta: 'Número de cuenta', nombre: 'Nombre' };
    
  public tipos: string[] = environment.tipoCuentas;
  public distritos: Distrito[] = [];
  
  //FORM
  public cuenta: Cuenta;

  public BANK_LOGO_IDS = environment.bank_logo_ids;
  public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Cuentas', active: true }];

  constructor(protected messageService: MessageService, private globalVars: GlobalVars, private cuentaService: CuentaService, private distritoService: DistritoService) {
    super(messageService);
  }

  ngOnInit() {
    this.actualUser = this.globalVars.actualUser;
    this.filtro.distrito_id_eq = this.globalVars.getActualDistrito();
      
    this.filter();
    this.distritoService.getDistritos({per_page: 1000}, {sort: 'nombre'}).subscribe(
        (rta: any) => {
            this.distritos = rta.data;
        }
    );
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.cursorWait();
    this.cuentaService.getCuentas(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.cuentas = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
        },
        error => {
            this.cursorDefault();
        }
    );
  }
  
  //ACCIONES
  changeState(cuenta: Cuenta, state, event){
    this.beforeSubmitList(event);
    cuenta.habilitado = ! cuenta.habilitado;
    this.cuentaService.updateCuenta(cuenta).subscribe(
        rta => {
            this.showSuccessList(true, 'Cuenta modificada correctamente');
        }, error => {
            cuenta.habilitado = ! cuenta.habilitado;
            this.showErrorList(error, true);
        }
    );
  }
  
  onNotifyDelete(cuenta: Cuenta):void {
    this.deleteCuenta(cuenta);
  }
    
  public deleteCuenta(cuenta: Cuenta) {
    this.beforeSubmitList();
    this.cuentaService.deleteCuenta(cuenta.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Cuenta eliminada correctamente');
            //ELIMINO DE LA LISTA      
            this.cuentas.forEach(function(cuentaFor, index, object) {
                if(cuentaFor.id == cuenta.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }  
  
  //FORMULARIO ALTA/EDICION
  //Para el Form
  public newCuenta(){
    this.cuenta = new Cuenta();
    this.cuenta.distrito = this.actualUser.distrito;
  }
  
  //Desde el formulario
  public actualizeForm(user): void {
    this.cuentas.splice(0, 0, user);
    this.cancelForm();
  }    
  public cancelForm(event?): void{
    this.cuenta = null;
  }
}