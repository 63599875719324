import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CuentaService } from 'app/module-cuenta/cuenta.service';
import { Cheque, Cuenta, MetodoDePago, Movimiento } from 'app/module-cuenta/models';
import { GenericFormComponent } from 'app/module-generic/components/generic-form.component';
import { InputDatepickerComponent } from 'app/module-generic/components/input-datepicker/input-datepicker.component';
import { MessageService } from 'app/services/message.service';
import { ProjectUtils } from 'app/services/utils';
import { environment } from 'environments/environment';

@Component({
  selector: 'movimiento-reposicion-form',
  templateUrl: './movimiento-reposicion-form.component.html',
  styleUrls: ['./movimiento-reposicion-form.component.sass']
})
export class MovimientoReposicionFormComponent extends GenericFormComponent implements OnInit {
  @ViewChild('datepicker', { static: false }) datepicker: InputDatepickerComponent;
  
  @Input() cuenta: Cuenta;

  public movimiento: Movimiento = new Movimiento();
  public metodos: MetodoDePago[] = [];
  public cuentas: Cuenta[] = [];
  public cuentasFiltro: Cuenta[] = [];
  public extraCuentas: Cuenta[] = [];
  public monedas: string[] = [environment.monedas[0]];
  public numeroCheque: number;

  public cheque: Cheque = null;
  public allowsReplacement: boolean = true;
  public allowsBeforeReplacement: boolean = false;

  public BANK_LOGO_IDS = environment.bank_logo_ids;

  public validDates: any = { min: (new Date()).setDate(0), max: new Date()};
  public defaultDate: Date = new Date();

  public showBalance: boolean = false;

  private defaultMovRestantes = {
    saldo: 0,
    movimientos: 0
  };

  public movRestantes = this.defaultMovRestantes;
  
  @Output() actualize: EventEmitter<Movimiento> = new EventEmitter<Movimiento>();

  constructor(protected messageService: MessageService, private cuentaService: CuentaService) {
    super(messageService);
  }

  ngOnInit(): void {
    this.movimiento.cuentadestino = this.cuenta;
    this.movimiento.fecha_contable = this.getFechaContableFormat(this.defaultDate);
    this.cuentaService.getMetodosDePago({ per_page: 1000 }, { sort: 'nombre' }).subscribe(
      (rta: any) => {
        this.metodos = rta.data.filter(m => {
          return m.tipo == 'TRANSFERENCIA' || m.tipo == 'CHEQUE';
        });
      }
    );
    this.cuentaService.getCuentas({ per_page: 1000 }, { distrito_id_eq: this.movimiento.cuentadestino.distrito_id, sort: 'nombre' }).subscribe(
      (rta: any) => {
        this.cuentas = rta.data.filter(c => c.id != this.movimiento.cuentadestino.id);
      }
    );
    this.movRestantes = this.defaultMovRestantes
    this.validarDestino();
  }


  public buscarCheque() {
    this.cuentaService.getCheques({ cuenta: this.movimiento.cuenta.id }, { numero_eq: this.numeroCheque, estado_di: 'ANULADO' }).subscribe(
      (rta: any) => {
        if (rta.data.length > 0) {
          this.movimiento.cheque = rta.data[0];
          this.movimiento.monto = this.movimiento.cheque.monto;
        } else {
          this.messageService.showNotification("top", "center", "warning", 'No se encontro el cheque');
        }
      }
    )
  }

  public save(event) {
    this.beforeSubmit(event);
    this.cuentaService.createMovimiento(this.movimiento).subscribe(
      (movimiento: Movimiento) => {
        this.showSuccess(true, 'Movimiento creado correctamente');
        this.actualize.emit(movimiento);
      }, err => {
        this.showError(err, true);
      });
  }

  public newCheque() {
    this.submitted = true;
    this.cheque = new Cheque();
    this.cheque.monto = this.movimiento.monto;
  }

  public actualizeFormCheque(cheque: Cheque): void {
    this.movimiento.cheque = cheque;
    this.movimiento.monto = this.movimiento.cheque.monto;
    this.cancelFormCheque();
  }
  public cancelFormCheque(event?): void {
    this.submitted = false;
    this.cheque = null;
  }


  public changeMetodo() {
    this.movimiento.cuenta = null;
    if (this.movimiento.metodopago == null) {
      this.cuentasFiltro = [];
      return;
    }
    if (this.movimiento.metodopago.tipo == 'TRANSFERENCIA' || this.movimiento.metodopago.tipo == 'CHEQUE') {
      this.cuentasFiltro = this.cuentas.filter(c => (c.tipo == 'BANCO') && (c.tipo_cuenta != 'CC$USD' && c.tipo_cuenta != 'CA$USD') && c.habilitado);
    } else {
      this.cuentasFiltro = [];
    }
    if (this.cuentasFiltro.length >= 1) {
      this.movimiento.cuenta = this.cuentasFiltro[0];
    }
  }

  public validarDestino() {
    this.cuentaService.getEstadoCuenta(this.movimiento.cuentadestino.id, this.movimiento.fecha_contable).subscribe(
      (data: any) => {
        this.allowsReplacement = data['movimiento'] != false;
        this.movRestantes.movimientos = data['movimientosRestantes'];
        this.movRestantes.saldo = data['saldoReposicionRestante'];
      }
    );
  }

  public enabledSubmit() {
    this.submitted = false;
  }

  public disabledSubmit() {
    this.submitted = true;
  }

  public onChangeAccount() {
    this.cancelFormCheque();
  }

  public enableBeforeReplacement() {
    let today = new Date();
    this.validDates.min = (new Date()).setMonth(today.getMonth() - 1, 0);
    this.validDates.max = today;
    this.allowsBeforeReplacement = true;
    this.defaultDate.setMonth(this.defaultDate.getMonth()-1, 1);
    this.movimiento.fecha_contable = this.getFechaContableFormat(this.defaultDate);
    setTimeout(() => {
      this.datepicker.toggleDatePicker();
      this.validarDestino();
    }, 100)
  }

  public hideRepoBalance() {
    setTimeout(() => {
      this.showBalance = false
    }, 2000)
  }

  private getFechaContableFormat(date: Date): string{
    return new Date(this.defaultDate.getTime() - (this.defaultDate.getTimezoneOffset() * 60000)).toISOString().substring(0,10) + ' 00:00:00';
  }
}
