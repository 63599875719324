import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, NgZone} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {environment} from '../../../environments/environment';

import {FacturaService} from '../factura.service';
import {ProveedorService} from '../../module-proveedor/proveedor.service';
import {DistritoService} from '../../module-distrito/distrito.service';
import {ProveedorCommunicate} from '../../module-proveedor/proveedor.communicate';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Factura, ImputacionContable} from '../models';
import {Proveedor, ProveedorImputacion} from '../../module-proveedor/models';
import {Distrito} from '../../module-distrito/models';
import {User} from '../../models/user';
import { Title } from '@angular/platform-browser';
import {first} from 'rxjs/operators';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';

declare var $: any;

@Component({
    selector: 'facturas_form',
    templateUrl: 'facturas_form.component.html'
})

export class FacturasFormComponent extends GenericFormComponent implements OnInit, AfterViewInit {
    @Input() id: number = null;
    public actualUser: User;
    public factura: Factura = new Factura();
    
    public imputacionesContables: ImputacionContable[] = [];
    public imputaciones: ProveedorImputacion[] = [];
    public tipos: string[] = environment.tiposFactura;
    public ivas: number[] = environment.ivas;
    public monedas: string[] = environment.monedas;
    public distritos: Distrito[] = [];

    public INIT_BREADCRUM_ITEMS = [ {title: 'Home', ref: '#' }, {title: 'Facturas', ref: '#/facturas' }];
    public breadcrumbItems: BreadcrumbItems[] = Object.assign([], this.INIT_BREADCRUM_ITEMS);

    private debounceTime;
    public proveedores: Proveedor[] = [];

    @ViewChild('select_inputacion_contable', { static: true }) selectImputacionContable: ElementRef;
    
    constructor(protected messageService: MessageService, private facturaService: FacturaService, private proveedorCommunicate: ProveedorCommunicate, 
        private route: ActivatedRoute, private router: Router, private globalVars: GlobalVars, private proveedorService: ProveedorService, private distritoService: DistritoService, 
        private _titleService: Title, private ngZone: NgZone) {
        super(messageService);
    }

    ngOnInit(){
        this.actualUser = this.globalVars.actualUser;
        
        this.distritoService.getDistritos({per_page: 1000}, {sort: 'nombre'}).subscribe(
            (rta: any) => {
                this.distritos = rta.data;
            }
        );
        if (this.id) {
            this.initiaLoad(this.id);
        } else {
            this.route.params.subscribe(
                params => {
                    if (params.id) {
                        this.initiaLoad(params.id);
                    } else {
                        this.factura.fecha = new Date().toISOString().slice(0,10) + " 00:00:00";
                        this.factura.fecha_vencimiento = this.factura.fecha;
                        this.factura.fecha_contable = this.factura.fecha;
                        let distrito: Distrito = new Distrito();
                        distrito.id = this.globalVars.getActualDistrito();
                        this.factura.distrito = distrito;
                        this.factura.distrito_id = this.factura.distrito.id;
                        this.breadcrumbItems.push({title: 'Agregar' , active: true });
                    }
                }
            );
        }
    }

    ngAfterViewInit() {
        this.ngZone.onStable.pipe(first()).subscribe(() => {
            $(this.selectImputacionContable.nativeElement).selectpicker();
        }); 
    }

    public initiaLoad(id) {
        this.facturaService.getFactura(id).subscribe(
            (factura: Factura) => {
                this.factura = factura;
                this.breadcrumbItems = Object.assign([], this.INIT_BREADCRUM_ITEMS);
                this.breadcrumbItems.push({title: `Factura # ${this.factura.print()}` , active: true });
                
                if (this.factura.distrito_id != this.globalVars.getActualDistrito() && ! this.globalVars.actualUser.multi_distrito) {
                    this.messageService.showNotification('top', 'center', 'danger', 'Usted no tiene acceso a esta factura');
                    this.router.navigate(['/facturas']);
                } else if (this.factura.distrito_id != this.globalVars.getActualDistrito() && this.globalVars.actualUser.multi_distrito) {
                    //fix de multi_distrito. Todos los usuarios multi_distritos puede generar facturas cruzadas
                    //this.globalVars.setActualDistrito(this.factura.distrito_id);
                }
                
                this.loadImputaciones();
                this.loadImputacionesContable();
                this._titleService.setTitle(`Factura # ${this.factura.print()} - ${this.factura.proveedor.nombre}`)
            }, error => {
                this.messageService.showNotification('top', 'center', 'danger', 'No se encontro la factura');
                this.router.navigate(['facturas']);
            }
        );
    }
        
    public loadImputaciones() {
        if (this.factura.proveedor) { 
            this.proveedorService.getImputacionesProveedor(this.factura.proveedor.id).subscribe(
                (imputaciones: ProveedorImputacion[]) => {
                    this.imputaciones = imputaciones;
                }
            )
        } else {
            this.imputaciones = []
        }
    }
    
    public loadImputacionesContable() {
        if (this.factura.proveedor) { 
            this.proveedorService.getImputacionesContablesProveedor(this.factura.proveedor.id).subscribe(
                (imputaciones: ImputacionContable[]) => {
                    this.imputacionesContables = imputaciones;
                    this.refreshSelectPick();
                    if (this.imputacionesContables.length == 0) {
                        this.proveedorService.getImputacionesContablesProveedor(this.factura.proveedor.id).subscribe(
                            (imputaciones: ImputacionContable[]) => {
                                this.imputacionesContables = imputaciones;
                                this.refreshSelectPick();
                            }
                        )
                    }
                }
            )
        } else {
            this.imputacionesContables = [];
            this.refreshSelectPick();
        }
    }

    private refreshSelectPick() {
        this.ngZone.onMicrotaskEmpty.pipe(first()).subscribe(() => {
            $(this.selectImputacionContable.nativeElement).selectpicker('refresh');
        });
    }
        
    public save(event){
        this.beforeSubmit(event);
        if(! this.factura.id){
            this.facturaService.createFactura(this.factura).subscribe(
                (factura: Factura) => {
                    this.showSuccess(true, 'Factura Nro '+factura.punto_venta+'-'+factura.numero+' del '+factura.proveedor.nombre+' creada correctamente');;
                    this.router.navigate(['/facturas', factura.id]);   
                },err => {
                    this.showError(err, true);
                });
        } else {
            this.facturaService.updateFactura(this.factura).subscribe(
                (factura: Factura) => {
                    this.factura.actualizarMonto(factura.monto, factura.monto_retencion);
                    this.showSuccess(true, 'Factura Nro '+factura.punto_venta+'-'+factura.numero+' del '+factura.proveedor.nombre+' modificada correctamente');
                },err => {
                    this.showError(err, true);
                });
        }
    }
    
    public saveRetencion(event){
        this.beforeSubmit(event);
        this.facturaService.updateRetencionFactura(this.factura).subscribe(
            (factura: Factura) => {
                this.factura.actualizarMonto(factura.monto, factura.monto_retencion);
                this.showSuccess(true, 'Factura modificada correctamente');
            },err => {
                this.showError(err, true);
            });
    }

    public detalleProveedor(proveedor: Proveedor) {
        this.proveedorCommunicate.proveedorModalPopUp(proveedor.id);
    }

    public generarOrden(){
        this.beforeSubmit();
        this.facturaService.createOrdenFactura(this.factura.id).subscribe(
            (orden) => {
                this.showSuccess(true, 'Orden generada correctamente');;
                this.router.navigate(['/ordenespago', orden.id]);   
            },err => {
                this.showError(err, true);
            });
    }
    
    //
    //UTILS
    public updateFecha(fecha) {
        this.factura.fecha = fecha;
        this.factura.fecha_vencimiento = this.factura.fecha;
        this.factura.fecha_contable = this.factura.fecha;
    }

    public customCompareImputacionContable(i1: ImputacionContable, i2: ImputacionContable) {
        if(i1 && i2){
            return i1.id == i2.id;
        }else{
            return i1 == i2;
        }
    }
    
    public customCompareImputacion(i1: ProveedorImputacion, i2: ProveedorImputacion) {
        if(i1 && i2){
            return i1.id == i2.id;
        }else{
            return i1 == i2;
        }
    }
    
    public actualizarRetencion() {
        if (! this.factura.tienePagos()) {
            this.factura.retencion = 0;
            if (this.factura.proveedor_imputacion && this.factura.proveedor_imputacion.retencion) {
                this.factura.retencion = this.factura.proveedor_imputacion.imputacion.ganancia_inscriptos;
            }
        }
    }

    public checkNumber(name, value) {
        if (value == null || value <= 0) {
            this.setInputError(name, "Campo incorrecto");
        } else {
            this.removeInputError(name);
        }
    }

    public onSelectProvider() {
        this.loadImputaciones();
        this.loadImputacionesContable();
        this.factura.tipo = null;
        this.tipos = this.factura.proveedor.tipoFacturasDisponibles(environment.tiposFactura);
        if (this.tipos.length == 1) {
            this.factura.tipo = this.tipos[0];
        }
    }
}
