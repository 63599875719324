import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule }   from '@angular/forms';
import { RouterModule } from '@angular/router';

import {TranslateComponentModule} from '../translate-module/translate-component.module';

import {HomeComponent} from './home/home.component';

//GENERALES
import {FixedPluginComponent} from './fixedplugin/fixedplugin.component';
import {FooterComponent} from './footer/footer.component';
import {NavbarComponent} from './navbar/navbar.component';
import {SidebarComponent} from './sidebar/sidebar.component';

//PAGINAS SIMPLES
import {ConfiguracionComponent} from './configuracion/configuracion.component';
import {PerfilComponent} from './perfil/perfil.component';
import { GenericModule } from 'app/module-generic/generic.module';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SearchBarService } from './search-bar/search-bar.service';
import { SearchResultsComponent } from './search-results/search-results.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    
    TranslateComponentModule,
    GenericModule
  ],
  declarations: [
    HomeComponent,
  
    FixedPluginComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    
    ConfiguracionComponent,
    PerfilComponent,
    SearchBarComponent,
    SearchResultsComponent
  ],
  exports: [
    HomeComponent,
  
    FixedPluginComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    
    ConfiguracionComponent,
    PerfilComponent
  ],
  providers: [
    SearchBarService
  ]
})
export class BaseModule { }
