
<div class="container-fluid">
    
    <div class="row">
        <div class="card">
            <div class="card-content">
                <breadcrumb [items]="breadcrumbItems"></breadcrumb>

                <form (submit)="save($event)" #datosOrdenForm="ngForm">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-12 col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4 text-center" [hidden]="orden.id || orden.proveedor">
                                <provider-select [(providerModel)]="orden.proveedor" title="Seleccione una Persona Jurídica"></provider-select>
                            </div>
                            <ng-container *ngIf="orden.proveedor">
                                <h5><strong>Persona Jurídica</strong></h5>
                                <div class="col-md-3">
                                    <p><strong class="mr-1">Nombre:</strong><a (click)="detalleProveedor(orden.proveedor)" rel="tooltip" title="Detalle">{{orden.proveedor.nombre}}</a></p>
                                </div>
                                <div class="col-md-2">
                                    <p><strong>CUIT:</strong> {{orden.proveedor.cuit | cuitformat}}</p>
                                </div>
                                <div class="col-md-3">
                                    <p><strong>Email:</strong> {{orden.proveedor.email}}</p>
                                </div>
                                <div class="col-md-3" *ngIf="globalVars.actualUser.multi_distrito">
                                    <div class="row">
                                        <label class="col-md-3 label-on-left">Distrito: </label>
                                        <div class="col-md-9" *ngIf="globalVars.actualUser.multi_distrito">
                                            <select class="form-control" data-style="btn btn-wd btn-block" name="distrito" [(ngModel)]="orden.distrito_id" [disabled]="orden.id">
                                                <option *ngFor="let distrito of distritos" [ngValue]="distrito.id">{{distrito.nombre}}</option>
                                            </select>
                                            <small [hidden]="!inputError.distrito_id" [innerHtml]="inputError.distrito_id" class="text-danger"></small>
                                        </div>
                                    </div>
                                </div>


                                <div class="row mt-2">
                                    <div class="col-md-12 text-center" *ngIf="!orden.id">
                                        <button type="button" class="btn btn-primary" (click)="orden.proveedor = null">Cambiar Persona Jurídica</button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <div class="col-sm-12 mt-2" *ngIf="orden.proveedor">
                            <ng-container>
                                <h5><strong>Datos</strong></h5>
                                <div class="col-xs-12 col-md-4" *ngIf="orden.numero != null">
                                    <div class="form-group">
                                        <label>Número</label>
                                        <div class="row">
                                            <div class="col-xs-4">
                                                <select class="form-control" data-style="btn btn-wd btn-block" name="punto_venta" [(ngModel)]="orden.punto_venta" [disabled]="orden.id">
                                                    <option [ngValue]="1">1</option>
                                                    <!-- <option [ngValue]="3">3</option> -->
                                                </select>
                                            </div>
                                            <div class="col-xs-8">
                                                <input type="number" name="numero" [(ngModel)]="orden.numero" class="form-control" placeholder="XXXXXX" disabled="disabled" >
                                            </div>
                                        </div>
                                        <small [hidden]="!inputError.punto_venta" [innerHtml]="inputError.punto_venta" class="text-danger"></small>
                                        <small [hidden]="!inputError.numero" [innerHtml]="inputError.numero" class="text-danger"></small>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Fecha</label>
                                        <input-datepicker [(dateModel)]="orden.fecha" [isStringFormat]="true" [required]="true" [disabled]="orden.id"></input-datepicker>
                                        <small [hidden]="!inputError.fecha" [innerHtml]="inputError.fecha" class="text-danger"></small>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Moneda</label>
                                        <select class="form-control" data-style="btn btn-wd btn-block" name="moneda" [(ngModel)]="orden.moneda" [disabled]="orden.id">
                                            <option [ngValue]="null">Seleccione una Moneda</option>
                                            <option *ngFor="let moneda of monedas" [ngValue]="moneda">{{moneda | translate: 'MONEDA'}}</option>
                                        </select>
                                        <small [hidden]="!inputError.moneda" [innerHtml]="inputError.moneda" class="text-danger"></small>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>


                    <div class="row text-center" *ngIf="orden.proveedor"><!-- O CONFIRMO TODO O NO CONFIRMO NADA DE LOS RECIBOS PARA PONER LA ORDEN CONFIRMADA -->
                        <button class="btn btn-primary" type="submit" *ngIf="!orden.id" [disabled]="submitted">Iniciar Orden</button>
                        <button class="btn btn-primary ml-2"  (click)="cancel()"  *ngIf="!orden.id" [disabled]="submitted">Cancelar</button>
                        <button class="btn btn-primary ml-2" type="button" *ngIf="orden.id && orden.estado == 'A' && orden.tienePagos()" (confirm-click)="changeState(orden, 'C', $event)" confirm-message="¿Está seguro que desea confirmar la Orden?" [disabled]="submitted">Confirmar Orden</button>
                        <button class="btn btn-primary ml-2" type="button" *ngIf="orden.id && orden.estado == 'P' && orden.tienePagos()" (confirm-click)="changeState(orden, 'A', $event)" confirm-message="¿Está seguro que desea aprobar la Orden?" [disabled]="submitted">Aprobar Orden</button>
                        <button class="btn btn-primary ml-2" type="button" *ngIf="orden.id && orden.estado == 'A'" (confirm-click)="changeState(orden, 'P', $event)" confirm-message="¿Está seguro que desea desaprobar la Orden?" [disabled]="submitted">Desaprobar Orden</button>
                        <button class="btn btn-primary ml-2" type="button" *ngIf="orden.id && orden.estado == 'C'" (confirm-click)="changeState(orden, 'A', $event)" confirm-message="¿Está seguro que desea desconfirmar la Orden?" [disabled]="submitted">Desconfirmar Orden</button>
                        <button class="btn btn-primary ml-2" type="button" *ngIf="orden.id && orden.estado == 'P'" (confirm-click)="changeState(orden, 'R', $event)" confirm-message="¿Está seguro que desea anular la Orden?" [disabled]="submitted">Anular Orden</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    
    <ordenes_facturas *ngIf="orden.id" [orden]="orden"></ordenes_facturas>
        
    <div class="row" *ngIf="orden.id">
        <div class="card">
            <div class="card-content">
                <h4 class="card-title">Importe / Tasas</h4>   
                <hr>
                                 
                <div class="row">
                    <div class="col-md-4 col-md-offset-8">                        
                        <p><strong>TOTAL:</strong> $ {{orden.monto | number: '1.2-2'}}</p>

                        <p><strong>COBRADO:</strong> $ {{orden.pagado | number: '1.2-2'}}</p>
                        <p><strong>A COBRAR:</strong> $ {{orden.aPagar() | number: '1.2-2'}}</p>


                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <recibos_orden *ngIf="orden.id && (orden.estado == 'A' || orden.estado == 'C')" [orden]="orden" [proveedor]="orden.proveedor"></recibos_orden>


    <!-- <recibos_orden *ngIf="orden.id && (orden.estado == 'A' || orden.estado == 'C')" [orden]="orden" [proveedor]="orden.proveedor" (reciboParaCrear)="onNotaDeCreditoAfip($event)"></recibos_orden> -->

    <!-- <nota_de_credito_orden *ngIf="orden.id && (orden.estado == 'A' || orden.estado == 'C')" [orden]="orden" [proveedor]="orden.proveedor" [(reciboParaCrear)]="reciboParaCrear"></nota_de_credito_orden> -->

    <notas-credito-orden *ngIf="orden.id && (orden.estado == 'A' || orden.estado == 'C')" [orden]="orden"></notas-credito-orden>


</div>


