<div #proveedoresModal class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" *ngIf="show">
            <div class="modal-header">
                <h5 class="modal-title col-sm-11 mt-1">Proveedores</h5>
                <button class="btn btn-simple btn-info btn-icon like col-sm-1" title="Cerrar" data-dismiss="modal" aria-label="Close" (click)="hide()"><i class="ti-close"></i></button>
            </div>
            <div class="modal-body">
                <div class="row">                        
                    <form class="form-inline" (submit)="filter(1, $event)" #searchProveedoresForm="ngForm">
                        <div class="filter-section">
                            <div class="col-md-12 search-filter">
                                <div class="col-md-4">
                                    <label for="search_elastic">Buscador:</label>
                                    <div class="input-group filter-input-group">
                                        <input class="form-control" type="text" placeholder="Nombre / CUIT / Email" name="search_elastic" [(ngModel)]="filtro.search_elastic" (input)="onChangeSearch($event)">
                                        <span class="input-group-addon" (click)="filter(1)"><i class="cmp-i busqueda i-sm"></i></span>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="toolbar">
                                        <button type="button" class="btn btn-primary pull-right" (click)="seleccionar()">Seleccionar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                
                <div class="row">                    
                    <div class="col-md-12 table-responsive">
                        <table class="table table-hover">
                            <thead class="text-primary">
                                <tr>
                                    <td>-</td>
                                    <th><b>Nombre</b></th>
                                    <th><b>CUIT</b></th>
                                    <th><b>Condición IVA</b></th>
                                    <th><b>Email</b></th>
                                    <!--<th><b>Distrito</b></th>-->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let proveedor of proveedores'>
                                    <td><input type="radio" [checked]="proveedor.selectedInTable" (change)="changeSelected(proveedor)"></td>
                                    <td>{{proveedor.nombre}}</td>
                                    <td>{{proveedor.cuit | cuitformat}}</td>
                                    <td>{{proveedor.condicion_iva | translate: 'CONIVA'}}</td>
                                    <td>{{proveedor.email}}</td>
                                    <!--<td>{{proveedor.distrito.nombre}}</td>-->
                                </tr>
                                <tr *ngIf="proveedores != null && proveedores.length == 0" class="text-center">
                                    <td colspan="5">No se encontraron proveedores</td>
                                </tr>
                            </tbody>
                        </table>                        
                        <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)" *ngIf="proveedores != null && proveedores.length > 0"></pager>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>