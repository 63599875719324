<div class="card">
    <div class="card-content">
        <h4 class="card-title">{{!distrito.id ? 'Nuevo Distrito' : 'Modificación de Distrito'}}</h4>
        <hr>
        <form class="form-horizontal" (submit)="save($event)" #datosForm="ngForm">
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Nombre de Distrito</label>
                        <div class="col-md-9">                                  
                            <input #firstElementDistrito type="text" name="nombre" [(ngModel)]="distrito.nombre" class="form-control" placeholder="Distrito La Plata" required>
                            <small [hidden]="!inputError.nombre" [innerHtml]="inputError.nombre" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Código</label>
                        <div class="col-md-9">
                            <input type="text" name="codigo" [(ngModel)]="distrito.codigo" class="form-control" placeholder="distrito-1-la-plata" required>
                            <small [hidden]="!inputError.codigo" [innerHtml]="inputError.codigo" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                
            </div>

            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Dirección</label>
                        <div class="col-md-9">                                 
                            <input type="text" name="direccion" [(ngModel)]="distrito.direccion" class="form-control" placeholder="Calle 3 Nro 1234" required>
                            <small [hidden]="!inputError.direccion" [innerHtml]="inputError.direccion" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>                    
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Teléfonos</label>
                        <div class="col-md-9">
                            <input type="text" name="telefonos" [(ngModel)]="distrito.telefonos" class="form-control" placeholder="221-544-1335" required>
                            <small [hidden]="!inputError.telefonos" [innerHtml]="inputError.telefonos" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>

            <div class="row text-center">
                <button class="btn btn-default btn-cancel" type="button" (confirm-click)="cancelForm($event)" confirm-message="¿Está seguro que desea cancelar este formulario?">Cancelar</button>
                <button class="btn btn-primary ml-2" type="submit" [disabled]="submitted">Guardar</button>
            </div>

            <a [routerLink]="['/usuarios']"  [queryParams]="{distrito: distrito.id}"  *ngIf="distrito.id" class="text-left pull-right">
                <p>Ver usuarios<i class="ml-1 ti-user"></i></p>
            </a>

            <div class="clearfix"></div>
        </form>
    </div>
</div>