import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVars } from 'app/services/globalVars';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SearchResults } from './search-bar.model';

@Injectable()
export class SearchBarService {
  private searchUrl = 'search';

  constructor(private http: HttpClient, private globalVars: GlobalVars) {        
  }

  search(params: any, filters: any): Observable<SearchResults> {
    const url = `${this.globalVars.apiHost}${this.searchUrl}`; 
    
    return this.http.get<any>(url + this.globalVars.filtroToUrlV2(filters), this.globalVars.getOptionsRequest(params)).pipe(       
    //return this.http.get<SearchResults>(url + this.globalVars.filtroToUrl(filtro)).pipe(
        map((results: SearchResults) => {
            return results
        }), catchError(this.globalVars.tratarErrores)
    );
  }
}
