<div class="card">
    <div class="card-content">
        <breadcrumb [items]="breadcrumbItems"></breadcrumb>
        
        <form class="form-horizontal" (submit)="save($event)" #datosForm="ngForm" *ngIf="user">
            <div class="form-group">
                <div class="col-sm-12 col-md-6 col-md-offset-3 profile-header">
                    <div class="row">
                        <div class="col-xs-12 col-md-5 text-center mr-2 visible-xs visible-sm">
                            <img src="../assets/icons/editar-config-cuenta.svg" class="profile-img"/>
                        </div>
                        <div class="col-xs-12 col-md-5 text-right mr-2 hidden-xs hidden-sm">
                            <img src="../assets/icons/editar-config-cuenta.svg" class="profile-img"/>
                        </div>


                        <div class="col-xs-12 col-md-6 text-center visible-xs visible-sm">
                            <h3>
                                {{user.username}}
                            </h3> 
                            <a (click)="editProfile()" [hidden]="!disabledEdit">Editar perfil</a>
                        </div>   
                        <div class="col-xs-12 col-md-6 text-left hidden-xs hidden-sm">
                            <h3>
                                {{user.username}}
                            </h3> 
                            <a (click)="editProfile()" [hidden]="!disabledEdit">Editar perfil</a>
                        </div>  

                    </div>
                </div>               
            </div>

            <br>
            <br class="visible-xs visible-sm">
            <br class="visible-xs visible-sm">
            <br class="visible-xs visible-sm">

            <div [hidden]="!disabledEditPassword">

                <div class="form-group">
                    <div class="col-xs-10 col-xs-offset-1 col-md-4 col-md-offset-4">
                        <div class="row">
                            <label>Nombre</label>
                            <div>
                                <input type="text" name="nombre" #nameField [(ngModel)]="user.nombre" class="form-control" [disabled]="disabledEdit" required>
                                <small [hidden]="!inputError.nombre" [innerHtml]="inputError.nombre" class="text-danger"></small>
                            </div>                            
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-10 col-xs-offset-1 col-md-4 col-md-offset-4">
                        <div class="row">
                            <label>Email</label>
                            <div>                                 
                                <input type="email" name="email" [(ngModel)]="user.email" class="form-control" [disabled]="disabledEdit" (keyup)="removeInputError('email')" required>
                                <small [hidden]="!inputError.email" [innerHtml]="inputError.email" class="text-danger"></small>
                            </div>                            
                        </div>
                    </div>  
                </div>
    
                <div class="form-group">
                    <div class="col-xs-10 col-xs-offset-1 col-md-4 col-md-offset-4">
                        <div class="row">
                            <label>Distrito</label>
                            <div>
                                <input type="text" name="distrito" [(ngModel)]="user.distrito.nombre" class="form-control" readonly="readonly">
                            </div>                            
                        </div>
                    </div>
                </div>

                <div class="row text-center mt-2" *ngIf="!disabledEdit">
                    <a (click)="disabledEditPassword = false">Cambiar Contraseña</a>
                </div>
            </div>

            <div [hidden]="disabledEditPassword">
                <div class="form-group">
                    <div class="col-xs-10 col-xs-offset-1 col-md-4 col-md-offset-4">
                        <div class="row">
                            <label>Nueva Contraseña</label>
                            <div>
                                <input type="password" name="password" [(ngModel)]="user.password" class="form-control" [required]="!user.id">
                                <small [hidden]="!inputError.password" [innerHtml]="inputError.password" class="text-danger"></small>
                            </div>                            
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-10 col-xs-offset-1 col-md-4 col-md-offset-4">
                        <div class="row">
                            <label>Repita Contraseña</label>
                            <div>
                                <input type="password" name="password" [(ngModel)]="user.confPassword" class="form-control" [required]="!user.id" (keyup)="removeInputError('confPassword')">
                                <small [hidden]="!inputError.confPassword" [innerHtml]="inputError.confPassword" class="text-danger"></small>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>

            <div class="row text-center mt-2" *ngIf="!disabledEdit">
                <button class="btn btn-default btn-cancel" type="button" (click)="restoreInitialValues()">Cancelar</button>
                <button class="btn btn-primary ml-1" type="submit" [disabled]="submitted">Guardar</button>
            </div>
            <div class="clearfix"></div>
        </form>
    </div>
</div>