<div class="container-fluid">
    <!-- end row -->
    <div class="card">
        <div class="card-content">
            <h4 class="card-title">Filtros</h4>
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Buscar</label>
                        <input type="text" placeholder="Buscar" class="form-control">
                    </div>
                </div>
                <div class="col-md-1">
                    <div class="form-group">
                        <label>ID</label>
                        <input type="text" placeholder="ID" class="form-control">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Desde</label>
                        <input type="text" class="form-control datepicker" placeholder="Desde"/>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Hasta</label>
                        <input type="text" class="form-control datepicker" placeholder="Hasta"/>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label>Cuit</label>
                        <input type="text" class="form-control" placeholder="Cuit"/>
                    </div>
                </div>
            </div>

            <br/><br/>

            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Tipo</label>
                        <select class="form-control" data-style="btn btn-wd btn-block" title="(Ninguno)" data-size="7">
                            <option value="id">Lorem ipsum</option>
                            <option value="ms">Lorem ipsum</option>
                            <option value="ca">Lorem ipsum</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Pagado</label>
                        <select class="form-control" data-style="btn btn-wd btn-block" title="(Todos)" data-size="7">
                            <option value="id">Lorem ipsum</option>
                            <option value="ms">Lorem ipsum</option>
                            <option value="ca">Lorem ipsum</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Número</label>
                        <input type="text" class="form-control" placeholder="0"/>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Sucursal</label>
                        <select class="form-control" data-style="btn btn-wd btn-block" title="(Ninguno)" data-size="7">
                            <option value="id">Lorem ipsum</option>
                            <option value="ms">Lorem ipsum</option>
                            <option value="ca">Lorem ipsum</option>
                        </select>
                    </div>
                </div>
            </div>



            <br/><br/>
        </div>
    </div> <!-- end card -->


    <div class="card">
        <div class="card-content">
            <h4 class="card-title">Viáticos</h4>
            <div class="toolbar">
                <!--        Here you can write extra buttons/actions for the toolbar              -->
            </div>
            <div class="fresh-table">
                <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%" style="width:100%">
                    <thead>
                        <tr>
                            <th>{{ dataTable.headerRow[0] }}</th>
                            <th>{{ dataTable.headerRow[1] }}</th>
                            <th>{{ dataTable.headerRow[2] }}</th>
                            <th>{{ dataTable.headerRow[3] }}</th>
                            <th>{{ dataTable.headerRow[4] }}</th>
                            <th>{{ dataTable.headerRow[5] }}</th>
                            <th>{{ dataTable.headerRow[6] }}</th>
                            <th>{{ dataTable.headerRow[7] }}</th>
                            <th class="disabled-sorting">{{ dataTable.headerRow[8] }}</th>
                            <th class="disabled-sorting">{{ dataTable.headerRow[9] }}</th>
                            <th class="disabled-sorting">{{ dataTable.headerRow[10] }}</th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th>{{ dataTable.footerRow[0] }}</th>
                            <th>{{ dataTable.footerRow[1] }}</th>
                            <th>{{ dataTable.footerRow[2] }}</th>
                            <th>{{ dataTable.footerRow[3] }}</th>
                            <th>{{ dataTable.footerRow[4] }}</th>
                            <th>{{ dataTable.footerRow[5] }}</th>
                            <th>{{ dataTable.footerRow[6] }}</th>
                            <th>{{ dataTable.footerRow[7] }}</th>
                            <th>{{ dataTable.footerRow[8] }}</th>
                            <th>{{ dataTable.footerRow[9] }}</th>
                            <th>{{ dataTable.footerRow[10] }}</th>
                        </tr>
                    </tfoot>
                    <tbody>
                        <tr *ngFor="let row of dataTable.dataRows">
                            <td>{{row[0]}}</td>
                            <td>{{row[1]}}</td>
                            <td>{{row[2]}}</td>
                            <td>{{row[3]}}</td>
                            <td>{{row[4]}}</td>
                            <td>{{row[5]}}</td>
                            <td class="{{row[7]}}">{{row[6]}}</td>
                            <td><span class="ti-autorizado ti-check"></span></td>
                            <td><span class="ti-espera ti-close"></span></td>
                            <td><span ></span></td>
                            <td>
                                <button class="btn btn-simple btn-info btn-icon like"><i class="ti-heart"></i></button>
                                <button class="btn btn-simple btn-warning btn-icon edit"><i class="cmp-i editar i-sm"></i></button>
                                <button class="btn btn-simple btn-danger btn-icon remove"><i class="ti-close"></i></button>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- end content-->
    </div>
    <!--  end card  -->
</div>