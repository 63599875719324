import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';

//import 'rxjs/add/operator/toPromise';
import { Observable ,  Subject } from 'rxjs';
// Import RxJs required methods
import { map, catchError } from 'rxjs/operators';

import { GlobalVars } from '../services/globalVars';
import { Cache } from '../services/cache';

import {Factura, Producto, NotaDeCredito, ImputacionContable, ImputacionVenta} from './models';

@Injectable()
export class FacturaService{
    private facturasUrl = 'facturacion/factura_ventas';
    private productosUrl = 'facturacion/producto_ventas';
    private imputacionesContablesUrl = 'imputaciones_contables';
    private imputacionesVentasUrl = 'facturacion/imputaciones_ventas';
    private notasUrl = 'notas';

    constructor(private http: HttpClient, private globalVars: GlobalVars, private cache: Cache) {        
    }
        
    getFacturas(params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.facturasUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new Factura(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    getFactura(id): Observable<Factura> {
        const url= `${this.globalVars.apiHost}${this.facturasUrl}/${id}`;
        return this.http.get<Factura>(url, this.globalVars.getOptionsRequest()).pipe(
            map((factura: Factura) => {
                return new Factura(factura);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createFactura(factura: Factura): Observable<Factura> {
        const url = `${this.globalVars.apiHost}${this.facturasUrl}`;
        let body= JSON.parse(JSON.stringify(factura));
        body.proveedor_id = factura.proveedor ? factura.proveedor.id : null;
        body.proveedor_imputacion_id = factura.proveedor_imputacion ? factura.proveedor_imputacion.id : null;
        body.imputacion_venta_id = factura.imputacion_venta ? factura.imputacion_venta.id : null;
        body.distrito_id = factura.distrito.id;  
        return this.http.post<Factura>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((factura: Factura) => {
                return new Factura(factura);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
     
    updateFactura(factura: Factura): Observable<Factura> {
        const url = `${this.globalVars.apiHost}${this.facturasUrl}/${factura.id}`;
        let body= JSON.parse(JSON.stringify(factura));        
        body.proveedor_id = factura.proveedor.id;
        body.proveedor_imputacion_id = factura.proveedor_imputacion ? factura.proveedor_imputacion.id : null;
        body.imputacion_venta_id = factura.imputacion_venta ? factura.imputacion_venta.id : null;
        if (factura.tienePagos()) {
            delete body.proveedor_id;
            //delete body.proveedor_imputacion_id;
            delete body.monto;
            delete body.moneda;
            delete body.proveedor_imputacion_id;
            delete body.monto;
            delete body.iva;
        }
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((factura: Factura) => {
                return new Factura(factura);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    cambiarEstadoFactura(factura: Factura, estado: string): Observable<Factura> {
        const url = `${this.globalVars.apiHost}${this.facturasUrl}/${factura.id}`;
        let body = {estado: estado};
        return this.http.patch(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((factura: Factura) => {
                return new Factura(factura);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
      
    deleteFactura(id: number): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.facturasUrl}/${id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //PRODUCTOS DE FACTURA
    getProductosFactura(id: number): Observable<Producto[]> {
        const url = `${this.globalVars.apiHost}${this.facturasUrl}/${id}/producto_ventas`;        
        return this.http.get<any>(url, this.globalVars.getOptionsRequest()).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new Producto(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //PRODUCTOS
    createProducto(producto: Producto): Observable<Producto> {
        const url = `${this.globalVars.apiHost}${this.productosUrl}`;
        let body= JSON.parse(JSON.stringify(producto));
        body.factura_venta_id = producto.factura.id;
        return this.http.post<Producto>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((producto: Producto) => {
                return new Producto(producto);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
     
    updateProducto(producto: Producto): Observable<Producto> {
        const url = `${this.globalVars.apiHost}${this.productosUrl}/${producto.id}`;
        let body= JSON.parse(JSON.stringify(producto));
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((producto: Producto) => {
                return new Producto(producto);
            }), catchError(this.globalVars.tratarErrores)
        );
    }    
      
    deleteProducto(id: number): Observable<Producto> {
        const url = `${this.globalVars.apiHost}${this.productosUrl}/${id}`;
        return this.http.delete<Producto>(url, this.globalVars.getOptionsRequest()).pipe(
            map((producto: Producto) => {
                return new Producto(producto);
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    //
    //NOTAS
    getNotas(params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.notasUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new NotaDeCredito(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    //
    //NOTAS DE FACTURA
    getNotasFactura(id: number): Observable<NotaDeCredito[]> {
        const url = `${this.globalVars.apiHost}${this.facturasUrl}/${id}/notas`;        
        return this.http.get<NotaDeCredito[]>(url, this.globalVars.getOptionsRequest()).pipe(
            map((notas: NotaDeCredito[]) => {
                return notas.map(nota => new NotaDeCredito(nota));
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    createNota(nota: NotaDeCredito): Observable<NotaDeCredito> {
        const url = `${this.globalVars.apiHost}${this.notasUrl}`;
        let body= JSON.parse(JSON.stringify(nota));
        body.factura_id = nota.factura.id;
        return this.http.post<NotaDeCredito>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((nota: NotaDeCredito) => {
                return new NotaDeCredito(nota);
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    deleteNota(id: number): Observable<NotaDeCredito> {
        const url = `${this.globalVars.apiHost}${this.notasUrl}/${id}`;
        return this.http.delete<NotaDeCredito>(url, this.globalVars.getOptionsRequest()).pipe(
            map((nota: NotaDeCredito) => {
                return new NotaDeCredito(nota);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //IMPUTACIONES CONTABLES
    getImputacionesContables(params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.imputacionesContablesUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new ImputacionContable(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    getImputacionContable(id): Observable<ImputacionContable> {
        const url= `${this.globalVars.apiHost}${this.imputacionesContablesUrl}/${id}`;
        return this.http.get<ImputacionContable>(url, this.globalVars.getOptionsRequest()).pipe(
            map((imputacion: ImputacionContable) => {
                return new ImputacionContable(imputacion);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createImputacionContable(imputacion: ImputacionContable): Observable<ImputacionContable> {
        const url = `${this.globalVars.apiHost}${this.imputacionesContablesUrl}`;
        let body= JSON.parse(JSON.stringify(imputacion));    
        return this.http.post<ImputacionContable>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((imputacion: ImputacionContable) => {
                return new ImputacionContable(imputacion);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
     
    updateImputacionContable(imputacion: ImputacionContable): Observable<ImputacionContable> {
        const url = `${this.globalVars.apiHost}${this.imputacionesContablesUrl}/${imputacion.id}`;
        let body= JSON.parse(JSON.stringify(imputacion));
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((imputacion: ImputacionContable) => {
                return new ImputacionContable(imputacion);
            }), catchError(this.globalVars.tratarErrores)
        );
    }    
      
    deleteImputacionContable(id: number): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.imputacionesContablesUrl}/${id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }

    //
    //IMPUTACIONES VENTAS
    getImputacionesVentas(params?, filtro?): Observable<any> {
        console.log('0');
        const url = `${this.globalVars.apiHost}${this.imputacionesVentasUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new ImputacionContable(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    getImputacionVenta(id): Observable<ImputacionVenta> {
        const url= `${this.globalVars.apiHost}${this.imputacionesVentasUrl}/${id}`;
        return this.http.get<ImputacionVenta>(url, this.globalVars.getOptionsRequest()).pipe(
            map((imputacion: ImputacionVenta) => {
                return new ImputacionVenta(imputacion);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createImputacionVenta(imputacion: ImputacionVenta): Observable<ImputacionVenta> {
        const url = `${this.globalVars.apiHost}${this.imputacionesVentasUrl}`;
        let body= JSON.parse(JSON.stringify(imputacion));    
        return this.http.post<ImputacionVenta>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((imputacion: ImputacionVenta) => {
                return new ImputacionVenta(imputacion);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
     
    updateImputacionVenta(imputacion: ImputacionVenta): Observable<ImputacionVenta> {
        const url = `${this.globalVars.apiHost}${this.imputacionesVentasUrl}/${imputacion.id}`;
        let body= JSON.parse(JSON.stringify(imputacion));
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((imputacion: ImputacionVenta) => {
                return new ImputacionVenta(imputacion);
            }), catchError(this.globalVars.tratarErrores)
        );
    }    
      
    deleteImputacionVenta(id: number): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.imputacionesVentasUrl}/${id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }
}