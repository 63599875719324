import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';

import {UserService } from '../../services/user.service';
import {GlobalVars} from '../../services/globalVars';
import {MessageService} from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends GenericFormComponent implements OnInit {
  loginForm: any= {email: '', password: '', remember: false};
  public access: boolean= false;

  constructor(protected messageService: MessageService, private router: Router, private userService: UserService, private globalVars: GlobalVars) {
    super(messageService);
  }

  ngOnInit() {
  }

  public login(event){
    this.beforeSubmit(event);
    this.userService.login(this.loginForm.email, this.loginForm.password).subscribe(
        rta => {
            this.noShowSuccess();
            this.access = true;
            let page: string = '';//environment.defaultPages[this.globalVars.getActualPanel()];
            setTimeout(() => {
              this.router.navigate([page]);
            }, 3000);
            
        },err => {
            this.showError(
              {'error': "El nombre de usuario y/o la contraseña son incorrectos. Por favor, inténtalo nuevamente"},
              true,        
            );
        }
    );
  }
}