import { Component, OnInit, Input } from '@angular/core';

import {FacturaService} from '../factura.service';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Factura} from '../models';
import {OrdenDePago} from '../../module-orden/models';
 
@Component({
  selector: 'ordenes_factura',
  templateUrl: './ordenes.component.html'
})
export class OrdenesComponent extends GenericFormComponent implements OnInit {
  @Input() factura: Factura;
  public ordenes: OrdenDePago[];
    
  constructor(protected messageService: MessageService, private facturaService: FacturaService) {
    super(messageService);
  }

  ngOnInit() {
    this.filter();
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    this.cursorWait();
    this.facturaService.getOrdenesFactura(this.factura.id).subscribe(
        (ordenes: OrdenDePago[]) => {
            this.cursorDefault();
            this.ordenes = ordenes;
        },
        error => {
            this.cursorDefault();
        }
    );
  }

}