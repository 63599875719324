import {Distrito} from '../module-distrito/models';

export class Proveedor {
    public id: number;
    public nombre: string;
    public cuit: string;
    public email: string;
    public direccion: string;
    public telefono: string;
    public condicion_iva: string = null;
    public es_propio: boolean = false;
    public persona_juridica: boolean = false;
    public consumidor_final: boolean = false;
    public saldo: number;    
    public created_at: any;
    
    public distrito: Distrito = null;
    
    public selectedInTable: boolean = false;
    public total_facturas: number;
    public total_ordenes: number;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.nombre = json.nombre;
            this.cuit = json.cuit;
            this.email = json.email;
            this.direccion = json.direccion;
            this.telefono = json.telefono;
            this.condicion_iva = json.condicion_iva;
            this.es_propio = json.es_propio;
            this.persona_juridica = json.persona_juridica;
            this.consumidor_final = json.consumidor_final;
            this.saldo = json.saldo;
            this.created_at = json.created_at;
            this.total_facturas = json.total_facturas;
            this.total_ordenes = json.total_ordenes;
            
            if (json.distrito) {
                this.distrito = new Distrito(json.distrito);
            }
        }
    }
    
    public infoFromAfip(info: any) {
        try {
            this.direccion = info['datosGenerales']['domicilioFiscal']['direccion'];
            if (info['datosGenerales']['tipoPersona'] == 'FISICA') {
                this.nombre = info['datosGenerales']['nombre'] + " " + info['datosGenerales']['apellido']
                this.persona_juridica = false;
            } else {
                this.nombre = info['datosGenerales']['razonSocial']
                this.persona_juridica = true;
            }
            if ('datosMonotributo' in info) {
                this.condicion_iva = 'MONOT';
                this.consumidor_final = false;
            } else if ('datosRegimenGeneral' in info) {
                this.condicion_iva = 'RESIN';
                for (let imp of info['datosRegimenGeneral']['impuesto']) {
                    if (imp["idImpuesto"] == 32) {
                        this.condicion_iva = 'EXENT';
                        break;
                    }
                }
                this.consumidor_final = false;
            } else {
                this.condicion_iva = 'CONFI';
                this.consumidor_final = true;
            }
        } catch (err) {
            console.log(err);
        }        
    }
    
    public tipoFacturasDisponibles(tipos: string[]) {
        if (this.condicion_iva == 'CONFI') {
            return tipos.filter(t => t == 'C');
        }
        if (this.condicion_iva == 'MONOT') {
            return tipos.filter(t => t == 'C');
        }
        if (this.condicion_iva == 'RESIN') {
            return tipos.filter(t => t == 'B' || t == 'RP');
        }
        if (this.condicion_iva == 'EXENT') {
            return tipos.filter(t => t == 'C' || t == 'RP');
        }
        if (this.condicion_iva == 'RE_NOI') {
            return tipos.filter(t => t == 'C' || t == 'RP');
        }
        if (this.condicion_iva == 'NORES') {
            return tipos.filter(t => t == 'C');
        }
        if (this.condicion_iva == 'RES_X') {
            return tipos.filter(t => t == 'X');
        }
        if (this.condicion_iva == 'EGRBA') {
            return tipos.filter(t => t == 'E');
        }
    }
}

export class Imputacion {
    public id: number;
    public codigo: string;
    public nombre: string;
    public descripcion: string;
    public ganancia_inscriptos: number;
    public monto_inicial: number;
    public ganancia_no_inscriptos: number;
    public monto_inicial_no_ins: number;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.codigo = json.codigo;
            this.nombre = json.nombre;
            this.descripcion = json.descripcion;
            this.ganancia_inscriptos = json.ganancia_inscriptos;
            this.monto_inicial = json.monto_inicial;
            this.ganancia_no_inscriptos = json.ganancia_no_inscriptos;
            this.monto_inicial_no_ins = json.monto_inicial_no_ins;
        }
    }
}

export class ProveedorImputacion {
    public id: number;
    public retencion: boolean = true;
    public proveedor: Proveedor;
    public imputacion: Imputacion = null;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.retencion = json.retencion;
            if (json.proveedor) {
                this.proveedor = new Proveedor(json.proveedor);
            }
            if (json.imputaciones) {
                this.imputacion = new Imputacion(json.imputaciones[0]);
            }
            if (json.imputacion) {
                this.imputacion = new Imputacion(json.imputacion);
            }
        }
    }
}