<div class="container-fluid">
    <div *ngIf="permiso" class="row">
        <permisos_form [permiso]="permiso" (actualize)="actualizeForm($event)" (cancel)="cancelForm($event)"></permisos_form>
    </div>
    
    <div class="row">
        <div class="card">
            <div class="card-content">                
                <breadcrumb [items]="breadcrumbItems"></breadcrumb>
            
                <div class="row mt-2">
                    <div class="col-sm-12">
                        <button class="btn btn-simple btn-icon btn-add" *ngIf="! permiso" (click)="newPermiso()" title="Agregar"><i class="cmp-i agregar"></i> Agregar</button>
                    </div>  
                </div>

                <div class="row">
                    <div class="col-md-12 table-responsive">
                        <table class="table table-hover">
                            <thead class="text-primary">
                                <tr>
                                    <th><b>Id</b></th>
                                    <th><b>Usuario</b></th>
                                    <th><b>Proveedor</b></th>
                                    <th><b>Acciones</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let permiso of permisos'>
                                    <td>{{permiso.id}}</td>
                                    <td>{{permiso.user.username}}</td>
                                    <td>{{permiso.proveedor.nombre}}</td>
                                    <td class="td-actions text-right">
                                        <button type="button" rel="tooltip" title="Eliminar Permiso" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="permiso" confirm-message="¿Está seguro que desea eliminar el Permiso?">
                                            <i class="cmp-i eliminar-borrar i-sm"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="permisos != null && permisos.length == 0" class="text-center">
                                    <td colspan="4">No se encontraron permisos</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>          
    </div>
</div>