import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';

//import 'rxjs/add/operator/toPromise';
import { Observable ,  Subject } from 'rxjs';
// Import RxJs required methods
import { map, catchError } from 'rxjs/operators';

import { GlobalVars } from '../services/globalVars';
import { Cache } from '../services/cache';

import {Viatico, ItemViatico, Periodo, TipoViatico, NotaCreditoViatico, ImputacionViatico} from './models';
import {Ciudad} from '../models/user';
import { NotaDeCredito } from '../module-factura/models';
import {OrdenDePago} from '../module-orden/models';

@Injectable()
export class ViaticoService{
    private viaticosUrl = 'viaticos';
    private imputacionUrl = 'imputaciones_viaticos';
    private periodosUrl = 'periodos';
    private tipoViaticosUrl = 'tipo_viaticos';
    private viaticosProveedorUrl = 'proveedores';
    private ciudadesUrl = 'ciudades';

    private reportesUrl = 'reportes/';

    constructor(private http: HttpClient, private globalVars: GlobalVars, private cache: Cache) {        
    }
    
    //
    //Mis Viaticos
    getMisViaticos(id, params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.viaticosProveedorUrl}/${id}/viaticos`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new Viatico(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createViatico(viatico: Viatico): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.viaticosUrl}`;
        let body= JSON.parse(JSON.stringify(viatico));
        // body.distrito_id = viatico.distrito ? viatico.distrito.id : null;
        body.periodo_id = viatico.periodo ? viatico.periodo.id : null;
        body.proveedor_id = viatico.proveedor ? viatico.proveedor.id : null;
        return this.http.post(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((viatico: Viatico) => {
                return new Viatico(viatico);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
     
    updateViatico(viatico: Viatico): Observable<Viatico> {
        const url = `${this.globalVars.apiHost}${this.viaticosUrl}/${viatico.id}`;
        let body = JSON.parse(JSON.stringify(viatico));
        // body.distrito_id = viatico.distrito ? viatico.distrito.id : null;
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((viatico: Viatico) => {
                return new Viatico(viatico);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
      
    deleteViatico(id: number): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.viaticosUrl}/${id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }
    
    getViatico(id): Observable<Viatico> {
        const url= `${this.globalVars.apiHost}${this.viaticosUrl}/${id}`;
        return this.http.get<Viatico>(url, this.globalVars.getOptionsRequest()).pipe(
            map((viatico: Viatico) => {
                return new Viatico(viatico);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //ITEMS
    getItemsViatico(id_viatico: number, params?, filtro?): Observable<ItemViatico[]> {
        const url = `${this.globalVars.apiHost}${this.viaticosUrl}/${id_viatico}/item_viaticos`;        
        return this.http.get<ItemViatico[]>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((items: ItemViatico[]) => {
                return items.map(item => new ItemViatico(item));
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createItemViatico(item: ItemViatico): Observable<ItemViatico> {
        const url = `${this.globalVars.apiHost}${this.viaticosUrl}/${item.viatico.id}/item_viaticos`;
        let body= JSON.parse(JSON.stringify(item));
        body.tipo_viatico_id = item.tipo_viatico ? item.tipo_viatico.id : null;
        body.viatico_id = item.viatico.id;
        delete body.viatico;
        return this.http.post(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((item: ItemViatico) => {
                return new ItemViatico(item);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    updateItemViatico(id_viatico: number, item: ItemViatico): Observable<ItemViatico> {
        const url = `${this.globalVars.apiHost}${this.viaticosUrl}/${id_viatico}/item_viaticos/${item.id}`;
        let body= JSON.parse(JSON.stringify(item));
        body.tipo_viatico_id = item.tipo_viatico ? item.tipo_viatico.id : null;
        delete body.viatico;
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((item: ItemViatico) => {
                return new ItemViatico(item);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    deleteItemViatico(id_viatico: number, id: number): Observable<ItemViatico> {
        const url = `${this.globalVars.apiHost}${this.viaticosUrl}/${id_viatico}/item_viaticos/${id}`;
        return this.http.delete<ItemViatico>(url, this.globalVars.getOptionsRequest()).pipe(
            map((item: ItemViatico) => {
                return new ItemViatico(item);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //NOTA DE VIATICO
    getNotaViatico(id_viatico: number): Observable<NotaDeCredito> {
        const url= `${this.globalVars.apiHost}${this.viaticosUrl}/${id_viatico}/nota`;
        return this.http.get<NotaDeCredito>(url, this.globalVars.getOptionsRequest()).pipe(
            map((nota: NotaDeCredito) => {
                return new NotaDeCredito(nota);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    getNotaUsadaViatico(id_viatico: number): Observable<NotaDeCredito> {
        const url= `${this.globalVars.apiHost}${this.viaticosUrl}/${id_viatico}/nota_usada`;
        return this.http.get<NotaDeCredito>(url, this.globalVars.getOptionsRequest()).pipe(
            map((nota: NotaDeCredito) => {
                return new NotaDeCredito(nota);
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    //
    //ORDENES DE VIATICO
    getOrdenesViatico(id: number): Observable<OrdenDePago[]> {
        const url = `${this.globalVars.apiHost}${this.viaticosUrl}/${id}/ordenes`;        
        return this.http.get<OrdenDePago[]>(url, this.globalVars.getOptionsRequest()).pipe(
            map((ordenes: OrdenDePago[]) => {
                return ordenes.map(nota => new OrdenDePago(nota));
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    
    //
    //Nota de Proveedor
    getNotaProveedor(id): Observable<NotaDeCredito> {
        const url= `${this.globalVars.apiHost}${this.viaticosProveedorUrl}/${id}/nota_viatico`;
        return this.http.get<NotaDeCredito>(url, this.globalVars.getOptionsRequest()).pipe(
            map((nota: NotaDeCredito) => {
                return new NotaDeCredito(nota);
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    getImputacionesViatico(params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.imputacionUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new ImputacionViatico(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    //
    //ADMIN        
    getViaticos(params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.viaticosUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new Viatico(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    cambiarEstadoViatico(viatico: Viatico, estado: string): Observable<Viatico> {
        const url = `${this.globalVars.apiHost}${this.viaticosUrl}/${viatico.id}`;
        let body = {estado: estado};
        return this.http.patch(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((viatico: Viatico) => {
                return new Viatico(viatico);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    guardarMontoViatico(viatico: Viatico, monto_real: number): Observable<Viatico> {
        const url = `${this.globalVars.apiHost}${this.viaticosUrl}/${viatico.id}`;
        let body = {monto_real: monto_real};
        return this.http.patch(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((viatico: Viatico) => {
                return new Viatico(viatico);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //PERIODOS
    getPeriodos(params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.periodosUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new Periodo(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    getPeriodo(id): Observable<Periodo> {
        const url= `${this.globalVars.apiHost}${this.periodosUrl}/${id}`;
        return this.http.get<Periodo>(url, this.globalVars.getOptionsRequest()).pipe(
            map((periodo: Periodo) => {
                return new Periodo(periodo);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createPeriodo(periodo: Periodo): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.periodosUrl}`;
        let body= JSON.parse(JSON.stringify(periodo));
        return this.http.post(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((periodo: Periodo) => {
                return new Periodo(periodo);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
     
    updatePeriodo(periodo: Periodo): Observable<Periodo> {
        const url = `${this.globalVars.apiHost}${this.periodosUrl}/${periodo.id}`;
        let body = JSON.parse(JSON.stringify(periodo));
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((periodo: Periodo) => {
                return new Periodo(periodo);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
      
    deletePeriodo(id: number): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.periodosUrl}/${id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }

    //
    //Tipos Viaticos
    getTipoViaticos(id, params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.periodosUrl}/${id}/${this.tipoViaticosUrl}`;
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.map(item => new TipoViatico(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    createTipoViatico(tipoViatico: TipoViatico): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.periodosUrl}/${tipoViatico.periodo.id}/${this.tipoViaticosUrl}`;
        let body= JSON.parse(JSON.stringify(tipoViatico));
        return this.http.post(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((tipoViatico: TipoViatico) => {
                return new TipoViatico(tipoViatico);
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    updateTipoViatico(tipoViatico: TipoViatico): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.periodosUrl}/${tipoViatico.periodo.id}/${this.tipoViaticosUrl}/${tipoViatico.id}`;
        let body = JSON.parse(JSON.stringify(tipoViatico));
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((tipoViatico: TipoViatico) => {
                return new TipoViatico(tipoViatico);
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    updateBatchTipoViatico(periodo: Periodo, porcentaje: number): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.periodosUrl}/${periodo.id}/${this.tipoViaticosUrl}/batch`;
        let body = {porcentaje: porcentaje};
        return this.http.put<any>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((rta) => {
                rta.data = rta.map(item => new TipoViatico(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    deleteTipoViatico(tipoViatico: TipoViatico): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.periodosUrl}/${tipoViatico.periodo.id}/${this.tipoViaticosUrl}/${tipoViatico.id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //CIUDADES
    getCiudades(params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.ciudadesUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new Ciudad(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    //
    //PDF
    pdfViatico(id): Observable<Blob> {
        const url = `${this.globalVars.apiHost}${this.reportesUrl}viatico?id=${id}`;
        return this.http.get<Blob>(url, this.globalVars.getOptionsRequestBlob());
    }
}