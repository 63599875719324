<div class="row">
    <div class="card">
        <div class="card-content">
            <h4 class="card-title">Ordenes de Pago</h4>
            <hr>

            <filters-header-section (onFilter)="filter(1)">
                <div class="col-sm-12">
                    <generic_period (outDate)="changeFilterDate($event)"></generic_period>
                </div>
            </filters-header-section>
            
            <div class="row">
                <div class="col-md-12 table-responsive">
                    <table class="table table-hover">
                        <thead class="text-primary">
                            <tr>
                                <th><b>Número</b></th>
                                <th><b>Fecha</b></th>
                                <th><b>Monto / A pagar</b></th>
                                <th><b>Ret. Gana.</b></th>
                                <th><b>Ret. Caja</b></th>
                                <th><b>Pagado</b></th>
                                <th><b>Acciones</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor='let orden of ordenes'>
                                <td>
                                    <a (click)="detalleOrden(orden)" rel="tooltip" title="Detalle">{{orden.punto_venta}} - {{orden.numero}}</a>
                                    <span *ngIf="orden.anulada"> (ANULADA)</span>
                                </td>
                                <td>{{orden.fecha | date: 'dd/MM/yyyy'}}</td>
                                <td>$ {{orden.monto | number: '1.2-2'}} / $ {{orden.monto_descuentos | number: '1.2-2'}}</td>
                                <td>$ {{orden.retencion | number: '1.2-2'}}</td>
                                <td>$ {{orden.descuentos | number: '1.2-2'}}</td>
                                <td>$ {{orden.pagado | number: '1.2-2'}}</td>
                                <td class="td-actions text-right">
                                    <a [routerLink]="['/ordenespago', orden.id]" rel="tooltip" title="Editar Orden" class="btn btn-primary btn-simple btn-xs btn-cmp-i">
                                        <i class="cmp-i editar i-sm"></i>
                                    </a>
                                    <button type="button" rel="tooltip" title="Eliminar Orden" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="orden" confirm-message="¿Está seguro que desea eliminar la Orden?">
                                        <i class="cmp-i eliminar-borrar i-sm"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr *ngIf="ordenes != null && ordenes.length == 0" class="text-center">
                                <td colspan="7">No se encontraron ordenes</td>
                            </tr>
                        </tbody>
                    </table>                        
                    <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)" *ngIf="ordenes != null && ordenes.length > 0"></pager>
                </div>
            </div>            
        </div>
    </div>
</div>