<div *ngIf="notaCreditoAfipNew" class="row">
    <nota-credito-orden-form [orden]="orden" [notaCreditoAfip]="notaCreditoAfipNew" (actualize)="actualizeForm($event)" (cancel)="cancelForm($event)"></nota-credito-orden-form>
</div>

<div class="row" *ngIf="!notaCreditoAfipNew">
    <div class="card">
        <div class="card-content">
            <h4 class="card-title">Notas de Crédito</h4>
            <hr>
            <div class="row mt-1">
                <div class="col-sm-12">
                    <button class="btn btn-simple btn-icon btn-add" (click)="newNotaCreditoAfip()" title="Agregar"><i class="cmp-i agregar"></i> Agregar</button>
                </div>  
            </div>

            <div class="row">
                <div class="col-md-12 table-responsive">
                    <table class="table table-hover">
                        <thead class="text-primary">
                            <tr>
                                <!-- <th>Nro Comprobante</th> -->
                                <th>Descripción</th>
                                <th>Método de Pago</th>
                                <th>Monto</th>
                                <th>Fecha Recibo</th>
                                <th>Fecha Vto Pago</th>
                                <th>Fecha Serv Desde</th>
                                <th>Fecha Serv Hasta</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor='let notaCreditoAfip of notasCreditoAfip'>
                                <!-- <td>
                                    <span *ngIf="notaCreditoAfip.punto_venta">{{notaCreditoAfip.punto_venta}}</span>
                                    <span *ngIf="!notaCreditoAfip.punto_venta">-</span>
                                    <span *ngIf="notaCreditoAfip.nro_comprobante"> - {{notaCreditoAfip.nro_comprobante}}</span>
                                    <span *ngIf="!notaCreditoAfip.nro_comprobante">-</span>
                                </td> -->
                                <td>
                                    <span *ngIf="!notaCreditoAfip.descripcion">-</span>
                                    <span *ngIf="notaCreditoAfip.descripcion">{{notaCreditoAfip.descripcion}}</span>
                                    <span *ngIf="notaCreditoAfip.anulado"> (ANULADO)</span>                           
                                </td>
                                <td>{{notaCreditoAfip.tipo}}</td>
                                <td>$ {{notaCreditoAfip.monto | number: '1.2-2'}}</td>
                                <td>
                                	<span >{{notaCreditoAfip.fecha | date: 'dd/MM/yyyy'}}</span>
                                </td>
                                <td>
                                    <span >{{notaCreditoAfip.fecha_vto_pago | date: 'dd/MM/yyyy'}}</span>
                                </td>
                                <td>
                                    <span >{{notaCreditoAfip.fecha_serv_desde | date: 'dd/MM/yyyy'}}</span>
                                </td>
                                <td>
                                    <span >{{notaCreditoAfip.fecha_serv_hasta | date: 'dd/MM/yyyy'}}</span>
                                </td> 
                                

                                <td class="td-actions text-right">
                                    <button type="button" rel="tooltip" *ngIf="notaCreditoAfip.estado == 'I'" title="Confirmar nota de crédito en Afip" class="btn btn-primary btn-simple btn-xs" (confirm-click)="onConfirmAfip($event)" [confirm-params]="notaCreditoAfip" confirm-message="¿Está seguro que desea confirmar la nota de crédito en Afip?">
                                        <i class="ti-check"></i>
                                    </button>
                                    <button type="button" rel="tooltip" *ngIf="notaCreditoAfip.estado == 'C'" title="Imprimir nota de crédito en Afip" class="btn btn-primary btn-simple btn-xs ml-2" (confirm-click)="onPrintAfip($event)" [confirm-params]="notaCreditoAfip" confirm-message="¿Está seguro que desea imprimir la nota de crédito en Afip?">
                                        <i class="ti-printer"></i>
                                    </button>
                                    <button type="button" rel="tooltip" title="Editar nota de crédito" class="btn btn-primary btn-simple btn-xs btn-cmp-i ml-2" (click)="editNotaCredito(notaCreditoAfip)">
                                        <i class="cmp-i editar i-sm"></i>
                                    </button>
                                    <button type="button" rel="tooltip" title="Eliminar nota de crédito" class="btn btn-danger btn-simple btn-xs btn-cmp-i ml-2" (confirm-click)="deleteNotaCredito(notaCreditoAfip)" [confirm-params]="notaCred" confirm-message="¿Está seguro que desea eliminar la nota de crédito?">
                                        <i class="cmp-i eliminar-borrar i-sm"></i>
                                    </button>
                                </td>                                             
                            </tr>
                            <tr *ngIf="notasCreditoAfip != null && notasCreditoAfip.length == 0" class="text-center">
                                <td colspan="9">No hay Notas de Crédito</td>
                            </tr>
                        </tbody>
                    </table>  
                </div>
            </div>
        </div>
    </div>
</div>