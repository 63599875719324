
<div class="container-fluid">    
    
    <div class="row">
        <div class="card">
            <div class="card-content">
                <breadcrumb [items]="breadcrumbItems"></breadcrumb>
                
                <form (submit)="save($event)" #datosForm="ngForm">
                    <div class="row">
                        <div class="col-md-12">

                            <div class="col-sm-12 col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4 text-center" [hidden]="factura.id || factura.proveedor">
                                <provider-select [(providerModel)]="factura.proveedor" (onModelChange)="onSelectProvider()"
                                    title="Seleccione una Persona Jurídica"></provider-select>
                            </div>


                            <ng-container *ngIf="factura.proveedor">
                                <h5><strong>Persona Jurídica</strong></h5>
                                <div class="col-md-3">
                                    <p><strong class="mr-1">Nombre:</strong><a (click)="detalleProveedor(factura.proveedor)" rel="tooltip" title="Detalle">{{factura.proveedor.nombre}}</a></p>
                                </div>
                                <div class="col-md-2">
                                    <p><strong>CUIT:</strong> {{factura.proveedor.cuit | cuitformat}}</p>
                                </div>
                                <div class="col-md-3">
                                    <p><strong>Email:</strong> {{factura.proveedor.email}}</p>
                                </div>
                                <div class="col-md-3" *ngIf="!globalVars.actualUser.multi_distrito">
                                    <p><strong>Distrito:</strong> {{factura.distrito.nombre}}</p>
                                    
                                </div>
                                <div class="col-md-3" *ngIf="globalVars.actualUser.multi_distrito">
                                    <div class="row">
                                        <label class="col-md-3 label-on-left">Distrito: </label>
                                        <div class="col-md-9" *ngIf="globalVars.actualUser.multi_distrito">
                                            <select class="form-control" data-style="btn btn-wd btn-block" name="distrito" [(ngModel)]="factura.distrito_id" >
                                                <option *ngFor="let distrito of distritos" [ngValue]="distrito.id">{{distrito.nombre}}</option>
                                            </select>
                                            <small [hidden]="!inputError.distrito_id" [innerHtml]="inputError.distrito_id" class="text-danger"></small>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-md-12 text-center" *ngIf="!factura.id">
                                        <button type="button" class="btn btn-primary" (click)="factura.proveedor = null">Cambiar Persona</button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        
                        <div class="col-xs-12 mt-2" *ngIf="factura.proveedor">
                            <h5><strong>Datos</strong></h5>
                            <div class="col-xs-12 col-md-4">
                                <div class="form-group">
                                    <label>Número</label>
                                    <div class="row">
                                        <div class="col-xs-4">
                                            <input type="number" name="punto_venta" [(ngModel)]="factura.punto_venta" class="form-control" placeholder="0001" disabled="">
                                            <small [hidden]="!inputError.punto_venta" [innerHtml]="inputError.punto_venta" class="text-danger"></small>
                                        </div>
                                        <div class="col-xs-8">
                                            <input type="number" name="numero" [(ngModel)]="factura.numero" class="form-control" placeholder="0000001" disabled="">
                                            <small [hidden]="!inputError.numero" [innerHtml]="inputError.numero" class="text-danger"></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-4">
                                <div class="form-group">
                                    <label>Fecha</label>
                                    <input-datepicker [(dateModel)]="factura.fecha" [isStringFormat]="true" [required]="true"></input-datepicker>
                                    <small [hidden]="!inputError.fecha" [innerHtml]="inputError.fecha" class="text-danger"></small>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-4">
                                <div class="form-group">
                                    <label>Fecha Vencimiento</label>
                                    <input-datepicker [(dateModel)]="factura.fecha_vencimiento" [isStringFormat]="true" [required]="true"></input-datepicker>
                                    <small [hidden]="!inputError.fecha_vencimiento" [innerHtml]="inputError.fecha_vencimiento" class="text-danger"></small>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-4">
                                <div class="form-group">
                                    <label>Fecha Contable</label>
                                    <input-datepicker [(dateModel)]="factura.fecha_contable" [isStringFormat]="true" [required]="true" [disabled]="factura.tienePagos()"></input-datepicker>
                                    <!-- <input type="date" name="fecha_contable" [ngModel]="factura.fecha_contable | date:'yyyy-MM-dd'" (ngModelChange)="validDate($event) ? (factura.fecha_contable = $event ? $event + ' 00:00:00' : null) : null" class="form-control" required [disabled]="factura.tienePagos()"> -->
                                    <small [hidden]="!inputError.fecha_contable" [innerHtml]="inputError.fecha_contable" class="text-danger"></small>
                                </div>
                            </div>
                            
                            
                            <!-- <div class="col-md-2">
                                <div class="form-group">
                                    <label>Tipo de Factura</label>
                                    <select name="tipo" class="form-control" data-style="btn btn-wd btn-block" title="Tipo de Factura" data-size="7" [(ngModel)]="factura.tipo" required="">
                                        <option *ngFor="let tipo of tipos" [ngValue]="tipo">{{tipo | translate: "TIPO_FACTURA_VENTA"}}</option>
                                    </select>
                                    <small [hidden]="!inputError.tipo" [innerHtml]="inputError.tipo" class="text-danger"></small>
                                </div>
                            </div> -->
                            <!-- <div class="col-md-2">
                                <div class="form-group">
                                    <label>Moneda</label>
                                    <select class="form-control" data-style="btn btn-wd btn-block" name="moneda" [(ngModel)]="factura.moneda" [disabled]="factura.tienePagos()">
                                        <option [ngValue]="null">Seleccione una Moneda</option>
                                        <option *ngFor="let moneda of monedas" [ngValue]="moneda">{{moneda | translate: 'MONEDA'}}</option>
                                    </select>
                                </div>
                            </div> -->
                            
                            <div class="col-xs-12 col-md-4">
                                <div class="form-group">
                                    <label style="display: block;">Imputación Venta</label>
                                    <select name="imputacion_venta" class="form-control" data-style="btn btn-wd btn-block" name="imputacion_venta" [(ngModel)]="factura.imputacion_venta" [compareWith]='customCompareImputacionVenta' [disabled]="factura.tienePagos()">
                                        <option [ngValue]="null">Ninguna</option>
                                        <option *ngFor="let imputacion of imputacionesContables" [ngValue]="imputacion">{{imputacion.nombre}}</option>
                                    </select>
                                    <small [hidden]="!inputError.imputacion_venta_id" [innerHtml]="inputError.imputacion_venta_id" class="text-danger"></small>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-4">
<!--                                     <div class="form-group">
                                    <label>IVA</label>
                                    <select name="iva" [(ngModel)]="factura.iva" class="form-control" [disabled]="factura.tienePagos()" required>
                                        <option *ngFor="let iva of ivas" [value]="iva">{{iva}}%</option>
                                    </select>
                                    <small [hidden]="!inputError.iva" [innerHtml]="inputError.iva" class="text-danger"></small>                       
                                </div> -->
                            </div>

                            <div class="col-xs-12 col-md-4">
<!--                                     <div class="form-group">
                                    <label style="display: block;">Categoria de Retencion</label>
                                    <select name="proveedor_imputacion" class="form-control" data-style="btn btn-wd btn-block" name="proveedor_imputacion" [(ngModel)]="factura.proveedor_imputacion" [compareWith]='customCompareImputacion' (change)="actualizarRetencion()" [disabled]="factura.tienePagos()">
                                        <option [ngValue]="null">Ninguna</option>
                                        <option *ngFor="let imputacion of imputaciones" [ngValue]="imputacion">{{imputacion.imputacion.nombre}}</option>
                                    </select>
                                    <small [hidden]="!inputError.proveedor_imputacion_id" [innerHtml]="inputError.proveedor_imputacion_id" class="text-danger"></small>
                                </div> -->
                            </div>
                            <div class="col-xs-12 col-md-4">
                            </div>
                            <div class="col-xs-12 col-md-4">
                                <div class="form-group">
                                    <label>Monto</label>                               
                                    <input type="number" name="monto" [(ngModel)]="factura.monto" class="form-control" [disabled]="factura.tienePagos()" required >
                                    <small [hidden]="!inputError.monto" [innerHtml]="inputError.monto" class="text-danger"></small>                          
                                </div>
                            </div>

                            <div class="col-xs-12">
                                <div class="form-group">
                                    <label>Descripción</label>                               
                                    <textarea type="text" name="descripcion" [(ngModel)]="factura.descripcion" class="form-control" rows="4" placeholder="Descripción del ingreso" style="height: 80px !important;" [disabled]="factura.tienePagos()" required></textarea>
                                    <small [hidden]="!inputError.descripcion" [innerHtml]="inputError.descripcion" class="text-danger"></small>                          
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <div class="row text-center" *ngIf="factura.proveedor">
                        <button class="btn btn-primary" type="submit" *ngIf="! factura.id" [disabled]="submitted">Crear Ingreso</button>
                        <button class="btn btn-primary" type="submit" *ngIf="factura.id" [disabled]="submitted">Actualizar Ingreso</button>
                        <button *ngIf="factura.id && factura.estado != 'A'" class="btn btn-primary ml-2" type="submit" [disabled]="submitted" (confirm-click)="changeState(factura, 'A', $event)" confirm-message="¿Está seguro que desea aprobar el Ingreso?">Aprobar Ingreso</button>
                        <button *ngIf="factura.id && factura.estado != 'R' && actualUser.esTesorero()" type="button" rel="tooltip" title="Rechazar Ingreso" class="btn btn-wd ml-2" (confirm-click)="changeState(factura, 'R', $event)" confirm-message="¿Está seguro que desea rechazar el Ingreso?">
                        Rechazar Ingreso
                        </button>
                        <a *ngIf="factura.estado == 'A'" [routerLink]="['/ordenesventas/new']" [queryParams]="{proveedor: factura.proveedor.id}" rel="tooltip" title="Generar O.V." class="btn btn-primary ml-2">
                            Generar Orden de Venta
                        </a>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- <productos *ngIf="factura.id" [factura]="factura"></productos> -->

    <div class="row" *ngIf="false">
        <div class="card">
            <div class="card-content">
                <h4 class="card-title">Importe / Tasas</h4>   
                <hr>                 
                <div class="row">
                    <div class="col-md-4 col-md-offset-8">
                        <p><strong>Monto:</strong> $ {{factura.monto | number: '1.2-2'}}</p>
                    </div>
                </div>
            </div>
        </div> <!-- end card -->
    </div>

</div>


