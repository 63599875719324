import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule }   from '@angular/forms';

import {GenericModule} from '../module-generic/generic.module';
import {TranslateComponentModule} from '../translate-module/translate-component.module';

import {FacturaService} from './factura.service';
import {FacturaCommunicate} from './factura.communicate';

import {FacturasComponent} from './facturas/facturas.component';
import {FacturasFormComponent} from './facturas_form/facturas_form.component';
import {ProductosComponent} from './productos/productos.component';
import {ProductosFormComponent} from './productos_form/productos_form.component';
import {NotasComponent} from './notas/notas.component';
import {NotasFormComponent} from './notas_form/notas_form.component';
import {ImputacionesComponent} from './imputaciones/imputaciones.component';
import {ImputacionesFormComponent} from './imputaciones_form/imputaciones_form.component';
import {OrdenesComponent} from './ordenes/ordenes.component';

import {FacturasModalComponent} from './facturas_modal/facturas_modal.component';
import {FacturaModalComponent} from './factura_modal/factura_modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    
    GenericModule,
    TranslateComponentModule,
  ],
  declarations: [
    FacturasComponent,
    FacturasFormComponent,
    ProductosComponent,
    ProductosFormComponent,
    NotasComponent,
    NotasFormComponent,
    ImputacionesComponent,
    ImputacionesFormComponent,
    OrdenesComponent,
    
    FacturasModalComponent,
    FacturaModalComponent
  ],
  providers: [
    FacturaService,
    FacturaCommunicate
  ],
  exports: [
    FacturasComponent,
    FacturasFormComponent,
    
    FacturasModalComponent,
    FacturaModalComponent
  ]
})
export class FacturaModule { }
