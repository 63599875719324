import { Component, OnInit } from '@angular/core';

import {UserService} from '../user.service';
import {DistritoService} from '../../module-distrito/distrito.service';
import {GlobalVars} from '../../services/globalVars';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Rol} from '../../models/user';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
 
@Component({
  selector: 'roles',
  templateUrl: './roles.component.html'
})
export class RolesComponent extends GenericFormComponent implements OnInit {
  public roles: Rol[];
  public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Roles', active: true }];
    
  constructor(protected messageService: MessageService, private globalVars: GlobalVars, private userService: UserService, private distritoService: DistritoService) {
    super(messageService);
  }

  ngOnInit() {
    this.filter();
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    //this.users= null;
    this.cursorWait();
    this.userService.getRoles().subscribe(
        (roles: Rol[]) => {
            this.cursorDefault();
            this.roles= roles;
        },
        error => {
            this.cursorDefault();
        }
    );
  }
  
}