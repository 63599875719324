import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule }   from '@angular/forms';

import {GenericModule} from '../module-generic/generic.module';
import {TranslateComponentModule} from '../translate-module/translate-component.module';

import {ReportesService} from './reportes.service';

import { ReportesComponent } from './reportes/reportes.component';
import { ReporteOrdenesComponent } from './reporte-ordenes/reporte-ordenes.component';
import { ReporteCajaChicaComponent } from './reporte-caja-chica/reporte-caja-chica.component';
import { ReporteChequesComponent } from './reporte-cheques/reporte-cheques.component';
import { ReportMenuItemComponent } from './reportes/report-menu-item/report-menu-item.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    
    GenericModule,
    TranslateComponentModule,
  ],
  declarations: [
    ReportesComponent,
    ReporteOrdenesComponent,
    ReporteCajaChicaComponent,
    ReporteChequesComponent,
    ReportMenuItemComponent,
  ],
  providers: [
    ReportesService
  ],
  exports: [
  ]
})
export class ReportesModule { }
