import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi }    from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app.routing';
import {AuthGuard, AuthGuardByRole, NoLogin }   from './auth/authguard';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {Interceptor} from './auth/interceptor';

import {TranslateComponentModule} from './translate-module/translate-component.module';
import {GenericModule} from './module-generic/generic.module';
import {BaseModule} from './module-base/base.module';

import {UserModule} from './module-user/user.module';
import {DistritoModule} from './module-distrito/distrito.module';
import {ProveedorModule} from './module-proveedor/proveedor.module';
import {CuentaModule} from './module-cuenta/cuenta.module';
import {FacturaModule} from './module-factura/factura.module';
import {OrdenModule} from './module-orden/orden.module';
import {ViaticoModule} from './module-viatico/viatico.module';
import {ReportesModule} from './module-reportes/reportes.module';

import { GlobalVars }   from './services/globalVars';
import { ConfigService }   from './services/config.service';
import { Cache }   from './services/cache';
import { ProjectUtils } from './services/utils';
import {MessageService} from './services/message.service';
import {PdfService} from './services/pdf.service';

import {UserService} from './services/user.service';
import {AfipService} from './services/afip.service';

import { AppComponent }   from './app.component';

import { ViaticoComponent } from './components/viatico-component/viatico.component';
import { AltaFacturaComponent } from './components/alta-factura-component/alta-factura.component';
import { ProveedorComponent } from './components/proveedor-component/proveedor.component';
import { OrdenComponent } from './components/orden-component/orden.component';

import {FacturaVentaModule} from './module-factura-venta/factura.module';
import {OrdenVentaModule} from './module-orden-venta/orden.module';
import {PuntoDeVentaModule} from './module-punto-de-venta/punto-de-venta.module';
import {ReciboModule} from './module-recibo/recibo.module';
import { FilterCacheUtilsService } from './services/filter-cache-utils.service';
import { ModuleReposicionModule } from './module-reposicion/module-reposicion.module';
import { ConfigModule } from './module-config/config.module';
import { NotaCreditoAfipModule } from './module-nota-credito-afip/nota-credito-afip.module';

export function loadConfig(configService: ConfigService) {
    return () => configService.loadConfig();
}

@NgModule({ declarations: [
        AppComponent,
        ViaticoComponent,
        AltaFacturaComponent,
        ProveedorComponent,
        OrdenComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        FormsModule,
        TranslateComponentModule,
        GenericModule,
        BaseModule,
        UserModule,
        DistritoModule,
        ProveedorModule,
        CuentaModule,
        FacturaModule,
        OrdenModule,
        ViaticoModule,
        ReportesModule,
        FacturaVentaModule,
        OrdenVentaModule,
        PuntoDeVentaModule,
        ReciboModule,
        ModuleReposicionModule,
        NotaCreditoAfipModule,
        ConfigModule,
        RouterModule,
        AppRoutingModule], providers: [
        GlobalVars,
        ConfigService,
        Cache,
        ProjectUtils,
        MessageService,
        PdfService,
        UserService,
        AfipService,
        AuthGuard,
        AuthGuardByRole,
        NoLogin,
        FilterCacheUtilsService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptor,
            multi: true
        },
        { provide: APP_INITIALIZER, useFactory: loadConfig, deps: [ConfigService], multi: true },
        provideHttpClient(withInterceptorsFromDi())
    ] })

export class AppModule { }
