
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';

import {ViaticoService} from '../viatico.service';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Periodo, TipoViatico} from '../models';
 
@Component({
  selector: 'tipo_viaticos',
  templateUrl: './tipo_viaticos.component.html'
})
export class TipoViaticosComponent extends GenericFormComponent implements OnInit {
  @Input() periodo: Periodo;
  public viaticosKilometros: TipoViatico[] = [];

  public porcentaje: number = 0;

  public viaticoHotel: TipoViatico = new TipoViatico();
  public viaticoComida: TipoViatico = new TipoViatico();;
  public viaticoOtros: TipoViatico = new TipoViatico();;

  public params = {page: 1, per_page: 10};
  public filtro = {tipo_eq: null};

  public flag = true;
  
  constructor(protected messageService: MessageService, private viaticoService: ViaticoService) {
    super(messageService);
  }

  ngOnInit() {
    this.cursorWait();
    this.filtro = {tipo_eq: 'K'};
    this.viaticoService.getTipoViaticos(this.periodo.id, this.params, this.filtro).subscribe(
        (rta: any) => {
            this.cursorDefault();
            this.viaticosKilometros = rta;
        },
        error => {
            this.cursorDefault();
        }
    );

    this.cursorWait();
    this.filtro = {tipo_eq: 'H'};
    this.viaticoService.getTipoViaticos(this.periodo.id, this.params, this.filtro).subscribe(
        (rta: any) => {
            this.cursorDefault();
            this.viaticoHotel = rta[0];
        },
        error => {
            this.cursorDefault();
        }
    );

    this.cursorWait();
    this.filtro = {tipo_eq: 'C'};
    this.viaticoService.getTipoViaticos(this.periodo.id, this.params, this.filtro).subscribe(
        (rta: any) => {
            this.cursorDefault();
            this.viaticoComida = rta[0];
        },
        error => {
            this.cursorDefault();
        }
    );

    // this.cursorWait();
    // this.filtro = {tipo_eq: 'O'};
    // this.viaticoService.getTipoViaticos(this.periodo.id, this.params, this.filtro).subscribe(
    //     (rta: any) => {
    //         this.cursorDefault();
    //         this.viaticoOtros = rta[0];
    //     },
    //     error => {
    //         this.cursorDefault();
    //     }
    // );
  }
  //FORMULARIO ALTA/EDICION
  //Para el Form
  public guardarKilometros(){
    /*this.flag = true;
    this.viaticosKilometros.forEach((item, index) => {
      this.update(item);
    });
            
    if(this.flag){
      this.showSuccess(true, 'Tipo viatico actualizado correctamente');
    }*/
    let services = [];
    for (let item of this.viaticosKilometros) {
        services.push(this.viaticoService.updateTipoViatico(item));
    }
    this.beforeSubmit();
    return observableForkJoin(services).subscribe(
        (rta: any) => {
            this.showSuccess(true, 'Tipo viático actualizado correctamente')
        },err => {
            this.showError({}, true, 'Hubo un problema actualizando los items');
        }
    );
  }

  public guardarHotel(event){
    this.update(this.viaticoHotel);
  }

  public guardarComida(event){
    this.update(this.viaticoComida);
  }

  // public guardarOtros(event){
  //   this.update(this.viaticoOtros);
  // }

  public update(item){
    this.beforeSubmit();
    this.viaticoService.updateTipoViatico(item).subscribe(
        (tipoViatico: TipoViatico) => {
            this.showSuccess(true, 'Tipo viático actualizado correctamente')
        },err => {
            this.showError(err, true);
        }
    );
  }

  public actualizarKilometros(){
    this.flag = true;
    this.viaticoService.updateBatchTipoViatico(this.periodo, this.porcentaje).subscribe(
        (rta: any) => {
            this.cursorDefault();
            this.viaticosKilometros = rta;
            this.showSuccess(true, 'Se ha actualizado correctamente')
        },err => {
            this.showError(err, true);
        });
  }
}