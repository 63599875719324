import {Component, Input, Output, EventEmitter } from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'pager',
  templateUrl: 'pager.html'
})
export class PagerComponent {
    @Input() info: any;
    
    @Input() actualPage: number;
    @Input() perPage: number;
    
    @Output() filter: EventEmitter<any> = new EventEmitter<any>();    

    public cant_listado: number[] = environment.cant_listado;

    constructor() {
    }
    
    public filterPage(page?){
        this.filter.emit({page: page, per_page: this.perPage});
    }
}