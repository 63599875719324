import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import {AuthGuard, AuthGuardByRole, NoLogin }   from './auth/authguard';

import {LoginComponent} from './module-user/login/login.component';
import {ConfiguracionComponent} from './module-base/configuracion/configuracion.component';
import {PerfilComponent} from './module-base/perfil/perfil.component';
import {HomeComponent} from './module-base/home/home.component';

import {UsersComponent} from './module-user/users/users.component';
import {RolesComponent} from './module-user/roles/roles.component';
import {PermisosComponent} from './module-user/permisos/permisos.component';
import {DistritosComponent} from './module-distrito/distritos/distritos.component';
import {ImputacionesComponent} from './module-proveedor/imputaciones/imputaciones.component';
import {ImputacionesComponent as ImputacionesContableComponent} from './module-factura/imputaciones/imputaciones.component';
import {MetodosPagoComponent} from './module-cuenta/metodos_pago/metodos_pago.component';

import {FacturasComponent} from './module-factura/facturas/facturas.component';
import {FacturasFormComponent} from './module-factura/facturas_form/facturas_form.component';
import {OrdenesComponent} from './module-orden/ordenes/ordenes.component';
import {OrdenesFormComponent} from './module-orden/ordenes_form/ordenes_form.component';
import {DeudasRetencionComponent} from './module-orden/deudas_retencion/deudas_retencion.component';
import {CuentasComponent} from './module-cuenta/cuentas/cuentas.component';
import {CuentasDetalleComponent} from './module-cuenta/cuentas_detalle/cuentas_detalle.component';
import {ChequerasComponent} from './module-cuenta/chequeras/chequeras.component';
import {ChequerasDetalleComponent} from './module-cuenta/chequeras_detalle/chequeras_detalle.component';
import {ChequesComponent} from './module-cuenta/cheques/cheques.component';
import {ProveedoresComponent} from './module-proveedor/proveedores/proveedores.component';
import {ProveedoresDetalleComponent} from './module-proveedor/proveedores_detalle/proveedores_detalle.component';
import {ViaticosComponent} from './module-viatico/viaticos/viaticos.component';
import {ViaticosFormComponent} from './module-viatico/viaticos_form/viaticos_form.component';
import {PeriodosComponent} from './module-viatico/periodos/periodos.component';
import {PeriodosDetalleComponent} from './module-viatico/periodos_detalle/periodos_detalle.component';
import {ReportesComponent} from './module-reportes/reportes/reportes.component';

import {FacturasVentasComponent} from './module-factura-venta/facturas/facturas.component';
import {FacturasVentasFormComponent} from './module-factura-venta/facturas_form/facturas_form.component';

import {OrdenesVentasComponent} from './module-orden-venta/ordenes/ordenes.component';
import {OrdenesVentasFormComponent} from './module-orden-venta/ordenes_form/ordenes_form.component';

import {PuntoDeVentaComponent} from './module-punto-de-venta/punto-de-venta/punto-de-venta.component';
import {PuntoDeVentaFormComponent} from './module-punto-de-venta/punto-de-venta-form/punto-de-venta-form.component';

import {ReciboComponent} from './module-recibo/recibo/recibo.component';
import { ReposicionComponent } from './module-reposicion/reposicion/reposicion.component';
import { ImputacionesFormComponent } from './module-factura/imputaciones_form/imputaciones_form.component';
import { SearchResultsComponent } from './module-base/search-results/search-results.component';
import { AfipConfigComponent } from './module-config/afip-config/afip-config.component';
import { NotaCreditoAfipComponent } from './module-nota-credito-afip/nota-credito-afip/nota-credito-afip.component';

export const routes: Routes = [
    {path: '', component: HomeComponent, canActivate: [AuthGuard],  data: {title: 'Tablero'} },
    {path: 'login', component: LoginComponent, canActivate: [NoLogin]},    
    
    { path: 'config/valor-modulo', component: ConfiguracionComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Configuracion', roles: ['tesorero', 'admin_sistema']} },
    { path: 'perfil', component: PerfilComponent, canActivate: [AuthGuard],
        data: {title: 'Mi Perfil'} },
    
    { path: 'usuarios', component: UsersComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Usuarios', roles: ['admin_sistema']} },
    { path: 'usuarios/:id/permisos', component: PermisosComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Permisos Especificos', roles: ['admin_sistema']} },
    { path: 'roles', component: RolesComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Roles', roles: ['admin_sistema']} },    
    { path: 'distritos', component: DistritosComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Distritos', roles: ['admin_sistema']} },
    { path: 'categorias_retencion', component: ImputacionesComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Categorías de Retención', roles: ['admin_sistema']}, },

    { path: 'metodospago', component: MetodosPagoComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Métodos de Pago', roles: ['admin_sistema']} },
    { path: 'periodos', component: PeriodosComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Periodos Viático', roles: ['admin_sistema']} },
    { path: 'periodos/:id', component: PeriodosDetalleComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Periodos Detalle', roles: ['admin_sistema']} },
        
    { path: 'imputaciones', component: ImputacionesContableComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Imputaciones', roles: ['tesorero', 'auditor']}, },
    { path: 'imputaciones/new', component: ImputacionesFormComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Imputaciones', roles: ['tesorero', 'auditor']}, },
    { path: 'imputaciones/:id', component: ImputacionesFormComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Imputaciones', roles: ['tesorero', 'auditor']}, },
    { path: 'facturas', component: FacturasComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Facturas', roles: ['tesorero', 'administrativo']} },
    { path: 'facturas/new', component: FacturasFormComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Alta Factura', roles: ['tesorero', 'administrativo']} },
    { path: 'facturas/:id', component: FacturasFormComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Edición Factura', roles: ['tesorero', 'administrativo']} },
    { path: 'ordenespago', component: OrdenesComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Ordenes de Pago', roles: ['tesorero', 'administrativo']} },
    { path: 'ordenespago/new', component: OrdenesFormComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Alta Orden de Pago', roles: ['tesorero', 'administrativo']} },
    { path: 'ordenespago/:id', component: OrdenesFormComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Edición Orden de Pago', roles: ['tesorero', 'administrativo']} },
    { path: 'cuentas', component: CuentasComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Cuentas', roles: ['tesorero']} },
    { path: 'cuentas/:id', component: CuentasDetalleComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Cuenta Detalle', roles: ['tesorero', 'administrativo']} },
    { path: 'chequeras', component: ChequerasComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Chequeras', roles: ['tesorero']} },
    { path: 'chequeras/:id', component: ChequerasDetalleComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Chequera Detalle', roles: ['tesorero']} },
    { path: 'cheques', component: ChequesComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Cheques', roles: ['tesorero', 'administrativo']} },
    { path: 'cheques/:id', component: ChequesComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Cheques', roles: ['tesorero', 'administrativo']} },
    { path: 'proveedores', component: ProveedoresComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Proveedores', roles: ['tesorero', 'administrativo']} },
    { path: 'proveedores/:id', component: ProveedoresDetalleComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Proveedor Detalle', roles: ['tesorero', 'administrativo']} },
    { path: 'misviaticos', component: ViaticosComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Mis Viáticos', auth: false, cargadorViaticos: false, roles: ['tesorero', 'autoridad']} },
    { path: 'viaticos', component: ViaticosComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Carga de Viáticos', auth: false, cargadorViaticos: true, roles: ['cargador_viatico']} },
    { path: 'viaticos/new', component: ViaticosFormComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Alta Viático', roles: ['tesorero', 'cargador_viatico', 'autoridad']} },
    { path: 'viaticos/:id', component: ViaticosFormComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Edición Viático', roles: ['tesorero', 'cargador_viatico', 'autoridad']} },        
    { path: 'authviaticos', component: ViaticosComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Autorización de Viáticos', auth: true, cargadorViaticos: false, roles: ['tesorero']} },
    { path: 'deudas', component: DeudasRetencionComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Deudas Retenciones', roles: ['tesorero', 'administrativo']} },
    { path: 'reportes', component: ReportesComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Reportes', auth: false, cargadorViaticos: true, roles: ['tesorero', 'administrativo']}
    },
    { path: 'reportes/:id', component: ReportesComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Reportes', auth: false, cargadorViaticos: true, roles: ['tesorero', 'administrativo']}
    },
    { path: 'ingresosventas', component: FacturasVentasComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Ingresos', roles: ['tesorero', 'administrativo'], esVenta:['si']} },
    { path: 'ingresosventas/new', component: FacturasVentasFormComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Alta Ingreso', roles: ['tesorero', 'administrativo'], esVenta:['si']} },
    { path: 'ingresosventas/:id', component: FacturasVentasFormComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Edición Ingreso', roles: ['tesorero', 'administrativo'], esVenta:['si']} },
    { path: 'ordenesventas', component: OrdenesVentasComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Ordenes de Venta', roles: ['tesorero', 'administrativo'], esVenta:['si']} },
    { path: 'ordenesventas/new', component: OrdenesVentasFormComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Alta Orden de Venta', roles: ['tesorero', 'administrativo'], esVenta:['si']} },
    { path: 'ordenesventas/:id', component: OrdenesVentasFormComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Edición Orden de Venta', roles: ['tesorero', 'administrativo'], esVenta:['si']} },
    { path: 'puntodeventas', component: PuntoDeVentaComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Punto De Ventas', roles: ['tesorero', 'administrativo'], esVenta:['si']} },
    // { path: 'puntodeventas/new', component: PuntoDeVentaFormComponent, canActivate: [AuthGuardByRole],
    //     data: {title: 'Alta Punto De Venta', roles: ['tesorero', 'administrativo']} },
    // { path: 'puntodeventas/:id', component: PuntoDeVentaFormComponent, canActivate: [AuthGuardByRole],
    //     data: {title: 'Edicion Punto De Venta', roles: ['tesorero', 'administrativo']} },
    { path: 'recibos', component: ReciboComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Recibos', roles: ['tesorero', 'administrativo'], esVenta:['si']} },
    { path: 'reposicion', component: ReposicionComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Reposicion Caja Chica', roles: ['tesorero']} },
    { path: 'notascreditoafip', component: NotaCreditoAfipComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Notas De Credito Afip', roles: ['tesorero', 'administrativo'], esVenta:['si']} },

    {path: 'search-results', component: SearchResultsComponent, canActivate: [AuthGuard],  data: {title: 'Resultados Busqueda'} },

    { path: 'config/afip', component: AfipConfigComponent, canActivate: [AuthGuardByRole],
        data: {title: 'Configuración Afip', roles: ['admin_sistema']} },

    { path: '**', redirectTo: '', pathMatch: 'full'}
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
