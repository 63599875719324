<div *ngIf="movimiento" class="row">
    <movimientos_form [movimiento]="movimiento" (actualize)="actualizeForm($event)" (cancel)="cancelForm($event)"></movimientos_form>
</div>

<div class="row">
    <div class="card">
        <div class="card-content">
            <h4 class="card-title col-md-10">Movimientos</h4>
            <div class="col-md-2 mt-1">
                <div class="pull-right" > 
                    <a href="#" data-toggle="dropdown" class="text-left btn-info">
                        <p><i class="cmp-i exportar"></i>  Exportar<i class="ml-1 ti-angle-down"></i></p>
                    </a>
                    <ul class="dropdown-menu">
                        <li>
                            <a (click)="pdfMovimientos(0)" class="cursor-pointer">Movimientos (.pdf) <i class="cmp-i descargar-pdf"></i></a>
                            <a (click)="pdfMovimientos(1)" class="cursor-pointer">Movimientos (.xls) <i class="cmp-i descargar-excel"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row"></div>
            <hr>

            <filters-header-section (onFilter)="filter(1)">
                <div class="col-md-12">
                    <div class="col-md-4">
                        <label for="metodo_pago_id">Método de pago</label>
                        <select class="form-control" data-style="btn btn-wd btn-block" name="metodo_pago_id"  [(ngModel)]="filtro.metodo_pago_id" (change)="filter(1)">
                            <option [ngValue]="null">Todos</option>
                            <option *ngFor="let metodo of metodos" [ngValue]="metodo.id">{{metodo.nombre}}</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label for="params_tipo">Tipo</label>
                        <select class="form-control" data-style="btn btn-wd btn-block" name="params_tipo" [(ngModel)]="params.tipo" (change)="filter(1)">
                            <option [ngValue]="null">Todos</option>
                            <option value="reposicion">Reposiciones</option>
                            <option value="egreso">Egresos</option>
                            <option value="ingreso">Ingresos</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <generic_period (outDate)="changeFilterDate($event)"></generic_period>                        
                </div>
            </filters-header-section>



            <div class="row mt-2" *ngIf="cuenta.proveedor == null">
                <div class="col-sm-9">
                    <button class="btn btn-simple btn-info btn-icon like btn-add" *ngIf="! movimiento && cuenta.tipo != 'CAJA_CHICA'" (click)="newMovimiento()">Agregar <i class="cmp-i agregar"></i></button>
                </div>  
                <div class="col-sm-3">
                    <sort-select-structured [options]="sortOptions" [(sortModel)]="filtro.sort" (onSelect)="filter(1)"></sort-select-structured>
                </div>
            </div>
            
            <div class="row mt-1">
                <div class="col-md-12 table-responsive">
                    <table class="table table-hover">
                        <thead class="text-primary table-header-title">
                            <tr>
                                <th rowspan="2"><b>Orden de Pago</b></th>
                                <th rowspan="2"><b>Monto</b></th>
                                <th rowspan="2"><b>Moneda</b></th>
                                <th rowspan="2"><b>Tipo</b></th>
                                <th colspan="2" class="text-center"><b>Origen / Destino</b></th>
                                <th rowspan="2"><b>Fecha</b></th>
                                <th rowspan="2"><b>Método de Pago</b></th>
                                <th rowspan="2"><b>Cheque</b></th>
                                <th rowspan="2"><b>Descripción</b></th>
                                <th rowspan="2"><b>Acciones</b></th>
                            </tr>
                            <tr>
                                <th class="text-center"><b>Banco</b></th>
                                <th class="text-center"><b>Cuenta</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor='let movimiento of movimientos'>
                                <td>
                                    <span *ngIf="!movimiento.orden_pago_id">-</span>
                                    <span *ngIf="movimiento.orden_pago_id"><a [routerLink]="['/ordenespago', movimiento.orden_pago_id]" rel="tooltip" title="Detalle Orden de Pago">{{movimiento.orden_pago_id}}</a></span>
                                </td> 
                                <td>
                                    <span *ngIf="movimiento.cuentadestino == null || movimiento.cuenta.id == cuenta.id" class="status-danger">-{{movimiento.monto | number: '1.2-2'}}</span>
                                    <span *ngIf="movimiento.cuentadestino && movimiento.cuentadestino.id == cuenta.id" class="status-sucess">{{movimiento.monto | number: '1.2-2'}}</span>
                                </td>

                                <td>{{movimiento.moneda | translate: 'MONEDA'}}</td>
                                <td>
                                    <span *ngIf="movimiento.cuentadestino == null || movimiento.cuenta.id == cuenta.id">Egreso</span>
                                    <span *ngIf="movimiento.cuentadestino && movimiento.cuentadestino.id == cuenta.id">Ingreso</span>
                                </td>
                                
                                <td *ngIf="movimiento.cuentadestino == null || movimiento.cuenta.id == cuenta.id">
                                    <div *ngIf="movimiento.cuentadestino && movimiento.cuentadestino.id != cuenta.id">
                                        <span *ngIf="movimiento.cuentadestino.tipo == 'BANCO' && movimiento.cuentadestino.banco_id">
                                            <span [class]="'bank-logo bank-logo-' + movimiento.cuentadestino.banco_id" *ngIf="BANK_LOGO_IDS.includes(movimiento.cuentadestino.banco_id)"></span>
                                        </span>
                                        <span *ngIf="movimiento.cuentadestino.tipo != 'BANCO' || !movimiento.cuentadestino.banco_id" class="text-center">-</span>
                                    </div>
                                    <span *ngIf="!movimiento.cuentadestino || movimiento.cuentadestino && movimiento.cuentadestino.id == cuenta.id" class="text-center">-</span>
                                </td>
                                <td *ngIf="movimiento.cuentadestino == null || movimiento.cuenta.id == cuenta.id">
                                    <div *ngIf="movimiento.cuentadestino && movimiento.cuentadestino.id != cuenta.id">
                                        <span> {{movimiento.cuentadestino.nombre}}</span>
                                    </div>
                                    <span *ngIf="!movimiento.cuentadestino || movimiento.cuentadestino && movimiento.cuentadestino.id == cuenta.id" class="text-center">-</span>
                                </td>

                                <td *ngIf="movimiento.cuentadestino && movimiento.cuentadestino.id == cuenta.id">
                                    <div *ngIf="movimiento.cuenta.nombre && movimiento.cuenta.id != cuenta.id">
                                        <span *ngIf="movimiento.cuenta.tipo == 'BANCO' && movimiento.cuenta.banco_id">
                                            <span [class]="'bank-logo bank-logo-' + movimiento.cuenta.banco_id" *ngIf="BANK_LOGO_IDS.includes(movimiento.cuenta.banco_id)"></span>
                                        </span>
                                        <span *ngIf="movimiento.cuenta.tipo != 'BANCO' || !movimiento.cuenta.banco_id" class="text-center">-</span>
                                    </div>
                                    <span *ngIf="!movimiento.cuenta.nombre || movimiento.cuenta.id == cuenta.id" class="text-center">-</span>
                                </td>
                                <td *ngIf="movimiento.cuentadestino && movimiento.cuentadestino.id == cuenta.id">
                                    <div *ngIf="movimiento.cuenta.nombre && movimiento.cuenta.id != cuenta.id">
                                        <a [routerLink]="['/cuentas', movimiento.cuenta.id]" rel="tooltip" title="Detalle Cuenta">{{movimiento.cuenta.nombre}}</a>
                                    </div>
                                    <span *ngIf="!movimiento.cuenta.nombre || movimiento.cuenta.id == cuenta.id" class="text-center">-</span>
                                </td>


                                <td>{{movimiento.fecha_contable | date: 'dd/MM/yyyy'}}</td>
                                <td>{{movimiento.metodopago.nombre}}</td>
                                <td>
                                    <span *ngIf="!movimiento.cheque">-</span>
                                    <span *ngIf="movimiento.cheque"><a (click)="detalleCheque(movimiento.cheque)" rel="tooltip" title="Detalle">{{movimiento.cheque.numero}}</a></span>
                                </td>  
                                <td>
                                    <span *ngIf="!movimiento.descripcion">-</span>
                                    <span *ngIf="movimiento.descripcion">{{movimiento.descripcion}}</span>
                                    <span *ngIf="movimiento.anulado"> (ANULADO)</span>                    
                                </td>                             
                                <td class="td-actions text-right">
                                    <button type="button" rel="tooltip" title="Eliminar Movimiento" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="movimiento" confirm-message="¿Está seguro que desea eliminar el Movimiento?">
                                        <i class="cmp-i eliminar-borrar i-sm"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr *ngIf="movimientos != null && movimientos.length == 0" class="text-center">
                                <td colspan="11">No se encontraron movimientos</td>
                            </tr>
                        </tbody>
                    </table>
                    <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)"  *ngIf="movimientos != null && movimientos.length > 0"></pager>
                </div>
            </div>
        </div>
    </div>
</div>