import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Sort from 'app/models/Sort';

@Component({
  selector: 'sort-select-structured',
  templateUrl: './sort-select-structured.component.html',
  styleUrls: ['./sort-select-structured.component.scss']
})
export class SortSelectStructuredComponent implements OnInit {
  @Input() options: Sort[] = [];
  @Input() sortModel: Sort;
  @Output() sortModelChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

  public optionKeys: string[] = [];

  constructor() { }

  ngOnInit(): void {
    this.optionKeys = Object.keys(this.options);
  }

  public onSelectLocal(option: Sort): void {
    this.sortModel = option;
    this.sortModelChange.emit(option);
    this.onSelect.emit();
  }

}
