import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute } from '@angular/router';

import {environment} from '../../../environments/environment';

import {ViaticoService} from '../viatico.service';
import {UserService} from '../../module-user/user.service';
import {DistritoService} from '../../module-distrito/distrito.service';
import {GlobalVars} from '../../services/globalVars';
import { MessageService } from '../../services/message.service';
import {PdfService} from '../../services/pdf.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {User, PermisoViatico} from '../../models/user';
import {Viatico} from '../models';
import {Distrito} from '../../module-distrito/models';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
import { FilterCache } from 'app/models/filter-cache';
import { FilterCacheUtilsService } from 'app/services/filter-cache-utils.service';

@Component({
  selector: 'viaticos',
  templateUrl: './viaticos.component.html'
})
export class ViaticosComponent extends GenericFormComponent implements OnInit {
  public actualUser: User;
  public authViaticos: boolean = false;
  public cargadorViaticos: boolean = false;
  public permisosViaticos: PermisoViatico[] = [];
  
  public viaticos: Viatico[];
  public params = {page: 1, per_page: 10, proveedor_search: null};
  public filtroDefault = {or_numero_matches: null, estado_eq: null, emision_gteq: null, emision_lteq: null, 
    distrito_id_eq: null, proveedor_id_eq: null, user_id_di: null, sort: 'emision,desc'};
  public filtro = Object.assign({},this.filtroDefault);
  public someFiltersApplied: boolean = false;
  private viewId: string = "viaticos"

  public distritos: Distrito[] = [];
  public estados: string[] = environment.estadosViatico;
  //FORM
  public viatico: Viatico;

  public hasLoadPermissions: boolean = true;

  public sortOptions: { [id: string] : string } = { 'emision,desc': 'Fecha de emisión', 'numero': 'Número', 'distrito_id': 'Distrito' };
  public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }];

  constructor(protected messageService: MessageService, private globalVars: GlobalVars, private router: Router, private activatedRoute: ActivatedRoute,
      private viaticoService: ViaticoService, private distritoService: DistritoService, private userService: UserService,
      private pdfService: PdfService, private filterCacheUtilsService: FilterCacheUtilsService) {
    super(messageService);
  }

  ngOnInit() {
    this.actualUser = this.globalVars.actualUser;    
    
    this.authViaticos = this.activatedRoute.snapshot.data['auth'];
    this.cargadorViaticos = this.activatedRoute.snapshot.data['cargadorViaticos'];
    if (this.authViaticos) this.viewId = "viaticos_auth"
    if (this.cargadorViaticos) this.viewId = "viaticos_cargador"

    this.loadInitFilters();
    this.loadCacheFiltersParams();
    
    if (this.authViaticos || this.cargadorViaticos) {
        this.filter();
        if (this.authViaticos) 
            this.breadcrumbItems.push({title: 'Autorización de viáticos', active: true });
        else 
            this.breadcrumbItems.push({title: 'Carga de viáticos', active: true });
    } else {
        this.userService.getPermisosViaticos({}, {user_id_eq: this.actualUser.id}).subscribe(
            (permisos: PermisoViatico[]) => {
                if (permisos.length == 0) {
                    this.messageService.showNotification("top", "center", "warning", 'No tiene asociada ninguna persona para asignar viaticos');
                    this.hasLoadPermissions = false;
                    return;
                }
                this.hasLoadPermissions = true;
                this.permisosViaticos = permisos;
                this.filter();
            }
        )
        this.breadcrumbItems.push({title: 'Mis viáticos', active: true });
    }
    
    
    this.distritoService.getDistritos({per_page: 1000}, {sort: 'nombre'}).subscribe(
        (rta: any) => {
            this.distritos = rta.data;
        }
    );
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.params.proveedor_search = this.filtro.or_numero_matches;
    this.cursorWait();
    this.someFiltersApplied = this.globalVars.isSomeFilterApplied(this.filtro);
    if (this.authViaticos || this.cargadorViaticos) {
        this.viaticoService.getViaticos(this.params, this.filtro).subscribe(
            rta => {
                this.cursorDefault();
                this.viaticos= rta.data;
                rta.info = {
                    page: rta.current_page,
                    countOfPages: rta.last_page,
                    count: rta.total
                }
                this.assemblePager(rta.info);
                this.filterCacheUtilsService.setFilterParams(this.viewId, this.filtro, this.params);
            },
            error => {
                this.cursorDefault();
            }
        );
    } else {
        if (this.permisosViaticos.length == 0) {
            return;
        }
        this.viaticoService.getMisViaticos(this.permisosViaticos[0].proveedor.id, this.params, this.filtro).subscribe(
            rta => {
                this.cursorDefault();
                this.viaticos= rta.data;
                rta.info = {
                    page: rta.current_page,
                    countOfPages: rta.last_page,
                    count: rta.total
                }
                this.assemblePager(rta.info);
                this.filterCacheUtilsService.setFilterParams(this.viewId, this.filtro, this.params);
            },
            error => {
                this.cursorDefault();
            }
        );
    }
  }

  public pdfViatico(viatico) {
      this.cursorWait();
      this.viaticoService.pdfViatico(viatico.id).subscribe(
          rta => {
              this.cursorDefault();
              this.pdfService.loadPdf("viatico", rta); 
          },
          error => {
              this.cursorDefault();
              alert('Ups! No pudimos imprimir el pdf');
          }
      );
  }
    
  public changeFilterDate(value){
    this.filtro.emision_gteq = value.from;
    this.filtro.emision_lteq = value.to;
    this.filter(1);
  }
  
  //ACCIONES  
  changeState(viatico: Viatico, estado: string, event){
    this.beforeSubmitList(event);
    this.viaticoService.cambiarEstadoViatico(viatico, estado).subscribe(
        rta => {
            this.showSuccessList(true, 'Viatico modificado correctamente');
            viatico.estado = estado;
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }
  
  onNotifyDelete(viatico: Viatico):void {
    this.deleteViatico(viatico);
  }
    
  public deleteViatico(viatico: Viatico) {
    this.beforeSubmitList();
    this.viaticoService.deleteViatico(viatico.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Viatico eliminado correctamente');
            //ELIMINO DE LA LISTA      
            this.viaticos.forEach(function(viaticoFor, index, object) {
                if(viaticoFor.id == viatico.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }



  private loadInitFilters(): void {
    this.filtro.distrito_id_eq = this.globalVars.getActualDistrito();
  }

  private loadCacheFiltersParams() {
    let cacheFilters: FilterCache = this.filterCacheUtilsService.getFilterParams(this.viewId);
    if (!cacheFilters) return;

    this.filtro = cacheFilters.filters;
    this.params = cacheFilters.params;
  }

  public resetFilters() {
    this.filtro = Object.assign({}, this.filtroDefault);
    this.loadInitFilters();
    this.filter();
  }
  
}