<div class="container-fluid">
    <div *ngIf="user" class="row">
        <users_form [user]="user" (actualize)="actualizeForm($event)" (cancel)="cancelForm($event)"></users_form>
    </div>
    
    <div class="row">
        <div class="card">
            <div class="card-content">
                <breadcrumb [items]="breadcrumbItems"></breadcrumb>                                 

                <filters-header-section (onFilter)="filter(1)">
                    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                        <label for="search">Usuario:</label>
                        <div class="input-group">
                            <input class="form-control" type="text" placeholder="Búsqueda de usuarios" name="search" [(ngModel)]="filtro.or_username_matches">
                            <span class="input-group-addon" (click)="filter(1)"><i class="cmp-i busqueda i-sm"></i></span>
                        </div>
                    </div>
                                        
                    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                        <label for="distrito_id">Distrito:</label>
                        <select class="form-control" data-style="btn btn-wd btn-block" name="distrito_id"  [(ngModel)]="filtro.distrito_id_eq" (change)="filter(1)" [disabled]="! actualUser.multi_distrito">
                            <option [ngValue]="null">Todos</option>
                            <option *ngFor="let distrito of distritos" [ngValue]="distrito.id">{{distrito.nombre}}</option>
                        </select>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-2 text-center">
                        <div class="form-group">
                            <label class="checkbox-inline"><input type="checkbox" name="enabled" [(ngModel)]="filtro.habilitado_eq" (change)="filter(1)"> Ver solo usuarios habilitados</label>
                        </div>
                    </div> 
                </filters-header-section>
            
                <div class="row mt-2">
                    <div class="col-sm-10">
                        <button class="btn btn-simple btn-icon btn-add" *ngIf="! user" (click)="newUser()" title="Agregar"><i class="cmp-i agregar"></i> Agregar</button>
                    </div>  
                    <div class="col-sm-2">
                        <sort-select [options]="sortOptions" [(sortModel)]="filtro.sort" (onSelect)="filter(1)"></sort-select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 table-responsive">
                        <table class="table table-hover">
                            <thead class="text-primary">
                                <tr>
                                    <th><b>Usuario</b></th>
                                    <th><b>Nombre</b></th>
                                    <th><b>Email</b></th>
                                    <th><b>Habilitado</b></th>                                    
                                    <!--<th><b>Cobra Viaticos</b></th>-->
                                    <th><b>Distrito</b></th>
                                    <th><b>Roles</b></th>
                                    <th><b>Acciones</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let user of users'>
                                    <td><a (click)="updateUser(user)" rel="tooltip" title="Editar Usuario">{{user.username}}</a></td>
                                    <td>{{user.nombre}}</td>
                                    <td>{{user.email}}</td>
                                    <td>
                                        <span *ngIf='user.habilitado'>Habilitado</span>
                                        <span *ngIf='!user.habilitado'>Deshabilitado</span>
                                    </td>                                    
<!--                                    <td>
                                        <span *ngIf="user.cobra_viatico">SI</span>                                        
                                        <span *ngIf="!user.cobra_viatico">NO</span>
                                    </td>-->
                                    <td>
                                        <span *ngIf="user.distrito">{{user.distrito.nombre}}</span>
                                        <span *ngIf="! user.distrito">-</span>
                                    </td>
                                    <td>{{user.printRoles()}}</td>
                                    <td class="td-actions text-right">
                                        <a *ngIf="user.hasAtLeastOneRolByName(['tesorero', 'autoridad'])" [routerLink]="['/usuarios', user.id, 'permisos']" rel="tooltip" title="Permisos" class="btn btn-primary btn-simple btn-xs">
                                            <i class="ti-key"></i>
                                        </a>
                                        <button type="button" rel="tooltip" title="Editar Usuario" class="btn btn-primary btn-simple btn-xs btn-cmp-i" (click)="updateUser(user)">
                                            <i class="cmp-i editar i-sm"></i>
                                        </button>
                                        <button *ngIf="user.habilitado" type="button" rel="tooltip" title="Deshabilitar Usuario" class="btn btn-danger btn-simple btn-xs" (click)="changeState(user, false, $event)">
                                            <i class="ti-close"></i>
                                        </button>
                                        <button *ngIf="!user.habilitado" type="button" rel="tooltip" title="Habilitar Usuario" class="btn btn-danger btn-simple btn-xs" (click)="changeState(user, true, $event)">
                                            <i class="ti-check"></i>
                                        </button>
                                        <button *ngIf="!user.enabled" type="button" rel="tooltip" title="Eliminar Usuario" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="user" confirm-message="¿Está seguro que desea eliminar el Usuario?">
                                            <i class="cmp-i eliminar-borrar i-sm"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="users != null && users.length == 0" class="text-center">
                                    <td colspan="7">No se encontraron usuarios</td>
                                </tr>
                            </tbody>
                        </table>                        
                        <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)" *ngIf="users != null && users.length > 0"></pager>
                    </div>
                </div>
            </div>
        </div>          
    </div>
</div>