<div class="container-fluid">    
    <div class="row">
        <div class="card">
            <div class="card-content">
                <div class="row">
                    <breadcrumb [items]="breadcrumbItems" class="col-md-10"></breadcrumb>
                    <div class="col-md-2 mt-1">
                        <div class="pull-right"> 
                            <a href="#" data-toggle="dropdown" class="text-left  btn-info">
                                <p><i class="cmp-i exportar"></i>  Exportar<i class="ml-1 ti-angle-down"></i></p>
                            </a>
                            <ul class="dropdown-menu">
                                <li>
                                    <a (click)="pdfOrdenes(0)" class="cursor-pointer">Ordenes (.pdf) <i class="cmp-i descargar-pdf"></i></a>
                                    <a (click)="pdfOrdenes(1)" class="cursor-pointer">Ordenes (.xls) <i class="cmp-i descargar-excel"></i></a>
                                    <a (click)="pdfRetenciones(0)" class="cursor-pointer">Retenciones (.pdf) <i class="cmp-i descargar-pdf"></i></a>
                                    <a (click)="pdfRetenciones(1)" class="cursor-pointer">Retenciones (.xls) <i class="cmp-i descargar-excel"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <filters-header-section (onFilter)="filter(1)" [advancedFilters]="true" [someFilterApllied]="someFiltersApplied" (onClear)="resetFilters()">
                    <div class="col-sm-12">
                        <div class="col-sm-12 col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4">
                            <label for="search_elastic">Buscador:</label>
                            <div class="input-group">
                                <input #search_elastic class="form-control" type="text" placeholder=" Punto de Venta / Número / Proveedor" name="search_elastic" [(ngModel)]="filtro.search_elastic" (keydown.enter)="filter(1)" autofocus>
                                <span class="input-group-addon" (click)="filter(1)"><i class="cmp-i busqueda i-sm"></i></span>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12">
                        <div class="col-sm-12 col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4">
                            <label for="distrito_id">Distrito:</label>
                            <select class="form-control" data-style="btn btn-wd btn-block" name="distrito_id"  [(ngModel)]="filtro.distrito_id" (change)="filter(1)" [disabled]="!actualUser.multi_distrito">
                                <option [ngValue]="null">Todos</option>
                                <option *ngFor="let distrito of distritos" [ngValue]="distrito.id">{{distrito.nombre}}</option>
                            </select>
                        </div>
                    </div>
                
                    <div moreFilters>
                        <div class="col-md-12">
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="estado">Estado:</label>
                                <select class="form-control" data-style="btn btn-wd btn-block" name="estado" [(ngModel)]="filtro.estado" (change)="changeEstado()">
                                    <option [ngValue]="null">Todos</option>
                                    <option [ngValue]="'abiertas'">Abiertas</option>
                                    <option [ngValue]="'finalizadas'">Finalizadas</option>
                                    <option [ngValue]="'revision'">En Revisión</option>
                                    <option [ngValue]="'verificadas'">Verificadas</option>
                                    <option [ngValue]="'sinverificar'">Sin Verificar</option>
                                    <option [ngValue]="'anuladas'">Anuladas</option>
                                </select>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="punto_venta">Punto de Venta:</label>
                                <div class="form-group ordenar filter-form-control">
                                    <input type="text" class="form-control" placeholder="Punto de Venta" name="punto_venta" [(ngModel)]="filtro.punto_venta">
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="numero">Nro Comprobante:</label>
                                <div class="form-group ordenar filter-form-control">
                                    <input type="text" class="form-control" placeholder="Nro Comprobante" name="numero" [(ngModel)]="filtro.numero">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12" style="margin-top: 10px;">
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="pagado_gteq">Pagado Mínimo:</label>
                                <div class="form-group ordenar filter-form-control">
                                    <input type="number" class="form-control" placeholder="Pagado" name="pagado_gteq" [(ngModel)]="filtro.pagado_gteq" min="0">
                                    <div class="text-danger" *ngIf="filtro.pagado_gteq && filtro.pagado_lteq && filtro.pagado_gteq >= filtro.pagado_lteq">
                                        El monto pagado mínimo tiene que ser menor que el monto pagado máximo
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="pagado_lteq">Pagado Máximo:</label>
                                <div class="form-group ordenar filter-form-control">
                                    <input type="number" class="form-control" placeholder="Pagado" name="pagado_lteq" [(ngModel)]="filtro.pagado_lteq" min="0">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12" style="margin-top: 10px;">                                           
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="monto_gteq">Monto Mínimo:</label>
                                <div class="form-group ordenar filter-form-control">
                                    <input type="number" class="form-control" placeholder="Monto Mínimo" name="monto_gteq" [(ngModel)]="filtro.monto_gteq" min="0">
                                    <div class="text-danger" *ngIf="filtro.monto_gteq && filtro.monto_lteq && filtro.monto_gteq >= filtro.monto_lteq">
                                        El monto mínimo tiene que ser menor que el monto máximo
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="monto_lteq">Monto Máximo:</label>
                                <div class="form-group ordenar filter-form-control">
                                    <input type="number" class="form-control" placeholder="Monto Máximo" name="monto_lteq" [(ngModel)]="filtro.monto_lteq" min="0">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <generic_period (outDate)="changeFilterDate($event)" [fromDate]="filtro.fecha_gteq" [toDate]="filtro.fecha_lteq" [enableCleanOption]="true"></generic_period>
                        </div>
                                        
                    </div>	
                </filters-header-section> 
                

                <div class="row mt-2">
                    <div class="col-sm-10">
                        <button class="btn btn-simple btn-icon btn-add" [routerLink]="['/ordenespago/new']" title="Agregar"><i class="cmp-i agregar"></i> Agregar</button>
                    </div>  
                    <div class="col-sm-2">
                        <sort-select-structured  [options]="sortOptions" [(sortModel)]="filtro.sort" (onSelect)="filter(1)"></sort-select-structured >
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 table-responsive">
                        <table class="table table-hover">
                            <thead class="text-primary">
                                <tr>
                                    <th><b>Número</b></th>
                                    <th><b>Proveedor</b></th>
                                    <th><b>Fecha</b></th>
                                    <th><b>Monto</b></th>
                                    <th><b>A pagar</b></th>
                                    <th><b>Notas Credito</b></th>
                                    <th><b>Ret. Gana.</b></th>
                                    <th><b>Ret. Caja</b></th>
                                    <th><b>Pagado</b></th>
                                    <th><b>Estado</b></th>
                                    <th><b>Acciones</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let orden of ordenes'>
                                    <td>
                                        <a [routerLink]="['/ordenespago', orden.id]" rel="tooltip" title="Editar Orden">{{orden.punto_venta}} - {{orden.numero}}</a>
                                        <span *ngIf="orden.anulada"> (ANULADA)</span>
                                    </td>
                                    <td>
                                        <a *ngIf="orden.proveedor" [routerLink]="['/proveedores', orden.proveedor.id]" rel="tooltip" title="Detalle Proveedor" class="link-no-highlight">{{orden.proveedor.nombre}}</a>
                                        <span *ngIf="!orden.proveedor">-</span>
                                    </td>
                                    <td>{{orden.fecha | date: 'dd/MM/yyyy'}}</td>
                                    <td>$&#160;{{orden.monto | number: '1.2-2'}}</td>
                                    <td>$&#160;{{orden.monto_descuentos | number: '1.2-2'}}</td>
                                    <td>$&#160;{{orden.total_notas_credito | number: '1.2-2'}}</td>
                                    <td>$&#160;{{orden.retencion | number: '1.2-2'}}</td>
                                    <td>$&#160;{{orden.descuentos | number: '1.2-2'}}</td>
                                    <td>$&#160;{{orden.pagado | number: '1.2-2'}}</td>
                                    <td>
                                        <span *ngIf="orden.anulada" class="status-danger">
                                            <b>Anulada</b><i class="ml-1 ti-na"></i>
                                        </span>
                                        <span *ngIf="orden.verificada" class="status-sucess">
                                            <b>Verificada</b><i class="ml-1 ti-check"></i>
                                        </span>
                                        <span *ngIf="orden.revision && !orden.anulada" class="status-warning">
                                            <b>Revisión</b><i class="ml-1 ti-time"></i>
                                        </span>
                                        <span *ngIf="orden.finalizada && !orden.revision && !orden.verificada && !orden.anulada" class="status-sucess">
                                            <b>Finalizada</b><i class="ml-1 ti-check-box"></i>
                                        </span>
                                        <span *ngIf="!orden.finalizada && !orden.revision" class="status-info">
                                            <b>Iniciada</b><i class="ml-1 ti-bookmark-alt"></i>
                                        </span>
                                    </td>
                                    <td class="td-actions text-right">
                                        <button type="button" rel="tooltip" title="Imprimir Orden" class="btn btn-danger btn-simple btn-xs" (click)="pdfOrden(orden)">
                                            <i class="ti-printer"></i>
                                        </button>
                                        <a [routerLink]="['/ordenespago', orden.id]" rel="tooltip" title="Editar Orden" class="btn btn-primary btn-simple btn-xs btn-cmp-i">
                                            <i class="cmp-i editar i-sm"></i>
                                        </a>
                                        <button type="button" rel="tooltip" title="Eliminar Orden" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="orden" confirm-message="¿Está seguro que desea eliminar la Orden?">
                                            <i class="cmp-i eliminar-borrar i-sm"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="ordenes != null && ordenes.length == 0" class="text-center">
                                    <td colspan="11">No se encontraron ordenes</td>
                                </tr>
                            </tbody>
                        </table>                        
                        <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)" *ngIf="ordenes != null && ordenes.length > 0"></pager>
                    </div>
                </div>
            </div>
        </div>          
    </div>
</div>