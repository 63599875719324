<div class="card">
    <div class="card-content">
        <h4 class="card-title" *ngIf="!proveedor.id">Nuevo Proveedor</h4>
        <hr *ngIf="!proveedor.id">
        <breadcrumb [items]="breadcrumbItems" *ngIf="proveedor.id"></breadcrumb>

        <form class="form-horizontal" (submit)="save($event)" #datosForm="ngForm">
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">CUIT</label>
                        <div class="col-md-7">  
                            <div *ngIf="!editable" class="provider-data">{{proveedor.cuit | cuitformat}}</div>                               
                            <input #firstElementProveedor type="text" name="cuit" [(ngModel)]="proveedor.cuit" class="form-control" placeholder="20356089522" (keyup.enter)="searchCuit()" 
                                (blur)="enabledSubmit()" (focus)="disabledSubmit()" required *ngIf="editable">
                            <small [hidden]="!inputError.cuit" [innerHtml]="inputError.cuit" class="text-danger"></small>
                        </div>
                        <div class="col-md-2" [hidden]="!editable">
                            <button type="button" class="btn btn-primary pull-right" (click)="searchCuit()"><i class="cmp-i busqueda i-sm"></i> </button>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Nombre</label>
                        <div class="col-md-9">
                            <span *ngIf="!editable" class="provider-data">{{proveedor.nombre}}</span>                                  
                            <input type="text" name="nombre" [(ngModel)]="proveedor.nombre" class="form-control" placeholder="Juan Perez" *ngIf="editable" required>
                            <small [hidden]="!inputError.nombre" [innerHtml]="inputError.nombre" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Condición Iva</label>
                        <div class="col-md-9">
                            <span *ngIf="!editable" class="provider-data">{{proveedor.condicion_iva}}</span>
                            <select class="form-control" data-style="btn btn-wd btn-block" name="condicion_iva" [(ngModel)]="proveedor.condicion_iva" 
                                (change)="changecondicion_iva()" *ngIf="editable">
                                <option [ngValue]="null">Seleccione condicion frente al Iva</option>
                                <option *ngFor="let condicion of condiciones" [ngValue]="condicion">{{condicion | translate: 'CONIVA'}}</option>
                            </select>
                            <small [hidden]="!inputError.condicion_iva" [innerHtml]="inputError.condicion_iva" class="text-danger"></small>
                        </div>                           
                    </div>
                </div>
            </div>            

            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Dirección</label>
                        <div class="col-md-9">
                            <span *ngIf="!editable" class="provider-data">{{proveedor.direccion}}</span>                          
                            <input type="text" name="direccion" [(ngModel)]="proveedor.direccion" class="form-control" 
                                placeholder="Calle 3 Nro 1234" required *ngIf="editable">
                            <small [hidden]="!inputError.direccion" [innerHtml]="inputError.direccion" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Teléfonos</label>
                        <div class="col-md-9">
                            <span *ngIf="!editable" class="provider-data">{{proveedor.telefono}}</span>                 
                            <input type="text" name="telefono" [(ngModel)]="proveedor.telefono" class="form-control" 
                                placeholder="221-544-2439" *ngIf="editable" required>
                            <small [hidden]="!inputError.telefono" [innerHtml]="inputError.telefono" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>
            
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Email</label>
                        <div class="col-md-9">
                            <span *ngIf="!editable" class="provider-data">{{proveedor.email}}</span>  
                            <input type="email" name="email" [(ngModel)]="proveedor.email" class="form-control" 
                                *ngIf="editable" placeholder="juan@sistema.com">
                            <small [hidden]="!inputError.email" [innerHtml]="inputError.email" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
<!--                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Distrito</label>
                        <div class="col-md-9">
                            <span *ngIf="!editable" class="provider-data">{{proveedor.distrito}}</span>
                            <select class="form-control" data-style="btn btn-wd btn-block" name="distrito" [(ngModel)]="proveedor.distrito" [compareWith]='customCompareDistrito' *ngIf="editable">
                                <option [ngValue]="null">Seleccione un Distrito</option>
                                <option *ngFor="let distrito of distritos" [ngValue]="distrito">{{distrito.nombre}}</option>
                            </select>
                            <small [hidden]="!inputError.distrito_id" [innerHtml]="inputError.distrito_id" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>-->
            </div>
            
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Opciones</label>
                        <div class="col-md-9">
                            <div class="checkbox">
                                <input type="checkbox" name="es_propio" value="1" [(ngModel)]="proveedor.es_propio" checked [disabled]="!editable">
                                <label>Es propio</label>
                            </div>
                            <div class="checkbox">
                                <input type="checkbox" name="consumidor_final" value="1" [(ngModel)]="proveedor.consumidor_final" checked [disabled]="!editable">
                                <label>Consumidor Final</label>
                            </div>
                            <div class="checkbox">
                                <input type="checkbox" name="persona_juridica" value="1" [(ngModel)]="proveedor.persona_juridica" checked [disabled]="!editable">
                                <label>Persona Jurídica</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center">
                <button class="btn btn-default btn-cancel" type="button" (confirm-click)="cancelForm($event)" confirm-message="¿Está seguro que desea cancelar este formulario?" *ngIf="!proveedor.id">Cancelar</button>
                <button class="btn btn-primary ml-2" type="submit" [disabled]="submitted" *ngIf="editable">Guardar</button>
            </div>
            <div class="clearfix"></div>
        </form>
    </div>
</div>