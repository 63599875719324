import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {CuentaService} from '../cuenta.service';
import {GlobalVars} from '../../services/globalVars';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Cuenta} from '../models';
 
@Component({
  selector: 'cuentas_detalle',
  templateUrl: './cuentas_detalle.component.html'
})
export class CuentasDetalleComponent extends GenericFormComponent implements OnInit {
  public cuenta: Cuenta;
  
  constructor(protected messageService: MessageService, private route:ActivatedRoute, private router: Router, private cuentaService: CuentaService,
      private globalVars: GlobalVars) {
    super(messageService);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
        if(params['id']){
            this.cursorWait();
            this.cuentaService.getCuenta(params['id']).subscribe(
                (cuenta: Cuenta) => {
                    this.cursorDefault();
                    this.cuenta = cuenta;
                    
                    if (this.cuenta.proveedor == null) {
                      if (this.cuenta.distrito.id != this.globalVars.getActualDistrito() && ! this.globalVars.actualUser.multi_distrito) {
                          this.messageService.showNotification('top', 'center', 'danger', 'Usted no tiene acceso a esta cuenta');
                          this.router.navigate(['/cuentas']);
                      } else if (this.cuenta.distrito.id != this.globalVars.getActualDistrito() && this.globalVars.actualUser.multi_distrito) {
                          this.globalVars.setActualDistrito(this.cuenta.distrito.id);
                      }
                    }
                }, error => {
                    this.cursorDefault();
                    this.messageService.showNotification('top', 'center', 'danger', 'No se encontro la cuenta');
                    this.router.navigate(['cuentas']);
                }
            );
        }
    });
  }

}