<div class="container-fluid">    
    <div class="row">
        <div class="card">
            <div class="card-content">
                <breadcrumb [items]="breadcrumbItems"></breadcrumb> 
                <div class="row mt-2">
                    <div class="form-group">
                        <div class="col-md-12">
                            <div class="row">
                                <label class="col-md-3 label-on-left">Fecha expiración cerficado: </label>
                                <div class="col-md-7">  
                                    {{certExpirationDate | date: 'dd/MM/yyyy'}} 
                                    <span *ngIf="certStatus == states.active" class="badge badge-custom-success ml-2">Activo</span>
                                    <span *ngIf="certStatus == states.closeToexpire" class="badge badge-custom-warning ml-2">Próximo a expirar</span>
                                    <span *ngIf="certStatus == states.expired" class="badge badge-custom-danger ml-2">Expirado</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                   
            </div>
        </div>          
    </div>
</div>