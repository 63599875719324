import { Component, OnInit, Input, ElementRef, AfterViewInit, ViewChild } from '@angular/core';

import {UserService} from '../../services/user.service';
import {GlobalVars} from '../../services/globalVars';
import { MessageService } from '../../services/message.service';

import {User} from '../../models/user';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';

@Component({
  selector: 'perfil',
  templateUrl: 'perfil.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent extends GenericFormComponent implements OnInit {
    @ViewChild('nameField') firstElement: ElementRef;
    
    @Input() user: User;
    
    public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Perfil de Usuario', active: true }];
    public disabledEdit: boolean = true;
    public disabledEditPassword: boolean = true;
    private initialUserValue: User;
    
    constructor(protected messageService: MessageService, private globalVars: GlobalVars, private userService: UserService) {
        super(messageService);
    }

    ngOnInit(): void {
        this.userService.actualUser().subscribe(
            (user: User) => {
                this.user = user;
                this.initialUserValue = Object.assign({}, user);
            }
        );
    }
    
    public save(event){
        if (this.user.password != this.user.confPassword) {
            this.setInputError('confPassword', "Las contraseñas no coinciden.");
            return;
        }
        if (!this.validateEmail(this.user.email)) {
            this.setInputError('email', "No se corresponde con una dirección de e-mail válida.");
            return;
        }

        this.beforeSubmit(event);



        this.disabledEdit = true;
        this.disabledEditPassword = true;
        this.userService.updatePreferences(this.user).subscribe(
            (user: User) => {
                this.showSuccess(true, 'Usuario modificado correctamente');
                this.globalVars.setActualUser(user);
                this.user = new User(this.globalVars.actualUser);  
                this.initialUserValue = Object.assign({}, this.user);         
            },err => {
                this.showError(err.error, true);
            });
    }

    public restoreInitialValues(){
        this.removeInputError('confPassword');
        this.removeInputError('email');
        this.disabledEdit = true;
        this.disabledEditPassword = true;
        this.user = Object.assign({}, this.initialUserValue);
    }

    private validateEmail(email) {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    public editProfile(){
        this.disabledEdit = false;
        setTimeout(() => {
            this.firstElement.nativeElement.focus();
        }, 10)
    }
}