import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';

//
//FUENTE: https://scotch.io/tutorials/simple-language-translation-in-angular-2-part-1
//

//SERVICE
import { TRANSLATION_PROVIDERS } from './translations';
import { TranslateService } from './translate.service';
//PIPE
import {TranslatePipe, TranslateApiPipe} from './translate.pipe';

//export function loadConfig(translateService: TranslateService) {
//    return () => translateService.loadConfig();
//}

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    TranslatePipe,
    TranslateApiPipe
  ],
  providers: [
    TRANSLATION_PROVIDERS,
    TranslateService,
    
//    { provide: APP_INITIALIZER, useFactory: loadConfig, deps: [TranslateService], multi: true }
  ],
  exports: [  
    TranslatePipe,
    TranslateApiPipe
  ]
})
export class TranslateComponentModule { }
