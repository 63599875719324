<div class="row mt-2">
    <div class="filter-section">            
        <div class="row">
            <div class="col-sm-12 col-md-offset-2 col-md-8">
                <generic_period (outDate)="changeFilterDate($event)" [enableMonthOptions]="true"></generic_period>
            </div>
        </div>

        <div *ngIf="type == 'ordenes'">
            <div class="col-sm-12 col-md-offset-2 col-md-8 col-lg-offset-3 col-lg-6 mt-1">
                <label for="cuenta-sel">Cuenta:</label>
                <ng-select 
                    class="custom-ng-select"
                    [items]="cuentas"
                    [(ngModel)]="params.cuenta" 
                    name="cuenta-sel"
                    placeholder="Seleccione una cuenta"
                >
                    <ng-template ng-label-tmp let-item="item">
                        <span [class]="'mr-2 bank-logo bank-logo-' + item.banco_id" *ngIf="BANK_LOGO_IDS.includes(item.banco_id)"></span>
                        <b>{{item.nombre}}</b>
                        <i class="ml-1">{{item.descripcion?.length > 0 ? '(' + item.descripcion + ') ' : ''}}</i>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                        <span [class]="'bank-logo bank-logo-' + item.banco_id" *ngIf="BANK_LOGO_IDS.includes(item.banco_id)"></span>
                        <span style="margin-left: 100px;" *ngIf="!BANK_LOGO_IDS.includes(item.banco_id)"></span>
                        <b class="ml-2">{{item.nombre}}</b>
                        <i class="ml-1">{{item.descripcion?.length > 0 ? '(' + item.descripcion + ') ' : ''}}</i>
                    </ng-template>
                </ng-select>   
            </div>
        </div>

        <div class="row"></div>

        <div class="row mt-2">
            <div class="col-sm-12 col-md-6 col-md-offset-3">
                <ng-container *ngIf="type == 'ordenes'">
                    <div class="col-sm-12 col-md-6">
                     <custom-btn-loader
                        text="Descargar Pdf"
                        icon="cmp-i descargar-pdf"
                        [loading]="loading && params.excel == 0"
                        [disabled]="!filtro.fecha_gteq || !filtro.fecha_lteq || !params.cuenta || loading"
                        (action)="pdfOrdenes(0)"
                        btnClass="btn btn-info btn-icon btn-block like btn-add"
                    ></custom-btn-loader> 
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <custom-btn-loader
                            text="Descargar Excel"
                            icon="cmp-i descargar-excel"
                            [loading]="loading && params.excel == 1"
                            [disabled]="!filtro.fecha_gteq || !filtro.fecha_lteq || !params.cuenta || loading"
                            (action)="pdfOrdenes(1)"
                            btnClass="btn btn-info btn-icon btn-block like btn-add"
                        ></custom-btn-loader> 
                    </div>
                </ng-container>
                <ng-container *ngIf="type == 'retenciones-ganancia'">
                    <div class="col-sm-12 col-md-6">
                        <custom-btn-loader
                            text="Descargar Pdf"
                            icon="cmp-i descargar-pdf"
                            [loading]="loading && params.excel == 0"
                            [disabled]="!filtro.fecha_gteq || !filtro.fecha_lteq || loading"
                            (action)="pdfRetenciones(0)"
                            btnClass="btn btn-info btn-icon btn-block like btn-add"
                        ></custom-btn-loader> 
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <custom-btn-loader
                            text="Descargar Excel"
                            icon="cmp-i descargar-excel"
                            [loading]="loading && params.excel == 1"
                            [disabled]="!filtro.fecha_gteq || !filtro.fecha_lteq || loading"
                            (action)="pdfRetenciones(1)"
                            btnClass="btn btn-info btn-icon btn-block like btn-add"
                        ></custom-btn-loader> 
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>