import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';

//import 'rxjs/add/operator/toPromise';
import { Observable, of } from 'rxjs';

import { GlobalVars } from '../services/globalVars';

@Injectable()
export class AfipService{
    private afipUrl = 'afip/'; 

    constructor(private http: HttpClient, private globalVars: GlobalVars) {       
    }

    infoCuit(cuit): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.afipUrl}cuit?cuit=${cuit}`;
        return this.http.get<any>(url, this.globalVars.getOptionsRequest());
    }

    public getCertificateExpirationDate(): Observable<string> {
        const url = `${this.globalVars.apiHost}${this.afipUrl}cert_info`;
        return this.http.get<string>(url, this.globalVars.getOptionsRequest());
    }
}