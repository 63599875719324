import { Injectable } from '@angular/core';

import { Subject }    from 'rxjs';

import {Viatico} from './models';

@Injectable()
export class ViaticoCommunicate {
    // Observable string sources    
    private viaticosModalPopSource = new Subject<any>();
    private viaticoModalPopSource = new Subject<number>();
    private viaticosSelecModalPopSource = new Subject<Viatico[]>();
    
    // Observable string streams
    public viaticosModalPop$ = this.viaticosModalPopSource.asObservable();
    public viaticoModalPop$ = this.viaticoModalPopSource.asObservable();
    public viaticosSelecModalPop$ = this.viaticosSelecModalPopSource.asObservable();
    
    constructor() { 
    }
    
    public viaticosModalPopUp(info: any){
        this.viaticosModalPopSource.next(info);
    }
    public viaticoModalPopUp(id: number){
        this.viaticoModalPopSource.next(id);
    }   
    public viaticosSelecModalPopUp(viaticos: Viatico[]){
        this.viaticosSelecModalPopSource.next(viaticos);
    }
}