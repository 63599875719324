import { Component, OnInit } from '@angular/core';

declare var $:any;

declare interface DataTable {
    headerRow: string[];
    footerRow: string[];
    dataRows: string[][];
}

@Component({
    selector: 'data-table-cmp',
    templateUrl: 'proveedor.component.html'
})

export class ProveedorComponent implements OnInit{
    public dataTable: DataTable;
    ngOnInit(){
        this.dataTable = {
            headerRow: [ 'Tipo', 'Emisión', 'Número', 'Nota', 'Nombre', 'Total(mon. original)', 'Total', 'Deudor', 'Acredor', 'Imputado', 'Balance', 'Saldo'],
            footerRow: [ 'Tipo', 'Emisión', 'Número', 'Nota', 'Nombre', 'Total(mon. original)', 'Total', 'Deudor', 'Acredor', 'Imputado', 'Balance', 'Saldo'],
            dataRows: [
            ]
         };
    }
}
