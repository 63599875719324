import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild } from '@angular/core';

import {environment} from '../../../environments/environment';

import {OrdenService} from '../orden.service';
import {CuentaService} from '../../module-cuenta/cuenta.service';
import {ProveedorService} from '../../module-proveedor/proveedor.service';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';

import {Proveedor} from '../../module-proveedor/models';
import {Cuenta, Movimiento, MetodoDePago, Cheque} from '../../module-cuenta/models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

@Component({
  selector: 'movimientos_orden_form',
  templateUrl: 'movimientos_form.html'
})
export class MovimientosFormComponent extends GenericFormComponent implements OnInit, AfterViewInit{
    @ViewChild('firstElementMovimiento', { static: true }) firstElement: ElementRef;
    
    @Input() movimiento: Movimiento = new Movimiento();
    @Input() proveedor: Proveedor;
    public metodos: MetodoDePago[] = [];
    public cuentas: Cuenta[] = [];
    public cuentasFiltro: Cuenta[] = [];
    public cuentasDestino: Cuenta[] = [];
    public cuentasDestinoFiltro: Cuenta[] = [];
    public monedas: string[] = environment.monedas;
    public numeroCheque: number;
    
    public cheque: Cheque = null;
    
    @Output() actualize: EventEmitter<Movimiento> = new EventEmitter<Movimiento>();
    @Output() cancel: EventEmitter<string> = new EventEmitter<string>();
    
    constructor(protected messageService: MessageService, private cuentaService: CuentaService, private proveedorService: ProveedorService, private ordenService: OrdenService,
        private globalVars: GlobalVars) {
        super(messageService);
    }

    ngOnInit(): void {
        this.cuentaService.getMetodosDePago({per_page: 1000}, {sort: 'nombre'}).subscribe(
            (rta: any) => {
                this.metodos = rta.data;
            }
        );
        this.cuentaService.getCuentas({per_page: 1000}, {distrito_id_eq: this.globalVars.getActualDistrito(), sort: 'nombre'}).subscribe(
            (rta: any) => {
                this.cuentas = rta.data.filter(c => !c.proveedor_id);
            }
        );
        this.proveedorService.getCuentasProveedor(this.proveedor.id).subscribe(
            (cuentas: Cuenta[]) => {
                this.cuentasDestino = cuentas;
                if (cuentas.length == 0) {
                    this.messageService.showNotification("top", "center", "warning", 'El proveedor no tiene cuentas');
                }
            }
        );
    }
    
    ngAfterViewInit(): void {
        this.firstElement.nativeElement.focus();
    }
    
    public buscarCheque() {
        this.cuentaService.getCheques({cuenta: this.movimiento.cuenta.id}, {numero_eq: this.numeroCheque, estado_di: 'ANULADO'}).subscribe(
            (rta: any) => {
                if (rta.data.length > 0) {
                    this.movimiento.cheque = rta.data[0];
                    this.movimiento.monto = this.movimiento.cheque.monto;
                } else {
                    this.messageService.showNotification("top", "center", "warning", 'No se encontro el cheque');
                }
            }
        )
    }
    
    public save(event){
        this.beforeSubmit(event);
        this.ordenService.createMovimiento(this.movimiento).subscribe(
            (movimiento: Movimiento) => {
                this.showSuccess(true, 'Movimiento creado correctamente');
                this.actualize.emit(movimiento);
            },err => {
                this.showError(err, true);
            });
    }
    
    public cancelForm($event?){
        this.cancel.emit("Cancelacion");
    }
    
    public newCheque() {
        this.submitted = true;
        this.cheque = new Cheque();
        this.cheque.monto = this.movimiento.monto;
        if (this.proveedor) {
            this.cheque.nombre = this.proveedor.nombre;
            this.cheque.estado = 'PROVEEDOR';
        }
    }
    
    //
    //DESDE EL FORMULARIO DE CHEQUE
    public actualizeFormCheque(cheque: Cheque): void {
        this.movimiento.cheque = cheque;
        this.movimiento.monto = this.movimiento.cheque.monto;
        this.cancelFormCheque();
    }    
    public cancelFormCheque(event?): void {
        this.submitted = false;
        this.cheque = null;
    }
    
    //
    //UTILS
    public changeMetodo() {
        if (this.movimiento.metodopago == null) {
            this.cuentasFiltro = [];
            return;
        }
        if (this.movimiento.metodopago.tipo == 'TRANSFERENCIA' || this.movimiento.metodopago.tipo == 'DEBITO') {
            this.cuentasFiltro = this.cuentas.filter(c => c.tipo == 'BANCO');
        }else if (this.movimiento.metodopago.tipo == 'CHEQUE') {
            this.cuentasFiltro = this.cuentas.filter(c => c.tipo == 'BANCO');
        }else if (this.movimiento.metodopago.tipo == 'EFECTIVO') {
            this.cuentasFiltro = this.cuentas.filter(c => c.tipo == 'CAJA_CHICA');
        }else {
            this.cuentasFiltro = [];
        }
        this.movimiento.cuenta = null;
        if (this.cuentasFiltro.length >= 1) {
            this.movimiento.cuenta = this.cuentasFiltro[0];
        }
        
        if (this.movimiento.metodopago == null) {
            this.cuentasDestinoFiltro = [];
            return;
        }
        if (this.movimiento.metodopago.tipo == 'TRANSFERENCIA' || this.movimiento.metodopago.tipo == 'DEBITO') {
            this.cuentasDestinoFiltro = this.cuentasDestino.filter(c => c.tipo == 'BANCO');
        }else if (this.movimiento.metodopago.tipo == 'CHEQUE') {
            this.cuentasDestinoFiltro = this.cuentasDestino.filter(c => c.tipo != 'BANCO');
        }else if (this.movimiento.metodopago.tipo == 'EFECTIVO') {
            this.cuentasDestinoFiltro = this.cuentasDestino.filter(c => c.tipo != 'BANCO');
        }else {
            this.cuentasDestinoFiltro = [];
        }
        this.movimiento.cuentadestino = null;
        if (this.cuentasDestinoFiltro.length >= 1) {
            this.movimiento.cuentadestino = this.cuentasDestinoFiltro[0];
        }
    }

    public changeCuentaOrigen() {
        this.cheque = null;
    }
    
    public enabledSubmit() {
        this.submitted = false;
    }
    
    public disabledSubmit() {
        this.submitted = true;
    }
}