import { Component, OnInit, Input } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {environment} from '../../../environments/environment';

import {FacturaService} from '../factura.service';
import {ProveedorService} from '../../module-proveedor/proveedor.service';
import {DistritoService} from '../../module-distrito/distrito.service';
import {ProveedorCommunicate} from '../../module-proveedor/proveedor.communicate';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Factura, ImputacionVenta} from '../models';
import {Proveedor, ProveedorImputacion} from '../../module-proveedor/models';
import {Distrito} from '../../module-distrito/models';
import {User} from '../../models/user';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';

@Component({
    selector: 'facturas_form',
    templateUrl: 'facturas_form.component.html'
})

export class FacturasVentasFormComponent extends GenericFormComponent implements OnInit {
    @Input() id: number = null;
    public actualUser: User;
    public factura: Factura = new Factura();
    
    public imputacionesContables: ImputacionVenta[] = [];
    public imputaciones: ProveedorImputacion[] = [];
    public tipos: string[] = environment.tiposFacturaVenta;
    public ivas: number[] = environment.ivas;
    public monedas: string[] = environment.monedas;
    public distritos: Distrito[] = [];
    public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Ingresos', ref: '#/ingresosventas' }];
    
    constructor(protected messageService: MessageService, private facturaService: FacturaService, private proveedorCommunicate: ProveedorCommunicate, 
        private route: ActivatedRoute, private router: Router, private globalVars: GlobalVars, private proveedorService: ProveedorService, private distritoService: DistritoService) {
        super(messageService);
    }

    ngOnInit(){
        this.actualUser = this.globalVars.actualUser;
        
        this.distritoService.getDistritos({per_page: 1000}, {sort: 'nombre'}).subscribe(
            (rta: any) => {
                this.distritos = rta.data;
            }
        );
        if (this.id) {
            this.initiaLoad(this.id);
        } else {
            this.route.params.subscribe(
                params => {
                    if (params.id) {
                        this.initiaLoad(params.id);
                    } else {
                        this.factura.fecha = new Date().toISOString().slice(0,10) + " 00:00:00";
                        this.factura.fecha_vencimiento = this.factura.fecha;
                        this.factura.fecha_contable = this.factura.fecha;
                        let distrito: Distrito = new Distrito();
                        distrito.id = this.globalVars.getActualDistrito();
                        this.factura.distrito = distrito;
                        this.factura.distrito_id = this.factura.distrito.id;
                        this.breadcrumbItems.push({title: 'Alta' , active: true });
                    }
                }
            );
        }
    }

    public initiaLoad(id) {
        this.facturaService.getFactura(id).subscribe(
            (factura: Factura) => {
                this.factura = factura;
                this.breadcrumbItems.push({title: `# ${this.factura.punto_venta} - ${this.factura.numero}` , active: true });
                
                if (this.factura.distrito_id != this.globalVars.getActualDistrito() && ! this.globalVars.actualUser.multi_distrito) {
                    this.messageService.showNotification('top', 'center', 'danger', 'Usted no tiene acceso a este ingreso');
                    this.router.navigate(['/ingresosventas']);
                } else if (this.factura.distrito_id != this.globalVars.getActualDistrito() && this.globalVars.actualUser.multi_distrito) {
                    //fix de multi_distrito. Todos los usuarios multi_distritos puede generar facturas cruzadas
                    //this.globalVars.setActualDistrito(this.factura.distrito_id);
                }
                
                this.loadImputaciones();
                this.loadImputacionesContable();
            }, error => {
                this.messageService.showNotification('top', 'center', 'danger', 'No se encontro el ingreso');
                this.router.navigate(['ingresosventas']);
            }
        );
    }

      //ACCIONES
  changeState(factura: Factura, estado: string, event){
    this.beforeSubmitList(event);
    this.facturaService.cambiarEstadoFactura(factura, estado).subscribe(
        rta => {
            this.showSuccessList(true, 'Ingreso modificado correctamente');
            factura.estado = estado;
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }
        
    public loadImputaciones() {
        if (this.factura.proveedor) { 
            this.proveedorService.getImputacionesProveedor(this.factura.proveedor.id).subscribe(
                (imputaciones: ProveedorImputacion[]) => {
                    this.imputaciones = imputaciones;
                }
            )
        } else {
            this.imputaciones = []
        }
    }
    
    public loadImputacionesContable() {
        if (this.factura.proveedor) { 
            // this.proveedorService.getImputacionesContablesProveedor(this.factura.proveedor.id).subscribe(
            //     (imputaciones: ImputacionContable[]) => {
            //         this.imputacionesContables = imputaciones;
            //         if (this.imputacionesContables.length == 0) {
            //             this.proveedorService.getImputacionesContablesProveedor(this.factura.proveedor.id).subscribe(
            //                 (imputaciones: ImputacionContable[]) => {
            //                     this.imputacionesContables = imputaciones;
            //                 }
            //             )
            //         }
            //     }
            // )
            this.facturaService.getImputacionesVentas({page: 1, per_page: 1000}, {sort: 'nombre'}).subscribe(
                rta => {
                    this.imputacionesContables = rta.data;
                }
            );
        } else {
            this.imputacionesContables = []
        }
    }
        
    public save(event){
        this.beforeSubmit(event);
        if(! this.factura.id){
            this.facturaService.createFactura(this.factura).subscribe(
                (factura: Factura) => {
                    this.showSuccess(true, 'Ingreso creada correctamente');;
                    this.router.navigate(['/ingresosventas', factura.id]);   
                },err => {
                    this.showError(err, true);
                });
        } else {
            this.facturaService.updateFactura(this.factura).subscribe(
                (factura: Factura) => {
                    this.showSuccess(true, 'Ingreso modificada correctamente');
                },err => {
                    this.showError(err, true);
                });
        }
    }

    public detalleProveedor(proveedor: Proveedor) {
        this.proveedorCommunicate.proveedorModalPopUp(proveedor.id);
    }
    
    //
    //UTILS
    public updateFecha(fecha) {
        this.factura.fecha = fecha;
        this.factura.fecha_vencimiento = this.factura.fecha;
        this.factura.fecha_contable = this.factura.fecha;
    }

    public customCompareImputacionVenta(i1: ImputacionVenta, i2: ImputacionVenta) {
        if(i1 && i2){
            return i1.id == i2.id;
        }else{
            return i1 == i2;
        }
    }
    
    public customCompareImputacion(i1: ProveedorImputacion, i2: ProveedorImputacion) {
        if(i1 && i2){
            return i1.id == i2.id;
        }else{
            return i1 == i2;
        }
    }

    public onSelectProvider() {
        this.loadImputaciones();
        this.loadImputacionesContable();
        this.factura.tipo = null;
        this.tipos = this.factura.proveedor.tipoFacturasDisponibles(environment.tiposFactura);
        if (this.tipos.length == 1) {
            this.factura.tipo = this.tipos[0];
        }
    }
}
