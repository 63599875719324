<div class="container-fluid">
    <div *ngIf="proveedor" class="row">
        <proveedores_form [proveedor]="proveedor" (actualize)="actualizeForm($event)" (cancel)="cancelForm($event)"></proveedores_form>
    </div>
    
    <div class="row">
        <div class="card">
            <div class="card-content">
                <breadcrumb [items]="breadcrumbItems"></breadcrumb>
                         
                <filters-header-section (onFilter)="filter(1)" [showFilterButton]="false">
                    <div searchElastic>
                        <div class="col-sm-12">
                            <div class="col-sm-12 col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4">
                                <label for="search_elastic">Buscador:</label>
                                <div class="input-group">
                                    <input #search_elastic class="form-control" type="text" placeholder="Nombre / CUIT / Email" name="search_elastic" [(ngModel)]="filtro.search_elastic" (keydown.enter)="filter(1)" autofocus>
                                    <span class="input-group-addon cursor-pointer" (click)="filter(1)"><i class="cmp-i busqueda i-sm"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>                                 
                    <!--                               
                        <div class="col-md-4">
                                <label for="distrito_id">Distrito:</label>
                            <select class="form-control" data-style="btn btn-wd btn-block" name="distrito_id"  [(ngModel)]="filtro.distrito_id_eq" (change)="filter(1)">
                                <option [ngValue]="null">Todos</option>
                                <option *ngFor="let distrito of distritos" [ngValue]="distrito.id">{{distrito.nombre}}</option>
                            </select>
                        </div>
                    -->  
                </filters-header-section> 
            
                

                <div class="row mt-2">
                    <div class="col-sm-10">
                        <button class="btn btn-simple btn-icon btn-add" *ngIf="! proveedor" (click)="newProveedor()" title="Agregar"><i class="cmp-i agregar"></i> Agregar</button>
                    </div>  
                    <div class="col-sm-2">
                        <sort-select-structured [options]="sortOptions" [(sortModel)]="filtro.sort" (onSelect)="filter(1)"></sort-select-structured>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-12 table-responsive">
                        <table class="table table-hover">
                            <thead class="text-primary">
                                <tr>
                                    <th><b>Nombre</b></th>
                                    <th><b>CUIT</b></th>
                                    <th><b>Condición IVA</b></th>
                                    <th><b>Email</b></th>
                                    <!--<th><b>Distrito</b></th>-->
                                    <th><b>Acciones</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let proveedor of proveedores'>
                                    <td><a [routerLink]="['/proveedores', proveedor.id]" rel="tooltip" title="Editar Proveedor">{{proveedor.nombre}}</a></td>
                                    <td>{{proveedor.cuit | cuitformat}}</td>
                                    <td>{{proveedor.condicion_iva | translate: 'CONIVA'}}</td>
                                    <td>{{proveedor.email}}</td>
                                    <!--<td>{{proveedor.distrito.nombre}}</td>-->
                                    <td class="td-actions text-right">
                                        <a [routerLink]="['/proveedores', proveedor.id]" rel="tooltip" title="Editar Proveedor" class="btn btn-primary btn-simple btn-xs btn-cmp-i">
                                            <i class="cmp-i editar i-sm"></i>
                                        </a>
                                        <button type="button" rel="tooltip" title="Eliminar Proveedor" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="proveedor" confirm-message="¿Está seguro que desea eliminar el Proveedor?">
                                            <i class="cmp-i eliminar-borrar i-sm"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="proveedores != null && proveedores.length == 0" class="text-center">
                                    <td colspan="6">No se encontraron proveedores</td>
                                </tr>
                            </tbody>
                        </table>                        
                        <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)" *ngIf="proveedores != null && proveedores.length > 0"></pager>
                    </div>
                </div>
            </div>
        </div>          
    </div>
</div>