import { Component, Input } from '@angular/core';
import { BreadcrumbItems } from './breadcrum-items.model';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  @Input() items: BreadcrumbItems[] = [];

  constructor() { }

}
