<div #viaticosModal class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" *ngIf="show">
            <div class="modal-header">
                <h5 class="modal-title col-sm-11 mt-1">Viáticos</h5>
                <button class="btn btn-simple btn-info btn-icon like col-sm-1" title="Cerrar" data-dismiss="modal" aria-label="Close" (click)="hide()"><i class="ti-close"></i></button>
            </div>
            <div class="modal-body">
                <div class="row">                        
                    <form class="form-inline" (submit)="filter(1, $event)" #searchViaticosForm="ngForm">
                        <div class="filter-section">
                            <div class="col-md-12 search-filter">
                                <div class="col-md-4">
                                    <div class="input-group filter-input-group">
                                        <input class="form-control" type="text" placeholder="Búsqueda de viaticos" name="search" [(ngModel)]="filtro.numero_matches" (input)="onChangeSearch($event)">
                                        <span class="input-group-addon" (click)="filter(1)"><i class="cmp-i busqueda i-sm"></i></span>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="toolbar">
                                        <button type="button" class="btn btn-primary pull-right" (click)="seleccionar()" *ngIf="viaticos != null && viaticos.length > 0">Seleccionar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                
                <div class="row">                    
                    <div class="col-md-12 table-responsive">
                        <table class="table table-hover">                            
                            <thead class="text-primary">
                                <tr>
                                    <td><input type="checkbox" [checked]="selectAll" (change)="changeAllSelects()" *ngIf="viaticos != null && viaticos.length > 0"></td>
                                    <th><b>Número</b></th>
                                    <th><b>Emisión</b></th>
                                    <th><b>Persona</b></th>
                                    <th><b>Monto / A Pagar / Facturado</b></th>
                                    <th><b>Ordenes de pago por</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let viatico of viaticos'>
                                    <td><input type="checkbox" [checked]="viatico.selectedInTable" (change)="viatico.selectedInTable= !viatico.selectedInTable"></td>
                                    <td>{{viatico.numero}}</td>
                                    <td>{{viatico.emision  | date: 'dd/MM/yyyy'}}</td>
                                    <td>{{viatico.proveedor.nombre}} - {{viatico.proveedor.cuit | cuitformat}}</td>
                                    <td>$ {{viatico.monto | number: '1.2-2'}} / $ {{viatico.monto_neto | number: '1.2-2'}} / $ {{viatico.monto_real | number: '1.2-2'}}</td>
                                    <td>$ {{viatico.pagado | number: '1.2-2'}}</td>
                                </tr>
                                <tr *ngIf="viaticos != null && viaticos.length == 0" class="text-center">
                                    <td colspan="6">No se encontraron viáticos</td>
                                </tr>
                            </tbody>
                        </table>                        
                        <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)" *ngIf="viaticos != null && viaticos.length > 0"></pager>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>