
import {first} from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild, NgZone } from '@angular/core';

import {ProveedorService} from '../proveedor.service';
import {FacturaService} from '../../module-factura/factura.service';
import { MessageService } from '../../services/message.service';

import {Proveedor} from '../models';
import {ImputacionContable} from '../../module-factura/models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

declare var $: any;

@Component({
  selector: 'imputaciones_contable_proveedor_form',
  templateUrl: 'imputaciones_contable_proveedor_form.html'
})
export class ImputacionesContableProveedorFormComponent extends GenericFormComponent implements OnInit, AfterViewInit{
    @ViewChild('firstElementImputacionContable', { static: true }) firstElement: ElementRef;
    
    @Input() proveedor: Proveedor;
    @Input() imputacion: ImputacionContable = null;
    
    public imputaciones: ImputacionContable[] = [];
    
    @Output() actualize: EventEmitter<ImputacionContable> = new EventEmitter<ImputacionContable>();
    @Output() cancel: EventEmitter<string> = new EventEmitter<string>();
    
    constructor(protected messageService: MessageService, private proveedorService: ProveedorService, private facturaService: FacturaService, private ngZone: NgZone) {
        super(messageService);
    }

    ngOnInit(): void {
        this.facturaService.getImputacionesContables({per_page: 1000}, {sort: 'nombre'}).subscribe(
            rta => {
                this.imputaciones = rta.data;
                this.ngZone.onStable.pipe(first()).subscribe(() => {
                    $(this.firstElement.nativeElement).selectpicker(); 
                });
            }
        );
    }
    ngAfterViewInit(): void {
        this.firstElement.nativeElement.focus();
    }
    
    public save(event){
        if (! this.imputacion) {
            return;
        }
        this.beforeSubmit(event);
        this.proveedorService.createImputacionContableProveedor(this.proveedor, this.imputacion).subscribe(
            (prov: Proveedor) => {
                this.showSuccess(true, 'Imputación asignada correctamente');
                this.actualize.emit(this.imputacion);             
            },err => {
                this.showError(err, true);
            });
    }
    
    public cancelForm($event?){
        this.cancel.emit("Cancelacion");
    }
    
    //
    //UTILS
    public customCompareImputacion(i1: ImputacionContable, i2: ImputacionContable) {
        if(i1 && i2){
            return i1.id == i2.id;
        }else{
            return i1 == i2;
        }
    }
}