import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild } from '@angular/core';

import {ProveedorService} from '../proveedor.service';
import { MessageService } from '../../services/message.service';

import {Imputacion} from '../models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

@Component({
  selector: 'imputaciones_form',
  templateUrl: 'imputaciones_form.html'
})
export class ImputacionesFormComponent extends GenericFormComponent implements OnInit, AfterViewInit{
    @ViewChild('firstElementImputacion', { static: true }) firstElement: ElementRef;
    
    @Input() imputacion: Imputacion= new Imputacion();
    
    @Output() actualize: EventEmitter<Imputacion> = new EventEmitter<Imputacion>();
    @Output() cancel: EventEmitter<string> = new EventEmitter<string>();
    
    constructor(protected messageService: MessageService, private proveedorService: ProveedorService) {
        super(messageService);
    }

    ngOnInit(): void {
    }
    ngAfterViewInit(): void {
        this.firstElement.nativeElement.focus();
    }
    
    public save(event){
        this.beforeSubmit(event);
        if(!this.imputacion.id){
            this.proveedorService.createImputacion(this.imputacion).subscribe(
                (imputacion: Imputacion) => {
                    this.showSuccess(true, 'Categoría creada correctamente');
                    this.imputacion = imputacion;
                    this.actualize.emit(this.imputacion);             
                },err => {
                    this.showError(err, true);
                });
        }else{
            this.proveedorService.updateImputacion(this.imputacion).subscribe(
                (imputacion: Imputacion) => {
                    this.showSuccess(true, 'Categoría modificada correctamente');  
                    this.imputacion = imputacion;
                    this.actualize.emit(this.imputacion);  
                },err => {
                    this.showError(err, true);
                });
        }
    }
    
    public cancelForm($event?){
        this.cancel.emit("Cancelacion");
    }
}