import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from './translate.service'; // our translate service

@Pipe({
    name: 'translate',
})
export class TranslatePipe implements PipeTransform {

    constructor(private _translate: TranslateService) { }

    transform(value: string, args?: string): any {
        if (!value) return;
        let prefix = args ? args : null;
        return this._translate.instant(value, prefix);
    }
}

@Pipe({
    name: 'translateapi',
})
export class TranslateApiPipe implements PipeTransform {

    constructor(private _translate: TranslateService) { }

    transform(value: string, args?: string): any {
        if (!value) return;
        let prefix = args ? args : null;
        return this._translate.instantApi(value, prefix);
    }
}