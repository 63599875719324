import { Component, OnInit, Input } from '@angular/core';

import {ProveedorService} from '../proveedor.service';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Proveedor} from '../models';
import {ImputacionContable} from '../../module-factura/models';
 
@Component({
  selector: 'imputaciones_contable_proveedor',
  templateUrl: './imputaciones_contable_proveedor.component.html'
})
export class ImputacionesContableProveedorComponent extends GenericFormComponent implements OnInit {
  @Input() proveedor: Proveedor;
  public imputaciones: ImputacionContable[];
  //FORM
  public formImputacion: boolean = false;
    
  constructor(protected messageService: MessageService, private proveedorService: ProveedorService) {
    super(messageService);
  }

  ngOnInit() {
    this.filter();
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    this.cursorWait();
    this.proveedorService.getImputacionesContablesProveedor(this.proveedor.id).subscribe(
        (imputaciones: ImputacionContable[]) => {
            this.cursorDefault();
            this.imputaciones = imputaciones;
        },
        error => {
            this.cursorDefault();
        }
    );
  }
  
  //ACCIONES    
  onNotifyDelete(provImpu: ImputacionContable):void {
    this.deleteImputacion(provImpu);
  }
    
  public deleteImputacion(provImpu: ImputacionContable) {
    this.beforeSubmitList();
    this.proveedorService.deleteImputacionContableProveedor(this.proveedor.id, provImpu.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Imputacion eliminada correctamente');
            //ELIMINO DE LA LISTA      
            this.imputaciones.forEach(function(imputacionFor, index, object) {
                if(imputacionFor.id == provImpu.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }
  
    
  //FORMULARIO ALTA/EDICION
  //Para el Form
  public newImputacion(){
    this.formImputacion = true;
  }
  
  //Desde el formulario
  public actualizeForm(provImpu: ImputacionContable): void {
    let exists= false;
    this.imputaciones.forEach(function(imputacionFor, index, object) {
        if(imputacionFor.id == provImpu.id) {
            object[index]= provImpu;
            exists= true;
        }
    });
    if(!exists){
        this.imputaciones.splice(0, 0, provImpu);
    }
    this.cancelForm();
  }    
  public cancelForm(event?): void{
    this.formImputacion = false;
  }
  
}