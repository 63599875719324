<div #facturasModal class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" *ngIf="show">
            <div class="modal-header">
                <h5 class="modal-title col-sm-11 mt-1">Ingresos</h5>
                <button class="btn btn-simple btn-info btn-icon like col-sm-1" title="Cerrar" data-dismiss="modal" aria-label="Close" (click)="hide()"><i class="ti-close"></i></button>
            </div>
            <div class="modal-body">
                <div class="row">                        
                    <form class="form-inline" (submit)="filter(1, $event)" #searchFacturasForm="ngForm">
                        <div class="filter-section">
                            <div class="col-md-12 search-filter">
                                <div class="col-md-4">
                                    <div class="input-group filter-input-group">
                                        <input class="form-control" type="text" placeholder="Búsqueda de ingresos" name="search" [(ngModel)]="filtro.or_numero_matches" (input)="onChangeSearch($event)">
                                        <span class="input-group-addon" (click)="filter(1)"><i class="cmp-i busqueda i-sm"></i></span>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="toolbar">
                                        <button type="button" class="btn btn-primary pull-right" (click)="seleccionar()" *ngIf="facturas != null && facturas.length > 0">Seleccionar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                
                <div class="row">                    
                    <div class="col-md-12 table-responsive">
                        <table class="table table-hover">
                            <thead class="text-primary">
                                <tr>
                                    <td><input type="checkbox" [checked]="selectAll" (change)="changeAllSelects()" *ngIf="facturas != null && facturas.length > 0"></td>
                                    <th><b>Número</b></th>
                                    <th><b>Fecha</b></th>
                                    <th><b>Imputación Venta</b></th>
                                    <th><b>Descripción</b></th>
                                    <th><b>Monto / IVA</b></th>
                                    <th><b>Ordenes de Venta por</b></th>
                                    <th><b>Proveedor</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let factura of facturas'>
                                    <td><input type="checkbox" [checked]="factura.selectedInTable" (change)="factura.selectedInTable= !factura.selectedInTable"></td>
                                    <td>{{factura.punto_venta}} - {{factura.numero}}</td>
                                    <td>{{factura.fecha | date: 'dd/MM/yyyy'}}</td>
                                    <td>
                                        <span *ngIf="factura.imputacion_venta">{{factura.imputacion_venta.nombre}}</span>
                                        <span *ngIf="!factura.imputacion_venta">-</span>
                                    </td>
                                    <td>{{factura.descripcion}}</td>
                                    <td>$ {{factura.monto | number: '1.2-2'}} / {{factura.iva | number: '1.2-2'}}</td>
                                    <td>$ {{factura.pagado | number: '1.2-2'}}</td>
                                    <td>{{factura.moneda | translate: 'MONEDA'}}</td>
                                    <td>
                                        <span *ngIf="factura.proveedor">{{factura.proveedor.nombre}}</span>
                                        <span *ngIf="! factura.proveedor">-</span>
                                    </td>
                                </tr>
                                <tr *ngIf="facturas != null && facturas.length == 0" class="text-center">
                                    <td colspan="8">No se encontraron ingresos</td>
                                </tr>
                            </tbody>
                        </table>                        
                        <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)" *ngIf="facturas != null && facturas.length > 0"></pager>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>