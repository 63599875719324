import { Component, OnInit } from '@angular/core';

declare var $:any;

declare interface DataTable {
    headerRow: string[];
    footerRow: string[];
    dataRows: string[][];
}

@Component({
    selector: 'data-table-cmp',
    templateUrl: 'viatico.component.html'
})

export class ViaticoComponent implements OnInit{
    public dataTable: DataTable;
    ngOnInit(){
        this.dataTable = {
            headerRow: [ 'Tipo', 'Emisión', 'Nombre', 'Id', 'Proveedor', 'Número', 'Total (Mon. Original)', 'Autorizado', 'Espera', 'Trans', 'Acciones' ],
            footerRow: [ 'Tipo', 'Emisión', 'Nombre', 'Id', 'Proveedor', 'Número', 'Total (Mon. Original)', 'Autorizado', 'Espera', 'Trans', 'Acciones' ],
            dataRows: [
                ['FMC', '03/07/18', 'Distrito IV', '172', 'ZAMBRANA EZEQUIEL ROBERTO', '000100000012', '24480.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito IV', '42', 'TORRES DANIEL ANGEL', '000200000043', '48000.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito IV', '2210', 'ALVAREZ HECTOR HUGO', '000100000046', '12480.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Colegio', '56', 'BRITTOS ELIZABETH', '000100000087', '1550.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito IV', '42', 'TORRES DANIEL ANGEL', '000200000043', '48000.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito IV', '2210', 'ALVAREZ HECTOR HUGO', '000100000046', '12480.00', 'monto-total-error', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Colegio', '56', 'BRITTOS ELIZABETH', '000100000087', '1550.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito II', '172', 'ZAMBRANA EZEQUIEL ROBERTO', '000100000012', '24480.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito IV', '2210', 'ALVAREZ HECTOR HUGO', '000100000046', '12480.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Colegio', '56', 'BRITTOS ELIZABETH', '000100000087', '1550.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito II', '172', 'ZAMBRANA EZEQUIEL ROBERTO', '000100000012', '24480.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Colegio', '56', 'BRITTOS ELIZABETH', '000100000087', '1550.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito II', '172', 'ZAMBRANA EZEQUIEL ROBERTO', '000100000012', '24480.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito IV', '2210', 'ALVAREZ HECTOR HUGO', '000100000046', '12480.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Colegio', '56', 'BRITTOS ELIZABETH', '000100000087', '1550.00', 'monto-total', 'x', 'X' ,'' ,''],

                ['FMC', '03/07/18', 'Distrito IV', '172', 'ZAMBRANA EZEQUIEL ROBERTO', '000100000012', '24480.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito IV', '42', 'TORRES DANIEL ANGEL', '000200000043', '48000.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito IV', '2210', 'ALVAREZ HECTOR HUGO', '000100000046', '12480.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Colegio', '56', 'BRITTOS ELIZABETH', '000100000087', '1550.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito IV', '42', 'TORRES DANIEL ANGEL', '000200000043', '48000.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito IV', '2210', 'ALVAREZ HECTOR HUGO', '000100000046', '12480.00', 'monto-total-error', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Colegio', '56', 'BRITTOS ELIZABETH', '000100000087', '1550.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito II', '172', 'ZAMBRANA EZEQUIEL ROBERTO', '000100000012', '24480.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito IV', '2210', 'ALVAREZ HECTOR HUGO', '000100000046', '12480.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Colegio', '56', 'BRITTOS ELIZABETH', '000100000087', '1550.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito II', '172', 'ZAMBRANA EZEQUIEL ROBERTO', '000100000012', '24480.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Colegio', '56', 'BRITTOS ELIZABETH', '000100000087', '1550.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito II', '172', 'ZAMBRANA EZEQUIEL ROBERTO', '000100000012', '24480.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito IV', '2210', 'ALVAREZ HECTOR HUGO', '000100000046', '12480.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Colegio', '56', 'BRITTOS ELIZABETH', '000100000087', '1550.00', 'monto-total', 'x', 'X' ,'' ,''],

                ['FMC', '03/07/18', 'Distrito IV', '172', 'ZAMBRANA EZEQUIEL ROBERTO', '000100000012', '24480.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito IV', '42', 'TORRES DANIEL ANGEL', '000200000043', '48000.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito IV', '2210', 'ALVAREZ HECTOR HUGO', '000100000046', '12480.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Colegio', '56', 'BRITTOS ELIZABETH', '000100000087', '1550.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito IV', '42', 'TORRES DANIEL ANGEL', '000200000043', '48000.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito IV', '2210', 'ALVAREZ HECTOR HUGO', '000100000046', '12480.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Colegio', '56', 'BRITTOS ELIZABETH', '000100000087', '1550.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito II', '172', 'ZAMBRANA EZEQUIEL ROBERTO', '000100000012', '24480.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito IV', '2210', 'ALVAREZ HECTOR HUGO', '000100000046', '12480.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Colegio', '56', 'BRITTOS ELIZABETH', '000100000087', '1550.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito II', '172', 'ZAMBRANA EZEQUIEL ROBERTO', '000100000012', '24480.00', 'monto-total-error', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Colegio', '56', 'BRITTOS ELIZABETH', '000100000087', '1550.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito II', '172', 'ZAMBRANA EZEQUIEL ROBERTO', '000100000012', '24480.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Distrito IV', '2210', 'ALVAREZ HECTOR HUGO', '000100000046', '12480.00', 'monto-total', 'x', 'X' ,'' ,''],
                ['FMC', '03/07/18', 'Colegio', '56', 'BRITTOS ELIZABETH', '000100000087', '1550.00', 'monto-total', 'x', 'X' ,'' ,''],
            ]
         };
    }

    ngAfterViewInit(){

        $('#datatables').DataTable({
            "pagingType": "full_numbers",
            "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
            "searching": false,
            responsive: true,
            language: {
                paginate: {
                    first:      "Primera",
                    last:       "Última",
                    next:       "Siguiente",
                    previous:   "Anterior"
                },
                lengthMenu:     "Mostrar _MENU_ entradas",
                search: "_INPUT_",
                searchPlaceholder: "Buscar",
            }
        });

        var table = $('#datatables').DataTable();
        /* // Edit record
        table.on( 'click', '.edit', function () {
            var $tr = $(this).closest('tr');

            var data = table.row($tr).data();
            alert( 'You press on Row: ' + data[0] + ' ' + data[1] + ' ' + data[2] + '\'s row.' );
        });

        // Delete a record
        table.on( 'click', '.remove', function (e) {
            var $tr = $(this).closest('tr');
            table.row($tr).remove().draw();
            e.preventDefault();
        });

        //Like record
        table.on( 'click', '.like', function () {
            alert('You clicked on Like button');
        });*/
    }
}
