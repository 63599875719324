<div class="container-fluid">    
    <div class="row">
        <div class="card">
            <div class="card-content">
                <breadcrumb [items]="breadcrumbItems"></breadcrumb>

                <div *ngIf="!reporteSeleccionado" class="row">
                    <report-menu-item *ngFor="let reporte of reportes" [report]="reporte" class="col-sm-offset-1 col-sm-10"></report-menu-item>
                </div>
                
                <reporte_ordenes *ngIf="reporteSeleccionado == 'ordenes' || reporteSeleccionado == 'retenciones-ganancia'" [type]="reporteSeleccionado"></reporte_ordenes>
    
                <reporte_caja_chica *ngIf="reporteSeleccionado == 'caja-chica'"></reporte_caja_chica>
    
                <reporte_cheques *ngIf="reporteSeleccionado == 'cheques'"></reporte_cheques>

                <div class="mt-2"></div>

            </div>
        </div>         
    </div>
</div>