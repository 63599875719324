<nav #navbar class="navbar navbar-default" id="super-nav-bar">
    <div class="container-fluid row">
        <div class="navbar-minimize col-sm-2" [hidden]="!isMobileMenu()">
            <button id="minimizeSidebar" class="btn btn-default">
                <i class="cmp-i menu-opt-2" title="Ocultar menu" *ngIf="!misc.sidebar_mini_active"></i>
                <i class="cmp-i menu-opt-2" style="transform: rotate(180deg);" title="Mostrar menu" *ngIf="misc.sidebar_mini_active"></i>
            </button>
        </div>

        <div [ngClass]="isMobileMenu() ? 'col-md-6 col-lg-7' : 'col-sm-11'" *ngIf="user">
            <search-bar></search-bar>
        </div>

        <div class="col-sm-1" [hidden]="isMobileMenu()">
            <button type="button" class="navbar-toggle" (click)="sidebarToggle()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar bar1"></span>
                <span class="icon-bar bar2"></span>
                <span class="icon-bar bar3"></span>
            </button>
        </div>

        <div class="collapse navbar-collapse" *ngIf="user && isMobileMenu()">
            <ul class="nav navbar-nav navbar-right"> 
                <li>
                    <a href="#" class="btn-rotate" (click)="cleanAllCache()" title="Limpiar Cache">
                        <i class="cmp-i borrar-cache"></i>
                        <p class="hidden-lg hidden-md">Borrar Cache</p>
                    </a>
                </li>

                <li class="dropdown">
                    <a href="#" class="dropdown-toggle btn-magnify" data-toggle="dropdown" aria-controls="mainDropdown">
                        <p>{{user.username}}</p>
                        <i class="cmp-i desplegar" style="margin-left: 10px;" *ngIf="user.multi_distrito"></i>
                    </a>
                    <form class="dropdown-menu">
                        <ul class="dropdown-menu" #mainDropdown>
                            <li>
                                <a [routerLink]="['/perfil']" class="btn-rotate cursor-pointer" title="Perfil">
                                    <i class="cmp-i usuario"></i>
                                    <b style="margin-left: 10px;">Mi Perfil</b>
                                </a>
                            </li>

                            <li *ngIf="distrito">
                                <a class="dropdown-toggle btn-magnify" [ngClass]="{'disabled': ! user.multi_distrito}" (click)="showSubMenu.distritos = !showSubMenu.distritos; showSubMenu.paneles = false;">
                                    <i [ngClass]="showSubMenu.distritos ? 'ti-angle-down' : 'ti-angle-left'" *ngIf="user.multi_distrito"></i>
                                    <b style="margin-left: 10px;">Distrito: {{distrito.nombre}}</b>
                                </a>
                                <ul class="dropdown-menu" *ngIf="user.multi_distrito && showSubMenu.distritos" id="distritoCollapse" style="display: contents;">
                                    <li *ngFor="let distri of distritos">
                                        <a (click)="selectDistrito(distri)" [ngClass]="{'selected-item': distrito.id == distri.id}">
                                            {{distri.nombre}}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            
                            <li>
                                <a class="dropdown-toggle btn-magnify" (click)="showSubMenu.paneles = !showSubMenu.paneles; showSubMenu.distritos = false;">
                                    <i [ngClass]="showSubMenu.paneles ? 'ti-angle-down' : 'ti-angle-left'" *ngIf="paneles?.length > 1"></i>
                                    <b style="margin-left: 10px;">Panel de {{getActualPanel() | translate: 'PANEL'}}</b>
                                </a>
                                <ul class="dropdown-menu" *ngIf="paneles?.length > 1 && showSubMenu.paneles" id="panelesDropdown" style="display: contents;">
                                    <li *ngFor="let panel of paneles">
                                        <a (click)="selectPanel(panel)"  [ngClass]="{'selected-item': getActualPanel() == panel}">
                                            {{panel | translate: 'PANELIN'}}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            
                            <li>
                                <a (click)="gotoDedaloFreshdesk()" class="btn-rotate cursor-pointer" title="Soporte">
                                    <i class="cmp-i soporte"></i>
                                    <b style="margin-left: 10px;">Soporte</b>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="btn-rotate cursor-pointer" (click)="closeSession()" title="Cerrar Sesion">
                                    <i class="cmp-i cerrar-sesion"></i>
                                    <b style="margin-left: 10px;">Cerrar Sesion</b>
                                </a>
                            </li>
                        </ul>
                    </form>
                </li>
            </ul>
        </div>
    </div>
</nav>
