import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
 
//import 'rxjs/add/operator/toPromise';
import { Observable ,  Subject } from 'rxjs';
// Import RxJs required methods




import {environment} from '../../environments/environment';

import { MessageService } from '../services/message.service';
import { GlobalVars } from '../services/globalVars';
import {UserService} from '../services/user.service';

/**
 * Esta clase se encarga de cargar toda la informacion del usuario si es que se encuentra conectado.
 * No le importa si hay usuario conectado o no.
 */
@Injectable()
export class LoadUser  {
 
    constructor(private globalVars: GlobalVars, private userService: UserService) { }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return new Observable<boolean>(observer => {
            if(this.globalVars.actualUser != null){
                observer.next(true);
            }else{           
                return this.userService.actualUser().subscribe(
                rta => {
                    observer.next(true);
                },err => {
                    observer.next(true);
                });
            }        
        });
    }
}

/**
 * Controla que el usuario este conectado, en caso contrario lo devuelve al /login.
 */
@Injectable()
export class AuthGuard  {
 
    constructor(private router: Router, private globalVars: GlobalVars, private userService: UserService) { }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return new Observable<boolean>(observer => {
            if(this.globalVars.actualUser != null){                
                observer.next(true);
            }else{
                return this.userService.actualUser().subscribe(
                rta => {
                    observer.next(true);
                },err => {
                    // not logged in so redirect to login page with the return url and return false
                    //this.router.navigate(['/home'], { queryParams: { returnUrl: state.url }});
                    this.router.navigate(['/login']);
                    observer.next(false);
                });
            }        
        });
    }
}
/**
 * Controla que el usuario este conectado, en caso contrario lo devuelve al /login.
 */
@Injectable()
export class AuthGuardAdmin  {
 
    constructor(private router: Router, private globalVars: GlobalVars, private userService: UserService) { }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return new Observable<boolean>(observer => {
            if(this.globalVars.actualUser != null){                
//                observer.next(this.globalVars.actualUser.is_admin);
                observer.next(true);
            }else{
                return this.userService.actualUser().subscribe(
                rta => {
                    observer.next(true);
//                    observer.next(this.globalVars.actualUser.is_admin);
                },err => {
                    // not logged in so redirect to login page with the return url and return false
                    //this.router.navigate(['/home'], { queryParams: { returnUrl: state.url }});
                    this.router.navigate(['/login']);
                    observer.next(false);
                });
            }        
        });
    }
}
/**
 * Controla que el usuario este conectado y sea administrador
 */
@Injectable()
export class AuthGuardByRole  {
 
    constructor(protected messageService: MessageService, private router: Router, private globalVars: GlobalVars, private userService: UserService) { }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return new Observable<boolean>(observer => {            
            let roles: string[]= route.routeConfig.data['roles'];
            if(route.routeConfig.data['esVenta'] == 'si'){
                // 11 es Instituto superior
                if(this.globalVars.getActualDistrito() == 11){
                    this.messageService.showNotification('top', 'center', 'danger', 'No se puede operar el panel de Ventas como Distrito Instituto');
                    this.globalVars.setActualPanel('operations');
                }
            }
            if(this.globalVars.actualUser != null){
                if (! this.globalVars.actualUser.hasAtLeastOneRolByName(roles)) {
                    this.router.navigate(['/']);
                }
                observer.next(this.globalVars.actualUser.hasAtLeastOneRolByName(roles));
            }else{
                return this.userService.actualUser().subscribe(
                rta => {
                    if (! this.globalVars.actualUser.hasAtLeastOneRolByName(roles)) {
                        this.router.navigate(['/']);
                    }
                    observer.next(this.globalVars.actualUser.hasAtLeastOneRolByName(roles));
                },err => {
                    // not logged in so redirect to login page with the return url and return false
                    //this.router.navigate(['/home'], { queryParams: { returnUrl: state.url }});
                    this.router.navigate(['/login']);
                    observer.next(false);
                });
            }        
        });
    }
}

/**
 * Controla que el usuario no este conectado, en caso contrario lo manda a una pagina por defecto segun profile
 */
@Injectable()
export class NoLogin  {
 
    constructor(private router: Router, private globalVars: GlobalVars, private userService: UserService) { }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return new Observable<boolean>(observer => {
            if(this.globalVars.actualUser != null){
                this.router.navigate([environment.defaultPage]);
                observer.next(false);
            }else{
                return this.userService.actualUser().subscribe(
                rta => {
                    this.router.navigate([environment.defaultPage]);
                    observer.next(false);
                },err => {
                    observer.next(true);
                });
            }        
        });
    }
}