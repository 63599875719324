<div *ngIf="cheque" class="row">
    <cheques_form [cheque]="cheque" (actualize)="actualizeForm($event)" (cancel)="cancelForm($event)"></cheques_form>
</div>

<div class="row">
    <div class="card">
        <div class="card-content">

            <h4 class="card-title col-md-10">Cheques</h4>
            <div class="col-md-2 mt-1">
                <div class="pull-right"> 
                    <a href="#" data-toggle="dropdown" class="text-left">
                        <p><i class="cmp-i exportar i-md"></i>  Exportar<i class="ml-1 ti-angle-down"></i></p>
                    </a>
                    <ul class="dropdown-menu">
                        <li>
                            <a (click)="pdfCheques(0)" class="cursor-pointer">Cheques (.pdf) <i class="cmp-i descargar-pdf"></i></a>
                            <a (click)="pdfCheques(1)" class="cursor-pointer">Cheques (.xls) <i class="cmp-i descargar-excel"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row"></div>
            <hr>
            
            <div class="row mt-2">
                <div class="col-sm-12">
                    <button class="btn btn-simple btn-icon btn-add" *ngIf="! cheque && ! chequera.finalizada" (click)="newCheque()" title="Agregar"><i class="cmp-i agregar"></i> Agregar</button>
                </div> 
            </div>

            <div class="row">
                <div class="col-md-12 table-responsive">
                    <table class="table table-hover">
                        <thead class="text-primary">
                            <tr>
                                <th><b>Número</b></th>
                                <th><b>Emisión</b></th>
                                <th><b>Acreditación</b></th>
                                <th><b>Lugar</b></th>
                                <th><b>Nombre</b></th>
                                <th><b>Monto</b></th>
                                <th><b>No a la Orden</b></th>
                                <th><b>Diferido</b></th>
                                <th><b>Usado</b></th>
                                <th><b>Orden Pago</b></th>
                                <th><b>Estado</b></th>
                                <th><b>Acciones</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor='let cheque of cheques'>
                                <td><a (click)="updateCheque(cheque)" rel="tooltip" title="Editar Chequera">{{cheque.numero}}</a></td>
                                <td>{{cheque.emision | date: 'dd/MM/yyyy'}}</td>
                                <td>
                                    <span *ngIf="cheque.acreditacion && cheque.acreditacion != '0000-00-00 00:00:00'">{{cheque.acreditacion | date: 'dd/MM/yyyy'}}</span>
                                    <span *ngIf="! cheque.acreditacion || cheque.acreditacion == '0000-00-00 00:00:00'">-</span>
                                </td>
                                <td>{{cheque.lugar}}</td>
                                <td><a *ngIf="cheque.nombre" [routerLink]="['/proveedores', cheque.proveedor_id]" title="Detalle Proveedor" rel="tooltip" class="link-no-highlight">{{cheque.nombre}}</a></td>
                                <td>{{cheque.monto | number: '1.2-2'}}</td>
                                <td>
                                    <span *ngIf="cheque.no_a_la_orden">SI</span>
                                    <span *ngIf="!cheque.no_a_la_orden">NO</span>
                                </td>
                                <td>
                                    <span *ngIf="cheque.diferido">SI</span>
                                    <span *ngIf="!cheque.diferido">NO</span>
                                </td>
                                <td>
                                    <span *ngIf="cheque.usado">SI</span>
                                    <span *ngIf="!cheque.usado">NO</span>
                                </td>
                                <td>
                                    <a *ngIf="cheque.movimiento?.ordenpago" [routerLink]="['/ordenespago', cheque.movimiento.ordenpago.id]" title="Detalle Orden de pago"
                                        rel="tooltip" class="link-no-highlight">{{cheque.movimiento.ordenpago.punto_venta}}-{{cheque.movimiento.ordenpago.numero}}</a>
                                </td>
                                <td>{{cheque.estado | translate: 'ESTADO_CHEQUE'}}</td>
                                
                                <td class="td-actions text-right">
                                    <button type="button" rel="tooltip" title="Editar Cheque" class="btn btn-primary btn-simple btn-xs btn-cmp-i" (click)="updateCheque(cheque)">
                                        <i class="cmp-i editar i-sm"></i>
                                    </button>
                                    <button *ngIf="! cheque.usado" type="button" rel="tooltip" title="Eliminar Cheque" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="cheque" confirm-message="¿Está seguro que desea eliminar la Cheque?">
                                        <i class="cmp-i eliminar-borrar i-sm"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr *ngIf="cheques != null && cheques.length == 0" class="text-center">
                                <td colspan="11">No se encontraron cheques</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>