import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';

//import 'rxjs/add/operator/toPromise';
import { Observable ,  Subject } from 'rxjs';
// Import RxJs required methods
import { map, catchError } from 'rxjs/operators';

import { GlobalVars } from '../services/globalVars';
import { Cache } from '../services/cache';

import {Imputacion, Proveedor, ProveedorImputacion} from './models';
import {ImputacionContable} from '../module-factura/models';
import {Cuenta} from '../module-cuenta/models';

@Injectable()
export class ProveedorService{
    private proveedoresUrl = 'proveedores';
    private imputacionesUrl = 'imputaciones';

    constructor(private http: HttpClient, private globalVars: GlobalVars, private cache: Cache) {        
    }
    
    //
    //PROVEEDORES
    getProveedores(params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.proveedoresUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrlV2(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new Proveedor(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    getProveedor(id): Observable<Proveedor> {
        const url= `${this.globalVars.apiHost}${this.proveedoresUrl}/${id}`;
        return this.http.get<Proveedor>(url, this.globalVars.getOptionsRequest()).pipe(
            map((proveedor: Proveedor) => {
                return new Proveedor(proveedor);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createProveedor(proveedor: Proveedor): Observable<Proveedor> {
        const url = `${this.globalVars.apiHost}${this.proveedoresUrl}`;
        let body= JSON.parse(JSON.stringify(proveedor)); 
        body.distrito_id = proveedor.distrito ? proveedor.distrito.id : null;
        return this.http.post<Proveedor>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((proveedor: Proveedor) => {
                return new Proveedor(proveedor);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
     
    updateProveedor(proveedor: Proveedor): Observable<Proveedor> {
        const url = `${this.globalVars.apiHost}${this.proveedoresUrl}/${proveedor.id}`;
        let body= JSON.parse(JSON.stringify(proveedor));
        body.distrito_id = proveedor.distrito ? proveedor.distrito.id : null;
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((proveedor: Proveedor) => {
                return new Proveedor(proveedor);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    deleteProveedor(id: number): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.proveedoresUrl}/${id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //CUENTAS PROVEEDOR
    getCuentasProveedor(idProveedor: number): Observable<Cuenta[]> {
        const url = `${this.globalVars.apiHost}${this.proveedoresUrl}/${idProveedor}/cuentas`;        
        return this.http.get<Cuenta[]>(url, this.globalVars.getOptionsRequest()).pipe(
            map((cuentas: Cuenta[]) => {
                return cuentas.map(item => new Cuenta(item));
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //IMPUTACIONES PROVEEDOR
    getImputacionesProveedor(idProveedor: number): Observable<ProveedorImputacion[]> {
        const url = `${this.globalVars.apiHost}${this.proveedoresUrl}/${idProveedor}/imputaciones`;        
        return this.http.get<ProveedorImputacion[]>(url, this.globalVars.getOptionsRequest()).pipe(
            map((provImpu: ProveedorImputacion[]) => {
                return provImpu.map(item => new ProveedorImputacion(item));
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createImputacionProveedor(proveedor: Proveedor, provImpu: ProveedorImputacion): Observable<ProveedorImputacion> {
        const url = `${this.globalVars.apiHost}${this.proveedoresUrl}/${proveedor.id}/imputaciones`;
        let body = JSON.parse(JSON.stringify(provImpu));
        body.proveedor_id = proveedor.id;
        body.imputacion_id = provImpu.imputacion ? provImpu.imputacion.id : null;
        return this.http.post<ProveedorImputacion>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((provImpu: ProveedorImputacion) => {
                return new ProveedorImputacion(provImpu);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
     
    updateImputacionProveedor(proveedor: Proveedor, provImpu: ProveedorImputacion): Observable<ProveedorImputacion> {
        const url = `${this.globalVars.apiHost}${this.proveedoresUrl}/${proveedor.id}/imputaciones/${provImpu.id}`;
        let body = JSON.parse(JSON.stringify(provImpu));
        body.proveedor_id = proveedor.id;
        body.imputacion_id = provImpu.imputacion ? provImpu.imputacion.id : null;
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((provImpu: ProveedorImputacion) => {
                return new ProveedorImputacion(provImpu);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    deleteImputacionProveedor(idProveedor: number, id: number): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.proveedoresUrl}/${idProveedor}/imputaciones/${id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //IMPUTACIONES
    getImputaciones(params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.imputacionesUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new Imputacion(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    getImputacion(id): Observable<Imputacion> {
        const url= `${this.globalVars.apiHost}${this.imputacionesUrl}/${id}`;
        return this.http.get<Imputacion>(url, this.globalVars.getOptionsRequest()).pipe(
            map((imputacion: Imputacion) => {
                return new Imputacion(imputacion);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createImputacion(imputacion: Imputacion): Observable<Imputacion> {
        const url = `${this.globalVars.apiHost}${this.imputacionesUrl}`;
        let body= JSON.parse(JSON.stringify(imputacion));    
        return this.http.post<Imputacion>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((imputacion: Imputacion) => {
                return new Imputacion(imputacion);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
     
    updateImputacion(imputacion: Imputacion): Observable<Imputacion> {
        const url = `${this.globalVars.apiHost}${this.imputacionesUrl}/${imputacion.id}`;
        let body= JSON.parse(JSON.stringify(imputacion));
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((imputacion: Imputacion) => {
                return new Imputacion(imputacion);
            }), catchError(this.globalVars.tratarErrores)
        );
    }    
      
    deleteImputacion(id: number): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.imputacionesUrl}/${id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //IMPUTACIONES CONTABLES PROVEEDOR
    getImputacionesContablesProveedor(idProveedor: number): Observable<ImputacionContable[]> {
        const url = `${this.globalVars.apiHost}${this.proveedoresUrl}/${idProveedor}/imputaciones_contables`;        
        return this.http.get<ImputacionContable[]>(url, this.globalVars.getOptionsRequest()).pipe(
            map((provImpu: ImputacionContable[]) => {
                return provImpu.map(item => new ImputacionContable(item));
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createImputacionContableProveedor(proveedor: Proveedor, imputacion: ImputacionContable): Observable<Proveedor> {
        const url = `${this.globalVars.apiHost}${this.proveedoresUrl}/${proveedor.id}/imputaciones_contables`;
        let body = JSON.parse(JSON.stringify({}));
        body.imputacion_id = imputacion ? imputacion.id : null;
        return this.http.post<Proveedor>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((prov: Proveedor) => {
                return new Proveedor(prov);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    deleteImputacionContableProveedor(idProveedor: number, id: number): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.proveedoresUrl}/${idProveedor}/imputaciones_contables/${id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }
}