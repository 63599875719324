<div class="container-fluid">
    <!-- <div class="row" *ngIf="!cheque"> -->
    <div class="row">
        <div class="card">
            <div class="card-content">
                <div class="row">
                    <breadcrumb [items]="breadcrumbItems" class="col-md-10"></breadcrumb>
                </div>
                <div class="row">
                    <div class="col-md-12 table-responsive serch-result-container">
                        <div *ngIf="type == 'proveedores'">
                            <div class="row cursor-pointer search-result-card" *ngFor="let prov of results?.proveedores?.hits" [routerLink]="['/proveedores', prov.id]">
                                <div class="row">
                                    <div class="col-sm-12 search-result-att text-center">
                                        <h4 class="search-result-att-title"><span innerHTML="{{prov._formatted.nombre}}"></span></h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 search-result-att">
                                        <b>Cuit: </b><span innerHTML="{{prov._formatted.cuit | cuitformat}}"></span>
                                    </div>
                                    <div class="col-sm-3 search-result-att">
                                        <b>Dirección: </b><span innerHTML="{{prov._formatted.direccion}}"></span>
                                    </div>
                                    <div class="col-sm-3 search-result-att">
                                        <b>Telefono: </b><span innerHTML="{{prov._formatted.telefono}}"></span>
                                    </div>
                                    <div class="col-sm-3 search-result-att">
                                        <b>Email: </b><span innerHTML="{{prov._formatted.email}}"></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 search-result-att">
                                        <b>Condición IVA: </b><span innerHTML="{{prov._formatted.condicion_iva}}"></span>
                                    </div>
                                    <div class="col-sm-3 search-result-att">
                                        <b>Consumidor final: </b><span innerHTML="{{prov.consumidor_final ? 'Si' : 'No'}}"></span>
                                    </div>
                                    <div class="col-sm-3 search-result-att">
                                        <b>Persona jurídica: </b><span innerHTML="{{prov.persona_juridica ? 'Si' : 'No'}}"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="type == 'facturas'">
                            <div class="row cursor-pointer search-result-card" *ngFor="let fact of results?.facturas.hits" [routerLink]="['/facturas', fact.id]">
                                <div class="row">
                                    <div class="col-sm-12 search-result-att text-center">
                                        <h4 class="search-result-att-title">
                                            <span innerHTML="Factura # {{fact._formatted.punto_venta}}-{{fact._formatted.numero}}"></span>
                                        </h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 search-result-att">
                                        <b>Fecha: </b><span innerHTML="{{fact.fecha != '0000-00-00 00:00:00' ? (fact.fecha | date: 'dd/MM/yyyy') : '-'}}"></span>
                                    </div>
                                    <div class="col-sm-3 search-result-att">
                                        <b>Tipo: </b><span innerHTML="{{fact.tipo | translate: 'TIPO_FACTURA'}}"></span>
                                    </div>
                                    <div class="col-sm-6 search-result-att">
                                        <b>Proveedor: </b><span innerHTML="{{fact._formatted.proveedor.nombre}}"></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 search-result-att">
                                        <b>Moneda: </b><span innerHTML="{{fact.moneda | translate: 'MONEDA'}}"></span>
                                    </div>
                                    <div class="col-sm-3 search-result-att">
                                        <b>Monto/IVA: </b><span innerHTML="$ {{fact.monto | number: '1.2-2'}} / {{fact.iva | number: '1.2-2'}}"></span>
                                    </div>
                                    <div class="col-sm-3 search-result-att">
                                        <b>Ordenes de pago por: </b><span innerHTML="$ {{fact.pagado | number: '1.2-2'}}"></span>
                                    </div>
                                    <div class="col-sm-3 search-result-att">
                                        <b>Imputacion: </b><span innerHTML="{{fact._formatted.imputacion_contable.nombre}}"></span>
                                    </div>
                                </div>
                            </div>


                        </div>
        
                        <div *ngIf="type == 'ordenes_pago'">
                            <div class="row cursor-pointer search-result-card" *ngFor="let ord of results?.ordenes_pago.hits" [routerLink]="['/ordenespago', ord.id]">
                                <div class="row">
                                    <div class="col-sm-12 search-result-att text-center">
                                        <h4 class="search-result-att-title">
                                            <span innerHTML="Orden de pago # {{ord._formatted.punto_venta}}-{{ord._formatted.numero}}"></span>
                                        </h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 search-result-att">
                                        <b>Fecha: </b><span innerHTML="{{ord.fecha != '0000-00-00 00:00:00' ? (ord.fecha | date: 'dd/MM/yyyy') : '-'}}"></span>
                                    </div>
                                    <div class="col-sm-9 search-result-att">
                                        <b>Proveedor: </b><span innerHTML="{{ord._formatted.proveedor.nombre}}"></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 search-result-att">
                                        <b>Estado: </b>
                                        <span *ngIf="ord.anulada" class="status-danger">
                                            <b>Anulada</b><i class="ml-1 ti-na"></i>
                                        </span>
                                        <span *ngIf="ord.verificada" class="status-sucess">
                                            <b>Verificada</b><i class="ml-1 ti-check"></i>
                                        </span>
                                        <span *ngIf="ord.revision && !ord.anulada" class="status-warning">
                                            <b>Revisión</b><i class="ml-1 ti-time"></i>
                                        </span>
                                        <span *ngIf="ord.finalizada && !ord.revision && !ord.verificada && !ord.anulada" class="status-sucess">
                                            <b>Finalizada</b><i class="ml-1 ti-check-box"></i>
                                        </span>
                                        <span *ngIf="!ord.finalizada && !ord.revision" class="status-info">
                                            <b>Iniciada</b><i class="ml-1 ti-bookmark-alt"></i>
                                        </span>
                                    </div>


                                    <div class="col-sm-3 search-result-att">
                                        <b>Monto: </b><span innerHTML="$ {{ord.monto | number: '1.2-2'}}"></span>
                                    </div>
                                    <div class="col-sm-3 search-result-att">
                                        <b>A pagar: </b><span innerHTML="{{ord.monto_descuentos | number: '1.2-2'}}"></span>
                                    </div>
                                    <div class="col-sm-3 search-result-att">
                                        <b>Ordenes de pago por: </b><span innerHTML="$ {{ord.pagado | number: '1.2-2'}}"></span>
                                    </div>
                                </div>
                        </div>
                        </div>
                    <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)"></pager>
                </div>

            </div>
        </div>          
    </div>
</div>



