import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';

//import 'rxjs/add/operator/toPromise';
import { Observable ,  Subject } from 'rxjs';
// Import RxJs required methods
import { map, catchError } from 'rxjs/operators';

import { GlobalVars } from '../services/globalVars';
import { Cache } from '../services/cache';

import {OrdenDeVentaGeneral, OrdenDeVenta, NotaCreditoAfip} from './models';
import {Recibo} from './models';

@Injectable()
export class OrdenService{
    private ordenesUrl = 'facturacion/orden_ventas';
    private recibosUrl = 'facturacion/recibos/orden';
    
    private reportesUrl = 'reportes/';

    constructor(private http: HttpClient, private globalVars: GlobalVars, private cache: Cache) {        
    }
    
    getOrdenes(params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new OrdenDeVentaGeneral(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    getOrden(id): Observable<OrdenDeVentaGeneral> {
        const url= `${this.globalVars.apiHost}${this.ordenesUrl}/${id}`;
        return this.http.get<OrdenDeVentaGeneral>(url, this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDeVentaGeneral) => {
                return new OrdenDeVentaGeneral(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createOrden(orden: OrdenDeVentaGeneral): Observable<OrdenDeVentaGeneral> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}`;
        let body= JSON.parse(JSON.stringify(orden));
        body.proveedor_id = orden.proveedor ? orden.proveedor.id : null;
        body.distrito_id = orden.distrito.id;
        return this.http.post<OrdenDeVentaGeneral>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDeVentaGeneral) => {
                return new OrdenDeVentaGeneral(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }


    cambiarEstadoOrden(orden: OrdenDeVentaGeneral, estado: string): Observable<OrdenDeVentaGeneral> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${orden.id}`;
        let body = {estado: estado};
        return this.http.patch(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDeVentaGeneral) => {
                return new OrdenDeVentaGeneral(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
     
    updateOrden(orden: OrdenDeVentaGeneral): Observable<OrdenDeVentaGeneral> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${orden.id}`;
        let body= JSON.parse(JSON.stringify(orden));
        delete body.orden;
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDeVentaGeneral) => {
                return new OrdenDeVentaGeneral(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    finalizarOrden(orden: OrdenDeVentaGeneral): Observable<OrdenDeVentaGeneral> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${orden.id}/finalizar`;
        let body = {finalizada: true};
        return this.http.post(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDeVentaGeneral) => {
                return new OrdenDeVentaGeneral(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    verificarOrden(orden: OrdenDeVentaGeneral): Observable<OrdenDeVentaGeneral> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${orden.id}/verificar`;
        let body = {verificada: true};
        return this.http.post(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDeVentaGeneral) => {
                return new OrdenDeVentaGeneral(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
      
    deleteOrden(id: number): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }

    anularOrden(id: number): Observable<OrdenDeVentaGeneral> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${id}/anular`;
        return this.http.post(url, JSON.stringify({}), this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDeVentaGeneral) => {
                return new OrdenDeVentaGeneral(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //ORDENES/FACTURAS
    getOrdenesFacturas(id: number): Observable<OrdenDeVenta[]> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${id}/ordenes_facturas`;        
        return this.http.get<OrdenDeVenta[]>(url, this.globalVars.getOptionsRequest()).pipe(
            map((ordenes: OrdenDeVenta[]) => {
                ordenes = ordenes.map(item => new OrdenDeVenta(item));
                return ordenes;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createOrdenFactura(orden: OrdenDeVenta): Observable<OrdenDeVenta> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${orden.ordenventageneral.id}/ordenes_facturas`;
        let body= JSON.parse(JSON.stringify(orden));
        if (orden.factura) {
            body.factura_venta_id = orden.factura.id;
        }
        return this.http.post<OrdenDeVenta>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDeVenta) => {
                return new OrdenDeVenta(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    updateOrdenFactura(orden: OrdenDeVenta): Observable<OrdenDeVenta> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${orden.ordenventageneral.id}/ordenes_facturas/${orden.id}`;
        let body= JSON.parse(JSON.stringify(orden));
        delete body.factura;
        // if (! orden.manualRetencion) {
        //     delete body.retencion;
        // }
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDeVenta) => {
                return new OrdenDeVenta(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    deleteOrdenFactura(idOrden: number, id: number): Observable<OrdenDeVentaGeneral> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${idOrden}/ordenes_facturas/${id}`;
        return this.http.delete<OrdenDeVentaGeneral>(url, this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDeVentaGeneral) => {
                return new OrdenDeVentaGeneral(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    getRecibosOrden(id: number): Observable<Recibo[]> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${id}/recibos`;
        return this.http.get<Recibo[]>(url, this.globalVars.getOptionsRequest()).pipe(
            map((recibos: Recibo[]) => {
                return recibos.map(item => new Recibo(item));
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createRecibo(recibo: Recibo): Observable<Recibo> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${recibo.ordenventa.id}/recibos`;
        let body= JSON.parse(JSON.stringify(recibo));
        body.ordenventa_id = recibo.ordenventa ? recibo.ordenventa.id : null;
        return this.http.post<Recibo>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((recibo: Recibo) => {
                return new Recibo(recibo);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    deleteRecibo(recibo: Recibo): Observable<OrdenDeVentaGeneral> {
        console.log(recibo);
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${recibo.ordenventa.id}/recibos/${recibo['id']}`;
        return this.http.delete<OrdenDeVentaGeneral>(url, this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDeVentaGeneral) => {
                return new OrdenDeVentaGeneral(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    confirmarRecibo(recibo: Recibo): Observable<Recibo> {
        const url = `${this.globalVars.apiHost}facturacion/recibos/${recibo['id']}/confirmarAfip`;
        return this.http.post<Recibo>(url, JSON.stringify({}), this.globalVars.getOptionsRequest()).pipe(
            map((recibo: Recibo) => {
                return new Recibo(recibo);
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    
    imprimirRecibo(recibo: Recibo): Observable<Blob> {
        const url = `${this.globalVars.apiHost}facturacion/recibos/${recibo['id']}/imprimirAfip`;
        return this.http.get<Blob>(url, this.globalVars.getOptionsRequestBlob());
    }


    createNotaDeCredito(notaDeCredito: NotaCreditoAfip): Observable<NotaCreditoAfip> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${notaDeCredito.ordenventa.id}/notas_de_credito_afip`;
        return this.http.post<NotaCreditoAfip>(url, JSON.stringify(notaDeCredito), this.globalVars.getOptionsRequest()).pipe(
            map((notaDeCredito: NotaCreditoAfip) => {
                return new NotaCreditoAfip(notaDeCredito);
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    getNotasDeCreditoOrden(id: number): Observable<NotaCreditoAfip[]> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${id}/notas_de_credito_afip`;
        return this.http.get<NotaCreditoAfip[]>(url, this.globalVars.getOptionsRequest()).pipe(
            map((notasDeCredito: NotaCreditoAfip[]) => {
                return notasDeCredito.map(item => new NotaCreditoAfip(item));
            }), catchError(this.globalVars.tratarErrores)
        );
    }
}