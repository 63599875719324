import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';

import {environment} from '../../../environments/environment';

import {ReciboService} from '../recibo.service';
import {DistritoService} from '../../module-distrito/distrito.service';
import {ProveedorService} from '../../module-proveedor/proveedor.service';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Recibo} from '../../module-orden-venta/models';
import {User} from '../../models/user';
import {Distrito} from '../../module-distrito/models';
import {Proveedor} from '../../module-proveedor/models';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
import { FilterCacheUtilsService } from 'app/services/filter-cache-utils.service';
import { FilterCache } from 'app/models/filter-cache';
 
const VIEW_ID = "recibos";

declare var $: any;

@Component({
  selector: 'recibo',
  templateUrl: './recibo.component.html'
})
export class ReciboComponent extends GenericFormComponent implements OnInit {
  public actualUser: User;
    
  public recibos: Recibo[];
  public params = {page: 1, per_page: 10, proveedor_search: null};
  public filtroDefault = {proveedor_id_eq: null, or_nro_comprobante_matches: null, or_descripcion_matches: null, or_punto_venta_matches: null,
    tipo_eq: null, estado_eq: null, fecha_gteq: null, fecha_lteq: null, imputacion_contable_id_eq: null, 
    distrito_id_eq: null, punto_venta_eq: null, nro_comprobante_eq: null, sort: 'fecha,desc'};
  public filtro = Object.assign({},this.filtroDefault);
  public someFiltersApplied: boolean = false;
  
  public distritos: Distrito[] = [];
  public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Recibos', active: true }];
  
  @ViewChild('fac_select_imputaciones') selectImputaciones: ElementRef;
  // @ViewChild('fac_select_proveedores') selectProveedores: ElementRef;

  public sortOptions: { [id: string] : string } = { 'fecha,desc': 'Fecha' };
    
  constructor(protected messageService: MessageService, private globalVars: GlobalVars, private reciboService: ReciboService, private distritoService: DistritoService,
      private ngZone: NgZone, private filterCacheUtilsService: FilterCacheUtilsService) {
    super(messageService);
  }

  ngOnInit() {
    this.actualUser = this.globalVars.actualUser;
    this.loadInitFilters();
    this.loadCacheFiltersParams();
    this.filter();
    this.distritoService.getDistritos({per_page: 1000}, {sort: 'nombre'}).subscribe(
        (rta: any) => {
            this.distritos = rta.data;
        }
    );
    // this.proveedorService.getProveedores({page: 1}, {sort: 'nombre'}).subscribe(
    //     rta => {
    //         this.proveedores = rta.data;
    //         this.ngZone.onStable.first().subscribe(() => {
    //             $(this.selectProveedores.nativeElement).selectpicker(); 
    //         });
    //     }
    // );
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.cursorWait();    
    this.someFiltersApplied = this.globalVars.isSomeFilterApplied(this.filtro);
    this.reciboService.getRecibos(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.recibos = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
            this.filterCacheUtilsService.setFilterParams(VIEW_ID, this.filtro, this.params);
        },
        error => {
            this.cursorDefault();
        }
    );
  }
  
  public changeFilterDate(value){
    this.filtro.fecha_gteq = value.from;
    this.filtro.fecha_lteq = value.to;
    this.filter(1);
  }
  
  onNotifyDelete(recibo: Recibo):void {
    this.deleteRecibo(recibo);
  }
    
  public deleteRecibo(recibo: Recibo) {
    this.beforeSubmitList();
    this.reciboService.deleteRecibo(recibo.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Recibo eliminada correctamente');
            //ELIMINO DE LA LISTA      
            this.recibos.forEach(function(reciboFor, index, object) {
                if(reciboFor.id == recibo.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }

  private loadInitFilters(): void {
    this.filtro.distrito_id_eq = this.globalVars.getActualDistrito();
  }

  private loadCacheFiltersParams() {
    let cacheFilters: FilterCache = this.filterCacheUtilsService.getFilterParams(VIEW_ID);
    if (!cacheFilters) return;

    this.filtro = cacheFilters.filters;
    this.params = cacheFilters.params;
  }

  public resetFilters() {
    this.filtro = Object.assign({}, this.filtroDefault);
    this.loadInitFilters();
    this.filter();
  }
}