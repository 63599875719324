import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule }   from '@angular/forms';

import {GenericModule} from '../module-generic/generic.module';
import {TranslateComponentModule} from '../translate-module/translate-component.module';

import {CuentaService} from './cuenta.service';
import {CuentaCommunicate} from './cuenta.communicate';

import {CuentasComponent} from './cuentas/cuentas.component';
import {CuentasFormComponent} from './cuentas_form/cuentas_form.component';
import {CuentasDetalleComponent} from './cuentas_detalle/cuentas_detalle.component';
import {MovimientosComponent} from './movimientos/movimientos.component';
import {MovimientosFormComponent} from './movimientos_form/movimientos_form.component';
import {ChequerasComponent} from './chequeras/chequeras.component';
import {ChequerasFormComponent} from './chequeras_form/chequeras_form.component';
import {ChequerasDetalleComponent} from './chequeras_detalle/chequeras_detalle.component';
import {ChequerasChequesComponent} from './chequeras_cheques/chequeras_cheques.component';
import {ChequesComponent} from './cheques/cheques.component';
import {ChequesFormComponent} from './cheques_form/cheques_form.component';
import {MetodosPagoComponent} from './metodos_pago/metodos_pago.component';
import {MetodosPagoFormComponent} from './metodos_pago_form/metodos_pago_form.component';

import {ChequeModalComponent} from './cheque_modal/cheque_modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    
    GenericModule,
    TranslateComponentModule,
  ],
  declarations: [
    CuentasComponent,
    CuentasFormComponent,
    CuentasDetalleComponent,
    MovimientosComponent,
    MovimientosFormComponent,
    ChequerasComponent,
    ChequerasFormComponent,
    ChequerasDetalleComponent,
    ChequerasChequesComponent,
    ChequesComponent,
    ChequesFormComponent,
    MetodosPagoComponent,
    MetodosPagoFormComponent,

    ChequeModalComponent
  ],
  providers: [
    CuentaService,
    CuentaCommunicate
  ],
  exports: [
    CuentasComponent,
    CuentasFormComponent,
    MetodosPagoComponent,
    ChequerasComponent,
    ChequerasDetalleComponent,
    ChequesComponent,
    
    MovimientosFormComponent,
    ChequesFormComponent,

    ChequeModalComponent
  ]
})
export class CuentaModule { }
