import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import {environment} from '../../../environments/environment';

import {CuentaService} from '../cuenta.service';
import {DistritoService} from '../../module-distrito/distrito.service';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';

import {Cuenta, Banco} from '../models';
import {User} from '../../models/user';
import {Distrito} from '../../module-distrito/models';
import {Proveedor} from '../../module-proveedor/models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'cuentas_form',
  templateUrl: 'cuentas_form.html'
})
export class CuentasFormComponent extends GenericFormComponent implements OnInit, AfterViewInit{
    @ViewChild('firstElementCuenta', { static: true }) firstElement: ElementRef;
    public actualUser: User
    
    @Input() cuenta: Cuenta = new Cuenta();
    @Input() proveedor: Proveedor = null;
    public tipos: string[] = environment.tipoCuentas;
    public tiposCuenta: string[] = environment.tipoCuentasBanco;
    public bancos: Banco[] = [];
    public distritos: Distrito[] = [];
    public edit: Boolean = true;
    private originalAccountData: Cuenta = null;
    
    @Output() actualize: EventEmitter<Cuenta> = new EventEmitter<Cuenta>();
    @Output() cancel: EventEmitter<string> = new EventEmitter<string>();

    public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Cuentas', ref: '#/cuentas' }];
    
    constructor(protected messageService: MessageService, private cuentaService: CuentaService, private distritoService: DistritoService, 
        private router: Router, private globalVars: GlobalVars, private _titleService: Title) {
        super(messageService);
    }


    ngOnInit(): void {
        if(this.cuenta?.id) {
            this.edit = false;
            this.breadcrumbItems.push({ title: `${this.cuenta.nombre}`, active: true });
            this._titleService.setTitle(`Cuenta bancaria # ${this.cuenta.nombre}`)
        }

        this.actualUser = this.globalVars.actualUser;
        
        this.distritoService.getDistritos({per_page: 1000}, {sort: 'nombre'}).subscribe(
            (rta: any) => {
                this.distritos = rta.data;
            }
        );
        this.cuentaService.getBancos({per_page: 1000}, {sort: 'nombre'}).subscribe(
            (bancos: Banco[]) => {
                this.bancos = bancos;
            }
        );
        if (!this.cuenta.id) {
            this.cuenta.proveedor = this.proveedor;
            this.tipos = this.tipos.filter(t => t != 'CAJA_CHICA');
        }
        if (!this.cuenta.id && this.cuenta.proveedor) {
            this.cuenta.tipo = 'BANCO';
        }
        if (this.cuenta.id && this.cuenta.proveedor) {
            this.tipos = ['OTRO'].concat(this.tipos);
        }
    }
    ngAfterViewInit(): void {
        this.firstElement.nativeElement.focus();
    }
    
    public save(event){
        this.beforeSubmit(event);
        if(! this.cuenta.id){
            this.cuentaService.createCuenta(this.cuenta).subscribe(
                (cuenta: Cuenta) => {
                    this.showSuccess(true, 'Cuenta creada correctamente');
                    this.actualize.emit(cuenta);
                    this.router.navigate(['/cuentas', cuenta.id]);   
                },err => {
                    this.showError(err, true);
                });
        } else {
            this.cuentaService.updateCuenta(this.cuenta).subscribe(
                (cuenta: Cuenta) => {
                    this.showSuccess(true, 'Cuenta modificada correctamente');
                },err => {
                    this.showError(err, true);
                });
        }
        this.edit = false;
    }
    
    public cancelForm($event?){
        this.cancel.emit("Cancelacion");
    }
    
    //
    //UTILS    
    public customCompareDistrito(d1: Distrito, d2: Distrito) {
        if(d1 && d2){
            return d1.id == d2.id;
        }else{
            return d1 == d2;
        }
    }
    
    public customCompareBanco(b1: Banco, b2: Banco) {
        if(b1 && b2){
            return b1.id == b2.id;
        }else{
            return b1 == b2;
        }
    }

    public onEditAccount() {
        this.originalAccountData = Object.assign({}, this.cuenta);
        this.edit = true;
    }

    public onCancelEditAccount() {
        this.cuenta = Object.assign({}, this.originalAccountData);
        this.edit = false;
    }
}