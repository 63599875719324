<div class="row" *ngIf="showHeaderSection">
    <div class="col-sm-6">
        <div [innerHtml]="title" class="mt-2"></div>
    </div>
    <ng-content select="[searchElastic]"></ng-content>
    <div class="col-sm-12 mt-2" *ngIf="showFilterButton">
        <div class="row text-right">
            <button class="btn btn-simple btn-icon mr-2" (click)="toggleFilterSection()" title="Filtros"><i class="cmp-i filtro-accion-2"></i> Filtros <span class="badge" *ngIf="someFilterApllied" title="Hay filtros aplicados">!</span></button>
        </div>
    </div>
</div>

<div *ngIf="showFilterSection">
    <div class="row">
        <ng-content></ng-content>
    </div>

    <div class="row mt-1" *ngIf="advancedFilters">
        <ng-content select="[moreFilters]" *ngIf="showMoreFilters"></ng-content>
        <div class="row">
            <div class="text-center col-sm-12">
                <a (click)="showMoreFilters = true" *ngIf="!showMoreFilters" class="cursor-pointer advanced-filter-btn">Mostrar filtros avanzados</a>
                <a (click)="showMoreFilters = false" *ngIf="showMoreFilters" class="cursor-pointer advanced-filter-btn">Ocultar filtros avanzados</a>
            </div>
        </div>
    </div>
        
    <div class="row text-center mt-2">
        <button class="btn btn-danger mr-2" (click)="onClearLocal()" *ngIf="someFilterApllied"> Limpiar Filtros</button>
        <button class="btn btn-primary" (click)="onFilterLocal()"> Filtrar </button>
    </div>
</div>
