import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import flatpickr from 'flatpickr';
import flatpickrLang from "flatpickr/dist/l10n/es"

@Component({
  selector: 'input-datepicker',
  templateUrl: './input-datepicker.component.html'
})
export class InputDatepickerComponent implements OnInit, OnChanges{
  @Input() required?: boolean = false;
  @Input() dateModel: Object;
  @Output() dateModelChange: EventEmitter<Object> = new EventEmitter<Object>();
  @Input() defaultValue?: string | Date;
  @Input() isStringFormat?: boolean;
  @Input() minDate?: string;
  @Input() maxDate?: string;
  @Input() disabled?: boolean = false;
  @Output() actualize: EventEmitter<Object> = new EventEmitter<Object>();

  @ViewChild('datepicker', { static: true }) datepickerEl: ElementRef;

  private flatpickrInstance;

  ngOnInit(): void {
    flatpickr.localize(flatpickrLang.es);
    this.setFaltpickrConfig();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes.disabled && !changes.dateModel && !changes.minDate && !changes.maxDate) return;
    this.setFaltpickrConfig();
  }

  private setFaltpickrConfig(): void {
    this.datepickerEl.nativeElement.disabled = this.disabled;
    if(this.dateModel) {
      this.defaultValue = this.dateModel.toString();
    }
    this.flatpickrInstance = flatpickr(this.datepickerEl.nativeElement, {
      altInput: true,
      altFormat: "d/m/Y",
      dateFormat: "Y-m-d",
      allowInput: true,
      defaultDate: this.defaultValue ? new Date(this.defaultValue) : null,
      onChange: date => this.onChangeDates(date),
      minDate: this.minDate ?? "1900-01-01",
      maxDate: this.maxDate ?? "2300-12-31",
      static: true
    });
  }

  private onChangeDates(date){
    let newDate = new Date(date);
    this.isStringFormat ? this.dateModelChange.emit(newDate.toISOString().substring(0,10) + ' 00:00:00') : this.dateModelChange.emit(newDate);
    this.isStringFormat ? this.actualize.emit(newDate.toISOString().substring(0,10) + ' 00:00:00') : this.actualize.emit(newDate);
  }

  public toggleDatePicker() {
    this.flatpickrInstance.toggle();
  }
}
