import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import {FacturaService} from '../factura.service';
import {FacturaCommunicate} from '../factura.communicate';
import {GlobalVars} from '../../services/globalVars';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Factura} from '../models';

declare var $: any;

@Component({
  selector: 'facturas_modal',
  templateUrl: './facturas_modal.component.html'
})
export class FacturasModalComponent extends GenericFormComponent implements OnInit, OnDestroy {
  @ViewChild('facturasModal', { static: true }) el:ElementRef;
    
  public show: boolean= false;
  public facturas: Factura[];
  public params = {page: 1, per_page: 10, proveedor_search: null, esta_pagada: 1};
  public filtro = {search_elastic: null, or_punto_venta_matches: null, moneda: null, estado: 'A', proveedor_id: null, distrito_id: null, sort: { key: 'fecha', order: 'desc' }};
  public lastSearch: number = 0;

  public selectAll: boolean = false;
  
  //Observables
  private facturasModal: any;
    
  constructor(protected messageService: MessageService, private globalVars: GlobalVars, private facturaCommunicate: FacturaCommunicate, private facturaService: FacturaService) {
      super(messageService);
  }

  ngOnInit() {
    this.facturasModal = this.facturaCommunicate.facturasModalPop$.subscribe(
        (info: any) => {
            this.load();
            if ('proveedor' in info) {
                this.filtro.proveedor_id = info['proveedor'];
            } else {
                this.filtro.proveedor_id = null;
            }
            if ('moneda' in info) {
                this.filtro.moneda = info['moneda'];
            } else {
                this.filtro.proveedor_id = null;
            }
            if(!this.globalVars.actualUser.multi_distrito){
                this.filtro.distrito_id = this.globalVars.getActualDistrito();
            }
            this.filter();
        });
        
    $(this.el.nativeElement).on('hidden.bs.modal', (e) => {
        this.show= false;
    });
  }
  
  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.cursorWait();
    if(this.filtro.proveedor_id == null){
      this.params.proveedor_search = this.filtro.or_punto_venta_matches;
    }
    this.lastSearch = new Date().getTime() / 1000;
    this.facturaService.getFacturas(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.facturas = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
            this.lastSearch = new Date().getTime() / 1000;
        },
        error => {
            this.cursorDefault();
        }
    );
  }

  onChangeSearch(event) {
    if (new Date().getTime() / 1000 > this.lastSearch + 1) {
      this.filter(1);
    }
  }
     
  public seleccionar(){
    let facturas: Factura[] = this.facturas.filter(fac => fac.selectedInTable);
    if (facturas.length > 0) {
        this.facturaCommunicate.facturasSelecModalPopUp(facturas);
        this.hide();
    }
  } 
  
  //
  //UTILS
  public changeAllSelects(){
    this.selectAll = !this.selectAll;
    this.facturas.forEach(fac => fac.selectedInTable = this.selectAll);
  }
   
  //MODAL
  public load(){
    this.filtro.search_elastic = '';
    this.show= true;
    $(this.el.nativeElement).modal();
  }
  public hide(){
    $(this.el.nativeElement).modal("hide");
    this.show= false;
  }
  
  //OnDestroy
  ngOnDestroy() {
    this.facturasModal.unsubscribe();
  }
}