import {Factura} from '../module-factura-venta/models';
import {Proveedor} from '../module-proveedor/models';
import {Distrito} from '../module-distrito/models';

export class OrdenDeVentaGeneral {
    public id: number;
    public punto_venta: number = 1;
    public numero: number;
    public fecha: any;
    public moneda: string = 'PES';
    public monto: number = 0;
    public pagado: number = 0;
    public descripcion: string = '';
    public estado: string = 'P';
    
    public proveedor: Proveedor = null;
    public distrito: Distrito = null;
    public distrito_id: any;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.punto_venta = json.punto_venta;
            this.numero = json.numero;
            this.fecha = json.fecha;
            this.moneda = json.moneda;
            this.descripcion = json.descripcion;
            this.monto = json.monto;
            this.pagado = json.pagado;
            this.estado = json.estado;
            
            if (json.proveedor) {
                this.proveedor = new Proveedor(json.proveedor);
            }
            if (json.distrito) {
                this.distrito = new Distrito(json.distrito);
            }
            this.distrito_id = json.distrito_id;
        }
    }
    
    public actualizar(orden: OrdenDeVentaGeneral) {
        this.monto = orden.monto;
        this.pagado = orden.pagado;
    }
    
    public tienePagos() {
        return this.monto > 0;
    }
    
    public print() {
        return this.punto_venta + "-" + this.numero;
    }

    public aPagar() {
        return (this.monto - this.pagado);
    }
}

export class OrdenDeVenta {
    public id: number;
    public monto: number;
    public montoDescuentos: number;
    public retencion: number;
    public retencionManual: boolean = false;
    public porcentajeDescuentos: number = 0;
    public descuentos: number;
    public anulada: boolean = false;
    
    public factura: Factura = null;
    public ordenventageneral: OrdenDeVentaGeneral = null;
    
    public editando: boolean = false;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.monto = json.monto;
            this.montoDescuentos = json.montoDescuentos;
            this.retencion = json.retencion;
            this.retencionManual = json.retencionManual ? json.retencionManual : false;
            this.porcentajeDescuentos = Number(json.porcentajeDescuentos);
            this.descuentos = json.descuentos;
            this.anulada = json.anulada;
            
            if (json.factura_venta) {
                this.factura = new Factura(json.factura_venta);
            }
            if (json.ordenventageneral) {
                this.ordenventageneral = new OrdenDeVentaGeneral(json.ordenventageneral);
            }
        }
    }
    
    public inicializar (factura: Factura) {
        this.monto = factura.restaPagar();
        this.factura = factura;
    }

    public numero () {
        if (this.factura) {
            return this.factura.punto_venta + '-' + this.factura.numero;
        }
    }
    
    public montoOriginal() {
        if (this.factura) {
            return this.factura.monto;
        }
    }
}

export class Recibo {
    public id: number;
    public punto_venta: number;
    public nro_comprobante: number;
    public descripcion: string = '';
    public monto: number;
    public moneda: string = 'PES';
    public estado: string = 'I';
    public fecha: any;
    public fecha_vto_pago: any;
    public fecha_serv_desde: any;
    public fecha_serv_hasta: any;
    public nro_cuenta: string;
    public nro_cheque: string;
    public tipo: string = 'DEBITO';
    public created_at: string;
    public anulado: boolean = false;

    public ordenventa: OrdenDeVentaGeneral = null;
    public orden_venta_general_id: number;

    constructor(json?) {
        if(json){
            this.id = json.id;
            this.punto_venta = json.punto_venta;
            this.nro_comprobante = json.nro_comprobante;
            this.descripcion = json.descripcion;
            this.monto = json.monto;
            this.moneda = json.moneda;
            this.nro_cuenta = json.nro_cuenta;
            this.nro_cheque = json.nro_cheque;
            this.tipo = json.tipo;
            this.created_at = json.created_at;
            this.anulado = json.anulado;
            this.estado = json.estado;
            this.fecha = json.fecha;
            this.fecha_vto_pago = json.fecha_vto_pago;
            this.fecha_serv_desde = json.fecha_serv_desde;
            this.fecha_serv_hasta = json.fecha_serv_hasta;

            if (json.ordenventa) {
                this.ordenventa = new OrdenDeVentaGeneral(json.ordenventa);
            }
            this.orden_venta_general_id = json.orden_venta_general_id;
        }
    }
}

export class NotaCreditoAfip {
    public id: number;
    public punto_venta: number;
    public nro_comprobante: number;
    public descripcion: string = '';
    public monto: number;
    public moneda: string = 'PES';
    public estado: string = 'I';
    public fecha: any = new Date();
    public fecha_vto_pago: any;
    public fecha_serv_desde: any;
    public fecha_serv_hasta: any;
    public nro_cuenta: string;
    public nro_cheque: string;
    public tipo: string = 'DEBITO';
    public created_at: string;
    public anulado: boolean = false;

    public ordenventa: OrdenDeVentaGeneral = null;
    public orden_venta_general_id: number;

    public recibo: Recibo = null;
    public recibo_id: number = null;

    constructor(json?) {
        if(json){
            this.id = json.id;
            this.punto_venta = json.punto_venta;
            this.nro_comprobante = json.nro_comprobante;
            this.descripcion = json.descripcion;
            this.monto = json.monto;
            this.moneda = json.moneda;
            this.nro_cuenta = json.nro_cuenta;
            this.nro_cheque = json.nro_cheque;
            this.tipo = json.tipo;
            this.created_at = json.created_at;
            this.anulado = json.anulado;
            this.estado = json.estado;
            this.fecha = json.fecha;
            this.fecha_vto_pago = json.fecha_vto_pago;
            this.fecha_serv_desde = json.fecha_serv_desde;
            this.fecha_serv_hasta = json.fecha_serv_hasta;

            if (json.ordenventa) {
                this.ordenventa = new OrdenDeVentaGeneral(json.ordenventa);
            }
            this.orden_venta_general_id = json.orden_venta_general_id;

            if (json.recibo) {
                this.recibo = new Recibo(json.recibo);
            }
            this.recibo_id = json.recibo_id ?? null;
        }
    }
}