// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  
  dynamicConfig: 'assets/config/config.json',
  
  //API
  //apiHost: "http://compass.dedalo-dev.com:8020/api/",
  //apiHost: "https://compass-api.dedalo.com.ar/",
  apiHost: "https://colegiotecnicos.compass-demos.com/api/",
  
  defaultPage: 'facturas',
  panels: ['admin', 'operations', 'sells'],
  panelsRoles: {'admin': ['admin_sistema'], 'operations': ['tesorero', 'administrativo', 'auditor', 'autoridad'], 'sells': ['tesorero', 'administrativo', 'auditor', 'autoridad']},
  defaultPanelsByRol: {'tesorero': 'operations', 'admin_sistema': 'admin', 'administrativo': 'operations', 'auditor': 'operations', 'autoridad': 'operations'},
  defaultPages: {'admin': '/usuarios', 'operations': '/facturas', 'sells': '/'},
  
  tipoCuentas: ['BANCO', 'CAJA_CHICA'],
  tipoCuentasBanco: ['CC$PES', 'CC$USD', 'CA$PES', 'CA$USD'],
  tipoMetodos: ['TRANSFERENCIA', 'DEBITO', 'CHEQUE', 'EFECTIVO'],
  book_types: ['AU', 'MA'],
  estados_cheque: ['CARTERA','PROVEEDOR','CLIENTE','RECHAZADO','RECHADO_N','DEPOSITADO','RETIRO','COBRADO','VENDIDO','ANULADO','CAJA_CHICA'],
  monedas: ['PES', 'DOL'],
  tiposFactura: ['B', 'C', 'E', 'X', 'RP'],
  tiposFacturaVenta: ['RC'],
  estadosFactura: ['P', 'A', 'R'],
  ivas: [0, 10.5, 21, 27, 5, 2.5],
  condicionesIva: ['CONFI', 'MONOT', 'RESIN', 'EXENT', 'RE_NOI', 'NORES', 'RES_X', 'EGRBA'],
  descuentosCaja: [0, 5, 10],
  cant_listado: [5, 10, 20, 50],
  
  estadosViatico: ['P', 'A', 'R'],
  tiposViatico: ['FMC'],
  tipoViatico: ['K', 'H', 'C'],

  reportes: [
    {title: 'Ordenes', id: 'ordenes'},
    {title: 'Caja Chica', id: 'caja-chica'},
    {title: 'Retenciones Ganancia', id: 'retenciones-ganancia'},
    {title: 'Cheques', id: 'cheques'}
  ],

  meses: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
  
  //CACHE
  cachePrefix: 'sis-cont-',
  maxCacheItems: 200,
  cache: {
    //MENUS-ITEMS
    menus: ['ejemplo', 60*60],
  },

  bank_logo_ids: [1, 14, 303, 4, 20, 304, 5, 312, 120, 191, 328, 301, 330, 342]
};

