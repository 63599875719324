import { Injectable } from '@angular/core';

import { Subject }    from 'rxjs';

import {OrdenDeVentaGeneral} from './models';

@Injectable()
export class OrdenCommunicate {
    // Observable string sources
    private ordenModalPopSource = new Subject<number>();
    
    // Observable string streams
    public ordenModalPop$ = this.ordenModalPopSource.asObservable();
    
    constructor() { 
    }

    public ordenModalPopUp(id: number){
        this.ordenModalPopSource.next(id);
    }
}