
import {throwError as observableThrowError,  Observable ,  Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
//import {RequestOptions, Request, RequestMethod} from '@angular/http';

import {environment} from '../../environments/environment';

import {DomSanitizer } from '@angular/platform-browser';

import {MessageService} from './message.service';

import { User } from '../models/user';
import { Config } from '../models/config';

@Injectable()
export class GlobalVars {
    public authorization: string= null;
    public actualUser: User = null;
    public actualPanel: string = null;
    public actualDistrito: number = 0;
    public config: Config = null;
    
    //Dynamic Config
    public version: string;
    
    public apiHost: string= environment.apiHost;

    // Observable string sources
    private logoutUserSource = new Subject<any>();
    private changePanelSource = new Subject<any>();
    private changeDistritoSource = new Subject<any>();
    // Observable string streams
    logoutUser$ = this.logoutUserSource.asObservable();
    changePanel$ = this.changePanelSource.asObservable();
    changeDistrito$ = this.changeDistritoSource.asObservable();

    constructor(private sanitizer: DomSanitizer, private messageService: MessageService) { 
    }

    //
    //SESION Y SUS VARIABLES
    public deleteSessionData(){
        this.authorization= null;
        this.actualUser= null;     
        localStorage.clear();       
    }
        
    //Token
    public getAuthorization(){
        if(this.authorization == null){
            this.authorization= localStorage.getItem('authorization');
        }
        return this.authorization;
    }
    public setAuthorization(token: string){
        this.authorization= 'bearer ' + token;
        localStorage.setItem('authorization', this.authorization);
    }    
    
    //Usuario Actual
    public setActualUser(user: User){
        this.actualUser = user;
        if (this.getActualPanel() != null) {
            if (! this.actualUser.hasAtLeastOneRolByName(environment.panelsRoles[this.actualPanel])) {
                this.actualPanel = null;
            }
        }
        if (this.actualPanel == null) {
            if (this.actualPanel == null && this.actualUser.hasRolByName('tesorero')) {
                this.actualPanel = environment.defaultPanelsByRol['tesorero'];
            }
            if (this.actualPanel == null && this.actualUser.hasRolByName('administrativo')) {
                this.actualPanel = environment.defaultPanelsByRol['administrativo'];
            }
            if (this.actualPanel == null && this.actualUser.hasRolByName('autoridad')) {
                this.actualPanel = environment.defaultPanelsByRol['autoridad'];
            }
            if (this.actualPanel == null) {
                this.actualPanel = 'operations';
            }
            localStorage.setItem('actual-panel', this.actualPanel);     
        }
        //DISTRITOS    
        if (this.actualUser.multi_distrito) {
            if (this.getActualDistrito() == 0) {
                this.actualDistrito = this.actualUser.distrito.id;
            }
        } else {
            this.actualDistrito = this.actualUser.distrito.id;
        }
    }    

    //Paneles
    public getActualPanel(){
        if (this.actualPanel != null){
            return this.actualPanel;
        }
        this.actualPanel = localStorage.getItem('actual-panel');
        return this.actualPanel;
    }
    public setActualPanel(panel, redirect = true){
        this.actualPanel = panel;
        localStorage.setItem('actual-panel', this.actualPanel);
        this.changePanelSource.next({redirect: redirect});        
    }
    
    //Distrito
    public getActualDistrito(){
        if (this.actualDistrito != 0){
            return this.actualDistrito;
        }
        this.actualDistrito = Number(localStorage.getItem('actual-distrito'));
        return this.actualDistrito;
    }
    public setActualDistrito(distrito){
        this.actualDistrito = distrito;
        localStorage.setItem('actual-distrito', this.actualDistrito.toString());
        this.changeDistritoSource.next();
        window.location.reload();
    }

    //
    //HTTP
    public getOptionsRequest(params?, body?){
//        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
//        if(this.authorization != null){
//            headers = headers.append('Authorization', this.authorization);
//        }
//        let opts= { headers: headers };       
        let opts = {}; 
        if(params){
            let httpParams: HttpParams = new HttpParams();
            for (var prop in params) {
                if(params[prop] !== null){
                    httpParams= httpParams.append(prop, params[prop]);
                }
            }
            opts['params']= httpParams;
        }
        //
        //LO DE BODY CREO QUE CAMBIO
        if(body){
            opts['body']= body;
        }
        return opts;
//        let options = new RequestOptions(opts);
//        return options;
    }
    
    public getOptionsRequestBlob(params?, body?){
        let opts= {};    
        if(params){
            let httpParams: HttpParams = new HttpParams();
            for (var prop in params) {
                if(params[prop] !== null){
                    httpParams= httpParams.append(prop, params[prop]);
                }
            }
            opts['params']= httpParams;
        }
        if(body){
            opts['body']= body;
        }
        opts['responseType'] = "blob";
        return opts;
    }
    
    public filtroToUrl(filtro, sep = '?') {
        let urlPlus = '';
        for (var prop in filtro) {
            if (prop == 'sort') {
                for (var sort of filtro[prop].split("|")) {
                    urlPlus += sep + "sort[]=" + sort;
                    sep = '&';
                }
                continue;
            }
            if (filtro[prop] != null) {
                let propName = prop;
                if (propName.substring(0,3) == 'or_') {
                    propName = propName.substring(3);
                    urlPlus += sep + "filtro_or[" + propName + "]=" + filtro[prop];                    
                } else {
                    urlPlus += sep + "filtro[" + propName + "]=" + filtro[prop];
                }                
                sep = '&';
            }
        }
        return urlPlus;
    }

    public filtroToUrlV2(filtro, sep = '?') {
        let urlPlus = '';
        for (var prop in filtro) {
            if (prop == 'sort') {
                urlPlus += `${sep}sort_by=${filtro['sort'].key}&sort_order=${filtro['sort'].order}`
                sep = '&';
                continue;
            }
            if (filtro[prop] != null) {
                let propName = prop;
                urlPlus += `${sep}${propName}=${filtro[prop]}`    
                sep = '&';       
            }
        }
        return urlPlus;
    }

    public isSomeFilterApplied(filters): boolean {
        let count: number = 0;
        for (var prop in filters) {
            if (prop == 'sort') continue;
            if (filters[prop] != null) count++;
        }
        return count > 0
    }
    
    tratarErrores = (error) => {
        //let errorOb= JSON.stringify(error);
        if (error.status == 401) {
            this.logoutUserSource.next();
            return observableThrowError(error.error);            
//            const url= `${this.apiHost}login`;
//            return this.http.delete(url, this.getOptionsRequest())
//                .map((res:Response) => {
//                    this.authorization= null;
//                    //this.storage.remove('session_id');
//                    this.actualUser= null;
//                    //this.storage.remove('user');
//                    this.logoutUserSource.next();
//                    return Observable.throw(error._body != '' ? error.json() : '401 - Error de permisos');
//                })
//              .catch((error:any) => Observable.throw(error._body != '' ? error.json() : 'Server error'));            
        }else if(error.status == 403){
            //alert("Usted no tiene acceso a esta funcionalidad");
            this.messageService.showNotification("top", "center", "danger", 'Usted no tiene acceso a esta funcionalidad');
            return observableThrowError(error.error);  
        }else if(error.status == 500){
            //alert("Ha sucedido un error inesperado en el servidor. En caso de persistir este error comunicarse con personal tecnico.");
            this.messageService.showNotification("top", "center", "warning", 'No se pudo realizar la accion requerida');
            return observableThrowError(error.error);            
        }else if(error.status == 0){ //error.type= 3
            alert("No hay conexion con el Servidor. Vuelva a intentar en unos momentos");
            return observableThrowError(error);
        }else{
            //Aca estarian solo los errores que devuelve error que deben materializarse en comunicacion con el usuario.
            return observableThrowError(error.error);
        }
    }
    
    //
    //UTILS VARIOS
    public linkImage(fileName: string){
//        return this.sanitizer.bypassSecurityTrustResourceUrl(this.contenidoHost + fileName);   
    }
    public dataImage(base64: string){
        return this.sanitizer.bypassSecurityTrustResourceUrl(base64);   
    }
    
    public objectToUrlParameters(paramsObject){
        let paramsString= Object.keys(paramsObject).filter(prop => paramsObject[prop] != null).map(function(prop) {
            return [prop, paramsObject[prop]].map(encodeURIComponent).join("=");
        }).join("&");
        return paramsString;
    }
}