import { Component, OnInit, Input } from '@angular/core';

import {ViaticoService} from '../viatico.service';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Viatico, ItemViatico} from '../models';
 
@Component({
  selector: 'items_viatico',
  templateUrl: './items_viatico.component.html'
})
export class ItemsViaticoComponent extends GenericFormComponent implements OnInit {
  @Input() viatico: Viatico;
  public items: ItemViatico[];

  public itemK: ItemViatico = null;
  public itemH: ItemViatico = null;
  public itemC: ItemViatico = null;
  // public itemO: ItemViatico = null;
  public itemM: ItemViatico = null;
    
  constructor(protected messageService: MessageService, private viaticoService: ViaticoService) {
    super(messageService);
  }

  ngOnInit() {
    this.filter();
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    this.cursorWait();
    this.viaticoService.getItemsViatico(this.viatico.id).subscribe(
        (items: ItemViatico[]) => {
            this.cursorDefault();
            this.items = items;
            if (this.items.filter(i => i.tipo_viatico.tipo == 'K').length) {
                this.itemK = this.items.filter(i => i.tipo_viatico.tipo == 'K')[0];
            } else {
                this.itemK = new ItemViatico();
                this.itemK.viatico = this.viatico;
            }
            
            if (this.items.filter(i => i.tipo_viatico.tipo == 'H').length) {
                this.itemH = this.items.filter(i => i.tipo_viatico.tipo == 'H')[0];
            } else {
                this.itemH = new ItemViatico();
                this.itemH.viatico = this.viatico;
            }
            
            if (this.items.filter(i => i.tipo_viatico.tipo == 'C').length) {
                this.itemC = this.items.filter(i => i.tipo_viatico.tipo == 'C')[0];
            } else {
                this.itemC = new ItemViatico();
                this.itemC.viatico = this.viatico;
            }
            
            // if (this.items.filter(i => i.tipo_viatico.tipo == 'O').length) {
            //     this.itemO = this.items.filter(i => i.tipo_viatico.tipo == 'O')[0];
            // } else {
            //     this.itemO = new ItemViatico();
            //     this.itemO.viatico = this.viatico;
            // }
            
            if (this.items.filter(i => i.tipo_viatico.tipo == 'M').length) {
                this.itemM = this.items.filter(i => i.tipo_viatico.tipo == 'M')[0];
            } else {
                this.itemM = new ItemViatico();
                this.itemM.viatico = this.viatico;
                this.itemM.cantidad = 1;
            }
        },
        error => {
            this.cursorDefault();
        }
    );
  }
  
  //ACCIONES    
  onNotifyDelete(item: ItemViatico):void {
    this.deleteItem(item);
  }
    
  public deleteItem(item: ItemViatico) {
    this.beforeSubmitList();
    this.viaticoService.deleteItemViatico(this.viatico.id, item.id).subscribe(
        (item: ItemViatico) => {
            this.showSuccessList(true, 'Item eliminado correctamente');
            //ELIMINO DE LA LISTA      
            this.items.forEach(function(itemFor, index, object) {
                if(itemFor.id == item.id) {
                    object.splice(index, 1);
                }
            });
            this.viatico.actualizar(item.viatico);
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }

  //Desde el formulario
  public actualizeForm(item: ItemViatico): void {
    this.viatico.actualizar(item.viatico);
  }    
}