<div class="row">
    <div class="card">
        <div class="card-content">
            <h4 class="card-title">Facturas</h4>
            <hr>

            <filters-header-section (onFilter)="filter(1)">
                <div class="col-sm-12">
                    <generic_period (outDate)="changeFilterDate($event)"></generic_period>
                </div>                    
            </filters-header-section> 
            
            <div class="row mt-2">
                <div class="col-md-12 table-responsive">
                    <table class="table table-hover">
                        <thead class="text-primary">
                            <tr>
                                <th><b>Número</b></th>
                                <th><b>Fecha</b></th>
                                <th><b>Tipo</b></th>
                                <th><b>Monto</b></th>
                                <th><b>Ordenes de pago por</b></th>
                                <th><b>Moneda</b></th>
                                <th><b>Imputación</b></th>
                                <th><b>Acciones</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor='let factura of facturas'>
                                <td><a (click)="detalleFactura(factura)" rel="tooltip" title="Detalle">{{factura.punto_venta}} - {{factura.numero}}</a></td>
                                <td>{{factura.fecha | date: 'dd/MM/yyyy'}}</td>
                                <td>{{factura.tipo | translate: 'TIPO_FACTURA'}}</td>
                                <td>$ {{factura.monto | number: '1.2-2'}}</td>
                                <td>$ {{factura.pagado | number: '1.2-2'}}</td>
                                <td>{{factura.moneda | translate: 'MONEDA'}}</td>
                                <td>
                                    <span *ngIf="factura.imputacion_contable">{{factura.imputacion_contable.nombre}}</span>
                                    <span *ngIf="!factura.imputacion_contable">-</span>
                                </td>
                                <td class="td-actions text-right">
                                    <a [routerLink]="['/facturas', factura.id]" rel="tooltip" title="Editar Factura" class="btn btn-primary btn-simple btn-xs btn-cmp-i">
                                        <i class="cmp-i editar i-sm"></i>
                                    </a>
                                    <button type="button" rel="tooltip" title="Eliminar Factura" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="factura" confirm-message="¿Está seguro que desea eliminar la Factura?">
                                        <i class="cmp-i eliminar-borrar i-sm"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr *ngIf="facturas != null && facturas.length == 0" class="text-center">
                                <td colspan="8">No se encontraron facturas</td>
                            </tr>
                        </tbody>
                    </table>                        
                    <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)" *ngIf="facturas != null && facturas.length > 0"></pager>
                </div>
            </div>
        </div>
    </div>
</div>