import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sort-select',
  templateUrl: './sort-select.component.html',
  styleUrls: ['./sort-select.component.scss']
})
export class SortSelectComponent implements OnInit {
  @Input() options: { [id: string] : string } = {};
  @Input() sortModel: string;
  @Output() sortModelChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

  public optionKeys: string[] = [];

  constructor() { }

  ngOnInit(): void {
    this.optionKeys = Object.keys(this.options);
  }

  public onSelectLocal(option: string): void {
    this.sortModel = option;
    this.sortModelChange.emit(option);
    this.onSelect.emit();
  }

}
