import { Component, OnInit, Input } from '@angular/core';

import {FacturaService} from '../factura.service';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Factura, Producto} from '../models';
 
@Component({
  selector: 'productos',
  templateUrl: './productos.component.html'
})
export class ProductosComponent extends GenericFormComponent implements OnInit {
  @Input() factura: Factura;
  public productos: Producto[];
  //FORM
  public producto: Producto;
    
  constructor(protected messageService: MessageService, private facturaService: FacturaService) {
    super(messageService);
  }

  ngOnInit() {
    this.filter();
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    this.cursorWait();
    this.facturaService.getProductosFactura(this.factura.id).subscribe(
        (productos: Producto[]) => {
            this.cursorDefault();
            this.productos = productos;
        },
        error => {
            this.cursorDefault();
        }
    );
  }
  
  //ACCIONES    
  onNotifyDelete(producto: Producto):void {
    this.deleteProducto(producto);
  }
    
  public deleteProducto(producto: Producto) {
    this.beforeSubmitList();
    this.facturaService.deleteProducto(producto.id).subscribe(
        (rtaProducto: Producto) => {
            this.showSuccessList(true, 'Producto eliminado correctamente');
            //ELIMINO DE LA LISTA      
            this.productos.forEach(function(productoFor, index, object) {
                if(productoFor.id == producto.id) {
                    object.splice(index, 1);
                }
            });
            //this.factura.actualizarMonto(rtaProducto.factura.monto, rtaProducto.factura.monto_retencion);
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }
  
    
  //FORMULARIO ALTA/EDICION
  //Para el Form
  public newProducto(){
    this.producto = new Producto();
    this.producto.factura = this.factura;
  }
  public updateProducto(producto: Producto) {
    this.producto = new Producto(producto);
  }
  
  //Desde el formulario
  public actualizeForm(producto: Producto): void {
    let exists= false;
    //this.factura.actualizarMonto(producto.factura.monto, producto.factura.monto_retencion);
    this.productos.forEach(function(productoFor, index, object) {
        if(productoFor.id == producto.id) {
            object[index]= producto;
            exists= true;
        }
    });
    if(!exists){
        this.productos.splice(0, 0, producto);
    }
    this.cancelForm();
  }    
  public cancelForm(event?): void{
    this.producto = null;
  }
  
}