import { Component, OnInit } from '@angular/core';
import Sort from 'app/models/Sort';
import { User } from 'app/models/user';
import { Cuenta } from 'app/module-cuenta/models';
import { DistritoService } from 'app/module-distrito/distrito.service';
import { Distrito } from 'app/module-distrito/models';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
import { GenericFormComponent } from 'app/module-generic/components/generic-form.component';
import { GlobalVars } from 'app/services/globalVars';
import { MessageService } from 'app/services/message.service';
import { ProjectUtils } from 'app/services/utils';

@Component({
  selector: 'reposicion',
  templateUrl: './reposicion.component.html',
  styleUrls: ['./reposicion.component.sass']
})
export class ReposicionComponent extends GenericFormComponent implements OnInit {

  public actualUser: User;
    
  public params = {page: 1, per_page: 10, excel: 0};

  public sortOptions: Sort[] = [
    { key: 'fecha', order: 'desc', description: 'Fecha' }
  ];

  public distrito_id_selected: number;
  
  public distritos: Distrito[] = [];
  
  public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Reposicion caja chica', active: true }];

  public cuenta: Cuenta;
  public loading: boolean;

  public showBalance: boolean = false;

    
  constructor(protected messageService: MessageService, private globalVars: GlobalVars, 
      private distritoService: DistritoService, private projectUtils: ProjectUtils) {
    super(messageService);
  }

  ngOnInit() {
    this.actualUser = this.globalVars.actualUser;
    this.distrito_id_selected = this.globalVars.getActualDistrito();
    this.loadAccount();
    this.distritoService.getDistritos({per_page: 1000}, {sort: 'nombre'}).subscribe(
        (rta: any) => {
            this.distritos = rta.data;
        }
    );
    this.showBalance = this.projectUtils.getShowBalanceConfig();
  }

  public loadAccount(){
    this.cursorWait();
    this.loading = true;
    this.cuenta = null;
    this.distritoService.getCuentaCajaChicaDistrito(this.distrito_id_selected).subscribe(
      (response: any) => {
        this.cursorDefault();
        this.cuenta = response.id ? response : null;
        this.loading = false;
      },
      error => {
        this.cuenta = null;
        this.cursorDefault();
        this.loading = false;
      }
    );
  }

  public onAddMov() {
    this.loadAccount();
  }

  public toggleConfigBlance(): void {
    this.showBalance = !this.showBalance;
    this.projectUtils.setShowBalanceConfig(this.showBalance);
  }
}