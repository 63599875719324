import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Sort from 'app/models/Sort';
import { CuentaCommunicate } from 'app/module-cuenta/cuenta.communicate';
import { CuentaService } from 'app/module-cuenta/cuenta.service';
import { Cheque, Cuenta, MetodoDePago, Movimiento } from 'app/module-cuenta/models';
import { GenericFormComponent } from 'app/module-generic/components/generic-form.component';
import { MessageService } from 'app/services/message.service';
import { PdfService } from 'app/services/pdf.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'reposicion-movimientos',
  templateUrl: './movimientos.component.html',
  styleUrls: ['./movimientos.component.sass']
})
export class MovimientosComponent extends GenericFormComponent implements OnInit {
  @Input() cuenta: Cuenta;

  public movimientos: Movimiento[];
  public params = { page: 1, per_page: 10, excel: 0};
  public sortOptions: Sort[] = [
    { key: 'fecha_contable', order: 'desc', description: 'Fecha creación (Descendente)' },
    { key: 'fecha_contable', order: 'asc', description: 'Fecha creación (Ascendente)' },
  ]
  public filtro = {tipo: null, metodo_pago_id: null, fecha_contable_gteq: null, fecha_contable_lteq: null, sort: this.sortOptions[0] };

  public metodos: MetodoDePago[] = [];

  public fechas = null;

  public BANK_LOGO_IDS = environment.bank_logo_ids;

  constructor(protected messageService: MessageService, private cuentaService: CuentaService, private pdfService: PdfService,
    private cuentaCommunicate: CuentaCommunicate, private route: ActivatedRoute) {
    super(messageService);
  }

  ngOnInit() {
    this.filter();
    this.cuentaService.getMetodosDePago({ per_page: 1000 }, { sort: 'nombre' }).subscribe(
      (rta: any) => {
        this.metodos = rta.data.filter(m => {
          return m.tipo == 'TRANSFERENCIA' || m.tipo == 'CHEQUE';
        });
      }
    );
  }

  public filter(page?, event?) {
    this.route.queryParams.subscribe(params => {
      this.filtro.tipo = 'ingreso';

      if (this.fechas != 'fechacambiado') {
        if ((params['fecha_gteq'] !== undefined) &&
          (params['fecha_gteq'] != null) && (params['fecha_gteq'] != "undefined")) {
          this.filtro.fecha_contable_gteq = params['fecha_gteq'];
        }

        if ((params['fecha_lteq'] !== undefined) &&
          (params['fecha_lteq'] != null) && (params['fecha_lteq'] != "undefined")) {
          this.filtro.fecha_contable_gteq = params['fecha_lteq'];
        }
      } else {
        this.fechas = null;
      }

    });
    if (typeof page == 'object') {
      this.params.page = page.page || this.params.page;
      this.params.per_page = page.per_page || this.params.per_page;
    } else {
      this.params.page = page || this.params.page;
    }

    this.cursorWait();
    this.cuentaService.getMovimientosCuenta(this.cuenta.id, this.params, this.filtro).subscribe(
      (rta: any) => {
        this.cursorDefault();
        this.movimientos = rta.data;
        rta.info = {
          page: rta.current_page,
          countOfPages: rta.last_page,
          count: rta.total
        }
        this.assemblePager(rta.info);
      },
      error => {
        this.cursorDefault();
      }
    );
  }

  public changeFilterDate(value) {
    this.filtro.fecha_contable_gteq = value.from;
    this.filtro.fecha_contable_lteq = value.to;
    this.fechas = 'fechacambiado';
    this.filter(1);
  }

  public pdfMovimientos(excel: number) {
    this.cursorWait();
    this.params.excel = excel;
    this.cuentaService.pdfCajaChica(this.cuenta.id, this.params, this.filtro).subscribe(
      rta => {
        this.cursorDefault();
        if (excel) {
          this.pdfService.loadExcel("caja_chica", rta);
        } else {
          this.pdfService.loadPdf("caja_chica", rta);
        }
      },
      error => {
        this.cursorDefault();
        alert('Ups! No pudimos imprimir el pdf');
      }
    );
  }

  public detalleCheque(cheque: Cheque) {
    this.cuentaCommunicate.chequeModalPopUp(cheque);
  }

}
