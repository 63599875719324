import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule }   from '@angular/forms';

import {GenericModule} from '../module-generic/generic.module';
import {TranslateComponentModule} from '../translate-module/translate-component.module';

import {FacturaService} from './factura.service';
import {FacturaCommunicate} from './factura.communicate';

import {FacturasVentasComponent} from './facturas/facturas.component';
import {FacturasVentasFormComponent} from './facturas_form/facturas_form.component';
import {ProductosComponent} from './productos/productos.component';
import {ProductosFormComponent} from './productos_form/productos_form.component';

import {FacturasVentasModalComponent} from './facturas_modal/facturas_modal.component';
import {FacturaVentaModalComponent} from './factura_modal/factura_modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    
    GenericModule,
    TranslateComponentModule,
  ],
  declarations: [
    FacturasVentasComponent,
    FacturasVentasFormComponent,
    ProductosComponent,
    ProductosFormComponent,
    FacturasVentasModalComponent,
    FacturaVentaModalComponent
  ],
  providers: [
    FacturaService,
    FacturaCommunicate
  ],
  exports: [
    FacturasVentasComponent,
    FacturasVentasFormComponent,
    
    FacturasVentasModalComponent,
    FacturaVentaModalComponent
  ]
})
export class FacturaVentaModule { }
