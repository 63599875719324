<div class="container-fluid">    
    <div class="row">
        <div class="card">
            <div class="card-content">
                <breadcrumb [items]="breadcrumbItems"></breadcrumb>

                <filters-header-section (onFilter)="filter(1)" [advancedFilters]="true" [someFilterApllied]="someFiltersApplied" (onClear)="resetFilters()">
                    <div class="col-md-12">
                        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                            <label for="search">Descripción:</label>
                            <div class="input-group filter-input-group">
                                <input class="form-control" type="text" placeholder="Recibos" name="search" [(ngModel)]="filtro.or_descripcion_matches">
                                <span class="input-group-addon" (click)="filter(1)"><i class="cmp-i busqueda i-sm"></i></span>
                            </div>
                        </div>
                                            
                        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                            <label for="search">Distrito:</label>
                            <select class="form-control filter-form-control col-md-12" data-style="btn btn-wd btn-block" name="distrito_id"  [(ngModel)]="filtro.distrito_id_eq" (change)="filter(1)" [disabled]="! actualUser.multi_distrito">
                                <option [ngValue]="null">Todos</option>
                                <option *ngFor="let distrito of distritos" [ngValue]="distrito.id">{{distrito.nombre}}</option>
                            </select>
                        </div>                
                    </div>

                
                    <div moreFilters>
                        <div class="col-md-12" style="margin-top: 10px;">
                            <!-- <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                            <label for="proveedor_id">Proveedore:</label>
                                <select #fac_select_proveedores class="form-control filter-form-control selectpicker custom-live-search" data-live-search="true" data-style="btn btn-wd btn-block" name="proveedor_id" [(ngModel)]="filtro.proveedor_id_eq" (change)="filter(1)">
                                    <option [ngValue]="null">Todos</option>
                                    <option *ngFor="let proveedor of proveedores" [ngValue]="proveedor.id">{{proveedor.nombre}}</option>
                                </select>
                            </div> -->                                                
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="punto_venta">Punto de Venta:</label>
                                <div class="form-group ordenar filter-form-control">
                                    <input type="text" class="form-control" placeholder="Punto de Venta" name="punto_venta" [(ngModel)]="filtro.punto_venta_eq">
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="numero">Nro Comprobante:</label>
                                <div class="form-group ordenar filter-form-control">
                                    <input type="text" class="form-control" placeholder="Nro Comprobante" name="numero" [(ngModel)]="filtro.nro_comprobante_eq">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <generic_period (outDate)="changeFilterDate($event)" [fromDate]="filtro.fecha_gteq" [toDate]="filtro.fecha_lteq" [enableCleanOption]="true"></generic_period>
                        </div> 
                        <div class="col-md-12">
                            <!-- <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="imputacion_contable_id">Imputaciones:</label>
                                <select #fac_select_imputaciones class="form-control filter-form-control selectpicker custom-live-search" data-live-search="true" data-style="btn btn-wd btn-block" name="imputacion_contable_id" [(ngModel)]="filtro.imputacion_contable_id_eq" (change)="filter(1)">
                                    <option [ngValue]="null">Todas</option>
                                    <option *ngFor="let imputacion of imputacionesContables" [ngValue]="imputacion.id">{{imputacion.nombre}}</option>
                                </select>
                            </div> -->
                            <!-- <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="tipo">Tipo:</label>
                                <select class="form-control filter-form-control" data-style="btn btn-wd btn-block" name="tipo" [(ngModel)]="filtro.tipo_eq" (change)="filter(1)">
                                    <option [ngValue]="null">Todos</option>
                                    <option *ngFor="let tipo of tipos" [ngValue]="tipo">{{tipo | translate: "TIPO_recibo"}}</option>
                                </select>
                            </div> -->
                            <!-- <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="estado">Estado:</label>
                                <select class="form-control filter-form-control" data-style="btn btn-wd btn-block" name="estado" [(ngModel)]="filtro.estado_eq" (change)="filter(1)">
                                    <option [ngValue]="null">Todos</option>
                                    <option *ngFor="let estado of estados" [ngValue]="estado">{{estado | translate: "recibo_EST"}}</option>
                                </select>
                            </div> -->
                        </div>  
                    </div>
                                    
                                    
                </filters-header-section> 

                <div class="row mt-2">
                    <div class="col-sm-10">
                        <!-- <button class="btn btn-simple btn-icon btn-add" [routerLink]="['/recibos/new']" title="Agregar"><i class="cmp-i agregar"></i> Agregar</button> -->
                    </div>  
                    <div class="col-sm-2">
                        <sort-select [options]="sortOptions" [(sortModel)]="filtro.sort" (onSelect)="filter(1)"></sort-select>
                    </div>
                </div>     

                <div class="row">
                    <div class="col-md-12 table-responsive">
                        <table class="table table-hover">
                            <thead class="text-primary">
                                <tr>
                                    <th><b>Nro Comprobante</b></th>
                                    <th><b>Descripción</b></th>
                                    <th><b>Fecha</b></th>
                                    <th><b>Fecha Vto Pago</b></th>
                                    <th><b>Fecha Serv Desde</b></th>
                                    <th><b>Fecha Serv Hasta</b></th>
                                    <th><b>Monto</b></th>
                                    <th><b>Estado</b></th>
                                    <th><b>Acciones</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let recibo of recibos'>
                                    <td>
                                        <!-- <a [routerLink]="['/recibo', recibo.id]" rel="tooltip" title="Editar recibo"> -->
                                            <span *ngIf="recibo.punto_venta">{{recibo.punto_venta}}</span>
                                            <span *ngIf="!recibo.punto_venta">-</span>
                                            <span *ngIf="recibo.nro_comprobante">{{recibo.nro_comprobante}}</span>
                                            <span *ngIf="!recibo.nro_comprobante">-</span>
                                        <!-- </a> -->
                                    </td>
                                    <td>{{recibo.descripcion}}</td>
                                    <td>{{recibo.fecha | date: 'dd/MM/yyyy'}}</td>
                                    <td>{{recibo.fecha_vto_pago | date: 'dd/MM/yyyy'}}</td>
                                    <td>{{recibo.fecha_serv_desde | date: 'dd/MM/yyyy'}}</td>
                                    <td>{{recibo.fecha_serv_hasta | date: 'dd/MM/yyyy'}}</td>
                                    <td>$ {{recibo.monto | number: '1.2-2'}}</td>
                                    <td>{{recibo.estado | translate: "RECIBO_EST"}}</td>
                                    <td class="td-actions text-right">
                                        <a [routerLink]="['/ordenesventas', recibo.orden_venta_general_id]" rel="tooltip" title="Ver Recibo" class="btn btn-primary btn-simple btn-xs btn-cmp-i">
                                            <i class="cmp-i editar i-sm"></i>
                                        </a>
                                        <!-- <a [routerLink]="['/recibos', recibo.id]" rel="tooltip" title="Editar recibo" class="btn btn-primary btn-simple btn-xs btn-cmp-i">
                                            <i class="cmp-i editar i-sm"></i>
                                        </a> -->
                                        <!-- <button type="button" rel="tooltip" title="Eliminar recibo" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="recibo" confirm-message="¿Está seguro que desea eliminar la recibo?">
                                            <i class="cmp-i eliminar-borrar i-sm"></i>
                                        </button> -->
                                    </td>
                                </tr>
                                <tr *ngIf="recibos != null && recibos.length == 0" class="text-center">
                                    <td colspan="10">No se encontraron recibos</td>
                                </tr>
                            </tbody>
                        </table>                 
                        <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)" *ngIf="recibos != null && recibos.length > 0"></pager>
                    </div>
                </div>
            </div>
        </div>          
    </div>
</div>