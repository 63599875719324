import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule }   from '@angular/forms';

//GENERALES
import {GenericFormComponent} from './components/generic-form.component';
import {PagerComponent} from './components/pager/pager.component';
import {GenericPeriodComponent} from './components/generic_period/generic_period.component';

//GENERALES
import { ConfirmClickDirective }   from './directives/confirm_click.directive';
import { FiltersHeaderSectionComponent } from './components/filters-header-section/filters-header-section.component';
import { SortSelectComponent } from './components/sort-select/sort-select.component';
import { SortSelectStructuredComponent } from './components/sort-select-structured/sort-select-structured.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { InputDatepickerComponent } from './components/input-datepicker/input-datepicker.component';
import { CuitFormatPipe } from './pipes/cuit.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomBtnLoaderLoaderComponent } from './components/custom-btn-loader/custom-btn-loader.component';
import { ProviderSelectComponent } from './components/provider-select/provider-select.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [
    GenericFormComponent,
    PagerComponent,
    GenericPeriodComponent,
    ConfirmClickDirective,
    FiltersHeaderSectionComponent,
    SortSelectComponent,
    SortSelectStructuredComponent,
    BreadcrumbComponent,
    InputDatepickerComponent,
    CuitFormatPipe,
    CustomBtnLoaderLoaderComponent,
    ProviderSelectComponent
  ],
  exports: [
    PagerComponent,
    GenericPeriodComponent,
    ConfirmClickDirective,
    FiltersHeaderSectionComponent,
    SortSelectComponent,
    SortSelectStructuredComponent,
    BreadcrumbComponent,
    InputDatepickerComponent,
    CuitFormatPipe,
    NgSelectModule,
    CustomBtnLoaderLoaderComponent,
    ProviderSelectComponent
  ]
})
export class GenericModule { }
