<div class="row">
    <div class="col-sm-12 col-md-offset-1 col-md-10">
        <div class="card">
            <div class="card-content">
                <h4 class="card-title">Transporte<i class="ti-car ml-1"></i></h4>
                <hr>
                <div class="row">
                    <div class="col-md-12">
                        <items_viatico_form *ngIf="itemK" [item]="itemK" [periodo]="viatico.periodo" [viatico]="viatico" tipo="K" (actualize)="actualizeForm($event)"></items_viatico_form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-12 col-md-offset-1 col-md-10">
        <div class="card">
            <div class="card-content">
                <h4 class="card-title">Hotelería<i class="ti-home ml-1"></i></h4>
                <hr>
                <div class="row">
                    <div class="col-md-12">
                        <items_viatico_form *ngIf="itemH" [item]="itemH" [periodo]="viatico.periodo" [viatico]="viatico" tipo="H" (actualize)="actualizeForm($event)"></items_viatico_form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-12 col-md-offset-1 col-md-10">
        <div class="card">
            <div class="card-content">
                <h4 class="card-title">Comida<i class="ti-pie-chart ml-1"></i></h4>
                <hr>
                <div class="row">
                    <div class="col-md-12">
                        <items_viatico_form *ngIf="itemC" [item]="itemC" [periodo]="viatico.periodo" [viatico]="viatico" tipo="C" (actualize)="actualizeForm($event)"></items_viatico_form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-12 col-md-offset-1 col-md-10">
        <div class="card">
            <div class="card-content">
                <h4 class="card-title">Manual<i class="ti-layout-list-thumb ml-1"></i></h4>
                <hr>
                <div class="row">
                    <div class="col-md-12">
                        <items_viatico_form *ngIf="itemM" [item]="itemM" [periodo]="viatico.periodo" [viatico]="viatico" tipo="M" (actualize)="actualizeForm($event)"></items_viatico_form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>