<div class="container-fluid">
    <div class="row" *ngIf="user.hasAtLeastOneRolByName(['tesorero', 'admin_sistema', 'administrativo'])">
        <div class="row">
            <div class="col-md-offset-9 col-xs-3">
                <div class="text-center mt-2">
                    <div (click)="toggleConfigBlance()" title="Mostrar/ocultar balance" style="cursor: pointer;">
                        <i *ngIf="!showBalance" class="cmp-i ocultar i-md" style="vertical-align: middle; margin-bottom: 3px;"></i>     
                        <i *ngIf="showBalance" class="cmp-i ver i-md" style="vertical-align: middle; margin-bottom: 3px;"></i>  
                        {{showBalance ? 'Ocultar' : 'Ver'}} Saldos
                    </div>
                </div>
            </div>
        </div>
        <div class="row " *ngIf="!loading">
            <a [routerLink]="['/cuentas', dashboard.caja_chica_id]" [queryParams]="{distrito_id: params.distrito_id, fecha_lteq: params.fecha_lteq, fecha_gteq: params.fecha_gteq }" rel="tooltip" title="Saldo Caja Chica">
                <div class="col-lg-4 col-lg-offset-4 col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3">
                    <div class="card card-hover">
                        <div class="card-content cursor-pointer">
                            <div class="row">
                                <div class="col-xs-5">
                                    <div class="text-center">
                                        <i class="cmp-i saldo" style="width: 52px; background-color: #AB9ACA;"></i>
                                    </div>
                                </div>
                                <div class="col-xs-7">
                                    <div class="numbers">
                                        <p>Saldo Caja Chica</p>
                                        {{showBalance ? (dashboard.caja_chica == '-' ? dashboard.caja_chica : dashboard.caja_chica | number: '1.2-2') : '****'}}  
                                        <i *ngIf="!showBalance" class="cmp-i ocultar" style="vertical-align: top; margin-top: 3px;"></i>                                  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>

    <div class="row" *ngIf="user.hasAtLeastOneRolByName(['tesorero', 'admin_sistema', 'administrativo'])">
        <div class="col-md-offset-1 col-md-10">
            <div class="card">
                <div class="card-content">
                    <div class="row">
                        <div class="col-md-12">
                            <generic_period (outDate)="changeFilterDate($event)" defaultPeriod="actualMonth"></generic_period>
                        </div>   
                    </div>                
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="!loading && user.hasAtLeastOneRolByName(['tesorero', 'admin_sistema', 'administrativo'])">
        <h4><i class="cmp-i facturas i-md mr-1"></i>Facturas</h4>
        <hr>
        
        <div class="row">
            <a [routerLink]="['/facturas']" [queryParams]="{distrito_id: params.distrito_id, fecha_lteq: params.fecha_lteq, fecha_gteq: params.fecha_gteq}" rel="tooltip" title="Facturas Creadas">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="card card-hover" [ngClass]="{'home-card-border home-card-sucess' : (dashboard.facturas_creadas != '-' && dashboard.facturas_creadas > 0), 'home-card-disabled': (dashboard.facturas_creadas == '-' || dashboard.facturas_creadas == 0)}">
                        <div class="card-content cursor-pointer home-card">
                            <div class="row">
                                <div class="col-xs-5 icon-big text-center">
                                    <b>{{dashboard.facturas_creadas}}</b>
                                </div>
                                <div class="col-xs-7">
                                    <div class="home-card-title">
                                        <p>Creadas</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>

            <a [routerLink]="['/facturas']" [queryParams]="{distrito_id: params.distrito_id, fecha_lteq: params.fecha_lteq, fecha_gteq: params.fecha_gteq, estado: 'pagadas' }" rel="tooltip" title="Facturas Pagas">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="card card-hover" [ngClass]="{'home-card-border home-card-sucess' : (dashboard.facturas_pagadas != '-' && dashboard.facturas_pagadas > 0), 'home-card-disabled': (dashboard.facturas_pagadas == '-' || dashboard.facturas_pagadas == 0)}">
                        <div class="card-content cursor-pointer home-card">
                            <div class="row">
                                <div class="col-xs-5 icon-big text-center">
                                    <b>{{dashboard.facturas_pagadas}}</b>
                                </div>
                                <div class="col-xs-7">
                                    <div class="home-card-title">
                                        <p>Pagas</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>

            <a [routerLink]="['/facturas']" [queryParams]="{distrito_id: params.distrito_id, fecha_lteq: params.fecha_lteq, fecha_gteq: params.fecha_gteq, estado: 'pagoparcial' }" rel="tooltip" title="Facturas Pagos Parciales">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="card card-hover" [ngClass]="{'home-card-border home-card-warning' : (dashboard.facturas_pago_parcial != '-' && dashboard.facturas_pago_parcial > 0), 'home-card-disabled': (dashboard.facturas_pago_parcial == '-' || dashboard.facturas_pago_parcial == 0)}">
                        <div class="card-content cursor-pointer home-card">
                            <div class="row">
                                <div class="col-xs-5 icon-big text-center">
                                    <b>{{dashboard.facturas_pago_parcial}}</b>
                                </div>
                                <div class="col-xs-7">
                                    <div class="home-card-title">
                                        <p>Pagos Parciales</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>

            <a [routerLink]="['/facturas']" [queryParams]="{distrito_id: params.distrito_id, fecha_lteq: params.fecha_lteq, fecha_gteq: params.fecha_gteq, estado: 'sinpagos' }" rel="tooltip" title="Facturas Impagas">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="card card-hover" [ngClass]="{'home-card-border home-card-danger' : (dashboard.facturas_sin_pagos != '-' && dashboard.facturas_sin_pagos > 0), 'home-card-disabled': (dashboard.facturas_sin_pagos == '-' || dashboard.facturas_sin_pagos == 0)}">
                        <div class="card-content cursor-pointer home-card">
                            <div class="row">
                                <div class="col-xs-5 icon-big text-center">
                                    <b>{{dashboard.facturas_sin_pagos}}</b>
                                </div>
                                <div class="col-xs-7">
                                    <div class="home-card-title">
                                        <p>Impagas</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>

        <h4><i class="cmp-i ordenes-pago i-md mr-1"></i>Ordenes</h4>
        <hr>

        <div class="row">
            <a [routerLink]="['/ordenespago']" [queryParams]="{distrito_id: params.distrito_id, fecha_lteq: params.fecha_lteq, fecha_gteq: params.fecha_gteq, estado: 'creadas' }" rel="tooltip" title="Órdenes Creadas">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="card card-hover" [ngClass]="{'home-card-border home-card-sucess' : (dashboard.ordenes_creadas != '-' && dashboard.ordenes_creadas > 0), 'home-card-disabled': (dashboard.ordenes_creadas == '-' || dashboard.ordenes_creadas == 0)}">
                        <div class="card-content cursor-pointer home-card">
                            <div class="row">
                                <div class="col-xs-5 icon-big text-center">
                                    <b>{{dashboard.ordenes_creadas}}</b>
                                </div>
                                <div class="col-xs-7">
                                    <div class="home-card-title">
                                        <p>Creadas</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>

            <a [routerLink]="['/ordenespago']" [queryParams]="{distrito_id: params.distrito_id, fecha_lteq: params.fecha_lteq, fecha_gteq: params.fecha_gteq, estado: 'abiertas' }" rel="tooltip" title="Órdenes Abiertas">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="card card-hover" [ngClass]="{'home-card-border home-card-warning' : (dashboard.ordenes_abiertas != '-' && dashboard.ordenes_abiertas > 0), 'home-card-disabled': (dashboard.ordenes_abiertas == '-' || dashboard.ordenes_abiertas == 0)}">
                        <div class="card-content cursor-pointer home-card">
                            <div class="row">
                                <div class="col-xs-5 icon-big text-center">
                                    <b>{{dashboard.ordenes_abiertas}}</b>
                                </div>
                                <div class="col-xs-7">
                                    <div class="home-card-title">
                                        <p>Abiertas</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
            <a [routerLink]="['/ordenespago']" [queryParams]="{distrito_id: params.distrito_id, fecha_lteq: params.fecha_lteq, fecha_gteq: params.fecha_gteq, estado: 'revision' }" rel="tooltip" title="Órdenes en Revisión">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="card card-hover" [ngClass]="{'home-card-border home-card-danger' : (dashboard.ordenes_revision != '-' && dashboard.ordenes_revision > 0), 'home-card-disabled': (dashboard.ordenes_revision == '-' || dashboard.ordenes_revision == 0)}">
                        <div class="card-content cursor-pointer home-card">
                            <div class="row">
                                <div class="col-xs-5 icon-big text-center">
                                    <b>{{dashboard.ordenes_revision}}</b>
                                </div>
                                <div class="col-xs-7">
                                    <div class="home-card-title">
                                        <p>En Revisión</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
            <a [routerLink]="['/ordenespago']" [queryParams]="{distrito_id: params.distrito_id, fecha_lteq: params.fecha_lteq, fecha_gteq: params.fecha_gteq, estado: 'sinmovimientos' }" rel="tooltip" title="Órdenes sin Movimientos">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="card card-hover" [ngClass]="{'home-card-border home-card-danger' : (dashboard.ordenes_sin_movimientos != '-' && dashboard.ordenes_sin_movimientos > 0), 'home-card-disabled': (dashboard.ordenes_sin_movimientos == '-' || dashboard.ordenes_sin_movimientos == 0)}">
                        <div class="card-content cursor-pointer home-card">
                            <div class="row">
                                <div class="col-xs-5 icon-big text-center">
                                    <b>{{dashboard.ordenes_sin_movimientos}}</b>
                                </div>
                                <div class="col-xs-7">
                                    <div class="home-card-title">
                                        <p>Sin Movimientos</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
            <a [routerLink]="['/ordenespago']" [queryParams]="{distrito_id: params.distrito_id, fecha_lteq: params.fecha_lteq, fecha_gteq: params.fecha_gteq, estado: 'sinverificar' }" rel="tooltip" title="Órdenes sin Verificar">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="card card-hover" [ngClass]="{'home-card-border home-card-danger' : (dashboard.ordenes_sin_verificar != '-' && dashboard.ordenes_sin_verificar > 0), 'home-card-disabled': (dashboard.ordenes_sin_verificar == '-' || dashboard.ordenes_sin_verificar == 0)}">
                        <div class="card-content cursor-pointer home-card">
                            <div class="row">
                                <div class="col-xs-5 icon-big text-center">
                                    <b>{{dashboard.ordenes_sin_verificar}}</b>
                                </div>
                                <div class="col-xs-7">
                                    <div class="home-card-title">
                                        <p>Sin Verificar</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>

        <h4><i class="cmp-i otros i-md mr-1"></i>Otros</h4>
        <hr>
        <div class="row">
            <a rel="tooltip" title="Reposiciones Caja Chica">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="card" [ngClass]="{'home-card-border home-card-sucess' : (dashboard.proveedores_creados != '-' && dashboard.proveedores_creados > 0), 'home-card-disabled': (dashboard.proveedores_creados == '-' || dashboard.proveedores_creados == 0)}">
                        <div class="card-content home-card">
                            <div class="row">
                                <div class="col-xs-5 icon-big text-center">
                                    <b>{{dashboard.proveedores_creados}}</b>
                                </div>
                                <div class="col-xs-7">
                                    <div class="home-card-title">
                                        <p>Proveedores Creados</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>

            <a [routerLink]="['/reposicion']" rel="tooltip" title="Reposiciones Caja Chica">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="card card-hover" [ngClass]="{'home-card-border home-card-warning' : (dashboard.cantidad_reposicion != '-' && dashboard.cantidad_reposicion > 0), 'home-card-disabled': (dashboard.cantidad_reposicion == '-' || dashboard.cantidad_reposicion == 0)}">
                        <div class="card-content cursor-pointer home-card">
                            <div class="row">
                                <div class="col-xs-5 icon-big text-center">
                                    <b>{{dashboard.cantidad_reposicion}}</b>
                                </div>
                                <div class="col-xs-7">
                                    <div class="home-card-title">
                                        <p>Reposiciones Caja Chica</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>

        </div>
    </div>

    <hr>

    <div class="mt-2" style="display: flex; float: right;">
        <div class="dropup" *ngIf="user.hasAtLeastOneRolByName(['admin_sistema'])">
            <a class="dropdown-toggle" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Panel de Administración
                <span class="caret"></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                <li *ngFor="let item of menuAdm">
                    <a (click)="moveTo('admin', item.path)" class="cursor-pointer">
                        {{item.title}}
                    </a>
                </li>
            </ul>
        </div>
    
        <div class="dropup ml-2">
            <a class="dropdown-toggle" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Panel de Operaciones
                <span class="caret"></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                <li *ngFor="let item of menuOps">
                    <a (click)="moveTo('operations', item.path)" class="cursor-pointer">
                        {{item.title}}
                    </a>
                </li>
            </ul>
        </div>
    
        <div class="dropup ml-2" *ngIf="user.hasAtLeastOneRolByName(['tesorero', 'administrativo', 'auditor','admin_sistema']) && params.distrito_id != 11">
            <a class="dropdown-toggle" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Panel de Ventas
                <span class="caret"></span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                <li *ngFor="let item of menuOps">
                    <a (click)="moveTo('sells', item.path)" class="cursor-pointer">
                        {{item.title}}
                    </a>
                </li>
            </ul>
        </div>
    </div>

</div>
