<div class="row text-right mr-2">
    <b>Ordenar por:</b>
    <a href="#" data-toggle="dropdown">
        <p>{{options[sortModel]}} <i class="ti-angle-down"></i></p>
    </a>
    <ul class="dropdown-menu">
        <li *ngFor="let optionKey of optionKeys">
            <a (click)="onSelectLocal(optionKey)"><i class="ti-check" *ngIf="sortModel == optionKey"></i>{{options[optionKey]}}</a>
        </li>
    </ul>
</div>
