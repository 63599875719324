
export class Config{
    public id: number;
    public modulo: number;
    public redondeo: number;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.modulo = json.modulo;
            this.redondeo = json.redondeo;
        }
    }
}