
import {first} from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild, NgZone } from '@angular/core';

import {ProveedorService} from '../proveedor.service';
import { MessageService } from '../../services/message.service';

import {Imputacion, Proveedor, ProveedorImputacion} from '../models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

declare var $: any;

@Component({
  selector: 'imputaciones_proveedor_form',
  templateUrl: 'imputaciones_proveedor_form.html'
})
export class ImputacionesProveedorFormComponent extends GenericFormComponent implements OnInit, AfterViewInit{
    @ViewChild('firstElementImputacion', { static: true }) firstElement: ElementRef;
    
    @Input() proveedor: Proveedor;
    @Input() provImpu: ProveedorImputacion = new ProveedorImputacion();
    
    public imputaciones: Imputacion[] = [];
    
    @Output() actualize: EventEmitter<ProveedorImputacion> = new EventEmitter<ProveedorImputacion>();
    @Output() cancel: EventEmitter<string> = new EventEmitter<string>();
    
    constructor(protected messageService: MessageService, private proveedorService: ProveedorService, private ngZone: NgZone) {
        super(messageService);
    }

    ngOnInit(): void {
        this.proveedorService.getImputaciones({per_page: 1000}, {sort: 'nombre'}).subscribe(
            rta => {
                this.imputaciones = rta.data;
                this.ngZone.onStable.pipe(first()).subscribe(() => {
                    $(this.firstElement.nativeElement).selectpicker(); 
                });
            }
        );
    }
    ngAfterViewInit(): void {
        this.firstElement.nativeElement.focus();
    }
    
    public save(event){
        this.beforeSubmit(event);
        if(!this.provImpu.id){
            this.proveedorService.createImputacionProveedor(this.proveedor, this.provImpu).subscribe(
                (provImpu: ProveedorImputacion) => {
                    this.showSuccess(true, 'Imputación asignada correctamente');
                    this.provImpu = provImpu;
                    this.actualize.emit(this.provImpu);             
                },err => {
                    this.showError(err, true);
                });
        }else{
            this.proveedorService.updateImputacionProveedor(this.proveedor, this.provImpu).subscribe(
                (provImpu: ProveedorImputacion) => {
                    this.showSuccess(true, 'Imputación modificada correctamente');  
                    this.provImpu = provImpu;
                    this.actualize.emit(this.provImpu);  
                },err => {
                    this.showError(err, true);
                });
        }
    }
    
    public cancelForm($event?){
        this.cancel.emit("Cancelación");
    }
    
    //
    //UTILS
    public customCompareImputacion(i1: Imputacion, i2: Imputacion) {
        if(i1 && i2){
            return i1.id == i2.id;
        }else{
            return i1 == i2;
        }
    }
}