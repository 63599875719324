import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'custom-btn-loader',
  templateUrl: './custom-btn-loader.component.html',
  styleUrls: ['./custom-btn-loader.component.scss']
})
export class CustomBtnLoaderLoaderComponent {
  @Input() loading: boolean = false;
  @Input() disabled: boolean = false;
  @Input() text: string = "";
  @Input() icon: string = "";
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  @Input() btnClass: string = "";

  public onClick() {
    this.action.emit();
  }
}
