import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Reporte } from 'app/module-reportes/reportes.models';

@Component({
  selector: 'report-menu-item',
  templateUrl: './report-menu-item.component.html',
  styleUrls: ['./report-menu-item.component.scss']
})
export class ReportMenuItemComponent {
  @Input() report: Reporte;

  constructor(private router: Router) { }

  public redirectTo(reportId: string) {
    this.router.navigate([`/reportes/${reportId}`]);
  }
}
