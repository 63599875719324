import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';

//import 'rxjs/add/operator/toPromise';
import { Observable ,  Subject } from 'rxjs';
// Import RxJs required methods
import { map, catchError } from 'rxjs/operators';

import { GlobalVars } from '../services/globalVars';
import { Cache } from '../services/cache';

import {MetodoDePago, Cuenta, Chequera, Cheque, Movimiento, Banco} from './models';

@Injectable()
export class CuentaService{
    private cuentasUrl = 'cuentas';
    private movimientosUrl = 'movimientos';
    private chequerasUrl = 'chequeras';
    private chequesUrl = 'cheques';
    private metodosPagoUrl = 'metodos_pago';
    private bancosUrl = 'bancos';
    
    private reportesUrl = 'reportes/';

    constructor(private http: HttpClient, private globalVars: GlobalVars, private cache: Cache) {        
    }
    
    //
    //BANCOS
    getBancos(params?, filtro?): Observable<Banco[]> {
        const url = `${this.globalVars.apiHost}${this.bancosUrl}`;        
        return this.http.get<Banco[]>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((bancos: Banco[]) => {
                return bancos.map(item => new Banco(item));
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //CUENTAS
    getCuentas(params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.cuentasUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new Cuenta(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    getCuenta(id): Observable<Cuenta> {
        const url = `${this.globalVars.apiHost}${this.cuentasUrl}/${id}`;
        return this.http.get<Cuenta>(url, this.globalVars.getOptionsRequest()).pipe(
            map((cuenta: Cuenta) => {
                return new Cuenta(cuenta);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createCuenta(cuenta: Cuenta): Observable<Cuenta> {
        const url = `${this.globalVars.apiHost}${this.cuentasUrl}`;
        let body= JSON.parse(JSON.stringify(cuenta));
        body.distrito_id = cuenta.distrito ? cuenta.distrito.id : null;
        body.proveedor_id = cuenta.proveedor ? cuenta.proveedor.id : null;
        body.banco_id = cuenta.banco ? cuenta.banco.id : null;
        return this.http.post<Cuenta>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((cuenta: Cuenta) => {
                return new Cuenta(cuenta);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
     
    updateCuenta(cuenta: Cuenta): Observable<Cuenta> {
        const url = `${this.globalVars.apiHost}${this.cuentasUrl}/${cuenta.id}`;
        let body= JSON.parse(JSON.stringify(cuenta));
        body.distrito_id = cuenta.distrito ? cuenta.distrito.id : null;
        body.banco_id = cuenta.banco ? cuenta.banco.id : null;
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((cuenta: Cuenta) => {
                return new Cuenta(cuenta);
            }), catchError(this.globalVars.tratarErrores)
        );
    }    
      
    deleteCuenta(id: number): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.cuentasUrl}/${id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //ESTADOS DE CUENTA
    getEstadoCuenta(id: number, movDate: string): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.cuentasUrl}/${id}/estado`;
        
        let params = (movDate != null) ? { date : movDate } : null;
        return this.http.get<any>(url, this.globalVars.getOptionsRequest(params));
    }
    
    //
    //MOVIMIENTOS DE CUENTA
    getMovimientosCuenta(id: number, params: any, filtro: any): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.cuentasUrl}/${id}/movimientos`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrlV2(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta: any) => {
                rta.data = rta.data.map(item => new Movimiento(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //MOVIMIENTOS
    createMovimiento(movimiento: Movimiento): Observable<Movimiento> {
        const url = `${this.globalVars.apiHost}${this.movimientosUrl}`;
        let body= JSON.parse(JSON.stringify(movimiento));
        body.cuenta_id = movimiento.cuenta ? movimiento.cuenta.id : null;
        body.cuenta_destino_id = movimiento.cuentadestino ? movimiento.cuentadestino.id : -1;
        body.metodo_pago_id = movimiento.metodopago ? movimiento.metodopago.id : null;        
        if (movimiento.cheque && movimiento.metodopago.esCheque()) {
            body.cheque_id = movimiento.cheque.id;
        }
        return this.http.post<Movimiento>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((movimiento: Movimiento) => {
                return new Movimiento(movimiento);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    deleteMovimiento(id: number): Observable<Movimiento> {
        const url = `${this.globalVars.apiHost}${this.movimientosUrl}/${id}`;
        return this.http.delete<Movimiento>(url, this.globalVars.getOptionsRequest()).pipe(
            map((movimiento: Movimiento) => {
                return new Movimiento(movimiento);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //CHEQUERAS
    getChequeras(params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.chequerasUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new Chequera(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    getChequera(id): Observable<Chequera> {
        const url = `${this.globalVars.apiHost}${this.chequerasUrl}/${id}`;
        return this.http.get<Chequera>(url, this.globalVars.getOptionsRequest()).pipe(
            map((chequera: Chequera) => {
                return new Chequera(chequera);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createChequera(chequera: Chequera): Observable<Chequera> {
        const url = `${this.globalVars.apiHost}${this.chequerasUrl}`;
        let body= JSON.parse(JSON.stringify(chequera));
        body.cuenta_id = chequera.cuenta ? chequera.cuenta.id : null;
        return this.http.post<Chequera>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((chequera: Chequera) => {
                return new Chequera(chequera);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
     
    updateChequera(chequera: Chequera): Observable<Chequera> {
        const url = `${this.globalVars.apiHost}${this.chequerasUrl}/${chequera.id}`;
        let body= JSON.parse(JSON.stringify(chequera));
        delete body.cuenta;
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((chequera: Chequera) => {
                return new Chequera(chequera);
            }), catchError(this.globalVars.tratarErrores)
        );
    }    
      
    deleteChequera(id: number): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.chequerasUrl}/${id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //CHEQUES DE CHEQUERA
    getChequesChequera(id: number, params: any): Observable<Cheque[]> {
        const url = `${this.globalVars.apiHost}${this.chequerasUrl}/${id}/cheques`;        
        return this.http.get<Cheque[]>(url, this.globalVars.getOptionsRequest(params)).pipe(
            map((cheques: Cheque[]) => {
                return cheques.map(item => new Cheque(item));
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //CHEQUES
    getCheques(params: any, filtro: any): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.chequesUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta: any) => {
                rta.data = rta.data.map(item => new Cheque(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }


    getCheque(id: string): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.chequesUrl}/${id}`;        
        return this.http.get<any>(url).pipe(
            map((cheque: any) => {
                return new Cheque(cheque);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createCheque(cheque: Cheque): Observable<Cheque> {
        const url = `${this.globalVars.apiHost}${this.chequesUrl}`;
        let body= JSON.parse(JSON.stringify(cheque));
        body.chequera_id = cheque.chequera ? cheque.chequera.id : null;
        body.proveedor_id = cheque.proveedor ? cheque.proveedor.id : null;
        if (body.proveedor_id == null) {
            delete body.proveedor_id;
        }
        return this.http.post<Cheque>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((cheque: Cheque) => {
                return new Cheque(cheque);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
     
    updateCheque(cheque: Cheque): Observable<Cheque> {
        const url = `${this.globalVars.apiHost}${this.chequesUrl}/${cheque.id}`;
        let body= JSON.parse(JSON.stringify(cheque));
        delete body.cuenta;
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((cheque: Cheque) => {
                return new Cheque(cheque);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    deleteCheque(id: number): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.chequesUrl}/${id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //METODOS DE PAGO
    getMetodosDePago(params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.metodosPagoUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new MetodoDePago(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    getMetodoDePago(id): Observable<MetodoDePago> {
        const url = `${this.globalVars.apiHost}${this.metodosPagoUrl}/${id}`;
        return this.http.get<MetodoDePago>(url, this.globalVars.getOptionsRequest()).pipe(
            map((metodo: MetodoDePago) => {
                return new MetodoDePago(metodo);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createMetodoDePago(metodo: MetodoDePago): Observable<MetodoDePago> {
        const url = `${this.globalVars.apiHost}${this.metodosPagoUrl}`;
        let body= JSON.parse(JSON.stringify(metodo));    
        return this.http.post<MetodoDePago>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((metodo: MetodoDePago) => {
                return new MetodoDePago(metodo);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
     
    updateMetodoDePago(metodo: MetodoDePago): Observable<MetodoDePago> {
        const url = `${this.globalVars.apiHost}${this.metodosPagoUrl}/${metodo.id}`;
        let body= JSON.parse(JSON.stringify(metodo));
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((metodo: MetodoDePago) => {
                return new MetodoDePago(metodo);
            }), catchError(this.globalVars.tratarErrores)
        );
    }    
      
    deleteMetodoDePago(id: number): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.metodosPagoUrl}/${id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //REPORTES
    pdfCajaChica(id, params?, filtro?): Observable<Blob> {
        const url = `${this.globalVars.apiHost}${this.reportesUrl}caja_chica`;
        params['id'] = id;
        return this.http.get<Blob>(url + this.globalVars.filtroToUrlV2(filtro), this.globalVars.getOptionsRequestBlob(params));
    }
    
    pdfCheques(params?, filtro?): Observable<Blob> {
        const url = `${this.globalVars.apiHost}${this.reportesUrl}cheques`;
        return this.http.get<Blob>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequestBlob(params));
    }
}