<div *ngIf="formImputacion" class="row">
    <imputaciones_contable_proveedor_form [proveedor]="proveedor" (actualize)="actualizeForm($event)" (cancel)="cancelForm($event)"></imputaciones_contable_proveedor_form>
</div>

<div class="row">
    <div class="card">
        <div class="card-content">
            <h4 class="card-title">Imputaciones Contable</h4>
            <hr>
            <div class="row mt-2">
                <div class="col-sm-12">
                    <button class="btn btn-simple btn-icon btn-add" *ngIf="! formImputacion" (click)="newImputacion()" title="Agregar"><i class="cmp-i agregar"></i> Agregar</button>
                </div>  
            </div>

            <div class="row">
                <div class="col-md-12 table-responsive">
                    <table class="table table-hover">
                        <thead class="text-primary">
                            <tr>                                
                                <th><b>Imputación</b></th>
                                <th><b>Acciones</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor='let provImpu of imputaciones'>
                                <td>{{provImpu.nombre}}</td>
                                <td class="td-actions text-right">
                                    <button type="button" rel="tooltip" title="Eliminar Imputación" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="provImpu" confirm-message="¿Está seguro que desea eliminar la Imputación?">
                                        <i class="cmp-i eliminar-borrar i-sm"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr *ngIf="imputaciones != null && imputaciones.length == 0" class="text-center">
                                <td colspan="3">No se encontraron imputaciones</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>    
</div>