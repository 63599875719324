import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';

//import 'rxjs/add/operator/toPromise';
import { Observable ,  Subject } from 'rxjs';
// Import RxJs required methods
import { map, catchError } from 'rxjs/operators';

import { GlobalVars } from '../services/globalVars';
import { Cache } from '../services/cache';

import {OrdenDePagoGeneral, OrdenDePago, DeudaRetencion} from './models';
import {Movimiento} from '../module-cuenta/models';
import {NotaDeCredito} from '../module-factura/models';

@Injectable()
export class OrdenService{
    private ordenesUrl = 'ordenes';
    private movimientosUrl = 'movimientos/orden';
    private notasUrl = 'notas';
    private deudasUrl = 'deudas_retenciones'
    
    private reportesUrl = 'reportes/';

    constructor(private http: HttpClient, private globalVars: GlobalVars, private cache: Cache) {        
    }
    
    getOrdenes(params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrlV2(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new OrdenDePagoGeneral(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    getOrden(id): Observable<OrdenDePagoGeneral> {
        const url= `${this.globalVars.apiHost}${this.ordenesUrl}/${id}`;
        return this.http.get<OrdenDePagoGeneral>(url, this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDePagoGeneral) => {
                return new OrdenDePagoGeneral(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createOrden(orden: OrdenDePagoGeneral): Observable<OrdenDePagoGeneral> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}`;
        let body= JSON.parse(JSON.stringify(orden));
        body.proveedor_id = orden.proveedor ? orden.proveedor.id : null;
        body.distrito_id = orden.distrito.id;
        return this.http.post<OrdenDePagoGeneral>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDePagoGeneral) => {
                return new OrdenDePagoGeneral(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
     
    updateOrden(orden: OrdenDePagoGeneral): Observable<OrdenDePagoGeneral> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${orden.id}`;
        let body= JSON.parse(JSON.stringify(orden));
        delete body.orden;
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDePagoGeneral) => {
                return new OrdenDePagoGeneral(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    finalizarOrden(orden: OrdenDePagoGeneral): Observable<OrdenDePagoGeneral> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${orden.id}/finalizar`;
        let body = {finalizada: true};
        return this.http.post(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDePagoGeneral) => {
                return new OrdenDePagoGeneral(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    verificarOrden(orden: OrdenDePagoGeneral): Observable<OrdenDePagoGeneral> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${orden.id}/verificar`;
        let body = {verificada: true};
        return this.http.post(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDePagoGeneral) => {
                return new OrdenDePagoGeneral(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
      
    deleteOrden(id: number): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }

    anularOrden(id: number): Observable<OrdenDePagoGeneral> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${id}/anular`;
        return this.http.post(url, JSON.stringify({}), this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDePagoGeneral) => {
                return new OrdenDePagoGeneral(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //ORDENES/FACTURAS
    getOrdenesFacturas(id: number): Observable<OrdenDePago[]> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${id}/ordenes_facturas`;        
        return this.http.get<OrdenDePago[]>(url, this.globalVars.getOptionsRequest()).pipe(
            map((ordenes: OrdenDePago[]) => {
                ordenes = ordenes.map(item => new OrdenDePago(item));
                return ordenes;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createOrdenFactura(orden: OrdenDePago): Observable<OrdenDePago> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${orden.ordenpagogeneral.id}/ordenes_facturas`;
        let body= JSON.parse(JSON.stringify(orden));
        if (orden.factura) {
            body.factura_id = orden.factura.id;
        } else {
            body.viatico_id = orden.viatico.id;
        }
        return this.http.post<OrdenDePago>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDePago) => {
                return new OrdenDePago(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    updateOrdenFactura(orden: OrdenDePago): Observable<OrdenDePago> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${orden.ordenpagogeneral.id}/ordenes_facturas/${orden.id}`;
        let body= JSON.parse(JSON.stringify(orden));
        delete body.factura;
        delete body.viatico;
        // if (! orden.manualRetencion) {
        //     delete body.retencion;
        // }
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDePago) => {
                return new OrdenDePago(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    deleteOrdenFactura(idOrden: number, id: number): Observable<OrdenDePagoGeneral> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${idOrden}/ordenes_facturas/${id}`;
        return this.http.delete<OrdenDePagoGeneral>(url, this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDePagoGeneral) => {
                return new OrdenDePagoGeneral(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //MOVIMIENTOS ORDEN
    getMovimientosOrden(id: number): Observable<Movimiento[]> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${id}/movimientos`;        
        return this.http.get<Movimiento[]>(url, this.globalVars.getOptionsRequest()).pipe(
            map((movimientos: Movimiento[]) => {
                return movimientos.map(item => new Movimiento(item));
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //MOVIMIENTOS
    createMovimiento(movimiento: Movimiento): Observable<Movimiento> {
        const url = `${this.globalVars.apiHost}${this.movimientosUrl}`;
        let body= JSON.parse(JSON.stringify(movimiento));
        body.cuenta_id = movimiento.cuenta ? movimiento.cuenta.id : null;
        body.cuenta_destino_id = movimiento.cuentadestino ? movimiento.cuentadestino.id : -1;
        body.metodo_pago_id = movimiento.metodopago ? movimiento.metodopago.id : null;        
        body.orden_pago_id = movimiento.ordenpago ? movimiento.ordenpago.id : null;
        if (movimiento.cheque && movimiento.metodopago.esCheque()) {
            body.cheque_id = movimiento.cheque.id;
        }
        return this.http.post<Movimiento>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((movimiento: Movimiento) => {
                return new Movimiento(movimiento);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    deleteMovimiento(id: number): Observable<OrdenDePagoGeneral> {
        const url = `${this.globalVars.apiHost}${this.movimientosUrl}/${id}`;
        return this.http.delete<OrdenDePagoGeneral>(url, this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDePagoGeneral) => {
                return new OrdenDePagoGeneral(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    anularMovimiento(id: number): Observable<OrdenDePagoGeneral> {
        const url = `${this.globalVars.apiHost}${this.movimientosUrl}/${id}/anular`;
        return this.http.post<OrdenDePagoGeneral>(url, JSON.stringify({}), this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDePagoGeneral) => {
                return new OrdenDePagoGeneral(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    //
    //NOTAS ORDEN
    getNotasOrden(id: number): Observable<NotaDeCredito[]> {
        const url = `${this.globalVars.apiHost}${this.ordenesUrl}/${id}/notas`;        
        return this.http.get<NotaDeCredito[]>(url, this.globalVars.getOptionsRequest()).pipe(
            map((notas: NotaDeCredito[]) => {
                return notas.map(nota => new NotaDeCredito(nota));
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //NOTAS
    getNotas(params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.notasUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new NotaDeCredito(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    agregarNota(id: number, nota: NotaDeCredito): Observable<NotaDeCredito> {
        const url = `${this.globalVars.apiHost}${this.notasUrl}/${nota.id}`;
        let body = {orden_pago_id: id};
        return this.http.put<NotaDeCredito>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((nota: NotaDeCredito) => {
                return new NotaDeCredito(nota);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    liberarNota(id: number, nota: NotaDeCredito): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.notasUrl}/${nota.id}`;
        let body = {orden_pago_id: null};
        return this.http.put<OrdenDePagoGeneral>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((orden: OrdenDePagoGeneral) => {
                return new OrdenDePagoGeneral(orden);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //DEUDAS RETENCIONES
    getDeudasRetenciones(params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.deudasUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new DeudaRetencion(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    calcularDeudas(distrito_id: number, hasta: any): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.deudasUrl}`;
        let body = {distrito_id: distrito_id, hasta: hasta};
        return this.http.post<any>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }

    deleteDeudaRetencion(id: number): Observable<DeudaRetencion> {
        const url = `${this.globalVars.apiHost}${this.deudasUrl}/${id}`;
        return this.http.delete<DeudaRetencion>(url, this.globalVars.getOptionsRequest()).pipe(
            map((deuda: DeudaRetencion) => {
                return new DeudaRetencion(deuda);
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    //
    //REPORTES
    pdfOrdenes(params?, filtro?): Observable<Blob> {
        const url = `${this.globalVars.apiHost}${this.reportesUrl}ordenes_pago`;
        return this.http.get<Blob>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequestBlob(params));
    }
    
    pdfOrden(id): Observable<Blob> {
        const url = `${this.globalVars.apiHost}${this.reportesUrl}orden_pago?id=${id}`;
        return this.http.get<Blob>(url, this.globalVars.getOptionsRequestBlob());
    }
    
    pdfRetenciones(params?, filtro?): Observable<Blob> {
        const url = `${this.globalVars.apiHost}${this.reportesUrl}retencion_ganancia`;
        return this.http.get<Blob>(url + this.globalVars.filtroToUrlV2(filtro), this.globalVars.getOptionsRequestBlob(params));
    }
}