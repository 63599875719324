import {Component, OnInit } from '@angular/core';

import {OrdenService} from '../orden.service';
import {DistritoService} from '../../module-distrito/distrito.service';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';
import {PdfService} from '../../services/pdf.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {DeudaRetencion} from '../models';
import {User} from '../../models/user';
import {Distrito} from '../../module-distrito/models';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
import { FilterCacheUtilsService } from 'app/services/filter-cache-utils.service';
import { FilterCache } from 'app/models/filter-cache';

const VIEW_ID = "deudas";

@Component({
  selector: 'deudas_retencion',
  templateUrl: './deudas_retencion.component.html'
})
export class DeudasRetencionComponent extends GenericFormComponent implements OnInit {
  public actualUser: User;
    
  public deudas: DeudaRetencion[];
  public params = {page: 1, per_page: 10};
  public filtroDefault = {desde_gteq: null, desde_lteq: null, distrito_id_eq: null, sort: 'desde,desc'};
  public filtro = Object.assign({}, this.filtroDefault);
  public someFiltersApplied: boolean = false;
  
  public distritos: Distrito[] = [];

  public hasta: string = null;

  public sortOptions: { [id: string] : string } = { 'desde,desc': 'Fecha (Desc)'};
  public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Deudas Retenciones', active: true }];
    
  constructor(protected messageService: MessageService, private ordenService: OrdenService, private globalVars: GlobalVars, private distritoService: DistritoService, 
      private pdfService: PdfService, private filterCacheUtilsService: FilterCacheUtilsService) {
    super(messageService);
  }

  ngOnInit() {
    this.actualUser = this.globalVars.actualUser;
    this.loadInitFilters();
    this.loadCacheFiltersParams();
    this.filter();
    this.distritoService.getDistritos({per_page: 1000}, {sort: 'nombre'}).subscribe(
        (rta: any) => {
            this.distritos = rta.data;
        }
    );

    this.hasta = new Date().toISOString().slice(0,10) + " 00:00:00";
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.cursorWait();
    this.someFiltersApplied = this.globalVars.isSomeFilterApplied(this.filtro);
    this.ordenService.getDeudasRetenciones(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.deudas = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
            this.filterCacheUtilsService.setFilterParams(VIEW_ID, this.filtro, this.params);
        },
        error => {
            this.cursorDefault();
        }
    );
  }

  public changeFilterDate(value){
    this.filtro.desde_gteq = value.from;
    this.filtro.desde_lteq = value.to;
    this.filter(1);
  }
  
  //ACCIONES    
  onNotifyDelete(deuda: DeudaRetencion):void {
    this.deleteDeuda(deuda);
  }
    
  public deleteDeuda(deuda: DeudaRetencion) {
    this.beforeSubmitList();
    this.ordenService.deleteDeudaRetencion(deuda.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Deuda eliminada correctamente');            
            this.filter();
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }

  //CALCULAR
  public save(event){
      this.beforeSubmit(event);
      this.ordenService.calcularDeudas(this.filtro.distrito_id_eq, this.hasta).subscribe(
          (rta: any) => {
              this.showSuccess(true, 'Calculo realizado correctamente');
              this.filter();
          },err => {
              this.showError(err, true);
          });
  }

  private loadInitFilters(): void {
    this.filtro.distrito_id_eq = this.globalVars.getActualDistrito();
  }

  private loadCacheFiltersParams() {
    let cacheFilters: FilterCache = this.filterCacheUtilsService.getFilterParams(VIEW_ID);
    if (!cacheFilters) return;

    this.filtro = cacheFilters.filters;
    this.params = cacheFilters.params;
  }

  public resetFilters() {
    this.filtro = Object.assign({}, this.filtroDefault);
    this.loadInitFilters();
    this.filter();
  }
}