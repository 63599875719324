<div class="card">
    <div class="card-content">
        <h4 class="card-title">Nuevo Permiso</h4>
        <hr>

        <form class="form-horizontal" (submit)="save($event)" #datosForm="ngForm">
            <div class="form-group">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="col-sm-12 col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4 text-center" [hidden]="permiso.id || permiso.proveedor">
                                <provider-select [(providerModel)]="permiso.proveedor"></provider-select>
                            </div>
                            <!-- <div class="col-md-3">
                                <button *ngIf="! permiso.id" type="button" class="btn btn-primary" (click)="seleccionarProveedor()">Seleccionar Proveedor</button>
                            </div> -->
                            <ng-container *ngIf="permiso.proveedor">
                                <h5><strong>Proveedor</strong></h5>
                                <div class="col-md-3">
                                    <p><strong>Nombre:</strong> {{permiso.proveedor.nombre}}</p>
                                </div>
                                <div class="col-md-3">
                                    <p><strong>CUIT:</strong> {{permiso.proveedor.cuit | cuitformat}}</p>
                                </div>
                                <div class="col-md-3">
                                    <p><strong>Email:</strong> {{permiso.proveedor.email}}</p>
                                </div>
                                <div class="col-md-3 text-center" *ngIf="!permiso.id">
                                    <button type="button" class="btn btn-primary" (click)="permiso.proveedor = null">Cambiar Proveedor</button>
                                </div>
                            </ng-container>
                        </div>                          
                    </div>
                </div>
            </div>

            <div class="row text-center mt-2" *ngIf="permiso.proveedor">
                <button class="btn btn-default btn-cancel" type="button" (click)="cancelForm($event)">Cancelar</button>
                <button class="btn btn-primary ml-2" type="submit" [disabled]="submitted">Agregar</button>
            </div>
            <div class="clearfix"></div>
        </form>
    </div>
</div>