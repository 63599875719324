<div class="container-fluid">    
    <div class="row">
        <div class="card">
            <div class="card-content">
                <breadcrumb [items]="breadcrumbItems"></breadcrumb>
            
                <div class="row">
                    <div class="col-md-12 table-responsive">
                        <table class="table table-hover">
                            <thead class="text-primary">
                                <tr>
                                    <th><b>Nombre</b></th>
                                    <th><b>Descripción</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let rol of roles'>
                                    <td>{{rol.name.replace('_', ' ') | titlecase}}</td>
                                    <td>{{rol.description}}</td>
                                </tr>
                                <tr *ngIf="roles != null && roles.length == 0" class="text-center">
                                    <td colspan="2">No se encontraron roles</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>          
    </div>
</div>