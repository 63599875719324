import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule }   from '@angular/forms';

import {GenericModule} from '../module-generic/generic.module';
import {TranslateComponentModule} from '../translate-module/translate-component.module';

import {DistritoService} from './distrito.service';

import {DistritosComponent} from './distritos/distritos.component';
import {DistritosFormComponent} from './distritos_form/distritos_form.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    
    GenericModule,
    TranslateComponentModule,
  ],
  declarations: [
    DistritosComponent,
    DistritosFormComponent
  ],
  providers: [
    DistritoService
  ],
  exports: [
    DistritosComponent,
  ]
})
export class DistritoModule { }
