import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild } from '@angular/core';

import {environment} from '../../../environments/environment';

import {OrdenService} from '../orden.service';
import {ProveedorService} from '../../module-proveedor/proveedor.service';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';

import {Proveedor} from '../../module-proveedor/models';
import {OrdenDeVentaGeneral, Recibo} from '../models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

@Component({
  selector: 'recibos_orden_form',
  templateUrl: 'recibos_form.html'
})
export class RecibosFormComponent extends GenericFormComponent implements OnInit, AfterViewInit{
    @ViewChild('firstElementRecibo', { static: true }) firstElement: ElementRef;
    
    @Input() orden: OrdenDeVentaGeneral;
    @Input() recibo: Recibo = new Recibo();
    @Input() proveedor: Proveedor;

    public monedas: string[] = environment.monedas;
    public tipos = environment.tipoMetodos.filter(x => x != 'EFECTIVO');
    
    @Output() actualize: EventEmitter<Recibo> = new EventEmitter<Recibo>();
    @Output() cancel: EventEmitter<string> = new EventEmitter<string>();
    
    constructor(protected messageService: MessageService, private proveedorService: ProveedorService, private ordenService: OrdenService,
        private globalVars: GlobalVars) {
        super(messageService);
    }

    ngOnInit(): void {
        this.recibo.fecha = new Date().toISOString().slice(0,10) + " 00:00:00";
        this.recibo.fecha_vto_pago = new Date().toISOString().slice(0,10) + " 00:00:00";
        this.recibo.fecha_serv_desde = new Date().toISOString().slice(0,10) + " 00:00:00";
        this.recibo.fecha_serv_hasta = new Date().toISOString().slice(0,10) + " 00:00:00";
    }
    
    ngAfterViewInit(): void {
        this.firstElement.nativeElement.focus();
    }
    
    public save(event){
        if(!this.validForm())
            return;

        this.beforeSubmit(event);
        this.recibo.orden_venta_general_id = this.orden.id;
        this.ordenService.createRecibo(this.recibo).subscribe(
            (recibo: Recibo) => {
                this.showSuccess(true, 'Recibo creado correctamente');
                this.actualize.emit(recibo);
            },err => {
                this.showError(err, true);
            });
    }
    
    public cancelForm($event?){
        this.cancel.emit("Cancelacion");
    }
    
    public enabledSubmit() {
        this.submitted = false;
    }
    
    public disabledSubmit() {
        this.submitted = true;
    }

    private validForm(): boolean{
    let requiredFields = ["tipo", "monto", "fecha", "fecha_vto_pago", "fecha_serv_hasta", "fecha_serv_desde", "descripcion"];
    let validForm = true;

    requiredFields.forEach(field => {
        if(!this.validateField(field))
        validForm = false;
    })

    return validForm;
    }

    public onChangeTipo() {
    this.recibo.nro_cheque = null;
    this.recibo.nro_cuenta = null;
    this.validateField('tipo');
    }

    public validateField(field: string): boolean {
        if(field == "descripcion") {
          if(this.recibo[field] == null ||  this.recibo[field] == "") {
            this.inputError[field] = "Es necesario indicar una descripcion";
            return false;
          }
          this.inputError[field] = "";
          return true;
        }
    
        if(field == "tipo") {
          if(this.recibo.tipo == null){
            this.inputError.tipo = "Campo requerido.";
            return false;
          } else {
            let validTipoField = true;
            switch (this.recibo.tipo) {
              case 'CHEQUE':
                validTipoField = this.validateField('nro_cheque');
                break;
              case 'TRANSFERENCIA':
                validTipoField = this.validateField('nro_cuenta');
                break;
              default:
                validTipoField = true;
    
              return validTipoField;
            }
          }
    
          return true;
        }
      
        if(this.recibo[field] == null) {
          this.inputError[field] = "Campo requerido.";
          return false;
        }
    
        this.inputError[field] = "";
        return true;
      }
}