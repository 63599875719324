import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs';
// Import RxJs required methods
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import {GlobalVars} from '../services/globalVars';
import {TranslateService} from '../translate-module/translate.service';
import {DistritoService} from '../module-distrito/distrito.service';

import {Config} from '../models/config';

@Injectable()
export class ConfigService {
    private configUrl = 'config';
    private dashboardUrl = 'dashboard';

    constructor(private http: HttpClient, private globalVars: GlobalVars, private translateService: TranslateService, private distritoService: DistritoService) { 
    }

    public loadConfig(){
        return new Promise((resolve, reject) => {
        
            this.distritoService.loadDistritos().subscribe(
                (rta) => {
                    this.config().subscribe(
                        (config) => {
                            this.globalVars.config = config;
                            resolve(null);
                        }, error => {
                            reject();
                        }
                    );
                }, error => {
                    reject();
                }
            );
        
        
//            this.http.get<ConfigJson>(environment.dynamicConfig).subscribe(
//                config => {
//                    this.globalVars.rootBrand = config.rootBrand;
//                    this.globalVars.checkoutRequireUser = config.checkoutRequireUser;
//                    this.globalVars.checkoutCreateUser = config.checkoutCreateUser;
//                    this.globalVars.cleanCartInCheckout = config.cleanCartInCheckout;
//                    
//                    this.translateService.use(this.eComService.generalInfo.translate);
//                    
//                    //resolve();
//                    this.loadCategories().then(
//                        rta => { resolve();}
//                    ).catch(
//                        err => {reject();}
//                    );                    
//                }
//            );
        });
    }
    
    config(): Observable<Config> {
        const url = `${this.globalVars.apiHost}${this.configUrl}`;
        return this.http.get<Config>(url, this.globalVars.getOptionsRequest()).pipe(
            map((res) => new Config(res)),
            catchError((error:any) => this.globalVars.tratarErrores(error)),);
    }
    
    updateConfig(user: Config): Observable<Config> {
        const url = `${this.globalVars.apiHost}${this.configUrl}`;
        let body= JSON.parse(JSON.stringify(user));
        return this.http.put<Config>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map(res => {
                res = new Config(res);
                return res;
            })
        );
    }

    dashboard(params): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.dashboardUrl}`;
        return this.http.get<any>(url, this.globalVars.getOptionsRequest(params)).pipe(
            catchError((error:any) => this.globalVars.tratarErrores(error)));
    }
}