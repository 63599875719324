<div class="card">
    <div class="card-content">
        <h4 class="card-title">{{!imputacion.id ? 'Nueva Categoría' : 'Modificación de Categoría'}}</h4>
        <hr>

        <form class="form-horizontal" (submit)="save($event)" #datosForm="ngForm">
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Código</label>
                        <div class="col-md-9">                                  
                            <input #firstElementImputacion type="text" name="codigo" [(ngModel)]="imputacion.codigo" class="form-control" placeholder="1234" required>
                            <small [hidden]="!inputError.codigo" [innerHtml]="inputError.codigo" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Nombre de Imputación</label>
                        <div class="col-md-9">                                  
                            <input #firstElementImputacion type="text" name="nombre" [(ngModel)]="imputacion.nombre" class="form-control" placeholder="Imputación J" required>
                            <small [hidden]="!inputError.nombre" [innerHtml]="inputError.nombre" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Descripción</label>
                        <div class="col-md-9">                                 
                            <input type="text" name="descripcion" [(ngModel)]="imputacion.descripcion" class="form-control" placeholder="Descripción de la imputación" required>
                            <small [hidden]="!inputError.descripcion" [innerHtml]="inputError.descripcion" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>                    
            </div>

            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Ganancia Inscriptos (%)</label>
                        <div class="col-md-9">                                  
                            <input type="number" name="ganancia_inscriptos" [(ngModel)]="imputacion.ganancia_inscriptos" class="form-control" placeholder="10" required>
                            <small [hidden]="!inputError.ganancia_inscriptos" [innerHtml]="inputError.ganancia_inscriptos" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Monto Inicial Inscriptos</label>
                        <div class="col-md-9">                                 
                            <input type="number" name="monto_inicial" [(ngModel)]="imputacion.monto_inicial" class="form-control" placeholder="1000" required>
                            <small [hidden]="!inputError.monto_inicial" [innerHtml]="inputError.monto_inicial" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>                    
            </div>
            
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Ganancia No Inscriptos (%)</label>
                        <div class="col-md-9">                                  
                            <input type="number" name="ganancia_no_inscriptos" [(ngModel)]="imputacion.ganancia_no_inscriptos" class="form-control" placeholder="10" required>
                            <small [hidden]="!inputError.ganancia_no_inscriptos" [innerHtml]="inputError.ganancia_no_inscriptos" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Monto Inicial No Inscriptos</label>
                        <div class="col-md-9">                                 
                            <input type="number" name="monto_inicial_no_ins" [(ngModel)]="imputacion.monto_inicial_no_ins" class="form-control" placeholder="1000" required>
                            <small [hidden]="!inputError.monto_inicial_no_ins" [innerHtml]="inputError.monto_inicial_no_ins" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>                    
            </div>

            <div class="row text-center">
                <button class="btn btn-default btn-cancel" type="button" (confirm-click)="cancelForm($event)" confirm-message="¿Está seguro que desea cancelar este formulario?">Cancelar</button>
                <button class="btn btn-primary ml-2" type="submit" [disabled]="submitted">Guardar</button>
            </div>
            <div class="clearfix"></div>
        </form>
    </div>
</div>