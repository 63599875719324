import {Proveedor, ProveedorImputacion} from '../module-proveedor/models';
import {Distrito} from '../module-distrito/models';
import {OrdenDePagoGeneral, OrdenDePago} from '../module-orden/models';

export class Factura {
    public id: number;
    public punto_venta: number;
    public numero: number;
    public fecha: any;
    public fecha_vencimiento: any;
    public fecha_contable: any;
    public tipo: string = null;
    public iva: number = 0;
    public monto: number = 0;
    public monto_retencion: number = 0;
    public moneda: string = 'PES';
    public retencion: number = 0;
    public pagado: number = 0;
    public estado: string = 'P';
    
    public imputacion_contable: ImputacionContable = null;
    public proveedor: Proveedor = null;
    public proveedor_imputacion: ProveedorImputacion = null;
    public distrito: Distrito = null;
    public distrito_id: any = null;
    public ordenes: OrdenDePago = null;
    
    public selectedInTable: boolean = false;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.punto_venta = json.punto_venta;
            this.numero = json.numero;
            this.fecha = json.fecha;
            this.fecha_vencimiento = json.fecha_vencimiento;
            if (this.fecha_vencimiento == '0000-00-00 00:00:00') {
                this.fecha_vencimiento = null;
            }
            this.fecha_contable = json.fecha_contable;
            if (this.fecha_contable == '0000-00-00 00:00:00') {
                this.fecha_contable = null;
            }
            this.tipo = json.tipo;
            this.iva = Number(json.iva);
            this.monto = Number(json.monto);
            this.monto_retencion = json.monto_retencion;
            this.moneda = json.moneda;
            this.retencion = json.retencion;
            this.pagado = json.pagado;
            this.estado = json.estado;
            
            if (json.imputacion_contable) {
                this.imputacion_contable = new ImputacionContable(json.imputacion_contable);
            }
            if (json.proveedor) {
                this.proveedor = new Proveedor(json.proveedor);
            }
            if (json.proveedor_imputacion) {
                this.proveedor_imputacion = new ProveedorImputacion(json.proveedor_imputacion);
            }
            if (json.distrito) {
                this.distrito = new Distrito(json.distrito);
            }
            this.distrito_id = json.distrito_id;
            this.ordenes = json.ordenes
        }
    }
    
    public actualizarMonto(monto: number, monto_retencion: number) {
        this.monto = monto;
        this.monto_retencion = monto_retencion;
    }
    
    public restaPagar() {
        return Number((this.monto - this.pagado).toFixed(2));
    }
    
    public tienePagos() {
        return this.pagado != 0;
    }
    
    public print() {
        if(this.punto_venta == null){
            return '';
        }
        return this.punto_venta + "-" + this.numero;
    }
}

export class Producto {
    public id: number;
    public nombre: string;
    public cantidad: number = 1;
    public importe: number = 0;
    public descuento: number = 0;
    public total: number = 0;
    
    public factura: Factura = null;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.nombre = json.nombre;
            this.cantidad = Number(json.cantidad);
            this.importe = Number(json.importe);
            this.descuento = Number(json.descuento);
            this.total = Number(json.total);
            
            if (json.factura) {
                this.factura = new Factura(json.factura);
            }
        }
    }
    
    public calcularTotal() {        
        this.total = Number(((this.importe - this.descuento) * this.cantidad).toFixed(2));
    }
}

export class NotaDeCredito {
    public id: number;
    public fecha: any;
    public monto: number = 0;
    public moneda: string = 'PES';
    
    public proveedor: Proveedor = null;
    public factura: Factura = null;
    public ordenpago: OrdenDePagoGeneral = null;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.fecha = json.fecha;
            this.monto = json.monto;
            this.moneda = json.moneda;
            
            if (json.proveedor) {
                this.proveedor = new Proveedor(json.proveedor);
            }
            if (json.factura) {
                this.factura = new Factura(json.factura);
            }
            if (json.ordenpago) {
                this.ordenpago = new OrdenDePagoGeneral(json.ordenpago);
            }
        }
    }
}

export class ImputacionContable {
    public id: number;
    public codigo: string;
    public nombre: string;
    public descripcion: string;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.codigo = json.codigo;
            this.nombre = json.nombre;
            this.descripcion = json.descripcion;
        }
    }

    public print() {
        return this.codigo ?  this.codigo + ' - ' + this.nombre : this.nombre;
    }
}
