import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild } from '@angular/core';

import {DistritoService} from '../distrito.service';
import { MessageService } from '../../services/message.service';

import {Distrito} from '../models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

@Component({
  selector: 'distritos_form',
  templateUrl: 'distritos_form.html'
})
export class DistritosFormComponent extends GenericFormComponent implements OnInit, AfterViewInit{
    @ViewChild('firstElementDistrito', { static: true }) firstElement: ElementRef;
    
    @Input() distrito: Distrito= new Distrito();
    
    @Output() actualize: EventEmitter<Distrito> = new EventEmitter<Distrito>();
    @Output() cancel: EventEmitter<string> = new EventEmitter<string>();
    
    constructor(protected messageService: MessageService, private distritoService: DistritoService) {
        super(messageService);
    }

    ngOnInit(): void {
    }
    ngAfterViewInit(): void {
        this.firstElement.nativeElement.focus();
    }
    
    public save(event){
        this.beforeSubmit(event);
        if(!this.distrito.id){
            this.distritoService.createDistrito(this.distrito).subscribe(
                (distrito: Distrito) => {
                    this.showSuccess(true, 'Distrito creado correctamente');
                    this.distrito = distrito;
                    this.actualize.emit(this.distrito);             
                },err => {
                    this.showError(err, true);
                });
        }else{
            this.distritoService.updateDistrito(this.distrito).subscribe(
                (distrito: Distrito) => {
                    this.showSuccess(true, 'Distrito modificado correctamente');  
                    this.distrito = distrito;
                    this.actualize.emit(this.distrito);  
                },err => {
                    this.showError(err, true);
                });
        }
    }
    
    public cancelForm($event?){
        this.cancel.emit("Cancelacion");
    }
}