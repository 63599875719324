import { Injectable } from '@angular/core';
import 'rxjs/Rx';
import * as FileSaver from 'file-saver';

import {ProjectUtils} from './utils';


@Injectable()
export class PdfService {
    constructor(private utils: ProjectUtils) { 
    }    

    public loadPdf(name: string, data: any){
        var blob = new Blob([data], {type: 'application/pdf'});
        let date = new Date();
        FileSaver.saveAs(blob, this.utils.parseDateTimeToFullName(date) + "-" + name + ".pdf");
        //FileSaver.default(blob, name + ".pdf");
        /*
         * Este esta bueno pero el chrome debe bloquear el open*/
//        var fileURL = URL.createObjectURL(blob);
//        console.log(fileURL);
//        window.open(fileURL); // if you want to open it in new tab
    }
    
    public loadExcel(name: string, data: any){
        var blob = new Blob([data], {type: 'application/vnd.ms-excel'});
        let date = new Date();
        FileSaver.saveAs(blob, this.utils.parseDateTimeToFullName(date) + "-" + name + ".xls");
    }
}