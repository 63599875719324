<div class="container-fluid">
    <div *ngIf="cheque" class="row">
        <cheques_form [cheque]="cheque" (actualize)="actualizeForm($event)" (cancel)="cancelForm($event)"></cheques_form>
    </div>

    <div class="row" *ngIf="!cheque">
        <div class="card">
            <div class="card-content">
                <div class="row">
                    <breadcrumb [items]="breadcrumbItems" class="col-md-10"></breadcrumb>
                    <div class="col-md-2 mt-1">
                        <div class="pull-right"> 
                            <a href="#" data-toggle="dropdown" class="text-left  btn-info">
                                <p><i class="cmp-i exportar"></i>  Exportar<i class="ml-1 ti-angle-down"></i></p>
                            </a>
                            <ul class="dropdown-menu">
                                <li>
                                    <a (click)="pdfCheques(0)" class="cursor-pointer">Cheques (.pdf) <i class="cmp-i descargar-pdf"></i></a>
                                    <a (click)="pdfCheques(1)" class="cursor-pointer">Cheques (.xls) <i class="cmp-i descargar-excel"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <filters-header-section (onFilter)="filter(1)" [advancedFilters]="true" [someFilterApllied]="someFiltersApplied" (onClear)="resetFilters()">
                    <div class="col-sm-12">
                        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                            <label for="search">Número / Cuenta:</label>
                            <div class="input-group">
                                <input class="form-control" type="text" placeholder="Número / Cuenta" name="search" [(ngModel)]="filtro.numero_matches">
                                <span class="input-group-addon" (click)="filter(1)"><i class="cmp-i busqueda i-sm"></i></span>
                            </div>
                        </div>
                                            
                        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                            <label for="distrito_id">Distrito:</label>
                            <select class="form-control" data-style="btn btn-wd btn-block" name="distrito_id"  [(ngModel)]="params.distrito" (change)="updateCuentas(true);filter(1)" [disabled]="! actualUser.multi_distrito">
                                <option [ngValue]="null">Distritos</option>
                                <option *ngFor="let distrito of distritos" [ngValue]="distrito.id">{{distrito.nombre}}</option>
                            </select>
                        </div>
                    </div>

                    <div moreFilters>
                        <div class="col-sm-12">
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="cuenta_id">Cuenta:</label>
                                <select class="form-control" data-style="btn btn-wd btn-block" name="cuenta_id"  [(ngModel)]="params.cuenta" (change)="filter(1)">
                                    <option [ngValue]="null">Todas</option>
                                    <option *ngFor="let cuenta of cuentas" [ngValue]="cuenta.id">{{cuenta.nombre}} <span *ngIf="cuenta.tipo == 'BANCO' && cuenta.banco">({{cuenta.banco.nombre}})</span></option>
                                </select>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="estado_eq">Estado:</label>
                                <select class="form-control" data-style="btn btn-wd btn-block" name="estado_eq"  [(ngModel)]="filtro.estado_eq" (change)="filter(1)">
                                    <option [ngValue]="null">Todos</option>
                                    <option *ngFor="let estado of estados" [ngValue]="estado">{{estado | translate: 'ESTADO_CHEQUE'}}</option>
                                </select>
                            </div>
                        </div>
    
                        <div class="col-sm-12">
                            <generic_period (outDate)="changeFilterDate($event)" [fromDate]="filtro.emision_gteq" [toDate]="filtro.emision_lteq" [enableCleanOption]="true"></generic_period>
                        </div>
                    </div>
                
          
                </filters-header-section>  

                <div class="row mt-2">
                    <div class="col-sm-offset-10 col-sm-2">
                        <sort-select [options]="sortOptions" [(sortModel)]="filtro.sort" (onSelect)="filter(1)"></sort-select>
                    </div>
                </div>
            
                <div class="row">
                    <div class="col-md-12 table-responsive">
                        <table class="table table-hover">
                            <thead class="text-primary">
                                <tr>
                                    <th><b>Número</b></th>
                                    <th colspan="2" class="text-center"><b>Cuenta</b></th>
                                    <th><b>Emisión</b></th>
                                    <th><b>Lugar</b></th>
                                    <th><b>Nombre</b></th>
                                    <th><b>Monto</b></th>
                                    <th><b>No a la Orden</b></th>
                                    <th><b>Diferido</b></th>
                                    <th><b>Usado</b></th>
                                    <th><b>Estado</b></th>
                                    <th><b>Proveedor</b></th>
                                    <th><b>Acciones</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let cheque of cheques'>
                                    <td><a (click)="updateCheque(cheque)" rel="tooltip" title="Editar Chequera">{{cheque.numero}}</a></td>
                                    <td>
                                        <div class="row">
                                            <span *ngIf="cheque.chequera.cuenta.tipo == 'BANCO' && cheque.chequera.cuenta.banco">
                                                <span [class]="'bank-logo bank-logo-' + cheque.chequera.cuenta.banco.id" *ngIf="BANK_LOGO_IDS.includes(cheque.chequera.cuenta.banco.id)" [title]="cheque.chequera.cuenta.banco.nombre"></span>
                                                <span *ngIf="!BANK_LOGO_IDS.includes(cheque.chequera.cuenta.banco.id)">{{cheque.chequera.cuenta.banco.nombre}}</span>
                                            </span> 
                                        </div>
                                    </td>
                                    <td>
                                        <a [routerLink]="['/cuentas', cheque.chequera.cuenta.id]" rel="tooltip" title="Editar Cuenta" class="cursor-pointer ml-1 link-no-highlight">{{cheque.chequera.cuenta.nombre}}</a>
                                    </td>
                                    <td>{{cheque.emision | date: 'dd/MM/yyyy'}}</td>
                                    <td>{{cheque.lugar}}</td>
                                    <td>{{cheque.nombre}}</td>
                                    <td>{{cheque.monto | number: '1.2-2'}}</td>
                                    <td>
                                        <span *ngIf="cheque.no_a_la_orden">SI</span>
                                        <span *ngIf="!cheque.no_a_la_orden">NO</span>
                                    </td>
                                    <td>
                                        <span *ngIf="cheque.diferido">SI</span>
                                        <span *ngIf="!cheque.diferido">NO</span>
                                    </td>
                                    <td>
                                        <span *ngIf="cheque.usado" class="status-sucess">SI</span>
                                        <span *ngIf="!cheque.usado" class="status-danger">NO</span>
                                    </td>
                                    <td [ngClass]="(cheque.estado === 'RECHAZADO' || cheque.estado === 'RECHADO_N' || cheque.estado === 'ANULADO') ? 'status-danger': 'status-sucess'">{{cheque.estado | translate: 'ESTADO_CHEQUE'}}</td>
                                    <td>
                                        <a *ngIf="cheque.proveedor_id" [routerLink]="['/proveedores', cheque.proveedor_id]" title="Detalle Proveedor" rel="tooltip" class="link-no-highlight">{{cheque.proveedor.nombre}}</a>
                                        <span *ngIf="!cheque.proveedor_id"> - </span>
                                    </td>

                                    <td class="td-actions text-right">
                                        <button type="button" rel="tooltip" title="Editar Cheque" class="btn btn-primary btn-simple btn-xs btn-cmp-i" (click)="updateCheque(cheque)">
                                            <i class="cmp-i editar i-sm"></i>
                                        </button>
                                        <button *ngIf="! cheque.usado" type="button" rel="tooltip" title="Eliminar Cheque" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="cheque" confirm-message="¿Está seguro que desea eliminar la Cheque?">
                                            <i class="cmp-i eliminar-borrar i-sm"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="cheques != null && cheques.length == 0" class="text-center">
                                    <td colspan="12">No se encontraron cheques</td>
                                </tr>
                            </tbody>
                        </table>                        
                        <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)" *ngIf="cheques != null && cheques.length > 0"></pager>
                    </div>
                </div>
            </div>
        </div>          
    </div>
</div>