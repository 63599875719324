<div class="container-fluid">
    <div *ngIf="chequera" class="row">
        <chequeras_form [chequera]="chequera" (actualize)="actualizeForm($event)" (cancel)="cancelForm($event)"></chequeras_form>
    </div>
    
    <div class="row">
        <div class="card">
            <div class="card-content">
                <breadcrumb [items]="breadcrumbItems"></breadcrumb>
            
                <filters-header-section (onFilter)="filter(1)" [advancedFilters]="true" [someFilterApllied]="someFiltersApplied" (onClear)="resetFilters()">
                    <div class="col-md-12">
                        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                            <label for="search">Chequera:</label>
                            <div class="input-group">
                                <input class="form-control" type="text" placeholder="Chequera" name="search" [(ngModel)]="filtro.numero_desde_lteq">
                                <span class="input-group-addon" (click)="filter(1)"><i class="cmp-i busqueda i-sm"></i></span>
                            </div>
                        </div>
                                            
                        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                            <label for="distrito_id">Distrito:</label>
                            <select class="form-control" data-style="btn btn-wd btn-block" name="distrito_id"  [(ngModel)]="params.distrito" (change)="updateCuentas(true);filter(1)" [disabled]="! actualUser.multi_distrito">
                                <option [ngValue]="null">Todos los Distritos</option>
                                <option *ngFor="let distrito of distritos" [ngValue]="distrito.id">{{distrito.nombre}}</option>
                            </select>
                        </div>
                    </div>

                    <div moreFilters>
                        <div class="col-md-12">
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="cuenta_id">Cuenta:</label>
                                <select class="form-control" data-style="btn btn-wd btn-block" name="cuenta_id"  [(ngModel)]="filtro.cuenta_id_eq" (change)="filter(1)">
                                    <option [ngValue]="null">Todas</option>
                                    <option *ngFor="let cuenta of cuentas" [ngValue]="cuenta.id">{{cuenta.nombre}} <span *ngIf="cuenta.tipo == 'BANCO' && cuenta.banco">({{cuenta.banco.nombre}})</span></option>
                                </select>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="finalizada">Abiertas / Finalizadas:</label>
                                <select class="form-control" data-style="btn btn-wd btn-block" name="finalizada"  [(ngModel)]="filtro.finalizada_eq" (change)="filter(1)">
                                    <option [ngValue]="null">Todas</option>
                                    <option [ngValue]="0">Abiertas</option>
                                    <option [ngValue]="1">Finalizadas</option>
                                </select>
                            </div>
                          
                        </div>
                        <div class="col-md-12">
                            <generic_period (outDate)="changeFilterDate($event)" [fromDate]="filtro.emision_gteq" [toDate]="filtro.emision_lteq" [enableCleanOption]="true"></generic_period>
                        </div>
                    </div>
                </filters-header-section>


                <div class="row mt-2">
                    <div class="col-sm-10">
                        <button class="btn btn-simple btn-icon btn-add" *ngIf="!chequera" (click)="newChequera()" title="Agregar"><i class="cmp-i agregar"></i> Agregar</button>
                    </div>  
                    <div class="col-sm-2">
                        <sort-select [options]="sortOptions" [(sortModel)]="filtro.sort" (onSelect)="filter(1)"></sort-select>
                    </div>
                </div>
            
                <div class="row">
                    <div class="col-md-12 table-responsive">
                        <table class="table table-hover">
                            <thead class="text-primary">
                                <tr>
                                    <th><b>Cuenta</b></th>
                                    <th><b>Numeración Cheques</b></th>
                                    <th><b>Emisión</b></th>                                   
                                    <th><b>Finalizada</b></th>
                                    <th><b>Tipo de Libro</b></th>
                                    <th><b>Acciones</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let chequera of chequeras'>
                                    <td>
                                        <div class="row">
                                            <span *ngIf="chequera.cuenta.tipo == 'BANCO' && chequera.cuenta.banco">
                                                <span [class]="'bank-logo bank-logo-' + chequera.cuenta.banco.id" *ngIf="BANK_LOGO_IDS.includes(chequera.cuenta.banco.id)" [title]="chequera.cuenta.banco.nombre"></span>
                                                <span *ngIf="!BANK_LOGO_IDS.includes(chequera.cuenta.banco.id)">{{chequera.cuenta.banco.nombre}}</span>
                                            </span>  
                                            <!-- <div class="text-center">
                                                <b *ngIf="chequera.cuenta.tipo != 'BANCO' || !chequera.cuenta.banco">-</b>                                    
                                            </div>   -->
                                            <a [routerLink]="['/cuentas', chequera.cuenta.id]" rel="tooltip" title="Editar Cuenta" class="cursor-pointer ml-2 link-no-highlight">{{chequera.cuenta.nombre}}</a>
                                        </div>
                                    </td>
                                    <td><a [routerLink]="['/chequeras', chequera.id]" rel="tooltip" title="Editar Chequera">{{chequera.numero_desde}} - {{chequera.numero_hasta}}</a></td>
                                    <td>{{chequera.emision | date: 'dd/MM/yyyy'}}</td>                                   
                                    <td>
                                        <span *ngIf="chequera.finalizada" class="status-danger">SI</span>
                                        <span *ngIf="!chequera.finalizada" class="status-sucess">NO</span>
                                    </td>
                                    <td>{{chequera.tipo_libro | translate: 'BOOK_TYPE'}}</td>
                                    <td class="td-actions text-right">
                                        <a [routerLink]="['/chequeras', chequera.id]" rel="tooltip" title="Editar Chequera" class="btn btn-primary btn-simple btn-xs btn-cmp-i">
                                            <i class="cmp-i editar i-sm"></i>
                                        </a>
                                        <button type="button" rel="tooltip" title="Eliminar Chequera" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="chequera" confirm-message="¿Está seguro que desea eliminar la Chequera?">
                                            <i class="cmp-i eliminar-borrar i-sm"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="chequeras != null && chequeras.length == 0" class="text-center">
                                    <td colspan="8">No se encontraron chequeras</td>
                                </tr>
                            </tbody>
                        </table>                        
                        <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)" *ngIf="chequeras != null && chequeras.length > 0"></pager>
                    </div>
                </div>
            </div>
        </div>          
    </div>
</div>