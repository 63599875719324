import {Component, OnInit, Input, Output, EventEmitter, AfterContentChecked, OnChanges, SimpleChanges, ElementRef, ViewChild } from '@angular/core';

import {ProjectUtils, SelectPeriod} from '../../../services/utils';
import flatpickr from 'flatpickr';
import flatpickrLang from "flatpickr/dist/l10n/es"


@Component({
  selector: 'generic_period',
  templateUrl: 'generic_period.html'
})
export class GenericPeriodComponent implements OnInit {
    /**
     * Opciones
     * full = yyyy-mm-dd hh:mm:ss
     * ymd = yyyymmdd
     */
    @Input() format: string = 'full';
    @Output() outDate: EventEmitter<any> = new EventEmitter<any>();
    @Input() defaultPeriod?: 'today' | 'yesterday' | 'lastWeek' | 'actualMonth' | 'actualYear' = null;
    @Input() fromDate?: string; //YYYY-MM-DD HH:mm:SS
    @Input() toDate?: string; //YYYY-MM-DD HH:mm:SS
    @Input() enableCleanOption?: boolean = false;
    @Input() enableMonthOptions?: boolean = false;

    @ViewChild('datepicker', { static: true }) datepickerEl: ElementRef;

    public optPeriod: SelectPeriod = {period: null, fromDate: null, toDate: null};;

    public periods = [
        { period: 'today', placeholder: 'Hoy', group: 'Genericos'},
        { period: 'yesterday', placeholder: 'Ayer', group: 'Genericos'},
        { period: 'lastWeek', placeholder: 'Última semana', group: 'Genericos'},
        { period: 'actualMonth', placeholder: 'Mes Actual', group: 'Genericos'},
        { period: 'actualYear', placeholder: 'Año Actual', group: 'Genericos'}
    ]
    
    constructor(private projectUtils: ProjectUtils) {
    }

    ngOnInit(): void {
        if(this.enableMonthOptions) {
            this.setMonthPeriods();
        }
        if( this.defaultPeriod ) {
            this.optPeriod = {period: this.defaultPeriod, fromDate: null, toDate: null};
            this.loadPeriod();
        } else {
            if(this.fromDate) this.optPeriod.fromDate = this.fromDate.substring(0,10);
            if(this.toDate) this.optPeriod.toDate = this.toDate.substring(0,10);
        }

        flatpickr.localize(flatpickrLang.es);
        this.setDatepicker();
    }
    
    public updateUnixDate(){
        if(this.optPeriod?.period == null) return;
        if (this.format == 'full') {
            this.outDate.emit(this.projectUtils.updateFullFormatDate(this.optPeriod));
        } else {
            this.outDate.emit(this.projectUtils.updateYmdFormat(this.optPeriod));
        }
    }
    
    public loadPeriod(){
        if(this.optPeriod?.period == null) return;
        this.projectUtils.loadPeriod(this.optPeriod);
        this.updateUnixDate();
        this.setDatepicker();
    }

    public loadCustomDate() {
        this.optPeriod.period = '';
        this.updateUnixDate();
    }

    private onChangeDates(dates: Date[]){
        if(dates?.length == 1) return;
        this.optPeriod.period = '';
        this.optPeriod.fromDate = dates[0].toISOString().substring(0,10);
        this.optPeriod.toDate = dates[1].toISOString().substring(0,10);
        this.loadCustomDate();
    }

    private setDatepicker(): void {
        flatpickr(this.datepickerEl.nativeElement, {
            altInput: true,
            altFormat: "d/m/Y",
            dateFormat: "Y-m-d",
            allowInput: true,
            mode: "range",
            defaultDate: [this.optPeriod?.fromDate || null, this.optPeriod?.toDate || null],
            onChange: dates => this.onChangeDates(dates),
            static: true
        });
    }

    public setEmptyPeriod(){
        this.optPeriod = {period: null, fromDate: null, toDate: null};
        this.setDatepicker();
    }

    public comparePeriods(a: any, b: any): boolean {
        return a.period === b
    }

    private setMonthPeriods() {
        let monthsOpt = [
            { period: 'jan', placeholder: 'Enero', group: 'Meses'},
            { period: 'feb', placeholder: 'Febrero', group: 'Meses'},
            { period: 'mar', placeholder: 'Marzo', group: 'Meses'},
            { period: 'apr', placeholder: 'Abril', group: 'Meses'},
            { period: 'may', placeholder: 'Mayo', group: 'Meses'},
            { period: 'jun', placeholder: 'Junio', group: 'Meses'},
            { period: 'jul', placeholder: 'Julio', group: 'Meses'},
            { period: 'aug', placeholder: 'Agosto', group: 'Meses'},
            { period: 'sep', placeholder: 'Septiembre', group: 'Meses'},
            { period: 'oct', placeholder: 'Octubre', group: 'Meses'},
            { period: 'nov', placeholder: 'Noviembre', group: 'Meses'},
            { period: 'dec', placeholder: 'Diciembre', group: 'Meses'}
        ];

        this.periods = this.periods.concat(monthsOpt.slice(0, (new Date()).getMonth()));
    }
}