import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild } from '@angular/core';

import {environment} from '../../../environments/environment';

import {ViaticoService} from '../viatico.service';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';

import {Periodo, TipoViatico} from '../models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

@Component({
  selector: 'tipo_viaticos_form',
  templateUrl: 'tipo_viaticos_form.component.html'
})
export class TipoViaticosFormComponent extends GenericFormComponent implements OnInit, AfterViewInit{
    @ViewChild('firstElement', { static: true }) firstElement: ElementRef;
    
    @Input() tipoViatico: TipoViatico = new TipoViatico();

    public tipos:string [] = environment.tipoViatico;
    
    @Output() actualize: EventEmitter<TipoViatico> = new EventEmitter<TipoViatico>();
    @Output() cancel: EventEmitter<string> = new EventEmitter<string>();
    
    constructor(protected messageService: MessageService, private viaticoService: ViaticoService, private globalVars: GlobalVars) {
        super(messageService);
    }

    ngOnInit(): void {
    }
    
    ngAfterViewInit(): void {
        this.firstElement.nativeElement.focus();
    }
    
    public save(event){
        this.beforeSubmit(event);
        this.viaticoService.createTipoViatico(this.tipoViatico).subscribe(
            (tipoViatico: TipoViatico) => {
                this.showSuccess(true, 'Tipo viático creado correctamente');
                this.actualize.emit(tipoViatico);
            },err => {
                this.showError(err, true);
            });
    }
    
    public cancelForm($event?){
        this.cancel.emit("Cancelacion");
    }
    
    public enabledSubmit() {
        this.submitted = false;
    }
    
    public disabledSubmit() {
        this.submitted = true;
    }
}