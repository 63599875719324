import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild } from '@angular/core';

import {environment} from '../../../environments/environment';

import {CuentaService} from '../cuenta.service';
import { MessageService } from '../../services/message.service';

import {MetodoDePago} from '../models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

@Component({
  selector: 'metodos_pago_form',
  templateUrl: 'metodos_pago_form.html'
})
export class MetodosPagoFormComponent extends GenericFormComponent implements OnInit, AfterViewInit{
    @ViewChild('firstElementMetodo', { static: true }) firstElement: ElementRef;
    
    @Input() metodo: MetodoDePago= new MetodoDePago();
    public tipos: string[] = environment.tipoMetodos;
    
    @Output() actualize: EventEmitter<MetodoDePago> = new EventEmitter<MetodoDePago>();
    @Output() cancel: EventEmitter<string> = new EventEmitter<string>();
    
    constructor(protected messageService: MessageService, private cuentaService: CuentaService) {
        super(messageService);
    }

    ngOnInit(): void {
    }
    ngAfterViewInit(): void {
        this.firstElement.nativeElement.focus();
    }
    
    public save(event){
        this.beforeSubmit(event);
        if(!this.metodo.id){
            this.cuentaService.createMetodoDePago(this.metodo).subscribe(
                (metodo: MetodoDePago) => {
                    this.showSuccess(true, 'Método de pago creado correctamente');
                    this.metodo = metodo;
                    this.actualize.emit(this.metodo);             
                },err => {
                    this.showError(err, true);
                });
        }else{
            this.cuentaService.updateMetodoDePago(this.metodo).subscribe(
                (metodo: MetodoDePago) => {
                    this.showSuccess(true, 'Método de pago modificado correctamente');  
                    this.metodo = metodo;
                    this.actualize.emit(this.metodo);  
                },err => {
                    this.showError(err, true);
                });
        }
    }
    
    public cancelForm($event?){
        this.cancel.emit("Cancelación");
    }
}