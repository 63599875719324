<div class="row">
    <div class="card">
        <div class="card-content">
            <h4 class="card-title">Ingresos</h4>
            <hr>
            
            <div class="row">
                <div class="row mt-1">
                    <div class="col-sm-12">
                        <button class="btn btn-simple btn-icon btn-add" *ngIf="(orden.estado == 'P')" (click)="addDocumento('F')" title="Agregar"><i class="cmp-i agregar"></i> Agregar Ingreso</button>
                    </div>  
                </div>

                <div class="col-md-12 table-responsive">
                    <table class="table table-hover">
                        <thead class="text-primary">
                            <tr>
                                <th><b>Num. Documento</b></th>
                                <th><b>Imputación Venta</b></th>
                                <th><b>Descripción</b></th>
                                <th><b>Total (Monto original)</b></th>
                                <th><b>Imputado</b></th>
                                <th><b>Acciones</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor='let ordenFactura of ordenes'>
                                <tr>
                                    <td><a (click)="detalleDocumento(ordenFactura)" rel="tooltip" title="Detalle">{{ordenFactura.numero()}}</a></td>
                                    <td>
                                        <span *ngIf="ordenFactura.factura.imputacion_venta">{{ordenFactura.factura.imputacion_venta.nombre}}</span>
                                        <span *ngIf="!ordenFactura.factura.imputacion_venta">-</span>
                                    </td>
                                    <td>{{ordenFactura.factura.descripcion}}</td>
                                    <td>$ {{ordenFactura.montoOriginal() | number: '1.2-2'}}</td>
                                    <td>$ {{ordenFactura.monto | number: '1.2-2'}}</td>
                                    <td class="td-actions text-right">
                                        <button
                                            [disabled]="!(orden.estado == 'P' && ordenFactura.id)"
                                            [title]="!(orden.estado == 'P' && ordenFactura.id) ? 'No es posible editar ya que la orden esta aprobada.' : 'Editar'" 
                                            type="button" rel="tooltip" class="btn btn-primary btn-simple btn-xs btn-cmp-i" (click)="ordenFactura.editando = true;">
                                            <i class="cmp-i editar i-sm"></i>
                                        </button>
                                        <button *ngIf="orden.estado == 'P' && ordenFactura.id" type="button" rel="tooltip" title="Eliminar" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="ordenFactura" confirm-message="¿Está seguro que desea quitar la factura?">
                                            <i class="cmp-i eliminar-borrar i-sm"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="ordenFactura.editando">
                                    <td colspan="8">
                                        <div class="row">
                                            <div class="col-xs-offset-1 col-xs-10 col-md-offset-4 col-md-4">
                                                <div class="form-group">
                                                    <label>Monto</label>
                                                    <input type="number" name="monto" [(ngModel)]="ordenFactura.monto" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row text-center mt-2">
                                            <div class="col-md-12">
                                                <button type="submit" title="Cancelar" class="btn btn-default btn-cancel" (click)="cancel(ordenFactura)">Cancelar</button>
                                                <button *ngIf="ordenFactura.monto != 0" type="submit" title="Guardar" class="btn btn-primary ml-2" [disabled]="submitted" (click)="save(ordenFactura)">Guardar</button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                            <tr *ngIf="ordenes != null && ordenes.length == 0" class="text-center">
                                <td colspan="6">No hay ingresos</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>