import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {UserService} from '../user.service';
import {DistritoService} from '../../module-distrito/distrito.service';
import {GlobalVars} from '../../services/globalVars';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {User} from '../../models/user';
import {Distrito} from '../../module-distrito/models';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
 
@Component({
  selector: 'users',
  templateUrl: './users.component.html'
})
export class UsersComponent extends GenericFormComponent implements OnInit {
  public actualUser: User;
    
  public users: User[];
  public params = {page: 1, per_page: 10};
  public filtro = {or_username_matches: null, or_email_matches: null, habilitado_eq: 1, distrito_id_eq: null, sort: 'username'};
  public distritos: Distrito[] = [];
  //FORM
  public user: User;

  public sortOptions: { [id: string] : string } = { 'created_at,desc': 'Fecha de creación', 'username': 'Nombre de usuario', 'distrito_id': 'Distrito' };
  public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Usuarios', active: true }];
    
  constructor(protected messageService: MessageService, private globalVars: GlobalVars, private userService: UserService, private distritoService: DistritoService, private route: ActivatedRoute) {
    super(messageService);
  }

  ngOnInit() {
    this.actualUser = this.globalVars.actualUser;
    this.filtro.distrito_id_eq = this.route.snapshot.queryParams['distrito'] ? Number(this.route.snapshot.queryParams['distrito']) : this.globalVars.getActualDistrito();
    if (this.filtro.distrito_id_eq && !this.actualUser.multi_distrito && this.actualUser.distrito.id != this.filtro.distrito_id_eq) {
        this.filtro.distrito_id_eq = this.globalVars.getActualDistrito();
    }

    this.filter();
    this.distritoService.getDistritos({per_page: 1000}, {sort: 'nombre'}).subscribe(
        (rta: any) => {
            this.distritos = rta.data;
        }
    );
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    //this.users= null;
    this.cursorWait();
    this.filtro.habilitado_eq = this.filtro.habilitado_eq ? 1 : null;
    this.filtro.or_email_matches = this.filtro.or_username_matches;
    this.userService.getUsers(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.users= rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
        },
        error => {
            this.cursorDefault();
        }
    );
  }
  
  //ACCIONES  
  changeState(user: User, state, event){
    this.beforeSubmitList(event);
    user.habilitado = ! user.habilitado;
    this.userService.updateUser(user).subscribe(
        rta => {
            this.showSuccessList(true, 'Usuario modificado correctamente');
        }, error => {
            user.habilitado = ! user.habilitado;
            this.showErrorList(error, true);
        }
    );
  }
  
  onNotifyDelete(user: User):void {
    this.deleteUser(user);
  }
    
  public deleteUser(user: User) {
    this.beforeSubmitList();
    this.userService.deleteUser(user.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Usuario eliminado correctamente');
            //ELIMINO DE LA LISTA      
            this.users.forEach(function(userFor, index, object) {
                if(userFor.id == user.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }
  
    
  //FORMULARIO ALTA/EDICION
  //Para el Form
  public newUser(){
    this.user = new User();
    let distrito: Distrito = new Distrito();
    distrito.id = this.globalVars.getActualDistrito();
    this.user.distrito = distrito;
  }
  public updateUser(user: User){
    this.user = new User(user);
  }
  
  //Desde el formulario
  public actualizeForm(user): void {
    let exists= false;
    this.users.forEach(function(userFor, index, object) {
        if(userFor.id == user.id) {
            object[index]= user;
            exists= true;
        }
    });
    if(!exists){
        this.users.splice(0, 0, user);
    }
    this.cancelForm();
  }    
  public cancelForm(event?): void{
    this.user= null;
  }
  
}