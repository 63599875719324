<div class="container-fluid">
    <div *ngIf="cuenta" class="row">
        <cuentas_form [cuenta]="cuenta" [proveedor]="cuenta.proveedor" (actualize)="actualizeForm($event)" (cancel)="cancelForm($event)"></cuentas_form>
    </div>
    
    <div class="row" *ngIf="cuenta && cuenta.proveedor">
        <div class="card">
            <div class="card-content">
                <fieldset>
                    <div class="row">                    
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Nombre</label>
                                <input type="text" name="nombre" [(ngModel)]="cuenta.proveedor.nombre" class="form-control" readonly="readonly">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>CUIT</label>
                                <input type="text" name="cuit" [(ngModel)]="cuenta.proveedor.cuit" class="form-control" readonly="readonly">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Email</label>
                                <input type="email" name="email" [(ngModel)]="cuenta.proveedor.email" class="form-control" readonly="readonly">
                            </div>
                        </div>
                    </div>
                    <div class="row text-center">
                        <a [routerLink]="['/proveedores', cuenta.proveedor.id]" rel="tooltip" title="Detalle" class="btn btn-primary">
                            Detalle
                        </a>
                    </div>
                </fieldset>
            </div>
        </div>          
    </div>
    
<!--    <div class="row" *ngIf="cuenta">
        <div class="card">
            <div class="card-content">
                <h4 class="card-title">Saldo $ {{cuenta.monto}}</h4>
            </div>
        </div>          
    </div>-->
    
    <movimientos *ngIf="cuenta" [cuenta]="cuenta"></movimientos>
    
</div>