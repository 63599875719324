import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';

//import 'rxjs/add/operator/toPromise';
import { Observable ,  Subject } from 'rxjs';
// Import RxJs required methods
import { map, catchError } from 'rxjs/operators';

import { GlobalVars } from '../services/globalVars';
import { Cache } from '../services/cache';

import {User, Rol, PermisoViatico} from '../models/user';

@Injectable()
export class UserService{
    private usersUrl = 'users';
    private rolesUrl = 'roles';
    private permisosViaticosUrl = 'permisos/viaticos';

    constructor(private http: HttpClient, private globalVars: GlobalVars, private cache: Cache) {        
    }
    
    getUsers(params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.usersUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new User(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    getUser(id): Observable<User> {
        const url= `${this.globalVars.apiHost}${this.usersUrl}/${id}`;
        return this.http.get<User>(url, this.globalVars.getOptionsRequest()).pipe(
            map((user: User) => {
                return new User(user);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createUser(user: User): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.usersUrl}`;
        let body= JSON.parse(JSON.stringify(user));
        body.password_confirmation = user.confPassword; 
        body.distrito_id = user.distrito ? user.distrito.id : null;
        body.roles = user.roles.map(r => r.id);
        return this.http.post(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((user: User) => {
                return new User(user);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
     
    updateUser(user: User): Observable<User> {
        const url = `${this.globalVars.apiHost}${this.usersUrl}/${user.id}`;
        let body= JSON.parse(JSON.stringify(user));
        if(body.password == ''){
            delete body.password;
        } else {
            body.password_confirmation = user.confPassword;
        }
        body.distrito_id = user.distrito ? user.distrito.id : null;
        body.roles = user.roles.map(r => r.id);
        return this.http.put(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((user: User) => {
                return new User(user);
            }), catchError(this.globalVars.tratarErrores)
        );
    }    
      
    deleteUser(id: number): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.usersUrl}/${id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //ROLES USUARIO
    getUserRoles(id: number): Observable<Rol[]> {
        const url = `${this.globalVars.apiHost}${this.usersUrl}/${id}/roles`;        
        return this.http.get<Rol[]>(url, this.globalVars.getOptionsRequest()).pipe(
            map((roles: Rol[]) => {
                roles = roles.map(item => new Rol(item));
                return roles;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //ROLES
    getRoles(): Observable<Rol[]> {
        const url = `${this.globalVars.apiHost}${this.rolesUrl}`;        
        return this.http.get<Rol[]>(url, this.globalVars.getOptionsRequest()).pipe(
            map((roles: Rol[]) => {
                roles = roles.map(item => new Rol(item));
                return roles;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    getRol(id): Observable<Rol> {
        const url = `${this.globalVars.apiHost}${this.rolesUrl}/${id}`;
        return this.http.get<Rol>(url, this.globalVars.getOptionsRequest()).pipe(
            map((rol: Rol) => {
                return new Rol(rol);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    //
    //PERMISOS VIATICOS - FILTRAR POR USER
    getPermisosViaticos(params?, filtro?): Observable<PermisoViatico[]> {
        const url = `${this.globalVars.apiHost}${this.permisosViaticosUrl}`;        
        return this.http.get<PermisoViatico[]>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((permisos: PermisoViatico[]) => {
                permisos = permisos.map(item => new PermisoViatico(item));
                return permisos;
            }), catchError(this.globalVars.tratarErrores)
        );
    }
    
    createPermisosViatico(permiso: PermisoViatico): Observable<PermisoViatico> {
        const url = `${this.globalVars.apiHost}${this.permisosViaticosUrl}`;
        let body= JSON.parse(JSON.stringify(permiso));
        body.user_id = permiso.user ? permiso.user.id : null;
        body.proveedor_id = permiso.proveedor ? permiso.proveedor.id : null;
        return this.http.post(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((permiso: PermisoViatico) => {
                return new PermisoViatico(permiso);
            }), catchError(this.globalVars.tratarErrores)
        );
    }
      
    deletePermisoViatico(id: number): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.permisosViaticosUrl}/${id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }
}