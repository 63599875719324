import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import {environment} from '../../../environments/environment';

import {CuentaService} from '../cuenta.service';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';

import {Cuenta, Chequera} from '../models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';

@Component({
  selector: 'chequeras_form',
  templateUrl: 'chequeras_form.html'
})
export class ChequerasFormComponent extends GenericFormComponent implements OnInit, AfterViewInit{
    @ViewChild('firstElementChequera', { static: true }) firstElement: ElementRef;
    
    @Input() chequera: Chequera = new Chequera();
    public cuentas: Cuenta[] = [];
    public book_types: string[] = environment.book_types;
    
    @Output() actualize: EventEmitter<Chequera> = new EventEmitter<Chequera>();
    @Output() cancel: EventEmitter<string> = new EventEmitter<string>();

    public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Chequeras', ref: '#/chequeras' }];
    public BANK_LOGO_IDS = environment.bank_logo_ids;
    
    constructor(protected messageService: MessageService, private cuentaService: CuentaService, private router: Router, private globalVars: GlobalVars) {
        super(messageService);
    }

    ngOnInit(): void {
        if(this.chequera?.id) {
            this.breadcrumbItems.push({ title: `Cuenta: ${this.chequera.cuenta.nombre} - Números: ${this.chequera.numero_desde} / ${this.chequera.numero_desde}`, active: true });
        }
        this.cuentaService.getCuentas({per_page: 1000}, {tipo_eq: 'BANCO', distrito_id_eq: this.globalVars.getActualDistrito(), sort: 'nombre'}).subscribe(
            (rta: any) => {
                this.cuentas = rta.data;
            }
        );
    }
    ngAfterViewInit(): void {
        this.firstElement.nativeElement.focus();
    }
    
    public save(event){
        this.beforeSubmit(event);
        if(! this.chequera.id){
            this.cuentaService.createChequera(this.chequera).subscribe(
                (chequera: Chequera) => {
                    this.showSuccess(true, 'Chequera creada correctamente');
                    this.actualize.emit(chequera);
                    this.router.navigate(['/chequeras', chequera.id]);   
                },err => {
                    this.showError(err, true);
                });
        } else {
            this.cuentaService.updateChequera(this.chequera).subscribe(
                (chequera: Chequera) => {
                    this.showSuccess(true, 'Chequera modificada correctamente');
                },err => {
                    this.showError(err, true);
                });
        }
    }
    
    public cancelForm($event?){
        this.cancel.emit("Cancelacion");
    }
    
    //
    //UTILS
    public customCompareCuenta(c1: Cuenta, c2: Cuenta) {
        if(c1 && c2){
            return c1.id == c2.id;
        }else{
            return c1 == c2;
        }
    }

    public checkNumber(name, value) {
        if (value == null || value <= 0) {
            this.setInputError(name, "Campo incorrecto");
        } else {
            this.removeInputError(name);
        }
    }
}