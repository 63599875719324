import { Component, OnInit } from '@angular/core';

declare var $:any;

declare interface DataTable {
    headerRow: string[];
    footerRow: string[];
    dataRows: string[][];
}

@Component({
    selector: 'data-table-cmp',
    templateUrl: 'alta-factura.component.html'
})

export class AltaFacturaComponent implements OnInit{
    public dataTable: DataTable;
    ngOnInit(){
        this.dataTable = {
            headerRow: [ 'Nombre', 'Nota', 'Var.', 'Envase', 'Cantidad', 'Importe', 'Descuento', 'IVA', 'Total Línea', 'Tot Línea', 'Acciones' ],
            footerRow: [ 'Nombre', 'Nota', 'Var.', 'Envase', 'Cantidad', 'Importe', 'Descuento', 'IVA', 'Total Línea', 'Tot Línea', 'Acciones' ],
            dataRows: [
                ['Producto 1', '2024', '', '1', '5', '1200.00', '200.00', '21%', '1500.00', '1500.00' ,''],
                ['Producto 2', '1025', '', '1', '2', '2200.00', '100.00', '21%', '2500.00', '2500.00' ,''],
                ['Producto 3', '2024', '', '1', '5', '1200.00', '200.00', '21%', '1500.00', '1500.00' ,''],
                ['Producto 4', '1025', '', '1', '2', '2200.00', '100.00', '21%', '2500.00', '2500.00' ,''],
            ]
         };
    }
}
