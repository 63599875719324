import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {CuentaService} from '../cuenta.service';
import {DistritoService} from '../../module-distrito/distrito.service';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Cuenta, Chequera} from '../models';
import {User} from '../../models/user';
import {Distrito} from '../../module-distrito/models';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
import { FilterCacheUtilsService } from 'app/services/filter-cache-utils.service';
import { FilterCache } from 'app/models/filter-cache';
import { environment } from 'environments/environment';

const VIEW_ID = "chequeras";

@Component({
  selector: 'chequeras',
  templateUrl: './chequeras.component.html'
})

export class ChequerasComponent extends GenericFormComponent implements OnInit {
  public actualUser: User;
    
  public chequeras: Chequera[];
  public params = {distrito: null, page: 1, per_page: 10};
  public filtroDefault = {numero_desde_lteq: null, numero_hasta_gteq: null, cuenta_id_eq: null, finalizada_eq: 0, emision_gteq: null, emision_lteq: null, sort: 'emision,desc'};
  public filtro = Object.assign({},this.filtroDefault);
  public someFiltersApplied: boolean = false;
  
  public cuentas: Cuenta[] = [];
  public distritos: Distrito[] = [];
  
  //FORM
  public chequera: Chequera;
    
  public sortOptions: { [id: string] : string } = { 'numero,desc': 'Número', 'emision,desc': 'Emision' };
  public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Chequeras', active: true }];
  public BANK_LOGO_IDS = environment.bank_logo_ids;


  constructor(protected messageService: MessageService, private globalVars: GlobalVars, private cuentaService: CuentaService, 
    private route: ActivatedRoute, private distritoService: DistritoService, private filterCacheUtilsService: FilterCacheUtilsService) {
    super(messageService);
  }

  ngOnInit() {
    this.actualUser = this.globalVars.actualUser;
    this.loadInitFilters();
    this.loadCacheFiltersParams();
    this.filter();    
    this.distritoService.getDistritos({per_page: 1000}, {sort: 'nombre'}).subscribe(
        (rta: any) => {
            this.distritos = rta.data;
        }
    );
    this.updateCuentas();
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    if (this.filtro.numero_desde_lteq == '') {
      this.filtro.numero_desde_lteq = null;
    }
    this.filtro.numero_hasta_gteq = this.filtro.numero_desde_lteq;
    this.cursorWait();
    this.someFiltersApplied = this.globalVars.isSomeFilterApplied(this.filtro);
    this.cuentaService.getChequeras(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.chequeras = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
            this.filterCacheUtilsService.setFilterParams(VIEW_ID, this.filtro, this.params);
        },
        error => {
            this.cursorDefault();
        }
    );
  }
      
  public updateCuentas(resetCuenta = false) {
    if (resetCuenta) {
        this.filtro.cuenta_id_eq = null
    }
    this.cuentaService.getCuentas({per_page: 1000}, {tipo_eq: 'BANCO', distrito_id_eq: this.params.distrito, sort: 'nombre'}).subscribe(
        (rta: any) => {
            this.cuentas = rta.data;
        }
    );
  }
  
  public changeFilterDate(value){
    this.filtro.emision_gteq = value.from;
    this.filtro.emision_lteq = value.to;
    this.filter(1);
  }
  
  //ACCIONES  
  onNotifyDelete(chequera: Chequera):void {
    this.deleteChequera(chequera);
  }
    
  public deleteChequera(chequera: Chequera) {
    this.beforeSubmitList();
    this.cuentaService.deleteChequera(chequera.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Chequera eliminada correctamente');
            //ELIMINO DE LA LISTA      
            this.chequeras.forEach(function(chequeraFor, index, object) {
                if(chequeraFor.id == chequera.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }  
  
  //FORMULARIO ALTA/EDICION
  //Para el Form
  public newChequera(){
    this.chequera = new Chequera();
    this.chequera.emision = new Date().toISOString().slice(0,10) + " 00:00:00";
  }
  
  //Desde el formulario
  public actualizeForm(user): void {
    this.cuentas.splice(0, 0, user);
    this.cancelForm();
  }    
  public cancelForm(event?): void{
    this.chequera = null;
  }

  private loadInitFilters(): void {
    this.params.distrito = this.globalVars.getActualDistrito();
    this.filtro.cuenta_id_eq = this.route.snapshot.queryParams['cuenta'] ? Number(this.route.snapshot.queryParams['cuenta']) : null;
  }

  private loadCacheFiltersParams() {
    let cacheFilters: FilterCache = this.filterCacheUtilsService.getFilterParams(VIEW_ID);
    if (!cacheFilters) return;

    this.filtro = cacheFilters.filters;
    this.params = cacheFilters.params;
  }

  public resetFilters() {
    this.filtro = Object.assign({}, this.filtroDefault);
    this.loadInitFilters();
    this.filter();
  }
  
}