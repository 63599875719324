import { Component, OnInit, Input } from '@angular/core';

import {FacturaService} from '../factura.service';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Factura, NotaDeCredito} from '../models';
 
@Component({
  selector: 'notas',
  templateUrl: './notas.component.html'
})
export class NotasComponent extends GenericFormComponent implements OnInit {
  @Input() factura: Factura;
  public notas: NotaDeCredito[];
  //FORM
  public nota: NotaDeCredito;
    
  constructor(protected messageService: MessageService, private facturaService: FacturaService) {
    super(messageService);
  }

  ngOnInit() {
    this.filter();
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    this.cursorWait();
    this.facturaService.getNotasFactura(this.factura.id).subscribe(
        (notas: NotaDeCredito[]) => {
            this.cursorDefault();
            this.notas = notas;
        },
        error => {
            this.cursorDefault();
        }
    );
  }
  
  //ACCIONES    
  onNotifyDelete(nota: NotaDeCredito):void {
    this.deleteNota(nota);
  }
    
  public deleteNota(nota: NotaDeCredito) {
    this.beforeSubmitList();
    this.facturaService.deleteNota(nota.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Nota de credito eliminada correctamente');
            //ELIMINO DE LA LISTA      
            this.notas.forEach(function(notaFor, index, object) {
                if(notaFor.id == nota.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }
  
    
  //FORMULARIO ALTA/EDICION
  //Para el Form
  public newNota(){
    this.nota = new NotaDeCredito();
    this.nota.factura = this.factura;
    this.nota.monto = this.factura.monto;
    this.nota.fecha = new Date().toISOString().slice(0,10) + " 00:00:00";
  }
  
  //Desde el formulario
  public actualizeForm(nota: NotaDeCredito): void {
    this.notas.splice(0, 0, nota);
    this.cancelForm();
  }   
  public cancelForm(event?): void{
    this.nota = null;
  }
  
}