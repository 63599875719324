<div class="card">
    <div class="card-content" [ngClass]="this.withBackground ? 'color-inner-forms' : ''">
        <h4 class="card-title" *ngIf="!cheque.id">Nuevo cheque</h4>
        <hr *ngIf="!cheque.id">
        
        <breadcrumb [items]="breadcrumbItems" *ngIf="cheque.id"></breadcrumb>

        <form class="form-horizontal" (submit)="save($event)" #datosForm="ngForm">
            <div class="form-group" *ngIf="cheque.id != null">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Chequera</label>
                        <div class="col-md-9">
                            <a [routerLink]="['/chequeras', cheque.chequera.id]" rel="tooltip" title="Chequera">
                                {{cheque.chequera.numero_desde}} - {{cheque.chequera.numero_hasta}}
                            </a>
                        </div>  
                    </div>    
                </div>  
                <div class="col-md-6"> 
                    <div class="row">
                        <label class="col-md-3 label-on-left">Banco</label>
                        <span [class]="'bank-logo bank-logo-' + cheque.chequera.cuenta.banco.id" *ngIf="BANK_LOGO_IDS.includes(cheque.chequera.cuenta.banco.id)" [title]="cheque.chequera.cuenta.banco.nombre"></span>
                        <span *ngIf="!BANK_LOGO_IDS.includes(cheque.chequera.cuenta.banco.id)">{{cheque.chequera.cuenta.banco.nombre}}</span>
                    </div> 
                </div> 
            </div>

            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Número de Cheque</label>
                        <div class="col-md-9">                                  
                            <input #firstElementCheque type="number" name="numero" [(ngModel)]="cheque.numero" class="form-control" placeholder="Número de cheque" (blur)="changeNumeroCheque()" required (blur)="checkNumber('numero', cheque.numero)">
                            <small [hidden]="!inputError.numero" [innerHtml]="inputError.numero" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Monto</label>
                        <div class="col-md-9">                                  
                            <input type="number" name="monto" [(ngModel)]="cheque.monto" class="form-control" placeholder="1000" required (blur)="checkNumber('monto', cheque.monto)">
                            <small [hidden]="!inputError.monto" [innerHtml]="inputError.monto" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>
            
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Lugar</label>
                        <div class="col-md-9">                                  
                            <input type="text" name="lugar" [(ngModel)]="cheque.lugar" class="form-control" placeholder="La Plata" required>
                            <small [hidden]="!inputError.lugar" [innerHtml]="inputError.lugar" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Nombre</label>
                        <div class="col-md-9">                                  
                            <input type="text" name="nombre" [(ngModel)]="cheque.nombre" class="form-control" placeholder="Juan Perez" required>
                            <small [hidden]="!inputError.nombre" [innerHtml]="inputError.nombre" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>
            
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Fecha emisión</label>
                        <div class="col-md-9">
                            <input-datepicker [(dateModel)]="cheque.emision" [isStringFormat]="true" [required]="true" ></input-datepicker>
                            <small [hidden]="!inputError.emision" [innerHtml]="inputError.emision" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Fecha acreditación</label>
                        <div class="col-md-9">                 
                            <input-datepicker [(dateModel)]="cheque.acreditacion" [isStringFormat]="true" [required]="true" [disabled]="!cheque.diferido"></input-datepicker>
                            <small [hidden]="!inputError.acreditacion" [innerHtml]="inputError.acreditacion" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>
            
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Fecha vencimiento</label>
                        <div class="col-md-9">                                  
                            <input-datepicker [(dateModel)]="cheque.vencimiento" [isStringFormat]="true" [required]="true" [disabled]="true"></input-datepicker>
                            <small [hidden]="!inputError.vencimiento" [innerHtml]="inputError.vencimiento" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Estado</label>
                        <div class="col-md-9">
                            <select class="form-control" data-style="btn btn-wd btn-block" name="estado" [(ngModel)]="cheque.estado">
                                <option [ngValue]="null">Seleccione un Estado</option>
                                <option *ngFor="let estado of estados" [ngValue]="estado">{{estado | translate: 'ESTADO_CHEQUE'}}</option>
                            </select>
                            <small [hidden]="!inputError.estado" [innerHtml]="inputError.estado" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>
                
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Opciones</label>
                        <div class="col-md-9">
                            <div class="checkbox">
                                <input type="checkbox" name="no_a_la_orden" value="1" [(ngModel)]="cheque.no_a_la_orden" checked>
                                <label>No a la Orden</label>
                            </div>
                            <div class="checkbox">
                                <input type="checkbox" name="diferido" value="1" [(ngModel)]="cheque.diferido" (change)="changeDiferido()" checked>
                                <label>Diferido</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="form-group" *ngIf="cheque.movimiento != null">
                <div class="col-md-12 mt-2">
                    <h4 class="card-title">Movimiento</h4>
                </div>

                <div class="col-md-12 mt-1">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Descripción</label>
                        <div class="col-md-9">                                  
                            <span class="provider-data">{{cheque.movimiento.descripcion ?? '-'}} </span>
                            <span *ngIf="cheque.movimiento.anulado"> (ANULADO)</span>
                        </div>                            
                    </div>
                </div>


                <div class="col-md-4 mt-1">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Tipo</label>
                        <div class="col-md-9">
                            <span class="provider-data" *ngIf="cheque.movimiento.cuentadestino == null || cheque.movimiento.cuenta.id == cheque.chequera.cuenta.id">Egreso</span>
                            <span class="provider-data" *ngIf="cheque.movimiento.cuentadestino && cheque.movimiento.cuentadestino.id == cheque.chequera.cuenta.id">Ingreso</span>
                        </div>                            
                    </div>
                </div>

                <div class="col-md-8 mt-1">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Cuenta Destino</label>
                        <div class="col-md-9">                                  
                            <div>
                                <div *ngIf="cheque.movimiento.cuentadestino && cheque.movimiento.cuentadestino.id != cheque.chequera.cuenta.id">
                                    <span *ngIf="cheque.movimiento.cuentadestino.tipo == 'BANCO' && cheque.movimiento.cuentadestino.banco_id">
                                        <span [class]="'bank-logo bank-logo-' + cheque.movimiento.cuentadestino.banco_id" *ngIf="BANK_LOGO_IDS.includes(cheque.movimiento.cuentadestino.banco_id)"></span>
                                    </span>
                                </div>
                                <span class="provider-data" *ngIf="!cheque.movimiento.cuentadestino" class="text-center">-</span>
                            </div>
                            <div>
                                <div *ngIf="cheque.movimiento.cuentadestino && cheque.movimiento.cuentadestino.tipo != 'BANCO' && cheque.movimiento.cuentadestino.id != cheque.chequera.cuenta.id">
                                    <span class="provider-data"> {{cheque.movimiento.cuentadestino.nombre}}</span>
                                </div>
                            </div>
                        </div>                            
                    </div>
                </div>


                <div class="col-md-4 mt-1">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Moneda</label>
                        <div class="col-md-9">                                  
                            <span class="provider-data">{{cheque.movimiento.moneda | translate: 'MONEDA'}}</span>
                        </div>                            
                    </div>
                </div>

                <div class="col-md-4 mt-1">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Monto</label>
                        <div class="col-md-9">                                  
                            <span class="provider-data">{{cheque.movimiento.monto}}</span>
                        </div>                            
                    </div>
                </div>

                <div class="col-md-4 mt-1">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Fecha</label>
                        <div class="col-md-9">                                  
                            <span class="provider-data">{{cheque.movimiento.fecha_contable | date: 'dd/MM/yyyy'}}</span>
                        </div>                            
                    </div>
                </div>


                <div *ngIf="cheque.movimiento.ordenpago">
                    <div class="col-md-12 mt-2">
                        <h5 class="card-title">Orden De Pago</h5>
                    </div>
    
                    <div class="col-md-4 mt-1">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Número</label>
                            <div class="col-md-9">
                                <a [routerLink]="['/ordenespago', cheque.movimiento.ordenpago.id]" rel="tooltip" title="Orden de pago">{{cheque.movimiento.ordenpago.punto_venta}} - {{cheque.movimiento.ordenpago.numero}}</a>
                                <span class="provider-data" *ngIf="cheque.movimiento.ordenpago.anulada"> (ANULADA)</span>
                            </div>                            
                        </div>
                    </div>
    
                    <div class="col-md-4 mt-1">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Fecha</label>
                            <div class="col-md-9">                           
                                <span class="provider-data">{{cheque.movimiento.ordenpago.fecha | date: 'dd/MM/yyyy'}}</span>
                            </div>                            
                        </div>
                    </div>
    
                    <div class="col-md-4 mt-1">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Monto / A pagar</label>
                            <div class="col-md-9">                           
                                <span class="provider-data">$ {{cheque.movimiento.ordenpago.monto | number: '1.2-2'}} / $ {{cheque.movimiento.ordenpago.monto_descuentos | number: '1.2-2'}}</span>
                            </div>                            
                        </div>
                    </div>
    
                    <div class="col-md-4 mt-1">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Ret. Gana.</label>
                            <div class="col-md-9">                           
                                <span class="provider-data">$ {{cheque.movimiento.ordenpago.retencion | number: '1.2-2'}}</span>
                            </div>                            
                        </div>
                    </div>
    
                    <div class="col-md-4 mt-1">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Ret. Caja</label>
                            <div class="col-md-9">                           
                                <span class="provider-data">$ {{cheque.movimiento.ordenpago.descuentos | number: '1.2-2'}}</span>
                            </div>                            
                        </div>
                    </div>

                    <div class="col-md-4 mt-1">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Pagado</label>
                            <div class="col-md-9">                           
                                <span class="provider-data">$ {{cheque.movimiento.ordenpago.pagado | number: '1.2-2'}}</span>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>

            <br>

            <div class="row text-center mt-2">
                <button class="btn btn-default btn-cancel" type="button" (confirm-click)="cancelForm($event)" confirm-message="¿Está seguro que desea cancelar este formulario?">Cancelar</button>
                <button class="btn btn-primary ml-1" type="submit" [disabled]="submitted">Generar Cheque</button>
            </div>
            <div class="clearfix"></div>
        </form>
    </div>
</div>