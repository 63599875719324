
import {first} from 'rxjs/operators';
import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {environment} from '../../../environments/environment';

import {ViaticoService} from '../viatico.service';
import {DistritoService} from '../../module-distrito/distrito.service';
import {UserService} from '../../module-user/user.service';
import {GlobalVars} from '../../services/globalVars';
import { MessageService } from '../../services/message.service';
import {PdfService} from '../../services/pdf.service';

import {User, PermisoViatico, Ciudad} from '../../models/user';
import {Viatico, Periodo, NotaCreditoViatico, ImputacionViatico} from '../models';
import {Proveedor} from '../../module-proveedor/models';
import {Distrito} from '../../module-distrito/models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';

declare var $: any;

@Component({
  selector: 'viaticos_form',
  templateUrl: 'viaticos_form.html'
})
export class ViaticosFormComponent extends GenericFormComponent implements OnInit, AfterViewInit{
    @Input() id: number = null;
    public actualUser: User;
    
    viatico: Viatico = new Viatico();
    public tipos: string[] = environment.tiposViatico;
    public ciudades: Ciudad[] = [];
    public periodos: Periodo[] = [];
    public permisosViaticos: PermisoViatico[] = [];
    public distritos: Distrito[] = [];
    public imputaciones: ImputacionViatico[] = [];

    public items_hotel: number = 0;
    public item_hotel_monto: number = 0;
    public monto_real: number = 0;

    public notaPendiente: NotaCreditoViatico = null;
    public notaGenerada: NotaCreditoViatico = null;
    public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Carga de viáticos', ref: '#/viaticos' }];
    
    
    @ViewChild('fac_select_ciudades_ori', { static: true }) selectCiudadesOri: ElementRef;
    @ViewChild('fac_select_ciudades_des', { static: true }) selectCiudadesDes: ElementRef;
    
    
    constructor(protected messageService: MessageService, private viaticoService: ViaticoService, private route: ActivatedRoute, private router: Router,
        private globalVars: GlobalVars, private userService: UserService, private ngZone: NgZone, private distritoService: DistritoService,
        private pdfService: PdfService) {
        super(messageService);
    }

    ngOnInit(): void {
        this.actualUser = this.globalVars.actualUser;
        this.distritoService.getDistritos({per_page: 1000}, {sort: 'nombre'}).subscribe(
            (rta: any) => {
                this.distritos = rta.data;
            }
        );
        this.loadImputacionesContable();
        if (this.id) {
            this.initialLoad(this.id);
        } else {
            this.route.params.subscribe(
                params => {
                    if (params.id) {
                        this.initialLoad(params.id);
                    } else {
                        if (! this.actualUser.hasAtLeastOneRolByName(['cargador_viatico'])) {
                            this.userService.getPermisosViaticos({}, {user_id_eq: this.actualUser.id}).subscribe(
                                (permisos: PermisoViatico[]) => {
                                    if (permisos.length == 0) {
                                        alert ('No tiene asociada ninguna persona para asignar viáticos');
                                        return;
                                    }
                                    this.permisosViaticos = permisos;
                                    this.viatico.proveedor = this.permisosViaticos[0].proveedor;
                                }
                            )
                        }
                        this.viatico.emision = new Date().toISOString().slice(0,10) + " 00:00:00";
                        this.viatico.distrito = this.distritoService.getLoadDistrito(this.globalVars.getActualDistrito());
                        this.viatico.distrito_id = this.viatico.distrito.id;
                        this.viaticoService.getPeriodos({page: 1, per_page: 2}, {fecha_lteq: new Date().toISOString().slice(0,10) + " 23:59:59",sort: 'fecha,desc'}).subscribe(
                            (rta) => {
                                this.periodos = rta.data;
                            }
                        );
                        this.breadcrumbItems.push({ title: 'Agregar', active: true });
                        
                        this.loadCiudades();
                    }
                }
            );
        }
    }
    
    ngAfterViewInit() {
        this.ngZone.onStable.pipe(first()).subscribe(() => {
            $(this.selectCiudadesOri.nativeElement).selectpicker();
            $(this.selectCiudadesDes.nativeElement).selectpicker(); 
        });
    }

    public initialLoad(id: number) {
        this.viaticoService.getViatico(id).subscribe(
            (viatico: Viatico) => {
                this.viatico = viatico;
                this.breadcrumbItems.push({ title: `${this.viatico.numero}`, active: true });
                
                if (this.viatico.distrito.id != this.globalVars.getActualDistrito() && ! this.globalVars.actualUser.multi_distrito) {
                    this.messageService.showNotification('top', 'center', 'danger', 'Usted no tiene acceso a este viático');
                    this.router.navigate(['/viaticos']);
                } else if (this.viatico.distrito.id != this.globalVars.getActualDistrito() && this.globalVars.actualUser.multi_distrito) {
                    //fix de multi_distrito. Todos los usuarios multi_distritos puede generar facturas cruzadas
                    //this.globalVars.setActualDistrito(this.viatico.distrito.id);
                }
                
                this.permisosViaticos = [{id: 1, user: null, proveedor: this.viatico.proveedor}];
                this.loadTipoViaticoHotel();
                if (! this.viatico.autorizado) {
                    this.loadNotaPendiente();
                } else {
                    this.monto_real = this.viatico.monto;
                    this.loadNotaUsada();
                    this.loadNotaGenerada();
                }

                this.periodos = [this.viatico.periodo];
                
                this.loadCiudades();

            }, error => {
                this.messageService.showNotification('top', 'center', 'danger', 'No se encontro el viático');
                this.router.navigate(['viaticos']);
            }
        );
    }
    
    public loadCiudades() {
        this.viaticoService.getCiudades({per_page: 1000}, {sort: 'nombre'}).subscribe(
            rta => {
                this.ciudades = rta.data;
                this.ngZone.onMicrotaskEmpty.pipe(first()).subscribe(() => {
                    $(this.selectCiudadesOri.nativeElement).selectpicker('refresh');
                    //$(this.selectCiudadesOri.nativeElement).selectpicker('val', this.viatico.direccion_origen);
                    $(this.selectCiudadesDes.nativeElement).selectpicker('refresh');
                    //$(this.selectCiudadesDes.nativeElement).selectpicker('val', this.viatico.direccion_destino);
                });
            }
        );
    }
    
    public loadTipoViaticoHotel() {
        this.viaticoService.getTipoViaticos(this.viatico.periodo.id, {per_page: 1000}, {tipo_eq: 'H'}).subscribe(
            (rta: any) => {
                this.item_hotel_monto = rta.data[0].monto;
            }
        );
    }

    public loadNotaPendiente() {
        this.viaticoService.getNotaProveedor(this.viatico.proveedor.id).subscribe(
            (nota: NotaCreditoViatico) => {
                this.notaPendiente = nota;
            }, error => {
            }
        );
    }
    
    public loadNotaUsada() {
        this.viaticoService.getNotaUsadaViatico(this.viatico.id).subscribe(
            (nota: NotaCreditoViatico) => {
                //usada y pendiente es la misma
                this.notaPendiente = nota;
            }, error => {
            }
        );
    }
    
    public loadNotaGenerada() {
        this.viaticoService.getNotaViatico(this.viatico.id).subscribe(
            (nota: NotaCreditoViatico) => {
                this.notaGenerada = nota;
            }, error => {
            }
        );
    }

    public loadImputacionesContable() {
        this.viaticoService.getImputacionesViatico({per_page: 1000}, {nombre_matches: 'viatico'}).subscribe(
            (imputaciones: any) => {
                this.imputaciones = imputaciones.data;
            }
        );
    }
    
    public save(event){
        this.beforeSubmit(event);
        if(!this.viatico.id){
            this.viatico.cantidad_veces = 1;
            this.viaticoService.createViatico(this.viatico).subscribe(
                (viatico: Viatico) => {
                    this.noShowSuccess();
                    this.router.navigate(['/viaticos', viatico.id]);     
                },err => {
                    this.showError(err, true);
                });
        }else{
            this.viatico.cantidad_veces = 1;
            this.viaticoService.updateViatico(this.viatico).subscribe(
                (viatico: Viatico) => {
                    this.showSuccess(true, 'Viático modificado correctamente');  
                    this.viatico = viatico;
                },err => {
                    this.showError(err, true);
                });
        }
    }
    
    changeState(viatico: Viatico, estado: string, event){
        this.beforeSubmitList(event);
        this.viaticoService.cambiarEstadoViatico(viatico, estado).subscribe(
            (viatico: Viatico) => {
                this.showSuccessList(true, 'Viático modificado correctamente');
                this.viatico.estado = viatico.estado;
                this.viatico.autorizado = viatico.autorizado;
                this.viatico.monto_neto = viatico.monto_neto;
                if (this.viatico.autorizado) {
                    this.monto_real = this.viatico.monto;
                    this.loadNotaUsada();
                }
            }, error => {
                this.showErrorList(error, true);
            }
        );
    }
    
    saveMontoReal(event){
        this.beforeSubmitList(event);
        this.viaticoService.guardarMontoViatico(this.viatico, this.monto_real).subscribe(
            (viatico: Viatico) => {
                this.showSuccessList(true, 'Viático modificado correctamente');
                this.viatico.monto_real = viatico.monto_real;
                this.loadNotaGenerada();
            }, error => {
                this.showErrorList(error, true);
            }
        );
    }

    public pdfViatico() {
        this.cursorWait();
        this.viaticoService.pdfViatico(this.viatico.id).subscribe(
            rta => {
                this.cursorDefault();
                this.pdfService.loadPdf("viatico", rta); 
            },
            error => {
                this.cursorDefault();
                alert('Ups! No pudimos imprimir el pdf');
            }
        );
    }
    
    //
    //UTILS
    public customCompareCiudad(c1: any, c2: any) {
        if(c1 && c2){
            return c1 == c2;
        }else{
            return c1 == c2;
        }
    }
    
    public customComparePeriodo(p1: Periodo, p2: Periodo) {
        if(p1 && p2){
            return p1.id == p2.id;
        }else{
            return p1 == p2;
        }
    }
    
    public customCompareProveedor(p1: Proveedor, p2: Proveedor) {
        if(p1 && p2){
            return p1.id == p2.id;
        }else{
            return p1 == p2;
        }
    }

    public actualizarMontoFacturado() {
        this.monto_real = this.viatico.monto - (this.items_hotel * this.item_hotel_monto);
        if (this.monto_real < 0) {
            this.monto_real = 0;
        }
    }

    public onSelectProvider() {
        this.permisosViaticos = [{id: 1, user: null, proveedor: this.viatico.proveedor}];
    }
}