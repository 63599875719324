<div class="row">
    <div class="card">
        <div class="card-content">
            <h4 class="card-title">Ordenes</h4>
            <hr>

            <div class="row">                
                <div class="col-md-12 table-responsive">
                    <table class="table table-hover">
                        <thead class="text-primary">
                            <tr>
                                <th><b>Número Orden</b></th>
                                <th><b>Fecha</b></th>
                                <th><b>Imputado</b></th>
                                <th><b>Retención Ganancias</b></th>
                                <th><b>Retención Caja</b></th>
                                <th><b>Estado</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor='let orden of ordenes'>
                                <td>
                                    <a [routerLink]="['/ordenespago', orden.ordenpagogeneral.id]" rel="tooltip" title="Editar Orden">{{orden.ordenpagogeneral.punto_venta}} - {{orden.ordenpagogeneral.numero}}</a>
                                    <span *ngIf="orden.ordenpagogeneral.anulada"> (ANULADA)</span>
                                </td>
                                <td>{{orden.ordenpagogeneral.fecha | date: 'dd/MM/yyyy'}}</td>
                                <td>$ {{orden.monto | number: '1.2-2'}}</td>
                                <td>$ {{orden.retencion | number: '1.2-2'}}</td>
                                <td>{{orden.porcentaje_descuentos | number: '1.2-2'}}% - $ {{orden.descuentos | number: '1.2-2'}}</td>
                                
                                <td>
                                    <span *ngIf="orden.ordenpagogeneral.anulada">Anulada</span>
                                    <span *ngIf="orden.ordenpagogeneral.verificada">Verificada</span>
                                    <span *ngIf="orden.ordenpagogeneral.revision && !orden.ordenpagogeneral.anulada">Revisión</span>
                                    <span *ngIf="orden.ordenpagogeneral.finalizada && !orden.ordenpagogeneral.revision && !orden.ordenpagogeneral.verificada && !orden.ordenpagogeneral.anulada">Finalizada</span>
                                    <span *ngIf="!orden.ordenpagogeneral.finalizada && !orden.ordenpagogeneral.revision">Iniciada</span>
                                </td>
                            </tr>
                            <tr *ngIf="ordenes != null && ordenes.length == 0" class="text-center">
                                <td colspan="6">Sin ordenes</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>