<div class="row">
    <div class="card">
        <div class="card-content">
            <h4 class="card-title">Notas de Credito</h4>
            <hr>

            <div class="row">
                <div class="col-md-12 table-responsive">
                    <table class="table table-hover">
                        <thead class="text-primary">
                            <tr>
                                <th><b>Fecha</b></th>
                                <th><b>Monto</b></th>
                                <th><b>Moneda</b></th>
                                <th><b>Orden de Pago</b></th>
                                <th><b>Acciones</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor='let nota of notas'>
                                <td>{{nota.fecha | date: 'dd/MM/yyyy'}}</td>
                                <td>$ {{nota.monto | number: '1.2-2'}}</td>
                                <td>{{nota.moneda | translate: 'MONEDA'}}</td>
                                <td>
                                    <span *ngIf="nota.ordenpago">{{nota.ordenpago.print()}}</span>
                                    <span *ngIf="!nota.ordenpago">-</span>
                                </td>
                                
                                <td class="td-actions text-right">
                                    <button
                                        [disabled]="nota.ordenpago"
                                        [title]="nota.ordenpago ? 'No es posible eliminar la nota.' : 'Eliminar Nota'" 
                                        type="button" rel="tooltip" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="nota" confirm-message="¿Está seguro que desea eliminar la nota de credito?">
                                        <i class="cmp-i eliminar-borrar i-sm"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr *ngIf="notas != null && notas.length == 0" class="text-center">
                                <td colspan="5">Sin notas de credito</td>
                            </tr>
                        </tbody>
                    </table>
                    <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)" *ngIf="notas != null && notas.length > 0"></pager>
                </div>
            </div>
        </div>
    </div>
</div>