import { Component, OnInit } from '@angular/core';

import {DistritoService} from '../distrito.service';
import {GlobalVars} from '../../services/globalVars';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Distrito} from '../models';
import {User} from '../../models/user';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
 
@Component({
  selector: 'distritos',
  templateUrl: './distritos.component.html'
})
export class DistritosComponent extends GenericFormComponent implements OnInit {
  public actualUser: User;
    
  public distritos: Distrito[];
  public params = {page: 1, per_page: 10};
  public filtro = {nombre_matches: null, sort: 'nombre'};
  //FORM
  public distrito: Distrito;

  public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Distritos', active: true }];
    
  constructor(protected messageService: MessageService, private globalVars: GlobalVars, private distritoService: DistritoService) {
    super(messageService);
  }

  ngOnInit() {
    this.actualUser = this.globalVars.actualUser;
      
    this.filter();
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.cursorWait();
    this.distritoService.getDistritos(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.distritos = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
        },
        error => {
            this.cursorDefault();
        }
    );
  }
  
  //ACCIONES    
  onNotifyDelete(distrito: Distrito):void {
    this.deleteDistrito(distrito);
  }
    
  public deleteDistrito(distrito: Distrito) {
    this.beforeSubmitList();
    this.distritoService.deleteDistrito(distrito.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Distrito eliminado correctamente');
            //ELIMINO DE LA LISTA      
            this.distritos.forEach(function(distritoFor, index, object) {
                if(distritoFor.id == distrito.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }
  
    
  //FORMULARIO ALTA/EDICION
  //Para el Form
  public newDistrito(){
    this.distrito = new Distrito();
  }
  public updateDistrito(distrito: Distrito){
    this.distrito = new Distrito(distrito);
  }
  
  //Desde el formulario
  public actualizeForm(distrito): void {
    let exists= false;
    this.distritos.forEach(function(distritoFor, index, object) {
        if(distritoFor.id == distrito.id) {
            object[index]= distrito;
            exists= true;
        }
    });
    if(!exists){
        this.distritos.splice(0, 0, distrito);
    }
    this.cancelForm();
  }    
  public cancelForm(event?): void{
    this.distrito = null;
  }
  
  //
  //UTILS
  public hasAccess(id: number) {
    return this.actualUser.multi_distrito || this.actualUser.distrito.id == id;
  }
}