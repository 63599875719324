import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';

import {GlobalVars} from '../../services/globalVars';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
import { Reporte } from '../reportes.models';
import { ActivatedRoute } from '@angular/router';

 
@Component({
  selector: 'reportes',
  templateUrl: './reportes.component.html'
})
export class ReportesComponent extends GenericFormComponent implements OnInit {
  public distrito: number;

  public reporteSeleccionado: string;

  public reportes: Reporte[] = environment.reportes;

  public breadcrumbItems: BreadcrumbItems[] = [];

  constructor(protected messageService: MessageService, private globalVars: GlobalVars, private route: ActivatedRoute, ) {
    super(messageService);
  }

  ngOnInit() {
    this.distrito = this.globalVars.getActualDistrito();
    this.route.params.subscribe(params => {
      this.breadcrumbItems = [ {title: 'Home', ref: '#' }];
      let reporteSeleccionadoInfo = this.reportes.find((reporte) => reporte.id == params['id']);

      if (reporteSeleccionadoInfo) {
        this.reporteSeleccionado = reporteSeleccionadoInfo.id;
        this.breadcrumbItems.push({title: 'Reportes', ref: '#/reportes' });
        this.breadcrumbItems.push({title: reporteSeleccionadoInfo.title, active: true });
      } else {
        this.reporteSeleccionado = null;
        this.breadcrumbItems.push({title: 'Reportes', active: true });
      }
    });
  }
  
}