import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SearchBarService } from './search-bar.service';
import { SearchResults } from './search-bar.model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GlobalVars } from 'app/services/globalVars';

@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchBarComponent implements OnInit {
  @ViewChild('search_bar', { static: true }) selectSearch: ElementRef;

  public searchText: string = "";
  public results: SearchResults = null;
  public showResults: boolean = false;
  public onModelChange$ = new Subject<string>(); 
  
  public params = {distrito_id: null, page: 1, per_page: 5};
  public filters = {search_text: null};

  public searches: string[] = [];

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.showResults = event.target?.attributes?.id?.value == "search-bar-input" || event.target?.attributes?.id?.value == "delete-search-btn";
  }

  constructor(private searchBarService: SearchBarService, private globalVars: GlobalVars) { }

  ngOnInit(): void {
    this.searches = JSON.parse(localStorage.getItem("searches")) ?? []; 

    if(!this.globalVars.actualUser.multi_distrito)
      this.params.distrito_id = this.globalVars.getActualDistrito();

    this.onModelChange$.pipe(debounceTime(500)).subscribe(_ => {
      this.filter();
    });
  }

  public filter(){
    let localTextSearch = this.searchText;

    if(localTextSearch == this.filters.search_text)
      return;

    if(localTextSearch?.trim() == "" || localTextSearch?.trim().length < 3){
      this.results = null;
      this.showResults = true;
      return;
    }

    this.filters.search_text = localTextSearch;
    this.searchBarService.search(this.params, this.filters).subscribe(res => {
      this.results = res;
      this.showResults = true;

      if(this.searches.includes(localTextSearch?.trim()))
        this.searches = this.searches.filter(x => x != localTextSearch?.trim());
      else {
        if(this.searches.length > 4) 
          this.searches.shift();
      }

      this.searches.push(localTextSearch?.trim());
      this.saveSearchHistory();
    });
  }

  public historySearch(searchHistory: string) {
    this.searchText = searchHistory;
    this.filter();
  }

  public deleteSearch(search: string) {
    this.searches = this.searches.filter(x => x != search?.trim());
    this.saveSearchHistory();
  }

  private saveSearchHistory(){
    localStorage.setItem('searches', JSON.stringify(this.searches));
  }
}
