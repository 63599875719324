<div *ngIf="producto" class="row">
    <productos_form [producto]="producto" (actualize)="actualizeForm($event)" (cancel)="cancelForm($event)"></productos_form>
</div>

<div class="row">
    <div class="card">
        <div class="card-content">
            <h4 class="card-title">Productos</h4>
            <hr>

            <div class="row mt-2">
                <div class="col-sm-12">
                    <button class="btn btn-simple btn-icon btn-add" (click)="newProducto()" title="Agregar"><i class="cmp-i agregar"></i> Agregar</button>
                </div>  
            </div>

            <div class="row">
                <div class="col-md-12 table-responsive">
                    <table class="table table-hover">
                        <thead class="text-primary">
                            <tr>
                                <th><b>Nombre</b></th>
                                <th><b>Cantidad</b></th>
                                <th><b>Importe</b></th>
                                <th><b>Descuento</b></th>
                                <th><b>Total Línea</b></th>
                                <th class="text-center"><b>Acciones</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor='let producto of productos'>
                                <td>{{producto.nombre}}</td>
                                <td>{{producto.cantidad}}</td>
                                <td>$ {{producto.importe | number: '1.2-2'}}</td>
                                <td>$ {{producto.descuento | number: '1.2-2'}}</td>
                                <td>$ {{producto.total | number: '1.2-2'}}</td>
                                
                                <td class="td-actions text-center">
                                    <button 
                                        [disabled]="factura.pagado != 0"
                                        [title]="factura.pagado != 0 ? 'No es posible editar el producto ya que hay un monto pagado para la factura.' : 'Editar Producto'" 
                                        type="button" rel="tooltip" class="btn btn-primary btn-simple btn-xs btn-cmp-i" (click)="updateProducto(producto)">
                                        <i class="cmp-i editar i-sm"></i>
                                    </button>
                                    <button *ngIf="factura.pagado == 0" type="button" rel="tooltip" title="Eliminar Producto" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="producto" confirm-message="¿Está seguro que desea eliminar el Producto?">
                                        <i class="cmp-i eliminar-borrar i-sm"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr *ngIf="productos != null && productos.length == 0" class="text-center">
                                <td colspan="9">Sin Productos</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>