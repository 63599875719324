import { Component, OnInit, Input } from '@angular/core';

import {environment} from '../../../environments/environment';

import {FacturaService} from '../../module-factura/factura.service';
import {FacturaCommunicate} from '../../module-factura/factura.communicate';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Proveedor} from '../models';
import {Factura} from '../../module-factura/models';
 
@Component({
  selector: 'facturas_proveedor',
  templateUrl: './facturas.component.html'
})
export class FacturasComponent extends GenericFormComponent implements OnInit {
  @Input() proveedor: Proveedor;
  public facturas: Factura[];
  public params = {page: 1, per_page: 10};
  public filtro = {distrito_id: null, proveedor_id: null, fecha_gteq: null, fecha_lteq: null, sort: { key: 'fecha', order: 'desc' }};
  
  public tipos: string[] = environment.tiposFactura;
    
  constructor(protected messageService: MessageService, private facturaService: FacturaService,
    private facturaCommunicate: FacturaCommunicate, private globalVars: GlobalVars) {
    super(messageService);
  }

  ngOnInit() {
    this.filtro.proveedor_id = this.proveedor.id;
    this.filtro.distrito_id = this.globalVars.getActualDistrito();
    this.filter();
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.cursorWait();
    this.facturaService.getFacturas(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.facturas = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
        },
        error => {
            this.cursorDefault();
        }
    );
  }
    
  public changeFilterDate(value){
    this.filtro.fecha_gteq = value.from;
    this.filtro.fecha_lteq = value.to;
    this.filter(1);
  }
  
  //ACCIONES    
  onNotifyDelete(factura: Factura):void {
    this.deleteFactura(factura);
  }
    
  public deleteFactura(factura: Factura) {
    this.beforeSubmitList();
    this.facturaService.deleteFactura(factura.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Factura eliminada correctamente');
            //ELIMINO DE LA LISTA      
            this.facturas.forEach(function(facturaFor, index, object) {
                if(facturaFor.id == factura.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }

  public detalleFactura(factura: Factura) {
    this.facturaCommunicate.facturaModalPopUp(factura.id);
  }
}