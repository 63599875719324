import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {ConfigService} from '../../services/config.service';
import {GlobalVars} from '../../services/globalVars';
import {MessageService} from '../../services/message.service';

import {User} from '../../models/user';

import {ROUTES_ADM, ROUTES_OPS, ROUTES_SEL, RouteInfo} from '../sidebar/sidebar.component';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';
import { ProjectUtils } from 'app/services/utils';

@Component({
  selector: 'home',
  templateUrl: './home.component.html'
})
export class HomeComponent extends GenericFormComponent implements OnInit {
  public user: User;
  
  public params = { fecha_gteq: null, fecha_lteq: null, distrito_id: null };

  public distrito = null;

  public dashboard: any = {
    facturas_creadas: "-",
    facturas_pagadas: "-",
    facturas_pago_parcial: "-",
    facturas_sin_pagos: "-",
    ordenes_abiertas: "-",
    ordenes_creadas: "-",
    ordenes_revision: "-",
    ordenes_sin_movimientos: "-",
    proveedores_creados: "-",
    caja_chica: "-",
    cantidad_reposicion: "-",
    caja_chica_id: "-"
  };

  public menuAdm: RouteInfo[] = ROUTES_ADM;
  public menuOps: RouteInfo[] = ROUTES_OPS;
  public menuSel: RouteInfo[] = ROUTES_SEL;

  public showBalance: boolean = true;
  public loading: boolean = true;

  constructor(protected messageService: MessageService, private globalVars: GlobalVars, private router: Router,
    private configService: ConfigService, private projectUtils: ProjectUtils) {
    super(messageService);
  }

  ngOnInit() {
    this.user = this.globalVars.actualUser;
    this.params.distrito_id = this.globalVars.getActualDistrito();
    //this.filter();

    this.menuAdm = this.menuAdm.filter(menuItem => {
        return this.user.hasAtLeastOneRolByName(menuItem.roles);
    });
    this.menuOps = this.menuOps.filter(menuItem => {
        return this.user.hasAtLeastOneRolByName(menuItem.roles);
    });
    this.menuSel = this.menuSel.filter(menuItem => {
        return this.user.hasAtLeastOneRolByName(menuItem.roles);
    });

    this.showBalance = this.projectUtils.getShowBalanceConfig();
  }

  filter() {
    this.loading = true;
    let params = {...this.params};  
    this.configService.dashboard(params).subscribe(
        (rta: any) => {
            this.dashboard = rta;
            this.loading = false;
        }
    );
  }
  
  public moveTo(panel: string, url: string) {
    this.globalVars.setActualPanel(panel, false);
    this.router.navigate([url]);
  }

  public changeFilterDate(value){
    this.params.fecha_gteq = value.from;
    this.params.fecha_lteq = value.to;
    this.filter();
  }

  public toggleConfigBlance(): void {
    this.showBalance = !this.showBalance;
    this.projectUtils.setShowBalanceConfig(this.showBalance);
  }
}