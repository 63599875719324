<div class="row">
    <div class="col-sm-12 search-bar">
        <div class="input-group filter-input-group search-component">
            <span class="input-group-addon cursor-pointer" (click)="filter()"><i class="cmp-i busqueda"></i></span>
            <input #search_elastic id="search-bar-input" class="form-control search-input" type="text" placeholder="Buscar..." name="search" 
                [(ngModel)]="searchText" (keydown.enter)="filter()" autocomplete="off"
                (ngModelChange)="onModelChange$.next()" (click)="filter()">
        </div>
    </div>
</div>

<div class="row" *ngIf="showResults">
    <span class="col-sm-offset-1 col-sm-10 search-result-panel">
        <div *ngIf="!results" class="mt-1">
            <b>{{searches?.length > 0 ? "Historial" : "Ingrese al menos 3 caracteres..."}}</b>

            <div class="row cursor-pointer search-result-item" *ngFor="let search of searches">
                <span (click)="historySearch(search)" class="col-sm-10 history-item" >
                    <span class="ti-time mr-1 history-icon" style="width: 20px; vertical-align: middle;"></span> 
                    <span>{{search}}</span>
                </span>
                <button type="button" rel="tooltip" title="Eliminar busqueda" id="delete-search-btn"
                    (click)="deleteSearch(search)" class="btn btn-simple btn-xs col-sm-2">
                    <i class="cmp-i eliminar-borrar" style="width: 20px; vertical-align: top;" id="delete-search-btn"></i>
                </button>
            </div>
        </div>
        <div *ngIf="results" class="mt-1">
            <b>Proveedor</b>
            <p *ngIf="results?.proveedores?.estimatedTotalHits == 0" class="ml-1">No se encontraron resultados</p>
            <div class="row cursor-pointer search-result-item" *ngFor="let prov of results?.proveedores?.hits">
                <span innerHTML="{{prov._formatted.nombre}}   |   {{prov._formatted.cuit | cuitformat}}" 
                    [routerLink]="['/proveedores', prov.id]"></span>
            </div>
            <div class="row cursor-pointer search-result-item mt-1" *ngIf="results?.proveedores?.estimatedTotalHits > 5">
                <span [routerLink]="['/search-results']" [queryParams]="{type: 'proveedores', search_text: searchText}" *ngIf="results?.proveedores?.estimatedTotalHits != 1000">
                    Se encontraron <b>{{results?.proveedores?.estimatedTotalHits - 5}}</b> resultados mas
                </span>
                <span [routerLink]="['/search-results']" [queryParams]="{type: 'proveedores', search_text: searchText}" *ngIf="results?.proveedores?.estimatedTotalHits == 1000">
                    Se encontraron <b>999+</b> resultados mas
                </span>
            </div>
             
            <br>
            <b>Factura</b>
            <p *ngIf="results?.facturas?.estimatedTotalHits == 0" class="ml-1">No se encontraron resultados</p>
    
            <div class="row cursor-pointer search-result-item" *ngFor="let fact of results?.facturas?.hits">
                <span innerHTML="# {{fact._formatted.punto_venta}}-{{fact._formatted.numero}}   |   {{fact.fecha != '0000-00-00 00:00:00' ? (fact.fecha | date: 'dd/MM/yyyy') : '-'}}   |   {{fact._formatted.proveedor.nombre}}" 
                    [routerLink]="['/facturas', fact.id]"></span>
            </div>
            <div class="row cursor-pointer search-result-item mt-1" *ngIf="results?.facturas?.estimatedTotalHits > 5">
                <span [routerLink]="['/search-results']" [queryParams]="{type: 'facturas', search_text: searchText}" *ngIf="results?.facturas?.estimatedTotalHits != 1000">
                    Se encontraron <b>{{results?.facturas?.estimatedTotalHits - 5}}</b> resultados mas
                </span>
                <span [routerLink]="['/search-results']" [queryParams]="{type: 'facturas', search_text: searchText}" *ngIf="results?.facturas?.estimatedTotalHits == 1000">
                    Se encontraron <b>999+</b> resultados mas
                </span>
            </div>
    
            <br>
            <b>Orden de pago</b>
            <p *ngIf="results?.ordenes_pago?.estimatedTotalHits == 0" class="ml-1">No se encontraron resultados</p>
            <div class="row cursor-pointer search-result-item" *ngFor="let ord of results?.ordenes_pago?.hits">
                <span innerHTML="# {{ord._formatted.punto_venta}}-{{ord._formatted.numero}}   |   {{ord.fecha != '0000-00-00 00:00:00' ? (ord.fecha | date: 'dd/MM/yyyy') : '-'}}   |   {{ord._formatted.proveedor.nombre}}" 
                    [routerLink]="['/ordenespago', ord.id]"></span>
            </div>
            <div class="row cursor-pointer search-result-item mt-1" *ngIf="results?.ordenes_pago?.estimatedTotalHits > 5">
                <span [routerLink]="['/search-results']" [queryParams]="{type: 'ordenes_pago', search_text: searchText}" *ngIf="results?.ordenes_pago?.estimatedTotalHits != 1000">
                    Se encontraron <b>{{results?.ordenes_pago?.estimatedTotalHits - 5}}</b> resultados mas
                </span>
                <span [routerLink]="['/search-results']" [queryParams]="{type: 'ordenes_pago', search_text: searchText}" *ngIf="results?.ordenes_pago?.estimatedTotalHits == 1000">
                    Se encontraron <b>999+</b> resultados mas
                </span>
            </div>
            <br>
        </div>
    </span>
</div> 