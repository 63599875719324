
<div class="container-fluid">
    <!-- end row -->
    <div class="card">
        <div class="card-content">
            <h4 class="card-title">Proveedor</h4>
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label style="display: block;">Proveedor</label>
                        <input style="display: inline-block; width: 90%; margin-right: 3%" type="text" placeholder="Buscar" class="form-control">
                        <span class="cmp-i busqueda"></span>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Nombre</label>
                        <input type="text" placeholder="ID" class="form-control">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Dirección</label>
                        <input type="text" class="form-control" placeholder="Desde"/>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Categoría Impositiva</label>
                        <input type="text" class="form-control" placeholder="Hasta"/>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-content">
            <h4 class="card-title">Datos</h4>
            <div class="row">
                <div class="col-md-1">
                    <div class="form-group">
                        <label>Pago Manual</label>
                        <input type="checkbox" class="form-control" placeholder="" checked="checked" />
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Nùmero</label>
                        <input type="text" class="form-control" placeholder="0"/>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Fecha</label>
                        <input type="text" class="form-control datepicker" placeholder=""/>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Tipo de Pago</label>
                        <select class="form-control" data-style="btn btn-wd btn-block" title="Contado" data-size="7">
                            <option value="id">Contado</option>
                            <option value="ms">Lorem ipsum</option>
                            <option value="ca">Lorem ipsum</option>
                        </select>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Area</label>
                        <select class="form-control" data-style="btn btn-wd btn-block" title="Acción Social" data-size="7">
                            <option value="id">Acción Social</option>
                            <option value="ms">Lorem ipsum</option>
                            <option value="ca">Lorem ipsum</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Imputación Contable</label>
                        <select class="form-control" data-style="btn btn-wd btn-block" title="Gastos Pendientes de Pago" data-size="7">
                            <option value="id">Gastos Pendientes de Pago</option>
                            <option value="ms">Lorem ipsum</option>
                            <option value="ca">Lorem ipsum</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label>Tasas</label>
                        <select class="form-control" data-style="btn btn-wd btn-block" title="IVA 21%" data-size="7">
                            <option value="id">Lorem ipsum</option>
                            <option value="ms">Lorem ipsum</option>
                            <option value="ca">Lorem ipsum</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label>Moneda</label>
                        <select class="form-control" data-style="btn btn-wd btn-block" title="$(Peso Argentino)" data-size="7">
                            <option value="id">Lorem ipsum</option>
                            <option value="ms">Lorem ipsum</option>
                            <option value="ca">Lorem ipsum</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label>Cotizaciòn</label>
                        <select class="form-control" data-style="btn btn-wd btn-block" title="Sistema" data-size="7">
                            <option value="id">Sistema</option>
                            <option value="ms">Lorem ipsum</option>
                            <option value="ca">Lorem ipsum</option>
                        </select>
                    </div>
                </div>
            </div>



            <br/><br/>
        </div>
    </div> <!-- end card -->


    <div class="card">
        <div class="card-content">
            <h4 class="card-title">Documento</h4>
            <div class="toolbar">
                <button class="btn btn-simple btn-info btn-icon like">Agregar <i class="cmp-i agregar"></i></button>
                <button class="btn btn-simple btn-info btn-icon like">Eliminar <i class="cmp-i eliminar-borrar i-sm"></i></button>
            </div>
            <div class="fresh-table">
                <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%" style="width:100%">
                    <thead>
                        <tr>
                            <th>{{ dataTable.headerRow[0] }}</th>
                            <th>{{ dataTable.headerRow[1] }}</th>
                            <th>{{ dataTable.headerRow[2] }}</th>
                            <th>{{ dataTable.headerRow[3] }}</th>
                            <th>{{ dataTable.headerRow[4] }}</th>
                            <th>{{ dataTable.headerRow[5] }}</th>
                            <th>{{ dataTable.headerRow[6] }}</th>
                            <th>{{ dataTable.headerRow[7] }}</th>
                            <th>{{ dataTable.headerRow[8] }}</th>
                            <th>{{ dataTable.headerRow[9] }}</th>
                            <th class="disabled-sorting">{{ dataTable.headerRow[10] }}</th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th>{{ dataTable.footerRow[0] }}</th>
                            <th>{{ dataTable.footerRow[1] }}</th>
                            <th>{{ dataTable.footerRow[2] }}</th>
                            <th>{{ dataTable.footerRow[3] }}</th>
                            <th>{{ dataTable.footerRow[4] }}</th>
                            <th>{{ dataTable.footerRow[5] }}</th>
                            <th>{{ dataTable.footerRow[6] }}</th>
                            <th>{{ dataTable.footerRow[7] }}</th>
                            <th>{{ dataTable.footerRow[8] }}</th>
                            <th>{{ dataTable.footerRow[9] }}</th>
                            <th>{{ dataTable.footerRow[10] }}</th>
                        </tr>
                    </tfoot>
                    <tbody>
                        <tr *ngFor="let row of dataTable.dataRows">
                            <td>{{row[0]}}</td>
                            <td>{{row[1]}}</td>
                            <td>{{row[2]}}</td>
                            <td>{{row[3]}}</td>
                            <td>{{row[4]}}</td>
                            <td>{{row[5]}}</td>
                            <td>{{row[6]}}</td>
                            <td>{{row[7]}}</td>
                            <td>{{row[8]}}</td>
                            <td>{{row[9]}}</td>
                            <td>
                                <button class="btn btn-simple btn-info btn-icon like"><i class="ti-heart"></i></button>
                                <button class="btn btn-simple btn-warning btn-icon edit"><i class="cmp-i editar i-sm"></i></button>
                                <button class="btn btn-simple btn-danger btn-icon remove"><i class="ti-close"></i></button>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- end content-->
    </div>
    <!--  end card  -->

    <div class="card">
        <div class="card-content">
            <h4 class="card-title">Cheque</h4>
            <div class="toolbar">
                <button class="btn btn-simple btn-info btn-icon like">Agregar <i class="cmp-i agregar"></i></button>
                <button class="btn btn-simple btn-info btn-icon like">Eliminar <i class="cmp-i eliminar-borrar i-sm"></i></button>
            </div>
            <div class="fresh-table">
                <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%" style="width:100%">
                    <thead>
                        <tr>
                            <th>{{ chequeTable.headerRow[0] }}</th>
                            <th>{{ chequeTable.headerRow[1] }}</th>
                            <th>{{ chequeTable.headerRow[2] }}</th>
                            <th>{{ chequeTable.headerRow[3] }}</th>
                            <th>{{ chequeTable.headerRow[4] }}</th>
                            <th>{{ chequeTable.headerRow[5] }}</th>
                            <th>{{ chequeTable.headerRow[6] }}</th>
                            <th>{{ chequeTable.headerRow[7] }}</th>
                            <th>{{ chequeTable.headerRow[8] }}</th>
                            <th>{{ chequeTable.headerRow[9] }}</th>
                            <th class="disabled-sorting">{{ chequeTable.headerRow[10] }}</th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th>{{ chequeTable.footerRow[0] }}</th>
                            <th>{{ chequeTable.footerRow[1] }}</th>
                            <th>{{ chequeTable.footerRow[2] }}</th>
                            <th>{{ chequeTable.footerRow[3] }}</th>
                            <th>{{ chequeTable.footerRow[4] }}</th>
                            <th>{{ chequeTable.footerRow[5] }}</th>
                            <th>{{ chequeTable.footerRow[6] }}</th>
                            <th>{{ chequeTable.footerRow[7] }}</th>
                            <th>{{ chequeTable.footerRow[8] }}</th>
                            <th>{{ chequeTable.footerRow[9] }}</th>
                            <th>{{ chequeTable.footerRow[10] }}</th>
                        </tr>
                    </tfoot>
                    <tbody>
                        <tr *ngFor="let row of chequeTable.dataRows">
                            <td>{{row[0]}}</td>
                            <td>{{row[1]}}</td>
                            <td>{{row[2]}}</td>
                            <td>{{row[3]}}</td>
                            <td>{{row[4]}}</td>
                            <td>{{row[5]}}</td>
                            <td>{{row[6]}}</td>
                            <td>{{row[7]}}</td>
                            <td>{{row[8]}}</td>
                            <td>{{row[9]}}</td>
                            <td>
                                <button class="btn btn-simple btn-info btn-icon like"><i class="ti-heart"></i></button>
                                <button class="btn btn-simple btn-warning btn-icon edit"><i class="cmp-i editar i-sm"></i></button>
                                <button class="btn btn-simple btn-danger btn-icon remove"><i class="ti-close"></i></button>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- end content-->
    </div>
    <!--  end card  -->

    <div class="card">
        <div class="card-header">
        </div>
        <div class="card-content">
            <div class="row">
                <div class="col-md-6">
                    <div id="acordeon">
                        <div class="panel-group" id="accordion">
                            <div class="panel panel-border panel-default">
                                <a data-toggle="collapse" href="#collapseOne">
                                    <div class="panel-heading">
                                        <h4 class="panel-title">
                                            Notas
                                            <i class="ti-angle-down"></i>
                                        </h4>
                                    </div>
                                </a>
                                <div id="collapseOne" class="panel-collapse collapse">
                                    <div class="panel-body">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><!--  end acordeon -->
                    <div class="panel panel-border panel-default">
                        <a data-toggle="collapse" href="#collapseTwo">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    Cuenta
                                    <i class="ti-angle-down"></i>
                                </h4>
                            </div>
                        </a>
                        <div id="collapseTwo" class="panel-collapse collapse">
                            <div class="panel-body">
                                <h5><strong>Limite de credito</strong> 0.00</h5>
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-border panel-default">
                        <a data-toggle="collapse" href="#collapseThree">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    T. Cambio
                                    <i class="ti-angle-down"></i>
                                </h4>
                            </div>
                        </a>
                        <div id="collapseThree" class="panel-collapse collapse">
                            <div class="panel-body">
                                <h5><strong>T. Cambio</strong> 1,000</h5>
                                <h5><strong>Manual</strong> <input type="text" placeholder="0.00"></h5>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col-md-6">
                    <div class="nav-tabs-navigation">
                        <div class="nav-tabs-wrapper">
                            <ul id="tabs" class="nav nav-tabs" data-tabs="tabs">
                                <li class="active"><a href="#home" data-toggle="tab">Importe</a></li>
                                <li><a href="#profile" data-toggle="tab">Tasas</a></li>
                            </ul>
                        </div>
                    </div>
                    <div id="my-tab-content" class="tab-content text-center">
                        <div class="tab-pane active" id="home">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Gastos</label>
                                        <input type="text" placeholder="0.00" class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Percepción IB</label>
                                        <input type="text" placeholder="0.00" class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label>Calculo</label>
                                        <input type="checkbox" placeholder="0.00" class="form-control" checked="checked">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Exento</label>
                                        <input type="text" placeholder="0.00" class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Editar Percepción</label>
                                        <input type="text" placeholder="0.00" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Percepción IVA</label>
                                        <input type="text" placeholder="0.00" class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Retención IB</label>
                                        <input type="text" placeholder="0.00" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Percepción SUSS</label>
                                        <input type="text" placeholder="0.00" class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Neto</label>
                                        <input type="text" placeholder="0.00" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Percepción GAN</label>
                                        <input type="text" placeholder="0.00" class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>IVA</label>
                                        <input type="text" placeholder="0.00" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Neto no Gravado</label>
                                        <input type="text" placeholder="0.00" class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <h3>Total</h3>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <h3>0.00</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="profile">
                            <p>Tasas</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>




</div>
