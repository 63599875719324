import { Component, OnInit } from '@angular/core';

import {FacturaService} from '../factura.service';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {ImputacionContable} from '../models';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
import { FilterCacheUtilsService } from 'app/services/filter-cache-utils.service';
import { GlobalVars } from 'app/services/globalVars';
import { FilterCache } from 'app/models/filter-cache';
 
const VIEW_ID = "imputaciones";

@Component({
  selector: 'imputaciones',
  templateUrl: './imputaciones.component.html'
})
export class ImputacionesComponent extends GenericFormComponent implements OnInit {
  public imputaciones: ImputacionContable[];
  public params = {page: 1, per_page: 10};
  public filtroDefault = {nombre_matches: null, sort: 'nombre'};
  public filtro = Object.assign({}, this.filtroDefault);
  public someFiltersApplied: boolean = false;
  
  public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Imputaciones', active: true }];
    
  constructor(protected messageService: MessageService, private facturaService: FacturaService, private filterCacheUtilsService: FilterCacheUtilsService, private globalVars: GlobalVars) {
    super(messageService);
  }

  ngOnInit() {
    this.loadCacheFiltersParams();
    this.filter();
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.cursorWait();
    this.someFiltersApplied = this.globalVars.isSomeFilterApplied(this.filtro);
    this.facturaService.getImputacionesContables(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.imputaciones = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
            this.filterCacheUtilsService.setFilterParams(VIEW_ID, this.filtro, this.params);
        },
        error => {
            this.cursorDefault();
        }
    );
  }
  
  //ACCIONES    
  onNotifyDelete(imputacion: ImputacionContable):void {
    this.deleteImputacion(imputacion);
  }
    
  public deleteImputacion(imputacion: ImputacionContable) {
    this.beforeSubmitList();
    this.facturaService.deleteImputacionContable(imputacion.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Imputacion eliminada correctamente');
            //ELIMINO DE LA LISTA      
            this.imputaciones.forEach(function(imputacionFor, index, object) {
                if(imputacionFor.id == imputacion.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }   

  private loadCacheFiltersParams() {
    let cacheFilters: FilterCache = this.filterCacheUtilsService.getFilterParams(VIEW_ID);
    if (!cacheFilters) return;

    this.filtro = cacheFilters.filters;
    this.params = cacheFilters.params;
  }

  public resetFilters() {
    this.filtro = Object.assign({}, this.filtroDefault);
    this.filter();
  }
  
}