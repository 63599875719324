import { Component, OnInit, Input } from '@angular/core';

import {ViaticoService} from '../viatico.service';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Viatico} from '../models';
import {OrdenDePago} from '../../module-orden/models';
 
@Component({
  selector: 'ordenes_viatico',
  templateUrl: './ordenes.component.html'
})
export class OrdenesComponent extends GenericFormComponent implements OnInit {
  @Input() viatico: Viatico;
  public ordenes: OrdenDePago[];
    
  constructor(protected messageService: MessageService, private viaticoService: ViaticoService) {
    super(messageService);
  }

  ngOnInit() {
    console.log("Aca")
    this.filter();
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    this.cursorWait();
    this.viaticoService.getOrdenesViatico(this.viatico.id).subscribe(
        (ordenes: OrdenDePago[]) => {
            console.log(ordenes)
            this.cursorDefault();
            this.ordenes = ordenes;
        },
        error => {
            this.cursorDefault();
        }
    );
  }

}