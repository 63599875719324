<div class="container-fluid">    
    <div class="row">
        <div class="card">
            <div class="card-content">
                <breadcrumb [items]="breadcrumbItems"></breadcrumb>
                             
                <filters-header-section (onFilter)="filter(1)" [someFilterApllied]="someFiltersApplied" (onClear)="resetFilters()">
                    <div class="col-md-12">
                        <!-- <div class="col-md-4">
                            <div class="input-group">
                                <input class="form-control" type="text" placeholder="Búsqueda de usuarios" name="search" [(ngModel)]="filtro.username_matches">
                                <span class="input-group-addon" (click)="filter(1)"><i class="cmp-i busqueda i-sm"></i></span>
                            </div>
                        </div> -->
                                            
                        <div class="col-sm-12 col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4">
                            <label for="search">Nombre:</label>
                            <div class="input-group">
                                <input class="form-control" type="text" placeholder="Nombre" name="search" [(ngModel)]="filtro.nombre_matches">
                                <span class="input-group-addon" (click)="filter(1)"><i class="cmp-i busqueda i-sm"></i></span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-12 filter-header">
                        <div class="panel panel-border panel-default">
                            <a data-toggle="collapse" href="#collapseOnePlain" class="collapsed" aria-expanded="false">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        Filtros
                                        <i class="ti-angle-down"></i>
                                    </h4>
                                </div>
                            </a>
                            <div id="collapseOnePlain" class="panel-collapse collapse" aria-expanded="false" style="height: 0px;">
                                <div class="panel-body">
                                    <div class="col-md-12">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label class="checkbox-inline"><input type="checkbox" name="enabled" [(ngModel)]="filtro.habilitado_eq" (change)="filter(1)"> Ver solo usuarios habilitados</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <select class="form-control" data-style="btn btn-wd btn-block" name="orderBy"  [(ngModel)]="filtro.sort" (change)="filter(1)">
                                                <option value="created_at,desc">Ordenar por fecha de creación</option>
                                                <option value="username">Ordenar por nombre de usuario</option>
                                                <option value="distrito_id">Ordenar por distrito</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                                    
                                    
                </filters-header-section>  


                <div class="row mt-2">
                    <div class="col-sm-12">
                        <a class="btn btn-simple btn-icon btn-add" [routerLink]="['/imputaciones/new']" title="Agregar"><i class="cmp-i agregar"></i> Agregar</a>
                    </div>  
                </div>

                <div class="row">
                    <div class="col-md-12 table-responsive">
                        <table class="table table-hover">
                            <thead class="text-primary">
                                <tr>
                                    <th><b>Nombre</b></th>
                                    <th><b>Descripción</b></th>
                                    <th><b>Acciones</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let imputacion of imputaciones'>
                                    <td><a [routerLink]="['/imputaciones', imputacion.id]" rel="tooltip" title="Editar Imputacion">{{imputacion.nombre}}</a></td>
                                    <td>{{imputacion.descripcion}}</td>
                                    <td class="td-actions text-right">
                                        <a [routerLink]="['/imputaciones', imputacion.id]" rel="tooltip" title="Editar Imputacion" class="btn btn-primary btn-simple btn-xs btn-cmp-i">
                                            <i class="cmp-i editar i-sm"></i>
                                        </a>
                                        <button type="button" rel="tooltip" title="Eliminar Imputación" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="imputacion" confirm-message="¿Está seguro que desea eliminar el Imputación?">
                                            <i class="cmp-i eliminar-borrar i-sm"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="imputaciones != null && imputaciones.length == 0" class="text-center">
                                    <td colspan="6">No se encontraron imputaciones</td>
                                </tr>
                            </tbody>
                        </table>                        
                        <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)" *ngIf="imputaciones != null && imputaciones.length > 0"></pager>
                    </div>
                </div>
            </div>
        </div>          
    </div>
</div>