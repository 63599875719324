import { Component, OnInit, Input } from '@angular/core';

import {FacturaService} from '../../module-factura/factura.service';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Proveedor} from '../models';
import {NotaDeCredito} from '../../module-factura/models';
 
@Component({
  selector: 'notas_proveedor',
  templateUrl: './notas.component.html'
})
export class NotasComponent extends GenericFormComponent implements OnInit {
  @Input() proveedor: Proveedor;
  public notas: NotaDeCredito[];
  
  public params = {page: 1, per_page: 10};
  public filtro = {proveedor_id_eq: null, distrito_id_eq: null, sort: 'fecha,desc'};
    
  constructor(protected messageService: MessageService, private facturaService: FacturaService,
    private globalVars: GlobalVars) {
    super(messageService);
  }

  ngOnInit() {
    this.filtro.proveedor_id_eq = this.proveedor.id;
    this.filtro.distrito_id_eq = this.globalVars.getActualDistrito();
    this.filter();
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.cursorWait();
    this.facturaService.getNotas(this.params, this.filtro).subscribe(
        (rta: any) => {
            this.cursorDefault();
            this.notas = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
        },
        error => {
            this.cursorDefault();
        }
    );
  }
  
  //ACCIONES    
  onNotifyDelete(nota: NotaDeCredito):void {
    this.deleteNota(nota);
  }
    
  public deleteNota(nota: NotaDeCredito) {
    this.beforeSubmitList();
    this.facturaService.deleteNota(nota.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Nota de credito eliminada correctamente');
            //ELIMINO DE LA LISTA      
            this.notas.forEach(function(notaFor, index, object) {
                if(notaFor.id == nota.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }
  
}