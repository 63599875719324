<div class="container-fluid">    
    
    <div class="row">
        <div class="col-sm-12 col-md-offset-1 col-md-10">
            <div class="card">
                <div class="card-content">
                    <breadcrumb [items]="breadcrumbItems"></breadcrumb>
    
                    <form class="form-horizontal" (submit)="save($event)" #datosForm="ngForm">
                        <fieldset [disabled]="viatico.autorizado">
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="row">
                                        <label class="col-md-3 label-on-left">Persona Imputada</label>      
                                        <div class="col-md-9">
                                            <provider-select  class="col-sm-12 text-center" *ngIf="!viatico.proveedor && actualUser.hasRolByName('cargador_viatico')" [(providerModel)]="viatico.proveedor" (onModelChange)="onSelectProvider()"></provider-select>
                        
                                            <select *ngIf="viatico.proveedor" class="form-control" data-style="btn btn-wd btn-block" name="proveedor" [(ngModel)]="viatico.proveedor" [compareWith]='customCompareProveedor' [disabled]="viatico.id">
                                                <option *ngFor="let permiso of permisosViaticos" [ngValue]="permiso.proveedor">{{permiso.proveedor.nombre}} - {{permiso.proveedor.cuit | cuitformat}}</option>
                                            </select>
                                            <small [hidden]="!inputError.proveedor_id" [innerHtml]="inputError.proveedor_id" class="text-danger"></small>
                                        </div>                            
                                    </div>
                                </div>
                                <div class="col-md-6" *ngIf="actualUser.multi_distrito">
                                    <div class="row">
                                        <label class="col-md-3 label-on-left">Distrito</label>
                                        <div class="col-md-9" *ngIf="globalVars.actualUser.multi_distrito">
                                            <select class="form-control" data-style="btn btn-wd btn-block" name="distrito" [(ngModel)]="viatico.distrito_id" >
                                                <option *ngFor="let distrito of distritos" [ngValue]="distrito.id">{{distrito.nombre}}</option>
                                            </select>
                                            <small [hidden]="!inputError.distrito_id" [innerHtml]="inputError.distrito_id" class="text-danger"></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="row">
                                        <label class="col-md-3 label-on-left">Número</label>
                                        <div class="col-md-9">                                  
                                            <input type="number" name="numero" [(ngModel)]="viatico.numero" class="form-control" required disabled="disabled">
                                            <small [hidden]="!inputError.numero" [innerHtml]="inputError.numero" class="text-danger"></small>
                                        </div>                            
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <label class="col-md-3 label-on-left">Emisión</label>
                                        <div class="col-md-9">   
                                            <input-datepicker [(dateModel)]="viatico.emision" [isStringFormat]="true" [required]="true"></input-datepicker>                              
                                            <small [hidden]="!inputError.emision" [innerHtml]="inputError.emision" class="text-danger"></small>
                                        </div>                               
                                    </div>
                                </div>                    
                            </div>
                         
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="row">
                                        <label class="col-md-3 label-on-left">Origen</label>
                                        <div class="col-md-9">
                                            <select #fac_select_ciudades_ori class="form-control filter-form-control selectpicker custom-live-search" data-live-search="true" 
                                                data-live-search-placeholder="Buscar Origen" name="direccion_origen" data-style="btn btn-wd btn-block" 
                                                title="Dirección de origen" [(ngModel)]="viatico.direccion_origen" [compareWith]='customCompareCiudad'>
                                                <option [ngValue]="null">Seleccione un origen</option>
                                                <option *ngFor="let ciudad of ciudades" value="{{ciudad.id}}">{{ciudad.nombre}}</option>
                                            </select>
                                            <small [hidden]="!inputError.direccion_origen" [innerHtml]="inputError.direccion_origen" class="text-danger"></small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <label class="col-md-3 label-on-left">Destino</label>
                                        <div class="col-md-9">
                                            <select #fac_select_ciudades_des class="form-control filter-form-control selectpicker custom-live-search" data-live-search="true" 
                                                data-live-search-placeholder="Buscar Destino" name="direccion_destino" data-style="btn btn-wd btn-block" 
                                                title="Direccion de destino" [(ngModel)]="viatico.direccion_destino" [compareWith]='customCompareCiudad'>
                                                <option [ngValue]="null">Seleccione un destino</option>
                                                <option *ngFor="let ciudad of ciudades" value="{{ciudad.id}}">{{ciudad.nombre}}</option>
                                            </select>
                                            <small [hidden]="!inputError.direccion_destino" [innerHtml]="inputError.direccion_destino" class="text-danger"></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="form-group">
                                <div class="col-md-6 mt-1">
                                    <div class="row">
                                        <label class="col-md-3 label-on-left">Periodo / Tabla</label>
                                        <div class="col-md-9">
                                            <select class="form-control" data-style="btn btn-wd btn-block" name="periodo" [(ngModel)]="viatico.periodo" [compareWith]='customComparePeriodo' [disabled]="viatico.id">
                                                <option [ngValue]="null">Seleccione un Periodo</option>
                                                <option *ngFor="let periodo of periodos" [ngValue]="periodo">{{periodo.nombre}}</option>
                                            </select>
                                            <small [hidden]="!inputError.periodo_id" [innerHtml]="inputError.periodo_id" class="text-danger"></small>
                                        </div>                            
                                    </div>
                                </div>
                                <div class="col-md-6 mt-1">
                                    <div class="row">
                                        <label class="col-md-3 label-on-left">Imputación</label>
                                        <div class="col-md-9">
                                            <select name="imputacion_viatico" class="form-control" data-style="btn btn-wd btn-block" name="imputacion_viatico" [(ngModel)]="viatico.imputacion_viatico_id" > 
                                                <option [ngValue]="null">Seleccione una Imputación</option>
                                                <option *ngFor="let imputacion of imputaciones" [ngValue]="imputacion.id">{{imputacion.nombre}}</option>
                                            </select>
                                            <small [hidden]="!inputError.imputacion_viatico_id" [innerHtml]="inputError.imputacion_viatico_id" class="text-danger"></small>
                                        </div>                            
                                    </div>
                                </div>
                            </div>


    
                            <div class="form-group">
                                <!-- <div class="col-md-6">
                                    <div class="row">
                                        <label class="col-md-3 label-on-left">Cantidad de veces</label>
                                        <div class="col-md-9">                                  
                                            <input type="number" name="cantidad_veces" [(ngModel)]="viatico.cantidad_veces" class="form-control" required>
                                            <small [hidden]="!inputError.cantidad_veces" [innerHtml]="inputError.cantidad_veces" class="text-danger"></small>
                                        </div>                            
                                    </div>
                                </div> -->
                                <div class="col-md-12">
                                    <div class="row form-group">
                                        <label class="col-md-3 label-on-left">Descripción</label>
                                        <div class="col-md-12">
                                            <textarea name="descripcion" [(ngModel)]="viatico.descripcion" class="form-control" rows="4"  placeholder="Descripción del viático" style="height: 80px !important;"></textarea>
                                            <small [hidden]="!inputError.descripcion" [innerHtml]="inputError.descripcion" class="text-danger"></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
        <!--                    <div class="form-group">
                                <div class="col-md-6">
                                    <div class="row">
                                        <label class="col-md-3 label-on-left">Monto</label>
                                        <div class="col-md-9">                                  
                                            <input type="number" name="monto" [(ngModel)]="viatico.monto" class="form-control" required>
                                            <small [hidden]="!inputError.monto" [innerHtml]="inputError.monto" class="text-danger"></small>
                                        </div>                           
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <label class="col-md-3 label-on-left">Tipo</label>
                                        <div class="col-md-9">
                                            <select name="tipo" class="form-control" data-style="btn btn-wd btn-block" title="Tipo de Viatico" data-size="7" [(ngModel)]="viatico.tipo" required="">
                                                <option [ngValue]="null">Seleccione un tipo</option>
                                                <option *ngFor="let tipo of tipos" [ngValue]="tipo">{{tipo | translate: "VIATICO_EST"}}</option>
                                            </select>
                                            <small [hidden]="!inputError.tipo" [innerHtml]="inputError.tipo" class="text-danger"></small>
                                        </div>
                                    </div>
                                </div>
                            </div>-->
                        </fieldset>
    
                        <div class="row text-center">
                            <button class="btn btn-primary" type="submit" *ngIf="! viatico.id" [disabled]="submitted">Iniciar Viático</button>
                            <button class="btn btn-primary" type="submit" *ngIf="viatico.id && !viatico.autorizado" [disabled]="submitted">Actualizar Viático</button>
                            <button class="btn btn-primary btn-info ml-2" type="button" *ngIf="viatico.id && viatico.monto > 0 && viatico.estado != 'A' && actualUser.hasAtLeastOneRolByName(['tesorero'])" (click)="changeState(viatico, 'A', $event)" [disabled]="submitted">Aprobar Viático</button>
                            <button class="btn btn-primary btn-danger ml-2" type="button" *ngIf="viatico.id && viatico.monto > 0 && viatico.estado != 'R' && actualUser.hasAtLeastOneRolByName(['tesorero'])" (click)="changeState(viatico, 'R', $event)" [disabled]="submitted">Rechazar Viático</button>
                            <button type="button" class="btn btn-default ml-2" *ngIf="viatico.id" (click)="pdfViatico()">Imprimir</button> 
                        </div>
                        <div class="clearfix"></div>
                    </form>
                </div>
            </div>
        </div>
        
    </div>
    
    <items_viatico *ngIf="viatico.id" [viatico]="viatico"></items_viatico>
    
    <div class="row" *ngIf="viatico.id && viatico.autorizado && viatico.monto_real == 0 && actualUser.hasAtLeastOneRolByName(['tesorero'])">
        <div class="col-sm-12 col-md-offset-1 col-md-10">
            <div class="card">
                <div class="card-content">
                    <h4 class="card-title">Carga de monto facturado</h4>
                    <hr>
                    <form class="form-horizontal" (submit)="saveMontoReal($event)" #datosMontoForm="ngForm">
                        <fieldset>
                            <div class="form-group">
                                <div class="col-sm-12">
                                    <div class="row">
                                        <label class="col-sm-offset-2 col-sm-3 col-md-offset-3 label-on-left">Items Hotel no utilizados</label>
                                        <div class="col-sm-5 col-md-3">                                  
                                            <input type="number" name="items_hotel" [(ngModel)]="items_hotel" (change)="actualizarMontoFacturado()" class="form-control" placeholder="1" required>
                                        </div>                            
                                    </div>
                                </div>
                                <div class="col-sm-12 mt-1">
                                    <div class="row">
                                        <label class="col-sm-offset-2 col-sm-3 col-md-offset-3 label-on-left">Monto Facturado</label>
                                        <div class="col-sm-5 col-md-3">                                  
                                            <input type="number" name="monto_real" [(ngModel)]="monto_real" class="form-control" placeholder="1000" required>
                                            <small [hidden]="!inputError.monto_real" [innerHtml]="inputError.monto_real" class="text-danger"></small>
                                        </div>                            
                                    </div>
                                </div>                   
                            </div>
                        </fieldset>
                        <div class="row text-center mt-2">                    
                            <button class="btn btn-primary" type="submit" [disabled]="submitted">Guardar</button>
                        </div>
                        <div class="clearfix"></div>
                    </form>
                </div>
            </div>
        </div>
        
    </div>
    
    <div class="row" *ngIf="viatico.id">
        <div class="col-sm-12 col-md-offset-1 col-md-10">
            <div class="card">
                <div class="card-content">                  
                    <div class="row">
                        <div class="col-sm-12 col-md-4">
                            <h4 style="height: 40px;">Nota pendiente / utilizada</h4>
                            <hr>
                            <div *ngIf="notaPendiente?.id">
                                <!-- <p><strong>Id: </strong> {{notaPendiente.id}}</p> -->
                                <p><strong>Monto: </strong> $ {{notaPendiente.monto | number: '1.2-2'}}</p>
                                <p><strong>Fecha:</strong> {{notaPendiente.fecha | date: 'dd/MM/yyyy'}}</p>
                            </div>
                            <div *ngIf="!notaPendiente?.id">
                                <p><strong>No hay nota pendiente / utilizada</strong></p>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <h4 style="height: 40px;">Nota Generada</h4>
                            <hr>
                            <div *ngIf="notaGenerada">
                                <!-- <p><strong>Id: </strong> {{notaGenerada.id}}</p> -->
                                <p><strong>Monto: </strong> $ {{notaGenerada.monto | number: '1.2-2'}}</p>
                                <p><strong>Fecha:</strong> {{notaGenerada.fecha | date: 'dd/MM/yyyy'}}</p>
                            </div>
                            <div *ngIf="!notaGenerada">
                                <p><strong>No hay nota generada</strong></p>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <h4 style="height: 40px;">Totales</h4>
                            <hr>
                            <p><strong>Monto: </strong> $ {{viatico.monto | number: '1.2-2'}}</p>
                            <p><strong>Monto a Pagar: </strong> $ {{viatico.monto_neto | number: '1.2-2'}}</p>
                            <p><strong>Monto Facturado:</strong> $ {{viatico.monto_real | number: '1.2-2'}}</p>
                        </div>
                    </div>
                </div>
            </div> <!-- end card -->
        </div>
    </div>


    <div class="col-sm-12 col-md-offset-1 col-md-10" *ngIf="viatico.id">
        <ordenes_viatico [viatico]="viatico"></ordenes_viatico>
    </div>
</div>