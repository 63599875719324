import {Factura} from '../module-factura/models';
import {Proveedor} from '../module-proveedor/models';
import {Distrito} from '../module-distrito/models';
import {Viatico} from '../module-viatico/models';

export class OrdenDePagoGeneral {
    public id: number;
    public punto_venta: number = 1;
    public numero: number;
    public fecha: any;
    public moneda: string = 'PES';
    public monto: number = 0;
    public monto_descuentos: number = 0;
    public retencion: number = 0;
    public descuentos: number = 0;
    public pagado: number = 0;
    public multi_proveedor: boolean = false;
    public descripcion: string = '';
    public nota: string;
    public finalizada: boolean = false;
    public revision: boolean = false;
    public verificada: boolean = false;
    public anulada: boolean = false;
    
    public proveedor: Proveedor = null;
    public distrito: Distrito = null;
    public distrito_id: any;
    public total_notas_credito: number = 0;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.punto_venta = json.punto_venta;
            this.numero = json.numero;
            this.fecha = json.fecha;
            this.moneda = json.moneda;
            this.descripcion = json.descripcion;
            this.monto = json.monto;
            this.monto_descuentos = json.monto_descuentos;            
            this.retencion = json.retencion;
            this.descuentos = json.descuentos;
            this.pagado = json.pagado;
            this.multi_proveedor = json.multi_proveedor;
            this.nota = json.nota;
            this.finalizada = json.finalizada;
            this.revision = json.revision;
            this.verificada = json.verificada;
            this.anulada = json.anulada;
            
            if (json.proveedor) {
                this.proveedor = new Proveedor(json.proveedor);
            }
            if (json.distrito) {
                this.distrito = new Distrito(json.distrito);
            }
            this.distrito_id = json.distrito_id;
            this.total_notas_credito = json.total_notas_credito;
        }
    }
    
    public actualizar(orden: OrdenDePagoGeneral) {
        this.monto = orden.monto;
        this.monto_descuentos = orden.monto_descuentos;
        this.retencion = orden.retencion;
        this.descuentos = orden.descuentos;
        this.pagado = orden.pagado;
        this.nota = orden.nota;
    }
    
    public tieneDocumentos() {
        return this.monto > 0;
    }

    public tienePagos() {
        return this.pagado > 0;
    }
    
    public estaPagado(redondeo = 0) {
        return this.id && (this.monto_descuentos == this.pagado || Math.abs(this.monto_descuentos - this.pagado) <= redondeo) && this.monto_descuentos > 0;
    }
    
    public restaPagar() {
        return Number((this.monto_descuentos - this.pagado).toFixed(2));
    }
    
    public print() {
        if(this.numero == null){
            return '';
        }
        return this.punto_venta + "-" + this.numero;
    }
}

export class OrdenDePago {
    public id: number;
    public monto: number;
    public monto_descuentos: number;
    public retencion: number;
    public retencion_manual: boolean = false;
    public porcentaje_descuentos: number = 0;
    public descuentos: number;
    public anulada: boolean = false;
    
    public factura: Factura = null;
    public viatico: Viatico = null;
    public ordenpagogeneral: OrdenDePagoGeneral = null;
    
    public editando: boolean = false;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.monto = json.monto;
            this.monto_descuentos = json.monto_descuentos;
            this.retencion = json.retencion;
            this.retencion_manual = json.retencion_manual ? json.retencion_manual : false;
            this.porcentaje_descuentos = Number(json.porcentaje_descuentos);
            this.descuentos = json.descuentos;
            this.anulada = json.anulada;
            
            if (json.factura) {
                this.factura = new Factura(json.factura);
            }
            if (json.viatico) {
                this.viatico = new Viatico(json.viatico);
            }
            if (json.ordenpagogeneral) {
                this.ordenpagogeneral = new OrdenDePagoGeneral(json.ordenpagogeneral);
            }
        }
    }
    
    public inicializar (factura: Factura) {
        this.monto = factura.restaPagar();
        this.monto = this.monto ?? 0;
        this.factura = factura;
    }
    public inicializarViatico (viatico: Viatico) {
        this.monto = viatico.restaPagar();
        this.monto = this.monto ?? 0;
        this.viatico = viatico;
    }
    
    public numero () {
        if (this.factura) {
            return this.factura.punto_venta + '-' + this.factura.numero;
        } else {
            return this.viatico.numero;
        }
    }
    
    public montoOriginal() {
        if (this.factura) {
            return this.factura.monto;
        } else {
            return this.viatico.monto_neto;
        }
    }
}

export class DeudaRetencion {
    public id: number;
    public desde: any;
    public hasta: number;
    public monto: number;
    
    public factura: Factura = null;
    public distrito: Distrito = null;
    public proveedor: Proveedor = null;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.monto = json.monto;
            this.desde = json.desde;
            this.hasta = json.hasta;
            
            if (json.factura) {
                this.factura = new Factura(json.factura);
            }
            if (json.proveedor) {
                this.proveedor = new Proveedor(json.proveedor);
            }
            if (json.distrito) {
                this.distrito = new Distrito(json.distrito);
            }
        }
    }
}