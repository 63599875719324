import {Distrito} from '../module-distrito/models';

export class PuntoDeVenta {
    public id: number;
    public punto_venta: string;
    public distrito: Distrito = null;
    public distrito_id: any = null;
    
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.punto_venta = json.punto_venta;
            this.distrito = json.distrito;
            this.distrito_id = json.distrito_id;
        }
    }
}