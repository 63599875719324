<ng-container *ngIf="tipos.length == 0">
    <p>No hay items de este tipo.</p>
</ng-container>
<form *ngIf="tipos.length > 0" class="form-horizontal" (submit)="save($event)" #datosItemForm="ngForm">
    <fieldset [disabled]="viatico.autorizado">
        <div class="form-group">
            <!-- <div class="col-md-6" *ngIf="tipo == 'K'">
                <div class="row">
                    <label class="col-sm-offset-2 col-sm-3 col-md-offset-3 label-on-left">KMs</label>
                    <div class="col-sm-5 col-md-3">
                        <select #fac_select_tipos class="form-control filter-form-control selectpicker custom-live-search" data-live-search="true" data-style="btn btn-wd btn-block" name="tipo_viatico" [(ngModel)]="item.tipo_viatico" [compareWith]='customCompareTipoViatico'>
                            <option *ngFor="let tipo of tipos" [ngValue]="tipo">{{tipo.kilometros}}</option>
                        </select>
                        <small [hidden]="!inputError.tipo_viatico_id" [innerHtml]="inputError.tipo_viatico_id" class="text-danger"></small>
                    </div>                            
                </div>
            </div> -->
            <div class="col-sm-12" *ngIf="tipo == 'K'">
                <div class="row">
                    <label class="col-sm-offset-2 col-sm-3 col-md-offset-3 label-on-left">Kilometros Exactos</label>
                    <div class="col-sm-5 col-md-3">                                  
                        <input type="number" name="kilometros" [(ngModel)]="item.kilometros" class="form-control" placeholder="15" required>
                        <small [hidden]="!inputError.kilometros" [innerHtml]="inputError.kilometros" class="text-danger"></small>
                    </div>                            
                </div>
            </div>
            <div class="col-sm-12 mt-1" *ngIf="tipo != 'M'">
                <div class="row">
                    <label class="col-sm-offset-2 col-sm-3 col-md-offset-3 label-on-left">Cantidad</label>
                    <div class="col-sm-5 col-md-3">                                  
                        <input type="number" name="cantidad" [(ngModel)]="item.cantidad" class="form-control" placeholder="1" required>
                        <small [hidden]="!inputError.cantidad" [innerHtml]="inputError.cantidad" class="text-danger"></small>
                    </div>                            
                </div>
            </div>
            <div class="col-sm-12 mt-1" *ngIf="tipo == 'M'">
                <div class="row">
                    <label class="col-sm-offset-2 col-sm-3 col-md-offset-3 label-on-left">Monto Manual</label>
                    <div class="col-sm-5 col-md-3">                                  
                        <input type="number" name="monto" [(ngModel)]="item.monto" class="form-control" placeholder="1000" required>
                        <small [hidden]="!inputError.monto" [innerHtml]="inputError.monto" class="text-danger"></small>
                    </div>                            
                </div>
            </div>
            <div class="col-sm-12 mt-1" *ngIf="tipo == 'H'">
                <div class="row">
                    <label class="col-sm-offset-2 col-sm-3 col-md-offset-3 label-on-left">Monto Diario</label>
                    <div class="col-sm-5 col-md-3">                                  
                        <input type="number" name="monto_diario" [(ngModel)]="item.monto_diario" class="form-control" placeholder="1000" required>
                        <small [hidden]="!inputError.monto_diario" [innerHtml]="inputError.monto_diario" class="text-danger"></small>
                    </div>                            
                </div>
            </div>
        </div>
        <div class="form-group">            
            <div class="col-sm-offset-2 col-sm-8 col-md-offset-3 col-md-6 mt-1" *ngIf="tipo == 'M'">
                <div class="row">
                    <div class="col-sm-12">       
                        <label class="">Descripción</label>                           
                        <input type="text" name="descripcion" placeholder="Descripción de este item" [(ngModel)]="item.descripcion" class="form-control" required>
                        <small [hidden]="!inputError.descripcion" [innerHtml]="inputError.descripcion" class="text-danger"></small>
                    </div>                            
                </div>
            </div>
        </div>
    </fieldset>
        
    <div class="row">
        <div class="col-sm-12 text-center">
            <p><strong><b>Monto:</b> </strong> $ {{item.monto | number: '1.2-2'}}</p>
        </div>
    </div>

    <div class="row text-center">
        <button class="btn btn-primary" type="submit" *ngIf="! viatico.autorizado" [disabled]="submitted">Guardar</button>
    </div>
    <div class="clearfix"></div>
</form>