import { Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentInit } from '@angular/core';
import { Router } from '@angular/router';

import {environment} from '../../../environments/environment';

import { GlobalVars } from '../../services/globalVars';
import { UserService } from '../../services/user.service';
import {DistritoService} from '../../module-distrito/distrito.service';
import {Cache} from '../../services/cache';
import {MessageService} from '../../services/message.service';

import { User } from '../../models/user';
import {Distrito} from '../../module-distrito/models';

declare var $:any;
//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    // icon: string;
    children?: ChildrenItems[];
    roles: string[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTES_ADM: RouteInfo[] = [
    { path: '/usuarios', title: 'Usuarios', type: 'link', icontype: 'cmp-i usuario', roles: ['admin_sistema'] },
    { path: '/roles', title: 'Roles', type: 'link', icontype: 'cmp-i roles', roles: ['admin_sistema'] },
    { path: '/distritos', title: 'Distritos', type: 'link', icontype: 'cmp-i sucursales', roles: ['admin_sistema'] },
    { path: '/categorias_retencion', title: 'Categorías de Retención', type: 'link', icontype: 'cmp-i reportes-opt-2', roles: ['admin_sistema'] },
    { path: '/metodospago', title: 'Métodos de Pago', type: 'link', icontype: 'cmp-i metodos-pago', roles: ['admin_sistema'] },
    { path: '/periodos', title: 'Periodos de Viático', type: 'link', icontype: 'cmp-i periodo-viaticos', roles: ['admin_sistema'] },
    { path: '/config/afip', title: 'Afip', type: 'link', icontype: 'cmp-i afip', roles: ['admin_sistema'] },
    { path: '/config/valor-modulo', title: 'Valor del Modulo', type: 'link', icontype: 'cmp-i valor-modulo', roles: ['admin_sistema'] },
    { path: '/puntodeventas', title: 'Punto de Ventas', type: 'link', icontype: 'cmp-i puntos-venta', roles: ['tesorero', 'administrativo', 'auditor'] }
];

export const ROUTES_OPS: RouteInfo[] = [
    { path: '/facturas', title: 'Facturas', type: 'link', icontype: 'cmp-i facturas', roles: ['tesorero', 'administrativo', 'auditor'] },
    { path: '/ordenespago', title: 'Ordenes de Pago', type: 'link', icontype: 'cmp-i ordenes-pago', roles: ['tesorero', 'administrativo', 'auditor'] },
    { path: '/cuentas', title: 'Cuentas', type: 'link', icontype: 'cmp-i cuentas', roles: ['tesorero'] },
    { path: '/chequeras', title: 'Chequeras', type: 'link', icontype: 'cmp-i chequeras', roles: ['tesorero'] },
    { path: '/cheques', title: 'Cheques', type: 'link', icontype: 'cmp-i cheques', roles: ['tesorero', 'administrativo'] },
    { path: '/proveedores', title: 'Proveedores', type: 'link', icontype: 'cmp-i proveedores', roles: ['tesorero', 'administrativo'] },
    { path: '/misviaticos', title: 'Mis Viáticos', type: 'link', icontype: 'cmp-i mis-viaticos', roles: ['tesorero', 'autoridad'] },
    { path: '/viaticos', title: 'Carga de Viáticos', type: 'link', icontype: 'cmp-i viaticos-agregar', roles: ['cargador_viatico'] },
    { path: '/authviaticos', title: 'Autorización de Viáticos', type: 'link', icontype: 'cmp-i viaticos-habilitar', roles: ['tesorero'] },
    { path: '/deudas', title: 'Deudas Retenciones', type: 'link', icontype: 'cmp-i deuda-retenciones', roles: ['tesorero', 'administrativo'] },
    { path: '/imputaciones', title: 'Imputaciones', type: 'link', icontype: 'cmp-i imputaciones', roles: ['tesorero', 'auditor'] },
    { path: '/reportes', title: 'Reportes', type: 'link', icontype: 'cmp-i reportes', roles: ['tesorero', 'administrativo'] },
    { path: '/reposicion', title: 'Reposicion Caja Chica', type: 'link', icontype: 'cmp-i reposicion-caja-chica', roles: ['tesorero'] }
];

export const ROUTES_SEL: RouteInfo[] = [
    { path: '/ingresosventas', title: 'Ingresos', type: 'link', icontype: 'cmp-i ingreso', roles: ['tesorero', 'administrativo', 'auditor'] },
    { path: '/ordenesventas', title: 'Ordenes de Venta', type: 'link', icontype: 'cmp-i orden-pago-egreso', roles: ['tesorero', 'administrativo', 'auditor'] },
    { path: '/recibos', title: 'Recibos', type: 'link', icontype: 'cmp-i recibos-comprobantes', roles: ['tesorero', 'administrativo', 'auditor'] },
    { path: '/notascreditoafip', title: 'Notas De Crédito', type: 'link', icontype: 'cmp-i nota-credito', roles: ['tesorero', 'administrativo', 'auditor'] }
];

@Component({
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit, AfterViewInit{
    public menuItems: any[] = [];
    public paneles: string[] = [];
    user: User;
    
    public distritos: Distrito[] = [];
    public distrito: Distrito = null;
       
    constructor(private router: Router, private globalVars: GlobalVars, private userService: UserService, private cache: Cache, private messageService: MessageService, 
        private distritoService: DistritoService) { }
     
    ngOnInit() {
        this.user= this.globalVars.actualUser;
        this.defineMenuItems();
        this.definePaneles();
        
        //Cargo distritos
        this.distritos = this.distritoService.distritos;
        this.distrito = this.distritoService.getLoadDistrito(this.globalVars.getActualDistrito());

        //Me registro a los observer de login y logout
        //No es necesario asignar los eventos a ninguna variable y luego eliminarlos ya que el modulo se carga una unica vez
        this.userService.loginUser$.subscribe(
            user => {
                this.user = user;
                this.defineMenuItems();
                this.definePaneles();
                /* if(user.hasRolByName('autoridad')){
                    this.router.navigate(['/misviaticos']);
                } */
            }
        );
        this.userService.logoutUser$.subscribe(
            user => {
                this.user = null;
                this.defineMenuItems();
                this.router.navigate(['/login']);
            }
        );
                
        this.globalVars.changePanel$.subscribe(
            rta => {
                this.defineMenuItems();
                if (rta.redirect) {
                    let page: string = environment.defaultPages[this.globalVars.getActualPanel()];
                    this.router.navigate([page]);
                }
            }
        );
        
        this.globalVars.changeDistrito$.subscribe(
            (event) => {
                this.distrito = this.distritoService.getLoadDistrito(this.globalVars.getActualDistrito());
            }
        );
        
        var isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;
        isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;

        if (isWindows){
            // if we are on windows OS we activate the perfectScrollbar function
            $('.sidebar .sidebar-wrapper, .main-panel').perfectScrollbar();
            $('html').addClass('perfect-scrollbar-on');
        } else {
            $('html').addClass('perfect-scrollbar-off');
        }
    }
    
    ngAfterViewInit(){
        var $sidebarParent = $('.sidebar .nav > li.active .collapse li.active > a').parent().parent().parent();

        var collapseId = $sidebarParent.siblings('a').attr("href");

        $(collapseId).collapse("show");
    }
    
    //
    //ACCIONES
    cleanAllCache(){
        this.cache.cleanAllCache();
        this.messageService.showNotification("top", "center", "success", "Cache de Datos borrada correctamente");
    }
  
    closeSession(){    
      this.userService.logout();
    }
    
    public selectPanel(panel: string){
        this.globalVars.setActualPanel(panel);
    }
    
    public selectDistrito(distrito: Distrito){
        this.distrito = distrito;
        this.globalVars.setActualDistrito(distrito.id);
    }
    
    //UTILS
    defineMenuItems(){
        if (! this.user) {
            this.menuItems = [];
        } else {        
            switch(this.globalVars.getActualPanel()) {
                case 'admin':
                    this.menuItems = ROUTES_ADM;
                    break;
                case 'operations':
                    this.menuItems = ROUTES_OPS;
                    break;
                case 'sells':
                    this.menuItems = ROUTES_SEL;
                    break;
                default:
                    this.menuItems = [];
            }
            this.menuItems = this.menuItems.filter(menuItem => {
                return this.user.hasAtLeastOneRolByName(menuItem.roles);
            });
        }
    }
    
    definePaneles() {
        if (this.user) {
            this.paneles = environment.panels.filter(panel => {
                return this.user.hasAtLeastOneRolByName(environment.panelsRoles[panel]);
            });
        } else {
            this.paneles = [];
        }
    }
    
    public getActualPanel(){
        return this.globalVars.getActualPanel();
    }
    
    isNotMobileMenu(){
        if($(window).width() > 991){
            return false;
        }
        return true;
    }
}
