export const LANG_ES_NAME = 'es';

export const LANG_ES_TRANS = {
    //TRADUCCION SIMPLE
    'example': 'Ejemplo',
    
    //PANEL
    'PANEL_admin': 'Administración',
    'PANEL_operations': 'Operaciones',
    'PANEL_sells': 'Ventas',


    'PANELIN_admin': 'Administración',
    'PANELIN_operations': 'Contable',
    'PANELIN_sells': 'Ventas',
    
    'TIPO_CUENTA_BANCO': 'Cuenta de Banco', 
    'TIPO_CUENTA_CAJA_CHICA': 'Caja Chica', 
    'TIPO_CUENTA_FONDO_FIJO': 'Fondo Fijo',
    'TIPO_CUENTA_OTRO': 'Varios',

    'TIPO_METODO_TRANSFERENCIA': 'Transferencia', 
    'TIPO_METODO_DEBITO': 'Débito',
    'TIPO_METODO_CHEQUE': 'Cheque',
    'TIPO_METODO_EFECTIVO': 'Efectivo',
    'TIPO_METODO_OTRO': 'Otro',
    
    'BOOK_TYPE_AU': 'Automatico',
    'BOOK_TYPE_MA': 'Manual',
    
    'ESTADO_CHEQUE_CARTERA': 'Cartera',
    'ESTADO_CHEQUE_PROVEEDOR': 'Proveedor',
    'ESTADO_CHEQUE_CLIENTE': 'Cliente',
    'ESTADO_CHEQUE_RECHAZADO': 'Rechazado',
    'ESTADO_CHEQUE_RECHADO_N': 'Rechazado con nota',
    'ESTADO_CHEQUE_DEPOSITADO': 'Depositdado',
    'ESTADO_CHEQUE_RETIRO': 'Retiro',
    'ESTADO_CHEQUE_VENDIDO': 'Vendido',
    'ESTADO_CHEQUE_COBRADO': 'Cobrado',
    'ESTADO_CHEQUE_ANULADO': 'Anulado',
    'ESTADO_CHEQUE_CAJA_CHICA': 'Caja Chica',
    
    'TIPO_FACTURA_A': 'Factura A',
    'TIPO_FACTURA_B': 'Factura B',
    'TIPO_FACTURA_C': 'Factura C',
    'TIPO_FACTURA_E': 'Extractos Bancarios',
    'TIPO_FACTURA_X': 'Egresos Varios',
    'TIPO_FACTURA_RP': 'Retenciones Proveedor',
    //NO USADAS
    'TIPO_FACTURA_NC': 'Nota de Crédito',
    'TIPO_FACTURA_ND': 'Nota de Débito',
    'TIPO_FACTURA_NN': 'No Factura',
    //-----
    'TIPO_FACTURA_VENTA_RC': 'Recibo C',
    
    'MONEDA_PES': 'Peso',
    'MONEDA_DOL': 'Dolar',

    'CONIVA_CONFI': 'Consumidor Final',
    'CONIVA_MONOT': 'Monotributista',
    'CONIVA_RESIN': 'Responsable Inscripto',
    'CONIVA_EXENT': 'Exento',
    'CONIVA_RE_NOI': 'Responsable No Inscripto',
    'CONIVA_NORES': 'No Responsable',
    'CONIVA_RES_X': 'Responsable Inscripto X',
    'CONIVA_EGRBA': 'Egresos Bancarios',
    
    'VIATICO_EST_A': 'Aprobado',
    'VIATICO_EST_R': 'Rechazado',
    'VIATICO_EST_P': 'Pendiente',

    'TIPO_VIATICO_K': 'Kilometros',
    'TIPO_VIATICO_H': 'Hotel',
    'TIPO_VIATICO_C': 'Comida',
    'TIPO_VIATICO_O': 'Otros',

    'FACTURA_EST_A': 'Aprobado',
    'FACTURA_EST_R': 'Rechazado',
    'FACTURA_EST_P': 'Pendiente',

    'ORDENVENTA_EST_A': 'Aprobado',
    'ORDENVENTA_EST_R': 'Anulada',
    'ORDENVENTA_EST_P': 'Pendiente',
    'ORDENVENTA_EST_C': 'Confirmada',

    'RECIBO_EST_I': 'Pendiente',
    'RECIBO_EST_C': 'Conf. Afip',
    
    'VIATICO_TIPO_FMC': 'FMC'
};