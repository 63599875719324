<div #chequeModal class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" *ngIf="show">
            <div class="modal-header">
                <h5 class="modal-title col-sm-11 mt-1">Cheque</h5>
                <button class="btn btn-simple btn-info btn-icon like col-sm-1" title="Cerrar" data-dismiss="modal" aria-label="Close" (click)="hide()"><i class="ti-close"></i></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <cheques_form [cheque]="cheque"></cheques_form>
                </div>
            </div>
        </div>
    </div>
</div>