<div class="container-fluid">    
    <div class="row">
        <div class="card">
            <div class="card-content">
                <div class="row">
                    <breadcrumb [items]="breadcrumbItems" class="col-md-10"></breadcrumb>
                </div>
                
                <div class="row">
                    <div class="col-sm-12">
                        <div class="col-sm-12 col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4">
                            <label for="distrito_id">Distrito:</label>
                            <select class="form-control" data-style="btn btn-wd btn-block" name="distrito_id"  [(ngModel)]="distrito_id_selected" (change)="loadAccount()" [disabled]="!actualUser.multi_distrito">
                                <option *ngFor="let distrito of distritos" [ngValue]="distrito.id">{{distrito.nombre}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>          
    </div>

    <div class="row" *ngIf="!loading && cuenta">
        <div class="card">
            <div class="card-content">
                <a [routerLink]="['/cuentas', cuenta.id]">
                    <h4 class="card-title">{{cuenta.descripcion}}</h4>
                </a>
                <hr>

                <div class="row mt-1">
                    <div class="col-sm-12 col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4 text-center">
                        <b>Saldo</b>
                        <br>
                        <b style="font-size: 24px;">$ {{showBalance ? (cuenta.monto | number: '1.2-2') : '****'}}</b>
                        <div class="text-center mt-2">
                            <div (click)="toggleConfigBlance()" title="Mostrar/ocultar balance" style="cursor: pointer;">
                                <i *ngIf="!showBalance" class="cmp-i ocultar i-md" style="vertical-align: middle; margin-bottom: 3px;"></i>     
                                <i *ngIf="showBalance" class="cmp-i ver i-md" style="vertical-align: middle; margin-bottom: 3px;"></i>  
                                {{showBalance ? 'Ocultar' : 'Ver'}} Saldos
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <movimiento-reposicion-form *ngIf="cuenta" [cuenta]="cuenta" (actualize)="onAddMov()"></movimiento-reposicion-form>

    <reposicion-movimientos *ngIf="cuenta" [cuenta]="cuenta"></reposicion-movimientos>

    <div class="row" *ngIf="!loading && !cuenta">
        <h4 class="text-center">El distrito seleccionado no posee una cuenta caja chica asociada</h4>
    </div>

    <div class="row" *ngIf="loading">
        <h4 class="text-center">Cargando...</h4>
    </div>
</div>