import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule }   from '@angular/forms';

import {GenericModule} from '../module-generic/generic.module';
import {TranslateComponentModule} from '../translate-module/translate-component.module';

import {ViaticoService} from './viatico.service';
import {ViaticoCommunicate} from './viatico.communicate';

import {ViaticosComponent} from './viaticos/viaticos.component';
import {ViaticosFormComponent} from './viaticos_form/viaticos_form.component';
import {ItemsViaticoComponent} from './items_viatico/items_viatico.component';
import {ItemsViaticoFormComponent} from './items_viatico_form/items_viatico_form.component';
import {OrdenesComponent} from './ordenes/ordenes.component';

import {PeriodosComponent} from './periodos/periodos.component';
import {PeriodosFormComponent} from './periodos_form/periodos_form.component';
import {PeriodosDetalleComponent} from './periodos_detalle/periodos_detalle.component';
import {TipoViaticosComponent} from './tipo_viaticos/tipo_viaticos.component';
import {TipoViaticosFormComponent} from './tipo_viaticos_form/tipo_viaticos_form.component';

import {ViaticosModalComponent} from './viaticos_modal/viaticos_modal.component';
import {ViaticoModalComponent} from './viatico_modal/viatico_modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    
    GenericModule,
    TranslateComponentModule,
  ],
  declarations: [
    ViaticosComponent,
    ViaticosFormComponent,
    ItemsViaticoComponent,
    ItemsViaticoFormComponent,
    OrdenesComponent,
    
    PeriodosComponent,
    PeriodosFormComponent,
    PeriodosDetalleComponent,

    TipoViaticosComponent,
    TipoViaticosFormComponent,
    
    ViaticosModalComponent,
    ViaticoModalComponent
  ],
  providers: [
    ViaticoService,
    ViaticoCommunicate
  ],
  exports: [
    ViaticosComponent,
    ViaticosFormComponent,
    
    PeriodosComponent,
    PeriodosDetalleComponent,
    
    ViaticosModalComponent,
    ViaticoModalComponent
  ]
})
export class ViaticoModule { }
