import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule }   from '@angular/forms';

import {GenericModule} from '../module-generic/generic.module';
import {TranslateComponentModule} from '../translate-module/translate-component.module';
import {CuentaModule} from '../module-cuenta/cuenta.module';

import {OrdenService} from './orden.service';
import {OrdenCommunicate} from './orden.communicate';

import {OrdenesVentasComponent} from './ordenes/ordenes.component';
import {OrdenesVentasFormComponent} from './ordenes_form/ordenes_form.component';
import {OrdenesFacturasComponent} from './ordenes_facturas/ordenes_facturas.component';
import {RecibosComponent} from './recibos/recibos.component';
import {RecibosFormComponent} from './recibos_form/recibos_form.component';

import {OrdenVentaModalComponent} from './orden_venta_modal/orden_modal.component';
import { NotasCreditoOrdenComponent } from './notas-credito-orden/notas-credito-orden.component';
import { NotaCreditoOrdenFormComponent } from './nota-credito-orden-form/nota-credito-orden-form.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    
    GenericModule,
    TranslateComponentModule,
    CuentaModule,
  ],
  declarations: [
    OrdenesVentasComponent,
    OrdenesVentasFormComponent,
    OrdenesFacturasComponent,
    RecibosComponent,
    RecibosFormComponent,

    OrdenVentaModalComponent,
    NotasCreditoOrdenComponent,
    NotaCreditoOrdenFormComponent
  ],
  providers: [
    OrdenService,
    OrdenCommunicate
  ],
  exports: [
    OrdenesVentasComponent,
    OrdenesVentasFormComponent,

    OrdenVentaModalComponent
  ]
})
export class OrdenVentaModule { }
