import { Injectable, Inject } from '@angular/core';


declare var $: any;

@Injectable()
export class MessageService {
    constructor() { 
    }
    
    showNotification(from, align, color, message){
        //const type = ['','info','success','warning','danger'];
        //const typeA = ['center', 'left', 'right'];
        //const typeF = ['top', 'bottom'];
        $.notify({
            icon: "ti-alert",
            message: message
        },{
            type: color,
            timer: 1500,
            placement: {
                from: from,
                align: align
            },
            z_index: 1600
        });
    }
    
    showErrorLoadTable(){
        this.showNotification('top', 'center', 'danger', 'Ha sucedido un error');
    }
    
    public cursorWait(){
        document.body.style.cursor='wait';
    }
    public cursorDefault(){
        document.body.style.cursor='auto';
    }
}