import { Component, OnInit, Input } from '@angular/core';

import {environment} from '../../../environments/environment';

import {OrdenService} from '../orden.service';
import { MessageService } from '../../services/message.service';
import {FacturaCommunicate} from '../../module-factura/factura.communicate';
import {ViaticoCommunicate} from '../../module-viatico/viatico.communicate';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {OrdenDePagoGeneral, OrdenDePago} from '../models';
import {Factura} from '../../module-factura/models';
import {Viatico} from '../../module-viatico/models';
 
@Component({
  selector: 'ordenes_facturas',
  templateUrl: './ordenes_facturas.component.html'
})
export class OrdenesFacturasComponent extends GenericFormComponent implements OnInit {
  @Input() orden: OrdenDePagoGeneral;
  public ordenes: OrdenDePago[];

  //FORM
  public descuentos: number[] = environment.descuentosCaja;
    
  constructor(protected messageService: MessageService, private ordenService: OrdenService, private facturaCommunicate: FacturaCommunicate, private viaticoCommunicate: ViaticoCommunicate) {
    super(messageService);
  }

  ngOnInit() {
    this.filter();
    
    this.facturaCommunicate.facturasSelecModalPop$.subscribe(
        (facturas: Factura[]) => {
            for (let factura of facturas) {
                let orden: OrdenDePago = new OrdenDePago();
                orden.inicializar(factura);
                orden.ordenpagogeneral = this.orden;
                orden.editando = true;
                this.ordenes.push(orden);
            }
        }
    );
    
    this.viaticoCommunicate.viaticosSelecModalPop$.subscribe(
        (viaticos: Viatico[]) => {
            for (let viatico of viaticos) {
                let orden: OrdenDePago = new OrdenDePago();
                orden.inicializarViatico(viatico);
                orden.ordenpagogeneral = this.orden;
                orden.editando = true;
                this.ordenes.push(orden);
            }
        }
    );
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    this.cursorWait();
    this.ordenService.getOrdenesFacturas(this.orden.id).subscribe(
        (ordenes: OrdenDePago[]) => {
            this.cursorDefault();
            this.ordenes = ordenes;
        },
        error => {
            this.cursorDefault();
        }
    );
  }
  
  //ACCIONES 
  public save(ordenFactura: OrdenDePago){
    this.beforeSubmit(event);
    if(! ordenFactura.id){
        this.ordenService.createOrdenFactura(ordenFactura).subscribe(
            (orden: OrdenDePago) => {
                ordenFactura.editando = false;
                ordenFactura.id = orden.id;
                this.showSuccess(true, 'Ingreso agregada correctamente');
                this.orden.actualizar(orden.ordenpagogeneral);
                this.actualizeForm(orden);
            },err => {
                this.showError(err, true);
            });
    } else {
        this.ordenService.updateOrdenFactura(ordenFactura).subscribe(
            (orden: OrdenDePago) => {
                ordenFactura.editando = false;
                this.showSuccess(true, 'Ingreso modificada correctamente');
                this.orden.actualizar(orden.ordenpagogeneral);
                this.actualizeForm(orden);
            },err => {
                this.showError(err, true);
            });
    }
  }
     
  onNotifyDelete(ordenFactura: OrdenDePago):void {
    this.deleteOrdenFactura(ordenFactura);
  }
    
  public deleteOrdenFactura(ordenFactura: OrdenDePago) {
    this.beforeSubmitList();
    this.ordenService.deleteOrdenFactura(this.orden.id, ordenFactura.id).subscribe(
        (orden: OrdenDePagoGeneral) => {
            this.showSuccessList(true, 'Ingreso eliminado correctamente');
            //ELIMINO DE LA LISTA      
            this.ordenes.forEach(function(ordenFor, index, object) {
                if(ordenFor.id == ordenFactura.id) {
                    object.splice(index, 1);
                }
            });
            this.orden.actualizar(orden);
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }
  
    
  //FORMULARIO ALTA/EDICION  
  //Desde el formulario
  public addDocumento(tipo: string) {
    let data: any = {};
    if (! this.orden.multi_proveedor) {
        data['proveedor'] = this.orden.proveedor.id;
    }
    if (tipo == 'F') {
        data['moneda'] = this.orden.moneda;
        this.facturaCommunicate.facturasModalPopUp(data);
    } else {
        this.viaticoCommunicate.viaticosModalPopUp(data);
    }
  }
  
  public actualizeForm(ordenFactura: OrdenDePago): void {
    this.ordenes.forEach(function(ordenFor, index, object) {
        if (ordenFor.id == ordenFactura.id) {
            object[index]= ordenFactura;
        }
    });
  }
  
  public cancel(ordenFactura: OrdenDePago) {
    if (! ordenFactura.id) {
        this.ordenes.forEach(function(ordenFor, index, object) {
            if (ordenFor.factura && ordenFor.factura.id == ordenFactura.factura.id && ordenFor.editando == true) {
                object.splice(index, 1);
            }
            if (ordenFor.viatico && ordenFor.viatico.id == ordenFactura.viatico.id && ordenFor.editando == true) {
                object.splice(index, 1);
            }
        });
    } else{
        ordenFactura.editando = false;
    }
  }
  
  public detalleDocumento(orden: OrdenDePago) {
    if (orden.factura) {
        this.facturaCommunicate.facturaModalPopUp(orden.factura.id);
    } else {
        this.viaticoCommunicate.viaticoModalPopUp(orden.viatico.id);
    }
  }
}