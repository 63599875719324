import { Component, OnInit } from '@angular/core';

declare var $:any;

declare interface DataTable {
    headerRow: string[];
    footerRow: string[];
    dataRows: string[][];
}

@Component({
    selector: 'data-table-cmp',
    templateUrl: 'orden.component.html'
})

export class OrdenComponent implements OnInit{
    public dataTable: DataTable;
    public chequeTable: DataTable;
    ngOnInit(){
        this.dataTable = {
            headerRow: [ 'Emisiòn', 'Fecha Venc.', 'Id', 'P. Venta', 'Número', 'Mon', 'Total (mon. original)', 'Retención IB', 'Total', 'Saldo', 'Imputación', 'Ret IB', 'Ret %', 'Ret Tot', 'Comp'],
            footerRow: [ 'Emisiòn', 'Fecha Venc.', 'Id', 'P. Venta', 'Número', 'Mon', 'Total (mon. original)', 'Retención IB', 'Total', 'Saldo', 'Imputación', 'Ret IB', 'Ret %', 'Ret Tot', 'Comp'],
            dataRows: [
            ]
         };
         this.chequeTable = {
            headerRow: [ 'Nombre', 'Cuenta', 'Número', 'Chequera', 'Acreditar', 'Importe'],
            footerRow: [ 'Nombre', 'Cuenta', 'Número', 'Chequera', 'Acreditar', 'Importe'],
            dataRows: [
            ]
         };
    }
}
