import {Component, OnInit, AfterViewInit } from '@angular/core';

import {environment} from '../../../environments/environment';

import {ReportesService} from '../reportes.service';
import {CuentaService} from '../../module-cuenta/cuenta.service';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';
import {PdfService} from '../../services/pdf.service';
import {ProjectUtils} from '../../services/utils';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Cuenta} from '../../module-cuenta/models';


@Component({
  selector: 'reporte_caja_chica',
  templateUrl: './reporte-caja-chica.component.html'
})
export class ReporteCajaChicaComponent extends GenericFormComponent implements OnInit {
  public params = {excel: 0, cuenta: null};
  public filtro = { fecha_contable_gteq: null, fecha_contable_lteq: null, sort: { key: 'fecha_contable', order: 'desc', description: 'Fecha creación' }, distrito_id: null};
    
  public labels: string[] = environment.meses;
  public meses: number[] = [];
  public cuenta: Cuenta 
  public cuentas: Cuenta[] = [];
  public BANK_LOGO_IDS = environment.bank_logo_ids;
  public loading: boolean = false;
    

  constructor(protected messageService: MessageService, private globalVars: GlobalVars, private reportesService: ReportesService, 
      private pdfService: PdfService, private cuentaService: CuentaService, private projectUtils: ProjectUtils) {
    super(messageService);
  }

  ngOnInit() {
    this.filtro.distrito_id = this.globalVars.getActualDistrito();
    
    this.cuentaService.getCuentas({per_page: 1000}, {tipo_eq: 'CAJA_CHICA', distrito_id_eq: this.globalVars.getActualDistrito(), sort: 'nombre'}).subscribe(
        rta => {
            this.cuentas = rta.data;
        }
    );
  }

  public changeFilterDate(value){
    this.filtro.fecha_contable_gteq = value.from;
    this.filtro.fecha_contable_lteq = value.to;
  }


  public pdfMovimientos(excel: number) {
    if (this.params.cuenta == null) {
      alert("Seleccione una cuenta");
      return;
    }
    this.cursorWait();
    this.params.excel = excel;
    this.loading = true;
    let endpointParams = {
      excel: excel,
      cuenta: this.params.cuenta?.id
    }

    this.reportesService.pdfCajaChica(this.params.cuenta.id, endpointParams, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            if (excel) {
                this.pdfService.loadExcel("caja_chica", rta);                
            } else {
                this.pdfService.loadPdf("caja_chica", rta);
            }
            this.loading = false;
        },
        error => {
            this.cursorDefault();
            this.loading = false;
            alert('Ups! No pudimos imprimir el pdf');
        }
    );
  }
}