import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Proveedor } from 'app/module-proveedor/models';
import { ProveedorService } from 'app/module-proveedor/proveedor.service';

declare var $: any;

@Component({
  selector: 'provider-select',
  templateUrl: './provider-select.component.html',
  styleUrls: ['./provider-select.component.sass']
})
export class ProviderSelectComponent implements OnInit, AfterViewInit {
  @Input() providerModel: Proveedor;
  @Output() providerModelChange: EventEmitter<Proveedor> = new EventEmitter<Proveedor>();
  @Output() onModelChange: EventEmitter<Object> = new EventEmitter<Object>();
  @Input() title?: string = "Seleccione un Proveedor";

  private debounceTime;
  public proveedores: Proveedor[] = [];
  public errorMsg: string;
  
  @ViewChild('select_proveedor', { static: false }) selectProveedor: ElementRef;

  constructor(private proveedorService: ProveedorService) {}

  ngOnInit(): void {
    this.errorMsg = "";
  }

  ngAfterViewInit(): void {
    $(this.selectProveedor.nativeElement).selectpicker({
      noneResultsText: ''
    });

    $(document).on('keyup', $(this.selectProveedor.nativeElement).selectpicker('.bs-searchbox').val(), (e) => {
        e.preventDefault();
        if(!(e.target.type == 'search' && e.target.role == 'combobox' && e.target.role == 'combobox' && e.target.ariaAutoComplete == 'list'))
          return;
        
        if(e.target.value?.length < 3) {
            this.setProveedores([], e.target.value, false);
            clearTimeout(this.debounceTime);
            return;
        }
        let searchText =`${e.target.value}`;
        
        clearTimeout(this.debounceTime);
        this.debounceTime = setTimeout(() => {
            this.proveedorService.getProveedores({page: 1, per_page: 10}, { search_elastic: searchText, sort: { key: 'nombre', order: 'asc', description: 'Nombre' }}).subscribe(
                rta => {
                    this.setProveedores(rta.data, searchText, false);
                },
                error => {
                    this.setProveedores([], searchText, true);
                }
            );
        }, 300);
    });

    $(this.selectProveedor.nativeElement).on('show.bs.select', () => {
      setTimeout(() => {
        $("div.bs-searchbox > input").get(0).focus();
      },100);
    });
  }

  private setProveedores(proveedores: Proveedor[], textSearched: string, error: boolean){
      this.errorMsg = "";

      if(error)
        this.errorMsg = "Error al realizar la busqueda, intente nuevamente."

      if(!error && textSearched.length > 0 && textSearched.length < 3) 
        this.errorMsg = "Ingrese al menos 3 caracteres."

      if(!error && textSearched.length >= 3 && proveedores.length == 0) 
        this.errorMsg = `No se encontraron resultados para ${textSearched}.`

      this.proveedores = proveedores;

      setTimeout(() => {
          $(this.selectProveedor.nativeElement).selectpicker('refresh');
      },10);
  }

  public onChange(){
    this.providerModelChange.emit(this.providerModel);
    this.onModelChange.emit();
  }

}
