<div *ngIf="info">
    <div class="row"> 
        <div class="col-sm-4">
            <div class="row text-right">
                <div class="col-sm-8 text-right" style="margin-top: 5px;">Registros por página:</div>
                 <div class="col-sm-4">
                     <select class="form-control pull-left" name="per_page" [(ngModel)]="perPage" (change)="filterPage(1)">
                         <option *ngFor="let cant of cant_listado" [value]="cant">{{cant}}</option>
                     </select>
                </div>
            </div>
        </div>   
        <div class="col-sm-4 text-right" style="margin-top: 5px;">
             Registros totales: {{info.count}}   //   Página {{actualPage}} de {{info.countOfPages}}
        </div>      
        <div class="col-sm-4 text-right">
            <ul class="pagination float-right">
                <li class="page-item" [ngClass]="{disabled: info.page == 1}">
                    <a (click)="filterPage(info.first_page)" aria-label="First" [disabled]="info.page == 1" title="Primera: 1" >
                        <span aria-hidden="true">{{'<<'}}</span>
                    </a>
                </li>

                <li class="page-item" [ngClass]="{disabled: info.page == 1}">
                    <a (click)="filterPage(info.previous)" aria-label="Previous" [disabled]="info.page == 1" [title]="'Anterior: ' + (info.previous || 1)">
                        <span aria-hidden="true">{{'<'}}</span>
                    </a>
                </li>

                <li class="page-item active">
                    <a aria-label="Actual" [disabled]="true" [title]="'Página: ' + actualPage">
                        <span aria-hidden="true">{{actualPage}}</span>
                    </a>
                </li>  

                <li class="page-item" [ngClass]="{disabled: info.page == info.last_page}">
                    <a (click)="filterPage(info.next)" aria-label="Next" [disabled]="info.page == info.last_page" [title]="'Siguiente: ' + (info.next || info.last_page)">
                        <span aria-hidden="true">{{'>'}}</span>
                    </a>
                </li>

                <li class="page-item" [ngClass]="{disabled: info.page == info.last_page}">
                    <a (click)="filterPage(info.last_page)" aria-label="Last" [disabled]="info.page == info.last_page" [title]="'Ultima: ' + info.last_page">
                        <span aria-hidden="true">{{'>>'}}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>