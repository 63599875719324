<div class="row">
    <div class="card">
        <div class="card-content">
            <h4 class="card-title">Notas de Credito - Usadas</h4>      
            <hr>      

            <div class="row" *ngIf="! orden.estaPagado(redondeo)">                        
                <form class="form-inline" (submit)="filter(1, $event)" #searchNotasForm="ngForm">    
                    <div class="col-md-4">
                        <label for="nota">Nota:</label>
                        <select class="form-control ml-1" data-style="btn btn-wd btn-block" name="nota" [(ngModel)]="nota">
                            <option [ngValue]="null">Seleccione una nota</option>
                            <option *ngFor="let nota of notasLibres" [ngValue]="nota">{{nota.fecha | date: 'dd/MM/yyyy'}} - $ {{nota.monto}}</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <button class="btn btn-simple btn-icon btn-add" (click)="agregarNota()" *ngIf="orden.tieneDocumentos()" title="Agregar nota"><i class="cmp-i agregar"></i> Agregar nota</button>
                    </div>
                    <div class="col-md-4" *ngIf="notasLibres && notasLibres.length > 0">
                        <p><strong>Tiene notas pendientes para utilizar !!!</strong></p>
                    </div>
                </form>
            </div>
            
            <div class="row">
                <div class="col-md-12 table-responsive">
                    <table class="table table-hover">
                        <thead class="text-primary">
                            <tr>
                                <th><b>Fecha</b></th>
                                <th><b>Monto</b></th>
                                <th><b>Moneda</b></th>
                                <th><b>Acciones</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor='let nota of notas'>
                                <td>{{nota.fecha | date: 'dd/MM/yyyy'}}</td>
                                <td>$ {{nota.monto | number: '1.2-2'}}</td>
                                <td>{{nota.moneda | translate: 'MONEDA'}}</td>
                                
                                <td class="td-actions text-right">
                                    <button *ngIf="! orden.verificada" 
                                        [disabled]="orden.verificada"
                                        [title]="orden.verificada ? 'No es posible liberar la nota de credito ya que la orden esta verificada.' : 'Liberar nota de credito'" 
                                        type="button" rel="tooltip" class="btn btn-danger btn-simple btn-xs" (confirm-click)="onNotifyDelete($event)" [confirm-params]="nota" confirm-message="¿Está seguro que desea liberar la nota de credito?">
                                        <i class="cmp-i eliminar-borrar i-sm"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr *ngIf="notas != null && notas.length == 0" class="text-center">
                                <td colspan="4">Sin notas de credito</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>