import {Distrito} from '../module-distrito/models';
import {Proveedor} from '../module-proveedor/models';
import {User} from '../models/user';

export class Viatico {
    public id: number;
    public numero: number;
    public emision: any;
    public monto: number;
    public monto_neto: number;
    public monto_real: number;
    public tipo: string = null;
    public descripcion: string;
    public estado: string;
    public autorizado: boolean;
    public cantidad_veces: number = 1;
    public direccion_origen: string = null;
    public direccion_destino: string = null;
    public pagado: number = null;
    
    public distrito: Distrito = null;
    public periodo: Periodo = null;
    public proveedor: Proveedor = null;
    public user: User = null;
    public imputacion_viatico: ImputacionViatico = null;

    public distrito_id: any;
    public imputacion_viatico_id: any = null;
    
    public selectedInTable: boolean = false;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.numero = json.numero;
            this.emision = json.emision;
            this.monto = json.monto;
            this.monto_neto = json.monto_neto;
            this.monto_real = json.monto_real;
            this.tipo = json.tipo;
            this.descripcion = json.descripcion;
            this.estado = json.estado;
            this.autorizado = json.autorizado;
            this.cantidad_veces = json.cantidad_veces;
            this.direccion_origen = json.direccion_origen;
            this.direccion_destino = json.direccion_destino;
            this.pagado = json.pagado;
            
            if (json.distrito) {
                this.distrito = new Distrito(json.distrito);
            }
            if (json.periodo) {
                this.periodo = new Periodo(json.periodo);
            }
            if (json.user) {
                this.user = new User(json.user);
            }
            if (json.proveedor) {
                this.proveedor = new Proveedor(json.proveedor);
            }

            if (json.imputacion_viatico) {
                this.imputacion_viatico = new ImputacionViatico(json.imputacion_viatico);
            }
            this.distrito_id = json.distrito_id;
            this.imputacion_viatico_id = json.imputacion_viatico_id;
        }
    }
    
    public actualizar(viatico: Viatico) {
        this.monto = viatico.monto;
        this.monto_neto = viatico.monto_neto;
        this.monto_real = viatico.monto_real;
    }
    
    public restaPagar() {
        return Number((this.monto_neto - this.pagado).toFixed(2));
    }
}

export class ItemViatico {
    public id: number;
    public cantidad: number = 1;
    public monto: number = 0;
    public monto_diario: number = 0; // Es el monto del tipo viatico modificado
    public kilometros: number;
    public descripcion: string;
    public tipo_viatico: TipoViatico = null;
    public viatico: Viatico = null;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.cantidad = json.cantidad;
            this.monto = json.monto;
            this.monto_diario = json.monto_diario;
            this.kilometros = json.kilometros;
            this.descripcion = json.descripcion;
            
            if (json.tipo_viatico) {
                this.tipo_viatico = new TipoViatico(json.tipo_viatico);
            }
            if (json.viatico) {
                this.viatico = new Viatico(json.viatico);
            }
        }
    }
}

export class Periodo {
    public id: number;
    public nombre: string;
    public fecha: any;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.nombre = json.nombre;
            this.fecha = json.fecha;
        }
    }
}

export class TipoViatico {
    public id: number;
    public tipo: string = null;
    public monto: number;
    public kilometros_desde: number;
    public kilometros_hasta: number;
    public kilometros: string;
    
    public periodo: Periodo = null;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.tipo = json.tipo;
            this.monto = json.monto;
            this.kilometros_desde = json.kilometros_desde;
            this.kilometros_hasta = json.kilometros_hasta;
            this.kilometros = this.kilometros_desde + ' - ' + this.kilometros_hasta;
            if (json.periodo) {
                this.periodo = new Periodo(json.periodo);
            }
        }
    }
}

export class NotaCreditoViatico {
    public id: number;
    public monto: number;
    public fecha: any;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.monto = json.monto;
            this.fecha = json.fecha;
        }
    }
}


export class ImputacionViatico {
    public id: number;
    public codigo: string;
    public nombre: string;
    public descripcion: string;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.codigo = json.codigo;
            this.nombre = json.nombre;
            this.descripcion = json.descripcion;
        }
    }
}

