import { Injectable } from '@angular/core';

import { Subject }    from 'rxjs';

import {Factura} from './models';

@Injectable()
export class FacturaCommunicate {
    // Observable string sources    
    private facturasModalPopSource = new Subject<any>();
    private facturaModalPopSource = new Subject<number>();
    private facturasSelecModalPopSource = new Subject<Factura[]>();
    
    // Observable string streams
    public facturasModalPop$ = this.facturasModalPopSource.asObservable();
    public facturaModalPop$ = this.facturaModalPopSource.asObservable();
    public facturasSelecModalPop$ = this.facturasSelecModalPopSource.asObservable();
    
    constructor() { 
    }
    
    public facturasModalPopUp(info: any){
        this.facturasModalPopSource.next(info);
    }
    public facturaModalPopUp(id: number){
        this.facturaModalPopSource.next(id);
    }  
    public facturasSelecModalPopUp(facturas: Factura[]){
        this.facturasSelecModalPopSource.next(facturas);
    }
}