import { Component, OnInit, Input } from '@angular/core';

import {OrdenService} from '../orden.service';
import {CuentaCommunicate} from '../../module-cuenta/cuenta.communicate';
import { GlobalVars } from '../../services/globalVars';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {OrdenDePagoGeneral} from '../models';
import {Proveedor} from '../../module-proveedor/models';
import {Movimiento, Cheque} from '../../module-cuenta/models';
import { environment } from 'environments/environment';
 
@Component({
  selector: 'movimientos_orden',
  templateUrl: './movimientos.component.html'
})
export class MovimientosComponent extends GenericFormComponent implements OnInit {
  @Input() orden: OrdenDePagoGeneral;
  @Input() proveedor: Proveedor;
  public movimientos: Movimiento[];

  public redondeo: number = 0;

  //FORM
  public movimiento: Movimiento;

  public BANK_LOGO_IDS = environment.bank_logo_ids;
    
  constructor(protected messageService: MessageService, private ordenService: OrdenService, private cuentaCommunicate: CuentaCommunicate,
    private globalVars: GlobalVars) {
    super(messageService);
  }

  ngOnInit() {
    this.redondeo = this.globalVars.config.redondeo;
    this.filter();
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    this.cursorWait();
    this.ordenService.getMovimientosOrden(this.orden.id).subscribe(
        (movimientos: Movimiento[]) => {
            this.cursorDefault();
            this.movimientos = movimientos;
        },
        error => {
            this.cursorDefault();
        }
    );
  }
  
  //ACCIONES    
  onNotifyDelete(movimiento: Movimiento):void {
    this.deleteMovimiento(movimiento);
  }

  onNotifyAnular(movimiento: Movimiento):void {
    this.anularMovimiento(movimiento);
  }
    
  public deleteMovimiento(movimiento: Movimiento) {
    this.beforeSubmitList();
    this.ordenService.deleteMovimiento(movimiento.id).subscribe(
        (orden: OrdenDePagoGeneral) => {
            this.showSuccessList(true, 'Movimiento eliminado correctamente. El Cheque ha quedado liberado.');
            //ELIMINO DE LA LISTA      
            this.movimientos.forEach(function(movimientoFor, index, object) {
                if(movimientoFor.id == movimiento.id) {
                    object.splice(index, 1);
                }
            });
            this.orden.actualizar(orden);
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }

  public anularMovimiento(movimiento: Movimiento) {
    this.beforeSubmitList();
    this.ordenService.anularMovimiento(movimiento.id).subscribe(
        (orden: OrdenDePagoGeneral) => {
            this.showSuccessList(true, 'Cheque anulado correctamente');
            //ELIMINO DE LA LISTA      
            this.movimientos.forEach(function(movimientoFor, index, object) {
                if(movimientoFor.id == movimiento.id) {
                    object[index].anulado = true;
                    object[index].monto = 0;
                }
            });
            this.orden.actualizar(orden);
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }
  
    
  //FORMULARIO ALTA/EDICION
  //Para el Form
  public newMovimiento(){
    this.movimiento = new Movimiento();
    this.movimiento.ordenpago = this.orden;
    this.movimiento.monto = this.orden.restaPagar();
    this.movimiento.moneda = this.orden.moneda;
  }
  
  //Desde el formulario
  public actualizeForm(movimiento: Movimiento): void {
    this.movimientos.splice(0, 0, movimiento);
    this.orden.actualizar(movimiento.ordenpago);
    this.cancelForm();
  }    
  public cancelForm(event?): void{
    this.movimiento = null;
  }

  public detalleCheque(cheque: Cheque) {
    this.cuentaCommunicate.chequeModalPopUp(cheque);
  }
}