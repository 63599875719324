import { FilterCache } from "app/models/filter-cache";

const SUFIX_ID = '_filters';

export class FilterCacheUtilsService {

  constructor() { }

  public setFilterParams(id: string, filters: any, params: any): void {
    let dateTime = new Date();
    let toStorage = {
      filters,
      params,
      expirationDate: (dateTime).setHours(dateTime.getHours() + 12)
    }
    localStorage.setItem(`${id}${SUFIX_ID}`, JSON.stringify(toStorage));
  }

  public getFilterParams(id: string): FilterCache {
    let item = localStorage.getItem(`${id}${SUFIX_ID}`);
    if (!item) return null;
    let jsonItem: FilterCache = JSON.parse(item);
    let expired = new Date(jsonItem.expirationDate).getTime() < (new Date()).getTime();
    if (expired) localStorage.removeItem(`${id}${SUFIX_ID}`);
    return expired ? null : jsonItem; 
  }
}
