import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReposicionComponent } from './reposicion/reposicion.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { GenericModule } from 'app/module-generic/generic.module';
import { MovimientosComponent } from './movimientos/movimientos.component';
import { TranslateComponentModule } from 'app/translate-module/translate-component.module';
import { MovimientoReposicionFormComponent } from './movimiento-reposicion-form/movimiento-reposicion-form.component';
import { CuentaModule } from 'app/module-cuenta/cuenta.module';


@NgModule({
  declarations: [
    ReposicionComponent,
    MovimientosComponent,
    MovimientoReposicionFormComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    GenericModule,
    TranslateComponentModule,
    CuentaModule
  ]
})
export class ModuleReposicionModule { }
