import {Component, ElementRef } from '@angular/core';

import { MessageService } from '../../services/message.service';

declare var $: any;

@Component({
  selector: 'generic-form',
  template: '<p>Vista Abstracta</p>'
})

export class GenericFormComponent {
    public submitted: boolean = false;
    public error: string = null;
    public success: string = null;
    public inputError: any;
    public successList: string = null;
    public errorList: string = null;
    
    public info: any;
    
    constructor(protected messageService: MessageService) { 
        this.inputError = [];
    }
          
    //UTILS
    //-Wait / -Default
    public cursorWait(event?){
        if(event){
            event.preventDefault(); 
        }
        document.body.style.cursor ='wait';
    }
    public cursorDefault(){
        document.body.style.cursor ='auto';
    }
    
    //-Form
    protected beforeSubmit(event?){
        if(event){
            event.preventDefault(); 
        }
        this.submitted = true;        
        this.resetForm();
        this.cursorWait();
    }
    
    protected noShowSuccess(){
        this.cursorDefault();
        this.submitted = false;
    }
    protected showSuccess(popUp = false, defaultMsj?){
        this.cursorDefault();
        this.submitted = false;
        this.success = defaultMsj;
        if (popUp && this.success) {            
            this.messageService.showNotification("top", "center", "success", this.success);
        }
        this.resetFormTimeOut();
    }
    
    protected showError(err, popUp = false, defaultMsj?){
        this.cursorDefault();
        this.submitted= false;
        this.error = err.error;
        this.error = this.error || defaultMsj;
        if(popUp && this.error){
            this.messageService.showNotification("top", "center", "warning", this.error);
        }
        this.inputError = err;        
        this.resetFormTimeOut();
    }

    protected setInputError(name, value) {
        if (! this.inputError) {
            this.inputError = {};
        }
        this.inputError[name] = value;
    }

    protected removeInputError(name) {
        if (! this.inputError) {
            return;
        }
        delete this.inputError[name];
    }
    
    protected resetFormTimeOut(){
        setTimeout(() => {
            this.success= null;
            this.error= null;
        }, 3000);
    }
    
    protected resetForm(){
        this.success= null;
        this.error= null;
        this.inputError=[];
    }
    
    //-List
    protected beforeSubmitList(event?){
        if(event){
            event.preventDefault(); 
        }       
        this.resetList();  
        this.cursorWait();
    }
    
    protected noShowSuccessList(){
        this.cursorDefault();
    }
    protected showSuccessList(popUp = false, defaultMsj?){
        this.cursorDefault();
        this.successList = defaultMsj;
        if(popUp && this.successList){            
            this.messageService.showNotification("top", "center", "success", this.successList);
        }
        this.resetListaTimeOut();
    }
    
    protected showErrorList(err, popUp = false, defaultMsj?){
        this.cursorDefault();
        this.errorList = err.error;
        this.errorList = this.errorList || defaultMsj;
        if(popUp && this.errorList){
            this.messageService.showNotification("top", "center", "danger", this.errorList);
        }
        this.resetListaTimeOut();
    }
    
    private resetListaTimeOut(){
        setTimeout(() => {this.resetList();}, 3000);
    }
    
    private resetList(){
        this.successList= null;
        this.errorList= null;
    }
    
    //PAGER
    public assemblePager(info: any){
        this.info = info;    
        this.info.pages = [];
        this.info.first_page = 1;
        this.info.last_page = this.info.countOfPages;

        for(var i = 1; i <= this.info.countOfPages; i++){
            if(i - this.info.page <= 5 && i - this.info.page >= -5){
                this.info.pages.push(i);
            }
        }
        if (this.info.page > 1) {
            this.info.previous = this.info.page - 1;
        }
        if (this.info.page < this.info.countOfPages) {
            this.info.next = this.info.page + 1;
        }
    }
    
    //SCROLL
    public scrollToElement(el: ElementRef) {
        setTimeout(() => {
            window.scrollTo(0, $(el.nativeElement).prop('offsetTop'));
        }, 50);
    }

    //INPUTS
    validDate($event) {
        if (! $event) {
            return true;
        }
        let date = new Date($event)
        return date.getFullYear() >= 100;
    }
}