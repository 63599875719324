
import {filter} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd} from '@angular/router';

import {TranslateService} from './translate-module/translate.service';
import { User } from './models/user';
import { UserService } from './services/user.service';

declare const $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  user: User;
  globalVars: any;

  constructor(private _translate: TranslateService, private router: Router, private userService: UserService) {}

  ngOnInit() {
    this.user = this.globalVars?.actualUser;
    //To the Top after route
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)).subscribe(
            (data: NavigationEnd) => {
                //this.isInHome = data.url == '/home';
                //
                //ACA PODRIA IGNORAR ALUNA RUTA EN CASO DE SER NECESARIO
                //
                //NO SCROLLEA PORQ EN REALIDAD ESTA EN EL TOP POR EL SIDEBAR
                //
                document.querySelector('#super-nav-bar')?.scrollIntoView();                
            }
        );
        
      
    //set current langage
    this._translate.use('es');

    this.userService.loginUser$.subscribe(
      user => {
          this.user = user;
      }
    );

    this.userService.logoutUser$.subscribe(
      user => {
          this.user = null;
          this.router.navigate(['/login']);
      }
    );
  }
}