import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cuitformat',
})
export class CuitFormatPipe implements PipeTransform {

    transform(value: string): any {
        if (!value || value.length != 11) return value;
        return `${value.substring(0,2)}-${value.substring(2,10)}-${value.substring(10,11)}`
    }
}