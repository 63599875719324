import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { User } from 'app/models/user';
import { Distrito } from 'app/module-distrito/models';
import { GenericFormComponent } from 'app/module-generic/components/generic-form.component';
import { GlobalVars } from 'app/services/globalVars';
import { MessageService } from 'app/services/message.service';
import { NotaCreditoAfipService } from '../nota-credito-afip.service';
import { DistritoService } from 'app/module-distrito/distrito.service';
import { NotaCreditoAfip } from 'app/module-orden-venta/models';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
import Sort from 'app/models/Sort';
import { GenericPeriodComponent } from 'app/module-generic/components/generic_period/generic_period.component';

@Component({
  selector: 'nota-credito-afip',
  templateUrl: './nota-credito-afip.component.html',
  styleUrls: ['./nota-credito-afip.component.sass']
})
export class NotaCreditoAfipComponent extends GenericFormComponent implements OnInit {
  public actualUser: User;

  public notaCreditoAfips: NotaCreditoAfip[];
  public params = {page: 1, per_page: 10, proveedor_search: null};

  public sortOptions: Sort[] = [
    { key: 'fecha', order: 'desc', description: 'Fecha' },
    { key: 'monto', order: 'desc', description: 'Monto' }
  ]

  public filtroDefault = { fecha_gteq: null, fecha_lteq: null, distrito_id_eq: null, punto_venta_eq: null, nro_comprobante_eq: null, descripcion: null, sort: this.sortOptions[0]};
  public filtro = Object.assign({}, this.filtroDefault);
  public someFiltersApplied: boolean = false;

  public distritos: Distrito[] = [];

  @ViewChild('fac_select_imputaciones') selectImputaciones: ElementRef;
  // @ViewChild('fac_select_proveedores') selectProveedores: ElementRef;

  @ViewChild('generic_period') genericPeriod: GenericPeriodComponent;

  public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Notas de Crédito Afip', active: true }];

  constructor(protected messageService: MessageService, private globalVars: GlobalVars, 
    private notaCreditoAfipService: NotaCreditoAfipService, private distritoService: DistritoService,
      private ngZone: NgZone) {
    super(messageService);
  }

  ngOnInit() {
    this.actualUser = this.globalVars.actualUser;
    this.filtro.distrito_id_eq = this.globalVars.getActualDistrito();
    this.filter();
    this.distritoService.getDistritos({per_page: 1000}, {sort: 'nombre'}).subscribe(
        (rta: any) => {
            this.distritos = rta.data;
        }
    );
    // this.proveedorService.getProveedores({page: 1}, {sort: 'nombre'}).subscribe(
    //     rta => {
    //         this.proveedores = rta.data;
    //         this.ngZone.onStable.first().subscribe(() => {
    //             $(this.selectProveedores.nativeElement).selectpicker(); 
    //         });
    //     }
    // );
  }

  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }

    this.cursorWait();    
    this.someFiltersApplied = this.globalVars.isSomeFilterApplied(this.filtro);

    this.notaCreditoAfipService.getNotaCreditoAfips(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.notaCreditoAfips = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
        },
        error => {
            this.cursorDefault();
        }
    );
  }

  private loadInitFilters(): void {
    this.filtro.distrito_id_eq = this.globalVars.getActualDistrito();
  }

  public changeFilterDate(value){
    this.filtro.fecha_gteq = value.from;
    this.filtro.fecha_lteq = value.to;
    this.filter(1);
  }

  public resetFilters() {
    this.filtro = Object.assign({}, this.filtroDefault);
    this.genericPeriod.setEmptyPeriod();
    this.loadInitFilters();
    this.filter();
  }

  public deleteNotaCredito(notaCreditoAfip: NotaCreditoAfip) {
    this.notaCreditoAfipService.deleteNotaCreditoAfip(notaCreditoAfip).subscribe(
      () => {
        this.showSuccess(true, 'Nota de crédito eliminada correctamente');
        this.filter();
      },
      error => {
          this.cursorDefault();
      }
    );
  }

  public onConfirmAfip(notaCreditoAfip: NotaCreditoAfip) {
    this.beforeSubmitList();
    this.notaCreditoAfipService.confirmarNotaCreditoAfip(notaCreditoAfip).subscribe(
      (notaCreditoAfip: NotaCreditoAfip) => {
        this.showSuccessList(true, 'Nota de crédito confirmada correctamente');
        this.filter();
      }, error => {
        this.showErrorList(error, true);
      }
    );
  }

  public onPrintAfip(notaCreditoAfip: NotaCreditoAfip) {
    this.cursorWait();
    this.notaCreditoAfipService.imprimirNotaCreditoAfip(notaCreditoAfip).subscribe(
      rta => {
        this.cursorDefault();
        //this.pdfService.loadPdf("orden_pago", rta); 
      },
      error => {
        this.cursorDefault();
        alert('Ups! No pudimos imprimir el pdf');
      }
    );
  }
}
