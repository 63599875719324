<div class="row">
    <div class="card">
        <div class="card-content">
            <h4 class="card-title">Kilometros</h4> 
            <hr>           
            
            <div class="row">
                <div class="col-md-6 col-xs-12">
                    <div class="row">
                        <label class="col-md-5 label-on-left">Incrementar kilometros %</label>
                        <div class="col-md-7">                                  
                            <input type="number" name="nombre" [(ngModel)]="porcentaje" class="form-control" required>
                            <small [hidden]="!inputError.porcentaje" [innerHtml]="inputError.porcentaje" class="text-danger"></small>
                        </div>                        
                    </div>                    
                </div>
                <div class="col-md-6 col-xs-12">
                    <button class="btn btn-primary" type="submit" [disabled]="submitted" (click)="actualizarKilometros()">Actualizar</button>
                </div>
            </div>
            
            <div class="row">
                <div class="col-md-12 table-responsive">
                    <table class="table table-hover">
                        <thead class="text-primary">
                            <tr>
                                <th><b>Kilometros Desde</b></th>
                                <th><b>Kilometros Hasta</b></th>
                                <th><b>Monto</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor='let viaticosKilometro of viaticosKilometros'>
                                <td>
                                    <span *ngIf="!viaticosKilometro.kilometros_desde">-</span>
                                    <span *ngIf="viaticosKilometro.kilometros_desde">{{viaticosKilometro.kilometros_desde | number : '1.0-0'}}</span>
                                </td>
                                <td>
                                    <span *ngIf="!viaticosKilometro.kilometros_hasta">-</span>
                                    <span *ngIf="viaticosKilometro.kilometros_hasta">{{viaticosKilometro.kilometros_hasta | number : '1.0-0'}}</span>
                                </td>
                                <td>
                                    <input #firstElementImputacion type="text" name="nombre" [(ngModel)]="viaticosKilometro.monto" class="form-control" required >
                                    <small [hidden]="!inputError.monto" [innerHtml]="inputError.monto" class="text-danger"></small>
                                </td>
                            </tr>
                            <tr *ngIf="viaticosKilometros != null && viaticosKilometros.length == 0" class="text-center">
                                <td colspan="8">No se encontraron Tipos Viáticos</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row text-center">
                    <button class="btn btn-primary" type="submit" [disabled]="submitted" (click)="guardarKilometros()">Guardar</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-sm-12 col-md-6">
        <div class="row">
            <div class="card">
                <div class="card-content">
                    <h4 class="card-title">Viático Hotel</h4>
                    <hr>
                    <form class="form-horizontal" (submit)="guardarHotel($event)" #datosHotelForm="ngForm">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-12">
                                        <label class="col-md-3 label-on-left">Monto</label>
                                        <div class="col-md-9">                                  
                                            <input type="number" name="viatico_hotel" [(ngModel)]="viaticoHotel.monto" class="form-control" required>
                                            <small [hidden]="!inputError.monto" [innerHtml]="inputError.monto" class="text-danger"></small>
                                        </div>                            
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <div class="form-group">
                                    <button class="btn btn-primary" type="submit" [disabled]="submitted">Guardar</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="col-sm-12 col-md-6">
        <div class="row">
            <div class="card">
                <div class="card-content">
                    <h4 class="card-title">Viático Comida</h4>
                    <hr>
                    <form class="form-horizontal" (submit)="guardarComida($event)" #datosComidaForm="ngForm">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-12">
                                        <label class="col-md-3 label-on-left">Monto</label>
                                        <div class="col-md-9">                                  
                                            <input type="number" name="viatico_comida" [(ngModel)]="viaticoComida.monto" class="form-control" required>
                                            <small [hidden]="!inputError.monto" [innerHtml]="inputError.monto" class="text-danger"></small>
                                        </div>                            
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <div class="form-group">
                                    <button class="btn btn-primary" type="submit" [disabled]="submitted">Guardar</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="col-md-4">
        <div class="row">
            <div class="card">
                <div class="card-content">
                    <h4 class="card-title">Viatico Otros</h4>
                    <form class="form-horizontal" (submit)="guardarOtros($event)" #datosOtrosForm="ngForm">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-12">
                                        <label class="col-md-3 label-on-left">Monto</label>
                                        <div class="col-md-9">                                  
                                            <input type="number" name="viatico_otros" [(ngModel)]="viaticoOtros.monto" class="form-control" required>
                                            <small [hidden]="!inputError.monto" [innerHtml]="inputError.monto" class="text-danger"></small>
                                        </div>                            
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <button class="btn btn-primary pull-right" type="submit" [disabled]="submitted">Guardar</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div> -->
</div>