<div class="container-fluid">
    <div *ngIf="proveedor" class="row">
        <proveedores_form [proveedor]="proveedor"></proveedores_form>
    </div>
    
    <div *ngIf="cuenta" class="row">
        <cuentas_form [cuenta]="cuenta" [proveedor]="proveedor" (cancel)="cancelForm($event)"></cuentas_form>
    </div>
    
    <div class="row" *ngIf="proveedor">
        <div class="card">
            <div class="card-content">
                <h4 class="card-title">Cuentas</h4>
                <hr>

                <div class="row mt-2">
                    <div class="col-sm-12">
                        <button class="btn btn-simple btn-icon btn-add" *ngIf="! cuenta" (click)="newCuenta()" title="Agregar"><i class="cmp-i agregar"></i> Agregar</button>
                    </div>  
                </div>

                <div class="row">
                    <div class="col-md-12 table-responsive">
                        <table class="table table-hover">
                            <thead class="text-primary">
                                <tr>
                                    <th><b>Banco</b></th>
                                    <th><b>Número de Cuenta</b></th>
                                    <th><b>CBU</b></th>
                                    <th><b>Tipo de Cuenta</b></th>
                                    <th><b>Acciones</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let cuenta of cuentas'>
                                    <td>
                                        <span *ngIf="cuenta.tipo == 'BANCO' && cuenta.banco" class="ml-1">
                                            <span *ngIf="!BANK_LOGO_IDS.includes(cuenta.banco.id)" >({{cuenta.banco.nombre}})</span>
                                            <span [class]="'bank-logo bank-logo-' + cuenta.banco.id" *ngIf="BANK_LOGO_IDS.includes(cuenta.banco.id)" [title]="cuenta.banco.nombre"></span>
                                        </span>
                                        <b *ngIf="cuenta.tipo != 'BANCO' || !cuenta.banco">-</b>
                                    </td>
                                    <td>
                                        <a [routerLink]="['/cuentas', cuenta.id]" rel="tooltip" title="Editar Cuenta">{{cuenta.nombre}}</a>
                                    </td>
                                    <td>
                                        <span *ngIf="cuenta.tipo == 'BANCO' && cuenta.cbu">{{cuenta.cbu}}</span>
                                        <span *ngIf="cuenta.tipo != 'BANCO' || cuenta.cbu == null">-</span>
                                    <td>
                                        {{cuenta.tipo | translate: 'TIPO_CUENTA'}}
                                        <span *ngIf="cuenta.tipo == 'BANCO' && cuenta.tipo_cuenta"> ({{cuenta.tipo_cuenta}})</span>
                                    </td>
                                    <td class="td-actions text-right">
                                        <a [routerLink]="['/cuentas', cuenta.id]" rel="tooltip" title="Editar Cuenta" class="btn btn-primary btn-simple btn-xs btn-cmp-i">
                                            <i class="cmp-i editar i-sm"></i>
                                        </a>
                                        <button *ngIf="cuenta.habilitado" type="button" rel="tooltip" title="Deshabilitar Cuenta" class="btn btn-danger btn-simple btn-xs" (click)="changeState(cuenta, false, $event)">
                                            <i class="ti-close"></i>
                                        </button>
                                        <button *ngIf="!cuenta.habilitado" type="button" rel="tooltip" title="Habilitar Cuenta" class="btn btn-danger btn-simple btn-xs" (click)="changeState(cuenta, true, $event)">
                                            <i class="ti-check"></i>
                                        </button>
                                        <button *ngIf="cuenta.monto == 0" type="button" rel="tooltip" title="Eliminar Cuenta" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="cuenta" confirm-message="¿Está seguro que desea eliminar la Cuenta?">
                                            <i class="cmp-i eliminar-borrar i-sm"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>             
            </div>
        </div>  
    </div>
    
    <imputaciones_proveedor *ngIf="proveedor" [proveedor]="proveedor"></imputaciones_proveedor>

    <imputaciones_contable_proveedor *ngIf="proveedor" [proveedor]="proveedor"></imputaciones_contable_proveedor>
    
    <facturas_proveedor *ngIf="proveedor && !id" [proveedor]="proveedor"></facturas_proveedor>

    <viaticos_proveedor *ngIf="proveedor && !id" [proveedor]="proveedor"></viaticos_proveedor>
    
    <ordenes_proveedor *ngIf="proveedor && !id" [proveedor]="proveedor"></ordenes_proveedor>
    
    <notas_proveedor *ngIf="proveedor && !id" [proveedor]="proveedor"></notas_proveedor>
</div>