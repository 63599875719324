<div class="container-fluid">    
    <div class="row">
        <div class="card">
            <div class="card-content">
                <breadcrumb [items]="breadcrumbItems"></breadcrumb>

                <filters-header-section (onFilter)="filter(1)" [advancedFilters]="true" [someFilterApllied]="someFiltersApplied" (onClear)="resetFilters()">
                    <div class="col-sm-12">
                        <div class="col-sm-12 col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4">
                            <label for="distrito_id">Distrito:</label>
                            <select class="form-control" data-style="btn btn-wd btn-block" name="distrito_id"  [(ngModel)]="filtro.distrito_id_eq" (change)="filter(1)" [disabled]="! actualUser.multi_distrito">
                                <option [ngValue]="null">Todos</option>
                                <option *ngFor="let distrito of distritos" [ngValue]="distrito.id">{{distrito.nombre}}</option>
                            </select>
                        </div>
                    </div>
                
                    <div moreFilters>
                        <div class="col-md-12">
                            <generic_period (outDate)="changeFilterDate($event)" [fromDate]="filtro.desde_gteq" [toDate]="filtro.desde_lteq" [enableCleanOption]="true"></generic_period>
                        </div>        
                    </div>	
                </filters-header-section> 
                
                <div class="row">
                    <form class="form-inline" (submit)="save($event)" #calcularForm="ngForm">
                        <div class="filter-section">
                            <div class="col-md-12 filter-header">
                                <div class="panel panel-border panel-default">
                                    <a data-toggle="collapse" href="#collapseOneForm" class="collapsed" aria-expanded="false">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">
                                                Calcular deudas
                                                <i class="ti-angle-down"></i>
                                            </h4>
                                        </div>
                                    </a>
                                    <div id="collapseOneForm" class="panel-collapse collapse" aria-expanded="false" style="height: 0px;">
                                        <div class="panel-body">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="col-sm-12 col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4">
                                                        <div class="row">
                                                            <label for="hasta">Fecha Hasta</label>
                                                            <div>             
                                                                <input-datepicker [(dateModel)]="hasta" [isStringFormat]="true" [required]="true"></input-datepicker>
                                                                <small [hidden]="!inputError.hasta" [innerHtml]="inputError.hasta" class="text-danger"></small>
                                                            </div>                            
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row text-center mt-1">
                                                    <button class="btn btn-primary" type="submit" [disabled]="submitted">Calcular</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>   
                    </form>
                </div>


                <div class="row mt-2">
                    <div class="col-sm-10"></div>  
                    <div class="col-sm-2">
                        <sort-select [options]="sortOptions" [(sortModel)]="filtro.sort" (onSelect)="filter(1)"></sort-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 table-responsive">
                        <table class="table table-hover">
                            <thead class="text-primary">
                                <tr>
                                    <th><b>Fecha Desde</b></th>
                                    <th><b>Fecha Hasta</b></th>
                                    <th><b>Monto</b></th>
                                    <th><b>Factura</b></th>
                                    <th><b>Proveedor</b></th>
                                    <th><b>Acciones</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let deuda of deudas'>
                                    <td>{{deuda.desde | date: 'dd/MM/yyyy'}}</td>
                                    <td>{{deuda.hasta | date: 'dd/MM/yyyy'}}</td>
                                    <td>$ {{deuda.monto | number: '1.2-2'}}</td>
                                    <td>
                                        <span *ngIf="deuda.factura">{{deuda.factura.punto_venta}} - {{deuda.factura.numero}}</span>
                                        <span *ngIf="!deuda.factura">-</span>
                                    </td>
                                    <td>{{deuda.proveedor.cuit | cuitformat}}</td>
                                    <td class="td-actions text-right">
                                        <button type="button" rel="tooltip" title="Eliminar Deuda" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="deuda" confirm-message="¿Está seguro que desea eliminar la Deuda?">
                                            <i class="cmp-i eliminar-borrar i-sm"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="deudas != null && deudas.length == 0" class="text-center">
                                    <td colspan="7">No se encontraron deudas</td>
                                </tr>
                            </tbody>
                        </table>                        
                        <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)" *ngIf="deudas != null && deudas.length > 0"></pager>
                    </div>
                </div>
            </div>
        </div>          
    </div>
</div>