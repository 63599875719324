
<div class="container-fluid">
    <!-- end row -->
    <div class="card">
        <div class="card-content">
            <div class="row">
                <div class="col-md-3">
                <h3>Dallachiesa SRL</h3>
                </div>
            </div>
            <h4 class="card-title">Filtros</h4>
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Desde</label>
                        <input type="text" placeholder="" class="form-control datepicker">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Hasta</label>
                        <input type="text" placeholder="" class="form-control datepicker">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Moneda</label>
                        <select class="form-control" data-style="btn btn-wd btn-block" title="$(Peso Argentino)" data-size="7">
                            <option value="id">Lorem ipsum</option>
                            <option value="ms">Lorem ipsum</option>
                            <option value="ca">Lorem ipsum</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <br>
                        <button class="btn btn-wd">Consultar</button>
                    </div>
                </div>
            </div>

            <br/><br/>
        </div>
    </div> <!-- end card -->


    <div class="card">
        <div class="card-content">
            <div class="row">
                <div class="col-md-12">
                    <h4 class="card-title">Historial</h4>
                    <div class="toolbar">
                    </div>
                    <div class="fresh-table">
                        <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%" style="width:100%">
                            <thead>
                                <tr>
                                    <th>{{ dataTable.headerRow[0] }}</th>
                                    <th>{{ dataTable.headerRow[1] }}</th>
                                    <th>{{ dataTable.headerRow[2] }}</th>
                                    <th>{{ dataTable.headerRow[3] }}</th>
                                    <th>{{ dataTable.headerRow[4] }}</th>
                                    <th>{{ dataTable.headerRow[5] }}</th>
                                    <th>{{ dataTable.headerRow[6] }}</th>
                                    <th>{{ dataTable.headerRow[7] }}</th>
                                    <th>{{ dataTable.headerRow[8] }}</th>
                                    <th>{{ dataTable.headerRow[9] }}</th>
                                    <th class="disabled-sorting">{{ dataTable.headerRow[10] }}</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>{{ dataTable.footerRow[0] }}</th>
                                    <th>{{ dataTable.footerRow[1] }}</th>
                                    <th>{{ dataTable.footerRow[2] }}</th>
                                    <th>{{ dataTable.footerRow[3] }}</th>
                                    <th>{{ dataTable.footerRow[4] }}</th>
                                    <th>{{ dataTable.footerRow[5] }}</th>
                                    <th>{{ dataTable.footerRow[6] }}</th>
                                    <th>{{ dataTable.footerRow[7] }}</th>
                                    <th>{{ dataTable.footerRow[8] }}</th>
                                    <th>{{ dataTable.footerRow[9] }}</th>
                                    <th>{{ dataTable.footerRow[10] }}</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr *ngFor="let row of dataTable.dataRows">
                                    <td>{{row[0]}}</td>
                                    <td>{{row[1]}}</td>
                                    <td>{{row[2]}}</td>
                                    <td>{{row[3]}}</td>
                                    <td>{{row[4]}}</td>
                                    <td>{{row[5]}}</td>
                                    <td>{{row[6]}}</td>
                                    <td>{{row[7]}}</td>
                                    <td>{{row[8]}}</td>
                                    <td>{{row[9]}}</td>
                                    <td>
                                        <button class="btn btn-simple btn-info btn-icon like"><i class="ti-heart"></i></button>
                                        <button class="btn btn-simple btn-warning btn-icon edit"><i class="cmp-i editar i-sm"></i></button>
                                        <button class="btn btn-simple btn-danger btn-icon remove"><i class="ti-close"></i></button>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-3 col-md-offset-9">
                        <h5><strong>Saldo Inicial</strong> 2000 / <strong>A la Fecha</strong> 03/07/2016</h5>
                        <h5><strong>Saldo Final</strong> 5000 / <strong>A la Fecha</strong> 03/07/2016</h5>
                    </div>
                </div>
            </div>
        </div>
        <!-- end content-->
    </div>
    <!--  end card  -->

    <div class="card">
        <div class="card-header">
        </div>
        <div class="card-content">
            <h4>Cheques</h4>
            <div class="row">
                <div class="col-md-6">
                    <div class="panel-group" id="accordion">
                        <div class="panel panel-border panel-default">
                            <a href="#collapseOne" aria-expanded="true">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        Cheques
                                    </h4>
                                </div>
                            </a>
                            <div id="collapseOne" class="panel-collapse">
                                <div class="panel-body">
                                    <h5><strong>Total Cheques</strong> 0.00</h5>
                                    <h5><strong>Cheques no acreditados</strong> 0.00</h5>
                                    <h5><strong>Cheques acreditados</strong> 0.00</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-border panel-default">
                        <a href="#collapseThree">
                            <div class="panel-heading">
                                <div class="row">
                                    <div class="col-md-6">
                                        <h4 class="panel-title">
                                            Moneda
                                        </h4>
                                    </div>
                                    <div class="col-md-6">
                                        <h4 class="panel-title">
                                            T.Cambio
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <div id="collapseThree" class="panel-collapse" aria-expanded="true">
                            <div class="panel-body">
                                <div class="row">
                                    <div class="col-md-6">
                                        <h5><strong>Pesos</strong> 2000 <a href="#">Ver Cambio</a></h5>
                                    </div>
                                    <div class="col-md-6">
                                        <h5><strong>Valor</strong> 0</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="panel panel-border panel-default">
                        <a href="#collapseTwo">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    General
                                </h4>
                            </div>
                        </a>
                        <div id="collapseTwo" class="panel-collapse" aria-expanded="true">
                            <div class="panel-body">
                                <div class="row">
                                    <div class="col-md-6">
                                        <h5><strong>Saldo inicial</strong> 0.00</h5>
                                    </div>
                                    <div class="col-md-6">
                                        <h5><strong>Limite de credito</strong> 0.00</h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <h5><strong>Saldo Remito</strong> 0.00</h5>
                                    </div>
                                    <div class="col-md-6">
                                        <h5><strong>Credito disponible</strong> 0.00</h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <h5><strong>Saldo efectivo</strong> 0.00</h5>
                                    </div>
                                    <div class="col-md-6">
                                        <h5><strong>Porcentaje usado</strong> 0.00</h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                    </div>
                                    <div class="col-md-6">
                                        <h5><strong>Saldo total</strong> 0.00</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>