import {Distrito} from '../module-distrito/models';
import {Proveedor} from '../module-proveedor/models';

export class User{
    public id: number;
    public username: string;
    public password: string;
    public email: string;
    public nombre: string;
    public habilitado: boolean = true;
    public cobra_viatico: boolean = false;
    public multi_distrito: boolean = false;
    public distrito_id: number;
    public distrito: Distrito = null;
    public roles: Rol[] = [];
    
    public oldPassword: string;
    public confPassword: string;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.username = json.username;
            this.email = json.email;
            this.nombre = json.nombre;
            this.habilitado = json.habilitado;
            this.cobra_viatico = json.cobra_viatico;
            this.multi_distrito = json.multi_distrito;
            this.distrito_id = json.distrito_id;
            if (json.distrito) {
                this.distrito = new Distrito(json.distrito);
            }
            if (json.roles) {
                for (let rol of json.roles) {
                    this.roles.push(new Rol(rol));
                }
            }
        }
    }
        
    public actualize(user: User){
        this.id = user.id;
        this.username = user.username;
        this.email = user.email;
        this.nombre = user.nombre;
        this.habilitado = user.habilitado;
        this.cobra_viatico = user.cobra_viatico;
        this.multi_distrito = user.multi_distrito;
        this.distrito_id = user.distrito_id;
        if (user.distrito) {
            this.distrito = user.distrito;
        }
    }
    
    //PROFILES
    public esTesorero() {
        return this.hasRolByName('tesorero');
    }
    
    public removeRol(rol: Rol){
        for(let i= 0; i < this.roles.length; i++){
            if(rol.id == this.roles[i].id){
                this.roles.splice(i, 1);
            }            
        }
    }
    public addRol(rol: Rol){
        if(!this.hasRol(rol)){
            this.roles.push(rol);
        }
    }    
    public hasRol(hasRol: Rol){
        for(let rol of this.roles) {
            if(hasRol.id == rol.id){
                return true;
            }
        }
        return false;
    }  
    public hasRolByName(rolName){
        for(let rol of this.roles) {
            if(rol.name == rolName){
                return true;
            }
        }
        return false;
    }
    public hasAtLeastOneRolByName(roles: string[]){
        for(let rolName of roles) {
            if (rolName == 'all') {
                return true;
            }
            if(this.hasRolByName(rolName)){
                return true;
            }
        }
        return false;
    }
    
    public hasRoles(){
        return this.roles.length > 0;
    }
    
    public printRoles() {
        return this.roles.map(r => r.name.replace('_', ' ')).join(', ');
    }
}

export class Rol{
    public id: number;
    public name: string;
    public description: string
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.name = json.name;
            this.description = json.description;
        }
    }
}

export class PermisoViatico{
    public id: number;
    public user: User = null;
    public proveedor: Proveedor = null;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            if (json.user) {
                this.user = new User(json.user);
            }
            if (json.proveedor) {
                this.proveedor = new Proveedor(json.proveedor);
            }
        }
    }
}

export class Ciudad{
    public id: number;
    public nombre: string;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.nombre = json.nombre;
        }
    }
}