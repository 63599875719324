<div *ngIf="recibo" class="row">
    <recibos_orden_form [recibo]="recibo" [orden]="orden" [proveedor]="proveedor" (actualize)="actualizeForm($event)" (cancel)="cancelForm($event)"></recibos_orden_form>
</div>

<div class="row">
    <div class="card">
        <div class="card-content">
            <h4 class="card-title">Recibos</h4>
            <hr>

            <div class="row mt-1">
                <div class="col-sm-12">
                    <button class="btn btn-simple btn-icon btn-add" (click)="newRecibo()" title="Agregar"><i class="cmp-i agregar"></i> Agregar</button>
                </div>  
            </div>
            
            <div class="row">
                <div class="col-md-12 table-responsive">
                    <table class="table table-hover">
                        <thead class="text-primary">
                            <tr>
                                <th><b>Descripción</b></th>
                                <th><b>Método de Pago</b></th>
                                <th><b>Monto</b></th>
                                <th><b>Fecha Recibo</b></th>
                                <th><b>Fecha Vto Pago</b></th>
                                <th><b>Fecha Serv Desde</b></th>
                                <th><b>Fecha Serv Hasta</b></th>
                                <th><b>Acciones</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor='let recibo of recibos'>
                                <td>
                                    <span *ngIf="!recibo.descripcion">-</span>
                                    <span *ngIf="recibo.descripcion">{{recibo.descripcion}}</span>
                                    <span *ngIf="recibo.anulado"> (ANULADO)</span>                           
                                </td>
                                <td>{{recibo.tipo}}</td>
                                <td>$ {{recibo.monto | number: '1.2-2'}}</td>
                                <td>
                                	<span >{{recibo.fecha | date: 'dd/MM/yyyy'}}</span>
                                </td>
                                <td>
                                    <span >{{recibo.fecha_vto_pago | date: 'dd/MM/yyyy'}}</span>
                                </td>
                                <td>
                                    <span >{{recibo.fecha_serv_desde | date: 'dd/MM/yyyy'}}</span>
                                </td>
                                <td>
                                    <span >{{recibo.fecha_serv_hasta | date: 'dd/MM/yyyy'}}</span>
                                </td>
                                <td class="td-actions text-right">
                                    <button type="button" rel="tooltip" *ngIf="recibo.estado == 'C'" title="Imprimir recibo en Afip" class="btn btn-danger btn-simple btn-xs" (confirm-click)="onPrintAfip($event)" [confirm-params]="recibo" confirm-message="¿Está seguro que desea imprimir el recibo?">
                                        <i class="ti-printer"></i>
                                    </button>
                                </td>
                                <td class="td-actions text-right">
                                    <button type="button" rel="tooltip" *ngIf="recibo.estado == 'I'" title="Confirmar recibo en Afip" class="btn btn-danger btn-simple btn-xs" (confirm-click)="onConfirmAfip($event)" [confirm-params]="recibo" confirm-message="¿Está seguro que desea confirmar el recibo en Afip?">
                                        <i class="ti-check"></i>
                                    </button>
                                </td>
                                <td class="td-actions text-right">
                                    <button type="button" rel="tooltip" *ngIf="recibo.estado == 'I'" title="Eliminar recibo" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="recibo" confirm-message="¿Está seguro que desea eliminar el recibo?">
                                        <i class="cmp-i eliminar-borrar i-sm"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr *ngIf="recibos != null && recibos.length == 0" class="text-center">
                                <td colspan="9">No hay pagos / recibos</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>