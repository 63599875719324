import { Injectable, OnInit }    from '@angular/core';
import { HttpClient } from '@angular/common/http';

//import 'rxjs/add/operator/toPromise';
import { Observable ,  Subject } from 'rxjs';
// Import RxJs required methods
import { map, catchError } from 'rxjs/operators';

import {environment} from '../../environments/environment';

import { GlobalVars } from '../services/globalVars';
import { Cache } from '../services/cache';

import { User } from '../models/user';

@Injectable()
export class UserService{
    private loginUrl = 'auth/login';  // URL to web api
    private preferencesUrl = 'auth/me';
    private userUrl = 'user';

    public redirectLogin: string = null;

    // Observable string sources
    private loginUserSource = new Subject<any>();
    private logoutUserSource = new Subject<any>();
    // Observable string streams
    loginUser$ = this.loginUserSource.asObservable();
    logoutUser$ = this.logoutUserSource.asObservable();

    constructor(private http: HttpClient, private globalVars: GlobalVars, private cache: Cache) {
        this.globalVars.logoutUser$.subscribe(
            rta => {
                this.logout();
            }
        );        
    }

    //LOGIN - PREFERENCES
    login(email, password): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.loginUrl}`;
        let body= {login: email, password: password};
        return this.http.post<any>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((res: any) => {
                this.globalVars.setAuthorization(res.access_token);
                //this.globalVars.setActualUser(new User(res.user));
                //Levanto para observadores
                //this.loginUserSource.next(this.globalVars.actualUser);
                //Aviso a los otros tabs
                res.redirect = this.redirectLogin;
                this.redirectLogin = null;
                return res;
            })
        );
    }
    
    logout() {
        this.internalLogout();
    }
    private internalLogout(){
        this.globalVars.deleteSessionData();
        this.logoutUserSource.next();
    }        
    
    //
    //PREFERENCIAS
    actualUser(): Observable<User> {
        return new Observable<User>(observer => {
            if(this.globalVars.actualUser != null){
                observer.next(this.globalVars.actualUser);
            }else{            
                if(this.globalVars.getAuthorization() != null){
                    return this.preferencias().subscribe(
                    user => {
                        this.globalVars.setActualUser(user);
                        //Levanto para observadores
                        this.loginUserSource.next(this.globalVars.actualUser);
                        observer.next(user);
                    },err => {
                        this.globalVars.deleteSessionData();
                        observer.error(null);
                    });
                }else{
                    observer.error(null);
                }                
            }        
        });
    }
    preferencias(): Observable<User> {
        const url= `${this.globalVars.apiHost}${this.preferencesUrl}`;
        return this.http.get<User>(url, this.globalVars.getOptionsRequest()).pipe(
            map((res) => new User(res)),
            catchError((error:any) => this.globalVars.tratarErrores(error)),);
    }
    
    updatePreferences(user: User): Observable<User> {
        const url = `${this.globalVars.apiHost}${this.preferencesUrl}`;
        let body= JSON.parse(JSON.stringify(user));
        delete body.profiles;
        if(body.password == ''){
            delete body.password;
        } else {
            body.password_confirmation = user.confPassword;
        }
        //body.person = JSON.parse(JSON.stringify(person));
        return this.http.put<User>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map(res => {
                res = new User(res);
                this.globalVars.actualUser.actualize(res);
                return res;
            })
        );
    }
        
}