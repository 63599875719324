import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalVars } from 'app/services/globalVars';
import { SearchBarService } from '../search-bar/search-bar.service';
import { SearchResults } from '../search-bar/search-bar.model';
import { GenericFormComponent } from 'app/module-generic/components/generic-form.component';
import { MessageService } from 'app/services/message.service';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';

@Component({
  selector: 'search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent extends GenericFormComponent implements OnInit {

  public params = {distrito_id: null, page: 1, per_page: 10};
  public filters = {search_text: null};
  public type: string = null;

  public results: SearchResults = null;
  public initBreadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }];
  public breadcrumbItems: BreadcrumbItems[] = Object.assign([], this.initBreadcrumbItems);

  constructor(protected messageService: MessageService, private globalVars: GlobalVars, private route: ActivatedRoute, private router: Router,
    private searchBarService: SearchBarService) {
      super(messageService);
    }

  ngOnInit(): void {
    if(!this.route.snapshot.queryParams['type'] || this.route.snapshot.queryParams['type']?.trim() == '' || !this.route.snapshot.queryParams['search_text'] || this.route.snapshot.queryParams['search_text']?.trim() == '')
      this.router.navigate(['']);

    if(!this.globalVars.actualUser.multi_distrito)
      this.params.distrito_id = this.globalVars.getActualDistrito();

    this.route.queryParams.subscribe((params) => {
      this.setParams();
      this.filter();
    })
  }

  public setParams() {
    this.filters.search_text = this.route.snapshot.queryParams['search_text'];
    this.type = this.route.snapshot.queryParams['type'];

    this.breadcrumbItems = Object.assign([], this.initBreadcrumbItems);
    switch (this.type) {
      case 'proveedores':
        this.breadcrumbItems.push({ title: "Proveedores", ref: '#/proveedores' });
        break;
      case 'facturas':
        this.breadcrumbItems.push({ title: "Facturas", ref: '#/facturas' });
        break;
      case 'ordenes_pago':
        this.breadcrumbItems.push({ title: "Ordenes de pago", ref: '#/ordenespago' });
        break;
      default:
        this.router.navigate(['']);
    }
    
    this.breadcrumbItems.push({ title: `Resultados para "${this.filters.search_text}"`, active: true });
  }

  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.searchBarService.search(this.params, this.filters).subscribe((res: SearchResults) => {
      this.results = res;
      let offset = 0;
      let limit = 0;
      let estimatedTotalHits = 0;

      switch (this.type) {
        case 'proveedores':
          offset = res.proveedores.offset;
          limit = res.proveedores.limit;
          estimatedTotalHits = res.proveedores.estimatedTotalHits;
          break;
        case 'facturas':
          offset = res.facturas.offset;
          limit = res.facturas.limit;
          estimatedTotalHits = res.facturas.estimatedTotalHits;
          break;
        case 'ordenes_pago':
          offset = res.ordenes_pago.offset;
          limit = res.ordenes_pago.limit;
          estimatedTotalHits = res.ordenes_pago.estimatedTotalHits;
          break;
        default:
          this.router.navigate(['']);
      }

      let info: any = {
        page: Math.trunc(offset/limit) + 1,
        countOfPages: Math.ceil(estimatedTotalHits/limit),
        count: estimatedTotalHits
      }

      this.assemblePager(info);
    });
  }

}
