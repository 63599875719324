import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import {GlobalVars} from '../services/globalVars';

@Injectable()
export class Interceptor implements HttpInterceptor {

    constructor(private globalVars: GlobalVars) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = req.headers.set('Content-Type', 'application/json');
        if (this.globalVars.getAuthorization() != null) {
            headers = headers.set('Authorization', this.globalVars.getAuthorization());
        }
        const authReq = req.clone({
            headers: headers
        });
        return next.handle(authReq);
    }
}