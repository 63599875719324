<div class="card">
    <div class="card-content">
        <h4 class="card-title">Nuevo Movimiento</h4>
        <hr>
        <form class="form-horizontal" (submit)="save($event)" #datosMovForm="ngForm"> 
            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Método de Pago</label>
                        <div class="col-md-9">
                            <select class="form-control" data-style="btn btn-wd btn-block" name="metodopago" [(ngModel)]="movimiento.metodopago" (change)="changeMetodo()">
                                <option [ngValue]="null">Seleccione un Método de Pago</option>
                                <option *ngFor="let metodo of metodos" [ngValue]="metodo">{{metodo.nombre}}</option>
                            </select>
                            <small [hidden]="!inputError.metodo_pago_id" [innerHtml]="inputError.metodo_pago_id" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Monto</label>
                        <div class="col-md-9">                                  
                            <input type="number" name="monto" [(ngModel)]="movimiento.monto" class="form-control" placeholder="1000" required>
                            <small [hidden]="!inputError.monto" [innerHtml]="inputError.monto" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>
            
            <div class="form-group">                
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Cuenta Destino</label>
                        <div class="col-md-9">
                            <select class="form-control" data-style="btn btn-wd btn-block" name="cuentadestino" [(ngModel)]="movimiento.cuentadestino" (change)="validarDestino()" [disabled]="!movimiento.metodopago">
                                <option [ngValue]="null">Seleccione un Destino</option>
                                <option *ngFor="let cuenta of cuentasFiltro" [ngValue]="cuenta">{{cuenta.nombre}} <span *ngIf="cuenta.tipo == 'BANCO' && cuenta.banco">({{cuenta.banco.nombre}})</span></option>
                            </select>
                            <small [hidden]="!inputError.cuenta_destino_id" [innerHtml]="inputError.cuenta_destino_id" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Moneda</label>
                        <div class="col-md-9">
                            <select class="form-control" data-style="btn btn-wd btn-block" name="moneda" [(ngModel)]="movimiento.moneda">
                                <option [ngValue]="null">Seleccione una Moneda</option>
                                <option *ngFor="let moneda of monedas" [ngValue]="moneda">{{moneda | translate: 'MONEDA'}}</option>
                            </select>
                            <small [hidden]="!inputError.moneda" [innerHtml]="inputError.moneda" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Observaciones</label>
                        <div class="col-md-9">                                  
                            <input #firstElementMovimiento type="text" name="descripcion" [(ngModel)]="movimiento.descripcion" class="form-control" placeholder="Obervaciones del movimiento" required>
                            <small [hidden]="!inputError.descripcion" [innerHtml]="inputError.descripcion" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
            </div>
            
            <ng-container *ngIf="movimiento.metodopago && movimiento.metodopago.esCheque() && movimiento.cuenta.tipo == 'BANCO'">
                <h4>Cheque</h4>

                <div class="form-group">
                    <div class="col-md-6">
                        <div class="input-group">
                            <input class="form-control" type="text" placeholder="Búsqueda de cheque" name="search" [(ngModel)]="numeroCheque" (keyup.enter)="buscarCheque()" (blur)="enabledSubmit()" (focus)="disabledSubmit()">
                            <span class="input-group-addon" (click)="buscarCheque()"><i class="cmp-i busqueda i-sm"></i></span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <button class="btn btn-primary" type="button" (click)="newCheque()">Crear Cheque</button>
                    </div>
                </div>
                
                <div *ngIf="cheque" class="row">
                    <cheques_form [cheque]="cheque" [cuenta]="movimiento.cuenta" [withBackground]="true" (actualize)="actualizeFormCheque($event)" (cancel)="cancelFormCheque($event)"></cheques_form>
                </div>

                <div class="form-group" *ngIf="movimiento.cheque">
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Número de Cheque</label>
                            <div class="col-md-9">                                  
                                <input type="number" name="numero_cheque" [(ngModel)]="movimiento.cheque.numero" class="form-control" placeholder="Número de cheque" disabled="disabled">
                                <small [hidden]="!inputError.numero" [innerHtml]="inputError.numero" class="text-danger"></small>
                            </div>                            
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Monto</label>
                            <div class="col-md-9">                                  
                                <input type="number" name="monto_cheque" [(ngModel)]="movimiento.cheque.monto" class="form-control" placeholder="1000" disabled="disabled">
                                <small [hidden]="!inputError.monto" [innerHtml]="inputError.monto" class="text-danger"></small>
                            </div>                            
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="row text-center">
                <button class="btn btn-default btn-cancel" type="button" (confirm-click)="cancelForm($event)" confirm-message="¿Está seguro que desea cancelar este formulario?">Cancelar</button>
                <button class="btn btn-primary ml-1" type="submit" [disabled]="submitted">Guardar</button>
            </div>
            <div class="clearfix"></div>
        </form>
    </div>
</div>