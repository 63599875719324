import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule }   from '@angular/forms';

import {GenericModule} from '../module-generic/generic.module';
import {TranslateComponentModule} from '../translate-module/translate-component.module';

import {ReciboService} from './recibo.service';

import {ReciboComponent} from './recibo/recibo.component';
//import {ReciboFormComponent} from './recibo_form/recibo_form.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    
    GenericModule,
    TranslateComponentModule,
  ],
  declarations: [
    ReciboComponent,
    //ReciboFormComponent,
  ],
  providers: [
    ReciboService,
  ],
  exports: [
    ReciboComponent,
  //  ReciboFormComponent,
  ]
})
export class ReciboModule { }
