import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

import {UserService} from '../user.service';
import {ProveedorCommunicate} from '../../module-proveedor/proveedor.communicate';
import { MessageService } from '../../services/message.service';

import {PermisoViatico} from '../../models/user';
import {Proveedor} from '../../module-proveedor/models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

@Component({
  selector: 'permisos_form',
  templateUrl: 'permisos_form.html'
})
export class PermisosFormComponent extends GenericFormComponent {    
    @Input() permiso: PermisoViatico = new PermisoViatico();
    
    @Output() actualize: EventEmitter<PermisoViatico> = new EventEmitter<PermisoViatico>();
    @Output() cancel: EventEmitter<string> = new EventEmitter<string>();
    
    constructor(protected messageService: MessageService, private userService: UserService) {
        super(messageService);
    }
    
    public save(event){
        this.beforeSubmit(event);
        this.userService.createPermisosViatico(this.permiso).subscribe(
            (permiso: PermisoViatico) => {
                this.showSuccess(true, 'Permiso creado correctamente');
                this.permiso = permiso;
                this.actualize.emit(this.permiso);             
            },err => {
                this.showError(err, true);
            });
    }
    
    public cancelForm($event?){
        this.cancel.emit("Cancelacion");
    }
}