
import {first} from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';

import {environment} from '../../../environments/environment';

import {FacturaService} from '../factura.service';
import {DistritoService} from '../../module-distrito/distrito.service';
import {ProveedorService} from '../../module-proveedor/proveedor.service';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Factura, ImputacionContable} from '../models';
import {User} from '../../models/user';
import {Distrito} from '../../module-distrito/models';
import {Proveedor} from '../../module-proveedor/models';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
import { FilterCacheUtilsService } from 'app/services/filter-cache-utils.service';
import { FilterCache } from 'app/models/filter-cache';
 
declare var $: any;
const VIEW_ID = "ingresosventas";

@Component({
  selector: 'facturas',
  templateUrl: './facturas.component.html'
})
export class FacturasVentasComponent extends GenericFormComponent implements OnInit {
  public actualUser: User;
    
  public facturas: Factura[];
  public params = {page: 1, per_page: 10, proveedor_search: null};
  public filtroDefault = {proveedor_id_eq: null, or_numero_matches: null, or_punto_venta_matches: null,
    tipo_eq: null, estado_eq: null, fecha_gteq: null, fecha_lteq: null, imputacion_contable_id_eq: null, 
    distrito_id_eq: null, punto_venta_eq: null, numero_eq: null, sort: 'fecha,desc'};
  public filtro = Object.assign({},this.filtroDefault);
  public someFiltersApplied: boolean = false;
  
  public tipos: string[] = environment.tiposFactura;
  public estados: string[] = environment.estadosFactura;
  public cant_listado: number[] = environment.cant_listado;
  public imputacionesContables: ImputacionContable[] = [];
  public distritos: Distrito[] = [];
  public proveedores: Proveedor[] = [];
  
  @ViewChild('fac_select_imputaciones', { static: true }) selectImputaciones: ElementRef;
  // @ViewChild('fac_select_proveedores') selectProveedores: ElementRef;

  public sortOptions: { [id: string] : string } = { 'fecha,desc': 'Fecha' };
  public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Ingresos', active: true }];
    
  constructor(protected messageService: MessageService, private globalVars: GlobalVars, private facturaService: FacturaService, private distritoService: DistritoService,
      private ngZone: NgZone, private proveedorService: ProveedorService, private filterCacheUtilsService: FilterCacheUtilsService) {
    super(messageService);
  }

  ngOnInit() {
    this.actualUser = this.globalVars.actualUser;
    this.loadInitFilters();
    this.loadCacheFiltersParams();
    this.filter();
    this.facturaService.getImputacionesVentas({page: 1, per_page: 1000}, {sort: 'nombre'}).subscribe(
        rta => {
            this.imputacionesContables = rta.data;
            this.ngZone.onStable.pipe(first()).subscribe(() => {
                $(this.selectImputaciones.nativeElement).selectpicker(); 
            });
        }
    );
    this.distritoService.getDistritos({per_page: 1000}, {sort: 'nombre'}).subscribe(
        (rta: any) => {
            this.distritos = rta.data;
        }
    );
    // this.proveedorService.getProveedores({page: 1}, {sort: 'nombre'}).subscribe(
    //     rta => {
    //         this.proveedores = rta.data;
    //         this.ngZone.onStable.first().subscribe(() => {
    //             $(this.selectProveedores.nativeElement).selectpicker(); 
    //         });
    //     }
    // );
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.cursorWait();    
    this.filtro.or_punto_venta_matches = this.filtro.or_numero_matches;
    this.params.proveedor_search = this.filtro.or_numero_matches;
    this.filtro.numero_eq = this.filtro.numero_eq == '' ? null : this.filtro.numero_eq;
    this.filtro.punto_venta_eq = this.filtro.punto_venta_eq == '' ? null : this.filtro.punto_venta_eq;
    this.someFiltersApplied = this.globalVars.isSomeFilterApplied(this.filtro);
    this.facturaService.getFacturas(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.facturas = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
            this.filterCacheUtilsService.setFilterParams(VIEW_ID, this.filtro, this.params);
        },
        error => {
            this.cursorDefault();
        }
    );
  }
  
  public changeFilterDate(value){
    this.filtro.fecha_gteq = value.from;
    this.filtro.fecha_lteq = value.to;
    this.filter(1);
  }
  
  //ACCIONES
  changeState(factura: Factura, estado: string, event){
    this.beforeSubmitList(event);
    this.facturaService.cambiarEstadoFactura(factura, estado).subscribe(
        rta => {
            this.showSuccessList(true, 'Ingreso modificado correctamente');
            factura.estado = estado;
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }

  onNotifyDelete(factura: Factura):void {
    this.deleteFactura(factura);
  }
    
  public deleteFactura(factura: Factura) {
    this.beforeSubmitList();
    this.facturaService.deleteFactura(factura.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Ingreso eliminado correctamente');
            //ELIMINO DE LA LISTA      
            this.facturas.forEach(function(facturaFor, index, object) {
                if(facturaFor.id == factura.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }

  private loadInitFilters(): void {
    this.filtro.distrito_id_eq = this.globalVars.getActualDistrito();
  }

  private loadCacheFiltersParams() {
    let cacheFilters: FilterCache = this.filterCacheUtilsService.getFilterParams(VIEW_ID);
    if (!cacheFilters) return;

    this.filtro = cacheFilters.filters;
    this.params = cacheFilters.params;
  }

  public resetFilters() {
    this.filtro = Object.assign({}, this.filtroDefault);
    this.loadInitFilters();
    this.filter();
  }
}