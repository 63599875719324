import { Injectable } from '@angular/core';

import { Subject }    from 'rxjs';

import {Cheque} from './models';

@Injectable()
export class CuentaCommunicate {
    // Observable string sources
    private chequeModalPopSource = new Subject<Cheque>();
    
    // Observable string streams
    public chequeModalPop$ = this.chequeModalPopSource.asObservable();
    
    constructor() { 
    }
    
    public chequeModalPopUp(cheque: Cheque){
        this.chequeModalPopSource.next(cheque);
    }
}