import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GenericFormComponent } from 'app/module-generic/components/generic-form.component';
import { NotaCreditoAfip, OrdenDeVentaGeneral, Recibo } from '../models';
import { environment } from 'environments/environment';
import { MessageService } from 'app/services/message.service';
import { OrdenService } from '../orden.service';
import { NotaCreditoAfipService } from 'app/module-nota-credito-afip/nota-credito-afip.service';

@Component({
  selector: 'nota-credito-orden-form',
  templateUrl: './nota-credito-orden-form.component.html',
  styleUrls: ['./nota-credito-orden-form.component.sass']
})
export class NotaCreditoOrdenFormComponent extends GenericFormComponent implements OnInit {
  @Input() orden: OrdenDeVentaGeneral;
  @Input() notaCreditoAfip: NotaCreditoAfip;
 
  @Output() actualize: EventEmitter<string> = new EventEmitter<string>();
  @Output() cancel: EventEmitter<string> = new EventEmitter<string>();

  public monedas: string[] = environment.monedas;
  public tipos = environment.tipoMetodos.filter(x => x != 'EFECTIVO');

  public recibos: Recibo[] = [];
  public loading: boolean = false;

  constructor(protected messageService: MessageService, private ordenService: OrdenService, private notaCreditoAfipService: NotaCreditoAfipService) {
      super(messageService);
  }

  ngOnInit(): void {
    this.ordenService.getRecibosOrden(this.orden.id).subscribe(
      (recibos: Recibo[]) => {
        this.recibos = recibos;

        if(this.recibos.length == 1)
          this.notaCreditoAfip.recibo_id = this.recibos[0].id;
      },
      err => {
        this.showError(err, true);
      }
    );
  }

  public cancelForm($event?){
      this.cancel.emit();
  }

  public onSave() {
    if(!this.validForm())
      return;

    this.loading = true;

    if(!this.notaCreditoAfip.id) {
      this.ordenService.createNotaDeCredito(this.notaCreditoAfip).subscribe(
        (notaCreditoAfip: NotaCreditoAfip) => {
          this.showSuccess(true, 'Nota de crédito creada correctamente');
          this.actualize.emit();
          this.loading = false;
        },
        err => {
          this.showErrorList(err, true);
          this.loading = false;
        }
      );
    } else {
      this.notaCreditoAfipService.updateNotaDeCredito(this.notaCreditoAfip).subscribe(
        (notaCreditoAfip: NotaCreditoAfip) => {
          this.showSuccess(true, 'Nota de crédito actualizada correctamente');
          this.actualize.emit();
          this.loading = false;
        },
        err => {
          this.showErrorList(err, true);
          this.loading = false;
        }
      );
    }
  }

  public enabledSubmit() {
      this.submitted = false;
  }

  public disabledSubmit() {
      this.submitted = true;
  }

  public onChangeTipo() {
    this.notaCreditoAfip.nro_cheque = null;
    this.notaCreditoAfip.nro_cuenta = null;
    this.validateField('tipo');
  }

  private validForm(): boolean{
    let requiredFields = ["recibo_id", "tipo", "monto", "fecha", "fecha_vto_pago", "fecha_serv_hasta", "fecha_serv_desde", "descripcion"];
    let validForm = true;

    requiredFields.forEach(field => {
      if(!this.validateField(field))
        validForm = false;
    })

    return validForm;
  }

  public validateField(field: string): boolean {
    if(field == "descripcion") {
      if(this.notaCreditoAfip[field] == null ||  this.notaCreditoAfip[field] == "") {
        this.inputError[field] = "Es necesario indicar una descripcion";
        return false;
      }
      this.inputError[field] = "";
      return true;
    }

    if(field == "tipo") {
      if(this.notaCreditoAfip.tipo == null){
        this.inputError.tipo = "Campo requerido.";
        return false;
      } else {
        let validTipoField = true;
        switch (this.notaCreditoAfip.tipo) {
          case 'CHEQUE':
            validTipoField = this.validateField('nro_cheque');
            break;
          case 'TRANSFERENCIA':
            validTipoField = this.validateField('nro_cuenta');
            break;
          default:
            validTipoField = true;

          return validTipoField;
        }
      }

      return true;
    }
  
    if(this.notaCreditoAfip[field] == null) {
      this.inputError[field] = "Campo requerido.";
      return false;
    }

    this.inputError[field] = "";
    return true;
  }

  private reload() {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
}