import { Component, OnInit, Input } from '@angular/core';

import {environment} from '../../../environments/environment';

import {ViaticoService} from '../../module-viatico/viatico.service';
import {ViaticoCommunicate} from '../../module-viatico/viatico.communicate';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Proveedor} from '../models';
import {Viatico} from '../../module-viatico/models';
 
@Component({
  selector: 'viaticos_proveedor',
  templateUrl: './viaticos.component.html'
})
export class ViaticosComponent extends GenericFormComponent implements OnInit {
  @Input() proveedor: Proveedor;
  public viaticos: Viatico[];
  public params = {page: 1, per_page: 10, proveedor_search: null};
  public filtro = {or_numero_matches: null, estado_eq: null, emision_gteq: null, emision_lteq: null, 
    distrito_id_eq: null, sort: 'emision,desc'};
    
  constructor(protected messageService: MessageService, private viaticoService: ViaticoService,
    private viaticoCommunicate: ViaticoCommunicate, private globalVars: GlobalVars) {
    super(messageService);
  }

  ngOnInit() {
    this.filtro.distrito_id_eq = this.globalVars.getActualDistrito();    
    this.filter();
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    this.cursorWait();
    this.viaticoService.getMisViaticos(this.proveedor.id, this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.viaticos = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
        },
        error => {
            this.cursorDefault();
        }
    );
  }
    
  public changeFilterDate(value){
    this.filtro.emision_gteq = value.from;
    this.filtro.emision_lteq = value.to;
    this.filter(1);
  }
  
  //ACCIONES  
  onNotifyDelete(viatico: Viatico):void {
    this.deleteViatico(viatico);
  }
    
  public deleteViatico(viatico: Viatico) {
    this.beforeSubmitList();
    this.viaticoService.deleteViatico(viatico.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Viático eliminado correctamente');
            //ELIMINO DE LA LISTA      
            this.viaticos.forEach(function(viaticoFor, index, object) {
                if(viaticoFor.id == viatico.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }

  public detalleViatico(viatico: Viatico) {
    this.viaticoCommunicate.viaticoModalPopUp(viatico.id);
  }
}