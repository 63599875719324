import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {CuentaService} from '../cuenta.service';
import {GlobalVars} from '../../services/globalVars';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Chequera} from '../models';
 
@Component({
  selector: 'chequeras_detalle',
  templateUrl: './chequeras_detalle.component.html'
})
export class ChequerasDetalleComponent extends GenericFormComponent implements OnInit {
  public chequera: Chequera;
  
  constructor(protected messageService: MessageService, private route:ActivatedRoute, private router: Router, private cuentaService: CuentaService,
      private globalVars: GlobalVars) {
    super(messageService);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
        if(params['id']){
            this.cursorWait();
            this.cuentaService.getChequera(params['id']).subscribe(
                (chequera: Chequera) => {
                    this.cursorDefault();
                    this.chequera = chequera;
                    
                    if (this.chequera.cuenta.distrito_id != this.globalVars.getActualDistrito() && ! this.globalVars.actualUser.multi_distrito) {
                        this.messageService.showNotification('top', 'center', 'danger', 'Usted no tiene acceso a esta chequera');
                        this.router.navigate(['/chequeras']);
                    } else if (this.chequera.cuenta.distrito_id != this.globalVars.getActualDistrito() && this.globalVars.actualUser.multi_distrito) {
                        this.globalVars.setActualDistrito(this.chequera.cuenta.distrito_id);
                    }
                }, error => {
                    this.cursorDefault();
                    this.messageService.showNotification('top', 'center', 'danger', 'No se encontro la chequera');
                    this.router.navigate(['chequeras']);
                }
            );
        }
    });
  }

}