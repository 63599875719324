import {Distrito} from '../module-distrito/models';
import {Proveedor} from '../module-proveedor/models';
import {OrdenDePagoGeneral} from '../module-orden/models';

export class Cuenta {
    public id: number;
    public numero_cuenta: string;
    public cbu: string;
    public alias: string;
    public titular: string;
    public cuit: string;
    public nombre: string;
    public descripcion: string;
    public tipo: string = null;
    public tipo_cuenta: string = null;
    public monto: number;
    public habilitado: boolean = true;
    
    public distrito: Distrito = null;
    public distrito_id: any = null;
    public proveedor_id: number = null;
    public proveedor: Proveedor = null;
    public banco: Banco = null;
    public banco_id: string = null;

    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.numero_cuenta = json.numero_cuenta;
            this.cbu = json.cbu;
            this.alias = json.alias;
            this.titular = json.titular;
            this.cuit = json.cuit;
            this.nombre = json.nombre;
            this.descripcion = json.descripcion;
            this.tipo = json.tipo;
            this.tipo_cuenta = json.tipo_cuenta
            this.monto = json.monto;
            
            if (json.distrito) {
                this.distrito = new Distrito(json.distrito);
            }
            this.distrito_id = json.distrito_id;
            this.proveedor_id = json.proveedor_id;
            if (json.proveedor) {
                this.proveedor = new Proveedor(json.proveedor);
            }
            if (json.banco) {
                this.banco = new Banco(json.banco);
            }
            this.banco_id = json.banco_id
        }
    }
}

export class Banco {
    public id: number;
    public nombre: string;
    
     constructor(json?) {
        if(json){
            this.id = json.id;
            this.nombre = json.nombre;
        }
     }
}

export class Chequera {
    public id: number;
    public numero: number;
    public emision: string;
    public numero_desde: number;
    public numero_hasta: number;
    public finalizada: boolean = false;
    public tipo_libro: string = 'AU';    
    
    public cuenta: Cuenta = null;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.numero = json.numero;
            this.emision = json.emision;
            this.numero_desde = json.numero_desde;
            this.numero_hasta = json.numero_hasta;
            this.finalizada = json.finalizada;
            this.tipo_libro = json.tipo_libro;
            
            if (json.cuenta) {
                this.cuenta = new Cuenta(json.cuenta);
            }
        }
    }
}

export class Cheque {
    public id: number;
    public numero: number;
    public emision: string;
    public acreditacion: string;
    public vencimiento: string;
    public lugar: string;
    public nombre: string;
    public monto: number;
    public proveedor_id: number;
    public no_a_la_orden: boolean = false;
    public diferido: boolean = false;
    public usado: boolean = false;
    public estado: string = null;
    
    public chequera: Chequera = null;
    public proveedor: Proveedor;
    public movimiento: Movimiento;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.numero = json.numero;
            this.emision = json.emision;
            this.acreditacion = json.acreditacion;
            if (this.acreditacion == '0000-00-00 00:00:00') {
                this.acreditacion = null;
            }
            this.vencimiento = json.vencimiento;
            if (this.vencimiento == '0000-00-00 00:00:00') {
                this.vencimiento = null;
            }
            this.lugar = json.lugar;
            this.nombre = json.nombre;
            this.monto = json.monto;
            this.proveedor_id = json.proveedor_id;
            this.no_a_la_orden = json.no_a_la_orden;
            this.diferido = json.diferido;
            this.usado = json.usado;
            this.estado = json.estado;
            
            if (json.chequera) {
                this.chequera = new Chequera(json.chequera);
            }
            if (json.proveedor) {
                this.proveedor = new Proveedor(json.proveedor);
            }
            if (json.movimiento) {
                this.movimiento = new Movimiento(json.movimiento);
            }
        }
    }
}

export class Movimiento {
    public id: number;
    public descripcion: string;
    public monto: number;
    public moneda: string = 'PES';
    public created_at: string;
    public anulado: boolean = false;
        
    public metodopago: MetodoDePago = null;
    public cuenta: Cuenta = null;
    public cuentadestino: Cuenta = null;
    public cheque: Cheque = null;
    public ordenpago: OrdenDePagoGeneral = null;
    public orden_pago_id: number;
    public fecha_contable: string;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.descripcion = json.descripcion;
            this.monto = json.monto;
            this.moneda = json.moneda;
            this.created_at = json.created_at;
            this.anulado = json.anulado;
            this.orden_pago_id = json.orden_pago_id;
            this.fecha_contable = json.fecha_contable;
            
            if (json.metodopago) {
                this.metodopago = new MetodoDePago(json.metodopago);
            }
            if (json.cuenta) {
                this.cuenta = new Cuenta(json.cuenta);
            }
            if (json.cuentadestino) {
                this.cuentadestino = new Cuenta(json.cuentadestino);
            }
            if (json.cheque) {
                this.cheque = new Cheque(json.cheque);
            }
            if (json.ordenpago) {
                this.ordenpago = new OrdenDePagoGeneral(json.ordenpago);
            }
        }
    }
}

export class MetodoDePago {
    public id: number;
    public nombre: string;
    public descripcion: string;
    public tipo: string = null;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.nombre = json.nombre;
            this.descripcion = json.descripcion;
            this.tipo = json.tipo;
        }
    }
    
    public esCheque() {
        return this.tipo == 'CHEQUE';
    }
}