<div class="container-fluid">    
    <div class="row">
        <div class="card">
            <div class="card-content">
                <breadcrumb [items]="breadcrumbItems"></breadcrumb>

                <filters-header-section (onFilter)="filter(1)" [advancedFilters]="true" [someFilterApllied]="someFiltersApplied" (onClear)="resetFilters()">
                    <div class="col-md-12">
                        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                            <label for="search">Orden:</label>
                            <div class="input-group">
                                <input class="form-control" type="text" placeholder="Búsqueda de ordenes" name="search" [(ngModel)]="filtro.or_numero_matches">
                                <span class="input-group-addon" (click)="filter(1)"><i class="cmp-i busqueda i-sm"></i></span>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                            <label for="distrito_id">Distrito:</label>
                            <select class="form-control" data-style="btn btn-wd btn-block" name="distrito_id"  [(ngModel)]="filtro.distrito_id_eq" (change)="filter(1)" [disabled]="! actualUser.multi_distrito">
                                <option [ngValue]="null">Todos los Distritos</option>
                                <option *ngFor="let distrito of distritos" [ngValue]="distrito.id">{{distrito.nombre}}</option>
                            </select>
                        </div>
                    </div>
                    
                    <div moreFilters>
                        <div class="col-md-12">
                            <!-- <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <select class="form-control" data-style="btn btn-wd btn-block" name="estado" [(ngModel)]="estadoOrden" (change)="changeEstado()">
                                    <option [ngValue]="null">Todos los Estados</option>
                                    <option [ngValue]="0">Iniciadas</option>
                                    <option [ngValue]="1">Finalizadas</option>
                                    <option [ngValue]="2">En Revision</option>
                                    <option [ngValue]="3">Verificadas</option>
                                    <option [ngValue]="4">Anuladas</option>
                                </select>
                            </div> -->
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="punto_venta">Punto de Venta:</label>
                                <div class="form-group ordenar filter-form-control">
                                    <input type="text" class="form-control" placeholder="Punto de Venta" name="punto_venta" [(ngModel)]="filtro.punto_venta_eq">
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="numero">Nro Comprobante:</label>
                                <div class="form-group ordenar filter-form-control">
                                    <input type="text" class="form-control" placeholder="Nro Comprobante" name="numero" [(ngModel)]="filtro.numero_eq">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <generic_period (outDate)="changeFilterDate($event)" [fromDate]="filtro.fecha_gteq" [toDate]="filtro.fecha_lteq" [enableCleanOption]="true"></generic_period>
                        </div>  
                    </div>
                                    
                                    
                </filters-header-section>


                <div class="row mt-2">
                    <div class="col-sm-10">
                        <button class="btn btn-simple btn-icon btn-add" [routerLink]="['/ordenesventas/new']" title="Agregar"><i class="cmp-i agregar"></i> Agregar</button>
                    </div>  
                    <div class="col-sm-2">
                        <sort-select [options]="sortOptions" [(sortModel)]="filtro.sort" (onSelect)="filter(1)"></sort-select>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-12 table-responsive">
                        <table class="table table-hover">
                            <thead class="text-primary">
                                <tr>
                                    <th><b>Número</b></th>
                                    <th><b>Fecha</b></th>
                                    <th><b>Monto / A Cobrar</b></th>
                                    <th><b>Cobrado</b></th>
                                    <th><b>Persona</b></th>
                                    <th><b>Estado</b></th>
                                    <th><b>Acciones</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let orden of ordenes'>
                                    <td>
                                        <a [routerLink]="['/ordenesventas', orden.id]" rel="tooltip" title="Editar Orden">{{orden.punto_venta}} - {{orden.numero}}</a>
                                        <span *ngIf="orden.estado == 'R'"> (ANULADA)</span>
                                    </td>
                                    <td>{{orden.fecha | date: 'dd/MM/yyyy'}}</td>
                                    <td>$ {{orden.monto | number: '1.2-2'}} / $ {{orden.aPagar() | number: '1.2-2'}}</td>
                                    <td>$ {{orden.pagado | number: '1.2-2'}}</td>
                                    <td>
                                        <span *ngIf="orden.proveedor">{{orden.proveedor.nombre}}</span>
                                        <span *ngIf="!orden.proveedor">-</span>
                                    </td>
                                    <td>
                                        {{orden.estado | translate: "ORDENVENTA_EST"}}
                                    </td>
                                    <td class="td-actions text-right">
                                        <!-- <button type="button" rel="tooltip" title="Imprimir Orden" class="btn btn-danger btn-simple btn-xs" (click)="pdfOrden(orden)">
                                            <i class="ti-printer"></i>
                                        </button> -->
                                        <a [routerLink]="['/ordenesventas', orden.id]" rel="tooltip" title="Editar Orden" class="btn btn-primary btn-simple btn-xs btn-cmp-i">
                                            <i class="cmp-i editar i-sm"></i>
                                        </a>
                                        <button type="button" rel="tooltip" title="Eliminar Orden" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="orden" confirm-message="¿Está seguro que desea eliminar la Orden?">
                                            <i class="cmp-i eliminar-borrar i-sm"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="ordenes != null && ordenes.length == 0" class="text-center">
                                    <td colspan="8">No se encontraron ordenes</td>
                                </tr>
                            </tbody>
                        </table>                        
                        <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)" *ngIf="ordenes != null && ordenes.length > 0"></pager>
                    </div>
                </div>
            </div>
        </div>          
    </div>
</div>