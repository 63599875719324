import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import {CuentaCommunicate} from '../cuenta.communicate';
import {GlobalVars} from '../../services/globalVars';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Cheque} from '../models';

declare var $: any;

@Component({
  selector: 'cheque_modal',
  templateUrl: './cheque_modal.component.html'
})
export class ChequeModalComponent extends GenericFormComponent implements OnInit, OnDestroy {
  @ViewChild('chequeModal', { static: true }) el:ElementRef;

  public show: boolean = false;
  public cheque: Cheque = null;
  
  //Observables
  private chequeModal: any;
    
  constructor(protected messageService: MessageService, private globalVars: GlobalVars, private cuentaCommunicate: CuentaCommunicate) {
      super(messageService);
  }

  ngOnInit() {
    this.chequeModal = this.cuentaCommunicate.chequeModalPop$.subscribe(
        (cheque: Cheque) => {
          this.cheque = cheque;
          this.load();
        });
        
    $(this.el.nativeElement).on('hidden.bs.modal', (e) => {
        this.show= false;
        this.cheque = null;
    });
  }
   
  //MODAL
  public load(){
    this.show= true;
    $(this.el.nativeElement).modal();
  }
  public hide(){
    $(this.el.nativeElement).modal("hide");
    this.show= false;
    this.cheque = null;
  }
  
  //OnDestroy
  ngOnDestroy() {
    this.chequeModal.unsubscribe();
  }
}