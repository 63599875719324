import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';

//import 'rxjs/add/operator/toPromise';
import { Observable ,  Subject } from 'rxjs';
// Import RxJs required methods
import { map, catchError } from 'rxjs/operators';

import { GlobalVars } from '../services/globalVars';
import { Cache } from '../services/cache';


@Injectable()
export class ReportesService{
    private reportesUrl = 'reportes/';

    constructor(private http: HttpClient, private globalVars: GlobalVars, private cache: Cache) {        
    }
    
    //
    //REPORTES
    pdfOrdenes(params?, filtro?): Observable<Blob> {
        const url = `${this.globalVars.apiHost}${this.reportesUrl}ordenes_pago`;
        return this.http.get<Blob>(url + this.globalVars.filtroToUrlV2(filtro), this.globalVars.getOptionsRequestBlob(params));
    }
    
    pdfRetenciones(params?, filtro?): Observable<Blob> {
        const url = `${this.globalVars.apiHost}${this.reportesUrl}retencion_ganancia`;
        return this.http.get<Blob>(url + this.globalVars.filtroToUrlV2(filtro), this.globalVars.getOptionsRequestBlob(params));
    }

    pdfCajaChica(id, params?, filtro?): Observable<Blob> {
        const url = `${this.globalVars.apiHost}${this.reportesUrl}caja_chica`;
        params['id'] = id;
        return this.http.get<Blob>(url + this.globalVars.filtroToUrlV2(filtro), this.globalVars.getOptionsRequestBlob(params));
    }
    
    pdfCheques(params?, filtro?): Observable<Blob> {
        const url = `${this.globalVars.apiHost}${this.reportesUrl}cheques`;
        return this.http.get<Blob>(url + this.globalVars.filtroToUrl(filtro), this.globalVars.getOptionsRequestBlob(params));
    }
}