import { Component, OnInit, Input } from '@angular/core';

import {OrdenService} from '../orden.service';
import {CuentaCommunicate} from '../../module-cuenta/cuenta.communicate';
import { GlobalVars } from '../../services/globalVars';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {OrdenDeVentaGeneral} from '../models';
import {Proveedor} from '../../module-proveedor/models';
import {Recibo} from '../models';

import {PdfService} from '../../services/pdf.service';
 
@Component({
  selector: 'recibos_orden',
  templateUrl: './recibos.component.html'
})
export class RecibosComponent extends GenericFormComponent implements OnInit {
  @Input() orden: OrdenDeVentaGeneral;
  @Input() proveedor: Proveedor;
  public recibos: Recibo[];

  public redondeo: number = 0;

  //FORM
  public recibo: Recibo;
    
  constructor(protected messageService: MessageService, private ordenService: OrdenService,
    private globalVars: GlobalVars, private pdfService: PdfService) {
    super(messageService);
  }

  ngOnInit() {
    this.redondeo = this.globalVars.config.redondeo;
    this.filter();
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    this.cursorWait();
    this.ordenService.getRecibosOrden(this.orden.id).subscribe(
        (recibos: Recibo[]) => {
            this.cursorDefault();
            this.recibos = recibos;
        },
        error => {
            this.cursorDefault();
        }
    );
  }
  
  //ACCIONES    
  onNotifyDelete(recibo: Recibo):void {
    recibo.ordenventa = this.orden;
    this.deleteRecibo(recibo);
  }


  //ACCIONES    
  onConfirmAfip(recibo: Recibo):void {
    console.log(recibo);
    this.beforeSubmitList();
    this.ordenService.confirmarRecibo(recibo).subscribe(
        (recibo: Recibo) => {
            this.showSuccessList(true, 'Recibo confirmado correctamente');
            this.filter();
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }

  public onPrintAfip(recibo: Recibo) {
        this.cursorWait();
        this.ordenService.imprimirRecibo(recibo).subscribe(
            rta => {
                this.cursorDefault();
                this.pdfService.loadPdf("orden_pago", rta); 
            },
            error => {
                this.cursorDefault();
                alert('Ups! No pudimos imprimir el pdf');
            }
        );
  }
    
  public deleteRecibo(recibo: Recibo) {
    this.beforeSubmitList();
    this.ordenService.deleteRecibo(recibo).subscribe(
        (orden: OrdenDeVentaGeneral) => {
            this.showSuccessList(true, 'Recibo eliminado correctamente');
            this.reload();
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }

    
  //FORMULARIO ALTA/EDICION
  //Para el Form
  public newRecibo(){
    this.recibo = new Recibo();
    this.recibo.ordenventa = this.orden;
  }
  
  //Desde el formulario
  public actualizeForm(recibo: Recibo): void {
    this.cancelForm();
    this.reload();
  }    
  public cancelForm(event?): void{
    this.recibo = null;
  }

  private reload() {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
}