<div class="container-fluid">
    <div *ngIf="cuenta" class="row">
        <cuentas_form [cuenta]="cuenta" (actualize)="actualizeForm($event)" (cancel)="cancelForm($event)"></cuentas_form>
    </div>
    
    <div class="row">
        <div class="card">
            <div class="card-content">     
                <breadcrumb [items]="breadcrumbItems"></breadcrumb>
                
                <filters-header-section (onFilter)="filter(1)">
                    <div class="col-md-12">
                        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                            <label for="search">Nombre:</label>
                            <div class="input-group">
                                <input class="form-control" type="text" placeholder="Nombre" name="search" [(ngModel)]="filtro.nombre_matches">
                                <span class="input-group-addon" (click)="filter(1)"><i class="cmp-i busqueda i-sm"></i></span>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                            <label for="distrito_id">Distrito:</label>
                            <select class="form-control" data-style="btn btn-wd btn-block" name="distrito_id"  [(ngModel)]="filtro.distrito_id_eq" (change)="filter(1)" [disabled]="! actualUser.multi_distrito">
                                <option [ngValue]="null">Todos</option>
                                <option *ngFor="let distrito of distritos" [ngValue]="distrito.id">{{distrito.nombre}}</option>
                            </select>
                        </div>


                        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                            <label for="tipo">Tipo de cuenta:</label>
                            <select class="form-control" data-style="btn btn-wd btn-block" name="tipo" [(ngModel)]="filtro.tipo_eq" (change)="filter(1)">
                                <option [ngValue]="null">Todas</option>
                                <option *ngFor="let tipo of tipos" [ngValue]="tipo">{{tipo | translate: "TIPO_CUENTA"}}</option>
                            </select>
                        </div>
                    </div>
                </filters-header-section>      

                <div class="row mt-2">
                    <div class="col-sm-10">
                        <button class="btn btn-simple btn-icon btn-add" *ngIf="!cuenta" (click)="newCuenta()" title="Agregar"><i class="cmp-i agregar"></i> Agregar</button>
                    </div>  
                    <div class="col-sm-2">
                        <sort-select [options]="sortOptions" [(sortModel)]="filtro.sort" (onSelect)="filter(1)"></sort-select>
                    </div>
                </div>

                

                <div class="row mt-1">
                    <div class="col-md-12 table-responsive">
                        <table class="table table-hover">
                            <thead class="text-primary">
                                <tr>
                                    <th><b>Banco</b></th>
                                    <th><b>Número de Cuenta</b></th>
                                    <th><b>Descripción</b></th>
                                    <th><b>Tipo de Cuenta</b></th>
                                    <!--<th><b>Saldo</b></th>-->
                                    <th><b>Distrito</b></th>
                                    <th><b>Acciones</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let cuenta of cuentas'>
                                    <td>
                                        <span *ngIf="cuenta.tipo == 'BANCO' && cuenta.banco">
                                            <span [class]="'bank-logo bank-logo-' + cuenta.banco.id" *ngIf="BANK_LOGO_IDS.includes(cuenta.banco.id)" [title]="cuenta.banco.nombre"></span>
                                            <span *ngIf="!BANK_LOGO_IDS.includes(cuenta.banco.id)" >({{cuenta.banco.nombre}})</span>
                                        </span>  
                                        <div class="text-center">
                                            <b *ngIf="cuenta.tipo != 'BANCO' || !cuenta.banco">-</b>                                    
                                        </div>  
                                    </td>
                                    <td>
                                        <a [routerLink]="['/cuentas', cuenta.id]" rel="tooltip" title="Editar Cuenta" class="cursor-pointer">{{cuenta.nombre}}</a>
                                    </td>
                                    <td>{{cuenta.descripcion}}</td>
                                    <td>
                                        {{cuenta.tipo | translate: 'TIPO_CUENTA'}}
                                        <span *ngIf="cuenta.tipo == 'BANCO' && cuenta.tipo_cuenta"> ({{cuenta.tipo_cuenta}})</span>
                                    </td>
                                    <!--<td>$ {{cuenta.monto}}</td>-->
                                    <td>{{cuenta.distrito.nombre}}</td>
                                    <td class="td-actions text-right">
                                        <a [routerLink]="['/cuentas', cuenta.id]" rel="tooltip" title="Editar Cuenta" class="btn btn-primary btn-simple btn-xs btn-cmp-i">
                                            <i class="cmp-i editar i-sm"></i>
                                        </a>
                                        <a *ngIf="cuenta.tipo == 'BANCO'" [routerLink]="['/chequeras']"  [queryParams]="{cuenta: cuenta.id}" rel="tooltip" title="Chequeras" class="btn btn-primary btn-simple btn-xs">
                                            <i class="ti-wallet"></i>
                                        </a>
                                        <a *ngIf="cuenta.tipo == 'BANCO'" [routerLink]="['/cheques']"  [queryParams]="{cuenta: cuenta.id}" rel="tooltip" title="Cheques" class="btn btn-primary btn-simple btn-xs">
                                            <i class="ti-credit-card"></i>
                                        </a>
                                        <button *ngIf="cuenta.habilitado" type="button" rel="tooltip" title="Deshabilitar Cuenta" class="btn btn-danger btn-simple btn-xs" (click)="changeState(cuenta, false, $event)">
                                            <i class="ti-close"></i>
                                        </button>
                                        <button *ngIf="!cuenta.habilitado" type="button" rel="tooltip" title="Habilitar Cuenta" class="btn btn-danger btn-simple btn-xs" (click)="changeState(cuenta, true, $event)">
                                            <i class="ti-check"></i>
                                        </button>
                                        <button *ngIf="cuenta.monto == 0 && cuenta.tipo != 'PROVEEDOR' && cuenta.tipo != 'CAJA_CHICA'" type="button" rel="tooltip" title="Eliminar Cuenta" class="btn btn-danger btn-simple btn-xs btn-cmp-i" (confirm-click)="onNotifyDelete($event)" [confirm-params]="cuenta" confirm-message="¿Está seguro que desea eliminar la Cuenta?">
                                            <i class="cmp-i eliminar-borrar i-sm"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="cuentas != null && cuentas.length == 0" class="text-center">
                                    <td colspan="5">No se encontraron cuentas</td>
                                </tr>
                            </tbody>
                        </table>                        
                        <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)" *ngIf="cuentas != null && cuentas.length > 0"></pager>
                    </div>
                </div>
            </div>
        </div>          
    </div>
</div>