import { Component, Input, OnInit } from '@angular/core';
import { GenericFormComponent } from 'app/module-generic/components/generic-form.component';
import { NotaCreditoAfip, OrdenDeVentaGeneral } from '../models';
import { MessageService } from 'app/services/message.service';
import { OrdenService } from '../orden.service';
import { GlobalVars } from 'app/services/globalVars';
import { PdfService } from 'app/services/pdf.service';
import { NotaCreditoAfipService } from 'app/module-nota-credito-afip/nota-credito-afip.service';

@Component({
  selector: 'notas-credito-orden',
  templateUrl: './notas-credito-orden.component.html',
  styleUrls: ['./notas-credito-orden.component.sass']
})
export class NotasCreditoOrdenComponent extends GenericFormComponent implements OnInit {
  @Input() orden: OrdenDeVentaGeneral;

  public notaCreditoAfipNew: NotaCreditoAfip;
  public notasCreditoAfip: NotaCreditoAfip[] = [];


  constructor(protected messageService: MessageService, private ordenService: OrdenService,
    private globalVars: GlobalVars, private pdfService: PdfService,
    private notaCreditoAfipService: NotaCreditoAfipService) {
    super(messageService);
  }

  ngOnInit() {
    this.filter();
  }

  public filter(){
    this.cursorWait();
    this.ordenService.getNotasDeCreditoOrden(this.orden.id).subscribe(
        (notaCreditoAfips: NotaCreditoAfip[]) => {
            this.cursorDefault();
            this.notasCreditoAfip = notaCreditoAfips;
        },
        error => {
            this.cursorDefault();
        }
    );
  }

  public onConfirmAfip(notaCreditoAfip: NotaCreditoAfip):void {
    this.beforeSubmitList();
    this.notaCreditoAfipService.confirmarNotaCreditoAfip(notaCreditoAfip).subscribe(
      (notaCreditoAfip: NotaCreditoAfip) => {
        this.showSuccessList(true, 'Nota de crédito confirmada correctamente');
        this.filter();
      }, error => {
        this.showErrorList(error, true);
      }
    );
  }

  public onPrintAfip(notaCreditoAfip: NotaCreditoAfip) {
    this.cursorWait();
    this.notaCreditoAfipService.imprimirNotaCreditoAfip(notaCreditoAfip).subscribe(
      rta => {
        this.cursorDefault();
        this.pdfService.loadPdf("orden_pago", rta); 
      },
      error => {
        this.cursorDefault();
        alert('Ups! No pudimos imprimir el pdf');
      }
    );
  }


  public newNotaCreditoAfip(){
    this.notaCreditoAfipNew = new NotaCreditoAfip();
    this.notaCreditoAfipNew.ordenventa = this.orden;
  }

  public actualizeForm(): void {
    this.cancelForm();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }    

  public cancelForm(event?): void{
    this.notaCreditoAfipNew = null;
  }
  
  public editNotaCredito(notaCreditoAfip: NotaCreditoAfip) {
    this.notaCreditoAfipNew = notaCreditoAfip;
    this.notaCreditoAfipNew.ordenventa = this.orden;
  }

  public deleteNotaCredito(notaCreditoAfip: NotaCreditoAfip) {
    this.notaCreditoAfipService.deleteNotaCreditoAfip(notaCreditoAfip).subscribe(
      () => {
        this.showSuccess(true, 'Nota de crédito eliminada correctamente');
        this.actualizeForm();
      },
      error => {
          this.cursorDefault();
      }
    );
  }
}