<div class="container-fluid">    
    <div class="row">
        <div class="card">
            <div class="card-content">
                <breadcrumb [items]="breadcrumbItems"></breadcrumb>        

                <filters-header-section (onFilter)="filter(1)" [advancedFilters]="true" [someFilterApllied]="someFiltersApplied" (onClear)="resetFilters()">                    
                    <div class="col-sm-12">
                        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                            <label for="descripcion">Descripción:</label>
                            <input type="text" class="form-control" placeholder="Descripción" name="descripcion" [(ngModel)]="filtro.descripcion">
                        </div> 
                        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                            <label for="distrito_id">Distrito:</label>
                            <select class="form-control filter-form-control col-md-12" data-style="btn btn-wd btn-block" name="distrito_id"  [(ngModel)]="filtro.distrito_id_eq" (change)="filter(1)" [disabled]="!actualUser.multi_distrito">
                                <option [ngValue]="null">Todos</option>
                                <option *ngFor="let distrito of distritos" [ngValue]="distrito.id">{{distrito.nombre}}</option>
                            </select>
                        </div>                
                    </div>
                
                    <div moreFilters>
                        <div class="col-md-12">
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="punto_venta">Punto de Venta:</label>
                                <input type="text" class="form-control" placeholder="Punto de Venta" name="punto_venta" [(ngModel)]="filtro.punto_venta_eq">
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label for="numero">Nro Comprobante:</label>
                                <input type="text" class="form-control" placeholder="Nro Comprobante" name="numero" [(ngModel)]="filtro.nro_comprobante_eq">
                            </div>

                        </div>
                        <!-- <div class="col-md-4">
                            <select #fac_select_imputaciones class="form-control filter-form-control selectpicker custom-live-search" data-live-search="true" data-style="btn btn-wd btn-block" name="imputacion_contable_id" [(ngModel)]="filtro.imputacion_contable_id_eq" (change)="filter(1)">
                                <option [ngValue]="null">Todos las Imputaciones</option>
                                <option *ngFor="let imputacion of imputacionesContables" [ngValue]="imputacion.id">{{imputacion.nombre}}</option>
                            </select>
                        </div> -->
                        <!-- <div class="col-md-4">
                            <select class="form-control filter-form-control" data-style="btn btn-wd btn-block" name="tipo" [(ngModel)]="filtro.tipo_eq" (change)="filter(1)">
                                <option [ngValue]="null">Todos los Tipos</option>
                                <option *ngFor="let tipo of tipos" [ngValue]="tipo">{{tipo | translate: "TIPO_recibo"}}</option>
                            </select>
                        </div> -->
                        <!-- <div class="col-md-4">
                            <select class="form-control filter-form-control" data-style="btn btn-wd btn-block" name="estado" [(ngModel)]="filtro.estado_eq" (change)="filter(1)">
                                <option [ngValue]="null">Todos los Estados</option>
                                <option *ngFor="let estado of estados" [ngValue]="estado">{{estado | translate: "recibo_EST"}}</option>
                            </select>
                        </div> -->

                        <!-- <div class="col-md-4">
                            <select #fac_select_proveedores class="form-control filter-form-control selectpicker custom-live-search" data-live-search="true" data-style="btn btn-wd btn-block" name="proveedor_id" [(ngModel)]="filtro.proveedor_id_eq" (change)="filter(1)">
                                <option [ngValue]="null">Todos los Proveedores</option>
                                <option *ngFor="let proveedor of proveedores" [ngValue]="proveedor.id">{{proveedor.nombre}}</option>
                            </select>
                        </div> -->                                                
             

                        <div class="col-md-12">
                            <generic_period #generic_period (outDate)="changeFilterDate($event)" [fromDate]="filtro.fecha_gteq" [toDate]="filtro.fecha_lteq" [enableCleanOption]="true"></generic_period>
                        </div>         
                    </div>        
                </filters-header-section>
            

                <div class="row mt-2">
                    <div class="col-sm-10">
                        <!-- <a [routerLink]="['/recibos/new']" class="btn btn-simple btn-info btn-icon like btn-add">Agregar <i class="cmp-i agregar"></i></a> -->
                    </div>  
                    <div class="col-sm-2">
                        <sort-select-structured [options]="sortOptions" [(sortModel)]="filtro.sort" (onSelect)="filter(1)"></sort-select-structured>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 table-responsive">

                        <table class="table table-hover">
                            <thead class="text-primary">
                                <tr>
                                    <th>Nro Comprobante</th>
                                    <th>Descripción</th>
                                    <th>Fecha</th>
                                    <th>Fecha Vto Pago</th>
                                    <th>Fecha Serv Desde</th>
                                    <th>Fecha Serv Hasta</th>
                                    <th>Monto</th>
                                    <th>Estado</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let notaCreditoAfip of notaCreditoAfips'>
                                    <td>
                                        <!-- <a [routerLink]="['/recibo', notaCreditoAfip.id]" rel="tooltip" title="Editar recibo"> -->
                                            <span *ngIf="notaCreditoAfip.punto_venta">{{notaCreditoAfip.punto_venta}}</span>
                                            <span *ngIf="!notaCreditoAfip.punto_venta">-</span>
                                            <span *ngIf="notaCreditoAfip.nro_comprobante"> - {{notaCreditoAfip.nro_comprobante}}</span>
                                            <span *ngIf="!notaCreditoAfip.nro_comprobante">-</span>
                                        <!-- </a> -->
                                    </td>
                                    <td>{{notaCreditoAfip.descripcion}}</td>
                                    <td>{{notaCreditoAfip.fecha | date: 'dd/MM/yyyy'}}</td>
                                    <td>{{notaCreditoAfip.fecha_vto_pago | date: 'dd/MM/yyyy'}}</td>
                                    <td>{{notaCreditoAfip.fecha_serv_desde | date: 'dd/MM/yyyy'}}</td>
                                    <td>{{notaCreditoAfip.fecha_serv_hasta | date: 'dd/MM/yyyy'}}</td>
                                    <td>$ {{notaCreditoAfip.monto | number: '1.2-2'}}</td>
                                    <td>{{notaCreditoAfip.estado | translate: "NOTA_AFIP_EST"}}</td>
                                    <td class="td-actions text-right">
                                        <button type="button" rel="tooltip" *ngIf="notaCreditoAfip.estado == 'C'" title="Imprimir nota de crédito en Afip" class="btn btn-primary btn-simple btn-xs" (confirm-click)="onPrintAfip($event)" [confirm-params]="notaCreditoAfip" confirm-message="¿Está seguro que desea imprimir la nota de crédito en Afip?">
                                            <i class="ti-printer"></i>
                                        </button>
                                        <button type="button" rel="tooltip" *ngIf="notaCreditoAfip.estado == 'I'" title="Confirmar nota de crédito en Afip" class="btn btn-primary btn-simple btn-xs ml-2" (confirm-click)="onConfirmAfip($event)" [confirm-params]="notaCreditoAfip" confirm-message="¿Está seguro que desea confirmar la nota de crédito en Afip?">
                                            <i class="ti-check"></i>
                                        </button> 
                                        <a [routerLink]="['/ordenesventas', notaCreditoAfip.orden_venta_general_id]" rel="tooltip" title="Ver Nota de Crédito" class="btn btn-primary btn-simple btn-xs btn-cmp-i ml-2">
                                            <i class="cmp-i editar i-sm"></i>
                                        </a>
                                        <button type="button" rel="tooltip" title="Eliminar nota de crédito" class="btn btn-danger btn-simple btn-xs btn-cmp-i ml-2" (confirm-click)="deleteNotaCredito(notaCreditoAfip)" [confirm-params]="notaCred" confirm-message="¿Está seguro que desea eliminar la nota de crédito?">
                                            <i class="cmp-i eliminar-borrar i-sm"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="notaCreditoAfips != null && notaCreditoAfips.length == 0" class="text-center">
                                    <td colspan="10">No se encontraron notas de crédito</td>
                                </tr>
                            </tbody>
                        </table> 
                        <pager [info]="info" [actualPage]="params.page" [perPage]="params.per_page" (filter)="filter($event)" *ngIf="notaCreditoAfips != null && notaCreditoAfips.length > 0"></pager>              
                    </div>
                </div>
            </div>
        </div>          
    </div>
</div>


