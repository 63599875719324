<div class="card">
    <div class="card-content">
        <h4 class="card-title">{{!nota.id ? 'Nueva Nota' : 'Modificación de Nota'}}</h4>
        <hr>

        <form class="form-horizontal" (submit)="save($event)" #datosNotaForm="ngForm">            
            <div class="form-group">
                <div class="col-md-3">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Monto</label>
                        <div class="col-md-9">                                  
                            <input #firstElementNota type="number" name="monto" [(ngModel)]="nota.monto" class="form-control" placeholder="1000" required>
                            <small [hidden]="!inputError.monto" [innerHtml]="inputError.monto" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Fecha</label>
                        <div class="col-md-9">
                            <input-datepicker [(dateModel)]="nota.fecha" [isStringFormat]="true" [required]="true" ></input-datepicker>
                            <small [hidden]="!inputError.fecha" [innerHtml]="inputError.fecha" class="text-danger"></small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center">
                <button class="btn btn-default btn-cancel" type="button" (confirm-click)="cancelForm($event)" confirm-message="¿Está seguro que desea cancelar este formulario?">Cancelar</button>
                <button class="btn btn-primary ml-2" type="submit" [disabled]="submitted">Guardar</button>
            </div>
            <div class="clearfix"></div>
        </form>
    </div>
</div>