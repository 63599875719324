import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild } from '@angular/core';

import {environment} from '../../../environments/environment';

import {FacturaService} from '../factura.service';
import { MessageService } from '../../services/message.service';

import {Producto} from '../models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

@Component({
  selector: 'productos_form',
  templateUrl: 'productos_form.html'
})
export class ProductosFormComponent extends GenericFormComponent implements OnInit, AfterViewInit{
    @ViewChild('firstElementProducto', { static: true }) firstElement: ElementRef;
    
    @Input() producto: Producto = new Producto();    
    
    @Output() actualize: EventEmitter<Producto> = new EventEmitter<Producto>();
    @Output() cancel: EventEmitter<string> = new EventEmitter<string>();
    
    constructor(protected messageService: MessageService, private facturaService: FacturaService) {
        super(messageService);
    }

    ngOnInit(): void {
    }
    ngAfterViewInit(): void {
        this.firstElement.nativeElement.focus();
    }
    
    public save(event){
        this.beforeSubmit(event);
        this.producto.calcularTotal();
        if(! this.producto.id){
            this.facturaService.createProducto(this.producto).subscribe(
                (producto: Producto) => {
                    this.showSuccess(true, 'Producto creado correctamente');
                    this.actualize.emit(producto);
                },err => {
                    this.showError(err, true);
                });
        } else {
            this.facturaService.updateProducto(this.producto).subscribe(
                (producto: Producto) => {
                    this.showSuccess(true, 'Producto modificado correctamente');
                    this.actualize.emit(producto);
                },err => {
                    this.showError(err, true);
                });
        }
    }
    
    public cancelForm($event?){
        this.cancel.emit("Cancelacion");
    }
}