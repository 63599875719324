
export class Distrito {
    public id: number;
    public nombre: string;
    public direccion: string;
    public telefonos: string;
    public codigo: string;
    
    constructor(json?) {
        if(json){
            this.id = json.id;
            this.nombre = json.nombre;
            this.direccion = json.direccion;
            this.telefonos = json.telefonos;
            this.codigo = json.codigo;
        }
    }
}