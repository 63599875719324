import { Component, OnInit } from '@angular/core';

import {ViaticoService} from '../viatico.service';
import { MessageService } from '../../services/message.service';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

import {Periodo} from '../models';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';
 
@Component({
  selector: 'periodos',
  templateUrl: './periodos.component.html'
})
export class PeriodosComponent extends GenericFormComponent implements OnInit {  
  public periodos: Periodo[];
  public params = {page: 1, per_page: 10};
  public filtro = {nombre_matches: null, fecha_gteq: null, fecha_lteq: null, sort: 'fecha,desc'};
  //FORM
  public periodo: Periodo;
  public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Períodos', active: true }];
    
  constructor(protected messageService: MessageService, private viaticoService: ViaticoService) {
    super(messageService);
  }

  ngOnInit() {
    this.filter();
  }

  /** Si no se le pasa pagina actualiza en la que esta */
  public filter(page?, event?){
    if (typeof page == 'object') {
        this.params.page = page.page || this.params.page;
        this.params.per_page = page.per_page || this.params.per_page;
    } else {
        this.params.page = page || this.params.page;
    }
    //this.users= null;
    this.cursorWait();
    this.viaticoService.getPeriodos(this.params, this.filtro).subscribe(
        rta => {
            this.cursorDefault();
            this.periodos = rta.data;
            rta.info = {
                page: rta.current_page,
                countOfPages: rta.last_page,
                count: rta.total
            }
            this.assemblePager(rta.info);
        },
        error => {
            this.cursorDefault();
        }
    );
  }
    
  public changeFilterDate(value){
    this.filtro.fecha_gteq = value.from;
    this.filtro.fecha_lteq = value.to;
    this.filter(1);
  }
  
  //ACCIONES
  
  onNotifyDelete(periodo: Periodo):void {
    this.deletePeriodo(periodo);
  }
    
  public deletePeriodo(periodo: Periodo) {
    this.beforeSubmitList();
    this.viaticoService.deletePeriodo(periodo.id).subscribe(
        rta => {
            this.showSuccessList(true, 'Periodo eliminado correctamente');
            //ELIMINO DE LA LISTA      
            this.periodos.forEach(function(periodoFor, index, object) {
                if(periodoFor.id == periodo.id) {
                    object.splice(index, 1);
                }
            });
        }, error => {
            this.showErrorList(error, true);
        }
    );
  }
  
    
  //FORMULARIO ALTA/EDICION
  //Para el Form
  public newPeriodo(){
    this.periodo = new Periodo();
    this.periodo.fecha = new Date().toISOString().slice(0,10) + " 00:00:00";
  }
  public updatePeriodo(periodo: Periodo){
    this.periodo = new Periodo(periodo);
  }
  
  //Desde el formulario
  public actualizeForm(periodo): void {
    let exists= false;
    this.periodos.forEach(function(periodoFor, index, object) {
        if(periodoFor.id == periodo.id) {
            object[index]= periodo;
            exists= true;
        }
    });
    if(!exists){
        this.periodos.splice(0, 0, periodo);
    }
    this.cancelForm();
  }    
  public cancelForm(event?): void{
    this.periodo= null;
  }
  
}