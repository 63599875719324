
<div class="card">
    <div class="card-content">
        <h4 class="card-title">{{notaCreditoAfip.id ? 'Editar' : 'Crear'}} Nota de Crédito</h4>
        <hr>

        <div class="text-center" *ngIf="recibos.length == 0">
            <b>No se encontraron recibos cargados para la orden de venta.</b>
        </div>

        <form class="form-horizontal" (submit)="onSave()" #datosMovForm="ngForm" *ngIf="recibos.length > 0">
            <div class="form-group">
                <div class="col-md-offset-4 col-md-4">
                    <label>Recibo asociado</label>
                    <select class="form-control" data-style="btn btn-wd btn-block" name="recibo_id" [(ngModel)]="notaCreditoAfip.recibo_id" (change)="validateField('recibo_id')">
                        <option [ngValue]="null">Seleccione el recibo asociado</option>
                        <option *ngFor="let recibo of recibos" [ngValue]="recibo.id">{{recibo.descripcion}}</option>
                    </select>
                    <small [hidden]="!inputError.recibo_id" [innerHtml]="inputError.recibo_id" class="text-danger"></small>   
                </div>
            </div>
            
            <br>

            <div [hidden]="!notaCreditoAfip.recibo_id">
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Método de Pago</label>
                            <div class="col-md-9">
                                <select class="form-control" data-style="btn btn-wd btn-block" name="tipo" [(ngModel)]="notaCreditoAfip.tipo" (change)="onChangeTipo()">
                                    <option [ngValue]="null">Seleccione un Método de Pago</option>
                                    <option *ngFor="let tipo of tipos" [ngValue]="tipo">{{tipo}}</option>
                                </select>
                                <small [hidden]="!inputError.tipo" [innerHtml]="inputError.tipo" class="text-danger"></small>
                            </div>                            
                        </div>
                    </div>
        
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Monto</label>
                            <div class="col-md-9">                                  
                                <input type="number" name="monto" [(ngModel)]="notaCreditoAfip.monto" class="form-control" (change)="validateField('monto')" required #firstElementNotaCreditoAfip>
                                <small [hidden]="!inputError.monto" [innerHtml]="inputError.monto" class="text-danger"></small>
                            </div>                            
                        </div>
                    </div>
                </div>
        
                <div class="form-group">
                    <div class="col-md-6" *ngIf="notaCreditoAfip.tipo == 'CHEQUE'">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Cheque</label>
                            <div class="col-md-9">                                  
                                <input type="number" name="nro_cheque" [(ngModel)]="notaCreditoAfip.nro_cheque" class="form-control" (change)="validateField('nro_cheque')" required>
                                <small [hidden]="!inputError.nro_cheque" [innerHtml]="inputError.nro_cheque" class="text-danger"></small>
                            </div>                            
                        </div>
                    </div>
                    <div class="col-md-6" *ngIf="notaCreditoAfip.tipo == 'TRANSFERENCIA'">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Cuenta</label>
                            <div class="col-md-9">                                  
                                <input type="number" name="nro_cuenta" [(ngModel)]="notaCreditoAfip.nro_cuenta" class="form-control" (change)="validateField('nro_cuenta')" required>
                                <small [hidden]="!inputError.nro_cuenta" [innerHtml]="inputError.nro_cuenta" class="text-danger"></small>
                            </div>                            
                        </div>
                    </div>
                </div>
        
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Fecha Nota</label>
                            <div class="col-md-9"> 
                                <input-datepicker [(dateModel)]="notaCreditoAfip.fecha" [isStringFormat]="true" [required]="true" (actualize)="validateField('fecha')"></input-datepicker>
                                <small [hidden]="!inputError.fecha" [innerHtml]="inputError.fecha" class="text-danger"></small>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Fecha Vencimiento Pago</label>
                            <div class="col-md-9"> 
                                <input-datepicker [(dateModel)]="notaCreditoAfip.fecha_vto_pago" [isStringFormat]="true" [required]="true" (actualize)="validateField('fecha_vto_pago')"></input-datepicker>
                                <small [hidden]="!inputError.fecha_vto_pago" [innerHtml]="inputError.fecha_vto_pago" class="text-danger"></small>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="form-group">
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Fecha Servicio Desde</label>
                            <div class="col-md-9"> 
                                <input-datepicker [(dateModel)]="notaCreditoAfip.fecha_serv_desde" [isStringFormat]="true" [required]="true" (actualize)="validateField('fecha_serv_desde')"></input-datepicker>
                                <small [hidden]="!inputError.fecha_serv_desde" [innerHtml]="inputError.fecha_serv_desde" class="text-danger"></small>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-3 label-on-left">Fecha Servicio Hasta</label>
                            <div class="col-md-9"> 
                                <input-datepicker [(dateModel)]="notaCreditoAfip.fecha_serv_hasta" [isStringFormat]="true" [required]="true" (actualize)="validateField('fecha_serv_hasta')"></input-datepicker>
                                <small [hidden]="!inputError.fecha_serv_hasta" [innerHtml]="inputError.fecha_serv_hasta" class="text-danger"></small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-12">
                        <label>Descripción</label>
                        <div class="row">
                            <div class="col-md-12">                                  
                                <textarea type="text" name="descripcion" [(ngModel)]="notaCreditoAfip.descripcion" class="form-control" rows="4" placeholder="Descripción de la nota de crédito" style="height: 80px !important;" (change)="validateField('descripcion')" required></textarea>
                                <small [hidden]="!inputError.descripcion" [innerHtml]="inputError.descripcion" class="text-danger"></small>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
    
    
    
            <div class="row text-center mt-2">
                <button class="btn btn-default btn-cancel" type="button" (confirm-click)="cancelForm($event)" confirm-message="¿Está seguro que desea cancelar la carga de la nota de crédito?">Cancelar</button>
                <custom-btn-loader
                    text="Guardar"
                    [loading]="loading"
                    [disabled]="submitted || loading"
                    (action)="onSave()"
                    btnClass="btn btn-primary ml-1"
                ></custom-btn-loader> 
            </div>
    
            <div class="clearfix"></div>
        </form>
    </div>
</div>
