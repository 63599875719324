import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';

//import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs/Rx';
import { Subject }    from 'rxjs/Subject';
// Import RxJs required methods
import { map, catchError } from 'rxjs/operators';

import { GlobalVars } from '../services/globalVars';
import {NotaCreditoAfip} from '../module-orden-venta/models';

@Injectable()
export class NotaCreditoAfipService {
    private notaCreditoAfipUrl = 'facturacion/notas_de_credito_afip';

    constructor(private http: HttpClient, private globalVars: GlobalVars) { }

    getNotaCreditoAfips(params?, filtro?): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.notaCreditoAfipUrl}`;        
        return this.http.get<any>(url + this.globalVars.filtroToUrlV2(filtro), this.globalVars.getOptionsRequest(params)).pipe(
            map((rta) => {
                rta.data = rta.data.map(item => new NotaCreditoAfip(item));
                return rta;
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    getNotaCreditoAfip(id): Observable<NotaCreditoAfip> {
        const url= `${this.globalVars.apiHost}${this.notaCreditoAfipUrl}/${id}`;
        return this.http.get<NotaCreditoAfip>(url, this.globalVars.getOptionsRequest()).pipe(
            map((notaCreditoAfip: NotaCreditoAfip) => {
                return new NotaCreditoAfip(notaCreditoAfip);
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    createNotaCreditoAfip(notaCreditoAfip: NotaCreditoAfip): Observable<NotaCreditoAfip> {
        const url = `${this.globalVars.apiHost}${this.notaCreditoAfipUrl}`;
        let body= JSON.parse(JSON.stringify(notaCreditoAfip));
        return this.http.post<NotaCreditoAfip>(url, JSON.stringify(body), this.globalVars.getOptionsRequest()).pipe(
            map((notaCreditoAfip: NotaCreditoAfip) => {
                return new NotaCreditoAfip(notaCreditoAfip);
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    updateNotaDeCredito(notaDeCredito: NotaCreditoAfip): Observable<NotaCreditoAfip> {
        const url = `${this.globalVars.apiHost}${this.notaCreditoAfipUrl}/${notaDeCredito.id}`;
        return this.http.put<NotaCreditoAfip>(url, JSON.stringify(notaDeCredito), this.globalVars.getOptionsRequest()).pipe(
            map((notaDeCredito: NotaCreditoAfip) => {
                return new NotaCreditoAfip(notaDeCredito);
            }), catchError(this.globalVars.tratarErrores)
        );
    }

    deleteNotaCreditoAfip(notaDeCredito: NotaCreditoAfip): Observable<any> {
        const url = `${this.globalVars.apiHost}${this.notaCreditoAfipUrl}/${notaDeCredito.id}`;
        return this.http.delete<any>(url, this.globalVars.getOptionsRequest()).pipe(
            catchError(this.globalVars.tratarErrores)
        );
    }

    confirmarNotaCreditoAfip(notaDeCredito: NotaCreditoAfip): Observable<NotaCreditoAfip> {
        const url = `${this.globalVars.apiHost}${this.notaCreditoAfipUrl}/${notaDeCredito.id}/confirmarAfip`;
        return this.http.post<NotaCreditoAfip>(url, JSON.stringify({}), this.globalVars.getOptionsRequest()).pipe(
            map((notaDeCredito: NotaCreditoAfip) => {
                return new NotaCreditoAfip(notaDeCredito);
            }), catchError(this.globalVars.tratarErrores)
        );
    }


    imprimirNotaCreditoAfip(notaDeCredito: NotaCreditoAfip): Observable<Blob> {
        const url = `${this.globalVars.apiHost}${this.notaCreditoAfipUrl}/${notaDeCredito.id}/imprimirAfip`;
        return this.http.get<Blob>(url, this.globalVars.getOptionsRequestBlob());
    }
}