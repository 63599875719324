import { Injectable } from '@angular/core';

import { Subject }    from 'rxjs';

import {Proveedor} from './models';

@Injectable()
export class ProveedorCommunicate {
    // Observable string sources    
    private proveedoresModalPopSource = new Subject<any>();
    private proveedorModalPopSource = new Subject<number>();
    private proveedoresSelecModalPopSource = new Subject<Proveedor[]>();
    
    // Observable string streams
    public proveedoresModalPop$ = this.proveedoresModalPopSource.asObservable();
    public proveedorModalPop$ = this.proveedorModalPopSource.asObservable();
    public proveedoresSelecModalPop$ = this.proveedoresSelecModalPopSource.asObservable();
    
    constructor() { 
    }
    
    public proveedoresModalPopUp(info: any){
        this.proveedoresModalPopSource.next(info);
    }
    public proveedorModalPopUp(id: number){
        this.proveedorModalPopSource.next(id);
    }  
    public proveedoresSelecModalPopUp(proveedores: Proveedor[]){
        this.proveedoresSelecModalPopSource.next(proveedores);
    }
}