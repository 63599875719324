import {Injectable, Inject} from '@angular/core';

import { HttpClient } from '@angular/common/http';

//import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs';
// Import RxJs required methods




import {GlobalVars} from '../services/globalVars';

import { TRANSLATIONS } from './translations'; // import our opaque token

@Injectable()
export class TranslateService {
    private _currentLang: string;
    private transalateApi: any[]= [];

    public get currentLang() {
        return this._currentLang;
    }

    // inject our translations
    constructor(@Inject(TRANSLATIONS) private _translations: any, private http: HttpClient, private globalVars: GlobalVars) {
    }
    
    public loadConfig(){
        return new Promise((resolve, reject) => {
            const url= `${this.globalVars.apiHost}api/i18n`;
            let rtaObs: Observable<any>= this.http.get(url, this.globalVars.getOptionsRequest());
            
            rtaObs.subscribe(
                (transalateApi) => {
                    this.transalateApi= transalateApi;
                    resolve(null);
                }, err => {
                    reject();
                }
            );
        });
    }

    public use(lang: string): void {
        // set current language
        this._currentLang = lang;
    }

    private translate(key: string, prefix?: string): string {
        // private perform translation
        let translation = key;
        key= prefix ? prefix + '_' + key : key;
        if (this._translations[this._currentLang] && this._translations[this._currentLang][key]) {
            return this._translations[this._currentLang][key];
        }
        return translation;
    }

    public instant(key: string, prefix?: string) {
        // call translation
        return this.translate(key, prefix); 
    }
    
    //TRANSLATE API
    private translateApi(key: string, prefix?: string): string {
        // private perform translation
        let translation = key;
        key= prefix ? prefix + '_' + key : key;
        if (this.transalateApi && this.transalateApi[key]) {
            return this.transalateApi[key];
        }
        return translation;
    }

    public instantApi(key: string, prefix?: string) {
        // call translation
        return this.translateApi(key, prefix); 
    }
}