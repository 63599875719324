import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild } from '@angular/core';

import {environment} from '../../../environments/environment';

import {CuentaService} from '../cuenta.service';
import { MessageService } from '../../services/message.service';

import {Cheque, Chequera, Cuenta} from '../models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';
import { BreadcrumbItems } from 'app/module-generic/components/breadcrumb/breadcrum-items.model';

@Component({
  selector: 'cheques_form',
  templateUrl: 'cheques_form.html'
})
export class ChequesFormComponent extends GenericFormComponent implements OnInit, AfterViewInit{
    @ViewChild('firstElementCheque', { static: true }) firstElement: ElementRef;
    
    @Input() withBackground: boolean = false;
    @Input() cheque: Cheque = new Cheque();
    @Input() cuenta: Cuenta = null;
    public chequeras: Chequera[] = [];
    public estados: string[] = environment.estados_cheque;
    
    @Output() actualize: EventEmitter<Cheque> = new EventEmitter<Cheque>();
    @Output() cancel: EventEmitter<string> = new EventEmitter<string>();
    
    public breadcrumbItems: BreadcrumbItems[] = [ {title: 'Home', ref: '#' }, {title: 'Cheques', ref: '#/cheques' }];
    public BANK_LOGO_IDS = environment.bank_logo_ids;

    constructor(protected messageService: MessageService, private cuentaService: CuentaService) {
        super(messageService);
    }

    ngOnInit(): void {
        if(this.cheque?.id) {
            this.breadcrumbItems.push({ title: `${this.cheque.numero}`, active: true });
        }

        if (this.cuenta != null) {
            this.cuentaService.getChequeras({per_page: 1000}, {cuenta_id_eq: this.cuenta.id, finalizada_eq: 0, sort: 'numero_desde'}).subscribe(
                (rta) => {
                    this.chequeras = rta.data;
                }
            );
        }
        if (!this.cheque.id && this.cheque.chequera) {
            this.selectChequera();
        }
    }
    ngAfterViewInit(): void {
        this.firstElement.nativeElement.focus();
    }
    
    public save(event){
        this.beforeSubmit(event);
        if(! this.cheque.id){
            this.cuentaService.createCheque(this.cheque).subscribe(
                (cheque: Cheque) => {
                    this.showSuccess(true, 'Cheque creado correctamente');
                    this.actualize.emit(cheque);
                },err => {
                    this.showError(err, true);
                });
        } else {
            this.cuentaService.updateCheque(this.cheque).subscribe(
                (cheque: Cheque) => {
                    this.showSuccess(true, 'Cheque modificado correctamente');
                    this.actualize.emit(cheque);
                },err => {
                    this.showError(err, true);
                });
        }
    }
    
    public cancelForm($event?){
        this.cancel.emit("Cancelacion");
    }
    
    public selectChequera() {
        //
        //CUANDO SE ESTA CREANDO EL CHEQUE SETEO CAMPOS POR DEFECTO
        this.cuentaService.getChequesChequera(this.cheque.chequera.id, {page: 1, per_page: 1000}).subscribe(
            (cheques: Cheque[]) => {
                cheques.sort(function(a, b){
                    var keyA = new Date(a.numero),
                        keyB = new Date(b.numero);
                    // Compare the 2 dates
                    if(keyA > keyB) return -1;
                    if(keyA < keyB) return 1;
                    return 0;
                });
                if (this.cheque.chequera.tipo_libro == 'AU') {
                    if (cheques.length > 0) {
                        this.cheque.numero = cheques[0].numero + 1;
                    } else {
                        this.cheque.numero = this.cheque.chequera.numero_desde;
                    }
                }
            },
            error => {
                this.cursorDefault();
            }
        );
        this.cheque.emision = new Date().toISOString().slice(0,10) + " 00:00:00";
        this.cheque.acreditacion = new Date().toISOString().slice(0,10) + " 00:00:00";
        let vencimiento = new Date();
        vencimiento.setDate(vencimiento.getDate() + 30);
        this.cheque.vencimiento = vencimiento.toISOString().slice(0,10);
    }
    
    public changeNumeroCheque() {
        if (!this.cheque.id && this.cuenta) {
            const chequera = this.chequeras.filter(c => {
                return c.numero_desde <= this.cheque.numero && c.numero_hasta >= this.cheque.numero
            });
            if (chequera.length == 1) {
                this.cheque.chequera = chequera[0];
            } else {
                this.cheque.chequera = null;
            }
        }
    }

    public changeDiferido() {
        if (! this.cheque.diferido) {
            this.cheque.acreditacion = new Date().toISOString().slice(0,10) + " 00:00:00";
        }
    }

    //
    // UTILS
    public checkNumber(name, value) {
        if (value == null || value <= 0) {
            this.setInputError(name, "Campo incorrecto");
        } else {
            this.removeInputError(name);
        }
    }
}