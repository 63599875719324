<div class="card">
    <div class="card-content">
        <breadcrumb [items]="breadcrumbItems"></breadcrumb>
        
        <form class="form-horizontal" (submit)="save($event)" #datosForm="ngForm">

            <div class="form-group">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Nombre de Imputación</label>
                        <div class="col-md-9">                                  
                            <input #firstElementImputacion type="text" name="nombre" [(ngModel)]="imputacion.nombre" class="form-control" placeholder="Imputación X" required>
                            <small [hidden]="!inputError.nombre" [innerHtml]="inputError.nombre" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-3 label-on-left">Descripción</label>
                        <div class="col-md-9">                                 
                            <input type="text" name="descripcion" [(ngModel)]="imputacion.descripcion" class="form-control" placeholder="Descripción de la imputación" required>
                            <small [hidden]="!inputError.descripcion" [innerHtml]="inputError.descripcion" class="text-danger"></small>
                        </div>                            
                    </div>
                </div>                    
            </div>

            <div class="row text-center">
                <button class="btn btn-default btn-cancel" type="button" (confirm-click)="cancelForm($event)" confirm-message="¿Está seguro que desea cancelar este formulario?">Cancelar</button>
                <button class="btn btn-primary ml-2" type="submit" [disabled]="submitted">Guardar</button>
            </div>
                    
            <div class="clearfix"></div>
        </form>
    </div>
</div>