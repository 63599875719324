import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild } from '@angular/core';

import {environment} from '../../../environments/environment';

import {CuentaService} from '../cuenta.service';
import { MessageService } from '../../services/message.service';
import {GlobalVars} from '../../services/globalVars';

import {Cuenta, Movimiento, MetodoDePago, Cheque} from '../models';

import {GenericFormComponent} from '../../module-generic/components/generic-form.component';

@Component({
  selector: 'movimientos_form',
  templateUrl: 'movimientos_form.html'
})
export class MovimientosFormComponent extends GenericFormComponent implements OnInit, AfterViewInit{
    @ViewChild('firstElementMovimiento', { static: true }) firstElement: ElementRef;
    
    @Input() movimiento: Movimiento = new Movimiento();
    public metodos: MetodoDePago[] = [];
    public cuentas: Cuenta[] = [];
    public cuentasFiltro: Cuenta[] = [];
    public extraCuentas: Cuenta[] = [];
    public monedas: string[] = environment.monedas;
    public numeroCheque: number;
    
    public cheque: Cheque = null;
    
    @Output() actualize: EventEmitter<Movimiento> = new EventEmitter<Movimiento>();
    @Output() cancel: EventEmitter<string> = new EventEmitter<string>();
    
    constructor(protected messageService: MessageService, private cuentaService: CuentaService, private globalVars: GlobalVars) {
        super(messageService);
    }

    ngOnInit(): void {
        this.cuentaService.getMetodosDePago({per_page: 1000}, {sort: 'nombre'}).subscribe(
            (rta: any) => {
                this.metodos = rta.data.filter(m => {
                    if (this.movimiento.cuenta.tipo == 'BANCO') {
                        return m.tipo == 'TRANSFERENCIA' || m.tipo == 'DEBITO' || m.tipo == 'CHEQUE';
                    } else {
                        return m.tipo != 'TRANSFERENCIA' && m.tipo != 'DEBITO' && m.tipo != 'CHEQUE';
                    }
                });
            }
        );
        this.cuentaService.getCuentas({per_page: 1000}, {distrito_id_eq: this.movimiento.cuenta.distrito.id, sort: 'nombre'}).subscribe(
            (rta: any) => {
                this.cuentas = rta.data.filter(c => c.id != this.movimiento.cuenta.id);
            }
        );
        let cuentaSubsidio = new Cuenta();
        cuentaSubsidio.id = -1;
        cuentaSubsidio.nombre = 'Pago de Subsidios';
        this.extraCuentas.push(cuentaSubsidio);
    }
    
    ngAfterViewInit(): void {
        this.firstElement.nativeElement.focus();
    }
    
    public buscarCheque() {
        this.cuentaService.getCheques({cuenta: this.movimiento.cuenta.id}, {numero_eq: this.numeroCheque, estado_di: 'ANULADO'}).subscribe(
            (rta: any) => {
                if (rta.data.length > 0) {
                    this.movimiento.cheque = rta.data[0];
                    this.movimiento.monto = this.movimiento.cheque.monto;
                } else {
                    this.messageService.showNotification("top", "center", "warning", 'No se encontro el cheque');
                }
            }
        )
    }
    
    public save(event){
        this.beforeSubmit(event);
        this.cuentaService.createMovimiento(this.movimiento).subscribe(
            (movimiento: Movimiento) => {
                this.showSuccess(true, 'Movimiento creado correctamente');
                this.actualize.emit(movimiento);
            },err => {
                this.showError(err, true);
            });
    }
    
    public cancelForm($event?){
        this.cancel.emit("Cancelacion");
    }
    
    public newCheque() {
        this.submitted = true;
        this.cheque = new Cheque();
        this.cheque.monto = this.movimiento.monto;
    }
    
    //
    //DESDE EL FORMULARIO DE CHEQUE
    public actualizeFormCheque(cheque: Cheque): void {
        this.movimiento.cheque = cheque;
        this.movimiento.monto = this.movimiento.cheque.monto;
        this.cancelFormCheque();
    }    
    public cancelFormCheque(event?): void {
        this.submitted = false;
        this.cheque = null;
    }
    
    //
    //UTILS
    public changeMetodo() {
        if (this.movimiento.metodopago == null) {
            this.cuentasFiltro = [];
            return;
        }
        if (this.movimiento.metodopago.tipo == 'TRANSFERENCIA' || this.movimiento.metodopago.tipo == 'DEBITO') {
            this.cuentasFiltro = this.cuentas.filter(c => (c.tipo == 'BANCO' || c.tipo == 'CAJA_CHICA'));
        }else if (this.movimiento.metodopago.tipo == 'CHEQUE') {
            this.cuentasFiltro = this.cuentas.filter(c => c.tipo != 'BANCO').concat(this.extraCuentas);
        }else if (this.movimiento.metodopago.tipo == 'EFECTIVO') {
            this.cuentasFiltro = this.cuentas.filter(c => c.tipo != 'BANCO');
        }else {
            this.cuentasFiltro = [];
        }
        if (this.cuentasFiltro.length >= 1) {
            this.movimiento.cuentadestino = this.cuentasFiltro[0];
        }
    }
    
    public validarDestino() {
        if (this.movimiento.cuentadestino && this.movimiento.cuentadestino.tipo == 'CAJA_CHICA') {
            this.cuentaService.getEstadoCuenta(this.movimiento.cuentadestino.id, null).subscribe(
                (data: any) => {
                    if (data['movimiento'] == false) {
                        this.showError({}, true, 'La caja chica no permite mas reposiciones por este mes.')
                    }
                    this.noShowSuccess();
                }
            );
        }
    }
    
    public enabledSubmit() {
        this.submitted = false;
    }
    
    public disabledSubmit() {
        this.submitted = true;
    }
}