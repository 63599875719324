import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { GenericModule } from 'app/module-generic/generic.module';
import { TranslateComponentModule } from 'app/translate-module/translate-component.module';
import { NotaCreditoAfipComponent } from './nota-credito-afip/nota-credito-afip.component';
import { NotaCreditoAfipService } from './nota-credito-afip.service';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    GenericModule,
    TranslateComponentModule
  ],
  declarations: [
    NotaCreditoAfipComponent
  ],
  providers: [NotaCreditoAfipService],
  exports: []
})
export class NotaCreditoAfipModule { }
