
import {mergeMap, map, filter} from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef, Directive } from '@angular/core';
import {Router, NavigationEnd } from '@angular/router';
import {Title, Meta } from '@angular/platform-browser';
import { Location } from '@angular/common';

import {environment} from '../../../environments/environment';

import { GlobalVars } from '../../services/globalVars';
import { UserService } from '../../services/user.service';
import {DistritoService} from '../../module-distrito/distrito.service';
import {Cache} from '../../services/cache';
import {MessageService} from '../../services/message.service';

import { User } from '../../models/user';
import {Distrito} from '../../module-distrito/models';

declare var $: any;

@Component({
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html',
    //styleUrls: ['../../../']
})

export class NavbarComponent implements OnInit{
    public paneles: string[] = [];
    
    location: Location;
    private nativeElement: Node;
    private toggleButton: any;
    private sidebarVisible: boolean;

    user: User;
    //INFO PAGE
    public titlePage: string = '';
    
    public distritos: Distrito[] = [];
    public distrito: Distrito = null;

    public showSubMenu: any = {
        distritos: false,
        paneles: false
    }

    public misc: any = {
        navbar_menu_visible: 0,
        active_collapse: true,
        disabled_collapse_init: 0,
    }

    @ViewChild("navbar-cmp") button: any;

    constructor(location:Location, private element : ElementRef, private globalVars: GlobalVars, private userService: UserService, private cache: Cache, private router: Router,
        private title: Title, private meta: Meta, private messageService: MessageService, private distritoService: DistritoService) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }

    ngOnInit(){
        //CARGAR TITULOS
        this.definePaneles();   

        var navbar : HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
        if($('body').hasClass('sidebar-mini')){
            this.misc.sidebar_mini_active = true;
        }
        $('#minimizeSidebar').click(() => {
            var $btn = $(this);

            if(this.misc.sidebar_mini_active == true){
                $('body').removeClass('sidebar-mini');
                this.misc.sidebar_mini_active = false;

            }else{
                setTimeout(() => {
                    $('body').addClass('sidebar-mini');

                    this.misc.sidebar_mini_active = true;
                },300);
            }

            // we simulate the window Resize so the charts will get updated in realtime.
            var simulateWindowResize = setInterval(function(){
                window.dispatchEvent(new Event('resize'));
            },180);

            // we stop the simulation of Window Resize after the animations are completed
            setTimeout(function(){
                clearInterval(simulateWindowResize);
            },1000);
        });
        
        this.user = this.globalVars.actualUser;
        //Cargo distritos
        this.distritos = this.distritoService.distritos;
        this.distrito = this.distritoService.getLoadDistrito(this.globalVars.getActualDistrito());
      
        //Me registro a los observer de login y logout
        //No es necesario asignar los eventos a ninguna variable y luego eliminarlos ya que el modulo se carga una unica vez
        this.userService.loginUser$.subscribe(
            user => {
                this.user = user;
                this.definePaneles();
                this.distrito = this.distritoService.getLoadDistrito(this.globalVars.getActualDistrito());
            }
        );
        this.userService.logoutUser$.subscribe(
            user => {
                this.user = null;
                this.distrito = null;
            }
        );
        
        this.globalVars.changeDistrito$.subscribe(
            (event) => {
                this.distrito = this.distritoService.getLoadDistrito(this.globalVars.getActualDistrito());
            }
        );
        
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(_ => this.router.routerState.root),
            map(route => {
              while (route.firstChild) route = route.firstChild;
              return route;
            }),
            mergeMap(route => route.data),)
            .subscribe(data => {                
                //REMUEVO META QUE NO SIEMPRA DEBA ESTAR
                //this.meta.removeTag("name='yourmetaname'");
                
                //AGREGO META
                if(data['metas']){
                    //LISTA
                    this.meta.addTags(data['metas']);
                }
                //this.meta.addTag({ name: 'pepe', content: 'yourmetacontent' });                
                
                //SETEO TITULO
                if(data['title']){
                    this.titlePage= data['title'];                    
                }else{
                    this.titlePage= '';                    
                }
                this.title.setTitle(this.titlePage);
            }
        );
    }
    
    //
    //ACIONES
    cleanAllCache(){
        this.cache.cleanAllCache();
        this.messageService.showNotification("top", "center", "success", "Cache de Datos borrada correctamente");
    }
  
    closeSession(){    
      this.userService.logout();
    }
    
    public selectPanel(panel: string){
        this.globalVars.setActualPanel(panel);
    }
    
    public selectDistrito(distrito: Distrito){
        this.distrito = distrito;
        this.globalVars.setActualDistrito(distrito.id);
    }
    
    //
    //UTILS
    isMobileMenu(){
        if($(window).width() < 991){
            return false;
        }
        return true;
    }

    sidebarOpen(){
        var toggleButton = this.toggleButton;
        var body = document.getElementsByTagName('body')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        },500);
        body.classList.add('nav-open');
        this.sidebarVisible = true;
    }
    sidebarClose(){
        var body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    }
    sidebarToggle(){
        // var toggleButton = this.toggleButton;
        // var body = document.getElementsByTagName('body')[0];
        if(this.sidebarVisible == false){
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

    definePaneles() {
        if (this.user) {
                this.paneles = environment.panels.filter(panel => {
                    return this.user.hasAtLeastOneRolByName(environment.panelsRoles[panel]);
                });
                if(this.globalVars.getActualDistrito() == 11){
                    this.paneles = this.paneles.filter(function(item) {
                        return item !== 'sells';
                    })
                }
        } else {
            this.paneles = [];
        }
    }
    
    public getActualPanel(){
        return this.globalVars.getActualPanel();
    }

    getPath(){
        // console.log(this.location);
        return this.location.prepareExternalUrl(this.location.path());
    }

    public gotoDedaloFreshdesk() : void {
        window.open("https://dedalo.freshdesk.com/", "_blank");
   }
}
